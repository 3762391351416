"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_TYPES = exports.WIDGET_TYPES = void 0;
exports.WIDGET_TYPES = [
    "carselector-apps",
    "carselector-cars",
    "carselector-classic-cars",
    "carselector-classic-motos",
    "carselector-motos",
    "carselector-trucks",
    "catalog-hdi",
    "parts-catalog",
    "parts-tires",
    "parts-mdd",
    "parts-origin",
    "diag-manufacturers",
    "diag-tools",
    "lms-catalog",
    "lms-docs",
    "lms-forms",
    "lms-initial-slides",
    "lms-news",
    "lms-pack",
    "lms-promotions",
    "lms-social",
    "lms-tools",
    "lms-applications",
    "lms-services",
    "lms-formations",
    "oeselector-brands",
    "oeselector-trucks",
    "rse-catalog",
    "rse-catalog-pl",
    "rse-docs",
    "rse-forms",
    "rse-initial-slides",
    "rse-news",
    "rse-news-pl",
    "rse-pack",
    "rse-promotions",
    "rse-promotions-pl",
    "rse-social",
    "rse-tools",
    "smart-catalog",
    "smart-docs",
    "smart-forms",
    "smart-initial-slides",
    "smart-news",
    "smart-pack",
    "smart-promotions",
    "smart-social",
    "smart-tools",
    "smart-applications",
    "smart-services",
    "smart-formations",
    "social-partners",
    "social-digital",
    "social-customer-relations",
    "social-network",
    "stats-selector-cars",
    "stats-selector-motos",
    "stats-selector-trucks",
];
exports.APP_TYPES = [
    "databox",
    "diag",
    "iconLink",
    "iframe",
    "iframeCarSelector",
    "levam",
    "linkTo",
    "stock",
    "internal",
];
