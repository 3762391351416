import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    Link,
    Route,
    Switch,
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import ReactTable, { Column } from "react-table";

import type { RawCartItem } from "shared/dist/types/cart";

import { CartContext } from "@/context/CartItems";
import {
    formatPrice,
    ItemDesc,
    MakeCell,
} from "@/pages/ShoppingCart/CartTable";
import { groupByChannel } from "@/pages/ShoppingCart/ShoppingCart";
import { fetchWithAuth, postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";
import { getKeys } from "@/utils/utils";

import "./Checkout.css";

const { useEffect, useState, useContext } = React;

type History = ReturnType<typeof useHistory>;

const onSubmit =
    (history: History) =>
    (data: unknown): void => {
        postContent("checkoutAddressing", data)
            .then(() => {
                history.push("/checkout/select-payment");
            })
            .catch((error) => {
                console.error(error);
                toastr.error("Une erreur inattendue est survenue");
            });
    };

const CheckoutAddressing = (): JSX.Element => {
    const { register, handleSubmit } = useForm();
    const history = useHistory();
    return (
        <form
            className="checkout-form"
            onSubmit={handleSubmit(onSubmit(history))}
        >
            <h3>Adresse de livraison</h3>
            <div className="two-fields">
                <label>
                    <span>Nom</span>
                    <input type="text" {...register("lastName")} required />
                </label>
                <label>
                    <span>Prénom</span>
                    <input type="text" {...register("firstName")} required />
                </label>
            </div>
            <label>
                <span>Adresse</span>
                <input type="text" {...register("street")} required />
            </label>
            <label>
                Pays
                <select {...register("countryCode")}>
                    <option value="FR">France</option>
                </select>
            </label>
            <div className="two-fields">
                <label>
                    <span>Ville</span>
                    <input type="text" {...register("city")} required />
                </label>
                <label>
                    <span>Code postal</span>
                    <input type="text" {...register("postcode")} required />
                </label>
            </div>
            <div className="checkout-footer">
                <Link to="/cart">Retour</Link>
                <button type="submit">Suivant</button>
            </div>
        </form>
    );
};

type Payment = {
    id: number;
    code: string;
    name: string;
    description?: string;
};

const onSelectPayment =
    (history: History) =>
    (data: { method: string }): void => {
        const { method } = data;
        if (!method || method === "undefined") return;
        fetchWithAuth(`selectPayment/${method}`)
            .then(() => {
                history.push("/checkout/complete");
            })
            .catch((error) => {
                console.error(error);
                toastr.error("Une erreur inattendue est survenue");
            });
    };

const SelectPayment = (): JSX.Element => {
    const [payments, setPayments] = useState<Payment[]>([]);
    useEffect(() => {
        fetchWithAuth("paymentMethods")
            .then((res) => res.json())
            .then((methods) => setPayments(methods))
            .catch((error) => console.error(error));
    }, []);
    const { register, handleSubmit } = useForm<{ method: string }>();
    const history = useHistory();
    return (
        <div className="checkout-select-payment">
            <form
                className="checkout-form"
                onSubmit={handleSubmit(onSelectPayment(history))}
            >
                <h2>Choix du paiement</h2>
                {payments.map((payment) => (
                    <label key={payment.id}>
                        <input
                            type="radio"
                            {...register("method")}
                            value={payment.code}
                            required
                        />
                        <span>{payment.name}</span>
                        {payment.description && <p>{payment.description}</p>}
                    </label>
                ))}
                <div className="checkout-footer">
                    <Link to="/checkout">Retour</Link>
                    <button type="submit" disabled={payments.length === 0}>
                        Suivant
                    </button>
                </div>
            </form>
        </div>
    );
};

const computeCartsTotal = (sum: number, item: RawCartItem): number =>
    sum + item.price * item.quantity;

const CartTable = (): JSX.Element => {
    const { t } = useTranslation();
    const { cart } = useContext(CartContext);
    const columns: Column[] = [
        {
            Header: t("item.make"),
            width: 100,
            Cell: MakeCell,
        },
        {
            Header: "Article",
            Cell: ItemDesc,
            id: "Article",
        },
        {
            Header: "Prix",
            width: 100,
            accessor: (item): string => formatPrice(item.unitPrice),
            id: "price",
        },
        {
            Header: "Quantité",
            width: 100,
            accessor: "quantity",
        },
    ];
    const total = formatPrice(cart.reduce(computeCartsTotal, 0));
    const carts = groupByChannel(cart);
    return (
        <>
            {getKeys(carts).map((channel) => (
                <ReactTable
                    key={channel}
                    data={carts[channel]}
                    columns={columns}
                    defaultPageSize={30}
                    minRows={1}
                    showPagination={false}
                />
            ))}
            <div className="cart-footer">
                <strong>Total: {total}</strong>
            </div>
        </>
    );
};

const onComplete = (history: History, cb: () => void) => (): void => {
    fetchWithAuth("completeCheckout")
        .then(() => {
            cb();
            history.push("/checkout/thank-you");
        })
        .catch((error) => {
            console.error(error);
            toastr.error("Une erreur inattendue est survenue");
        });
};

const CompleteCheckout = (): JSX.Element => {
    const history = useHistory();
    const { handleSubmit } = useForm();
    // const { updateSyliusItems } = useContext(CartContext);
    const cb = (): void => {
        console.log("TODO: update cart");
    };
    return (
        <form
            className="checkout-form"
            onSubmit={handleSubmit(onComplete(history, cb))}
        >
            <h3>Récapitulatif de la commande</h3>
            <CartTable />
            <div className="checkout-footer">
                <Link to="/checkout/select-payment">Retour</Link>
                <button type="submit">Confirmer la commande</button>
            </div>
        </form>
    );
};

const OrderComplete = (): JSX.Element => (
    <div className="order-complete">
        <p>Merci ! Votre commande a été enregistrée.</p>
    </div>
);

export const Checkout = (): JSX.Element => {
    const { path } = useRouteMatch();
    return (
        <div className="inner-content checkout">
            <Switch>
                <Route path={`${path}/select-payment`}>
                    <SelectPayment />
                </Route>
                <Route path={`${path}/complete`}>
                    <CompleteCheckout />
                </Route>
                <Route path={`${path}/thank-you`}>
                    <OrderComplete />
                </Route>
                <Route>
                    <CheckoutAddressing />
                </Route>
            </Switch>
        </div>
    );
};
