import type * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import {
    faCarSide,
    faMotorcycle,
    faTruckMoving,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import type { Vtype } from "shared/dist/types/vehicle";

import { AppsWidget } from "@/components/AppsWidget";

export const TabsBrands = (): React.ReactElement => {
    const { vtype = "vl" } = useParams<{ vtype: Vtype }>();
    const activeTab = vtype === "classic-motos" ? "classic" : vtype;
    const { t } = useTranslation();

    const path = "/selection-vehicule"; // could get it from useLocation

    return (
        <>
            <div>
                <span> {t("carselector.select-make")} </span>
                <Nav tabs style={{ display: "inline-flex" }}>
                    <NavItem>
                        <Link to={`${path}/vl`}>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "vl",
                                })}
                                tag="div"
                            >
                                <FontAwesomeIcon icon="car" />
                            </NavLink>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to={`${path}/motos`}>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "motos",
                                })}
                                tag="div"
                            >
                                <FontAwesomeIcon icon={faMotorcycle} />
                            </NavLink>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to={`${path}/pl`}>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "pl",
                                })}
                                tag="div"
                            >
                                <FontAwesomeIcon icon={faTruckMoving} />
                            </NavLink>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to={`${path}/classic`}>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "classic",
                                })}
                                tag="div"
                            >
                                <FontAwesomeIcon icon={faCarSide} />
                            </NavLink>
                        </Link>
                    </NavItem>
                </Nav>
            </div>
            <TabContent activeTab={activeTab} className="tabs-brands">
                <TabPane tabId="vl">
                    <AppsWidget widget="carselector-cars" />
                </TabPane>
                <TabPane tabId="pl">
                    <AppsWidget widget="carselector-trucks" />
                </TabPane>
                <TabPane tabId="motos">
                    <AppsWidget widget="carselector-motos" />
                </TabPane>
                <TabPane tabId="classic">
                    <h2 className="classic-cars">Classic Cars</h2>
                    <AppsWidget widget="carselector-classic-cars" />
                    <h2 className="classic-cars">Moto Legend</h2>
                    <AppsWidget widget="carselector-classic-motos" />
                </TabPane>
            </TabContent>
        </>
    );
};
