import type * as React from "react";
import { Redirect, Route } from "react-router-dom";

import type { UserAccess } from "shared/dist/types/auth";

import { useLogin } from "@/context/AuthContext";

import { Restricted } from "./Restricted";

interface PrivateRouteProps {
    children: React.ReactNode;
    redirectTo?: string;
    path?: string;
    needAccess: UserAccess;
    loading?: JSX.Element;
}

const RedirectRoute = ({
    children,
    redirectTo = "/login",
    needAccess,
    ...rest
}: PrivateRouteProps) => {
    return (
        <Route
            {...rest}
            render={({ location }): React.ReactNode => (
                <Redirect
                    to={{
                        pathname: redirectTo,
                        state: { from: location },
                    }}
                />
            )}
        />
    );
};

export const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = (
    props
) => {
    const { isLoading, hasUserInfo } = useLogin();
    // If the user is authenticated but the userInfo is not loaded yet, we don't
    // want to redirect. We want to wait for the user info to load while staying
    // on the same URL.
    if (isLoading) return props.loading ?? null;
    if (hasUserInfo) {
        return (
            <Restricted access={props.needAccess}>{props.children}</Restricted>
        );
    }
    return <RedirectRoute {...props} />;
};
