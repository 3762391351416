import * as React from "react";

import type { Attribute } from "shared/dist/types/item";
import type { GenartAttribute } from "shared/dist/types/types";

import { CriteriaContext } from "@/context/CriteriaContext";

type Props = {
    attributes: Attribute[] | undefined;
    localAttributes?: Record<string, string>;
    globalAttributes?: Record<string, string>;
    genartAttributes?: GenartAttribute[];
    shouldModify?: boolean;
    isGlobal?: boolean;
    shouldEdit?: boolean;
    showSuggestions?: boolean;
    showAllCriterias?: boolean;
    formRef?: React.Ref<HTMLFormElement>;
    attachInput?: (label: string, input: HTMLInputElement | null) => void;
};

const noop = () => {};

export const Attributes = (props: Props): JSX.Element => {
    const {
        attributes: origAttributes = [],
        localAttributes = {},
        globalAttributes = {},
        shouldModify = false,
        showSuggestions = false,
        attachInput = noop,
        isGlobal = true,
        shouldEdit,
        formRef = noop,
        genartAttributes = [],
        showAllCriterias = false,
    } = props;
    const expertAttributes = isGlobal ? globalAttributes : localAttributes;
    const attributes = origAttributes || [];
    const [criterias] = React.useContext(CriteriaContext);
    if (shouldModify) {
        return (
            <table>
                <tbody>
                    {attributes.map((attribute, i) => (
                        <tr key={`${attribute.labelId}-${i}`}>
                            <td>{attribute.label}</td>
                            <td>{attribute.value}</td>
                            <td>
                                <input
                                    type="text"
                                    data-id={attribute.labelId}
                                    defaultValue={
                                        expertAttributes[attribute.label] || ""
                                    }
                                    ref={(input) =>
                                        attachInput(attribute.label, input)
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    const mandatoryAttributes = genartAttributes
        .filter((attribute) => attribute.mandatory)
        .map((attribute) => attribute.label);
    const missingAttributes = showSuggestions
        ? genartAttributes.filter(
              (genartAttribute) =>
                  !attributes.find(
                      (attribute) => attribute.label === genartAttribute.label
                  )
          )
        : [];
    const displayedAttributes = attributes.filter(
        (attribute) =>
            showAllCriterias ||
            !criterias.hide ||
            mandatoryAttributes.includes(attribute.label)
    );

    const list = (
        <ul className="attributes-list">
            {displayedAttributes.map((attribute, i) => {
                const mandatory = mandatoryAttributes.includes(attribute.label);
                const localAttribute = localAttributes[attribute.label];
                const globalAttribute = globalAttributes[attribute.label];
                const key = `${attribute.labelId}-${i}`;
                if (
                    localAttribute &&
                    globalAttribute &&
                    localAttribute !== attribute.value
                ) {
                    return (
                        <li key={key}>
                            <label
                                className={
                                    mandatory && showSuggestions
                                        ? "attribute-mandatory"
                                        : ""
                                }
                            >
                                {shouldEdit && (
                                    <input
                                        type="checkbox"
                                        data-id={attribute.labelId}
                                    />
                                )}
                                <span className="attribute-label">
                                    {attribute.label}
                                </span>
                                {attribute.value == null ? "" : ": "}
                                <span className="strike">
                                    {attribute.value}
                                </span>{" "}
                                <span className="ktype-attribute">
                                    {localAttribute}
                                </span>{" "}
                                <span className="genart-attribute">
                                    ({globalAttribute})
                                </span>
                            </label>
                        </li>
                    );
                }
                const expertAttribute = localAttribute || globalAttribute;
                if (expertAttribute && expertAttribute !== attribute.value) {
                    return (
                        <li key={key}>
                            <label
                                className={
                                    mandatory && showSuggestions
                                        ? "attribute-mandatory"
                                        : ""
                                }
                            >
                                {shouldEdit && (
                                    <input
                                        type="checkbox"
                                        data-id={attribute.labelId}
                                    />
                                )}
                                <span className="attribute-label">
                                    {attribute.label}
                                </span>
                                {attribute.value == null ? "" : ": "}
                                <span className="strike">
                                    {attribute.value}
                                </span>{" "}
                                <span className="expert-attribute">
                                    {expertAttribute}
                                </span>
                            </label>
                        </li>
                    );
                }
                return (
                    <li key={key}>
                        <label
                            className={
                                mandatory && showSuggestions
                                    ? "attribute-mandatory"
                                    : ""
                            }
                        >
                            {shouldEdit && (
                                <input
                                    type="checkbox"
                                    data-id={attribute.labelId}
                                />
                            )}
                            <span className="attribute-label">
                                {attribute.label}
                            </span>
                            {attribute.value == null ? "" : ": "}
                            {attribute.value}
                        </label>
                    </li>
                );
            })}
            {missingAttributes.map((o) => (
                <li key={o.label}>
                    <label
                        className={
                            o.mandatory && showSuggestions
                                ? "attribute-mandatory"
                                : ""
                        }
                    >
                        <span className="attribute-label missing-attribute">
                            {o.label}
                        </span>
                    </label>
                </li>
            ))}
        </ul>
    );

    return shouldEdit ? (
        <form ref={formRef} className="attributes-form">
            {list}
        </form>
    ) : (
        list
    );
};
