import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import type { Attribute } from "shared/dist/types/item";
import type { ExpertData } from "shared/dist/types/parts";
import type { GenartAttribute } from "shared/dist/types/types";

import { i18n } from "@/config/i18n";
import { useExpertDataMode } from "@/context/ExpertDataContext";
import { getContent, postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

import { Attributes } from "./Attributes";
import { parseAttributes } from "./ItemContainer";

type EditButtonProps = {
    editing: boolean;
    validate: () => void;
    edit: () => void;
    cancel: () => void;
};

const MARGIN_LEFT_5: React.CSSProperties = { marginLeft: 5 };

const EditButton = (props: EditButtonProps) => {
    const { t } = useTranslation();
    const { editing, validate, cancel, edit } = props;
    if (editing) {
        return (
            <>
                <Button color="primary" onClick={validate}>
                    {t("common.submit")}
                </Button>
                <Button color="primary" onClick={cancel} style={MARGIN_LEFT_5}>
                    {t("common.cancel")}
                </Button>
            </>
        );
    }
    return (
        <Button color="primary" onClick={edit} style={MARGIN_LEFT_5}>
            {t("item.edit")}
        </Button>
    );
};

type ModifyButtonProps = {
    modifying: boolean;
    onRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isGlobal: boolean;
    validate: () => void;
    modify: () => void;
    cancel: () => void;
};

const ModifyButton = (props: ModifyButtonProps) => {
    const { modifying, onRadioChange, isGlobal } = props;
    const { t } = useTranslation();
    if (modifying) {
        return (
            <>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="isGlobal"
                            value="global"
                            onChange={onRadioChange}
                            checked={isGlobal}
                        />
                        {t("item.for-all-vehicles")}
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="isGlobal"
                            value="local"
                            onChange={onRadioChange}
                            checked={!isGlobal}
                        />
                        {t("item.only-this-vehicle")}
                    </label>
                </div>
                <Button color="primary" onClick={props.validate}>
                    {t("common.submit")}
                </Button>
                <Button
                    color="primary"
                    onClick={props.cancel}
                    style={MARGIN_LEFT_5}
                >
                    {t("common.cancel")}
                </Button>
            </>
        );
    }
    return (
        <Button color="primary" onClick={props.modify}>
            {t("item.modify")}
        </Button>
    );
};

type EditableAttributesProps = {
    attributes: Attribute[] | undefined;
    genartAttributes: GenartAttribute[];
    showSuggestions: boolean;
    genartId: number | undefined;
    articleId: string | undefined;
    refId: string;
    ktypnr: string | undefined;
};

const validateEdit = (genartId: number, formRef: HTMLFormElement) => {
    const inputTags = formRef.querySelectorAll("input");
    const attributes = [...inputTags].map((input) => {
        const inputLabel = (input.nextElementSibling as HTMLSpanElement)
            .innerText;
        const mandatory = input.checked;
        const labelId = input.dataset.id;
        return { mandatory, label: inputLabel, labelId };
    });
    return postContent(`setGenartAttributes/${genartId}`, attributes);
};

const setAttributes = (
    url: string,
    inputs: Record<string, HTMLInputElement>
) => {
    const rows = Object.keys(inputs).map((attribute) => ({
        attribute,
        value: inputs[attribute].value,
    }));
    return postContent(url, rows).then(() => rows);
};

export const EditableAttributes = ({
    attributes,
    genartAttributes,
    showSuggestions,
    articleId,
    refId,
    genartId,
    ktypnr,
}: EditableAttributesProps): JSX.Element => {
    const [editing, setEditing] = React.useState(false);
    const [modifying, setModifying] = React.useState(false);
    const [isGlobal, setGlobal] = React.useState(true);
    const [localAttributes, setLocalAttributes] = React.useState<
        Record<string, Record<string, string>>
    >({});
    const [globalAttributes, setGlobalAttributes] = React.useState<
        Record<string, Record<string, string>>
    >({});
    const inputs = React.useRef<Record<string, HTMLInputElement>>({});
    const formRef = React.useRef<HTMLFormElement>(null);
    const expertDataMode = useExpertDataMode();
    const editCriterias = () => {
        if (!formRef.current || !genartId) {
            return;
        }
        setEditing(false);
        validateEdit(genartId, formRef.current).catch((error) => {
            console.error(error);
            toastr.error(i18n.t("common.error"));
        });
    };
    const modify = () => setModifying(true);
    const cancel = () => setModifying(false);
    const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setGlobal(e.currentTarget.value === "global");
    const attachInput = (attribute: string, input: HTMLInputElement | null) => {
        if (input) {
            inputs.current[attribute] = input;
        }
    };
    const attrKey = `${articleId}/${refId}`;

    React.useEffect(() => {
        let unmounted = false;
        getContent<ExpertData>(`expertData/${ktypnr}/${genartId}`).then(
            (data) => {
                if (unmounted) return;
                const expertAttributes = parseAttributes(data.attributes);
                setLocalAttributes(expertAttributes[0]);
                setGlobalAttributes(expertAttributes[1]);
            }
        );
        return () => {
            unmounted = true;
        };
    }, [ktypnr, genartId]);

    const validateModify = () => {
        const ktype_param = isGlobal ? "GLOBAL" : ktypnr;
        const url = `setAttributes/${ktype_param}/${genartId}/${articleId}/${refId}`;
        setAttributes(url, inputs.current)
            .then((rows) => {
                toastr.success(
                    i18n.t("item.toast.change-ref-success", { refId })
                );
                const expertAttributes: Record<
                    string,
                    Record<string, string>
                > = JSON.parse(
                    JSON.stringify(
                        isGlobal ? globalAttributes : localAttributes
                    )
                );
                expertAttributes[attrKey] = expertAttributes[attrKey] || {};
                for (const row of rows) {
                    expertAttributes[attrKey][row.attribute] = row.value;
                }
                if (isGlobal) {
                    setGlobalAttributes(expertAttributes);
                } else {
                    setLocalAttributes(expertAttributes);
                }
                setModifying(false);
            })
            .catch((error) => {
                console.error(error);
                toastr.error(i18n.t("item.toast.change-ref-error", { refId }));
            });
    };

    return (
        <div className="d-flex">
            <Attributes
                attributes={attributes}
                genartAttributes={genartAttributes}
                showSuggestions={showSuggestions}
                showAllCriterias={true}
                localAttributes={localAttributes[attrKey]}
                globalAttributes={globalAttributes[attrKey]}
                formRef={formRef}
                shouldEdit={editing}
                shouldModify={modifying}
                attachInput={attachInput}
            />
            {expertDataMode && (
                <div className="ms-auto align-self-center">
                    {!editing && (
                        <ModifyButton
                            modifying={modifying}
                            onRadioChange={onRadioChange}
                            isGlobal={isGlobal}
                            modify={modify}
                            validate={validateModify}
                            cancel={cancel}
                        />
                    )}
                    {!modifying && (
                        <EditButton
                            editing={editing}
                            edit={() => setEditing(true)}
                            cancel={() => setEditing(false)}
                            validate={editCriterias}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
