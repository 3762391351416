import type * as React from "react";

import { AppsWidget } from "@/components/AppsWidget";

import { TabsBrands } from "./TabsBrands";

import "./SelectBrand.css";

export const SelectBrand = (): React.ReactElement => {
    return (
        <div className="selection-bloc main-bloc selection-brand select-brand">
            <TabsBrands />
            <div className="mobilit">
                <span>Mobil&apos;iT RENT VN VO VA VHU Conciergerie</span>
                <AppsWidget widget="carselector-apps" />
            </div>
        </div>
    );
};
