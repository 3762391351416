import type * as React from "react";

import type { UserAccess } from "shared/dist/types/auth";

import { useAuth } from "@/context/AuthContext";

import { Unauthorized } from "./Unauthorized";

type Props = {
    access: UserAccess;
    children: React.ReactNode;
    fallback?: React.ReactNode;
};

export const Restricted = ({
    access,
    children,
    fallback = <Unauthorized />,
}: Props): JSX.Element => {
    const { hasAccess } = useAuth();
    if (hasAccess(access)) {
        return <>{children}</>;
    }
    return <>{fallback}</>;
};
