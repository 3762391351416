import * as React from "react";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useCountry } from "@/context/CountryContext";
import { useAPI, useLanguage } from "@/utils/hooks";

import { TecdocPrice, TecdocPriceComponent } from "./TecdocPriceComponent";

type Props = {
    artNr: string;
    DLNr: string;
};

export const LocalPrice = ({ artNr, DLNr }: Props): JSX.Element | null => {
    const lang = useLanguage();
    const [country] = useCountry();
    const { data, error } = useAPI<TecdocPrice[]>(
        `/price/${lang}/${country.id}/${encodeURIComponent(artNr)}/${DLNr}`
    );

    if (error) {
        return <ErrorMessage />;
    }

    if (!data) {
        return <Loading />;
    }

    if (data.length === 0) {
        return null;
    }

    return (
        <>
            {data.map((price, i) => (
                <TecdocPriceComponent key={i} price={price} />
            ))}
        </>
    );
};
