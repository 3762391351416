import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    apiRootURL,
    CAIA_FULL_IMAGE_URL,
    CAIA_GUII_IMAGE_URL,
    IMAGES_SERVER,
    WORKSHOP_PATH,
} from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import { getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";
import { getLogoURL, getModelImage } from "@/utils/utils";

import { useAddItemSlide } from "./Item/ItemSlides";
import { Loading } from "./Loading";
import { SlidesStack } from "./SlidesStack";

import "./GuiSelector.css";

const { useState, useEffect } = React;

const IMG_ROOT_URL = `${IMAGES_SERVER}/itemid/`;

const setDefaultCarImage = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
): void => {
    e.currentTarget.src = `${apiRootURL}/media/models/default.jpg`;
};

type Params = {
    field: string;
    value: string;
    version: string;
    ktype: string;
};

type BreadcrumbProps = {
    brand: string | undefined;
    model: string | undefined;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isFull: boolean;
    url: string;
};

const Breadcrumbs = ({
    brand,
    model,
    onInputChange,
    isFull,
    url,
}: BreadcrumbProps) => {
    const { t } = useTranslation();
    const match = useRouteMatch<Params>();
    const brandLogo = getLogoURL(brand);
    const modelImage = brand && model && getModelImage(brand, model);
    const { field, value } = match.params;
    const isV2 = match.url.startsWith(WORKSHOP_PATH);
    const urlv2 = `${WORKSHOP_PATH}/CarSelector/search/${field}/${value}`;
    const bigImageURL = isFull ? CAIA_FULL_IMAGE_URL : CAIA_GUII_IMAGE_URL;
    return (
        <div className="breadcrumbs-wrapper breadcrumbs-family">
            <Breadcrumb>
                <div className="breadcrumb-img breadcrumb-img-brand">
                    <Link to={isV2 ? urlv2 : "/selection-vehicule/"}>
                        <img src={brandLogo} alt={brand} />
                    </Link>
                </div>
                {model && (
                    <div className="breadcrumb-img breadcrumb-img-model">
                        <Link
                            to={
                                isV2 ? urlv2 : `/selection-vehicule/vl/${brand}`
                            }
                        >
                            <img
                                src={modelImage}
                                alt={model}
                                onError={setDefaultCarImage}
                            />
                        </Link>
                    </div>
                )}
                <BreadcrumbItem>
                    {model ? (
                        <Link to={isV2 ? urlv2 : url}>
                            {brand} {model}
                        </Link>
                    ) : (
                        brand
                    )}
                </BreadcrumbItem>
                <BreadcrumbItem active>
                    {t(`albums.${isFull ? "vinCE" : "C@iA-gui-selector"}`)}
                </BreadcrumbItem>
                <div className="breadcrumb-filter gui-selector-filter">
                    <FontAwesomeIcon icon={faFilter} />
                    <input type="text" onChange={onInputChange}></input>
                </div>
                <div className="breadcrumb-img">
                    <img src={bigImageURL} alt="" />
                </div>
            </Breadcrumb>
        </div>
    );
};

type Criteria = { oe: string; label: string };

type Item = {
    itemId: string;
    label: string;
    frontRear: string;
    leftRight: string;
    criterias: Criteria[];
};

type Props = {
    isFull?: boolean;
    url: string;
};

const GuiSelectorContent = (props: Props): JSX.Element => {
    const [items, setItems] = useState<Item[]>([]);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("");
    const [vehicleData] = useVehicleData();
    const {
        Marque: brand,
        Modèle: model,
        id_vehiculo_kromeda: vehicleId,
    } = vehicleData;

    const { oeNrs } = useParams<{ oeNrs: string }>();
    const { isFull = false } = props;

    const { t } = useTranslation();
    const lang = useLanguage(true);
    const { addPartsSearchSlide } = useAddItemSlide();

    const fetchData = (): (() => void) => {
        let _mounted = true;
        const setUnmounted = () => {
            _mounted = false;
        };
        if (vehicleId === undefined) {
            return setUnmounted;
        }
        const url = isFull
            ? `parts/katia/all/${lang}/${vehicleId}`
            : `parts/katia/${lang}/${vehicleId}/${oeNrs}`;
        setLoading(true);
        getContent<Item[]>(url)
            .then((newItems) => {
                if (!_mounted) {
                    return;
                }
                setItems(newItems);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
        return setUnmounted;
    };

    useEffect(fetchData, [lang, vehicleId, oeNrs, isFull]);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setText(e.target.value);
    };

    const hasMatchingLabel = (item: Item): boolean =>
        `${item.label} - ${item.frontRear} ${item.leftRight}`
            .toLowerCase()
            .includes(text.toLowerCase());

    const hasMatchingCriteria = (criteria: Criteria): boolean =>
        (criteria.label + criteria.oe)
            .toLowerCase()
            .includes(text.toLowerCase());

    const filterItems = (item: Item): boolean =>
        hasMatchingLabel(item) || item.criterias.some(hasMatchingCriteria);

    const filterCriterias =
        (item: Item) =>
        (criteria: Criteria): boolean =>
            hasMatchingLabel(item) || hasMatchingCriteria(criteria);

    // const titleKey = `albums.${isFull ? "vinCE" : "C@iA-gui-selector"}`;
    const titleKey = isFull ? "albums.vinCE" : "albums.C@iA-gui-selector";
    if (loading) {
        return (
            <>
                <Breadcrumbs
                    brand={brand}
                    model={model}
                    onInputChange={onInputChange}
                    isFull={isFull}
                    url={props.url}
                />
                <h2>{t(titleKey)}</h2>
                <Loading />
            </>
        );
    }
    return (
        <>
            <Breadcrumbs
                brand={brand}
                model={model}
                onInputChange={onInputChange}
                isFull={isFull}
                url={props.url}
            />
            <div className="gui-selector-bloc">
                <h2>{t(titleKey)}</h2>
                <div className="d-flex">
                    <ul>
                        {items.filter(filterItems).map((item) => (
                            <li key={item.itemId}>
                                <img
                                    className="item-image"
                                    src={`${IMG_ROOT_URL}${item.itemId}.png`}
                                    alt=""
                                />
                                <span>{item.label}</span>
                                <span>
                                    {item.frontRear || item.leftRight
                                        ? " - "
                                        : ""}
                                </span>
                                <span>{item.frontRear}</span>
                                <span>{" " + item.leftRight}</span>
                                <ul>
                                    {item.criterias
                                        .filter(filterCriterias(item))
                                        .map((criteria) => (
                                            <li key={criteria.oe}>
                                                <span
                                                    className="oe-name"
                                                    onClick={() =>
                                                        addPartsSearchSlide(
                                                            criteria.oe,
                                                            "ref"
                                                        )
                                                    }
                                                >
                                                    {criteria.oe}
                                                </span>
                                                <span> </span>
                                                <span>{criteria.label}</span>
                                            </li>
                                        ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <SlidesStack className="oes-slider gui-selector-oe-results" />
                </div>
            </div>
        </>
    );
};

export const GuiSelector = (props: Props): JSX.Element => (
    <div className="selection-bloc main-bloc item-bloc">
        <GuiSelectorContent {...props} />
    </div>
);
