import type * as React from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { Restricted } from "@/components/Restricted";
import { SlidesProvider, SlidesStack } from "@/components/SlidesStack";
import { WizardNavigation } from "@/components/WizardNavigation";
import { IMAGES_SERVER } from "@/config/settings";
import { useAPI } from "@/utils/hooks";

import { TechautoItemTable } from "./TechautoItemTable";

import "./StockTechauto.css";

const DEFAULT_IMG_URL = `${IMAGES_SERVER}/gif/DEFAULT.png`;
const PATH = "/stock/techauto";

interface Category {
    id: string;
    label: string;
}

interface TechautoFamily {
    id: string;
    label: string;
    categories: Category[];
}

type TechautoUniverse = {
    id: number;
    label: string;
    families: TechautoFamily[];
};

export interface TechautoItem {
    ref: string;
    brand: string;
    dlnr?: number;
    shortName: string;
    label1: string;
    label2: string;
    price: string;
}

interface AlbumProps {
    name: string;
    isSelected: boolean;
    imgURL: string;
    bigImgUrl?: string;
    onClickHandler(): void;
    children: React.ReactNode;
}

const Album = ({
    name,
    isSelected,
    imgURL,
    bigImgUrl = imgURL,
    onClickHandler,
    children,
}: AlbumProps) => {
    return (
        <li className={isSelected ? "selected" : ""}>
            <div
                className="family-album tooltip-arrow"
                onClick={onClickHandler}
                role="button"
            >
                <img src={imgURL} alt="" />
                <div>{name}</div>
            </div>
            {/* The .details div has to be present in the DOM for the CSS transition to work. */}
            <div className="details">
                {isSelected && children}
                <img className="illustration" src={bigImgUrl} alt="" />
            </div>
        </li>
    );
};

export const StockTechauto = (): JSX.Element => {
    return (
        <Restricted access="Techauto-app">
            <div className="stock-techauto flex-grow-1 position-relative d-flex me-2 mt-1">
                <div className="main-bloc overflow-auto flex-grow-1">
                    <ErrorBoundary>
                        <SlidesProvider>
                            <StockTechautoFetcher />
                            <SlidesStack />
                        </SlidesProvider>
                    </ErrorBoundary>
                </div>
            </div>
        </Restricted>
    );
};

type TechautoBreadcrumbProps = {
    children?: React.ReactNode;
};

const TechautoBreadcrumb = ({ children }: TechautoBreadcrumbProps) => {
    return (
        <div className="breadcrumbs-wrapper">
            <Breadcrumb>
                <BreadcrumbItem className="align-items-center">
                    <FontAwesomeIcon
                        icon="barcode"
                        style={{ marginRight: 5 }}
                    />
                    <Link to={PATH}>Techauto</Link>
                </BreadcrumbItem>
                {children}
            </Breadcrumb>
            <div className="breadcrumb-img">
                <Link to={PATH}>
                    <img src={`${IMAGES_SERVER}/logos/techauto.jpg`} alt="" />
                </Link>
            </div>
        </div>
    );
};

type TechautoFamiliesProps = {
    families: TechautoFamily[];
    universeId: number;
};

const TechautoFamilies = (props: TechautoFamiliesProps) => {
    const { families, universeId } = props;
    const familyMatch = useRouteMatch<{ family: string }>(
        `${PATH}/:universe/:family`
    );
    const familyId = familyMatch?.params.family ?? null;
    const history = useHistory();
    const onFamilyClick = (id: string) => () => {
        const universeURL = `${PATH}/${universeId}`;
        const url = familyId === id ? universeURL : `${universeURL}/${id}`;
        history.push(url);
    };
    return (
        <ul className="albums families">
            {families.map((family) => (
                <Album
                    key={family.id}
                    isSelected={familyId === family.id}
                    imgURL={DEFAULT_IMG_URL}
                    name={family.label}
                    onClickHandler={onFamilyClick(family.id)}
                >
                    <ul>
                        {family.categories.map((category) => (
                            <li key={category.id}>
                                <Link
                                    to={`${PATH}/${universeId}/${family.id}/${category.id}`}
                                >
                                    {`${category.label} (${category.id})`}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Album>
            ))}
        </ul>
    );
};

const TechautoItems = ({ categoryId }: { categoryId: string }) => {
    const { data: items, error } = useAPI<TechautoItem[]>(
        `stock/techauto/${categoryId}`
    );
    if (error) {
        return <ErrorMessage />;
    }
    if (!items) {
        return <Loading />;
    }
    return <TechautoItemTable items={items} />;
};

const AppList = ({ ids }: { ids: number[] }) => {
    return (
        <div className="d-flex justify-content-around">
            {ids.map((id) => (
                <div key={id} className="marque">
                    <img src={`${IMAGES_SERVER}/logos/techauto.jpg`} alt="" />
                </div>
            ))}
        </div>
    );
};

const TechautoUniverses = ({
    universes,
}: {
    universes: TechautoUniverse[];
}): JSX.Element => {
    const history = useHistory();
    const match = useRouteMatch<{ universe: string }>(`${PATH}/:universe`);
    const universeId = match ? Number(match.params.universe) : universes[0].id;
    const familyMatch = useRouteMatch<{ family: string }>(
        `${PATH}/:universe/:family`
    );
    const familyId = familyMatch?.params.family ?? null;
    const categoryMatch = useRouteMatch<{ category: string }>(
        `${PATH}/:universe/:family/:category`
    );
    const categoryId = categoryMatch?.params.category ?? null;

    const tabIds = universes.map((u) => u.id);
    const tabLabels = universes.reduce<Record<number, string[]>>((acc, u) => {
        const label = `${u.id}. ${u.label}`;
        acc[u.id] = [label];
        return acc;
    }, {});

    const toggleTab = (tabId: number) => history.push(`${PATH}/${tabId}`);

    const families = universes.find((u) => u.id === universeId)?.families;

    if (!families) {
        return (
            <TechautoBreadcrumb>
                <span>Univers {universeId} manquant</span>
            </TechautoBreadcrumb>
        );
    }

    const selectedFamily = families.find((f) => f.id === familyId) ?? null;
    const selectedCategory = selectedFamily?.categories.find(
        (c) => c.id === categoryId
    );

    return (
        <>
            <TechautoBreadcrumb>
                <BreadcrumbItem className="align-items-center">
                    <Link to={`${PATH}/${universeId}`}>
                        {tabLabels[universeId][0]}
                    </Link>
                </BreadcrumbItem>
                {selectedFamily && (
                    <BreadcrumbItem className="align-items-center">
                        <Link to={`${PATH}/${universeId}/${selectedFamily.id}`}>
                            {selectedFamily.label}
                        </Link>
                    </BreadcrumbItem>
                )}
                {selectedCategory && (
                    <BreadcrumbItem className="align-items-center">
                        <span>{selectedCategory.label}</span>
                    </BreadcrumbItem>
                )}
            </TechautoBreadcrumb>
            <AppList ids={tabIds} />
            <WizardNavigation
                activeTab={universeId}
                updateActiveTab={toggleTab}
                tabIds={tabIds}
                tabLabels={tabLabels}
            />
            {selectedCategory ? (
                <TechautoItems categoryId={selectedCategory.id} />
            ) : (
                <TechautoFamilies families={families} universeId={universeId} />
            )}
        </>
    );
};

const StockTechautoFetcher = (): JSX.Element => {
    const { data: universes, error } =
        useAPI<TechautoUniverse[]>("stock/techauto");

    if (error) {
        return (
            <>
                <TechautoBreadcrumb />
                <ErrorMessage />
            </>
        );
    }

    if (universes === null) {
        return (
            <>
                <TechautoBreadcrumb />
                <Loading />
            </>
        );
    }

    return <TechautoUniverses universes={universes} />;
};
