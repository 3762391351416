import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactTable, { Column } from "react-table";

import type { OE } from "shared/dist/types/item";

import { CartButton } from "@/components/Item/ArticleTable";
import { Availability } from "@/components/Item/Availability";
import { IMAGES_SERVER } from "@/config/settings";
import { useAddToCart } from "@/context/CartItems";
import { useAvailability } from "@/utils/availability";
import { getSupplierLogoURL } from "@/utils/utils";

import type { TechautoItem } from "./StockTechauto";

type CellRenderer = { original: TechautoItem };

const ItemColumnMake = ({ original: item }: CellRenderer) => {
    const imgSrc = item.dlnr
        ? getSupplierLogoURL(item.dlnr)
        : `${IMAGES_SERVER}/logos/${item.brand}.jpg`;
    return (
        <>
            <a className="oe-make-label">{item.brand}</a>
            <br />
            <a>
                <img src={imgSrc} width="60" />
            </a>
        </>
    );
};

const ItemColumnDesc = ({ original: item }: CellRenderer) => {
    return (
        <>
            <div>
                <strong className="oe-name">{item.ref}</strong>
            </div>
            <div>{item.label1}</div>
            {item.label2 && <div>{item.label2}</div>}
        </>
    );
};

const ItemColumnPrice = ({ original: item }: CellRenderer) => {
    const price = (Number(item.price.replace(",", ".")) || 0).toFixed(2);
    return (
        <div className="text-right">
            <span>{price} CHF</span>
        </div>
    );
};

const makeOE = (item: TechautoItem): OE => ({
    id: item.ref,
    label: [item.label1, item.label2].join(" ").trim(),
    ean: "",
    makeId: String(item.dlnr || ""),
    makeLabel: item.brand,
    oeNrs: [],
});

const ItemColumnCart = ({ original: item }: CellRenderer) => {
    const oe = makeOE(item);
    const { availability } = useAvailability(oe, "techauto");
    const addToCart = useAddToCart();
    const disabled =
        availability.length === 0 ||
        availability[0].disponibilite?.known === false;
    const onClick = () => addToCart(oe, "Techauto");

    return (
        <CartButton
            disabled={disabled}
            cartName="Techauto"
            onClick={onClick}
            className=""
        />
    );
};

const ItemColumnAvailability = ({ original }: CellRenderer) => {
    const oe = makeOE(original);
    return <Availability endpoint="techauto" oe={oe} />;
};

interface Props {
    items: TechautoItem[];
}

export const TechautoItemTable = ({ items }: Props): JSX.Element => {
    const { t } = useTranslation();
    const columns: Column[] = [
        {
            Header: t("item.make"),
            width: 200,
            Cell: ItemColumnMake,
            accessor: "brand",
            sortable: true,
        },
        {
            Header: t("item.desc"),
            Cell: ItemColumnDesc,
            accessor: "ref",
            sortable: true,
        },
        {
            Header: t("item.availability"),
            width: 100,
            Cell: ItemColumnAvailability,
            id: "availability",
        },
        {
            Header: t("item.price"),
            width: 150,
            Cell: ItemColumnPrice,
            accessor: (oe: OE) => oe,
            id: "price",
            sortable: true,
        },
        {
            Header: t("item.cart"),
            Cell: ItemColumnCart,
            width: 100,
            sortable: false,
        },
    ];

    return (
        <ReactTable
            data={items}
            columns={columns}
            className="ArticleTable"
            defaultPageSize={50}
            minRows={2}
            sortable={false}
        />
    );
};
