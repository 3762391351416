import * as React from "react";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";

import "./UserProfileBloc.css";

type Props = {
    className?: string;
    reduced: boolean;
    children: React.ReactNode;
};

export const UserProfileBloc = (props: Props): JSX.Element => {
    const [reduced, setReduced] = React.useState(props.reduced);

    const { children, className = "d-flex sticky-top mb-5 position-absolute" } =
        props;
    const classname = classnames("user-profile-bloc", className, { reduced });
    const toggleTimeout = React.useRef<NodeJS.Timeout>();
    const mouseoutTimeout = React.useRef<NodeJS.Timeout>();
    const mouseEnterHandler = () => {
        toggleTimeout.current && clearTimeout(toggleTimeout.current);
        mouseoutTimeout.current && clearTimeout(mouseoutTimeout.current);
        setReduced(false);
    };
    const mouseLeaveHandler = () => {
        mouseoutTimeout.current && clearTimeout(mouseoutTimeout.current);
        mouseoutTimeout.current = setTimeout(() => setReduced(true), 5000);
    };

    React.useEffect(() => {
        setReduced(props.reduced);
        toggleTimeout.current && clearTimeout(toggleTimeout.current);
        mouseoutTimeout.current && clearTimeout(mouseoutTimeout.current);
        toggleTimeout.current = global.setTimeout(() => setReduced(true), 5000);
    }, [props.reduced]);

    return (
        <CSSTransition in={!reduced} classNames="upb" timeout={500}>
            <div
                className={classname}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
            >
                {children}
            </div>
        </CSSTransition>
    );
};

export const UserNameBloc = ({ children }: { children: React.ReactNode }) => (
    <div className="user-name-bloc d-flex align-items-center">{children}</div>
);
