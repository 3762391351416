import * as React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { i18n } from "@/config/i18n";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth, useUpdateUser } from "@/context/AuthContext";
import { fetchWithAuth } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

type Props = {
    className?: string;
    imgUrl: string;
    updatePicture(filename: string): void;
    postPicture(body: FormData): Promise<string>;
};

export const ProfilePictureContent = ({
    className = "user-profile-picture",
    imgUrl,
    updatePicture,
    postPicture,
}: Props): JSX.Element => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [imgSrc, setImgSrc] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setImgSrc(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    const toggle = () => {
        setImgSrc("");
    };

    const submitPicture = async () => {
        const file = inputRef.current?.files?.[0];
        if (isSubmitting || !file) return;
        const formData = new FormData();
        formData.append("image", file);
        setIsSubmitting(true);
        try {
            const filename = await postPicture(formData);
            updatePicture(filename);
            toggle();
        } catch (error) {
            console.error(error);
            toastr.error(i18n.t("common.error"));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div
            className={className}
            role="button"
            onClick={() => inputRef.current?.click()}
        >
            <img src={imgUrl} alt="" />
            <input
                type="file"
                className="d-none"
                accept="image/*"
                ref={inputRef}
                onChange={fileChangeHandler}
            />
            <Modal isOpen={imgSrc !== ""} toggle={toggle}>
                <ModalBody>
                    <img src={imgSrc} alt="" className="mw-100" />
                </ModalBody>
                <ModalFooter>
                    <div>
                        {isSubmitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="me-4"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCheck}
                                role="button"
                                size="2x"
                                className="me-4 text-success"
                                onClick={submitPicture}
                            />
                        )}
                        <FontAwesomeIcon
                            icon={faTimes}
                            role="button"
                            onClick={toggle}
                            size="2x"
                            className="text-secondary"
                        />
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export const ProfilePicture = ({
    className = "user-profile-picture",
}: {
    className?: string;
}): JSX.Element => {
    const { userInfo } = useAuth();
    const { setUserInfo } = useUpdateUser();

    const updatePicture = (filename: string) =>
        setUserInfo({ ...userInfo, picture: filename });

    const postPicture = (body: FormData) =>
        fetchWithAuth(`set-profile-picture`, {
            method: "POST",
            body,
        })
            .then((res) => res.json())
            .then(({ filename }) => filename);

    const url = `${IMAGES_SERVER}/userDocs/profile/${userInfo.picture}`;
    return (
        <ProfilePictureContent
            className={className}
            imgUrl={url}
            postPicture={postPicture}
            updatePicture={updatePicture}
        />
    );
};
