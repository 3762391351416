import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { QRCode } from "@/components/QRCode";
import { emptyVehicleData, useVehicleData } from "@/context/VehicleDataContext";
import { IdentifyVehicle } from "@/v2/CarSelector/IdentifyVehicle";

export const CarIdentifier = ({ showQRCode }: { showQRCode: boolean }) => {
    const { path } = useRouteMatch();
    if (showQRCode) {
        return <QRCode size={220} />;
    }
    return (
        <Switch>
            <Route path={`${path}/search/:field/:value`}>
                <KnownVehicle path={path} />
            </Route>
            <Route>
                <IdentifyVehicle />
            </Route>
        </Switch>
    );
};

const KnownVehicle = ({ path }: { path: string }) => {
    const { t } = useTranslation();
    const [vehicleData, setVehicleData] = useVehicleData();
    return (
        <>
            <div className="bloc-slide-title">
                {t("caridentifier.identified-vehicle")}
            </div>
            <div className="immatriculation">
                <div className="immat-wrapper">
                    <input
                        type="text"
                        id="immat"
                        name="immat"
                        maxLength={11}
                        value={vehicleData.Immat_SIV}
                        disabled
                    />
                </div>
            </div>
            <div className="blog-slider__text">
                {t("vehicle-data.vin")}: {vehicleData.Codif_Vin_PRF}
            </div>
            <button className="blog-slider__button">
                <Link
                    to={path}
                    onClick={() => setVehicleData(emptyVehicleData)}
                >
                    {t("identification.switch-vehicle")}
                </Link>
            </button>
        </>
    );
};
