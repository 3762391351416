import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Bookmark } from "shared/dist/types/types";

import { AppsWidget } from "@/components/AppsWidget";

import { TabsBrands } from "./TabsBrands";

import "./SelectBrand.css";

import history from "@/img/history.png";
import star from "@/img/star.png";

export const SelectBrand = (): React.ReactElement => {
    const [collapseOpen, setCollapseOpen] = React.useState<string | null>(null);
    const { t } = useTranslation();

    const toggleCollapse = (collapseName: string) => (): void => {
        setCollapseOpen(collapseOpen === collapseName ? null : collapseName);
    };

    const bookmarks: Bookmark[] = JSON.parse(localStorage.bookmarks || "[]");
    const previousSearches: Bookmark[] = JSON.parse(
        localStorage.previousSearches || "[]"
    );

    return (
        <div className="selection-bloc main-bloc selection-brand select-brand">
            <div className="brand-buttons">
                <div className="marque" onClick={toggleCollapse("history")}>
                    <img src={history} alt="history" />
                    <FontAwesomeIcon icon={faHistory} />
                    <span> {t("carselector.history")}</span>
                </div>
                <div className="marque" onClick={toggleCollapse("bookmarks")}>
                    <img src={star} alt="favorites" />
                    <FontAwesomeIcon icon={faStar} />
                    <span> {t("carselector.bookmarks")}</span>
                </div>
            </div>

            <Collapse isOpen={collapseOpen != null}>
                {collapseOpen === "history" && (
                    <div className="tooltip-content history-list">
                        <ul>
                            {previousSearches.map((search, i) => (
                                <li key={i}>
                                    <Link to={search.url}>
                                        <span>{search.label}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {collapseOpen === "bookmarks" && (
                    <div className="tooltip-content bookmark-list">
                        <ul>
                            {bookmarks.map((bookmark, i) => (
                                <li key={i}>
                                    <Link to={bookmark.url}>
                                        <span>{bookmark.label}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </Collapse>
            <br />
            <TabsBrands />
            <div className="mobilit">
                <span>Mobil&apos;iT RENT VN VO VA VHU Conciergerie</span>
                <AppsWidget widget="carselector-apps" />
            </div>
        </div>
    );
};
