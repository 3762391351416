import * as React from "react";
import { Alert } from "reactstrap";

import type { ApplicabilityCars } from "shared/dist/types/levam";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { NoResults } from "@/components/NoResults";
import { useAPI, useLanguage } from "@/utils/hooks";

export const LevamApplicability = ({ refOEM }: { refOEM: string }) => {
    const lang = useLanguage();
    const { data, error } = useAPI<ApplicabilityCars>(
        `naiApplicability/${lang}/${encodeURIComponent(refOEM)}`
    );

    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    if (data.error === "part_code not found") {
        return <NoResults />;
    }
    if (data.error) {
        return <Alert color="warning">Le service a renvoyé une erreur.</Alert>;
    }

    return (
        <div>
            <h3>{refOEM}</h3>
            <ul>
                {data.result.map((brand) => (
                    <li key={brand.catalog_code}>
                        <h4>{brand.catalog_name}</h4>
                        <ul>
                            {brand.families.map((family) => (
                                <li key={family.family_name}>
                                    <h5>{family.family_name}</h5>
                                    <ul>
                                        {family.models.map((model) => (
                                            <li key={model.model_name}>
                                                <h6>{model.model_name}</h6>
                                                <img src={model.model_image} />
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
};
