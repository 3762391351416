import * as React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Alert } from "reactstrap";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { App } from "shared/dist/types/apps";
import type { OE } from "shared/dist/types/item";
import type { CompletionEntry } from "shared/dist/types/types";

import { ErrorMessage } from "@/components/ErrorMessage";
import { ItemTable } from "@/components/Item/ItemTable";
import { useAuth } from "@/context/AuthContext";
import { useAPI, useLanguage } from "@/utils/hooks";
import { usePartsBrands } from "@/utils/usePartsBrands";

import { Loading } from "../Loading";

import { PartsBreadcrumbs } from "./PartsBreadcrumbs";

import "./SearchItemContainer.css";

const { useState } = React;

const BLOC_CLASSNAME =
    "search-item-container item-bloc main-bloc selection-bloc";
const ITEMS_PER_PAGE = 50;
const MARGIN_ICON = { marginRight: 5, marginTop: 8 };

const getOENrs = (oes: OE[]): string[] => {
    const oeNrs = oes.reduce<string[]>(
        (list, oe) => list.concat(oe.oes ? oe.oes.split(",") : []),
        []
    );
    return Array.from(new Set(oeNrs)).sort();
};

type ItemsResults = { items: OE[]; nbOfResults: number };

type Params = {
    brand: string;
    family: string;
    subFamily: string;
    itemId: string;
    0: string;
};

export const PartsItemContainer = (): JSX.Element => {
    const [selectedOEs, setSelectedOEs] = useState<
        ReadonlyArray<CompletionEntry>
    >([]);
    const [activePage, setActivePage] = useState(1);
    const { data: partsOrigin } = useAPI<App[]>("widget/parts-origin");
    const { partsBrands } = usePartsBrands();

    const { t } = useTranslation();
    const lang = useLanguage();
    const { userInfo } = useAuth();
    const groups = userInfo?.stockGroups || [];

    const params = useParams<Params>();
    const { brand, itemId } = params;
    const isOE = params[0] === "pieces-oe";
    const isStock = params[0] === "pieces-stock";
    let brandId: string | number | undefined = undefined;
    const searchBrand = (item: { name: string }) => item.name === brand;
    if (isStock) {
        brandId = groups.find(searchBrand)?.groupId;
    } else if (isOE) {
        brandId = (partsOrigin || []).find(searchBrand)?.brandId || undefined;
    } else {
        brandId = partsBrands.find(searchBrand)?.id;
    }
    const partType = isOE ? "oe" : isStock ? "stock" : "all";
    const url = brandId
        ? `parts/${partType}/make/article/${lang}/${brandId}/${itemId}/${activePage}`
        : null;

    const { data, error } = useAPI<ItemsResults>(url);

    if (error || !data) {
        return (
            <div className={BLOC_CLASSNAME}>
                <PartsBreadcrumbs isOE={isOE} />
                {error ? (
                    <ErrorMessage />
                ) : (
                    <Loading messageKey="item.loading" />
                )}
            </div>
        );
    }

    if (data.items.length === 0) {
        return (
            <div className={BLOC_CLASSNAME}>
                <PartsBreadcrumbs isOE={isOE} />
                <Alert color="info">{t("common.no-results")}</Alert>
            </div>
        );
    }

    // const oes = data.items;
    const oeNrs = getOENrs(data.items);

    const handlePageChange = setActivePage;

    const handleOEChange = (oes: ReadonlyArray<CompletionEntry>): void =>
        setSelectedOEs(oes);

    const filterSelectedOEs = (oe: OE): boolean =>
        selectedOEs === null ||
        selectedOEs.length === 0 ||
        (Boolean(oe.oes) &&
            selectedOEs.some((oeNr) =>
                oe.oes?.split(",").includes(oeNr.value)
            ));

    const paginationProps = {
        activePage,
        itemsCountPerPage: ITEMS_PER_PAGE,
        totalItemsCount: data.nbOfResults,
        pageRangeDisplayed: 15,
        onChange: handlePageChange,
        innerClass: "pagination pagination-sm",
        itemClass: "page-item",
        linkClass: "page-link",
    };

    const selectOptions: CompletionEntry[] = oeNrs.map((nr) => ({
        label: nr,
        value: nr,
    }));
    const filteredOEs = data.items.filter(filterSelectedOEs);
    const count = data.nbOfResults;
    let resultTitle = t("partselector.resultsWithCount", {
        count,
    });
    if (filteredOEs.length < data.items.length) {
        resultTitle += ` (${filteredOEs.length}/${data.items.length})`;
    }

    return (
        <div className={BLOC_CLASSNAME}>
            <PartsBreadcrumbs isOE={isOE} />
            <h2>{resultTitle}</h2>
            {oeNrs.length > 1 && (
                <div className="d-flex oes-breadcrumbs">
                    <FontAwesomeIcon icon={faFilter} style={MARGIN_ICON} />
                    <Select
                        name="oes"
                        placeholder={t("item.filter-placeholder")}
                        options={selectOptions}
                        value={selectedOEs}
                        onChange={handleOEChange}
                        isMulti
                        className="flex-grow-1"
                    />
                </div>
            )}
            {count > ITEMS_PER_PAGE && count > data.items.length && (
                <Pagination {...paginationProps} />
            )}
            <ItemTable oes={filteredOEs} />
        </div>
    );
};
