import * as React from "react";
import { useTranslation } from "react-i18next";

import "./Unauthorized.css";

export const Unauthorized = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="unauthorized">
            <p>{t("common.unauthorized")}</p>
        </div>
    );
};
