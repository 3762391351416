import * as React from "react";
import type { OnSuggestionSelected } from "react-autosuggest";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { faMotorcycle, faTruckMoving } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { getContent } from "@/utils/fetch";
import { getLogoURL, replaceIgnoreCase } from "@/utils/utils";

type VehicleSuggestion = CompletionEntry & {
    ktype: string;
    make: string;
    model: string;
    version: string;
    type: "vl" | "pl" | "moto";
};

const autocompleteAll = (input: string): Promise<VehicleSuggestion[]> =>
    getContent(`completion/version/${encodeURIComponent(input)}`);

const autocompleteModel =
    (make: string, vehicleType: string) =>
    (input: string): Promise<VehicleSuggestion[]> =>
        getContent(
            `completion/${
                vehicleType === "pl" ? "plVersion" : "version"
            }/${encodeURIComponent(make)}/${encodeURIComponent(input)}`
        );

const getIcon = (type: VehicleSuggestion["type"]) =>
    ({
        vl: "car" as const,
        moto: faMotorcycle,
        pl: faTruckMoving,
    }[type]);

export const renderSuggestion = (
    suggestion: Partial<VehicleSuggestion>,
    { query }: { query: string }
): JSX.Element => (
    <div className="d-flex align-items-center">
        {suggestion.make && (
            <div className="img-app-small me-2">
                <img
                    src={getLogoURL(
                        suggestion.make,
                        suggestion.type === "moto" ? "motos" : suggestion.type
                    )}
                    alt=""
                />
            </div>
        )}
        <div>
            <span
                dangerouslySetInnerHTML={{
                    __html: replaceIgnoreCase(
                        suggestion.label ?? "",
                        query,
                        '<span class="autosuggest-query">' + query + "</span>"
                    ),
                }}
            />
            {suggestion.type && (
                <FontAwesomeIcon
                    icon={getIcon(suggestion.type)}
                    className="ms-2"
                />
            )}
        </div>
    </div>
);

type Props = {
    model: boolean;
    show?: boolean;
};

export const CarSelectorSearch = ({
    model,
    show = true,
}: Props): JSX.Element => {
    const [value, setValue] = React.useState("");
    const history = useHistory();
    const { t } = useTranslation();
    const { make, vtype: vehicleType } = useParams<{
        make: string;
        vtype: string;
    }>();

    const onChange = (
        _event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => {
        setValue(newValue);
    };

    const onSuggestionSelected: OnSuggestionSelected<VehicleSuggestion> = (
        evt,
        { suggestion }
    ) => {
        const url = `/selection-vehicule/${suggestion.type}/${suggestion.make}/${suggestion.model}/${suggestion.version}/${suggestion.ktype}`;
        history.push(url);
        setValue("");
    };

    const autocomplete = model
        ? autocompleteModel(make, vehicleType)
        : autocompleteAll;
    const placeholder = model
        ? t("carselector.search-model-placeholder")
        : t("carselector.search-brand-placeholder");

    return (
        <div
            className={classnames("car-selector-search", { ["d-none"]: !show })}
        >
            <Autocomplete
                value={value}
                onChange={onChange}
                getSuggestions={autocomplete}
                placeholder={placeholder}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                prefix={make}
            />
        </div>
    );
};

CarSelectorSearch.defaultProps = {
    model: false,
};
