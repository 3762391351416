import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    faCartPlus,
    faEdit,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Genart } from "shared/dist/types/types";
import type { CustomSearch } from "shared/dist/types/userSettings";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { DTC_IMAGE } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import {
    CUSTOM_SEARCH_IMG_PATH,
    DEFAULT_IMG_URL,
} from "@/pages/CarSelector/CustomSearches";
import { getContent } from "@/utils/fetch";
import { useGenarts } from "@/utils/hooks";
import { isDefined } from "@/utils/utils";

import { ItemContainer } from "./Item/ItemContainer";
import { Loading } from "./Loading";
import { NewCustomSearch } from "./NewCustomSearch";
import { getPhotoPath, PHOTOS_ROOT_URL } from "./Photo360";

import "./GenartList.css";

const { useState, useEffect } = React;

const GuiiImage = ({
    vehicleData,
    photoNb,
}: {
    vehicleData: VehicleData;
    photoNb: string;
}) => {
    const [photosPath, setPhotoPath] = useState("");

    useEffect(() => {
        const isPL = Boolean(vehicleData.ntypnr);
        getPhotoPath(vehicleData, isPL)
            .then((path) => path && setPhotoPath(path))
            .catch((error) => console.error(error));
    }, [vehicleData]);

    if (!photosPath) return null;

    const src = `${PHOTOS_ROOT_URL}${photosPath}${photoNb}.jpg`;
    return <img src={src} alt="" />;
};

type Params = {
    ktype: string | undefined;
    hmdnr: string;
    family: string;
    subFamily: string;
    categoryId: string;
    itemId: string;
    name: string;
    field: string;
    value: string;
    genartIds: string;
};

type Props = {
    setGenartsForDeal: (ids: string[]) => void;
};

export const GenartList = (props: Props): JSX.Element => {
    const { genartsMap } = useGenarts();
    const [partsSettingsModalOpen, setPartsSettingsModalOpen] = useState(false);
    const [customSearches, setCustomSearches] = useState<CustomSearch[]>([]);
    const { t } = useTranslation();
    const history = useHistory();
    const [vehicleData] = useVehicleData();
    const genartListRef = React.useRef<HTMLUListElement>(null);
    const origParams = useParams<Params>();
    const origMatch = useRouteMatch();

    const togglePartsSettingsModal = (): void => {
        setPartsSettingsModalOpen((open) => !open);
    };

    const openPartsSettingsModal = (): void => {
        setPartsSettingsModalOpen(true);
    };

    useEffect(() => {
        getContent<CustomSearch[]>("custom-searches")
            .then(setCustomSearches)
            .catch((error) => {
                console.error(error);
            });
    }, []);

    if (!genartsMap[1]) {
        return (
            <div className="genart-list">
                {" "}
                <Loading />{" "}
            </div>
        );
    }

    const genartIds = origParams.genartIds.split("-");

    const itemId = Number(origParams.itemId);
    const { name } = origParams;
    const displayEditButton =
        !name.startsWith("Guii-") && name !== "from-breadcrumb";
    const genartData = genartsMap[itemId] || {
        family: undefined,
        subFamily: undefined,
        categoryId: undefined,
    };

    const params = {
        ...origParams,
        family: genartData.family,
        subFamily: genartData.subFamily,
        categoryId: String(genartData.categoryId),
    };
    const match = { ...origMatch, params };
    const tokens = origMatch.url.split("/");
    tokens.pop();
    const path = tokens.join("/");
    const { ktype, Marque: brand, Modèle: model } = vehicleData;
    const genartsWithLabels: Genart[] = genartIds.map((value) => {
        const genart = genartsMap[Number(value)];
        return {
            value: Number(value),
            label: genart?.label || value,
            family: genart?.family ?? "",
            subFamily: genart?.subFamily ?? "",
            categoryId: genart?.categoryId,
        };
    });

    const selectGenartsMode = ["operation", "MID"].includes(match.params.field);

    const goToGenartSelectorDeal = (): void => {
        const { value: opuid, field } = origParams;
        const ul = genartListRef.current;
        if (!ul) {
            return;
        }
        const checkedInputs: NodeListOf<HTMLInputElement> = ul.querySelectorAll(
            ".genart-checkbox:checked"
        );
        const ids = Array.from(checkedInputs)
            .map((input) => input.dataset.genartid)
            .filter(isDefined);
        props.setGenartsForDeal(ids);

        history.push(`/identification-vehicule/search/${field}/${opuid}`);
    };

    const customSearch = customSearches.find((search) => search.name === name);

    const photoNb = name.match(/^Guii-(\d+)$/)?.[1] ?? null;

    return (
        <div className="genart-list">
            <div className="genart-list-select">
                {customSearch && (
                    <img
                        src={
                            customSearch.image
                                ? CUSTOM_SEARCH_IMG_PATH + customSearch.image
                                : DEFAULT_IMG_URL
                        }
                        height="80"
                        alt=""
                    />
                )}
                {name === "dtc" && <img src={DTC_IMAGE} height="80" alt="" />}
                {name === "from-breadcrumb" && (
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="custom-search-breadcrumb-image"
                    />
                )}
                {photoNb !== null && (
                    <GuiiImage vehicleData={vehicleData} photoNb={photoNb} />
                )}
                <ul ref={genartListRef}>
                    {genartIds.map((genartId) => (
                        <li key={genartId}>
                            {selectGenartsMode && (
                                <input
                                    type="checkbox"
                                    className="genart-checkbox"
                                    data-genartid={genartId}
                                />
                            )}
                            <Link
                                to={`${path}/${genartId}`}
                                className={
                                    genartId === origParams.itemId
                                        ? "selected"
                                        : ""
                                }
                            >
                                {genartsMap[Number(genartId)]?.label ||
                                    genartId}
                            </Link>
                        </li>
                    ))}
                    {displayEditButton && (
                        <li>
                            <Button
                                color="primary"
                                title="Modifier la recherche"
                                onClick={openPartsSettingsModal}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            {selectGenartsMode && (
                                <Button
                                    color="success"
                                    className="deal-cart-button"
                                    onClick={goToGenartSelectorDeal}
                                >
                                    <FontAwesomeIcon icon={faCartPlus} />
                                    <span>DEAL</span>
                                </Button>
                            )}
                        </li>
                    )}
                </ul>
            </div>
            <ItemContainer
                match={match}
                vehicleData={vehicleData}
                ktype={ktype}
                brand={brand}
                model={model}
                itemId={String(itemId)}
            />
            <Modal
                isOpen={partsSettingsModalOpen}
                toggle={togglePartsSettingsModal}
                size="lg"
            >
                <ModalHeader toggle={togglePartsSettingsModal}>
                    {t("partsettings.modal-title")}
                </ModalHeader>
                <ModalBody>
                    <NewCustomSearch
                        close={togglePartsSettingsModal}
                        name={params.name}
                        genart={genartsWithLabels}
                        update
                    />
                </ModalBody>
            </Modal>
        </div>
    );
};
