import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";

import { useAPI } from "@/utils/hooks";

import { getCurrentImageIndex } from "./lib/threesixty";

type GenartId = { id360: number; item_ids: string };

export const Button360IAM = (): JSX.Element => {
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();

    const { data: genartIds } = useAPI<GenartId[]>("genart360ids", {
        initialData: [],
    });

    const handleClick = (): void => {
        const photoNb = getCurrentImageIndex();
        if (genartIds.length > 0 && photoNb > 0) {
            const genarts = genartIds[photoNb - 1].item_ids.split(",");
            const newURL = `${match.url}/search/Guii/${genarts.join("-")}/${
                genarts[0]
            }`;
            history.push(newURL === location.pathname ? match.url : newURL);
        }
    };

    return (
        <Button className="button-360 button-360-iam" onClick={handleClick}>
            360° IAM
        </Button>
    );
};
