import { apiRootURL } from "@/config/settings";

export const postPublic = (path: string, data: unknown): Promise<Response> => {
    const url = `${apiRootURL}/api/${path}`;
    return fetch(url, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
    });
};
