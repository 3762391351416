import * as React from "react";

import { getContent } from "@/utils/fetch";

import { useAuth } from "./AuthContext";

type BrandStats = {
    stats: { make: string; nb: number; percent: string }[];
    total: number;
};

type BrandStatsContext = [
    BrandStats,
    React.Dispatch<React.SetStateAction<BrandStats>>
];

const StatsContext = React.createContext<BrandStatsContext | null>(null);

export const StatsProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [stats, setStats] = React.useState<BrandStats>({
        stats: [],
        total: 0,
    });
    const { hasAccess } = useAuth();

    React.useEffect(() => {
        if (hasAccess("statsBadges")) {
            getContent<BrandStats>("brandStats").then((data) => setStats(data));
        }
    }, [hasAccess]);

    return (
        <StatsContext.Provider value={[stats, setStats]}>
            {children}
        </StatsContext.Provider>
    );
};

export const useStatsContext = (): BrandStatsContext => {
    const context = React.useContext(StatsContext);
    if (context === null) {
        throw new Error("useStatsContext must be used within a StatsProvider");
    }
    return context;
};
