import * as React from "react";

import { HUB_IDLP_HOST } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";

type HubIdlpContext = [string | null, (search: string) => void];

const HubIdlpContext = React.createContext<HubIdlpContext>([
    null,
    (): void => {},
]);

export const HubIdlpProvider = (props: {
    children: React.ReactNode;
}): JSX.Element => {
    const hubidlp = React.useState<string | null>(null);
    return (
        <HubIdlpContext.Provider value={hubidlp}>
            {props.children}
        </HubIdlpContext.Provider>
    );
};

export const useHubidlp = (): {
    url: string;
    setSearch: (search: string) => void;
} => {
    const context = React.useContext(HubIdlpContext);
    const { userInfo } = useAuth();
    if (context === undefined) {
        throw new Error("useHubidlp must be used within a HubIdlpProvider");
    }
    const baseUrl = `${HUB_IDLP_HOST}/C@iA-${userInfo.userId}`;
    const [search, setSearch] = context;
    const url =
        search == null ? baseUrl : `${baseUrl}/${encodeURIComponent(search)}`;
    return { url, setSearch };
};
