import * as React from "react";

import { VehicleDataBloc } from "@/components/VehicleDataBloc";
import { apiRootURL } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import { getModelImage } from "@/utils/utils";

const DEFAULT_IMAGE_URL = `${apiRootURL}/media/models/default.jpg`;
const setDefaultImage = (
    event: React.SyntheticEvent<HTMLImageElement>
): void => {
    event.currentTarget.src = DEFAULT_IMAGE_URL;
};

const VehicleDataToggler = (): JSX.Element => {
    const [folded, setFolded] = React.useState(true);
    const toggle = (): void => setFolded((f) => !f);

    const [vehicleData] = useVehicleData();

    const modelImage =
        vehicleData.Marque && vehicleData.Modèle
            ? getModelImage(vehicleData.Marque, vehicleData.Modèle)
            : DEFAULT_IMAGE_URL;

    return (
        <div className="vehicle-data-toggler">
            {!folded && <VehicleDataBloc />}
            <button
                type="button"
                className="vehicle-data-button"
                onClick={toggle}
            >
                <img src={modelImage} alt="car" onError={setDefaultImage} />
            </button>
        </div>
    );
};

export default VehicleDataToggler;
