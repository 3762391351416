import * as React from "react";
import { useTranslation } from "react-i18next";

declare global {
    const TogetherJS: () => void;
}

const toggleTogetherJS = () => TogetherJS();

export const SupportButton = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <button className="btn" onClick={toggleTogetherJS}>
            {t("header.support")}
        </button>
    );
};
