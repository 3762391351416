import * as React from "react";

type Criteria = {
    hide: boolean;
};

export type CriteriaContext = [Criteria, (state: Criteria) => void];
export const CriteriaContext = React.createContext<CriteriaContext>([
    { hide: false },
    (): void => {},
]);

export const CriteriaProvider = (props: {
    children: React.ReactNode;
}): JSX.Element => {
    const criterias = React.useState({ hide: false });
    return (
        <CriteriaContext.Provider value={criterias}>
            {props.children}
        </CriteriaContext.Provider>
    );
};
