import * as React from "react";
import { NavItem as ReactstrapNavItem, NavLink } from "reactstrap";
import classnames from "classnames";

type Props = {
    toggleTab: (tabId: string) => void;
    activeTab: string;
    tabId: string;
    tabName?: string;
};

const NavItem = ({
    tabId,
    activeTab,
    toggleTab,
    tabName = tabId,
}: Props): JSX.Element => {
    const onClick = React.useCallback(() => {
        toggleTab(tabId);
    }, [tabId, toggleTab]);
    return (
        <ReactstrapNavItem key={tabId}>
            <NavLink
                className={classnames({ active: activeTab === tabId })}
                onClick={onClick}
            >
                {tabName}
            </NavLink>
        </ReactstrapNavItem>
    );
};

const memoNavItem = React.memo(NavItem);

export { memoNavItem as NavItem };
