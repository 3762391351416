import * as React from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { PageApp } from "./AppsContext";
import { formatDate } from "./common";
import { EditAppWizard } from "./EditAppWizard";
import { NewAppWizard } from "./NewAppWizard";

type WidgetProps = {
    apps: PageApp[];
    name: string;
    label: string;
    setApps: React.Dispatch<React.SetStateAction<PageApp[]>>;
    children?: React.ReactNode;
};

export const Widget = ({
    name,
    apps,
    label,
    setApps,
    children,
}: WidgetProps) => {
    return (
        <div className="mb-4">
            <h3>
                {label}
                {children}
            </h3>
            <div className="d-flex flex-wrap overflow-hidden">
                {apps.map((app) => (
                    <EditableApp
                        app={app}
                        key={app.id}
                        label={label}
                        setApps={setApps}
                    />
                ))}
                <NewAppButton widget={name} label={label} setApps={setApps} />
            </div>
        </div>
    );
};
const DateBadge = ({ app }: { app: PageApp }) => {
    const ref = React.useRef<HTMLElement>(null);
    if (!app.dateFrom && !app.dateTo) {
        return null;
    }
    return (
        <>
            <Badge
                color="primary"
                className="badge-date position-absolute"
                innerRef={ref}
            >
                <FontAwesomeIcon icon={faClock} />
            </Badge>
            <UncontrolledTooltip target={ref}>
                {app.dateFrom && <p>Date début: {formatDate(app.dateFrom)}</p>}
                {app.dateTo && <p>Date fin: {formatDate(app.dateTo)}</p>}
            </UncontrolledTooltip>
        </>
    );
};

const EditableApp = ({
    app,
    label,
    setApps,
}: {
    app: PageApp;
    label: string;
    setApps: WidgetProps["setApps"];
}) => {
    const [modalOpen, toggle] = React.useReducer((o) => !o, false);
    return (
        <>
            <button
                className="marque new-app position-relative"
                onClick={toggle}
            >
                {app.img && (
                    <img src={app.img} alt={app.name} title={app.name} />
                )}
                <DateBadge app={app} />
            </button>
            {modalOpen && (
                <EditAppWizard
                    toggle={toggle}
                    app={app}
                    isOpen={modalOpen}
                    widgetLabel={label}
                    setApps={setApps}
                />
            )}
        </>
    );
};

const NewAppButton = ({
    widget,
    label,
    setApps,
}: {
    widget: string;
    label: string;
    setApps: WidgetProps["setApps"];
}) => {
    const [modalOpen, toggle] = React.useReducer((o) => !o, false);
    return (
        <>
            <button className="marque new-app" type="button" onClick={toggle}>
                <FontAwesomeIcon icon={faPlus} size="2x" />
            </button>
            <NewAppWizard
                widget={widget}
                toggle={toggle}
                isOpen={modalOpen}
                widgetLabel={label}
                setApps={setApps}
            />
        </>
    );
};
