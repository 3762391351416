import * as React from "react";
import { Link } from "react-router-dom";

import type { Vtype } from "shared/dist";

import { getLogoURL } from "@/utils/utils";

type Props = {
    brand: string;
    url: string;
    vType?: Vtype;
};

export const BrandItem = ({ brand, url, vType = "vl" }: Props): JSX.Element => {
    const brandLogo = getLogoURL(brand, vType);
    return (
        <div className="breadcrumb-img breadcrumb-img-brand">
            <Link to={url}>
                <img src={brandLogo} alt={brand} />
            </Link>
        </div>
    );
};
