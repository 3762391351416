import * as React from "react";

import { Restricted } from "@/components/Restricted";
import { Widget } from "@/pages/RSEEditor/Widget";
import { RoleGroupsProvider } from "@/pages/RSEEditor/RoleGroupContext";

import {
    AppsProvider,
    LMS_WIDGETS,
    useAppsContext,
    WIDGET_LABELS,
} from "./LMSAppsContext";

import "@/pages/RSEEditor/RSEEditor.css";

const ALL_LMS_WIDGETS = [...LMS_WIDGETS, "lms-initial-slides"] as const;

const WidgetList = () => {
    const [apps, setApps] = useAppsContext();
    return (
        <>
            {ALL_LMS_WIDGETS.map((widget) => (
                <Widget
                    name={widget}
                    apps={apps.filter((app) => app.widget === widget)}
                    key={widget}
                    label={WIDGET_LABELS[widget]}
                    setApps={setApps}
                />
            ))}
        </>
    );
};

const LMSEditorWrapper = (): JSX.Element => {
    return (
        <div className="lms-editor flex-grow-1 d-flex flex-column mt-5 bg-light m-2 p-3 position-relative">
            <h1>LMS Editor</h1>
            <div className="overflow-auto">
                <RoleGroupsProvider>
                    <AppsProvider>
                        <WidgetList />
                    </AppsProvider>
                </RoleGroupsProvider>
            </div>
        </div>
    );
};

export const LMSEditor = (): JSX.Element => (
    <Restricted access="LMSEditor">
        <LMSEditorWrapper />
    </Restricted>
);
