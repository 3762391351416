import type * as React from "react";
import { differenceInYears, parse } from "date-fns";

import type { SirenData } from "shared/dist/types/siren";

import { i18n } from "@/config/i18n";
import { getContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

const getAge = (date: string) => {
    let age = null;
    try {
        const now = new Date();
        const dateCreation = parse(date, "yyyy-MM-dd", now);
        age = differenceInYears(now, dateCreation);
    } catch (error) {
        // ignore
    }
    return age;
};
const formatData = (data: SirenData): React.ReactElement => {
    const age = getAge(data.dateCreationEtablissement);
    const ageLabel = age ? ` (${age} ans)` : "";
    return (
        <>
            <h2>Renseignements juridiques</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Raison sociale</td>
                        <td>{data.denominationUsuelleEtablissement}</td>
                    </tr>
                    <tr>
                        <td>Siren</td>
                        <td>{data.siren}</td>
                    </tr>
                    <tr>
                        <td>Nic</td>
                        <td>{data.nic}</td>
                    </tr>
                    <tr>
                        <td>Siret</td>
                        <td>{data.siret}</td>
                    </tr>
                    <tr>
                        <td>Activité principale</td>
                        <td>{data.activitePrincipaleEtablissement}</td>
                    </tr>
                    <tr>
                        <td>Nomenclature d&rsquo;activité</td>
                        <td>
                            {data.nomenclatureActivitePrincipaleEtablissement}
                        </td>
                    </tr>
                    <tr>
                        <td>Date de création</td>
                        <td>
                            {data.dateCreationEtablissement}
                            {ageLabel}
                        </td>
                    </tr>
                    <tr>
                        <td>Date de début d&rsquo;exercice</td>
                        <td>{data.dateDebut}</td>
                    </tr>
                    <tr>
                        <td>Adresse</td>
                        <td>
                            {data.numeroVoieEtablissement}{" "}
                            {data.typeVoieEtablissement}{" "}
                            {data.libelleVoieEtablissement}
                            <br />
                            {data.codePostalEtablissement}{" "}
                            {data.libelleCommuneEtablissement}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export const showLegalInfos = (siren: string): void => {
    getContent<SirenData>(`siren/${siren}`)
        .then((data) => {
            if (Object.keys(data).length === 0) {
                toastr.warning(
                    i18n.t("common.no-results-for", { search: siren })
                );
                return;
            }
            console.log(data);
            toastr.info(formatData(data), {
                closeButton: true,
                autoClose: false,
                className: "wide-toast",
            });
        })
        .catch((error) => {
            console.error(error);
            const message = i18n.t("identification.toast.error", {
                value: siren,
            });
            toastr.error(message);
        });
};
