import type * as React from "react";
import { faFileCsv, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import format from "date-fns/format";

import { i18n } from "@/config/i18n";
import { fetchWithAuth } from "@/utils/fetch";
import { printElements } from "@/utils/printElements";
import { toastr } from "@/utils/toastr";

import logoEyes from "@/img/logo_eyes.png";

const downloadCSV = (csvStr: string, name = "stats.csv") => {
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
    hiddenElement.target = "_blank";
    hiddenElement.download = name;
    hiddenElement.click();
};

const getCSVStats = async () => {
    const response = await fetchWithAuth("csvStats");
    const data = await response.text();
    const date = format(new Date(), "yyyy-MM-dd_HH-mm");
    const filename = `sv5Actia-${date}.csv`;
    downloadCSV(data, filename);
};

export const DashboardHeader = ({
    dashboard,
}: {
    dashboard: React.RefObject<HTMLElement>;
}): JSX.Element => {
    const print = () => {
        if (dashboard.current) {
            printElements([dashboard.current]);
        }
    };
    const getCSV = async () => {
        try {
            await getCSVStats();
        } catch (error) {
            toastr.error(i18n.t("common.error"));
        }
    };
    return (
        <div className="dashboard-header">
            <img src={logoEyes} alt="C@iA" width="100" />
            <span className="ms-2">
                Node Auto Infrastructure | .: monitoring API Diag OBD :.
            </span>
            <button
                type="button"
                className="btn btn-outline-light border-0 ms-auto me-2"
                onClick={getCSV}
            >
                <FontAwesomeIcon icon={faFileCsv} />
            </button>
            <button
                type="button"
                className="btn btn-outline-light border-0 me-2"
                onClick={print}
            >
                <FontAwesomeIcon icon={faPrint} />
            </button>
        </div>
    );
};
