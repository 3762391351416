import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    UncontrolledTooltip,
} from "reactstrap";
import { faBan, faPaperPlane, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { OE } from "shared/dist/types/item";

import { useAuth } from "@/context/AuthContext";
import { ItemAvailability, useAvailability } from "@/utils/availability";
import { postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";
import { makeHubidlpURL } from "@/utils/utils";

import { useAddItemSlide } from "./ItemSlides";

import "./Availability.css";

type AvailabilityCircleProps = {
    quantity?: number;
    from?: string;
    text?: string;
    availability?: Partial<ItemAvailability>;
    itemRef?: string;
    children?: React.ReactNode;
};

export const AvailabilityCircle = (
    props: AvailabilityCircleProps
): React.ReactElement => {
    const { t } = useTranslation();
    const { hasAccess, userInfo } = useAuth();
    const { addIframeSlide } = useAddItemSlide();
    const ref = React.useRef<HTMLDivElement>(null);
    const { quantity, from, text, availability = {}, itemRef } = props;
    const data: Partial<ItemAvailability> = {
        quantite: quantity,
        from,
        texte: text,
        ...availability,
    };
    const available = (data.quantite ?? 0) > 0;
    const className =
        data.status === "ko"
            ? "availability-error"
            : data.known || data.texte
            ? available
                ? "available"
                : "unavailable"
            : "unknown";
    const stocks = (data.stocks || []).map((stock, i) => (
        <div key={i}>
            <span>{stock.name}</span>: {stock.qty}
        </div>
    ));
    const title = data.status === "ko" ? t("item.availability-error") : text;

    const onClickHandler = () => {
        if (!itemRef) {
            return;
        }
        addIframeSlide(makeHubidlpURL(userInfo.userId, itemRef));
    };
    const showButton =
        data.status !== "ko" &&
        hasAccess("HubIdlp") &&
        /^(IDLP|PAP)/.test(data.from || "");
    return (
        <div className="oe-availability">
            <div className={"availability " + className} ref={ref}>
                {data.status === "ko" && (
                    <FontAwesomeIcon
                        icon={faBan}
                        title={title}
                        className="w-100 h-100"
                    />
                )}
                {showButton && (
                    <Button
                        color="link"
                        className="p-0"
                        onClick={onClickHandler}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                )}
            </div>
            {props.children}
            <UncontrolledTooltip target={ref}>
                <div>{data.from}</div>
                <div>{data.texte}</div>
                {stocks}
                {data.date && (
                    <div>
                        Le {data.date} à {data.heure}
                    </div>
                )}
                {data.site && <div>{data.site}</div>}
            </UncontrolledTooltip>
        </div>
    );
};

type AvailabilityProps = {
    oe: Partial<OE>;
    endpoint?: string;
};

export const Availability = ({
    oe,
    endpoint,
}: AvailabilityProps): JSX.Element | null => {
    const { availability } = useAvailability(oe, endpoint);
    if (oe.from === "caia") {
        return (
            <AvailabilityCircle
                quantity={oe.stock}
                from="C@iA"
                text={oe.partType || "Local"}
            />
        );
    }
    let localAvailability = null;
    if (oe.itemType === "clone") {
        localAvailability = (
            <AvailabilityCircle
                quantity={oe.stock}
                from="C@iA"
                text={oe.partType || "Local"}
            />
        );
    }
    if (oe.makeId === "REPARCAR") {
        localAvailability = (
            <AvailabilityCircle quantity={1} from="SivinCE" text="Enseigne" />
        );
    }
    if (oe.makeId === "GPA26") {
        return (
            <>
                <AvailabilityCircle quantity={1} from="GPA26" text="Enseigne" />
                <AvailabilityCircle
                    quantity={1}
                    from="SivinCE"
                    text="Enseigne"
                />
            </>
        );
    }
    if (oe.makeId === "Indra") {
        return <AvailabilityCircle quantity={1} from="Indra" text="Enseigne" />;
    }
    if (!availability) {
        return localAvailability;
    }
    return (
        <>
            {localAvailability}
            {availability.map((data, i) => (
                <AvailabilityCircle
                    key={i}
                    availability={{
                        ...data.disponibilite,
                        from: data.from,
                        status: data.status,
                    }}
                    itemRef={oe.id}
                />
            ))}
        </>
    );
};

type FormData = {
    price: string;
    amount: string;
    partType: string;
    stockGroup: string;
};

type NewCaiaItemFormProps = {
    item: OE;
    close: () => void;
    onSuccessfullAdd: (formData: FormData) => void;
};

const NewCaiaItemForm = ({
    item,
    close,
    onSuccessfullAdd = () => {},
}: NewCaiaItemFormProps) => {
    const { register, handleSubmit } = useForm<FormData>();
    const { t } = useTranslation();
    const { userInfo } = useAuth();

    const groups = (userInfo.stockGroups || []).filter(
        (group) => group.canUpdate === 1
    );

    const onSubmit = (formData: FormData): void => {
        // Don't send uneeded item data
        const { mediaDtos, ...cleanedItem } = item;
        postContent("cloneItem", { item: cleanedItem, formData })
            .then(() => {
                toastr.success(
                    t("new-item-wizard.toast.success", { ref: item.id })
                );
                onSuccessfullAdd(formData);
            })
            .catch((error) => {
                console.error(error);
                toastr.error(
                    t("new-item-wizard.toast.failure", { ref: item.id })
                );
            });
    };
    const { ref: priceRef, ...priceProps } = register("price", {
        required: true,
    });
    const { ref: amountRef, ...amountProps } = register("amount");
    const { ref: partTypeRef, ...partTypeProps } = register("partType");
    const { ref: stockGroupRef, ...stockGroupProps } = register("stockGroup");

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="add-caia-item-form">
            <div className="content">
                <strong className="oe-name">{item.id}</strong>
                {item.genArt && <p>{item.genArt}</p>}
                <FormGroup row>
                    <Label sm={4} for="price">
                        {t("new-item-wizard.price-label")}
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="number"
                            {...priceProps}
                            id="price"
                            innerRef={priceRef}
                            step="0.01"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={4} for="amount">
                        {t("new-item-wizard.quantity-label")}
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="number"
                            {...amountProps}
                            id="amount"
                            innerRef={amountRef}
                            defaultValue={1}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={4} for="partType">
                        Type de pièce
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="select"
                            {...partTypeProps}
                            id="partType"
                            innerRef={partTypeRef}
                        >
                            <option>neuve</option>
                            <option>réusine</option>
                            <option>retour</option>
                            <option>consigne</option>
                            <option>économie circulaire</option>
                            <option>export</option>
                            <option>déchet</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className={groups.length > 1 ? "" : "d-none"}>
                    <Label sm={4} for="stockGroup">
                        Group
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="select"
                            {...stockGroupProps}
                            id="stockGroup"
                            innerRef={stockGroupRef}
                        >
                            {groups.map((group) => (
                                <option
                                    key={group.groupId}
                                    value={group.groupId}
                                >
                                    {group.name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
            </div>
            <div className="footer">
                <Button color="secondary" onClick={close} type="button">
                    {t("common.cancel")}
                </Button>
                <Button color="primary">{t("common.submit")}</Button>
            </div>
        </form>
    );
};

type AvailabilityContainerProps = {
    oe: OE;
    onClonedItem?: () => void;
};

export const AvailabilityContainer = ({
    oe,
    onClonedItem = () => {},
}: AvailabilityContainerProps): JSX.Element => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [isClone, setIsClone] = React.useState(oe.itemType === "clone");
    const { userInfo } = useAuth();

    const toggle = (): void => setModalOpen((open) => !open);
    const { t } = useTranslation();

    const isLocal = oe.from === "caia" || isClone;
    const onSuccessfullAdd = (data: FormData): void => {
        setModalOpen(false);
        oe.itemType = "clone";
        oe.stock = Number(data.amount);
        oe.price = Number(data.price);
        oe.partType = data.partType;
        setIsClone(true);
        onClonedItem();
    };
    const groups = (userInfo?.stockGroups || []).filter(
        (group) => group.canUpdate === 1
    );
    const showAddButton = !isLocal && groups.length > 0;

    return (
        <div>
            {showAddButton && (
                <Button className="add-caia-item-button" onClick={toggle}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            )}
            <Modal isOpen={modalOpen} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>
                    {t("new-item-wizard.title")}
                </ModalHeader>
                <ModalBody>
                    <NewCaiaItemForm
                        item={oe}
                        close={toggle}
                        onSuccessfullAdd={onSuccessfullAdd}
                    />
                </ModalBody>
            </Modal>
            <Availability oe={oe} />
        </div>
    );
};
