import * as React from "react";

import { postContent } from "@/utils/fetch";

import { ErrorMessage } from "./ErrorMessage";

type Props = {
    className?: string;
    children: React.ReactNode;
};

export class ErrorBoundary extends React.Component<Props> {
    state = { hasError: false };

    static getDerivedStateFromError(): { hasError: boolean } {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
        console.error(error, info.componentStack);
        const errorString =
            typeof error === "string"
                ? error
                : error && typeof error.toString === "function"
                ? error.toString()
                : JSON.stringify(error);
        postContent("error", {
            pathname: window.location.href,
            error: errorString,
            stack: info.componentStack,
            userAgent: navigator.userAgent,
        }).catch(() => {
            console.error("Unable to send error to the backend service.");
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={this.props.className}>
                    <ErrorMessage />
                </div>
            );
        }

        return this.props.children;
    }
}
