import * as React from "react";
import Select, { createFilter, GroupBase } from "react-select";
import type { SelectComponents } from "react-select/dist/declarations/src/components";
import { Input, Label } from "reactstrap";

import type { User } from "shared/dist/types/auth";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { MenuList } from "@/components/OptimizedMenuList";
import { Restricted } from "@/components/Restricted";
import { useAPI } from "@/utils/hooks";

import { AccessRights } from "./AccessRights";
import { AddCompany } from "./AddCompany";
import { AddUserButton } from "./AddUser";
import type { UserOption } from "./types";
import { UserCodes } from "./UsersCode";

type CustomComponents = Partial<
    SelectComponents<UserOption, false, GroupBase<UserOption>>
>;
const selectComponents: CustomComponents = { MenuList };
const filterOption = createFilter({ ignoreAccents: false });

const SelectUsers = ({
    users,
    onChange,
}: {
    users: UserOption[];
    onChange(user: UserOption | null): void;
}) => {
    return (
        <Select
            options={users}
            onChange={onChange}
            components={selectComponents}
            filterOption={filterOption}
            isSearchable
            isClearable
            className="flex-grow-1"
        />
    );
};

const LoadUsers = ({
    onChange,
}: {
    onChange(user: UserOption | null): void;
}) => {
    const { data, error } = useAPI<User[]>("users");
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    const users: UserOption[] = data.map((user) => ({
        ...user,
        value: String(user.userId),
        label: `${user.name} - ${user.email}`,
    }));

    return <SelectUsers users={users} onChange={onChange} />;
};

const SelectCompany = ({
    companies,
    onChange,
    children,
}: {
    companies: User["companies"];
    onChange: (companyId: number) => void;
    children: JSX.Element;
}) => {
    if (companies.length === 1) {
        return (
            <div className="mt-2">
                Société: {companies[0].name}
                {children}
            </div>
        );
    }
    return (
        <div className="mt-2 d-flex align-items-center">
            <Label htmlFor="selectCompany" className="mb-0">
                Société:
            </Label>
            <Input
                type="select"
                id="selectCompany"
                className="ms-2"
                onChange={(event) =>
                    onChange(Number(event.currentTarget.value))
                }
            >
                {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                        {company.name}
                    </option>
                ))}
            </Input>
            {children}
        </div>
    );
};

const UsersAdminWrapper = (): JSX.Element => {
    const [user, setUser] = React.useState<UserOption | null>(null);
    const [company, setCompany] = React.useState<number | null>(null);
    const onUserChange = (newUser: UserOption | null) => {
        if (newUser) {
            setUser(newUser);
            setCompany(newUser.companies[0].id);
        } else {
            setUser(null);
            setCompany(null);
        }
    };
    return (
        <div className="users-admin flex-grow-1 d-flex mt-5 bg-light m-2 p-3 flex-column">
            <h1>Gestion des utilisateurs</h1>
            <div className="d-flex flex-column overflow-hidden h-100">
                <div className="d-flex">
                    <LoadUsers onChange={onUserChange} />
                    <AddUserButton />
                </div>
                {user && (
                    <>
                        <SelectCompany
                            companies={user.companies}
                            onChange={setCompany}
                        >
                            <AddCompany user={user} />
                        </SelectCompany>
                    </>
                )}
                {user && company !== null && (
                    <UserCodes userId={user.userId} companyId={company} />
                )}
                {user && company != null && (
                    <AccessRights userId={user.userId} companyId={company} />
                )}
            </div>
        </div>
    );
};

export const UsersAdminPage = (): JSX.Element => (
    <Restricted access="UsersAdmin">
        <UsersAdminWrapper />
    </Restricted>
);
