import * as React from "react";
import { Button } from "reactstrap";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";

import { useAPI } from "@/utils/hooks";

type User = {
    id: number;
    name: string;
};

const Users = (): JSX.Element | null => {
    const { data: users, fetchData } = useAPI<User[]>("showConnectedUsers", {
        ignoreCache: true,
    });
    if (!users) return null;

    const time = format(new Date(), "HH:mm:ss");
    return (
        <>
            <div className="mb-2">
                <span>À {time}</span>
                <Button className="ms-2" size="sm" onClick={fetchData}>
                    <FontAwesomeIcon icon={faSyncAlt} />
                </Button>
            </div>
            <ul>
                {users.map((user) => (
                    <li key={user.id}>
                        <span className="me-2">{user.id}</span>
                        <span className="me-2">{user.name}</span>
                    </li>
                ))}
            </ul>
        </>
    );
};

export const ConnectedUsers = (): JSX.Element => {
    return (
        <div className="connected-users bg-light align-self-start mt-5 p-3">
            <h2>Utilisateurs connectés sur {process.env.REACT_APP_ENV_NAME}</h2>
            <Users />
        </div>
    );
};
