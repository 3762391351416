import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { Button } from "reactstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { CompletionEntry } from "shared/dist/types/types";

import { DTC_IMAGE } from "@/config/settings";
import { getContent } from "@/utils/fetch";
import { useGenarts, useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { FamilyAlbum } from "./FamilyAlbum";

import "./DTCSearch.css";

const { useState } = React;

type DTCEntry = CompletionEntry & { fullLabel: string };
const autocomplete =
    (lang: string) =>
    (input: string): Promise<DTCEntry[]> =>
        getContent(`completion/code/${lang}/${input || "P"}`);

type Props = {
    mid: string;
    buttonLabel?: string;
    hide?: boolean;
};

type DTCResult = {
    code: string;
    label: string;
    genarts: string[];
};

// DTC = Diagnostic Trouble Code
export const DTCSearchContent = ({
    mid,
    buttonLabel,
    hide,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const lang = useLanguage(true);

    const [dtc, setDTC] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [dtcResults, setDTCResults] = useState<DTCResult[] | null>(null);
    const { genartsMap } = useGenarts();

    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const match = useRouteMatch();

    const submitCode = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (!dtc) return;
        setDTCResults(null);
        setIsSearching(true);
        const url = `/md360dtcMID/${lang}/${mid}/${dtc}`;
        getContent<DTCResult[] | { code: string }>(url)
            .then((data) => {
                setIsSearching(false);
                if ("code" in data) {
                    toastr.warning("Quota de requêtes dépassé");
                    setDTCResults(null);
                } else {
                    setDTCResults(data);
                }
            })
            .catch((error) => {
                setDTCResults(null);
                setIsSearching(false);
                console.error(error);
                toastr.error(
                    `Impossible de récupérer les infos pour le code ${dtc}`
                );
            });
    };

    const submit = (formData: Record<number, boolean>): void => {
        const genartIds = Object.keys(formData).filter(
            (key) => formData[Number(key)]
        );
        if (genartIds.length === 0) {
            return;
        }
        const url = `${match.url}/search/dtc/${genartIds.join("-")}/${
            genartIds[0]
        }`;
        history.push(url);
    };

    return (
        <>
            <form
                className={
                    hide
                        ? "d-none"
                        : "dtc-search-form d-flex mb-2 flex-grow-1 align-items-start"
                }
                onSubmit={submitCode}
            >
                <AsyncSelect
                    className="flex-grow-1"
                    isMulti
                    isClearable
                    cacheOptions
                    defaultOptions
                    loadOptions={autocomplete(lang)}
                    placeholder="Code erreur OBD"
                    formatOptionLabel={(data, { context }) =>
                        context === "menu" ? data.fullLabel : data.label
                    }
                    onChange={(codes) =>
                        setDTC(
                            codes
                                .map((v) => v.value)
                                .join()
                                .toUpperCase()
                        )
                    }
                    isDisabled={isSearching}
                    menuPortalTarget={document.body}
                />
                <Button
                    type="submit"
                    disabled={isSearching}
                    className="ms-2 align-self-start"
                >
                    <FontAwesomeIcon icon={faSearch} />
                </Button>
            </form>
            {dtcResults && dtcResults.length > 0 && (
                <form
                    className={
                        hide ? "d-none" : "dtc-results-form overflow-auto"
                    }
                    onSubmit={handleSubmit(submit)}
                >
                    {dtcResults.map((result) => (
                        <div key={result.code}>
                            <div className="dtc-code-label">
                                {result.code} {result.label}
                            </div>
                            {result.genarts.length === 0 && (
                                <p>
                                    {t("common.no-results-for", {
                                        search: result.code,
                                    })}
                                </p>
                            )}
                            <ul className="list-none">
                                {result.genarts
                                    .filter(
                                        (genartId) =>
                                            Number(genartId) in genartsMap
                                    )
                                    .map((genartId) => (
                                        <li key={genartId}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={true}
                                                    {...register(genartId)}
                                                />{" "}
                                                <span>
                                                    {
                                                        genartsMap[
                                                            Number(genartId)
                                                        ].label
                                                    }
                                                </span>
                                            </label>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    ))}
                    <Button
                        className="dtc-submit-btn"
                        color="primary"
                        type={buttonLabel ? "button" : "submit"}
                        disabled={Boolean(buttonLabel)}
                    >
                        {buttonLabel || t("common.submit")}
                    </Button>
                </form>
            )}
        </>
    );
};

export const DTCSearch = ({ mid, buttonLabel }: Props): JSX.Element => {
    const PATH = "/dtc";
    const match = useRouteMatch();
    const { pathname } = useLocation();
    const isSelected = pathname.replace(match.url, "").startsWith(PATH);
    return (
        <FamilyAlbum
            name="𝑥-DTC"
            imgURL={DTC_IMAGE}
            isSelected={isSelected}
            path={PATH}
            detailsClassName="dtc-search"
        >
            <DTCSearchContent mid={mid} buttonLabel={buttonLabel} />
        </FamilyAlbum>
    );
};
