import * as React from "react";

import type { LevamData } from "shared/dist/types/levam";

export const OEMDataRows = ({ data }: { data: LevamData }): JSX.Element => {
    return (
        <div>
            {data.models.map((model, i) => (
                <div key={i}>
                    {Object.keys(model)
                        .filter((key) => key !== "link")
                        .map((key) => (
                            <div key={key}>
                                <strong>{key}</strong>:
                                <span className="ms-1">{model[key]}</span>
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
};
