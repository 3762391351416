import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { App } from "shared/dist/types/apps";
import type { UserGroup } from "shared/dist/types/auth";
import type { CompletionEntry } from "shared/dist/types/types";

import { useSlides } from "@/components/SlidesStack";
import { apiRootURL, IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useAPI, useGenarts, useIsMounted, useLanguage } from "@/utils/hooks";
import { PartBrand, usePartsBrands } from "@/utils/usePartsBrands";
import { getSupplierLogoURL } from "@/utils/utils";

const ROTATED = { rotate: 45 };
const PARTS_PATH = "/(pieces|pieces-oe|pieces-stock)";

type Params = {
    path: string | undefined;
    brand: string | undefined;
    family: string | null;
    subFamily: string | null;
    genart: string | null;
};

const useBreadcrumbs = (): Params => {
    const brandMatch = useRouteMatch<{ brand: string; 0: string }>(
        `${PARTS_PATH}/:brand`
    );
    const familyMatch = useRouteMatch<{ brand: string; family: string }>(
        `${PARTS_PATH}/:brand/parts/:family`
    );
    const subfamilyMatch = useRouteMatch<{
        brand: string;
        family: string;
        subFamily: string;
    }>(`${PARTS_PATH}/:brand/parts/:family/:subFamily`);
    const fullMatch = useRouteMatch<{
        brand: string;
        family: string;
        subFamily: string;
        genartId: string;
    }>(`${PARTS_PATH}/:brand/parts/:family/:subFamily/:genartId`);

    const { genarts } = useGenarts();

    const brand = brandMatch?.params.brand;
    const path = brandMatch?.params[0];
    let family = null;
    let subFamily = null;
    let genartLabel = null;
    if (familyMatch) {
        family = familyMatch.params.family;
    }
    if (subfamilyMatch) {
        subFamily = subfamilyMatch.params.subFamily;
    }
    if (fullMatch) {
        const genartId = fullMatch.params.genartId;
        const genart = genarts.find((g) => g.value === Number(genartId));
        genartLabel = genart ? genart.label.replace(/ \(\d+\)$/, "") : family;
    }

    return { path, brand, family, subFamily, genart: genartLabel };
};

export const getBrandLogoUrl = (
    partsBrands: PartBrand[],
    brand: string | undefined
): string => {
    if (brand === "REPARCAR") {
        return `${IMAGES_SERVER}/logos/sivince.png`;
    }
    const partBrand = partsBrands.find(
        (partsBrand) => partsBrand.name === brand
    );
    if (!partBrand) {
        return `${apiRootURL}/media/logos/${brand}.png`;
    }
    const brandId = (partBrand || {}).id;
    return getSupplierLogoURL(brandId);
};

const useBrandLogoURL = (
    brand: string | undefined,
    isOE: boolean
): string | undefined => {
    const { data: partsOrigin } = useAPI<App[]>("widget/parts-origin");
    const { partsBrands } = usePartsBrands();
    const logo =
        ((partsOrigin || []).find((app) => app.name === brand) || {}).img ||
        undefined;
    return isOE ? logo : getBrandLogoUrl(partsBrands, brand);
};

const getStockLogo = (name: string | undefined, groups: UserGroup[]) => {
    const group = groups.find((stock) => stock.name === name);
    return group ? `${IMAGES_SERVER}/${group.logo}` : "";
};

export const PartsBreadcrumbs = ({
    isOE = false,
}: {
    isOE?: boolean;
}): JSX.Element => {
    const { t } = useTranslation();

    const { path, brand, family, subFamily, genart } = useBreadcrumbs();

    const [familyLabel, setFamilyLabel] = React.useState(family);
    const [subFamilyLabel, setSubFamilyLabel] = React.useState(subFamily);

    const lang = useLanguage(true);
    const isMounted = useIsMounted();

    const { userInfo } = useAuth();
    const groups = userInfo?.stockGroups || [];

    const { data: families } = useAPI<CompletionEntry[]>(
        family ? `families/${lang}` : null
    );
    const { data: subFamilies } = useAPI<CompletionEntry[]>(
        subFamily ? `subFamilies/${lang}` : null
    );

    React.useEffect(() => {
        if (!families || !isMounted()) return;
        const entry = families.find((f) => f.value === family);
        if (entry) {
            const label = `${entry.label} (${family})`;
            setFamilyLabel(label);
        }
    }, [families, family, isMounted]);

    React.useEffect(() => {
        if (!subFamilies || !isMounted()) return;
        const entry = subFamilies.find((f) => f.value === subFamily);
        if (entry) {
            const label = `${entry.label} (${subFamily})`;
            setSubFamilyLabel(label);
        }
    }, [subFamilies, subFamily, isMounted]);

    const { toggleVisibility } = useSlides();
    const brandLogoURL = useBrandLogoURL(brand, isOE);

    const brandLabel = brand === "REPARCAR" ? "sivinCE" : brand;
    const imgSrc =
        path === "pieces-stock" ? getStockLogo(brand, groups) : brandLogoURL;
    return (
        <div className="breadcrumbs-wrapper">
            <Breadcrumb>
                <BreadcrumbItem>
                    <FontAwesomeIcon
                        icon="barcode"
                        style={{ marginRight: 5 }}
                    />
                    <Link to="/pieces">{t("leftmenu.pieces")}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Link to={`/${path}/${brand}`}>{brandLabel}</Link>
                </BreadcrumbItem>
                {family && (
                    <BreadcrumbItem>
                        <Link to={`/${path}/${brand}/parts/${family}`}>
                            {familyLabel}
                        </Link>
                    </BreadcrumbItem>
                )}
                {subFamily && (
                    <BreadcrumbItem>
                        <Link
                            to={`/${path}/${brand}/parts/${family}/${subFamily}`}
                        >
                            {subFamilyLabel}
                        </Link>
                    </BreadcrumbItem>
                )}
                {genart && <BreadcrumbItem active>{genart}</BreadcrumbItem>}
                {process.env.REACT_APP_SHOW_CARSELECTOR_BACKBUTTON && (
                    <Link to="/pieces">
                        <FontAwesomeIcon
                            className="partselector-cross"
                            icon={faTimesCircle}
                        />
                    </Link>
                )}
            </Breadcrumb>
            <div className="breadcrumb-toggle">
                <FontAwesomeIcon
                    icon="arrows-alt"
                    onClick={toggleVisibility}
                    transform={ROTATED}
                />
            </div>
            <div className="breadcrumb-img">
                <Link to="/pieces">
                    <img src={imgSrc} alt={brand} />
                </Link>
            </div>
        </div>
    );
};
