import * as React from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Iframe } from "@/components/Iframe";
import { LEFT_MENU_BUTTONS } from "@/components/LeftMenu";
import { Restricted } from "@/components/Restricted";
import { Unauthorized } from "@/components/Unauthorized";
import { iframes } from "@/config/settings";
import { templates } from "@/config/templates";
import { useAuth } from "@/context/AuthContext";
import { urlERPAtom, urlMyBoxAtom } from "@/globalState/atoms";

const Template = ({
    html,
    className,
}: {
    html: string;
    className: string;
}): JSX.Element => (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
);

export const AppIframe = (): JSX.Element => {
    const { app } = useParams<{
        app: keyof typeof iframes | keyof typeof templates;
    }>();
    const { hasAccess } = useAuth();
    for (const button of LEFT_MENU_BUTTONS) {
        if (button.id === `app/${app}` && !hasAccess(button.access)) {
            return <Unauthorized />;
        }
    }
    return app in templates ? (
        <Template
            className={`iframe-wrapper iframe-${app}`}
            html={templates[app as keyof typeof templates]}
        />
    ) : (
        <Iframe app={app} url={iframes[app]} />
    );
};

const IframeERPContent = () => {
    const urlERP = useRecoilValue(urlERPAtom);
    return <Iframe app="ERP" url={urlERP} />;
};

export const IframeERP = (): JSX.Element => {
    return (
        <Restricted access="ERP">
            <IframeERPContent />
        </Restricted>
    );
};

export const IframeMyBox = (): JSX.Element => {
    const urlMyBox = useRecoilValue(urlMyBoxAtom);
    return <Iframe app="mybox" url={urlMyBox} />;
};
