import * as React from "react";
import { Redirect, useParams } from "react-router-dom";

import { getContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

type Params = {
    value: string;
    dtc: string;
    0: string;
};

const makeUrl = (params: Params, genarts: string[]): string =>
    `/identification-vehicule/search/${params[0]}/${
        params.value
    }/search/MD360-${params.dtc}/${genarts.join("-")}/${genarts[0]}`;

export const RedirectToGenarts = (): JSX.Element | null => {
    const [url, setUrl] = React.useState<string>();

    const params = useParams<Params>();
    const { value, dtc } = params;

    React.useEffect(() => {
        const path = params[0] === "MID" ? "md360dtcMID" : "md360dtc";
        getContent<string[]>(`${path}/${value}/${dtc}`)
            .then((genarts) => {
                const newURL = makeUrl(params, genarts);
                setUrl(newURL);
            })
            .catch((error) => {
                console.error(error);
                toastr.error("Impossible de récupérer les informations du DTC");
            });
    }, [value, dtc, params]);

    return url ? <Redirect to={url} /> : null;
};
