import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { getContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

export const INITIAL_SLIDE_URL = "initialSlideURL";

export const NotificationRedirect = (): JSX.Element | null => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    React.useEffect(() => {
        const onLoad = async () => {
            const { url, target } = await getContent<{
                url: string | null;
                target: "viewer" | "redirect" | "slide-rse";
            }>(`notifURL/${id}`);
            if (!url) {
                toastr.warn("Ce lien a expiré");
                return;
            }
            if (target === "viewer") {
                history.push(`/viewer/${id}`);
            } else if (target === "redirect") {
                window.location.href = url;
            } else {
                localStorage.setItem(INITIAL_SLIDE_URL, url);
                history.push(process.env.REACT_APP_LOGIN_PAGE || "/");
            }
        };
        onLoad();
    }, [history, id]);
    return null;
};
