import * as React from "react";
import Autosuggest, { OnSuggestionSelected } from "react-autosuggest";

import type { CompletionEntry } from "shared/dist/types/types";

import { debounce } from "@/utils/utils";

import { theme } from "./autocompleteTheme";

import "./Autocomplete.css";

const { useRef, useState, useEffect } = React;

type Props<T extends CompletionEntry> = {
    renderSuggestion?: (
        suggestion: T,
        data: { query: string; isHighlighted: boolean }
    ) => JSX.Element;
    getSuggestions: (value: string) => Promise<T[]>;
    value: string;
    onChange: (
        event: React.FormEvent<HTMLElement>,
        params: Autosuggest.ChangeEvent
    ) => void;
    onSuggestionSelected?: OnSuggestionSelected<T>;
    placeholder?: string;
    prefix?: string;
    pattern?: string;
    inputProps?: Partial<Autosuggest.InputProps<T>>;
};

export const Autocomplete = <T extends CompletionEntry>(
    props: Props<T>
): JSX.Element => {
    const cache = useRef<Record<string, T[]>>({});
    const [suggestions, setSuggestions] = useState<T[]>([]);

    const { getSuggestions } = props;

    const onSuggestionsFetchRequested = React.useMemo(
        () =>
            debounce(({ value }: { value: string }): void => {
                if (!value || value.length < 2) {
                    setSuggestions([]);
                    return;
                }
                if (cache.current[value]) {
                    setSuggestions(cache.current[value]);
                    return;
                }
                getSuggestions(value).then((newSuggestions) => {
                    cache.current[value] = newSuggestions;
                    setSuggestions(newSuggestions);
                });
            }, 300),
        [getSuggestions]
    );

    const onSuggestionsClearRequested = (): void => setSuggestions([]);

    useEffect(() => {
        cache.current = {};
    }, [props.prefix, props.getSuggestions]);

    const {
        value,
        renderSuggestion = defaultRenderSuggestion,
        onSuggestionSelected,
    } = props;
    const inputProps: Autosuggest.InputProps<T> = {
        placeholder: props.placeholder,
        pattern: props.pattern,
        ...props.inputProps,
        value,
        onChange: props.onChange,
    };

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={(suggestion: T): string => suggestion.value}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={theme}
        />
    );
};

const defaultRenderSuggestion: Required<
    Props<CompletionEntry>
>["renderSuggestion"] = (suggestion, { query }) => (
    <span>
        <span className="autosuggest-query">{query}</span>
        {suggestion.label.substring(query.length)}
    </span>
);
