import * as React from "react";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useSlides } from "@/components/SlidesStack";
import { useAPI, useLanguage } from "@/utils/hooks";

import { BatteryReplacementProcedures } from "./BatteryReplacementProcedures";
import { Illustration } from "./Illustration";
import { TranslatedByCaia } from "./TranslatedByCaia";

type BatteryReplacementProps = {
    mid: string;
};

export type AutodataImage = {
    id: string;
    graphic: {
        id: string;
        url: string;
        expires: number;
    };
};

type BatteryReplacementData = {
    translated?: boolean;
    mid: string;
    href: string;
    general_information: {
        value: ValueItem[];
        warnings: unknown[];
    };
    battery_location: {
        value: ValueItem[];
        notes: unknown[];
    } | null;
    special_tools: unknown[];
    precautions: {
        value: ValueItem[];
        notes: unknown[];
    };
    procedures: Procedure[];
    __images: AutodataImage[];
};

type BatteryReplacementEntry = {
    description: string;
    id: string;
    data: BatteryReplacementData;
};

type SimpleTextValue = {
    type: "text";
    value: string;
    sort_order: number;
};

type CompoundTextValue = {
    type: "compound_text";
    value: ValueItem[];
    sort_order: number;
};

type ImageValue = {
    type: "image";
    value: string;
    reference: unknown;
    sort_order: number;
};

export type ValueItem = SimpleTextValue | CompoundTextValue | ImageValue;

type SimpleStep = {
    kind: "step" | "note" | "warning";
    value: ValueItem;
    sort_order: number;
};

type SubStep = {
    kind: "substep";
    value: ValueItem;
    sort_order: number;
};

type CompoundStep = {
    kind: "compound_step";
    value: (SimpleStep | SubStep)[];
    sort_order: number;
};

export type Step = SimpleStep | SubStep | CompoundStep;

export type Procedure = {
    title: string;
    steps: Step[];
    procedures: Procedure[];
};

const MARGIN_RIGHT_5 = { marginRight: 5 };

const LEVEL1 = ["general_information", "precautions", "procedures"] as const;

const ImageComponent = ({ url, title }: { url: string; title: string }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggleModal = () => {
        setModalOpen((open) => !open);
    };
    const items = [{ original: url }];
    return (
        <>
            <span onClick={toggleModal}>
                <FontAwesomeIcon icon={faImage} style={MARGIN_RIGHT_5} />
                {title}
            </span>
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
                <ModalBody>
                    <ImageGallery
                        items={items}
                        showThumbnails={false}
                        showPlayButton={false}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export const findImgURL = (images: AutodataImage[], id: string): string =>
    images.find((image) => image.id === id)?.graphic.url || "";

const findBatteryLocationImage = (batteryData: BatteryReplacementData) => {
    const batteryLocation = batteryData.battery_location?.value[0];
    if (batteryLocation?.type !== "compound_text") {
        return "";
    }
    const imageItem = batteryLocation.value[1];
    if (imageItem?.type !== "image") {
        return "";
    }
    return findImgURL(batteryData.__images, imageItem.value);
};

const ValueItems = ({
    items,
    images,
}: {
    items: ValueItem[];
    images: AutodataImage[];
}) => {
    return (
        <ul>
            {items.map((item) => (
                <li key={item.sort_order}>
                    <ValueItemComponent item={item} images={images} />
                </li>
            ))}
        </ul>
    );
};

const ValueItemComponent = ({
    item,
    images,
}: {
    item: ValueItem;
    images: AutodataImage[];
}) => {
    return (
        <>
            {item.type === "text" ? item.value : null}
            {item.type === "image" ? (
                <ImageComponent
                    url={findImgURL(images, item.value)}
                    title={`Image ${item.value} ${
                        item.reference == null ? "" : `(${item.reference})`
                    }`}
                />
            ) : null}
            {item.type === "compound_text" ? (
                <ValueItems items={item.value} images={images} />
            ) : null}
        </>
    );
};

const Steps = ({
    steps,
    images,
}: {
    steps: Step[];
    images: AutodataImage[];
}) => {
    if (steps.length === 0) return null;
    return (
        <ul className="list-steps">
            {steps.map((step) => (
                <li key={step.sort_order}>
                    {(step.kind === "step" ||
                        step.kind === "substep" ||
                        step.kind === "note" ||
                        step.kind === "warning") && (
                        <ValueItemComponent item={step.value} images={images} />
                    )}
                    {step.kind === "compound_step" && (
                        <Steps steps={step.value} images={images} />
                    )}
                </li>
            ))}
        </ul>
    );
};

const Procedures = ({
    procedures,
    images,
}: {
    procedures: Procedure[];
    images: AutodataImage[];
}) => {
    const [procedureIndex, setProcedureIndex] = React.useState<number>();
    if (procedures.length === 0) return null;
    return (
        <>
            <ul className="list-procedures">
                {procedures.map((procedure, i) => (
                    <li
                        key={i}
                        onClick={() => setProcedureIndex(i)}
                        role="button"
                    >
                        {procedure.title}
                        {procedureIndex === i && (
                            <>
                                <Steps
                                    steps={procedures[procedureIndex].steps}
                                    images={images}
                                />
                                <Procedures
                                    procedures={procedure.procedures}
                                    images={images}
                                />
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </>
    );
};

const isValidLevel =
    (data: BatteryReplacementData) => (level: keyof BatteryReplacementData) => {
        const element = data[level];
        if (Array.isArray(element)) return element.length > 0;
        if (element instanceof Object) return Object.keys(element).length > 0;
        return Boolean(element);
    };

const BatteryReplacementLoader = ({ mid }: BatteryReplacementProps) => {
    const lang = useLanguage(true);
    const { data, error } = useAPI<BatteryReplacementEntry[]>(
        `autodata/batteryReplacement/${lang}/${mid}`
    );

    if (error) {
        return <ErrorMessage />;
    }

    if (!data) {
        return <Loading />;
    }

    return <BatteryReplacementContent batteryData={data} mid={mid} />;
};

const BatteryReplacementContent = ({
    batteryData,
    mid,
}: {
    mid: string;
    batteryData: BatteryReplacementEntry[];
}): JSX.Element => {
    const [data, setData] = React.useState<BatteryReplacementData>();
    const [level1, setLevel1] = React.useState<keyof BatteryReplacementData>();
    const { addSlide } = useSlides();
    const { t } = useTranslation();

    const imageURLs = batteryData.map((battery) =>
        findBatteryLocationImage(battery.data)
    );

    const batteryLabel = t("appoem.starterBattery");

    const replacement =
        batteryData.find((row) => row.data === data)?.description ||
        batteryLabel;

    return (
        <>
            <ul className="flex-shrink-0">
                {batteryData.map((row) => (
                    <li key={row.id}>
                        <Button color="link" onClick={() => setData(row.data)}>
                            {row.description || batteryLabel}
                        </Button>
                    </li>
                ))}
            </ul>
            {data && (
                <div className="d-flex flex-column">
                    <h3>{replacement}</h3>
                    <ul className="flex-shrink-0">
                        {LEVEL1.filter(isValidLevel(data)).map((name) => (
                            <li key={name}>
                                <Button
                                    onClick={() => setLevel1(name)}
                                    color="link"
                                >
                                    {t(`appoem.batteryReplacement.${name}`)}
                                </Button>
                            </li>
                        ))}
                    </ul>
                    {isValidLevel(data)("procedures") && (
                        <div
                            className="app-button d-flex justify-content-center align-items-center app-procedures"
                            role="button"
                            onClick={() =>
                                addSlide({
                                    content: (
                                        <BatteryReplacementProcedures
                                            procedures={data.procedures}
                                            images={data.__images}
                                            title={replacement}
                                        />
                                    ),
                                    id: `battery-replacement-procedure-${mid}-${replacement}`,
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faInfo} />
                        </div>
                    )}
                </div>
            )}
            {level1 === "general_information" && data?.general_information && (
                <div className="overflow-auto">
                    <h3>
                        {t("appoem.batteryReplacement.general_information")}
                    </h3>
                    <ValueItems
                        items={data.general_information.value}
                        images={data.__images}
                    />
                </div>
            )}
            {level1 === "precautions" && data?.precautions && (
                <div className="overflow-auto">
                    <h3>{t("appoem.batteryReplacement.precautions")}</h3>
                    <ValueItems
                        items={data.precautions.value}
                        images={data.__images}
                    />
                </div>
            )}
            {level1 === "procedures" && data?.procedures && (
                <div className="overflow-auto">
                    <h3>{t("appoem.batteryReplacement.procedures")}</h3>
                    <Procedures
                        procedures={data.procedures}
                        images={data.__images}
                    />
                </div>
            )}
            <Illustration imgURL={imageURLs} title="" />
            {data?.translated && <TranslatedByCaia />}
        </>
    );
};

export const BatteryReplacement = ({
    mid,
    hide = false,
}: BatteryReplacementProps & { hide?: boolean }): JSX.Element => (
    <div className={hide ? "d-none" : "appoemnart d-flex flex-grow-1"}>
        <BatteryReplacementLoader mid={mid} />
    </div>
);
