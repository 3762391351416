import * as React from "react";
import { useTranslation } from "react-i18next";

import {
    AutodataImage,
    findImgURL,
    Procedure,
    Step,
    ValueItem,
} from "./BatteryReplacement";

const ValueItems = ({
    items,
    images,
}: {
    items: ValueItem[];
    images: AutodataImage[];
}) => {
    return (
        <ul>
            {items.map((item) => (
                <li key={item.sort_order}>
                    <ValueItemComponent item={item} images={images} />
                </li>
            ))}
        </ul>
    );
};

const ImageComponent = ({ url, title }: { url: string; title: string }) => {
    return (
        <div>
            <span>{title}</span>
            <img src={url} />
        </div>
    );
};

const ValueItemComponent = ({
    item,
    images,
}: {
    item: ValueItem;
    images: AutodataImage[];
}) => {
    return (
        <>
            {item.type === "text" ? item.value : null}
            {item.type === "image" ? (
                <ImageComponent
                    url={findImgURL(images, item.value)}
                    title={`Image ${item.value} ${
                        item.reference == null ? "" : `(${item.reference})`
                    }`}
                />
            ) : null}
            {item.type === "compound_text" ? (
                <ValueItems items={item.value} images={images} />
            ) : null}
        </>
    );
};

const Steps = ({
    steps,
    images,
}: {
    steps: Step[];
    images: AutodataImage[];
}) => {
    if (steps.length === 0) return null;
    return (
        <ul className="list-steps">
            {steps.map((step) => (
                <li key={step.sort_order}>
                    {(step.kind === "step" ||
                        step.kind === "substep" ||
                        step.kind === "note" ||
                        step.kind === "warning") && (
                        <ValueItemComponent item={step.value} images={images} />
                    )}
                    {step.kind === "compound_step" && (
                        <Steps steps={step.value} images={images} />
                    )}
                </li>
            ))}
        </ul>
    );
};

const Procedures = ({
    procedures,
    images,
}: {
    procedures: Procedure[];
    images: AutodataImage[];
}) => {
    if (procedures.length === 0) return null;
    return (
        <>
            <ul className="list-procedures">
                {procedures.map((procedure, i) => (
                    <li key={i}>
                        {procedure.title}
                        <Steps steps={procedures[i].steps} images={images} />
                        <Procedures
                            procedures={procedure.procedures}
                            images={images}
                        />
                    </li>
                ))}
            </ul>
        </>
    );
};

const ProcedureComponent = ({
    procedure,
    images,
}: {
    procedure: Procedure;
    images: AutodataImage[];
}) => {
    const { t } = useTranslation();
    return (
        <div>
            <h2>{procedure.title}</h2>
            <Steps steps={procedure.steps} images={images} />
            {procedure.procedures.length > 0 && (
                <>
                    <div>{t("appoem.batteryReplacement.procedures")}</div>
                    <Procedures
                        procedures={procedure.procedures}
                        images={images}
                    />
                </>
            )}
        </div>
    );
};

type BatteryReplacementProceduresProps = {
    procedures: Procedure[];
    images: AutodataImage[];
    title: string;
};

export const BatteryReplacementProcedures = ({
    procedures,
    images,
    title,
}: BatteryReplacementProceduresProps): JSX.Element => {
    return (
        <div className="battery-replacement-procedures">
            <h1>{title}</h1>
            {procedures.map((procedure, i) => (
                <ProcedureComponent
                    procedure={procedure}
                    key={i}
                    images={images}
                />
            ))}
        </div>
    );
};
