import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
    Button,
    Col,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { RoleGroup, RoleGroups } from "shared/dist/types/apps";
import type { CompletionEntry } from "shared/dist/types/types";

import { i18n } from "@/config/i18n";
import { fetchWithAuth } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { useRoleGroupContext } from "./RoleGroupContext";

type FormFields = {
    name: string;
    image: FileList;
    roles: CompletionEntry[];
};

type Props = {
    toggle(): void;
    group?: RoleGroup;
};
export const NewGroupWizard = ({ group, toggle }: Props) => {
    const { t } = useTranslation();
    const { name = "" } = group || {};
    const roles: CompletionEntry[] = (group?.roles || []).map((role) => ({
        value: role.id,
        label: `${role.id} ${role.desc}`,
    }));
    const { control, register, handleSubmit } = useForm<FormFields>({
        defaultValues: { name, roles },
    });
    const { data: realmRoles } = useAPI<ReadonlyArray<CompletionEntry>>(
        "realmRoles",
        { initialData: [] }
    );
    const [, setGroups] = useRoleGroupContext();
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const submit = async (data: FormFields) => {
        if (isSubmitting) return;
        if (data.roles.length === 0) {
            toastr.info("Merci de sélectionner au moins une famille client");
            return;
        }
        setIsSubmitting(true);
        const formData = new FormData();
        const newRoles = data.roles.map((role) => role.value).join();
        formData.append("name", data.name);
        formData.append("roles", newRoles);
        if (data.image[0]) {
            formData.append("image", data.image[0]);
        }
        if (group) {
            formData.append("id", String(group.id));
        }
        const path = group ? "apps/update-group" : "apps/group";
        try {
            const res = await fetchWithAuth(path, {
                method: "POST",
                body: formData,
            });
            const groups: RoleGroups = await res.json();
            setGroups(groups);
            toggle();
        } catch (error) {
            toastr.error(i18n.t("common.error"));
            return;
        } finally {
            setIsSubmitting(false);
        }
    };

    const title = group
        ? "Modifier un groupe d'audience"
        : "Créer un nouveau groupe d'audience";
    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalHeader toggle={toggle}>Groupe d&apos;audience</ModalHeader>
            <ModalBody>
                <h4>{title}</h4>
                <FormGroup row>
                    <Label sm={4} for="audienceGroupName">
                        Nom du groupe
                    </Label>
                    <Col sm={8}>
                        <input
                            type="text"
                            className="form-control"
                            id="audienceGroupName"
                            maxLength={100}
                            {...register("name")}
                            required
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="audienceGroupImage" sm={4}>
                        {t("partsettings.image-label")}
                    </Label>
                    <Col sm={8}>
                        <input
                            type="file"
                            className="form-control"
                            id="audienceGroupImage"
                            accept="image/*"
                            {...register("image")}
                            required={group === undefined}
                        />
                    </Col>
                </FormGroup>
                <Controller
                    name="roles"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={realmRoles}
                            isMulti
                            placeholder="Familles client"
                        />
                    )}
                />
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button color="secondary" type="button" onClick={toggle}>
                    {t("common.cancel")}
                </Button>
                <Button color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="me-3"
                        />
                    ) : null}
                    {t("common.submit")}
                </Button>
            </ModalFooter>
        </form>
    );
};
