import * as React from "react";
import { Slide, ToastContainer } from "react-toastify";
import { TabContent, TabPane } from "reactstrap";
import { faCommentDots, faUser } from "@fortawesome/free-regular-svg-icons";
import { faCar, faShop, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "classnames";

import type { Customer } from "shared/dist/types/customer";

import { PublicSSEHandler } from "@/components/SSEHandler";
import { apiRootURL, IMAGES_SERVER } from "@/config/settings";
import { CustomerProvider, useCustomer } from "@/context/CustomerContext";
import { CartProvider } from "@/context/SimpleCartContext";
import { useSearchParams } from "@/utils/useSearchParams";

import { CartOrQuote } from "./CartOrQuote";
import { CustomerBloc } from "./CustomerBloc";
import { CustomerDataForm } from "./CustomerDataForm";
import { CustomerVehicles } from "./CustomerVehicles";
import { LoadInitialCart } from "./LoadInitialCart";
import { RegisterCartEvents } from "./RegisterCartEvents";

import "./RTPE.css";

export const RTPE = () => {
    return (
        <CartProvider>
            <PublicSSEHandler />
            <RegisterCartEvents />
            <LoadInitialCart />
            <ToastContainer
                closeButton={false}
                transition={Slide}
                theme="colored"
            />
            <CustomerProvider>
                <LoadCustomer />
                <div className="rtpe container-md d-flex flex-column">
                    <Company />
                    <CustomerBloc />
                    <Apps />
                    <CartOrQuote />
                </div>
            </CustomerProvider>
        </CartProvider>
    );
};

const LoadCustomer = () => {
    const params = useSearchParams();
    const customerId = params.get("id");
    const [, setCustomer] = useCustomer();
    React.useEffect(() => {
        fetch(`${apiRootURL}/api/vc/customer/${customerId}`)
            .then((res) => res.json())
            .then((data: Customer) => setCustomer(data))
            .catch((error) => {
                console.error(error);
                setCustomer(undefined);
            });
    }, [customerId, setCustomer]);
    return null;
};

const Company = () => (
    <>
        <div className="company-header">
            <span>Point S</span>
            <div className="company-logo">
                <img src={`${IMAGES_SERVER}/userDocs/logos/logo-point-s.png`} />
            </div>
        </div>
        <img
            className="company-cover"
            src={`${IMAGES_SERVER}/userDocs/logos/logo-point-s.svg`}
        />
    </>
);

type Tab =
    | "CustomerForm"
    | "Vehicles"
    | "Cards"
    | "Communication"
    | "Shop"
    | "Tires";

const AppButtonIcon = ({
    name,
    onClick,
    selectedApp,
    children,
}: {
    name: Tab;
    onClick(app: string): void;
    selectedApp: string | undefined;
    children: JSX.Element;
}) => {
    const className = clsx(
        "btn app-button p-0 flex-shrink-0",
        selectedApp === name && "tooltip-arrow"
    );
    return (
        <button
            type="button"
            className={className}
            onClick={() => onClick(name)}
        >
            {children}
        </button>
    );
};

const Apps = () => {
    const [activeTab, setActiveTab] = React.useState<Tab>();
    const toggle = (tab: Tab) =>
        setActiveTab((currentTab) => (currentTab === tab ? undefined : tab));
    return (
        <>
            <div className="rtpe-apps d-flex mt-3">
                <AppButtonIcon
                    name="CustomerForm"
                    onClick={toggle}
                    selectedApp={activeTab}
                >
                    <FontAwesomeIcon icon={faUser} className="app-button-img" />
                </AppButtonIcon>
                <AppButtonIcon
                    name="Vehicles"
                    onClick={toggle}
                    selectedApp={activeTab}
                >
                    <FontAwesomeIcon icon={faCar} className="app-button-img" />
                </AppButtonIcon>
                <AppButtonIcon
                    name="Cards"
                    onClick={toggle}
                    selectedApp={activeTab}
                >
                    <FontAwesomeIcon
                        icon={faWallet}
                        className="app-button-img"
                    />
                </AppButtonIcon>
                <AppButtonIcon
                    name="Communication"
                    onClick={toggle}
                    selectedApp={activeTab}
                >
                    <FontAwesomeIcon
                        icon={faCommentDots}
                        className="app-button-img"
                    />
                </AppButtonIcon>
                <AppButtonIcon
                    name="Shop"
                    onClick={toggle}
                    selectedApp={activeTab}
                >
                    <FontAwesomeIcon icon={faShop} className="app-button-img" />
                </AppButtonIcon>
                <AppButtonIcon
                    name="Tires"
                    onClick={toggle}
                    selectedApp={activeTab}
                >
                    <img
                        src={`${IMAGES_SERVER}/img/tire.png`}
                        className="app-button-img tire-app"
                    />
                </AppButtonIcon>
            </div>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="CustomerForm" className="app-content">
                    <CustomerDataForm />
                </TabPane>
                <TabPane tabId="Vehicles" className="app-content">
                    <CustomerVehicles />
                </TabPane>
            </TabContent>
        </>
    );
};
