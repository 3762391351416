import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import {
    faStar as emptyStar,
    faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Bookmark } from "shared/dist/types/types";

import { ToggleFullscreen } from "@/components/ToggleFullscreen";
import { getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";

import { PATH } from "./StatsSelector";

import "./VehicleBanner.css";

const { useState, useEffect } = React;

type VehicleVersion = {
    id: number;
    ktype: number;
    horsePowerKw: string;
    bodyStyle: string;
    fuelType: string;
    makeLabel: string;
    versionLabel: string;
    motorLabel: string;
    strokeCapacityLiter: string;
    yearFrom: string;
    yearTo: string;
};

const makeBookmarkLabel = (
    versions: VehicleVersion[],
    version: number
): string => {
    const data = versions.find((v) => v.id === version);
    return data
        ? `${data.makeLabel} ${data.versionLabel} ${data.motorLabel} ${data.yearFrom} - ${data.yearTo}`
        : "";
};

const makeAttributes = (versions: VehicleVersion[], id: number): string => {
    const versionData = versions.find((v) => v.id === id);
    if (!versionData) {
        return "";
    }
    const attributes = `${versionData.bodyStyle} ${versionData.fuelType} ${versionData.strokeCapacityLiter} ${versionData.horsePowerKw}Kw ${versionData.yearFrom}-${versionData.yearTo}`;
    return attributes;
};

type Params = {
    brand: string;
    model: string;
    version: string;
    ktype: string;
    vtype?: string;
};

type Props = {
    toggleFullscreen: () => void;
};

export const VehicleBanner = ({ toggleFullscreen }: Props): JSX.Element => {
    const bookmarks: Bookmark[] = JSON.parse(localStorage.bookmarks || "[]");

    const location = useLocation();
    const { t } = useTranslation();
    const lang = useLanguage();
    const [attributes, setAttributes] = useState<string>(t("common.loading"));
    const [isVintage, setVintage] = useState(false);
    const [isBookmarked, setBookmarked] = useState(
        bookmarks.findIndex((v) => v.url === location.pathname) !== -1
    );
    const { brand, model, version, ktype, vtype = "vl" } = useParams<Params>();

    const fetchData = (): void => {
        setAttributes(t("common.loading"));
        getContent<VehicleVersion[]>(
            `vehicles/versions/${lang}/${brand}/${model}`
        )
            .then((data) =>
                setAttributes(makeAttributes(data, Number(version)))
            )
            .catch((error) => {
                setAttributes("");
                console.error(error);
            });
    };

    useEffect(fetchData, [lang, brand, model, version, t]);

    useEffect(() => {
        getContent(`isVintage/${ktype}`).then((vintage) =>
            setVintage(Boolean(vintage))
        );
    }, [ktype]);

    const toggleBookmark = (): void => {
        if (isBookmarked) {
            console.log("Removing from bookmarks");
            const index = bookmarks.findIndex(
                (bookmark) => bookmark.url === location.pathname
            );
            if (index !== -1) {
                bookmarks.splice(index, 1);
                localStorage.bookmarks = JSON.stringify(bookmarks);
            }
        } else {
            console.log("Adding to bookmarks");
            getContent<VehicleVersion[]>(
                `vehicles/versions/${lang}/${brand}/${model}`
            ).then((versions) => {
                const label = makeBookmarkLabel(versions, Number(version));
                bookmarks.push({ url: location.pathname, label });
                localStorage.bookmarks = JSON.stringify(bookmarks);
            });
        }
        console.log("VehicleBanner", bookmarks);
        setBookmarked((bookmarked) => !bookmarked);
    };

    return (
        <div className="vehicule-banner">
            <div className="vehicule-brand">
                <span>{brand + " " + decodeURIComponent(model)}</span>
                {process.env.REACT_APP_SHOW_CARSELECTOR_BACKBUTTON && (
                    <Link to={`${PATH}/${vtype}`}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Link>
                )}
            </div>
            <div className="vehicule-attributes">
                {attributes + (isVintage ? " Vintage" : "")}
            </div>
            <FontAwesomeIcon
                className="star"
                icon={isBookmarked ? "star" : emptyStar}
                size="2x"
                onClick={toggleBookmark}
            />
            <ToggleFullscreen onClick={toggleFullscreen} />
        </div>
    );
};
