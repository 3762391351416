import * as React from "react";

export const TranslatedByCaia = (): JSX.Element => {
    return (
        <div className="position-absolute" style={{ right: 10, bottom: 10 }}>
            Translated by{" "}
            <strong>
                <span style={{ color: "#1e2337" }}>C@</span>
                <span style={{ color: "#f38102" }}>iA</span>
            </strong>
        </div>
    );
};
