import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import classnames from "classnames";
import SwiperCore, { Mousewheel, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import type { AuthInfo } from "shared/dist/types/auth";

import { i18n } from "@/config/i18n";
import {
    apiRootURL,
    DOCUMENT_TITLE,
    USE_KEYCLOAK,
    WORKSHOP_PATH,
} from "@/config/settings";
import { useLogin } from "@/context/AuthContext";
import { toastr } from "@/utils/toastr";

import { CAIA_LOGO_URL, iframeSlidesLogin } from "./settings";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

SwiperCore.use([Mousewheel, Pagination]);

const { useState, useEffect } = React;

type LocationState = { from: { pathname: string } };

const SlideContainer = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}): JSX.Element => (
    <div className="d-flex align-items-center justify-content-center">
        <div className={className}>{children}</div>
    </div>
);

type FormData = {
    email: string;
    password: string;
};
const LoginForm = () => {
    const location = useLocation<LocationState>();
    const history = useHistory();
    const { register, handleSubmit } = useForm<FormData>();
    const { setAuthInfo } = useLogin();
    const { t } = useTranslation();

    const submitLogin = ({ email, password }: FormData) => {
        console.log({ email, password });
        if (!email || !password) return;
        fetch(`${apiRootURL}/api/login`, {
            headers: {
                Authorization: "Basic " + window.btoa(`${email}:${password}`),
            },
        })
            .then((res) => {
                if (!res.ok) {
                    toastr.warning(i18n.t("login.wrong-credentials"));
                    throw Error("Wrong credentials " + res.status);
                }
                return res;
            })
            .then((res) => res.json())
            .then((data: AuthInfo) => {
                if (!data?.userInfo?.rights.includes("v2")) {
                    toastr.warning(i18n.t("login.wrong-credentials"));
                    throw Error("Wrong credentials");
                }
                setAuthInfo(data);
                const { from } = location.state || {
                    from: { pathname: `${WORKSHOP_PATH}/CarSelector` },
                };
                history.push(from);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <SlideContainer>
            <span className="blog-slider__code">
                {t("login.user-identification")}
            </span>
            <div className="blog-slider__title">{t("login.subtitle")}</div>
            <form onSubmit={handleSubmit(submitLogin)}>
                <input
                    placeholder={t("login.email")}
                    type="email"
                    className="form-control"
                    {...register("email")}
                />
                <input
                    placeholder={t("login.password")}
                    type="password"
                    className="form-control"
                    {...register("password")}
                />
                <button className="blog-slider__button">
                    {t("login.submit")}
                </button>
            </form>
        </SlideContainer>
    );
};

const KeycloakLogin = () => {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        keycloak.login({
            redirectUri:
                window.location.origin + `${WORKSHOP_PATH}/CarSelector`,
        });
    };
    return (
        <SlideContainer className="login-v2 caia-run">
            <span className="blog-slider__code">
                {t("login.user-identification")}
            </span>
            <form onSubmit={handleSubmit}>
                <button className="blog-slider__button">
                    {t("login.submit")}
                </button>
            </form>
        </SlideContainer>
    );
};

const LoginComponent = USE_KEYCLOAK ? KeycloakLogin : LoginForm;

const Login = (): JSX.Element => {
    const [iframeIndex, setIframeIndex] = useState(0);

    const { t } = useTranslation();
    const [showBottomDiv, setShowBottomDiv] = useState(true);

    const toggleBottomDiv = (): void => setShowBottomDiv((s) => !s);

    const handleSlideChange = (swiper: SwiperCore) => {
        setIframeIndex(swiper.realIndex);
    };

    useEffect(() => {
        document.title = DOCUMENT_TITLE + "Login";
    }, []);

    return (
        <>
            <div
                className="blog-slider__img brand-toggle-active"
                onClick={toggleBottomDiv}
            >
                <img src={CAIA_LOGO_URL} alt="" />
            </div>
            <Swiper
                spaceBetween={30}
                effect="fade"
                loop
                mousewheel
                direction="vertical"
                pagination={{ clickable: true }}
                onSlideChange={handleSlideChange}
                className={classnames("blog-slider", {
                    ["d-none"]: !showBottomDiv,
                })}
            >
                <SwiperSlide>
                    <LoginComponent />
                </SwiperSlide>
                <SwiperSlide>
                    <SlideContainer>
                        <span className="blog-slider__code">
                            {t("login.forgotten-password")}
                        </span>
                        <div className="blog-slider__title">
                            {t("login.forgotten-password-title")}
                        </div>
                        <form className="">
                            <input
                                name="email"
                                placeholder={t("login.email")}
                                type="email"
                                className="form-control"
                            />
                        </form>
                        <button className="blog-slider__button">
                            {t("login.reset-password")}
                        </button>
                    </SlideContainer>
                </SwiperSlide>
            </Swiper>

            {iframeSlidesLogin.map((src, i) => (
                <iframe
                    src={src}
                    key={`${src}-${i}`}
                    className={`iframe-slide ${
                        i === iframeIndex ? "" : "hidden"
                    }`}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    title={`login-v2-${i}`}
                />
            ))}
        </>
    );
};

export default Login;
