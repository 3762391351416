import type * as React from "react";

import { FicheArticleContainer } from "@/components/FicheArticle/FicheArticleContainer";
import { AppDates, useSlides } from "@/components/SlidesStack";
import { TireStockSearch } from "@/components/TireStockSearch";

import type { OEItem } from "./Item";
import { SearchItemContainer } from "./SearchItemContainer";

const FULLSIZE = { width: "100%", height: "100%" };

const ALLOW = "allow-scripts allow-same-origin allow-forms allow-popups";

const makeIframeSlideContent = ({
    id,
    sandbox,
}: {
    id: string;
    sandbox?: boolean;
}): JSX.Element => {
    return (
        <iframe
            title={"IframeSlide-" + id}
            src={id}
            frameBorder="0"
            style={FULLSIZE}
            sandbox={sandbox ? ALLOW : undefined}
            allow="microphone; camera"
        />
    );
};

const makePartsSearchSlideContent = (slide: SearchPartsSlide): JSX.Element => (
    <SearchItemContainer
        exactSearchMode
        isInSlide
        search={slide.id}
        searchType={slide.searchType}
        skipRefSearch={slide.searchType === "ref"}
    />
);

const makeFicheArticleContent = (
    slide: OEItem,
    ktype: string | undefined,
    hmdnr: string
): JSX.Element => {
    return <FicheArticleContainer oe={slide} ktype={ktype} hmdnr={hmdnr} />;
};

const makeTireContent = (id: string) => <TireStockSearch search={id} />;

export type SearchType = "ean" | "ref" | "mdd" | "reparcar";
type SearchPartsSlide = {
    id: string;
    searchType?: SearchType;
    type: "oe";
};

type IframeSlide = {
    id: string;
    sandbox: boolean;
    type: "iframe";
};

type FicheArticleSlide = {
    id: string;
    criterias: Record<string, string>;
    type: "fiche article";
};

type TireSlide = {
    id: string;
    type: "tire";
};

type Slide = SearchPartsSlide | IframeSlide | FicheArticleSlide | TireSlide;

export const useAddItemSlide = (): {
    addIframeSlide(id: string, sandbox?: boolean, dates?: AppDates): void;
    addPartsSearchSlide(id: string, searchType?: SearchType): void;
    addFicheArticleSlide(
        item: OEItem,
        ktype?: string | null,
        hmdnr?: string
    ): void;
    addTireSlide(id: string): void;
} => {
    const { addSlide } = useSlides();

    const addItemSlide = <TSlide extends Slide>(
        slide: TSlide,
        makeSlideContent: (slideObject: TSlide) => React.ReactElement,
        dates?: AppDates
    ) => {
        const { id } = slide;
        if (!id) {
            return;
        }
        const content = makeSlideContent(slide);
        addSlide({ content, id, dates });
    };

    const addIframeSlide = (id: string, sandbox = true, dates?: AppDates) =>
        addItemSlide(
            { id, sandbox, type: "iframe" },
            makeIframeSlideContent,
            dates
        );

    const addPartsSearchSlide = (id: string, searchType?: SearchType) =>
        addItemSlide(
            { id, searchType, type: "oe" },
            makePartsSearchSlideContent
        );

    const addFicheArticleSlide = (
        item: OEItem,
        ktype: string | undefined = undefined,
        hmdnr = ""
    ) => {
        const id = `${item.makeId}-${item.id}`;
        addItemSlide({ id, criterias: {}, type: "fiche article" }, () =>
            makeFicheArticleContent(item, ktype, hmdnr)
        );
    };

    const addTireSlide = (id: string) =>
        addItemSlide({ id, type: "tire" }, () => makeTireContent(id));

    return {
        addIframeSlide,
        addPartsSearchSlide,
        addFicheArticleSlide,
        addTireSlide,
    };
};
