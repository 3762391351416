import * as React from "react";
import {
    Link,
    Route,
    Switch,
    useLocation,
    useParams,
    useRouteMatch,
} from "react-router-dom";

import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { IMAGES_SERVER } from "@/config/settings";
import { cache } from "@/utils/cache";
import { getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";
import { usePartsBrands } from "@/utils/usePartsBrands";

import { ItemContainer } from "./Item/ItemContainer";
import { Loading } from "./Loading";
import { SelectPart } from "./SelectPart";

import "./SelectPartFamily.css";

const { useState, useEffect } = React;

const defaultImageURL = `${IMAGES_SERVER}/gif/DEFAULT.png`;

type Family = {
    id: string;
    label: string;
};

const Families = ({ families }: { families: Family[] }): JSX.Element => {
    const match = useRouteMatch<{ brand: string; family: string }>();
    const { family } = useParams<{ family: string }>();
    const location = useLocation();
    return (
        <div className="selection-bloc main-bloc">
            <Breadcrumbs match={match} />
            <img
                src={`${IMAGES_SERVER}/gif/${family}.png`}
                alt=""
                onError={(e): void => {
                    e.currentTarget.src = defaultImageURL;
                }}
                height="80px"
            />
            <ul>
                {families.map((fam) => (
                    <li key={fam.id}>
                        <Link to={`${location.pathname}/${fam.id}`}>
                            <span>{fam.label}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

type FamiliesParams = {
    ktype: string;
    brand: string;
    family: string;
};

type SubFamiliesParams = FamiliesParams & {
    subFamily: string;
};

const SubFamilies = ({ families }: { families: Family[] }): JSX.Element => {
    const match = useRouteMatch<SubFamiliesParams>();
    const { ktype, brand, family, subFamily } = useParams<SubFamiliesParams>();
    const matchingFamily = families.find((fam) => fam.id === subFamily);
    const label = matchingFamily ? matchingFamily.label : "Not found";
    return (
        <div className="selection-bloc main-bloc">
            <Breadcrumbs match={match} />
            <h2>{label}</h2>
            <SelectPart
                ktype={ktype}
                brand={brand}
                family={family}
                subFamily={subFamily}
            />
        </div>
    );
};

export const SelectPartSubFamily = (): JSX.Element => {
    const [isLoading, setLoading] = useState(false);
    const [families, setFamilies] = useState<Family[]>([]);
    const match = useRouteMatch<{
        brand: string;
        genartIds: string;
        family: string;
        subFamily: string;
        categoryId: string;
        itemId: string;
        ktype: string;
        hmdnr: string;
    }>();
    const { ktype, brand, family } = useParams<FamiliesParams>();
    const lang = useLanguage();
    const { partsBrands } = usePartsBrands();

    const getFamilies = (): void => {
        const key = `subfamilies_${lang}_${ktype}_${brand}_${family}_false`;
        if (cache[key]) {
            setFamilies(cache[key] as Family[]);
            setLoading(false);
            return;
        }
        setLoading(true);
        const brandId = ktype
            ? null
            : partsBrands.find((partsBrand) => partsBrand.name === brand)?.id;
        const url = ktype
            ? `parts/vehicle/${lang}/${ktype}/${family}`
            : `parts/all/make/${lang}/${brandId}/${family}`;
        getContent<Family[]>(url)
            .then((data) => {
                const newFamilies = data.map((fam) => ({
                    id: fam.id,
                    label: fam.label,
                }));
                setFamilies(newFamilies);
                setLoading(false);
                cache[key] = newFamilies;
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    useEffect(getFamilies, [lang, ktype, brand, family, partsBrands]);

    if (isLoading) {
        return (
            <div className="selection-bloc main-bloc selection-brand">
                <Breadcrumbs match={match} />
                <Loading messageKey="partselector.loading-part-families" />
            </div>
        );
    }
    return (
        <Switch>
            <Route path={match.path} exact>
                <Families families={families} />
            </Route>
            <Route path={`${match.path}/:subFamily`} exact>
                <SubFamilies families={families} />
            </Route>
            <Route path={`${match.path}/:subFamily/:categoryId/:itemId`} exact>
                <ItemContainer match={match} />
            </Route>
        </Switch>
    );
};
