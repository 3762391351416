import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Copyright } from "@/components/Copyright";
import { LanguageSelector } from "@/components/LanguageSelector";
import { ParcoursMIDRedirect } from "@/components/ParcoursMIDRedirect";
import { PhoneRedirect } from "@/components/PhoneRedirect";
import { PrivateRoute } from "@/components/PrivateRoute";
import { SlidesProvider } from "@/components/SlidesStack";
import { CartItems } from "@/context/CartItems";
import { CountryProvider } from "@/context/CountryContext";
import { CustomerProvider } from "@/context/CustomerContext";
import { ExpertDataProvider } from "@/context/ExpertDataContext";
import { ShoppingCart } from "@/pages/ShoppingCart/ShoppingCart";
import { CarSelectorRouter } from "@/v2/CarSelectorRouter";
import Login from "@/v2/Login";

import { UserPictureAndName } from "./UserPictureAndName";

const PrivateApp = (): JSX.Element => {
    const { path } = useRouteMatch();
    const searchParams = new URLSearchParams(window.location.search);
    const [reduced, setReduced] = React.useState(
        searchParams.get("mode") === "tablette"
    );
    const toggleReduced = (): void => setReduced((r) => !r);

    return (
        <ExpertDataProvider>
            <CustomerProvider>
                <CartItems>
                    <CountryProvider>
                        <SlidesProvider>
                            <Switch>
                                <Route path={`${path}/call/:phoneNumber`}>
                                    <PhoneRedirect
                                        path={path + "/CarSelector"}
                                    />
                                </Route>
                                <Route
                                    path={`${path}/parcoursMID/:mid/:genarts/:selectedGenart?`}
                                >
                                    <ParcoursMIDRedirect
                                        path={path + "/CarSelector"}
                                    />
                                </Route>
                                <Route path={path + "/CarSelector"}>
                                    <CarSelectorRouter
                                        reduced={reduced}
                                        toggleReduced={toggleReduced}
                                    />
                                </Route>
                                <Route path={path + "/cart"}>
                                    <ShoppingCart />
                                </Route>
                            </Switch>
                            <UserPictureAndName reduced={reduced} />
                        </SlidesProvider>
                    </CountryProvider>
                </CartItems>
            </CustomerProvider>
        </ExpertDataProvider>
    );
};

export const App = (): JSX.Element => {
    const { path } = useRouteMatch();

    React.useEffect(() => {
        document.body.classList.add("v2");
        return (): void => {
            document.body.classList.remove("v2");
        };
    }, []);

    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get("mode") === "tablette") {
            const { protocol, host } = window.location;
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.type = "text/css";
            link.href = `${protocol}//${host}/media/tablette.css`;
            const head = document.getElementsByTagName("head")[0];
            head.appendChild(link);
        }
    }, []);

    return (
        <>
            <ToastContainer closeButton={false} />
            <React.Suspense fallback={<div />}>
                <Switch>
                    <Route path={path} exact>
                        <Login />
                        <nav className="navbar-right">
                            <LanguageSelector />
                        </nav>
                    </Route>
                    <PrivateRoute redirectTo={path} needAccess="v2">
                        <PrivateApp />
                    </PrivateRoute>
                </Switch>
                <Copyright />
            </React.Suspense>
        </>
    );
};
