import * as React from "react";
import { Slide, ToastContainer } from "react-toastify";

import { fetchWithAuth } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

import { ScannerContainer } from "./BarcodeScanner";

const sendEvent = (event: "vin" | "ean") => (data: string) => {
    const eventType = event === "vin" ? "searchVin" : "searchEAN";
    fetchWithAuth(`sendEvent/${eventType}/${data}`)
        .then(() => {
            toastr.success(event.toUpperCase() + " partagé");
        })
        .catch((error) => {
            console.error(error);
            toastr.error("Impossible de partager le " + event.toUpperCase());
        });
};

export const SmartphoneScanner = ({
    type,
}: {
    type: "ean" | "vin";
}): JSX.Element => {
    return (
        <div className="fullscreen-scanner">
            <ToastContainer closeButton={false} transition={Slide} />
            <ScannerContainer type={type} onSubmit={sendEvent(type)} />
        </div>
    );
};
