import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth, useLogout } from "@/context/AuthContext";
import { clearCache } from "@/utils/cache";

export const UserMenu = (): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();
    const logout = useLogout();
    const { hasAccess } = useAuth();

    const logoutHandler = () => {
        logout();
        clearCache();
        history.push("/login");
    };

    return (
        <UncontrolledDropdown>
            <DropdownToggle color="none">
                <FontAwesomeIcon icon={faUser} />
            </DropdownToggle>
            <DropdownMenu end>
                {hasAccess("MyAccount") && (
                    <DropdownItem>
                        <Link to="/mon-compte">{t("usermenu.my-account")}</Link>
                    </DropdownItem>
                )}
                <DropdownItem onClick={logoutHandler}>
                    {t("usermenu.logout")}
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
