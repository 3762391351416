import * as React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { getContent } from "@/utils/fetch";
import { showLegalInfos } from "@/utils/sirenSearch";

const autocomplete = (input: string) =>
    getContent<CompletionEntry[]>(`completion/siren/${input}`);

export const ContactSelectorSearch = ({
    show = true,
}: {
    show?: boolean;
}): JSX.Element => {
    const [value, setValue] = React.useState("");
    const { t } = useTranslation();

    const onChange = (
        event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => {
        setValue(newValue);
    };

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>): void => {
        evt.preventDefault();
    };

    const onSuggestionSelected = (
        evt: unknown,
        { suggestion }: { suggestion: CompletionEntry }
    ): void => {
        const { value: chosenValue } = suggestion;
        showLegalInfos(chosenValue);
    };

    return (
        <form
            className={classnames("car-selector-search", { ["d-none"]: !show })}
            onSubmit={handleSubmit}
        >
            <Autocomplete
                value={value}
                onChange={onChange}
                getSuggestions={autocomplete}
                placeholder={t("contactselector.placeholder")}
                onSuggestionSelected={onSuggestionSelected}
            />
        </form>
    );
};
