import * as React from "react";
import { useParams } from "react-router-dom";

import type { GenartAttribute } from "shared/dist/types/types";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import type { OEItem } from "@/components/Item/Item";
import { getContent } from "@/utils/fetch";
import { useGenarts, useIsMounted, useLanguage } from "@/utils/hooks";

import {
    FicheArticle as FicheArticleComponent,
    GenartFicheArticle,
} from "./FicheArticle";

type Props = {
    oe: OEItem;
    ktype?: string;
    hmdnr?: string;
};

type State = {
    productAlternativeAndCross: GenartFicheArticle[];
    similarItems: GenartFicheArticle[];
    relatedKits: GenartFicheArticle[];
    genartAttributes: GenartAttribute[];
    label: string;
};

type FicheArticle = {
    section: number;
    genart: string;
    id: number;
};

export const FicheArticleContainer = (props: Props): JSX.Element => {
    const [state, setState] = React.useState<State>({
        productAlternativeAndCross: [],
        similarItems: [],
        relatedKits: [],
        genartAttributes: [],
        label: "",
    });

    const isMounted = useIsMounted();
    const lang = useLanguage();
    const { itemId } = useParams<{ itemId: string }>();
    const { genartsMap } = useGenarts();

    const fetchData = (): void => {
        const { id } = props.oe;
        const url = `fichearticle/${encodeURIComponent(id)}`;
        const genartId = itemId || props.oe.genart || props.oe.genartId;
        Promise.all([
            getContent<FicheArticle[]>(url),
            getContent<GenartAttribute[]>(
                `genartAttributes/${lang}/${genartId}`
            ),
        ])
            .then(([data, genartAttributes]) => {
                if (!isMounted()) {
                    return;
                }
                const toGenart = (row: FicheArticle): GenartFicheArticle => {
                    const genart = genartsMap[Number(row.genart)];
                    return { ...row, ...genart };
                };
                const productAlternativeAndCross = data
                    .filter((row) => row.section === 1)
                    .map(toGenart);
                const similarItems = data
                    .filter((row) => row.section === 2)
                    .map(toGenart);
                const relatedKits = data
                    .filter((row) => row.section === 3)
                    .map(toGenart);
                const idGenart = Number(itemId) || props.oe.genartId;
                const label = (idGenart && genartsMap[idGenart]?.label) || "";
                setState({
                    productAlternativeAndCross,
                    similarItems,
                    relatedKits,
                    genartAttributes,
                    label,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    React.useEffect(fetchData, [isMounted, itemId, lang, props.oe, genartsMap]);

    const { ktype, hmdnr } = props;

    return (
        <ErrorBoundary>
            <FicheArticleComponent
                oe={props.oe}
                hmdnr={hmdnr}
                ktypnr={ktype}
                {...state}
            />
        </ErrorBoundary>
    );
};
