import * as React from "react";
import { useTranslation } from "react-i18next";

import type { OEChannel } from "shared/dist/types/cart";
import type { OE } from "shared/dist/types/item";

import { CartContext, useAddToCart } from "@/context/CartItems";
import { IdlpDataWithProvenance, useAvailability } from "@/utils/availability";
import { isInArray } from "@/utils/utils";

const getSuppliers = (oe: OE, availability: IdlpDataWithProvenance[]) => {
    const suppliers = availability.map((data) => ({
        name: data.from,
        label: `${data.from} (${data.prix?.prixBase || ""} ${
            data.currency || "€"
        })`,
    }));
    if (oe.itemType === "clone" || oe.from === "caia") {
        suppliers.unshift({
            name: "local",
            label: `Article local (${oe.price}€)`,
        });
    }
    if (oe.makeLabel === "REPARCAR" && oe.price) {
        suppliers.unshift({
            name: "Reparcar",
            label: `Reparcar (${oe.price}€)`,
        });
    }
    if (oe.label_brand === "GPA26") {
        suppliers.push({
            name: "GPA26",
            label: `GPA26 (${oe.price}€)`,
        });
        suppliers.push({
            name: "Reparcar",
            label: `Reparcar (${oe.price}€)`,
        });
    }
    return suppliers;
};

const getChannelSupplier = (supplier: string): OEChannel => {
    if (supplier === "local") {
        return "Caia";
    }
    if (isInArray(supplier, ["Reparcar", "GPA26"] as const)) {
        return supplier;
    }
    if (supplier.startsWith("IDLP")) {
        return "IDLP";
    }
    if (supplier.startsWith("PAP")) {
        return "PAP";
    }
    if (supplier === "Techauto") {
        return "Techauto";
    }
    throw new Error(`Supplier ${supplier} not supported`);
};

type CartButtonsProps = {
    oe: OE;
    supplier: string;
    setSupplier: React.Dispatch<React.SetStateAction<string>>;
    ktypnr: string | undefined;
};

export const CartButtons = ({
    oe,
    supplier,
    setSupplier,
    ktypnr,
}: CartButtonsProps): JSX.Element | null => {
    const { t } = useTranslation();
    const { wishlist, updateWishlist, comparelist, updateComparelist } =
        React.useContext(CartContext);
    const addItemToCart = useAddToCart();
    const { availability } = useAvailability(oe);

    const handleSupplierChange = (
        event: React.SyntheticEvent<HTMLSelectElement>
    ): void => setSupplier(event.currentTarget.value);

    const suppliers = getSuppliers(oe, availability);

    if (suppliers.length === 0) {
        return null;
    }

    const selectedAvailability = availability.find(
        (vendor) => vendor.from === supplier
    );
    const stocks = selectedAvailability?.disponibilite?.stocks;

    return (
        <div className="cart-buttons">
            <div>
                <div className="d-flex align-items-center my-3">
                    {suppliers.length === 1 ? (
                        <div className="prodSelect">{suppliers[0].label}</div>
                    ) : (
                        <select
                            className="prodSelect"
                            value={supplier}
                            onChange={handleSupplierChange}
                        >
                            {suppliers.map((vendor) => (
                                <option key={vendor.name} value={vendor.name}>
                                    {vendor.label}
                                </option>
                            ))}
                        </select>
                    )}
                    {stocks && stocks.length > 0 && (
                        <select className="ms-2 prodSelect">
                            {stocks.map((stock) => (
                                <option key={stock.name} value={stock.name}>
                                    {stock.name}: {stock.qty}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="update-buttons d-flex">
                    <div
                        className="button wish"
                        onClick={() => updateWishlist(wishlist.concat(oe))}
                    >
                        {t("fiche-article.wishlist")}
                    </div>
                    <div
                        className="button compar ms-2"
                        onClick={() =>
                            updateComparelist(comparelist.concat(oe))
                        }
                    >
                        {t("fiche-article.set-aside")}
                    </div>
                </div>
            </div>
            <div
                className="button add"
                onClick={() => {
                    addItemToCart(
                        oe,
                        getChannelSupplier(supplier),
                        undefined,
                        ktypnr
                    ).catch((error) => console.error(error));
                }}
            >
                {t("fiche-article.add-to-cart")}
            </div>
        </div>
    );
};
