import { appsContextFactory } from "./AppsContext";

export const WIDGET_LABELS = {
    "smart-promotions": "Promotions",
    "smart-initial-slides": "Slides initiales",
    "smart-news": "Actualités",
    "smart-docs": "Documentations",
    "smart-forms": "Formulaires",
    "smart-catalog": "Catalogue",
    "smart-social": "Social",
    "smart-pack": "Pack App rse²",
    "smart-tools": "Outils Catalogue & Site",
    "smart-applications": "Applications",
    "smart-services": "Services",
    "smart-formations": "Formations",
    "smart-screens": "Écrans",
} as const;

export const SMART_WIDGETS = [
    "smart-promotions",
    "smart-news",
    "smart-docs",
    "smart-forms",
    "smart-catalog",
    "smart-tools",
    "smart-social",
    "smart-pack",
    "smart-applications",
    "smart-services",
    "smart-formations",
] as const;

export const { AppsProvider, useAppsContext } = appsContextFactory("smart-");
