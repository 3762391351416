import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button, Form, Input } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";

import type { AuthInfo } from "shared/dist/types/auth";

import { apiRootURL, DOCUMENT_TITLE } from "@/config/settings";
import { USE_KEYCLOAK } from "@/config/settings";
import { useLogin } from "@/context/AuthContext";
import { toastr } from "@/utils/toastr";

import { LanguageSelector } from "./LanguageSelector";
import { QRCodeReaderButton } from "./QRCodeReader";
import { TitleWithIcon } from "./TitleWithIcon";
import { TopIcon } from "./TopIcon";

import "./Login.css";

import logoEyes from "@/img/logo_eyes.png";

const validToken = (token: string) => {
    if (token.length !== 36 || !/^[0-9a-f-]+$/.test(token)) {
        return null;
    }
    return token;
};

const LoginForm = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation<{ from: { pathname: string } }>();
    const { setAuthInfo } = useLogin();

    React.useEffect(() => {
        document.title = DOCUMENT_TITLE + "Login";
    }, []);

    const handleLoginResponse = async (res: Response) => {
        if (!res.ok) {
            toastr.warning(t("login.wrong-credentials"));
            throw Error("Wrong credentials " + res.status);
        }
        const data: AuthInfo = await res.json();
        if (!data?.userInfo?.rights.includes("CaiaApp")) {
            toastr.warning(t("login.wrong-credentials"));
            throw Error("Wrong credentials");
        }
        setAuthInfo(data);
        const { from } = location.state || { from: { pathname: "/" } };
        history.push(from);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const email = form.email.value;
        const password = form.password.value;
        console.log({ email, password });

        fetch(`${apiRootURL}/api/login`, {
            headers: {
                Authorization: "Basic " + window.btoa(`${email}:${password}`),
            },
        })
            .then(handleLoginResponse)
            .catch((error) => {
                console.log(error);
            });
    };

    const handleQRCode = (token: string) => {
        fetch(`${apiRootURL}/api/qrauth`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then(handleLoginResponse)
            .catch((error) => console.error(error));
    };

    return (
        <div className="login">
            <h3>{t("login.title")}</h3>
            <Form onSubmit={handleSubmit}>
                <Input
                    type="email"
                    name="email"
                    placeholder={t("login.email")}
                />
                <Input
                    type="password"
                    name="password"
                    placeholder={t("login.password")}
                />
                <Button color="primary">{t("login.submit")}</Button>
                {process.env.REACT_APP_SHOW_QR_LOGIN ? (
                    <QRCodeReaderButton
                        cb={handleQRCode}
                        transformString={validToken}
                    />
                ) : null}
            </Form>
        </div>
    );
};

const KeycloakLogin = () => {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const location = useLocation<{ from: { pathname: string } }>();

    React.useEffect(() => {
        document.title = DOCUMENT_TITLE + "Login";
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const from =
            location.state?.from.pathname ||
            process.env.REACT_APP_LOGIN_PAGE ||
            "";
        const redirectUri = window.location.origin + from;
        keycloak.login({ redirectUri });
    };
    return (
        <div className="login caia-run">
            <h3>{t("login.title")}</h3>
            <Form onSubmit={handleSubmit}>
                <Button color="primary">{t("login.submit")}</Button>
            </Form>
        </div>
    );
};

const LoginPage = ({ children }: { children: React.ReactNode }) => (
    <div className="app">
        <ToastContainer closeButton={false} />
        <nav className="navbar-left">
            <TopIcon />
            <span className="caia-logo">
                <img src={logoEyes} alt="C@iA" width="100" />
            </span>
            <TitleWithIcon />
        </nav>
        <nav className="navbar-right">
            <LanguageSelector />
            <span style={{ width: "30px", display: "inline-block" }}></span>
        </nav>
        <div className="main-content">{children}</div>
    </div>
);

const LoginComponent = USE_KEYCLOAK ? KeycloakLogin : LoginForm;

export const Login = (): JSX.Element => (
    <React.Suspense fallback={<div>Loading translations…</div>}>
        <LoginPage>
            <LoginComponent />
        </LoginPage>
    </React.Suspense>
);
