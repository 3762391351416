import * as React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { WordPressSuggestions } from "@/pages/RSEEditor/WordPressSuggestions";

export const WPSearchBar = () => {
    const { addIframeSlide } = useAddItemSlide();
    const input = React.useRef("");
    const suggestion = React.useRef({ label: "", url: "" });

    const submit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const label = input.current;
        let url = `https://www.google.fr/search?igu=1&q=${encodeURIComponent(
            label
        )}`;
        if (label === suggestion.current.label) {
            url = suggestion.current.url;
        }
        addIframeSlide(url);
    };

    return (
        <form onSubmit={submit} className="wp-search-bar d-flex">
            <WordPressSuggestions
                onChange={(v) => (input.current = v)}
                onSuggestionSelected={(_event, data) => {
                    suggestion.current = data.suggestion;
                    addIframeSlide(data.suggestion.url);
                }}
            />
            <button
                type="submit"
                className="btn btn-outline-secondary ms-2 align-self-center"
            >
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </form>
    );
};
