import * as React from "react";

import type { Customer } from "shared/dist/types/customer";

export type TCustomerContext = [
    Customer | undefined,
    React.Dispatch<React.SetStateAction<Customer | undefined>>
];
export const CustomerContext = React.createContext<
    TCustomerContext | undefined
>(undefined);

export const CustomerProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const customerState = React.useState<Customer>();
    return (
        <CustomerContext.Provider value={customerState}>
            {children}
        </CustomerContext.Provider>
    );
};

export const useCustomer = (): TCustomerContext => {
    const context = React.useContext(CustomerContext);
    if (context === undefined) {
        throw new Error("useCustomer must be used within a CustomerProvider");
    }
    return context;
};
