import * as React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import type { VehicleData } from "shared";

import { BlocWithAlbums } from "@/components/BlocWithAlbums";
import { Photo360 } from "@/components/Photo360";
import { i18n } from "@/config/i18n";
import { CriteriaProvider } from "@/context/CriteriaContext";
import { emptyVehicleData, useVehicleData } from "@/context/VehicleDataContext";
import { getContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";
import { CarIdentifierApps } from "@/v2/CarIdentifierApps";
import { HubIdlpProvider } from "@/v2/HubIdlpContext";
import { IframesCarSelector } from "@/v2/IframesCarSelector";
import { IframesWithContext } from "@/v2/IframesWithContext";

import { MenuSwiper } from "./MenuSwiper";

type CarSelectorRouterProps = {
    reduced: boolean;
    toggleReduced(): void;
};

export const CarSelectorRouter = ({
    reduced,
    toggleReduced,
}: CarSelectorRouterProps): JSX.Element => {
    const [iframeVisible] = React.useState(true);
    const { path } = useRouteMatch();

    return (
        <CriteriaProvider>
            <HubIdlpProvider>
                <IframesCarSelector visible={iframeVisible} />
                <MenuSwiper onLogoClick={toggleReduced} reduced={reduced} />
                <Route path={`${path}/search/:field/:value`}>
                    <SearchVehicle />
                    <VehicleParts />
                    <IframesWithContext />
                    <V2Apps show={!reduced} />
                </Route>
            </HubIdlpProvider>
        </CriteriaProvider>
    );
};

const V2Apps = ({ show = true }: { show?: boolean }) => {
    const [vehicleData] = useVehicleData();
    if (!vehicleData.Immat_SIV && !vehicleData.ktype) {
        return null;
    }
    return <CarIdentifierApps show={show} />;
};

const SearchVehicle = () => {
    const { field, value } = useParams<{ field: string; value: string }>();
    const [vehicleData, setVehicleData] = useVehicleData();
    const { path } = useRouteMatch();

    React.useEffect(() => {
        const getData = async () => {
            const data = await getContent<VehicleData>(`${field}/${value}`);
            setVehicleData(data);
        };
        try {
            getData();
        } catch (error) {
            setVehicleData(emptyVehicleData);
            const message = i18n.t("identification.toast.error", { value });
            toastr.error(message);
        }
    }, [field, setVehicleData, value]);

    return (
        <Switch>
            <Route path={path} exact>
                {vehicleData === emptyVehicleData ? null : (
                    <div id="vehicle360" className="w-100">
                        <Photo360
                            vehicleData={vehicleData}
                            showCritair={false}
                            showColorBanner={false}
                        />
                    </div>
                )}
            </Route>
        </Switch>
    );
};

const VehicleParts = () => {
    const match = useRouteMatch();
    const matchIframe = useRouteMatch<{ appId: string }>({
        path: `${match.url}/app/:appId`,
    });
    const showParts = !match.isExact && !matchIframe?.isExact;
    if (!showParts) return null;
    return <BlocWithAlbums />;
};
