import * as React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useStatsContext } from "@/context/StatsContext";
import { useAPI, useLanguage } from "@/utils/hooks";

type Version = {
    name: string;
    ktype: number;
    hmdnr: number;
};

type Props = {
    brandName: string;
    modelName: string;
    vehicleType: string;
};

type KtypeStat = { nb: number; ktype: number };
const StatsBadge = ({
    stats,
    ktype,
}: {
    ktype: number;
    stats: KtypeStat[];
}) => {
    const [brandStats] = useStatsContext();
    const ktypeStat = stats.find((s) => s.ktype === ktype);
    if (!ktypeStat) {
        return null;
    }
    const percent = ((100 * ktypeStat.nb) / brandStats.total).toFixed(2);
    return <Badge className="badge-version">{percent}%</Badge>;
};

export const Versions = ({
    brandName,
    modelName,
    vehicleType,
}: Props): JSX.Element => {
    const lang = useLanguage();
    const route = vehicleType === "pl" ? "plVersions" : "versions";
    const endpoint = `${encodeURIComponent(brandName)}/${encodeURIComponent(
        modelName
    )}`;
    const url = `vehicles/${route}/${lang}/${endpoint}`;
    const { data: versions, error } = useAPI<Version[]>(url);
    const { data: stats } = useAPI<{ nb: number; ktype: number }[]>(
        `ktypnrStats/${endpoint}`
    );

    if (error) {
        return <ErrorMessage />;
    }
    if (!versions) {
        return <Loading />;
    }
    return (
        <ul>
            {versions
                .filter((version) => version.ktype !== null)
                .map((version, i) => (
                    <li key={i}>
                        <StatsBadge stats={stats || []} ktype={version.ktype} />
                        <Link
                            to={`/selection-vehicule/${vehicleType}/${encodeURIComponent(
                                brandName
                            )}/${encodeURIComponent(modelName)}/${
                                version.hmdnr
                            }/${version.ktype}`}
                        >
                            {version.name}
                        </Link>
                    </li>
                ))}
        </ul>
    );
};
