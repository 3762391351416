import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MARGIN_RIGHT_5 = { marginRight: 5 };
const AddTipWizard = React.lazy(() => import("@/components/AddTipWizard"));

type AddTipPageProps = {
    onClose(): void;
};

export const AddTipPage = ({ onClose }: AddTipPageProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="selection-bloc main-bloc selection-brand add-tip-page">
            <div className="breadcrumbs-wrapper breadcrumbs-family">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <FontAwesomeIcon icon="users" style={MARGIN_RIGHT_5} />
                        <Link to="/social">{t("leftmenu.social")}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {t("leftmenu.ajout-astuce")}
                    </BreadcrumbItem>
                    <div className="breadcrumb-img">
                        <FontAwesomeIcon icon={faThumbsUp} />
                    </div>
                </Breadcrumb>
            </div>
            <React.Suspense
                fallback={<div className="add-tip-wizard">Loading…</div>}
            >
                <AddTipWizard close={onClose} />
            </React.Suspense>
        </div>
    );
};
