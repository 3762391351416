import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { differenceInMinutes } from "date-fns";

import { apiRootURL } from "@/config/settings";
import { useLogin } from "@/context/AuthContext";
import { useIsMounted } from "@/utils/hooks";

import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";

type Params = {
    userId: string;
    timestamp: string;
};

type Status = "idle" | "pending" | "expired" | "error" | "ok" | "invalid";

const TIME_TO_LIVE = 10; // in minutes

export const AutoLogin = (): JSX.Element | null => {
    const { userId, timestamp } = useParams<Params>();
    const [status, setStatus] = React.useState<Status>("idle");
    const isMounted = useIsMounted();
    const { setAuthInfo } = useLogin();
    const history = useHistory();

    React.useEffect(() => {
        const age = differenceInMinutes(Date.now(), Number(timestamp));
        if (!/^\d{13}$/.test(timestamp) || age < 0 || age > TIME_TO_LIVE) {
            setStatus("expired");
            return;
        }
        setStatus("pending");

        fetch(`${apiRootURL}/api/login/${userId}/${timestamp}`)
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.status);
                }
                return response.json();
            })
            .then((response) => {
                if (isMounted()) {
                    const { token, expiresAt, pathname } = response;
                    if (token) {
                        setAuthInfo({ token, expiresAt, userInfo: null });
                    }
                    history.push(pathname);
                }
            })
            .catch((error) => {
                if (isMounted()) {
                    if (Number.isInteger(error) && error < 500) {
                        setStatus("invalid");
                    } else {
                        setStatus("error");
                    }
                }
            });
    }, [userId, timestamp, isMounted, history, setAuthInfo]);

    if (status === "idle") return null;
    if (status === "pending") return <Loading />;
    if (status === "error") return <ErrorMessage />;
    if (status === "expired") return <h1>Ce lien a expiré.</h1>;
    if (status === "invalid") return <h1>Ce lien est invalide.</h1>;
    return null;
};
