import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { OptionProps, SingleValueProps } from "react-select";
import AsyncSelect from "react-select/async";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import type { CompletionEntry } from "shared/dist/types/types";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { Restricted } from "@/components/Restricted";
import { i18n } from "@/config/i18n";
import { COMPANY_LOGO_PATH, IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { getContent, postContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { ImageSuggestions } from "./ImageSuggestions";
import { Suggestion, TitleSuggestions } from "./TitleSuggestions";

import "./NotificationSender.css";

type SuggestionWithImg = CompletionEntry & { img: string };

const CustomOption = <IsMulti extends boolean = boolean>(
    props: OptionProps<SuggestionWithImg, IsMulti>
) => {
    const { innerRef, innerProps, isDisabled, isFocused, isSelected, data } =
        props;

    const className = classnames({
        "filter-option": true,
        "option--is-disabled": isDisabled,
        "option--is-focused": isFocused,
        "option--is-selected": isSelected,
    });

    return (
        <div className={className} ref={innerRef} {...innerProps}>
            <img src={data.img} alt="" width="30" />
            <span>{data.label}</span>
        </div>
    );
};

const DisplayLabel = ({ data }: SingleValueProps<SuggestionWithImg, false>) => (
    <div style={{ gridArea: "1/1" }}>
        <img src={data.img} alt="" width="30" className="me-2" />
        <span>{data.label}</span>
    </div>
);

const customComponents = {
    Option: CustomOption,
    SingleValue: DisplayLabel,
};

const autocompleteUser = async (input: string) => {
    if (!input) return [];
    type User = { id: number; name: string; picture: string };
    const users = await getContent<User[]>(
        `completion/user/${encodeURIComponent(input)}`
    );
    return users.map<SuggestionWithImg>((user) => ({
        value: String(user.id),
        label: user.name,
        img: `${IMAGES_SERVER}/userDocs/profile/${user.picture}`,
    }));
};

const FormRow = ({
    label,
    children,
}: React.PropsWithChildren<{ label: string }>) => (
    <div className="mb-2 d-flex">
        <label className="me-3 col-form-label">{label}</label>
        {children}
    </div>
);

const UsersCount = () => {
    const { data, error } = useAPI<{ usersCount: number }>("notifUsers");
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    return (
        <div className="notif-users-count">
            Nombre d&rsquo;abonnés: <span>{data.usersCount}</span>
        </div>
    );
};

type IFormData = {
    title: string;
    text: string;
    roles: CompletionEntry[];
    image: string | undefined;
    from: SuggestionWithImg | undefined;
    users: readonly SuggestionWithImg[] | undefined;
    url: string | undefined;
    public: "public" | "private";
    target: "viewer" | "slide-rse" | "redirect";
};

const NotificationSenderForm = ({
    realmRoles,
    companies,
}: {
    realmRoles: ReadonlyArray<CompletionEntry>;
    companies: ReadonlyArray<SuggestionWithImg>;
}) => {
    const { control, handleSubmit, register, setValue, watch, getValues } =
        useForm<IFormData>({
            defaultValues: { public: "private", target: "slide-rse" },
        });
    const { hasAccess } = useAuth();
    const title = React.useRef<Suggestion>();

    const submit = async (data: IFormData) => {
        if (!data.roles.length && !data.users?.length) {
            toastr.info("Merci de remplir le champ A: et/ou le champ CC:");
            return;
        }
        if (data.public === "public" && data.target !== "viewer") {
            toastr.info("Le mode anonyme n'est possible que pour le viewer");
            return;
        }
        try {
            const body = {
                title: data.title,
                text: data.text,
                targetTags: data.roles.map((role) => role.value),
                targetUrl: data.url,
                image: data.image,
                icon: data.from?.img,
                users: data.users,
                public: data.public,
                target: data.target,
            };
            await postContent("sendNotification", body);
            toastr.success("La notification a été envoyée");
        } catch (error) {
            console.error(error);
            toastr.error(i18n.t("common.error"));
        }
    };
    const sendTest = () => {
        const data: IFormData = {
            title: getValues("title"),
            text: getValues("text"),
            roles: [], // ignore roles for the test
            image: getValues("image"),
            from: getValues("from"),
            users: getValues("users"),
            url: "",
            public: getValues("public"),
            target: getValues("target"),
        };
        if (!data.title || !data.text) {
            toastr.warn("Merci de remplir les champs Titre et Message");
            return;
        }
        submit(data);
    };
    const image = watch("image");
    const users = watch("users");
    const url = watch("url");
    const urlDoesMatch = Boolean(url && url === title.current?.url);
    return (
        <form onSubmit={handleSubmit(submit)} className="px-4 flex-grow-1">
            <FormRow label="De:">
                <Controller
                    name="from"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            placeholder=""
                            options={companies}
                            className="flex-grow-1"
                            components={customComponents}
                            isClearable
                        />
                    )}
                />
            </FormRow>
            <FormRow label="A:">
                <Controller
                    name="roles"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={realmRoles}
                            isMulti
                            placeholder="Familles client"
                            className="flex-grow-1"
                        />
                    )}
                />
            </FormRow>
            <FormRow label="CC:">
                {users?.length ? (
                    <img
                        className="me-1"
                        alt=""
                        src={users[0].img}
                        height={30}
                        width={30}
                    />
                ) : null}
                <AsyncSelect
                    className="flex-grow-1"
                    isMulti
                    isClearable
                    cacheOptions
                    defaultOptions
                    loadOptions={autocompleteUser}
                    placeholder=""
                    onChange={(v) => setValue("users", v)}
                    components={{
                        Option: CustomOption,
                    }}
                />
                <button
                    className="btn btn-secondary ms-1"
                    type="button"
                    disabled={!users?.length}
                    onClick={sendTest}
                >
                    Test
                </button>
            </FormRow>
            <FormRow label="Titre">
                <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TitleSuggestions
                            inputRef={field.ref}
                            onChange={field.onChange}
                            value={field.value}
                            onSuggestionSelected={(_, { suggestion }) => {
                                if (!hasAccess("urlRse")) return;
                                setValue("url", suggestion.url);
                                title.current = suggestion;
                            }}
                        />
                    )}
                    rules={{ required: true }}
                />
                {urlDoesMatch ? (
                    <button
                        className="btn btn-none ms-1 align-self-end"
                        type="button"
                        onClick={() => setValue("url", "")}
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </button>
                ) : null}
            </FormRow>
            <FormRow label="Message">
                <textarea
                    className="form-control"
                    {...register("text", { required: true })}
                />
            </FormRow>
            <FormRow label="Illustration">
                <ImageSuggestions onChange={(v) => setValue("image", v)} />
                <img
                    src={image}
                    alt=""
                    height={100}
                    width={100}
                    className="ms-2 notif-img-preview"
                />
            </FormRow>
            {title.current != undefined && !urlDoesMatch && (
                <FormRow label="URL">
                    <input
                        type="text"
                        className="form-control"
                        {...register("url")}
                    />
                </FormRow>
            )}
            {url && hasAccess("urlFreestyle") && (
                <>
                    <FormRow label="Affichage">
                        <select
                            {...register("target")}
                            className="custom-select"
                        >
                            <option value="slide-rse">Slide RSE</option>
                            <option value="viewer">Viewer</option>
                            {hasAccess("urlFreestyle") && (
                                <option value="redirect">Redirect</option>
                            )}
                        </select>
                    </FormRow>
                    <FormRow label="Accès">
                        <label className="col-form-label ms-2 cursor-pointer">
                            Anonyme
                            <input
                                type="radio"
                                value="public"
                                className="ms-1"
                                {...register("public")}
                            />
                        </label>
                        <label className="col-form-label ms-2 cursor-pointer">
                            Membre
                            <input
                                type="radio"
                                value="private"
                                className="ms-1"
                                {...register("public")}
                            />
                        </label>
                    </FormRow>
                </>
            )}
            <button type="submit" className="btn btn-primary">
                Envoyer
            </button>
        </form>
    );
};

type Company = { id: number; name: string; logo: string };

const DataLoader = () => {
    const { data: realmRoles, error } =
        useAPI<ReadonlyArray<CompletionEntry>>("realmRoles");
    const { data: rawCompanies, error: companiesError } =
        useAPI<ReadonlyArray<Company>>("companies");
    if (error || companiesError) {
        return <ErrorMessage />;
    }
    if (!realmRoles || !rawCompanies) {
        return <Loading />;
    }
    const companies = rawCompanies.map<SuggestionWithImg>((company) => ({
        value: String(company.id),
        label: company.name,
        img: `${IMAGES_SERVER}/${COMPANY_LOGO_PATH}/${company.logo}`,
    }));
    return (
        <NotificationSenderForm realmRoles={realmRoles} companies={companies} />
    );
};

export const NotificationSender = (): JSX.Element => {
    return (
        <Restricted access="NotificationSender">
            <div className="notification-sender flex-grow-1 d-flex flex-column m-2 p-3">
                <h1 className="mb-4">Envoyer une notification</h1>
                <UsersCount />
                <DataLoader />
            </div>
        </Restricted>
    );
};
