import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, Modal } from "reactstrap";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { RoleGroup, RoleGroups } from "shared/dist/types/apps";

import {
    AppAlbum,
    AppAlbums,
    AppButton,
    AppDetails,
} from "@/components/AppAlbums";
import { i18n } from "@/config/i18n";
import { IMAGES_SERVER } from "@/config/settings";
import { FetchError, fetchWithAuth, postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

import { NewGroupWizard } from "./NewGroupWizard";
import { useRoleGroupContext, useRoleGroups } from "./RoleGroupContext";

export const AudienceManagement = () => (
    <div className="mb-4">
        <h3>Gestion des audiences</h3>
        <div className="d-flex flex-wrap overflow-hidden">
            <AudienceGroups />
        </div>
    </div>
);

const AudienceGroups = () => {
    const groups = useRoleGroups();
    return (
        <AppAlbums className="flex-wrap">
            {Object.entries(groups).map(([groupId, group]) => (
                <AppAlbum id={groupId} key={groupId}>
                    <AppButton>
                        <img
                            src={`${IMAGES_SERVER}/audienceGroups/${group.image}`}
                            alt=""
                            title={group.name}
                        />
                    </AppButton>
                    <AppDetails className="d-flex">
                        <RolesList group={group} />
                    </AppDetails>
                </AppAlbum>
            ))}
            <NewGroupButton />
        </AppAlbums>
    );
};

const RolesList = ({ group }: { group: RoleGroup }) => {
    const { register, handleSubmit, formState } = useForm();
    const [, setGroups] = useRoleGroupContext();
    const submit = async (roles: Record<string, boolean>) => {
        console.log(roles);
        const body = { groupId: group.id, roles };
        try {
            const response = await postContent("apps/group/selection", body);
            const groups: RoleGroups = await response.json();
            setGroups(groups);
            toastr.success("La sélection a été mise à jour");
        } catch (error) {
            toastr.error(i18n.t("common.error"));
        }
    };
    return (
        <form
            onSubmit={handleSubmit(submit)}
            className="d-flex flex-column flex-grow-1"
        >
            <ul className="d-flex flex-wrap flex-column overflow-auto list-none">
                {group.roles.map((role) => (
                    <li key={role.id}>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked={role.selected}
                                className="me-2"
                                {...register(role.id)}
                            />
                            {role.id} {role.desc}
                        </label>
                    </li>
                ))}
            </ul>
            <div className="d-flex gap-4 justify-content-center mt-auto">
                <DeleteButton groupId={group.id} />
                <Button
                    type="submit"
                    color="primary"
                    disabled={formState.isSubmitting}
                >
                    Valider
                </Button>
                <EditGroupButton group={group} />
            </div>
        </form>
    );
};

const DeleteButton = ({ groupId }: { groupId: number }) => {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [, setGroups] = useRoleGroupContext();

    const deleteGroup = async () => {
        try {
            if (isSubmitting) return;
            setIsSubmitting(true);
            const res = await fetchWithAuth(`apps/group/${groupId}`, {
                method: "DELETE",
            });
            const groups = await res.json();
            setGroups(groups);
        } catch (error) {
            if (error instanceof FetchError && error.status === 409) {
                toastr.warn(
                    "Impossible de supprimer ce groupe, il est utilisé dans au moins une application"
                );
                return;
            }
            toastr.error(i18n.t("common.error"));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Button
            color="danger"
            type="button"
            disabled={isSubmitting}
            onClick={deleteGroup}
        >
            {isSubmitting ? (
                <FontAwesomeIcon icon={faSpinner} spin className="me-3" />
            ) : null}
            Supprimer
        </Button>
    );
};

const NewGroupButton = () => {
    const [isOpen, toggle] = React.useReducer((o) => !o, false);
    return (
        <>
            <button
                className="marque new-app"
                type="button"
                title="Nouveau groupe"
                onClick={toggle}
            >
                <FontAwesomeIcon icon={faPlus} size="2x" />
            </button>
            <Modal isOpen={isOpen} size="lg">
                {isOpen ? <NewGroupWizard toggle={toggle} /> : null}
            </Modal>
        </>
    );
};

const EditGroupButton = ({ group }: { group: RoleGroup }) => {
    const [isOpen, toggle] = React.useReducer((o) => !o, false);
    return (
        <>
            <Button type="button" color="success" onClick={toggle}>
                Éditer
            </Button>
            <Modal isOpen={isOpen} size="lg">
                {isOpen ? (
                    <NewGroupWizard toggle={toggle} group={group} />
                ) : null}
            </Modal>
        </>
    );
};
