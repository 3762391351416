import * as React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import type { CompletionEntry } from "shared/dist/types/types";
import type { Vtype } from "shared/dist/types/vehicle";

import { IMAGES_SERVER, WORKSHOP_PATH } from "@/config/settings";
import { setDefaultVehicleImage } from "@/pages/CarSelector/Albums";
import { getContent } from "@/utils/fetch";
import { useIsMounted, useLanguage } from "@/utils/hooks";
import { getModelImage } from "@/utils/utils";

import { BrandItem } from "./BrandItem";

import "./Breadcrumbs.css";

const { useState } = React;

const setDefaultImage = (e: React.SyntheticEvent<HTMLImageElement>): void => {
    e.currentTarget.src = `${IMAGES_SERVER}/gif/DEFAULT.png`;
};

const makeUrl = ({
    url = "",
    params,
}: {
    url?: string;
    params: BreadcrumbsProps["match"]["params"];
}): string => {
    const { brand, model, version, ktype, field, value } = params;
    if (url.startsWith("/identification-vehicule")) {
        return `/identification-vehicule/search/${field}/${value}`;
    }
    if (url.startsWith("/v3/search")) {
        return `/v3/search/${field}/${value}`;
    }
    if (url.startsWith(`${WORKSHOP_PATH}/CarSelector`)) {
        return `${WORKSHOP_PATH}/CarSelector/search/${field}/${value}`;
    }
    if (url.startsWith("/selection-vehicule")) {
        const path = url.split("/").slice(0, 3).join("/");
        return `${path}/${brand}/${model}/${version}/${ktype}`;
    }
    return `/pieces/${brand}`;
};

type BreadcrumbsProps = {
    image?: string;
    children?: React.ReactNode;
    match: {
        url?: string;
        params: {
            brand?: string;
            model?: string;
            family?: string;
            subFamily?: string;
            categoryId?: string;
            version?: string;
            ktype?: string;
            field?: string;
            type?: string;
            value?: string;
            vtype?: Vtype;
        };
    };
};

export const Breadcrumbs = (props: BreadcrumbsProps): React.ReactElement => {
    const { match, image, children } = props;
    const {
        brand,
        model,
        family,
        subFamily,
        categoryId,
        vtype: vehicleType = "vl",
    } = match.params;
    const [familyLabel, setFamilyLabel] = useState(family);
    const [subFamilyLabel, setSubFamilyLabel] = useState(subFamily);
    const [categoryLabel, setCategoryLabel] = useState(categoryId);

    const lang = useLanguage(true);
    const isMounted = useIsMounted();

    const fetchAndUpdate = React.useCallback(
        (
            path: string,
            setLabel: (label: string) => void,
            value: string | number | undefined
        ): void => {
            if (!value) {
                return;
            }
            getContent<CompletionEntry[]>(path).then((families) => {
                if (!isMounted()) {
                    return;
                }
                const foundFamily = families.find((f) => f.value === value);
                if (foundFamily) {
                    const label = `${foundFamily.label} (${value})`;
                    setLabel(label);
                }
            });
        },
        [isMounted]
    );

    const updateLabels = () => {
        fetchAndUpdate("families/" + lang, setFamilyLabel, family);
        fetchAndUpdate("subFamilies/" + lang, setSubFamilyLabel, subFamily);
        fetchAndUpdate(
            "categories/" + lang,
            setCategoryLabel,
            Number(categoryId)
        );
    };

    React.useEffect(updateLabels, [
        lang,
        family,
        subFamily,
        categoryId,
        fetchAndUpdate,
    ]);

    const url = makeUrl(match);
    const brandURL = match.url?.startsWith(WORKSHOP_PATH)
        ? url
        : `/selection-vehicule/${vehicleType}`;
    const modelURL = match.url?.startsWith(WORKSHOP_PATH)
        ? url
        : `/selection-vehicule/${vehicleType}/${brand}`;

    const modelImage = brand && model && getModelImage(brand, model);
    const brandLabel = decodeURIComponent(brand || "");

    return (
        <div className="breadcrumbs-wrapper breadcrumbs-family">
            <Breadcrumb listClassName="breadcrumb align-items-center">
                <BrandItem
                    brand={brand || ""}
                    url={brandURL}
                    vType={vehicleType}
                />
                {model && (
                    <div className="breadcrumb-img breadcrumb-img-model">
                        <Link to={modelURL}>
                            <img
                                src={modelImage}
                                alt={model}
                                onError={setDefaultVehicleImage(vehicleType)}
                            />
                        </Link>
                    </div>
                )}
                <BreadcrumbItem>
                    {model ? (
                        <Link to={url}>
                            {brandLabel} {decodeURIComponent(model)}
                        </Link>
                    ) : (
                        brandLabel
                    )}
                </BreadcrumbItem>
                {family && (
                    <BreadcrumbItem>
                        <Link to={url + "/parts/" + family}>{familyLabel}</Link>
                    </BreadcrumbItem>
                )}
                {subFamily && (
                    <BreadcrumbItem>
                        <Link to={url + "/parts/" + family + "/" + subFamily}>
                            {subFamilyLabel}
                        </Link>
                    </BreadcrumbItem>
                )}
                {categoryId && (
                    <BreadcrumbItem active>{categoryLabel}</BreadcrumbItem>
                )}
                {children}
                {family && (
                    <div className="breadcrumb-img">
                        <img
                            src={`${IMAGES_SERVER}/gif/${family}.png`}
                            alt=""
                            onError={setDefaultImage}
                        />
                    </div>
                )}
                {image && (
                    <div className="breadcrumb-img">
                        <img src={image} alt="" />
                    </div>
                )}
            </Breadcrumb>
        </div>
    );
};
