import * as React from "react";

import type { Country } from "@/components/CountrySelector";

type CountrySettings = [Country, React.Dispatch<React.SetStateAction<Country>>];

const CountryContext = React.createContext<CountrySettings | undefined>(
    undefined
);

const DEFAULT_COUNTRY: Country = {
    id: "F",
    label: "France",
    ISOcode2: "FR",
};
export const CountryProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const countryState = React.useState(DEFAULT_COUNTRY);

    return (
        <CountryContext.Provider value={countryState}>
            {children}
        </CountryContext.Provider>
    );
};

export const useCountry = (): CountrySettings => {
    const context = React.useContext(CountryContext);
    if (context === undefined) {
        throw new Error("useCountry must be used within a CountryProvider");
    }
    return context;
};
