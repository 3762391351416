import * as React from "react";
import type { ToastContentProps } from "react-toastify";
import { Button } from "reactstrap";
import { useSetRecoilState } from "recoil";

import type { Customer } from "shared/dist/types/customer";

import { useCustomer } from "@/context/CustomerContext";
import { useVehicleData } from "@/context/VehicleDataContext";
import { rtpeModalOpen } from "@/globalState/atoms";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

const ClientChange = ({
    closeToast,
    newCustomer,
}: Omit<ToastContentProps, "toastProps"> & { newCustomer: Customer }) => {
    const [, setCustomer] = useCustomer();
    const changeCustomer = () => {
        setCustomer(newCustomer);
        closeToast?.();
    };
    const customerLabel = newCustomer.info
        ? `${newCustomer.info.title} ${newCustomer.info.firstname} ${newCustomer.info.name}`
        : `#${newCustomer.id}`;
    return (
        <div className="vehicle-customer-toast">
            <p>Ce véhicule est lié à {customerLabel}</p>
            <div className="d-flex gap-2">
                <Button onClick={closeToast}>Conserver le client actuel</Button>
                <Button onClick={changeCustomer}>
                    Basculer sur {customerLabel}
                </Button>
            </div>
        </div>
    );
};

export const LoadCustomer = () => {
    const [, setCustomer] = useCustomer();
    const [vehicleData] = useVehicleData();
    const url = vehicleData.Immat_SIV
        ? `vc/info/${vehicleData.Immat_SIV}`
        : null;
    const { data } = useAPI<Customer | null>(url);
    const setRTPEModalOpen = useSetRecoilState(rtpeModalOpen);

    React.useEffect(() => {
        setCustomer((currentCustomer) => {
            if (currentCustomer) {
                if (data && data.id !== currentCustomer.id) {
                    toastr.info(<ClientChange newCustomer={data} />, {
                        autoClose: false,
                    });
                }
                return currentCustomer;
            }
            if (data && data.info) {
                const customerLabel = `${data.info.title} ${data.info.firstname} ${data.info.name}`;
                toastr.info(`Ce véhicule est lié à ${customerLabel}`, {
                    onClick: () => setRTPEModalOpen(true),
                });
            }
            return data || undefined;
        });
    }, [data, setCustomer, setRTPEModalOpen]);

    return null;
};
