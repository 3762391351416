import * as React from "react";
import classnames from "classnames";

import { Restricted } from "@/components/Restricted";
import { RSESearchBar } from "@/components/RSE/RSESearchBar";
import { SetInitialSlides } from "@/components/RSE/SetInitialSlides";
import { SlidesStack } from "@/components/SlidesStack";
import { showWPSearchBar } from "@/config/settings";

import { ContactSelectorPanelRSE } from "./ContactSelectorPanelRSE";
import { WPSearchBar } from "./WPSearchBar";

import "./ContactSelectorRSE.css";

export const ContactSelectorRSE = (): JSX.Element => {
    const [visible, setVisible] = React.useState(true);
    const className = classnames("iframe-wrapper", { "d-none": !visible });
    const wrapperName = classnames("inner-content", { "d-none": !visible });
    return (
        <Restricted access="RSE">
            <RSESearchBar setVisible={setVisible} />
            <div className={wrapperName}>
                {showWPSearchBar && <WPSearchBar />}
                <ContactSelectorPanelRSE setVisible={setVisible} />
            </div>
            <SetInitialSlides widget="rse-initial-slides" />
            <SlidesStack className={className} />
        </Restricted>
    );
};
