import * as React from "react";
import { useTranslation } from "react-i18next";

import { apiRootURL } from "@/config/settings";

type Props = {
    included: string[];
    excluded: string[];
};

export const Countries = ({
    included,
    excluded,
}: Props): JSX.Element | null => {
    const { t } = useTranslation();
    let includedCountries: JSX.Element | null = null;
    let excludedCountries: JSX.Element | null = null;
    if (included && included.length > 0) {
        includedCountries = (
            <div>
                {t("item.included-countries")}:{" "}
                {included.map((code) => (
                    <img
                        key={code}
                        src={`${apiRootURL}/media/countries/${code}.png`}
                        className="country-flag"
                        alt={code}
                    />
                ))}
            </div>
        );
    }
    if (excluded && excluded.length > 0) {
        excludedCountries = (
            <div>
                {t("item.excluded-countries")}:{" "}
                {excluded.map((code) => (
                    <img
                        key={code}
                        src={`${apiRootURL}/media/countries/${code}.png`}
                        className="country-flag"
                        alt={code}
                    />
                ))}
            </div>
        );
    }
    if (!includedCountries && !excludedCountries) {
        return null;
    }
    return (
        <>
            {includedCountries}
            {excludedCountries}
        </>
    );
};
