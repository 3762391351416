import * as defaultSettings from "./defaultSettings";
import * as localSettings from "./localSettings";

const settings: Partial<typeof defaultSettings> = {};
Object.assign(settings, defaultSettings, localSettings);

export const {
    CONTACT_SELECTOR_OTHER_PARTNERS,
    DIAG_OTHER_BRANDS,
    DOCUMENT_TITLE,
    PLUS_MENU_APPS,
    PROXY_PORT,
    dockApps,
    iframeCarSelector,
    iframeContactSelector,
    iframePartSelector,
    iframeStatsSelector,
    defaultIframeStatsSelector,
    iframes,
    IMAGES_SERVER,
    STOCK_IMAGE_URL,
    CAIA_FULL_IMAGE_URL,
    CAIA_GUII_IMAGE_URL,
    CAIA_GUI_SELECTOR_IMAGE_URL,
    COMPANY_LOGO_PATH,
    PROXY_DATABOX_URL,
    TABLETTE_IMG,
    DATABOX_IMG,
    HUB_IDLP_HOST,
    DTC_IMAGE,
    WORDPRESS_URL,
    USE_KEYCLOAK,
    RSE_IFRAME_SLIDER_URL,
    CART_TABS,
    DEFAULT_COMPANY_ID,
    LEVAM_URL,
    TIRE_SUPPLIERS,
    LANGUAGES,
    COLOR_MAP,
    apiRootURL,
    showWPSearchBar,
} = settings as typeof defaultSettings;

export const WORKSHOP_PATH = process.env.REACT_APP_WORKSHOP_PATH || "/workshop";
