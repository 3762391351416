import * as React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { MediaDto } from "shared/dist/types/item";

import { IMAGES_SERVER } from "@/config/settings";

type PDF = {
    id: string;
    name: string;
    url: string;
};

const mediasToPDFs = (medias: MediaDto[]): PDF[] =>
    (medias || [])
        .filter((media) => media.extension === "PDF")
        .map((media, i) => ({
            id: `${media.dLNr}-${media.bildname}-${i}`.replace(
                /[^a-z\d-_]/gi,
                "-"
            ),
            name: media.bildname,
            url: `${IMAGES_SERVER}/tecdoc/${String(media.dLNr).padStart(
                4,
                "0"
            )}_DOC/${media.bildname}.PDF`,
        }));

type PDFButtonProps = {
    name: string;
    onClick(): void;
};

const PDFButton = ({ name, onClick }: PDFButtonProps) => {
    const ref = React.useRef<HTMLElement>(null);
    return (
        <>
            <span ref={ref} className="ms-2">
                <FontAwesomeIcon
                    icon={faFilePdf}
                    onClick={onClick}
                    role="button"
                />
            </span>
            <UncontrolledTooltip target={ref}>{name}</UncontrolledTooltip>
        </>
    );
};

type Props = {
    medias: MediaDto[] | undefined;
    onClick: (url: string, sandbox: boolean) => void;
};

export const PDFList = ({ medias = [], onClick }: Props): JSX.Element => (
    <>
        {[...new Set(mediasToPDFs(medias))].map((dto) => (
            <PDFButton
                key={dto.id}
                name={dto.name}
                onClick={() => onClick(dto.url, false)}
            />
        ))}
    </>
);
