import * as React from "react";
import {
    Controller,
    FormProvider,
    useForm,
    useFormContext,
    useWatch,
} from "react-hook-form";
import Select from "react-select";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    TabContent,
    TabPane,
} from "reactstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { GroupOption, RoleOption } from "shared/dist/types/apps";
import type { CompletionEntry } from "shared/dist/types/types";

import { AppButton } from "@/components/AppButton";
import { DatePicker } from "@/components/DatePicker";
import { Loading } from "@/components/Loading";
import { addClient, removeClient } from "@/components/SSEHandler";
import { IMAGES_SERVER } from "@/config/settings";
import { cache } from "@/utils/cache";
import { fetchWithAuth, postContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";
import { CAIA_LOGO_URL } from "@/v2/settings";

import type { PageApp } from "./AppsContext";
import { formatDate, parseDate } from "./common";
import { ImageSuggestions } from "./ImageSuggestions";
import { QRCodeScreen } from "./QRCodeScreen";
import { RoleSelect } from "./RoleSelect";
import { useAppsContext } from "./SmartAppsContext";
import { URLSuggestions } from "./URLSuggestions";

export type WizardApp = Partial<Omit<PageApp, "widget">> & {
    widget: PageApp["widget"];
};

const DeleteApp = ({
    app,
    onDelete,
    widgetLabel,
    setApps,
}: {
    app: WizardApp;
    onDelete(): void;
    widgetLabel: string;
    setApps: React.Dispatch<React.SetStateAction<PageApp[]>>;
}) => {
    const { name, widget } = app;
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggle = () => setModalOpen((o) => !o);
    const deleteApp = async () => {
        try {
            await fetchWithAuth(`app/${widget}/${app.id}`, {
                method: "delete",
            });
            toastr.success(`L'app ${name} a été supprimée`);
            setModalOpen(false);
            onDelete();
            setApps((apps) =>
                apps.filter((a) => a.widget !== app.widget || a.id !== app.id)
            );
        } catch (error) {
            console.error(error);
            toastr.error("Impossible de supprimer l'app");
        }
    };
    return (
        <Button color="danger" className="me-auto" onClick={toggle}>
            <span>Supprimer</span>
            <Modal isOpen={modalOpen} toggle={toggle}>
                <ModalBody>
                    Êtes vous sûr de vouloir supprimer l&rsquo;app {name} du
                    widget {widgetLabel} ?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={deleteApp}>
                        Supprimer
                    </Button>
                    <Button color="secondary" type="button" onClick={toggle}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
        </Button>
    );
};

interface IFormInput {
    name: string;
    url: string;
    img: string;
    roles: RoleOption[] | undefined;
    screens: CompletionEntry[] | undefined;
    dateFrom: Date | null;
    dateTo: Date | null;
    alertStart: Date | null;
    urlStart: string | null;
    alertEnd: Date | null;
    urlEnd: string | null;
    urlMaintenance: string | null;
    maintenanceMode: boolean;
    urlCover: string | null;
    highlight: boolean;
}

type AppWizardProps = {
    app: WizardApp;
    toggle(): void;
    realmRoles: ReadonlyArray<CompletionEntry>;
    appRoles: RoleOption[];
    postTo: "updateApp" | "newApp";
    successMsg: string;
    errorMsg: string;
    title: string;
    setApps: React.Dispatch<React.SetStateAction<PageApp[]>>;
    widgetLabel: string;
};

const CommonFieldsApp = {
    name: "Paramètres",
    img: `${IMAGES_SERVER}/apps/settings.png`,
    url: "",
};
const ChronologyApp = {
    name: "Chronologie",
    img: `${IMAGES_SERVER}/apps/clock.svg`,
    url: "",
};
const ScreensApp = {
    name: "Écrans",
    img: `${IMAGES_SERVER}/apps/multiple_monitors.png`,
    url: "",
};

type TabIds = "commonFields" | "chronology" | "screens";

export const AppWizard = ({
    app,
    toggle,
    realmRoles,
    appRoles,
    postTo,
    successMsg,
    errorMsg,
    title,
    setApps,
    widgetLabel,
}: AppWizardProps): JSX.Element => {
    const methods = useForm<IFormInput>({
        defaultValues: {
            name: app.name || "",
            url: app.url || "",
            img: app.img || "",
            maintenanceMode: app.maintenanceMode || false,
        },
    });

    const [activeTab, setActiveTab] = React.useState<TabIds>("commonFields");

    const onSubmit = async (data: IFormInput) => {
        if (!data.name) {
            return;
        }
        try {
            const roles = (data.roles || [])
                .filter((role) => role.type === "family")
                .map((role) => role.value);
            const groups = (data.roles || [])
                .filter((role): role is GroupOption => role.type === "group")
                .map((role) => role.id);
            const screens = (data.screens || []).map((screen) => screen.value);
            const img = data.img || CAIA_LOGO_URL;
            const appData = {
                ...data,
                appType: "iframe",
                roles,
                groups,
                screens,
                widget: app.widget,
                img,
                id: app.id,
                dateFrom: formatDate(data.dateFrom),
                dateTo: formatDate(data.dateTo),
                alertStart: formatDate(data.alertStart),
                alertEnd: formatDate(data.alertEnd),
            };
            const response = await postContent(postTo, appData);
            if (postTo === "newApp") {
                const json = await response.json();
                const newApp = {
                    ...json,
                    dateFrom: parseDate(json.dateFrom),
                    dateTo: parseDate(json.dateTo),
                    alertStart: parseDate(json.alertStart),
                    alertEnd: parseDate(json.alertEnd),
                };
                setApps((apps) => apps.concat(newApp));
            } else {
                setApps((apps) => {
                    const oldApp = apps.find(
                        (a) => a.widget === app.widget && a.id == app.id
                    );
                    if (!oldApp) {
                        console.error("Old app not found, should not happen");
                        return apps;
                    }
                    const newApp = {
                        ...oldApp,
                        ...data,
                    };
                    return apps
                        .filter(
                            (a) => a.widget !== app.widget || a.id !== app.id
                        )
                        .concat(newApp);
                });
            }
            toastr.success(successMsg);
            cache.url_apps = undefined;
            toggle();
        } catch (error) {
            console.error(error);
            toastr.error(errorMsg);
        }
    };
    const isScreen = isScreenApp(app);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <div className="d-flex">
                        {isScreen ? (
                            <QRCodeScreen id={app.id} />
                        ) : (
                            <AppNav setActiveTab={setActiveTab} />
                        )}
                        <AppPreview />
                    </div>
                    <div className="p-4">
                        <div className="d-flex mb-2">
                            <label className="me-4">
                                <span>Widget</span>
                            </label>
                            <span>{widgetLabel}</span>
                        </div>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="commonFields">
                                <CommonAppFields
                                    app={app}
                                    appRoles={appRoles}
                                    realmRoles={realmRoles}
                                />
                            </TabPane>
                            <TabPane tabId="chronology">
                                <Chronology app={app} />
                            </TabPane>
                            <TabPane tabId="screens">
                                <Screens app={app} />
                            </TabPane>
                        </TabContent>
                    </div>
                    {isScreen && app.id ? (
                        <>
                            <ScreensStatus id={app.id} />
                            <a
                                className="text-decoration-underline link-primary"
                                href={`/screen/${app.id}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Ouvrir l&rsquo;écran dans une nouvelle fenêtre
                            </a>
                        </>
                    ) : null}
                </ModalBody>
                <ModalFooter>
                    {app.id ? (
                        <DeleteApp
                            app={app}
                            onDelete={toggle}
                            setApps={setApps}
                            widgetLabel={widgetLabel}
                        />
                    ) : null}
                    <Button color="none" type="submit">
                        <FontAwesomeIcon
                            icon={faCheck}
                            role="button"
                            size="2x"
                            className="text-success"
                        />
                    </Button>
                    <Button color="none" type="button" onClick={toggle}>
                        <FontAwesomeIcon
                            icon={faTimes}
                            role="button"
                            size="2x"
                            className="text-secondary"
                        />
                    </Button>
                </ModalFooter>
            </form>
        </FormProvider>
    );
};

const ScreensStatus = ({ id }: { id: number }) => {
    const { data, setData } = useAPI<{ count: number }>(`sse/screens/${id}`, {
        ignoreCache: true,
    });

    React.useEffect(() => {
        const clientId = addClient((event) => {
            if (event.type === "screen count") {
                setData({ count: event.count });
            }
        });
        return () => {
            removeClient(clientId);
        };
    }, [id, setData]);

    if (!data) return null;

    const { count } = data;
    const plural = count > 1 ? "s" : "";
    const text = `${
        count === 0 ? "Aucun" : count
    } écran${plural} connecté${plural}`;

    return <div>{text}</div>;
};

const AppPreview = () => {
    const { control } = useFormContext<IFormInput>();
    const appImg = useWatch({ control, name: "img" });
    const appName = useWatch({ control, name: "name" });
    return (
        <AppButton
            app={{
                img: appImg || CAIA_LOGO_URL,
                name: appName,
                url: "",
            }}
            className="ms-auto"
        />
    );
};

const isScreenApp = (app: WizardApp) => app.widget === "smart-screens";

const CommonAppFields = ({
    app,
    appRoles,
    realmRoles,
}: Pick<AppWizardProps, "app" | "appRoles" | "realmRoles">) => {
    const {
        control,
        formState: { errors },
        register,
        setValue,
    } = useFormContext<IFormInput>();
    const isScreen = isScreenApp(app);
    return (
        <>
            <div className="mb-2 d-flex">
                <label className="me-4 col-form-label">
                    <span>Nom</span>
                </label>
                <div className="d-flex flex-column flex-grow-1">
                    <input
                        type="text"
                        className="form-control"
                        maxLength={200}
                        defaultValue={app.name}
                        {...register("name", { required: true })}
                    />
                    {errors.name?.type === "required" && (
                        <span className="error-message">Le nom est requis</span>
                    )}
                </div>
            </div>
            {isScreen ? null : (
                <div className="mb-2 d-flex">
                    <label className="me-4 col-form-label">
                        <span>URL</span>
                    </label>
                    <URLSuggestions
                        defaultValue={app.url}
                        onChange={(v) => setValue("url", v)}
                    />
                </div>
            )}
            <div className="mb-2 d-flex">
                <label className="me-4 col-form-label flex-shrink-0">
                    <span>URL de l&rsquo;image</span>
                </label>
                <ImageSuggestions
                    defaultValue={app.img || ""}
                    onChange={(v) => setValue("img", v)}
                />
            </div>
            {isScreen ? null : (
                <Controller
                    name="roles"
                    control={control}
                    defaultValue={appRoles}
                    render={({ field }) => (
                        <RoleSelect {...field} realmRoles={realmRoles} />
                    )}
                />
            )}
        </>
    );
};

const Chronology = ({ app }: { app: WizardApp }) => {
    const {
        control,
        getValues,
        formState: { errors },
        register,
        setValue,
        watch,
    } = useFormContext<IFormInput>();
    const watchDateTo = watch("dateTo");
    const watchDateFrom = watch("dateFrom");
    return (
        <>
            <div className="my-2 d-flex">
                <label className="me-4 col-form-label flex-shrink-0">
                    Date de début
                </label>
                <Controller
                    name="dateFrom"
                    control={control}
                    defaultValue={app.dateFrom}
                    render={({ field }) => (
                        <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control"
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="my-2 d-flex">
                <label className="me-4 col-form-label flex-shrink-0">
                    Alerte début
                </label>
                <div className="d-flex flex-column flex-grow-1">
                    <Controller
                        name="alertStart"
                        control={control}
                        defaultValue={app.alertStart}
                        render={({ field }) => (
                            <DatePicker
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                className="form-control"
                                isClearable
                                disabled={watchDateFrom === null}
                                maxDate={watchDateFrom}
                            />
                        )}
                        rules={{
                            validate: (dateStart) => {
                                const dateFrom = getValues("dateFrom");
                                if (dateStart === null || dateFrom === null) {
                                    return true;
                                }
                                return dateStart < dateFrom;
                            },
                        }}
                    />
                    {errors.alertStart?.type === "validate" && (
                        <p className="error-message">
                            L&rsquo;alerte de début doit être antérieure à la
                            date de début
                        </p>
                    )}
                </div>
            </div>
            <div className="mb-2 d-flex">
                <label className="me-4 col-form-label">
                    <span>URL de début</span>
                </label>
                <URLSuggestions
                    defaultValue={app.urlStart}
                    onChange={(v) => setValue("urlStart", v)}
                />
            </div>
            <div className="my-2 d-flex">
                <label className="me-4 col-form-label flex-shrink-0">
                    Date de fin
                </label>
                <Controller
                    name="dateTo"
                    control={control}
                    defaultValue={app.dateTo}
                    render={({ field }) => (
                        <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control"
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="my-2 d-flex">
                <label className="me-4 col-form-label flex-shrink-0">
                    Alerte fin
                </label>
                <div className="d-flex flex-column flex-grow-1">
                    <Controller
                        name="alertEnd"
                        control={control}
                        defaultValue={app.alertEnd}
                        render={({ field }) => (
                            <DatePicker
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                className="form-control"
                                isClearable
                                disabled={watchDateTo === null}
                                maxDate={watchDateTo}
                            />
                        )}
                        rules={{
                            validate: (dateEnd) => {
                                const dateTo = getValues("dateTo");
                                if (dateEnd === null || dateTo === null) {
                                    return true;
                                }
                                return dateEnd < dateTo;
                            },
                        }}
                    />
                    {errors.alertEnd?.type === "validate" && (
                        <p className="error-message">
                            L&rsquo;alerte de fin doit être antérieure à la date
                            de fin
                        </p>
                    )}
                </div>
            </div>
            <div className="mb-2 d-flex">
                <label className="me-4 col-form-label">
                    <span>URL de fin</span>
                </label>
                <URLSuggestions
                    defaultValue={app.urlEnd}
                    onChange={(v) => setValue("urlEnd", v)}
                />
            </div>
            <div className="mb-2 d-flex align-items-center">
                <label className="me-4 col-form-label">
                    <span>URL Maintenance</span>
                </label>
                <URLSuggestions
                    defaultValue={app.urlMaintenance}
                    onChange={(v) => setValue("urlMaintenance", v)}
                />
                <input
                    type="checkbox"
                    className="ms-2 form-checkbox"
                    title="Activer l'URL de maintenance"
                    defaultChecked={app.maintenanceMode}
                    {...register("maintenanceMode")}
                />
            </div>
            <div className="mb-2 d-flex align-items-center">
                <label className="me-4 col-form-label">
                    <span>URL Cover</span>
                </label>
                <ImageSuggestions
                    defaultValue={app.urlCover}
                    onChange={(v) => setValue("urlCover", v)}
                />
                <input
                    type="checkbox"
                    className="ms-2 form-checkbox"
                    title="Mettre en avant"
                    defaultChecked={app.highlight}
                    {...register("highlight")}
                />
            </div>
        </>
    );
};

const useScreens = (): CompletionEntry[] => {
    try {
        const [apps] = useAppsContext();
        const screens = apps
            .filter(isScreenApp)
            .map((app) => ({ value: String(app.id), label: app.name }));
        return screens;
    } catch (error) {
        if (
            error instanceof Error &&
            error.message.startsWith("useAppsContext")
        ) {
            // not inside a SmartAppsProvider
            return [];
        }
        throw error;
    }
};

const Screens = ({ app }: Pick<AppWizardProps, "app">) => {
    const { control } = useFormContext<IFormInput>();
    const envScreens = useScreens();
    const url = app.id ? `app/screens/${app.widget}/${app.id}` : null;
    const { data } = useAPI<number[]>(url, { ignoreCache: true });
    if (!app.id) {
        return null;
    }
    if (!data) {
        return <Loading />;
    }
    const appScreens: CompletionEntry[] = data.map(String).map((id) => ({
        value: id,
        label: envScreens.find((s) => s.value === id)?.label || id,
    }));
    return (
        <div>
            <Controller
                name="screens"
                control={control}
                defaultValue={appScreens}
                render={({ field }) => (
                    <Select
                        {...field}
                        options={envScreens}
                        isMulti
                        placeholder="Écrans"
                    />
                )}
            />
        </div>
    );
};

const AppNav = ({
    setActiveTab,
}: {
    setActiveTab: React.Dispatch<React.SetStateAction<TabIds>>;
}) => {
    const screens = useScreens();
    return (
        <>
            <AppButton
                app={CommonFieldsApp}
                onClick={() => setActiveTab("commonFields")}
            />
            <AppButton
                app={ChronologyApp}
                onClick={() => setActiveTab("chronology")}
            />
            {screens.length === 0 ? null : (
                <AppButton
                    app={ScreensApp}
                    onClick={() => setActiveTab("screens")}
                />
            )}
        </>
    );
};
