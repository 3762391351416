import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";

import type { Genart } from "shared/dist/types/types";

import { i18n } from "@/config/i18n";
import { fetchWithAuth } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

import { GenartSelect } from "./GenartSelect";

import "./NewCustomSearch.css";

const { useState } = React;

type Props = {
    close: () => void;
    name?: string;
    genart?: ReadonlyArray<Genart>;
    update?: boolean;
};

export const NewCustomSearch = (props: Props): React.ReactElement => {
    const [genart, updateGenart] = useState(props.genart || []);
    const [name, updateName] = useState(props.name || "");
    const [selectedFile, updateSelectedFile] = useState<FileList | null>(null);

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const handleNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => updateName(event.target.value);
    const handleSelectedFileChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => updateSelectedFile(event.target.files);

    const submit = (): void => {
        const { close, update } = props;
        const genarts = genart.map((g) => g.label).join("#");
        const genartIds = genart.map((g) => g.value).join("-");
        console.log(genarts);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("oldname", props.name || "");
        formData.append("genarts", genarts);
        formData.append("genartIds", genartIds);
        if (selectedFile && selectedFile[0]) {
            formData.append("image", selectedFile[0]);
        }
        const url = `${update ? "update" : "add"}-custom-search`;
        fetchWithAuth(url, {
            method: "POST",
            body: formData,
        })
            .then(() => {
                toastr.info(
                    i18n.t(
                        update
                            ? "partsettings.update-success"
                            : "partsettings.success",
                        { name }
                    )
                );
                close();
                if (update) {
                    const tokens = location.pathname.split("/");
                    const itemId = tokens.pop();
                    tokens.pop(); // discard old genartIds
                    const newURL = [...tokens, genartIds, itemId].join("/");
                    history.push(newURL);
                }
            })
            .catch((e) => {
                console.error(e);
                toastr.error(i18n.t("partsettings.error", { name }));
            });
    };

    const title = props.update
        ? t("partsettings.update-search")
        : t("partsettings.create-custom-search");

    return (
        <div className="part-settings">
            <div className="content container">
                <h4>{title}</h4>
                <FormGroup row style={{ marginTop: "20px" }}>
                    <Label sm={4} for="customSearchName">
                        {t("partsettings.name-label")}
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="text"
                            id="customSearchName"
                            maxLength={255}
                            value={name}
                            onChange={handleNameChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="customSearchImage" sm={4}>
                        {t("partsettings.image-label")}
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="file"
                            id="customSearchImage"
                            name="image"
                            accept="image/*"
                            onChange={handleSelectedFileChange}
                        />
                    </Col>
                </FormGroup>
                <GenartSelect
                    genart={genart}
                    onGenartChange={updateGenart}
                    multi
                />
            </div>
            <div className="footer">
                <Button color="secondary" onClick={props.close}>
                    {t("common.cancel")}
                </Button>
                <Button
                    color="primary"
                    disabled={!genart || genart.length === 0}
                    onClick={submit}
                >
                    {t("common.submit")}
                </Button>
            </div>
        </div>
    );
};
