import { useEffect, useState } from "react";

import type { OE } from "shared/dist/types/item";

import { postContent } from "./fetch";
import { useIsMounted } from "./hooks";

export type ItemAvailability = {
    quantite: number | string;
    texte: string;
    from: string;
    known?: boolean;
    stocks?: { name: string; qty: number }[];
    date?: string;
    heure?: string;
    site?: string;
    status?: string;
};

export type IdlpData = {
    conditionnementTarif: string;
    conditionnementVente: string;
    texte: string;
    disponibilite: ItemAvailability;
    prix: {
        consigne: string;
        prixBase: string;
        prixNet: string;
        remise1: string;
        remise2: string;
    };
};

export type IdlpDataWithProvenance = Partial<IdlpData> & {
    from: string;
    status: string;
    currency?: string;
};

type AvailabilityLine = {
    marque: string;
    reference: string;
    quantite: string;
    reponse: IdlpData;
};

type AvailabilityData = {
    typeDoc: string;
    version: string;
    status: string;
    entete: {
        acheteur: string;
        password: string;
        vendeur: string;
        requete: string;
        reponse: {
            text: string;
        };
        currency?: string;
    };
    detail: {
        ligne: AvailabilityLine[];
    };
};

type AvailabilityWithProvenance = AvailabilityLine[] & {
    from: string;
    status: string;
    currency?: string;
};

const cache = new Map<string, IdlpDataWithProvenance[]>();

const waitingData = new Set<string>();

let timeout: NodeJS.Timeout;
let waitingPromise: Promise<void> | null = null;
let stopWaiting: () => void = () => {};

const makeRef = (oe: Partial<OE> | null) =>
    oe &&
    JSON.stringify({
        reference: oe.id,
        makeId: oe.makeId,
        marque: oe.makeLabel,
    });

export const getAvailability = (item: OE): IdlpDataWithProvenance[] => {
    const ref = makeRef(item);
    return (ref && cache.get(ref)) || [];
};

const addProvenance = (rows: AvailabilityData[]) => {
    if (!Array.isArray(rows)) {
        return [];
    }
    const availability: AvailabilityWithProvenance[] = rows.map((row) => {
        const { ligne } = row.detail || {};
        const lines = !ligne ? [] : Array.isArray(ligne) ? ligne : [ligne];
        const { currency, acheteur: from } = row.entete;
        return {
            ...lines,
            from,
            currency,
            status: row.status,
        } as AvailabilityWithProvenance;
    });
    return availability;
};

const avToIdlpData =
    (index: number) =>
    (availability: AvailabilityWithProvenance): IdlpDataWithProvenance => {
        const { from, status, currency } = availability;
        if (!availability[index]) {
            return { from, status, currency };
        }
        return { ...availability[index].reponse, from, currency, status };
    };

const fetchAvailability = (endpoint: string | undefined) => {
    waitingPromise = null;
    if (waitingData.size === 0) {
        return Promise.resolve();
    }
    const items = Array.from(waitingData.values()).map((item) =>
        JSON.parse(item)
    );
    waitingData.clear();
    console.log(`Availability: fetch ${items.length} items`);
    const path = endpoint ? `availability/${endpoint}` : "availability";
    return postContent(path, {
        items: endpoint ? items.map((item) => item.reference) : items,
    })
        .then((res) => res.json())
        .then((rows: AvailabilityData[]) => addProvenance(rows))
        .then((rows) => {
            // Do it server side ? Remove password from the json
            // sortAvailability: do it from cache (which has to be exported)
            for (let i = 0; i < items.length; i++) {
                cache.set(JSON.stringify(items[i]), rows.map(avToIdlpData(i)));
            }
        });
};

const getAvailabilityWithPooling = (
    key: string,
    endpoint: string | undefined
): Promise<IdlpDataWithProvenance[]> => {
    if (cache.has(key)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return Promise.resolve(cache.get(key)!);
    }
    waitingData.add(key);
    clearTimeout(timeout);
    if (!waitingPromise) {
        waitingPromise = new Promise((resolve, reject) => {
            stopWaiting = () =>
                fetchAvailability(endpoint).then(resolve).catch(reject);
        });
    }
    timeout = setTimeout(stopWaiting, 250);
    // Here cache.get(key) cannot be undefined because it has been set by fetchAvailability()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return waitingPromise.then(() => cache.get(key)!);
};

export const useAvailability = (
    oe: Partial<OE> | null,
    endpoint?: string
): { availability: IdlpDataWithProvenance[] } => {
    const ref = makeRef(oe);
    const [availability, setAvailability] = useState<IdlpDataWithProvenance[]>(
        []
    );
    const isMounted = useIsMounted();

    useEffect(() => {
        setAvailability((ref && cache.get(ref)) || []);
    }, [ref]);

    useEffect(() => {
        if (!oe || oe.type === "stockCareco" || !ref) {
            setAvailability([]);
            return;
        }
        getAvailabilityWithPooling(ref, endpoint)
            .then((av) => {
                if (isMounted()) {
                    setAvailability(av);
                }
            })
            .catch((error) => console.error(error));
    }, [endpoint, isMounted, oe, ref]);

    return { availability };
};
