import * as React from "react";
import ContentLoader from "react-content-loader";
import { Badge, UncontrolledTooltip } from "reactstrap";
import clsx from "classnames";

import {
    AppAlbum,
    AppAlbums,
    AppButton,
    AppDetails,
} from "@/components/AppAlbums";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useAPI } from "@/utils/hooks";
import { getLogoURL, getModelImage } from "@/utils/utils";

export const TopBrands = () => {
    const { data, error } = useAPI<BrandStats>("brandStats");
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    const stats = data.stats.slice().sort((b1, b2) => b2.nb - b1.nb);
    return (
        <div className="card">
            <div className="card-title">Top Marque Modèle Version</div>
            <TopBrandsContent stats={stats} total={data.total} />
        </div>
    );
};

const AppBrand = ({ label, percent }: { label: string; percent: string }) => {
    const ref = React.useRef(null);
    return (
        <>
            <img src={getLogoURL(label) || undefined} alt={label} ref={ref} />
            <Badge className="bottom-badge">{percent}%</Badge>
            <UncontrolledTooltip target={ref}>{label}</UncontrolledTooltip>
        </>
    );
};

const ItemRow = ({
    label,
    percent,
    onClick,
}: {
    label: string;
    percent: string;
    onClick?(): void;
}) => {
    return (
        <>
            <Badge>{percent}%</Badge>
            <button className="btn btn-none ms-2" onClick={onClick}>
                {label}
            </button>
        </>
    );
};

type ModelStat = { nb: number; model: string };
type ModelWithPercent = ModelStat & { percent: string };

const DEFAULT_MODEL_IMG = `${IMAGES_SERVER}/brands/vl/default.jpg`;
const ImageWithDefault = ({ src }: { src: string }) => {
    const [isOnError, setError] = React.useState(false);
    const imgSrc = isOnError ? DEFAULT_MODEL_IMG : src;

    return (
        <img
            src={imgSrc}
            alt=""
            width={70}
            height={70}
            onError={() => setError(true)}
        />
    );
};

const ModelButton = ({
    brand,
    model,
    onClick,
}: {
    brand: string;
    model: ModelWithPercent;
    onClick(): void;
}) => (
    <button
        type="button"
        className="marque btn text-left position-relative"
        onClick={onClick}
    >
        <ImageWithDefault src={getModelImage(brand, model.model)} />
        <Badge className="model-badge">{model.percent}%</Badge>
        <div className="model-name text-wrap">{model.model}</div>
    </button>
);

type KtypnrStat = { nb: number; ktype: number; label: string };
const KtypnrsList = ({
    ktypnrs,
}: {
    ktypnrs: Array<KtypnrStat & { percent: string }>;
}) => {
    return (
        <ul className="overflow-auto flex-grow-1">
            {ktypnrs.map((ktypnr) => (
                <li key={ktypnr.ktype}>
                    <ItemRow label={ktypnr.label} percent={ktypnr.percent} />
                </li>
            ))}
        </ul>
    );
};

const SkeletonRow = ({ n }: { n: number }) => (
    <>
        <rect x="2%" y={n * 30} rx="2" ry="2" width="5%" height="17" />
        <rect x="9%" y={n * 30} rx="2" ry="2" width="91%" height="17" />
    </>
);

const KtypnrSkeleton = (): JSX.Element => (
    <ContentLoader
        height={150}
        width="100%"
        className="mt-2"
        backgroundColor="#CCC"
    >
        {Array(5)
            .fill(0)
            .map((_, i) => (
                <SkeletonRow key={i} n={i} />
            ))}
    </ContentLoader>
);

const Ktypnrs = ({
    brand,
    model,
    total,
}: {
    brand: string;
    model: string;
    total: number;
}) => {
    const { data, error, isFetching } = useAPI<KtypnrStat[]>(
        `ktypnrStats-td/${brand}/${encodeURIComponent(model)}`
    );
    if (error) {
        return <ErrorMessage />;
    }
    if (!data || isFetching) {
        return <KtypnrSkeleton />;
    }
    const ktypnrs = data
        .map((k) => ({ ...k, percent: ((100 * k.nb) / total).toFixed(2) }))
        .sort((k1, k2) => k2.nb - k1.nb);
    return <KtypnrsList ktypnrs={ktypnrs} />;
};

const ModelsContent = ({
    brand,
    models,
    total,
}: {
    brand: string;
    models: ModelWithPercent[];
    total: number;
}) => {
    const [selectedModel, setSelectedModel] =
        React.useState<ModelWithPercent>();
    const toggleModel = (model: ModelWithPercent) =>
        setSelectedModel((currentModel) =>
            currentModel === model ? undefined : model
        );
    const ulClass = clsx("d-flex flex-wrap list-none overflow-auto", {
        "w-25": selectedModel !== undefined,
    });
    return (
        <div className="d-flex h-100">
            <ul className={ulClass}>
                {models.map((model) => (
                    <li key={model.model}>
                        <ModelButton
                            brand={brand}
                            model={model}
                            onClick={() => toggleModel(model)}
                        />
                    </li>
                ))}
            </ul>
            {selectedModel && (
                <Ktypnrs
                    brand={brand}
                    model={selectedModel.model}
                    total={total}
                />
            )}
        </div>
    );
};

const Models = ({ brand, total }: { brand: string; total: number }) => {
    const { data, error } = useAPI<ModelStat[]>(`modelStats-td/${brand}`);
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    const models = data
        .map((m) => ({ ...m, percent: ((100 * m.nb) / total).toFixed(2) }))
        .sort((m1, m2) => m2.nb - m1.nb);
    return <ModelsContent brand={brand} models={models} total={total} />;
};

type BrandStats = {
    stats: { make: string; nb: number; percent: string }[];
    total: number;
};

const TopBrandsContent = ({ stats, total }: BrandStats) => {
    const { hasAccess } = useAuth();
    const disableButtons = !hasAccess("statsModels");
    return (
        <AppAlbums className="flex-wrap p-2 gap-2">
            {stats.map((stat) => (
                <AppAlbum key={stat.make} id={stat.make}>
                    <AppButton disabled={disableButtons}>
                        <AppBrand label={stat.make} percent={stat.percent} />
                    </AppButton>
                    <AppDetails>
                        <Models brand={stat.make} total={total} />
                    </AppDetails>
                </AppAlbum>
            ))}
        </AppAlbums>
    );
};
