import * as React from "react";
import ImageGallery from "react-image-gallery";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

type IllustrationProps = {
    imgURL: string | string[];
    title: string;
};
export const Illustration = ({
    imgURL,
    title,
}: IllustrationProps): JSX.Element => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggleModal = () => {
        setModalOpen((open) => !open);
    };
    const imageURLs = Array.isArray(imgURL) ? imgURL : [imgURL];
    const items = imageURLs.map((original) => ({ original }));
    return (
        <>
            <img
                className="illustration"
                src={imageURLs[0]}
                alt="autodata"
                onClick={toggleModal}
            />
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
                <ModalBody>
                    <ImageGallery
                        items={items}
                        showThumbnails={false}
                        showPlayButton={imageURLs.length > 1}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};
