"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CRITAIR_GAZOLE_STEPS = exports.CRITAIR_ESSENCE_STEPS = exports.CRITAIR_TYPE_GAZOLE = exports.CRITAIR_TYPE_ESSENCE = exports.CRITAIR_TYPE_GAZ_HYBRID = exports.CRITAIR_TYPE_ELECTRIC = exports.EURO_NORMS = exports.CO2_STEPS = exports.IMAGES_SERVER = void 0;
exports.IMAGES_SERVER = "https://medias.ia.parts";
exports.CO2_STEPS = [
    { index: 100, label: "A" },
    { index: 120, label: "B" },
    { index: 140, label: "C" },
    { index: 160, label: "D" },
    { index: 200, label: "E" },
    { index: 250, label: "F" },
    { index: Infinity, label: "G" },
];
exports.EURO_NORMS = [
    { date: "01/09/2017", label: "Euro 6c" },
    { date: "01/09/2015", label: "Euro 6b" },
    { date: "01/01/2011", label: "Euro 5" },
    { date: "01/01/2006", label: "Euro 4" },
    { date: "01/01/2001", label: "Euro 3" },
    { date: "01/01/1996", label: "Euro 2" },
    { date: "01/01/1993", label: "Euro 1" },
];
exports.CRITAIR_TYPE_ELECTRIC = ["ELECTRIC"];
exports.CRITAIR_TYPE_GAZ_HYBRID = [
    "GAZ NAT.VEH",
    "G.P.L.",
    "CARB GAZEUX",
    "GAZOGENE",
];
exports.CRITAIR_TYPE_ESSENCE = ["ESSENCE"];
exports.CRITAIR_TYPE_GAZOLE = ["GAZOLE", "GAZ+ELEC HNR", "GAZOLE+GAZO"];
exports.CRITAIR_ESSENCE_STEPS = [
    { date: "01/01/2011", value: 1 },
    { date: "01/01/2006", value: 2 },
    { date: "01/01/1997", value: 3 },
    { date: "01/01/1400", value: 6 },
];
exports.CRITAIR_GAZOLE_STEPS = [
    { date: "01/01/2011", value: 2 },
    { date: "01/01/2006", value: 3 },
    { date: "01/01/2001", value: 4 },
    { date: "01/01/1997", value: 5 },
    { date: "01/01/1400", value: 6 },
];
