import type * as React from "react";
import classnames from "classnames";

import { AppsWidget } from "@/components/AppsWidget";
import { HideRSEButton } from "@/components/RSE/HideRSEButton";
import { RestoreSlidesButtons } from "@/components/RSE/RestoreSlidesButton";
import { SlideVisibilityWrapper } from "@/components/RSE/SlideVisibilityWrapper";
import { RSE_IFRAME_SLIDER_URL, showWPSearchBar } from "@/config/settings";
import { RSE_WIDGETS, WIDGET_LABELS } from "@/pages/RSEEditor/RSEAppsContext";

import { NewsSlider } from "../ContactSelector/NewsSlider";

import "@/pages/ContactSelector/ContactSelectorPanel.css";

const IframeSlider = () => {
    if (!RSE_IFRAME_SLIDER_URL) {
        return null;
    }
    return (
        <div className="iframe-slider">
            <iframe src={RSE_IFRAME_SLIDER_URL} className="w-100 h-100" />
        </div>
    );
};

const Content = () => {
    return (
        <>
            <NewsSlider />
            <IframeSlider />
            {RSE_WIDGETS.map((widget) => (
                <div key={widget}>
                    <AppsWidget widget={widget}>
                        <div>{WIDGET_LABELS[widget]}</div>
                    </AppsWidget>
                </div>
            ))}
        </>
    );
};

const TopBar = ({ onClose }: { onClose(): void }) => {
    return (
        <div
            className="text-right"
            style={{ marginTop: -20, marginRight: -16 }}
        >
            <RestoreSlidesButtons widget="rse-initial-slides" />
            <HideRSEButton callback={onClose} />
        </div>
    );
};

type Props = {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ContactSelectorPanelRSE = ({ setVisible }: Props): JSX.Element => {
    const classname = classnames("rse", { "mt-2": showWPSearchBar });
    return (
        <SlideVisibilityWrapper
            setVisible={setVisible}
            href="/rse"
            className={classname}
        >
            <TopBar onClose={() => setVisible(false)} />
            <Content />
        </SlideVisibilityWrapper>
    );
};
