import * as React from "react";
import { QRCodeSVG } from "qrcode.react";

export const QRCodeScreen = ({
    id,
    size = 100,
}: {
    id: number | undefined;
    size?: number;
}) => {
    if (!id) {
        return null;
    }
    const url = `${window.location.origin}/screen/${id}`;
    return <QRCodeSVG value={url} size={size} level="Q" />;
};
