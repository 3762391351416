import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { DBWidget } from "shared/dist/types/apps";

import { i18n } from "@/config/i18n";
import { fetchWithAuth, postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

type Props = {
    widget: DBWidget;
    toggle(): void;
    isOpen: boolean;
    title: string;
    setWidgets: React.Dispatch<React.SetStateAction<DBWidget[] | null>>;
};

type TFormData = Omit<DBWidget, "id">;

export const WidgetWizard = ({
    isOpen,
    title,
    toggle,
    widget,
    setWidgets,
}: Props) => {
    const { handleSubmit, register } = useForm<TFormData>({
        defaultValues: {
            name: widget.name,
            title: widget.title,
            subtitle: widget.subtitle,
            img: widget.img,
        },
    });

    const submit = async (data: TFormData) => {
        try {
            const body = {
                id: widget.id,
                ...data,
            };
            const response = await postContent("apps/widget", body);
            const widgets = await response.json();
            if ("error" in widgets) {
                toastr.warning(
                    `Le nom ${data.name} n'est pas disponible, merci d'en choisir un autre`
                );
                return;
            }
            setWidgets(widgets);
            toggle();
            if (widget.id) {
                toastr.success("Le widget a été modifié");
            } else {
                toastr.success("Le widget a été créé");
            }
        } catch (error) {
            console.error(error);
            toastr.error(i18n.t("common.error"));
        }
    };

    const deleteWidget = () => {
        toggle();
        setWidgets(
            (widgets) => widgets?.filter((w) => w.id !== widget.id) ?? null
        );
    };

    return (
        <Modal isOpen={isOpen} size="lg" toggle={toggle}>
            <form onSubmit={handleSubmit(submit)}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody className="d-flex flex-column gap-2">
                    <div className="marque align-self-end">
                        <img src={widget.img} width={70} height={70} />
                    </div>
                    <div className="d-flex">
                        <label className="me-4 col-form-label">
                            <span>Nom</span>
                        </label>
                        <div className="d-flex flex-column flex-grow-1">
                            <input
                                type="text"
                                className="form-control"
                                maxLength={200}
                                {...register("name", { required: true })}
                                disabled={Boolean(widget.id)}
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <label className="me-4 col-form-label">
                            <span>Titre</span>
                        </label>
                        <div className="d-flex flex-column flex-grow-1">
                            <input
                                type="text"
                                className="form-control"
                                maxLength={200}
                                {...register("title")}
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <label className="me-4 col-form-label">
                            <span>Sous-titre</span>
                        </label>
                        <div className="d-flex flex-column flex-grow-1">
                            <input
                                type="text"
                                className="form-control"
                                maxLength={200}
                                {...register("subtitle")}
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <label className="me-4 col-form-label">
                            <span>URL de l&rsquo;image</span>
                        </label>
                        <div className="d-flex flex-column flex-grow-1">
                            <input
                                type="text"
                                className="form-control"
                                maxLength={255}
                                {...register("img")}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {widget.id ? (
                        <DeleteWidget widget={widget} onDelete={deleteWidget} />
                    ) : null}
                    <Button color="none" type="submit">
                        <FontAwesomeIcon
                            icon={faCheck}
                            role="button"
                            size="2x"
                            className="text-success"
                        />
                    </Button>
                    <Button color="none" type="button" onClick={toggle}>
                        <FontAwesomeIcon
                            icon={faTimes}
                            role="button"
                            size="2x"
                            className="text-secondary"
                        />
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

const DeleteWidget = ({
    widget,
    onDelete,
}: {
    widget: DBWidget;
    onDelete(): void;
}) => {
    const [modalOpen, toggle] = React.useReducer((o) => !o, false);

    const deleteApp = async () => {
        try {
            await fetchWithAuth(`apps/widget/${widget.id}`, {
                method: "delete",
            });
            toastr.success(`Le widget ${widget.name} a été supprimé`);
            toggle();
            onDelete();
        } catch (error) {
            console.error(error);
            toastr.error("Impossible de supprimer le widget");
        }
    };

    return (
        <>
            <Button color="danger" className="me-auto" onClick={toggle}>
                <span>Supprimer</span>
            </Button>
            <Modal isOpen={modalOpen} toggle={toggle}>
                <ModalBody>
                    Êtes vous sûr de vouloir supprimer le widget {widget.name} ?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={deleteApp}>
                        Supprimer
                    </Button>
                    <Button color="secondary" type="button" onClick={toggle}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
