import * as React from "react";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";

import type {
    AuthInfo,
    Company,
    UserAccess,
    UserInfo,
} from "shared/dist/types/auth";

import { i18n } from "@/config/i18n";
import { apiRootURL, USE_KEYCLOAK } from "@/config/settings";
import { getContent } from "@/utils/fetch";
import { keycloakInstance } from "@/utils/keycloak";
import { toastr } from "@/utils/toastr";

type AuthContextValue = [
    AuthInfo,
    React.Dispatch<React.SetStateAction<AuthInfo>>
];

const AuthContext = React.createContext<AuthContextValue | undefined>(
    undefined
);

export const getToken = (): string | null => localStorage.getItem("token");

const timeoutRef = {
    logoutWarning: undefined as NodeJS.Timeout | undefined,
};

const Provider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const authInfo = React.useState<AuthInfo>(() => {
        const token = getToken();
        const expiresAtRaw = localStorage.getItem("expiresAt");
        const expiresAt = expiresAtRaw ? Number(expiresAtRaw) : null;
        return {
            token,
            expiresAt,
            userInfo: null,
        };
    });

    const [authState, setAuthState] = authInfo;

    React.useEffect(() => {
        if (
            authState.token &&
            !authState.userInfo?.userId &&
            authState.expiresAt &&
            Date.now() / 1000 < authState.expiresAt
        ) {
            getContent<UserInfo>("userInfo", {
                ignoreCache: true,
            }).then((userInfo) =>
                setAuthState((state) => ({ ...state, userInfo }))
            );
        }
    }, [authState, setAuthState]);

    return (
        <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
    );
};

const getUserData = async (token: string) => {
    const Authorization = "Bearer " + token;
    const res = await fetch(`${apiRootURL}/api/sso`, {
        headers: { Authorization },
    });
    if (!res.ok) {
        console.error("oups");
        return null;
    }
    const data: AuthInfo = await res.json();
    return data;
};

const scheduleDisconnectWarning = (expiresAt: number | null) => {
    clearTimeout(timeoutRef.logoutWarning);
    if (!expiresAt) return;
    const delay = expiresAt * 1000 - Date.now();
    timeoutRef.logoutWarning = setTimeout(() => {
        toastr.warning(i18n.t("login.disconnected"), {
            autoClose: false,
            onClick: () => window.location.reload(),
        });
    }, delay);
};

const KeycloakMapper = ({ children }: { children: React.ReactNode }) => {
    const { keycloak, initialized } = useKeycloak();
    const { setAuthInfo } = useLogin();
    React.useEffect(() => {
        if (!initialized) {
            return;
        }
        if (keycloak.authenticated && keycloak.token) {
            getUserData(keycloak.token).then((data) => {
                if (data) {
                    setAuthInfo(data);
                }
            });
        } else {
            setAuthInfo({ token: null, expiresAt: null, userInfo: null });
        }
    }, [keycloak.authenticated, keycloak.token, setAuthInfo, initialized]);
    return <>{children}</>;
};

const keycloakInitOptions = {
    silentCheckSsoRedirectUri: window.location.origin + "/silent-sso",
};

const KeycloakProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const authInfo = React.useState<AuthInfo>({
        token: "isLoading",
        expiresAt: null,
        userInfo: null,
    });
    return (
        <ReactKeycloakProvider
            authClient={keycloakInstance}
            initOptions={keycloakInitOptions}
        >
            <AuthContext.Provider value={authInfo}>
                <KeycloakMapper>{children}</KeycloakMapper>
            </AuthContext.Provider>
        </ReactKeycloakProvider>
    );
};

export const AuthProvider = USE_KEYCLOAK ? KeycloakProvider : Provider;

const useAuthContext = () => {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error("useAuthContext must be used within an AuthProvider");
    }
    return context;
};

export const useLogin = (): {
    isLoading: boolean;
    hasUserInfo: boolean;
    setAuthInfo: (authInfo: AuthInfo) => void;
} => {
    const [authState, setAuthState] = useAuthContext();

    const { userInfo } = authState;

    const setAuthInfo = React.useCallback(
        (authInfo: AuthInfo) => {
            localStorage.setItem("token", authInfo.token ?? "");
            localStorage.setItem("expiresAt", String(authInfo.expiresAt));
            scheduleDisconnectWarning(authInfo.expiresAt);
            setAuthState(authInfo);
        },
        [setAuthState]
    );

    const isLoading =
        authState.token === "isLoading" ||
        (Boolean(authState.token) &&
            !userInfo?.userId &&
            Boolean(
                authState.expiresAt && Date.now() / 1000 < authState.expiresAt
            ));
    const hasUserInfo = userInfo !== null;

    return {
        isLoading,
        hasUserInfo,
        setAuthInfo,
    };
};

type UpdateUser = {
    setUserInfo(info: UserInfo): void;
    switchToCompany(companyId: number): void;
};
export const useUpdateUser = (): UpdateUser => {
    const [authState, setAuthState] = useAuthContext();
    const { setAuthInfo } = useLogin();

    const { userInfo } = authState;
    const userInfoCompanyId = userInfo?.companyId;

    const setUserInfo = React.useCallback(
        (info: UserInfo) => {
            setAuthState((state) => ({ ...state, userInfo: info }));
        },
        [setAuthState]
    );

    const switchToCompany = React.useCallback(
        async (companyId: number) => {
            if (companyId === userInfoCompanyId) return Promise.resolve();
            const authInfo = await getContent<AuthInfo>(
                `switchToCompany/${companyId}`,
                { ignoreCache: true }
            );
            return setAuthInfo(authInfo);
        },
        [setAuthInfo, userInfoCompanyId]
    );

    return { setUserInfo, switchToCompany };
};

const useLocalLogout = (): (() => void) => {
    const [, setAuthState] = useAuthContext();
    const logout = React.useCallback(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("expiresAt");
        setAuthState({ token: null, expiresAt: null, userInfo: null });
    }, [setAuthState]);
    return logout;
};

const useKeycloakLogout = () => {
    const localLogout = useLocalLogout();
    const { keycloak } = useKeycloak();
    return () => {
        keycloak.logout();
        localLogout();
    };
};

export const useLogout = USE_KEYCLOAK ? useKeycloakLogout : useLocalLogout;

type UserData = {
    company: Company | undefined;
    hasAccess(to: UserAccess): boolean;
    userInfo: UserInfo;
};

export const useAuth = (): UserData => {
    const [authState] = useAuthContext();

    const { userInfo } = authState;
    if (userInfo === null) {
        throw new Error("useAuth must be used within a PrivateRoute");
    }
    const hasAccess = React.useCallback(
        (to: UserAccess) => userInfo.rights.includes(to),
        [userInfo]
    );

    const userInfoCompanyId = userInfo.companyId;
    const company = userInfo.companies[userInfoCompanyId];
    const userData = React.useMemo(
        () => ({ userInfo, company, hasAccess }),
        [company, hasAccess, userInfo]
    );
    return userData;
};

export const useIsLogged = (): boolean => {
    const [authState] = useAuthContext();
    const { userInfo } = authState;
    return userInfo !== null;
};

const DEFAULT_KEYCLOAK_ROLES = [
    "admin",
    "offline_access",
    "uma_authorization",
    `default-roles-${process.env.REACT_APP_KEYCLOAK_REALM?.toLowerCase()}`,
];

const useKeycloakRoles = () => {
    const { keycloak } = useKeycloak();
    const roles = keycloak.realmAccess?.roles || [];
    return roles.filter((role) => !DEFAULT_KEYCLOAK_ROLES.includes(role));
};

export const useRoles = USE_KEYCLOAK ? useKeycloakRoles : () => [];
