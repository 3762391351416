import * as React from "react";
import { Link } from "react-router-dom";

import { apiRootURL, dockApps } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";

import "./Dock.css";

const IMG_PATH = `${apiRootURL}/media/dock/`;

export const Dock = (): JSX.Element | null => {
    const { hasAccess } = useAuth();
    const showDock = hasAccess("Dock") && !process.env.REACT_APP_HIDE_DOCK;
    if (!showDock) return null;
    return (
        <>
            <div className="dock-background" key="0"></div>
            <div className="dock" key="1">
                {dockApps.map((app) => (
                    <Link to={"/app/" + app} className="app" key={app}>
                        <img
                            className="app-logo"
                            src={`${IMG_PATH}${app}.png`}
                            alt={app}
                            width="50px"
                            height="50px"
                        ></img>
                        <img
                            className="app-logo-reflection"
                            src={`${IMG_PATH}${app}.png`}
                            alt=""
                            width="50px"
                            height="15px"
                        ></img>
                    </Link>
                ))}
            </div>
        </>
    );
};
