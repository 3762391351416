import * as React from "react";
import { useTranslation } from "react-i18next";

import type { Channel, OEChannel, RawCartItem } from "shared/dist/types/cart";
import type { OE } from "shared/dist/types/item";
import type { Tire } from "shared/dist/types/parts";

import { getAvailability } from "@/utils/availability";
import { fetchWithAuth, getContent, postContent } from "@/utils/fetch";
import { useGenarts } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { useCustomer } from "./CustomerContext";

const { useState, useEffect } = React;

type State = {
    items: OE[];
    wishlist: OE[];
    comparelist: OE[];
    cart: RawCartItem[];
    updateItems: (items: OE[]) => void;
    updateWishlist: (wishlist: OE[]) => void;
    updateComparelist: (comparelist: OE[]) => void;
    updateCart: React.Dispatch<React.SetStateAction<RawCartItem[]>>;
};

const noop = (): void => {};

const initialState: State = {
    items: [],
    wishlist: [],
    comparelist: [],
    cart: [],
    updateItems: noop,
    updateWishlist: noop,
    updateComparelist: noop,
    updateCart: noop,
};

export const CartContext = React.createContext<State>(initialState);

type Props = {
    children: React.ReactNode;
};

const getCart = (customerId: number): Promise<RawCartItem[]> => {
    return getContent<RawCartItem[]>(`getCart/${customerId}`, {
        ignoreCache: true,
    });
};

export const CartItems = (props: Props): JSX.Element => {
    const [items, updateItems] = useState<OE[]>([]);
    const [wishlist, updateWishlist] = useState<OE[]>([]);
    const [comparelist, updateComparelist] = useState<OE[]>([]);
    const [cart, updateCart] = useState<RawCartItem[]>([]);
    const [customer] = useCustomer();
    const customerId = customer?.id;

    useEffect(() => {
        if (!customerId) {
            updateCart([]);
            return;
        }
        getCart(customerId)
            .then((rawCart) => updateCart(rawCart))
            .catch((error) => console.error(error));
    }, [customerId]);

    const state = {
        items,
        wishlist,
        comparelist,
        updateItems,
        updateWishlist,
        updateComparelist,
        cart,
        updateCart,
    };

    return (
        <CartContext.Provider value={state}>
            {props.children}
        </CartContext.Provider>
    );
};

const getProductPrice = (item: OE, channel: Channel): string => {
    let price = String(item.price);
    if (channel === "IDLP" || channel === "PAP" || channel === "Techauto") {
        const availability = getAvailability(item);
        const data = availability.find((d) => d.from.startsWith(channel));
        price = data?.prix?.prixBase.replace(",", ".") || "";
    }
    return price;
};

type ItemBody = {
    item: string;
    name: string;
    price: string;
    quantity: number;
    ktype: string | null | undefined;
    vin: string | undefined | null;
};

const addToCart = (
    body: ItemBody,
    successMessage: string
): Promise<{ id: number }> => {
    return postContent("addItemToCart", body)
        .then((answer) => {
            toastr.success(successMessage);
            return answer.json();
        })
        .catch((error) => {
            toastr.error(
                `Impossible d'ajouter l'article ${body.name} au panier`
            );
            throw error;
        });
};

const itemEquals = (a: OE, b: OE): boolean => {
    return (
        a.id === b.id &&
        a.ean === b.ean &&
        a.makeId === b.makeId &&
        a.genartId === b.genartId &&
        a.articleId === b.articleId &&
        a.photos === b.photos
    );
};

const updateQuantity = async (
    customerId: number | undefined,
    existingItem: RawCartItem,
    updateCart: React.Dispatch<React.SetStateAction<RawCartItem[]>>,
    cart: RawCartItem[]
): Promise<void> => {
    const quantity = existingItem.quantity + 1;
    try {
        await fetchWithAuth(
            `updateQuantity/${customerId}/${existingItem.id}/${quantity}`
        );
        updateCart((oldCarts) => {
            const updatedItem = cart.find((it) => it.id === existingItem.id);
            if (updatedItem) {
                updatedItem.quantity += 1;
            }
            return [...oldCarts];
        });
        toastr.info(`Quantité modifiée à ${quantity}`);
    } catch (error) {
        console.error(error);
        toastr.error("Impossible de modifier la quantité");
    }
};

export const useAddToCart = (): ((
    item: OE,
    channel: OEChannel,
    vin?: string | undefined,
    ktype?: string | undefined
) => Promise<void>) => {
    const { cart, updateCart } = React.useContext(CartContext);
    const { t } = useTranslation();
    const { genartsMap } = useGenarts();
    const [customer] = useCustomer();
    const customerId = customer?.id;
    return async (
        item: OE,
        channel: OEChannel,
        vin: string | undefined,
        ktype: string | undefined
    ): Promise<void> => {
        if (!customerId) {
            toastr.warning(
                "Veuillez sélectionner un client pour pouvoir ajouter au panier"
            );
            return;
        }
        const price = getProductPrice(item, channel);
        if (Number(price) === 0 || isNaN(Number(price))) {
            toastr.warning(
                "Impossible d'ajouter au panier un article sans prix"
            );
            console.log(`No price for item ${item.id}`);
            return;
        }
        const existingItem = cart.find(
            (cartItem) =>
                cartItem.channel === channel &&
                "makeId" in cartItem.item &&
                itemEquals(item, cartItem.item)
        );
        if (existingItem) {
            return updateQuantity(customerId, existingItem, updateCart, cart);
        }
        const name = `${
            (item.genartId != undefined && genartsMap[item.genartId]?.label) ||
            ""
        } ${item.id}`;
        const clonedItem: OE = { ...item };
        const body = {
            item: JSON.stringify(clonedItem),
            name,
            price,
            quantity: 1,
            channel,
            vin,
            ktype,
            customerId,
        };
        const message = t("item.added-to-cart", { item: body.name });
        return addToCart(body, message)
            .then(({ id }) => {
                updateCart((oldCart) => {
                    const cartItem = {
                        id,
                        name,
                        channel,
                        price: Number(price) * 100,
                        quantity: 1,
                        item: clonedItem,
                        vin,
                        ktype,
                    };
                    oldCart.push(cartItem);
                    return [...oldCart];
                });
            })
            .catch((error) => console.error(error));
    };
};

const tireEquals = (a: Tire, b: Tire): boolean =>
    a.ean === b.ean &&
    a.designation === b.designation &&
    a.marque === b.marque &&
    a["prix vente moyen TTC"] === b["prix vente moyen TTC"];

export const useAddTireToCart = (): ((
    tire: Tire,
    vin: string | null,
    ktype: string | null
) => Promise<void>) => {
    const { cart, updateCart } = React.useContext(CartContext);
    const { t } = useTranslation();
    const [customer] = useCustomer();
    const customerId = customer?.id;

    return (
        tire: Tire,
        vin: string | null,
        ktype: string | null
    ): Promise<void> => {
        const existingItem = cart.find(
            (cartItem) =>
                cartItem.channel === "Tire" &&
                "saison" in cartItem.item &&
                tireEquals(tire, cartItem.item)
        );
        if (existingItem) {
            return updateQuantity(customerId, existingItem, updateCart, cart);
        }

        const price = (Number(tire["prix vente moyen TTC"]) / 1.2).toFixed(2);
        const body = {
            item: JSON.stringify(tire),
            name: tire.designation,
            price,
            quantity: 2,
            channel: "Tire",
            vin,
            ktype,
            customerId,
        };
        const message = t("item.added-to-cart", { item: body.name });
        return addToCart(body, message)
            .then(({ id }) => {
                updateCart((oldCart) => {
                    const cartItem: RawCartItem = {
                        id,
                        channel: "Tire",
                        name: tire.designation,
                        price: Number(price) * 100,
                        quantity: 2,
                        item: tire,
                        vin,
                        ktype,
                    };
                    oldCart.push(cartItem);
                    return [...oldCart];
                });
            })
            .catch((error) => console.error(error));
    };
};
