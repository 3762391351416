import * as React from "react";
import { useRouteMatch } from "react-router-dom";

import { LazyIframe } from "@/components/LazyIframe";
import { LEVAM_URL } from "@/config/settings";
import { HUB_IDLP_HOST, iframes } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { VehicleDataContext } from "@/context/VehicleDataContext";
import { fetchWithAuth } from "@/utils/fetch";

import { iframeSlidesCaridentifier } from "./settings";

const getWorkshopDataURL = (
    vin: string | undefined,
    ktype: string | undefined,
    userId: number
): string => {
    const PROXY_WORKSHOPDATA_URL = process.env.REACT_APP_PROXY_WORKSHOPDATA_URL;
    if ((!vin && !ktype) || !PROXY_WORKSHOPDATA_URL) {
        return "";
    }
    const url = `${PROXY_WORKSHOPDATA_URL}/catia-${userId}/search.do?method=make&search=true&keyword=`;
    const workshopdataURL = vin
        ? `${url}${vin}&termType=5`
        : `${url}${ktype}&termType=2`;
    return workshopdataURL;
};

export const IframesWithContext = (): JSX.Element => {
    const [vehicleData] = React.useContext(VehicleDataContext);
    const [autodataURL, setAutodataURL] = React.useState<string>();
    const tecrmiPath = React.useRef<string>();
    const { userInfo, hasAccess } = useAuth();

    const { ktype, Mid_Autodata: mid, Codif_Vin_PRF: vin } = vehicleData;

    const updateAutodatURL = (): (() => void) => {
        if (!hasAccess("AutoData")) {
            return () => {};
        }
        let unmounted = false;
        fetchWithAuth("requestAutodataToken")
            .then((res) => res.text())
            .then((url) => {
                if (unmounted) {
                    return;
                }
                const newAutodataURL = mid
                    ? `${url}&VehicleId=${mid}&VehicleIdType=MID`
                    : `${url}&VehicleId=${ktype}&VehicleIdType=TECDOC`;
                setAutodataURL(newAutodataURL);
            })
            .catch((error) => {
                console.error(error);
            });
        return (): void => {
            unmounted = true;
        };
    };

    React.useEffect(updateAutodatURL, [ktype, mid, hasAccess]);
    React.useEffect(() => {
        const num = Math.floor(Math.random() * 50);
        const id = window.btoa("caia" + num + "#_#demo");
        console.log("IframeTecvrc", "caia" + num, id);
        tecrmiPath.current = `${iframes.tecrmi}/search.php?id=${id}&type=ktyp&val=`;
    }, []);

    const tecrmiURL = ktype ? tecrmiPath.current + ktype : "";
    const workshopdataURL = getWorkshopDataURL(vin, ktype, userInfo.userId);

    const match = useRouteMatch();
    const matchIframe = useRouteMatch<{ appId: string }>({
        path: `${match.url}/app/:appId`,
    });
    const oemURL = vin ? `${LEVAM_URL}?vin=${vin}` : LEVAM_URL;

    const isVisible = (appId: string): boolean => {
        const id =
            hasAccess("OEM") && /^-?[0-9]+$/g.test(appId)
                ? String(Number(appId) + 1)
                : appId;
        return id === matchIframe?.params.appId;
    };

    return (
        <>
            <LazyIframe
                className="iframe-slide"
                src={autodataURL}
                title="autodata"
                visible={isVisible("autodata")}
                restricted
            />
            <LazyIframe
                className="iframe-slide"
                src={tecrmiURL}
                title="tecrmi"
                visible={hasAccess("TecRMI") && isVisible("tecrmi")}
                restricted
            />
            <LazyIframe
                className="iframe-slide"
                src={workshopdataURL}
                title="workshopdata"
                visible={hasAccess("WorkshopData") && isVisible("workshopdata")}
                restricted
            />
            <LazyIframe
                className="iframe-slide"
                src={oemURL}
                title="oem-levam"
                visible={hasAccess("OEM") && isVisible("-1")}
            />
            {iframeSlidesCaridentifier.map(({ title, makeURL }, i) => (
                <LazyIframe
                    key={title}
                    title={`iframe-caridentifier-${i}`}
                    className="iframe-slide"
                    src={vehicleData.Marque ? makeURL(vehicleData) : ""}
                    visible={isVisible(String(i))}
                />
            ))}
            <LazyIframe
                className="iframe-slide"
                title="HubIdlp"
                src={`${HUB_IDLP_HOST}/C@iA-${userInfo.userId}`}
                visible={hasAccess("HubIdlp") && isVisible("hubidlp")}
                restricted
            />
        </>
    );
};
