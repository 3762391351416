import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilValue } from "recoil";

import { loadingState } from "@/globalState/atoms";

type Props = {
    onClick?: () => void;
};

export const TopIcon = ({ onClick = () => {} }: Props): JSX.Element => {
    const loading = useRecoilValue(loadingState);
    if (loading) {
        return (
            <FontAwesomeIcon
                className="spinner"
                icon="spinner"
                size="2x"
                spin
            />
        );
    }
    return (
        <button className="btn btn-link menu-toggler" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="30"
                height="30"
                focusable="false"
            >
                <title>Menu</title>
                <path
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    d="M4 7h22M4 15h22M4 23h22"
                ></path>
            </svg>
        </button>
    );
};
