import * as React from "react";
import { Alert } from "reactstrap";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useAPI } from "@/utils/hooks";

const makeBlobURL = (data: string) => {
    const html = window.atob(data);
    const { origin } = window.location;
    const fixedHtml = html
        .replace("charset=iso-8859-1", 'charset="UTF-8"')
        .replace(
            "</head>",
            `<link rel="stylesheet" href="${origin}/media/techauto.css"></head>`
        )
        .replaceAll(
            "http://www.maricosu.ch",
            "https://medias.ia.parts/www.techautocenter.ch"
        );
    const blob = new Blob([fixedHtml], { type: "text/html" });
    return URL.createObjectURL(blob);
};

const IframeOrder = ({ data }: { data: string }) => {
    const src = makeBlobURL(data);
    return <iframe src={src} className="flex-grow-1" />;
};

type TechautoOrderAPI =
    | { error: string; msg: string }
    | { status: string; data: string };

type Props = {
    orderId: string;
};

const OrderContent = ({ orderId }: Props) => {
    const { data, error } = useAPI<TechautoOrderAPI>(`order/${orderId}`);
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    if ("error" in data) {
        return <Alert color="warning">{data.msg}</Alert>;
    }
    return <IframeOrder data={data.data} />;
};

export const TechautoOrder = ({ orderId }: Props): JSX.Element => {
    return (
        <div className="techauto-order flex-grow-1 d-flex flex-column">
            <h3>Commande n°{orderId}</h3>
            <OrderContent orderId={orderId} />
        </div>
    );
};
