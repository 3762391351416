import * as React from "react";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { NoResults } from "@/components/NoResults";
import { IMAGES_SERVER } from "@/config/settings";
import { FetchError, getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { Illustration } from "./Illustration";

type AcRechargeProps = {
    mid: string;
};

type AcRechargeItem = {
    type: "text" | "image";
    id: string;
    description: string;
    value: string;
    other: unknown;
    unit: unknown;
    qualifier: unknown;
    note: string;
};

type AcRechargeNote = {
    id: string;
    name: string;
    content: {
        type: string;
        value: string;
        sort_order: number;
    }[];
};

type AcRechargeData = {
    ac_recharge_items: AcRechargeItem[];
    __notes?: AcRechargeNote[];
    __images: {
        id: string;
        graphic: {
            id: string;
            url: string;
            expires: number;
        };
    }[];
}[];

const MARGIN_RIGHT_5 = { marginRight: 5 };

const showStickyToast = (item: JSX.Element) => () => {
    toastr.info(
        <div>
            <img src={`${IMAGES_SERVER}/logos/autodata.png`} alt="autodata" />
            <div>{item}</div>
        </div>,
        { closeButton: true, autoClose: false }
    );
};

const ItemLine = ({ item }: { item: AcRechargeItem }) => (
    <>
        {item.description}:{" "}
        <strong>
            {item.value}
            {item.unit ? " " + item.unit : ""}
        </strong>
        {item.qualifier ? ` (${item.qualifier})` : ""}
    </>
);

const AcRechargeListItem = ({
    item,
    notes,
}: {
    item: AcRechargeItem;
    notes?: AcRechargeNote[];
}) => {
    const itemNote = notes?.find((note) => note.id === item.note);
    return (
        <div>
            <span onClick={showStickyToast(<ItemLine item={item} />)}>
                <ItemLine item={item} />
            </span>
            {itemNote && (
                <ul className="item-notes">
                    {itemNote.content.map((content) => (
                        <li key={content.sort_order}>{content.value}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const DEFAULT_ILLUSTRATION = `${IMAGES_SERVER}/logos/autodata.webp`;

const findTitle = (data: AcRechargeData): string =>
    data?.[0].ac_recharge_items?.[0].description ?? "";

type Status = "IDLE" | "LOADING" | "ERROR" | "NO_RESULTS" | "OK";

const AcRechargeLoader = ({ mid }: AcRechargeProps) => {
    const [status, setStatus] = React.useState<Status>("IDLE");
    const [data, setData] = React.useState<AcRechargeData | null>(null);
    const lang = useLanguage();

    React.useEffect(() => {
        setStatus("LOADING");
        getContent<AcRechargeData>(`autodata/acRecharge/${mid}/${lang}`)
            .then((acRechargeData) => {
                setData(acRechargeData);
                setStatus("OK");
            })
            .catch((error: unknown) => {
                if (error instanceof FetchError && error.status === 404) {
                    setStatus("NO_RESULTS");
                } else {
                    console.error(error);
                    setStatus("ERROR");
                }
            });
    }, [mid, lang]);

    if (status === "IDLE") {
        return null;
    }

    if (status === "LOADING") {
        return <Loading />;
    }

    if (status === "NO_RESULTS") {
        return <NoResults />;
    }

    if (status === "ERROR" || !data) {
        return <ErrorMessage />;
    }

    return <AcRechargeContent data={data} />;
};

const AcRechargeContent = ({
    data,
}: {
    data: AcRechargeData;
}): JSX.Element | null => {
    const [illustration, setIllustration] =
        React.useState(DEFAULT_ILLUSTRATION);

    React.useEffect(() => {
        const imageId = data?.[0]?.ac_recharge_items.filter(
            (item) => item.type === "image"
        )?.[0]?.value;
        if (!imageId) return;
        const image = data
            .flatMap((row) => row.__images)
            .find((img) => img.id === imageId);
        if (!image) return;
        setIllustration(image.graphic.url);
    }, [data]);

    const handleIllustration = (imageId: string) => () => {
        const image = data
            .flatMap((row) => row.__images)
            .find((img) => img.id === imageId);
        if (!image) return;
        if (illustration === image.graphic.url) {
            setIllustration(DEFAULT_ILLUSTRATION);
        } else {
            setIllustration(image.graphic.url);
        }
    };

    return (
        <>
            <ul>
                {data.map((row) =>
                    row.ac_recharge_items
                        .filter((item) => item.type === "image")
                        .map((item) => (
                            <li
                                key={item.id}
                                onClick={handleIllustration(item.value)}
                            >
                                <FontAwesomeIcon
                                    icon={faImage}
                                    style={MARGIN_RIGHT_5}
                                />
                                {item.description}
                            </li>
                        ))
                )}
                {data.map((row) =>
                    row.ac_recharge_items
                        .filter((item) => item.type === "text")
                        .map((item) => (
                            <li key={item.id}>
                                <AcRechargeListItem
                                    item={item}
                                    notes={row.__notes}
                                />
                            </li>
                        ))
                )}
            </ul>
            <Illustration imgURL={illustration} title={findTitle(data)} />
        </>
    );
};

export const AcRecharge = ({
    mid,
    hide = false,
}: AcRechargeProps & { hide?: boolean }): JSX.Element | null => (
    <div className={hide ? "d-none" : "appoemnart d-flex flex-grow-1"}>
        <AcRechargeLoader mid={mid} />
    </div>
);
