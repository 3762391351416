import * as React from "react";
import classnames from "classnames";

import { ContactSelectorSearch } from "@/components/ContactSelectorSearch";
import { DiagSearch } from "@/components/DiagSearch";
import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { PartSelectorSearch } from "@/components/PartSelectorSearch";
import { CarSelectorSearch } from "@/pages/CarSelector/CarSelectorSearch";
import { SearchTires } from "@/v2/CarSelector/SearchTires";
import { SearchVin } from "@/v2/CarSelector/SearchVin";
import { useHubidlp } from "@/v2/HubIdlpContext";

const SEARCH_TYPES = [
    "vin",
    "carselector",
    "partselector",
    "tires",
    "diag",
    "contactselector",
] as const;
export type SearchType = typeof SEARCH_TYPES[number];

export const SearchBar = ({
    searchType,
}: {
    searchType: SearchType;
}): JSX.Element => {
    const { addPartsSearchSlide } = useAddItemSlide();
    const { setSearch } = useHubidlp();
    const onSubmit = (search: string) => {
        addPartsSearchSlide(search);
        setSearch(search);
    };
    return (
        <div className="search-bar d-flex">
            <SearchVin show={searchType === "vin"} />
            <CarSelectorSearch show={searchType === "carselector"} />
            <PartSelectorSearch
                show={searchType === "partselector"}
                onSubmit={onSubmit}
            />
            <SearchTires show={searchType === "tires"} />
            <div
                className={classnames(
                    "flex flex-grow-1",
                    searchType === "diag" ? "d-flex" : "d-none"
                )}
            >
                <DiagSearch />
            </div>
            <ContactSelectorSearch show={searchType === "contactselector"} />
        </div>
    );
};
