import * as React from "react";

import type { PageWidget } from "shared/dist/types/apps";

import { DndWidget } from "@/components/AppsWidget";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useVehicleData } from "@/context/VehicleDataContext";
import { useAPI } from "@/utils/hooks";

import GenartCarousel from "./GenartCarousel";
import GenartTagSelector from "./GenartTagSelector";

interface Props {
    handleGenartTagSubmit?: () => void;
    show?: boolean;
}

const noop = () => {};

export const CarIdentifierApps = ({
    handleGenartTagSubmit = noop,
    show = true,
}: Props): JSX.Element => {
    const [vehicleData] = useVehicleData();
    const [carouselIndex, setCarouselIndex] = React.useState(0);
    const {
        data: carouselItems,
        error,
        setData,
    } = useAPI<PageWidget[]>("apps/widgets");

    if (error) {
        return <ErrorMessage />;
    }
    if (!carouselItems) {
        return <Loading />;
    }

    const className = `car-identifier-apps ${show ? "" : "hidden"}`;

    const brandModel =
        vehicleData.Marque && vehicleData.Modèle
            ? `${vehicleData.Marque} ${vehicleData.Modèle}`
            : "";

    return (
        <div className={className}>
            <GenartCarousel
                activeIndex={carouselIndex}
                setActiveIndex={setCarouselIndex}
            />
            <div className="vehicule-banner">
                <div className="vehicle-brand">
                    <span>{brandModel}</span>
                    <GenartTagSelector onSubmit={handleGenartTagSubmit} />
                </div>
                <div className="vehicule-attributes"></div>
            </div>
            <V2Widget
                items={carouselItems}
                index={carouselIndex}
                setData={setData}
            />
        </div>
    );
};

const V2Widget = ({
    items,
    index,
    setData,
}: {
    items: PageWidget[];
    index: number;
    setData: React.Dispatch<React.SetStateAction<PageWidget[] | null>>;
}) => {
    if (items.length === 0) {
        return null;
    }
    const widget = items[index];

    const setWidgetData: React.ComponentProps<typeof DndWidget>["setApps"] = (
        currentApps
    ) => {
        setData((pageWidget) => {
            if (!pageWidget) return pageWidget;
            const newWidget = [...pageWidget];
            const apps =
                typeof currentApps === "function"
                    ? currentApps(newWidget[index].apps)
                    : currentApps;
            newWidget[index].apps = apps || [];
            return newWidget;
        });
    };

    return (
        <div className="container">
            <h2>{widget.title}</h2>
            <div className="row">
                <div className="col-md-12 heroSlider-fixed">
                    <div className="overlay"></div>
                    <div className="slider responsive d-flex">
                        <DndWidget
                            widget={`v2-${widget.name}`}
                            apps={widget.apps}
                            className="d-flex"
                            setApps={setWidgetData}
                        />
                    </div>
                    <div className="prev">
                        <span
                            className="glyphicon glyphicon-chevron-left"
                            aria-hidden="true"
                        ></span>
                    </div>
                    <div className="next">
                        <span
                            className="glyphicon glyphicon-chevron-right"
                            aria-hidden="true"
                        ></span>
                    </div>
                </div>
            </div>
        </div>
    );
};
