import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";

import { useAPI } from "@/utils/hooks";

import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";

type Params = {
    mid: string;
    genarts: string;
    selectedGenart: string;
};

type Props = {
    path: string;
};

export const ParcoursMIDRedirect = ({ path }: Props): JSX.Element => {
    const { mid, genarts, selectedGenart } = useParams<Params>();
    const { t } = useTranslation();
    const { data, error } = useAPI<{ ktype: string }>(`midToKtype/${mid}`);

    if (error || !data) {
        return (
            <div className="main-bloc parcours-mid-redirect">
                {error ? <ErrorMessage /> : <Loading />}
            </div>
        );
    }

    if (data.ktype) {
        const genart = selectedGenart || genarts.split("-")[0];
        const url = `${path}/search/ktype/${data.ktype}/search/parcoursMID/${genarts}/${genart}`;
        return <Redirect to={url} />;
    }

    return (
        <div className="main-bloc parcours-mid-redirect">
            <p>{t("common.no-results-for", { search: mid })}</p>
        </div>
    );
};
