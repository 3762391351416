import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import type {
    ServiceBrakeItem,
    ServiceBrakeRepair,
    ServiceBrakesDataTranslated,
} from "shared/dist/types/autodata";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { NoResults } from "@/components/NoResults";
import { IMAGES_SERVER } from "@/config/settings";
import { FetchError, getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { Illustration } from "./Illustration";
import { TranslatedByCaia } from "./TranslatedByCaia";

type ServiceBrakesProps = {
    mid: string;
    hide?: boolean;
};

const ItemLine = ({ item }: { item: ServiceBrakeItem }) => (
    <>
        {item.description} {item.location ? `(${item.location})` : ""}:{" "}
        <strong>{item.value}</strong>
    </>
);

const RepairLine = ({ repair }: { repair: ServiceBrakeRepair }) => (
    <span>
        {repair.repair_description}: {repair.action} ({repair.time_mins} mins)
    </span>
);

const showStickyToast =
    (item: JSX.Element, level1: string, level2: string) => () => {
        toastr.info(
            <div>
                <img
                    src={`${IMAGES_SERVER}/logos/autodata.png`}
                    alt="autodata"
                />
                <div>{level1}</div>
                <div>{level2}</div>
                <div>{item}</div>
            </div>,
            { closeButton: true, autoClose: false }
        );
    };

const ServiceBrakesItem = ({
    item,
    notes,
    level2,
}: {
    item: ServiceBrakeItem;
    notes: ServiceBrakesDataTranslated["__notes"];
    level2: string;
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <span
                onClick={showStickyToast(
                    <ItemLine item={item} />,
                    t("appoem.serviceBrakes.technical_information"),
                    level2
                )}
            >
                <ItemLine item={item} />
            </span>
            {item.note && (
                <ul className="item-notes">
                    {notes
                        .find((note) => note.id === item.note)
                        ?.content.map((content) => (
                            <li key={content.sort_order}>{content.value}</li>
                        ))}
                </ul>
            )}
        </div>
    );
};

const findTitle = (data: ServiceBrakesDataTranslated): string => {
    if (!data) return "";
    for (const illustration of Object.values(data.illustrations)) {
        for (const element of illustration) {
            if (element.description) {
                return element.description;
            }
        }
    }
    return "";
};

type Status = "IDLE" | "LOADING" | "ERROR" | "NO_RESULTS" | "OK";

export const ServiceBrakes = ({
    mid,
    hide = false,
}: ServiceBrakesProps): JSX.Element => {
    const [status, setStatus] = React.useState<Status>("IDLE");
    const [data, setData] = React.useState<ServiceBrakesDataTranslated>();
    const lang = useLanguage(true);
    const [level1, setLevel1] = React.useState("");
    const [level2, setLevel2] = React.useState("");
    const { t } = useTranslation();

    React.useEffect(() => {
        setStatus("LOADING");
        setLevel1("");
        setLevel2("");
        getContent<ServiceBrakesDataTranslated>(
            `autodata/serviceBrakes/${mid}/${lang}`
        )
            .then((serviceBrakes) => {
                setData(serviceBrakes);
                setStatus("OK");
            })
            .catch((error: unknown) => {
                if (error instanceof FetchError && error.status === 404) {
                    setStatus("NO_RESULTS");
                } else {
                    console.error(error);
                    setStatus("ERROR");
                }
            });
    }, [mid, lang]);

    if (status === "LOADING") {
        return <Loading />;
    }

    if (status === "NO_RESULTS") {
        return <NoResults />;
    }

    if (status === "ERROR" || !data) {
        return <ErrorMessage />;
    }

    const levelHandler = (level: string) => () => {
        setLevel2("");
        setLevel1(level);
    };

    const illustration =
        data?.__images?.[0]?.graphic.url ??
        `${IMAGES_SERVER}/logos/autodata.webp`;

    return (
        <div className={hide ? "d-none" : "appoemnart d-flex flex-grow-1"}>
            <ul>
                <li>
                    <Button
                        color="link"
                        onClick={levelHandler("technical_information")}
                    >
                        {t("appoem.serviceBrakes.technical_information")}
                    </Button>
                </li>
                <li>
                    <Button color="link" onClick={levelHandler("repair_times")}>
                        {t("appoem.serviceBrakes.repair_times")}
                    </Button>
                </li>
            </ul>
            {level1 === "technical_information" && (
                <>
                    <div>
                        <h3>
                            {t("appoem.serviceBrakes.technical_information")}
                        </h3>
                        <ul>
                            {data.technical_information.map((group) => (
                                <li key={group.group_name}>
                                    <Button
                                        color="link"
                                        onClick={() =>
                                            setLevel2(group.group_name)
                                        }
                                    >
                                        {group.group_name}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {level2 && (
                        <div className="d-flex flex-column">
                            <h3>{level2}</h3>
                            <ul>
                                {data.technical_information
                                    .find(
                                        (group) => group.group_name === level2
                                    )
                                    ?.items.map((item) => (
                                        <li key={item.id}>
                                            <ServiceBrakesItem
                                                item={item}
                                                notes={data.__notes}
                                                level2={level2}
                                            />
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </>
            )}
            {level1 === "repair_times" && (
                <>
                    <div>
                        <h3>{t("appoem.serviceBrakes.repair_times")}</h3>
                        <ul>
                            {data.repair_times[0].groups.map((group) => (
                                <li key={group.group_name}>
                                    <Button
                                        color="link"
                                        onClick={() =>
                                            setLevel2(group.group_name)
                                        }
                                    >
                                        {group.group_name}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {level2 && (
                        <div className="d-flex flex-column">
                            <h3>{level2}</h3>
                            <ul>
                                {data.repair_times[0].groups
                                    .find(
                                        (group) => group.group_name === level2
                                    )
                                    ?.repairs.map((repair) => (
                                        <li
                                            key={repair.repair_id}
                                            onClick={showStickyToast(
                                                <RepairLine repair={repair} />,
                                                t(
                                                    "appoem.serviceBrakes.repair_times"
                                                ),
                                                level2
                                            )}
                                        >
                                            <RepairLine repair={repair} />
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </>
            )}
            <Illustration imgURL={illustration} title={findTitle(data)} />
            {data?.translated && <TranslatedByCaia />}
        </div>
    );
};
