import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewItemWizard = React.lazy(() =>
    import("@/components/NewItemWizard").then((comp) => ({
        default: comp.NewItemWizard,
    }))
);

export const AddNewItem = ({ close }: { close(): void }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="selection-bloc main-bloc selection-brand new-item-page">
            <div className="breadcrumbs-wrapper breadcrumbs-family">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <FontAwesomeIcon icon="users" className="me-1" />
                        <Link to="/social">{t("leftmenu.social")}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {t("new-item-wizard.title")}
                    </BreadcrumbItem>
                    <div className="breadcrumb-img">
                        <FontAwesomeIcon icon={faCartPlus} />
                    </div>
                </Breadcrumb>
            </div>
            <React.Suspense
                fallback={<div className="new-item-wizard">Loading…</div>}
            >
                <NewItemWizard close={close} />
            </React.Suspense>
        </div>
    );
};
