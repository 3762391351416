import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import type {
    GroupBase,
    MultiValueGenericProps,
    OptionProps,
} from "react-select";
import type { SelectComponents } from "react-select/dist/declarations/src/components";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { faMask } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import type { TFunction } from "i18next";

import type { Attribute, OE } from "shared/dist/types/item";
import type { ItemRef } from "shared/dist/types/parts";
import type { Vtype } from "shared/dist/types/vehicle";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ErrorMessage } from "@/components/ErrorMessage";
import { FilterSelect } from "@/components/FilterSelect";
import { Loading } from "@/components/Loading";
import { SlidesStack, StackSliderButtons } from "@/components/SlidesStack";
import { ToggleComponent } from "@/components/ToggleComponent";
import { WizardNavigation } from "@/components/WizardNavigation";
import { IMAGES_SERVER } from "@/config/settings";
import { useExpertDataMode } from "@/context/ExpertDataContext";
import { getContent } from "@/utils/fetch";
import { useIsMounted, useLanguage } from "@/utils/hooks";
import { getSupplierLogoURL } from "@/utils/utils";

import { AppOEMList } from "./AppOEM/AppOEMList";
import { ArticleTable } from "./ArticleTable";
import {
    hasCorrectDate,
    hasCorrectEngineCode,
    ItemAttributes,
} from "./ItemContainer";
import { PartsTabDetails } from "./PartsTabDetails";
import { VehicleApplicability } from "./VehicleApplicability";

import "react-image-gallery/styles/css/image-gallery.css";
import "./Item.css";

const { useState, useRef } = React;

type FilterType = "oem" | "brand" | "attribute";

type FilterOptionCommon = {
    label: string;
    value: string;
};

type FilterOptionItem = FilterOptionCommon & {
    type: FilterType;
};

type FilterOptionGenart = FilterOptionCommon & {
    type: "genart";
    familyId: string;
};

export type FilterOption = FilterOptionItem | FilterOptionGenart;

export type OEItem = OE & {
    from?: string;
    id_fournisseur?: number;
    attributes?: Attribute[];
    ArtNr?: string;
    criterias?: Record<string, string>;
    ktype?: string;
    ref_oem?: string;
};

type Discriminant = {
    value: string;
    translatedName: string;
    translatedValue: string;
};

export type TamaArticle = {
    id: string;
    attributes: Record<string, string>;
    discriminants: Record<string, Discriminant>;
    oes: OE[];
    oeNrs: string[];
};

const makeAttrLabel = (attrKey: string, attrValue: string) =>
    attrValue == null ? attrKey : `${attrKey}: ${attrValue}`;

const makeFilterOptions = (
    items: TamaArticle[],
    criterias: Record<string, string>,
    t: TFunction
): Record<string, ReadonlyArray<GroupBase<FilterOption>>> => {
    const filterOptions: Record<
        string,
        ReadonlyArray<GroupBase<FilterOption>>
    > = {};
    for (const item of items) {
        const oemOptions: FilterOption[] = Array.from(new Set(item.oeNrs)).map(
            (oe) => ({
                label: `${oe}${criterias[oe] ? " " + criterias[oe] : ""}`,
                value: oe,
                type: "oem",
            })
        );
        const brands = new Map<string, string>();
        for (const oe of item.oes) {
            brands.set(oe.makeId, oe.makeLabel);
        }
        const brandIds = [...brands.keys()];
        const brandOptions: FilterOption[] = brandIds.map((brandId) => ({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            label: brands.get(brandId)!,
            value: brandId,
            type: "brand",
        }));
        const attributes = new Set<string>();
        for (const oe of item.oes) {
            if (!oe.attributes) continue;
            for (const attribute of oe.attributes) {
                attributes.add(makeAttrLabel(attribute.label, attribute.value));
            }
        }
        const attributeOptions: FilterOption[] = [...attributes]
            .sort()
            .map((attribute) => ({
                label: attribute,
                value: attribute,
                type: "attribute",
            }));

        filterOptions[item.id] = [
            {
                label: t("item.make"),
                options: brandOptions,
            },
            {
                label: t("item.manufacturer-reference"),
                options: oemOptions,
            },
            {
                label: "Criteria",
                options: attributeOptions,
            },
        ];
    }
    return filterOptions;
};

const getImageInfo = (data: FilterOption) => {
    let src = "";
    let alt = "";
    switch (data.type) {
        case "oem":
            src = `${IMAGES_SERVER}/picto/oem.png`;
            alt = "oem";
            break;
        case "brand":
            src = getSupplierLogoURL(data.value);
            alt = data.value;
            break;
        case "attribute":
            src = `${IMAGES_SERVER}/picto/information.png`;
            alt = "attribute";
            break;
        case "genart": {
            const { familyId } = data;
            src = familyId
                ? `${IMAGES_SERVER}/gif/${familyId}.png`
                : `${IMAGES_SERVER}/gif/DEFAULT.png`;
            break;
        }
    }
    return { src, alt };
};

const CustomOption = (props: OptionProps<FilterOption, true>): JSX.Element => {
    const { innerRef, innerProps, label, isDisabled, isFocused, isSelected } =
        props;
    const data: FilterOption = props.data;

    const className = classnames({
        "filter-option": true,
        "option--is-disabled": isDisabled,
        "option--is-focused": isFocused,
        "option--is-selected": isSelected,
    });

    const { src, alt } = getImageInfo(data);

    return (
        <div className={className} ref={innerRef} {...innerProps}>
            <img src={src} alt={alt} width="30" />
            <span>{label}</span>
        </div>
    );
};

const FilterLabel = (
    props: MultiValueGenericProps<FilterOption, true>
): JSX.Element => {
    const { data } = props;
    const { src, alt } = getImageInfo(data);

    return (
        <div>
            <img src={src} alt={alt} width="30" />
            <span>{data.label}</span>
        </div>
    );
};

export const customComponents: Partial<
    SelectComponents<FilterOption, true, GroupBase<FilterOption>>
> = {
    Option: CustomOption,
    MultiValueLabel: FilterLabel,
};

export const filterSelectedOEs = (
    oes: OE[],
    filters: readonly FilterOption[]
): OE[] => {
    const oemFilters = (filters || []).filter(
        (filter) => filter.type === "oem"
    );
    const brandFilters = (filters || []).filter(
        (filter) => filter.type === "brand"
    );
    const attributeFilters = (filters || []).filter(
        (filter) => filter.type === "attribute"
    );
    const genartFilters = (filters || []).filter(
        (filter) => filter.type === "genart"
    );
    return (oes || []).filter(
        (oe) =>
            (oemFilters.length === 0 ||
                oemFilters.some((filter) => oe.oeNrs.includes(filter.value))) &&
            (brandFilters.length === 0 ||
                brandFilters.some((filter) => oe.makeId === filter.value)) &&
            (genartFilters.length === 0 ||
                genartFilters.some(
                    (filter) => filter.value === oe.articleId
                )) &&
            (attributeFilters.length === 0 ||
                attributeFilters.some((filter) =>
                    oe.attributes?.some(
                        (attribute) =>
                            makeAttrLabel(attribute.label, attribute.value) ===
                            filter.value
                    )
                ))
    );
};

type NavigationWithLabelsProps = {
    activeTab: string | undefined;
    items: TamaArticle[];
    toggleTab: (id: string) => void;
};

const NavigationWithLabels = ({
    activeTab,
    items,
    toggleTab,
}: NavigationWithLabelsProps) => {
    const FITTING_POSITION_ID = 31;
    const tabLabels = useRef<Record<string, string[]>>({});
    const tabIds = useRef<Record<string, Set<number>>>({});
    tabLabels.current = {};
    tabIds.current = {};
    for (const item of items) {
        const itemIds = new Set<number>();
        const fittingPositions = new Map<number, string>();
        for (const oe of item.oes) {
            if (!oe.attributes) continue;
            for (const attribute of oe.attributes) {
                if (
                    attribute.labelId === FITTING_POSITION_ID &&
                    attribute.valueId
                ) {
                    fittingPositions.set(attribute.valueId, attribute.value);
                    itemIds.add(attribute.valueId);
                }
            }
        }
        const tabLabel = [...fittingPositions.entries()].map(
            (p) => `${p[1]} (${p[0]})`
        );
        if (tabLabel.length > 1) {
            tabLabel[0] += ` (+${tabLabel.length - 1})`;
        }
        tabLabels.current[item.id] = tabLabel;
        tabIds.current[item.id] = itemIds;
    }

    const [labels, setLabels] = useState(tabLabels.current);

    const lang = useLanguage();
    const isMounted = useIsMounted();

    React.useEffect(() => {
        Promise.all(
            Object.entries(tabLabels.current).map(([tabId, tab]) =>
                tabIds.current[tabId].size > 1
                    ? getContent<string>(
                          `fittingPosition/${lang}/${[...tabIds.current[tabId]]
                              .sort()
                              .join()}`
                      ).then((label) => ({ label, tabId }))
                    : { label: tab[0], tabId }
            )
        )
            .then((newLabels) => {
                if (isMounted()) {
                    const fixedLabels: Record<string, string[]> = {};
                    for (const key of Object.keys(tabLabels.current)) {
                        const label = newLabels.find((l) => l.tabId === key);
                        fixedLabels[key] = label?.label
                            ? [label.label]
                            : tabLabels.current[key];
                    }
                    setLabels(fixedLabels);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [lang, isMounted, items]);

    return (
        <WizardNavigation
            activeTab={activeTab}
            tabIds={items.map((item) => item.id)}
            updateActiveTab={toggleTab}
            tabLabels={labels}
        />
    );
};

/* If it starts with a digit, it's a KTYPE tab */
const isTAMATab = (tab: TamaArticle): boolean => !/^\d/.test(tab.id);

const filterItems =
    (vehicleData: VehicleData) =>
    (item: TamaArticle): boolean =>
        !isTAMATab(item) ||
        (hasCorrectDate(item, vehicleData) &&
            hasCorrectEngineCode(item, vehicleData.Code_Moteur) &&
            item.oes.length > 0);

const getFilteredItems = (
    tamaArticles: TamaArticle[],
    vehicleData: VehicleData
): TamaArticle[] => {
    const filteredItems =
        tamaArticles.length > 2
            ? tamaArticles.filter(filterItems(vehicleData))
            : tamaArticles;
    return filteredItems.length > 1
        ? filteredItems.filter(isTAMATab)
        : filteredItems;
};

type Props = {
    match: {
        params: {
            family: string;
            subFamily: string;
            categoryId: string;
            itemId: string;
            ktype?: string;
        };
    };
    expertData?: ItemRef[];
    error?: boolean;
    localAttributes?: ItemAttributes;
    globalAttributes?: ItemAttributes;
    isLoading?: boolean;
    ktype?: string;
    hmdnr?: string;
    brand?: string;
    model?: string;
    items: TamaArticle[];
    label: string;
    criterias?: Record<string, string>;
    vehicleData: VehicleData;
};

export const Item = (props: Props): JSX.Element => {
    const [modalOpen, setModalOpen] = useState(false);
    const [makeId, setMakeId] = useState("");
    const [partId, setPartId] = useState("");
    const [refOem, setRefOem] = useState("");
    const [selectedFilters, setSelectedFilters] = useState<
        readonly FilterOption[]
    >([]);
    const [mid, setMid] = useState(props.vehicleData.Mid_Autodata);
    let { vtype } = useParams<{ vtype: Vtype }>();
    if (!vtype) {
        vtype = props.vehicleData.ntypnr ? "pl" : "vl";
    }

    const oeCache = useRef<Record<string, readonly FilterOption[]>>({});

    const { t } = useTranslation();
    const expertDataMode = useExpertDataMode();

    const openModal = (oe: OEItem) => (): void => {
        setMakeId(String(oe.id_fournisseur || oe.makeId));
        setPartId(oe.ArtNr || oe.id);
        setRefOem(oe.ref_oem ?? "");
        setModalOpen(true);
    };

    React.useEffect(() => {
        const Mid_Autodata = props.vehicleData.Mid_Autodata;
        if (Mid_Autodata) {
            setMid(Mid_Autodata);
            return;
        }
        if (props.ktype && !Mid_Autodata) {
            getContent<{ mid: string }>(`ktypnrToMid/${props.ktype}`)
                .then((data) => {
                    setMid(data.mid);
                })
                .catch((error) => console.error(error));
        }
    }, [props.ktype, props.vehicleData.Mid_Autodata]);

    const [hideTamaTabs, setHideTamaTabs] = useState(true);

    const items = hideTamaTabs
        ? getFilteredItems(props.items, props.vehicleData)
        : props.items;
    const [activeTab, setActiveTab] = useState<string | undefined>(
        items[0]?.id
    );
    React.useEffect(() => {
        const selectedItem = items.find((item) => item.id === activeTab);
        if (!selectedItem) {
            setActiveTab(items.length > 0 ? items[0].id : undefined);
        }
    }, [items, activeTab]);

    const toggleHide = () => setHideTamaTabs((hidden) => !hidden);

    const toggleModal = (): void => setModalOpen((open) => !open);

    const toggleTab = (tabId: string): void => {
        if (activeTab) {
            oeCache.current[activeTab] = selectedFilters;
        }
        const newSelectedOEs = oeCache.current[tabId] || [];
        setSelectedFilters(newSelectedOEs);
        setActiveTab(tabId);
    };

    const {
        match,
        ktype,
        brand,
        model,
        label,
        criterias = {},
        expertData = [],
        localAttributes = {},
        globalAttributes = {},
    } = props;

    const filterExpertData =
        (id: string) =>
        (oe: OE): boolean =>
            expertDataMode ||
            !expertData.find(
                (data) => data.articleId === id && data.refId === oe.id
            );

    const getResultLabel = (article: TamaArticle | undefined): string => {
        if (!article) return "";
        const count = article.oes.filter(filterExpertData(article.id)).length;
        const filteredOEs = filterSelectedOEs(
            article.oes,
            selectedFilters
        ).filter(filterExpertData(article.id));
        let resultLabel = t("partselector.resultsWithCount", { count });
        if (count > filteredOEs.length) {
            resultLabel = `${filteredOEs.length}/${resultLabel}`;
        }
        return resultLabel;
    };

    const filterOEs = (article: TamaArticle): OE[] => {
        const { itemId } = props.match.params;
        const filteredOEs = filterSelectedOEs(
            article.oes,
            selectedFilters
        ).filter(filterExpertData(article.id));
        return filteredOEs.map((oe) => ({
            genartId: Number(itemId),
            ...oe,
            articleId: article.id,
        }));
    };

    const params = { ...match.params, ktype, brand, model, vtype };
    const breadcrumbsMatch = { ...match, params };

    const options = React.useMemo(
        () => makeFilterOptions(items, criterias, t),
        [items, criterias, t]
    );

    if (props.error) {
        return (
            <div className="selection-bloc main-bloc item-bloc">
                <Breadcrumbs match={breadcrumbsMatch} />
                <h2>{label}</h2>
                <ErrorMessage />
            </div>
        );
    }
    if (props.isLoading) {
        return (
            <div className="selection-bloc main-bloc item-bloc">
                <Breadcrumbs match={breadcrumbsMatch} />
                <h2>{label}</h2>
                <Loading messageKey="item.loading" />
            </div>
        );
    }

    const { itemId } = props.match.params;

    const tableProperties = {
        openModal,
        globalAttributes,
        localAttributes,
        criterias,
        genart: Number(itemId),
        ktype: props.vehicleData.ktype || props.ktype,
        hmdnr: props.hmdnr,
        vin: props.vehicleData.Codif_Vin_PRF,
    };

    const article = items.find((item) => item.id === activeTab);

    return (
        <div className="selection-bloc main-bloc item-bloc">
            <ErrorBoundary>
                <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal}>
                        <span>
                            {t("item.vehicle-list", {
                                refId: partId,
                            })}
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <VehicleApplicability
                            makeId={makeId}
                            partId={partId}
                            refOem={refOem}
                            toggle={toggleModal}
                            itemId={itemId}
                        />
                    </ModalBody>
                </Modal>
                <Breadcrumbs match={breadcrumbsMatch}>
                    {props.items.length > 1 && (
                        <ToggleComponent
                            className="d-flex align-items-center ms-auto"
                            checked={hideTamaTabs}
                            onChange={toggleHide}
                        >
                            <FontAwesomeIcon icon={faMask} size="2x" />
                        </ToggleComponent>
                    )}
                    <StackSliderButtons />
                </Breadcrumbs>
                <AppOEMList
                    items={items}
                    brand={brand}
                    criterias={criterias}
                    vtype={vtype}
                    mid={mid}
                    toggleTab={toggleTab}
                    title={
                        <div className="d-inline-bloc align-self-end">
                            <h2>
                                <span>{label}</span>
                            </h2>
                            <h2>{getResultLabel(article)}</h2>
                        </div>
                    }
                >
                    <NavigationWithLabels
                        activeTab={activeTab}
                        toggleTab={toggleTab}
                        items={items}
                    />
                </AppOEMList>
                <SlidesStack className="oes-slider" />
                {article && (
                    <div>
                        {article.oes.length > 2 && (
                            <FilterSelect
                                placeholder={t("item.filter-placeholder")}
                                options={options[article.id]}
                                value={selectedFilters}
                                onChange={setSelectedFilters}
                                components={customComponents}
                            />
                        )}
                        <PartsTabDetails
                            article={article}
                            vehicleData={props.vehicleData}
                        />
                        <div className="oes-table">
                            <ArticleTable
                                {...tableProperties}
                                id={article.id}
                                oes={filterOEs(article)}
                            />
                        </div>
                    </div>
                )}
            </ErrorBoundary>
        </div>
    );
};
