import * as React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "classnames";
import { useRecoilValue } from "recoil";

import { ProfilePicture } from "@/components/ProfilePicture";
import { useAuth } from "@/context/AuthContext";
import { CartContext } from "@/context/CartItems";
import { sidebarVisibleAtom } from "@/globalState/atoms";

import { LanguageSelector } from "../LanguageSelector";
import { ToggleFullscreen } from "../ToggleFullscreen";

import { ExpertDataSwitch } from "./ExpertDataSwitch";
import { SupportButton } from "./SupportButton";
import { UserMenu } from "./UserMenu";

const RightSpinner = (
    <nav className="navbar-right">
        <FontAwesomeIcon className="spinner" icon="spinner" size="2x" spin />
    </nav>
);

const toggleFullscreen = (): void => {
    if (document.fullscreenElement === null) {
        document.documentElement.requestFullscreen();
    } else {
        document.exitFullscreen();
    }
};

const CartButton = () => {
    const { hasAccess } = useAuth();
    const { cart } = React.useContext(CartContext);
    const quantity = cart.reduce((sum, item) => sum + item.quantity, 0);
    if (!hasAccess("cart")) return null;
    return (
        <Link to="/cart" className="cart-link">
            <FontAwesomeIcon icon={faShoppingCart} />
            {quantity > 0 && <Badge pill>{quantity}</Badge>}
        </Link>
    );
};

const UserInfo = () => {
    const { userInfo } = useAuth();
    if (!userInfo || !process.env.REACT_APP_SHOW_USER_INFO_IN_HEADER)
        return null;
    return (
        <>
            <ProfilePicture className="user-picture-header" />
            <span className="username-header me-2">{userInfo.name}</span>
        </>
    );
};

export const HeaderRight = (): JSX.Element => {
    const hidden = !useRecoilValue(sidebarVisibleAtom);
    return (
        <React.Suspense fallback={RightSpinner}>
            <nav className={clsx("navbar-right", { hidden })}>
                <UserInfo />
                <CartButton />
                <ToggleFullscreen onClick={toggleFullscreen} />
                <ExpertDataSwitch />
                <SupportButton />
                <LanguageSelector />
                <UserMenu />
            </nav>
        </React.Suspense>
    );
};
