import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactTable, { Column } from "react-table";
import { CSSTransition } from "react-transition-group";
import { Badge, Button } from "reactstrap";
import classnames from "classnames";

import type { OE } from "shared/dist/types/item";
import type { Vtype } from "shared/dist/types/vehicle";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useExpertDataMode } from "@/context/ExpertDataContext";
import { useAPI } from "@/utils/hooks";
import { getLogoURL } from "@/utils/utils";

import { ItemColumnMake, ItemColumnPhoto } from "../ArticleTable";
import type { TamaArticle } from "../Item";
import { SearchType, useAddItemSlide } from "../ItemSlides";
import { PDFList } from "../PDFList";
import { TabLabel } from "../TabnavItem";

import { AcRecharge } from "./AcRecharge";
import { BatteryReplacement } from "./BatteryReplacement";
import { ServiceBrakes } from "./ServiceBrakes";

const isMdd = (oe: OE) => "oes" in oe;

type AppOEM = {
    id: string;
    vendors: number;
    items: OE[];
    oems: number;
    mddOems: number;
    type: "APPOEM";
};

type AppOEMNart = {
    id: string;
    type: "APPOEMNART";
};

type App = AppOEM | AppOEMNart;

const makeApp = (article: TamaArticle): AppOEM => {
    const items = article.oes.filter(isMdd);
    const vendors = new Set(items.map((oe) => oe.vhu || oe.makeId)).size;
    const mddOems = new Set(items.map((item) => item.oes)).size;
    const oems = article.oeNrs.length;
    return { id: article.id, vendors, items, oems, mddOems, type: "APPOEM" };
};

const ItemColumnDesc = ({
    original: oe,
    criterias,
}: {
    original: OE;
    criterias: Record<string, string>;
}) => {
    const { t } = useTranslation();
    const { addIframeSlide, addPartsSearchSlide, addFicheArticleSlide } =
        useAddItemSlide();
    return (
        <>
            <strong
                className="oe-name"
                onClick={() => addFicheArticleSlide({ ...oe, criterias })}
            >
                {oe.id}
            </strong>
            <PDFList medias={oe.mediaDtos} onClick={addIframeSlide} />
            <br />
            <div className="oe-genart">{oe.genArt}</div>
            <span
                className="oe-ean"
                data-ean={oe.ean}
                onClick={() =>
                    addPartsSearchSlide(
                        oe.ean,
                        (oe.from as SearchType) || "ean"
                    )
                }
            >
                {t("item.EAN")}: {oe.ean}
            </span>
            {oe.label && <p>{oe.label}</p>}
        </>
    );
};

type AppOEMDetailsProps = {
    article: TamaArticle;
    criterias: Record<string, string>;
};

const AppOEMDetails = ({ article, criterias }: AppOEMDetailsProps) => {
    const [selectedOem, setSelectedOem] = React.useState("");
    const items: OE[] = article.oes.filter(
        (item) => isMdd(item) && item.oeNrs.includes(selectedOem)
    );
    const { t } = useTranslation();

    React.useEffect(() => {
        const refOem = article.oes.find(isMdd)?.oes || article.oeNrs[0] || "";
        setSelectedOem(refOem);
    }, [article]);

    const DescCell = ({ original }: { original: OE }) => (
        <ItemColumnDesc original={original} criterias={criterias} />
    );

    const columns: Column[] = [
        {
            Header: t("item.make"),
            width: 100,
            Cell: ItemColumnMake,
            accessor: "makeLabel",
        },
        {
            Header: t("item.photo"),
            width: 100,
            Cell: ItemColumnPhoto,
            sortable: false,
        },
        {
            Header: t("item.desc"),
            Cell: DescCell,
            accessor: "id",
        },
    ];

    return (
        <>
            <ul>
                {article.oeNrs.map((oeNr) => (
                    <li key={oeNr}>
                        <Button
                            color="link"
                            className={
                                article.oes.some(
                                    (item) =>
                                        isMdd(item) && item.oeNrs.includes(oeNr)
                                )
                                    ? "has-offers"
                                    : ""
                            }
                            onClick={() => setSelectedOem(oeNr)}
                        >
                            {`${oeNr}${
                                criterias[oeNr] ? " " + criterias[oeNr] : ""
                            }`}
                        </Button>
                    </li>
                ))}
            </ul>
            <div className="d-flex overflow-hidden flex-column flex-grow-1">
                <h3>{selectedOem + " " + (criterias[selectedOem] || "")}</h3>
                <div className="d-flex overflow-hidden">
                    <ul>
                        <li>
                            <TabLabel article={article} />
                        </li>
                        <li>
                            <ul className="article-attributes">
                                {Object.keys(article.attributes || {}).map(
                                    (attribute) => (
                                        <li key={attribute}>
                                            {attribute}:{" "}
                                            {article.attributes[attribute]}
                                        </li>
                                    )
                                )}
                            </ul>
                        </li>
                    </ul>
                    <ReactTable
                        data={items}
                        columns={columns}
                        defaultPageSize={10}
                        showPagination={items.length > 10}
                        minRows={0}
                    />
                </div>
            </div>
            <img src={`${IMAGES_SERVER}/sivince/sivince_big.png`} alt="" />
        </>
    );
};

const ServiceBrakesApp: AppOEMNart = {
    id: "ServiceBrakes",
    type: "APPOEMNART",
};

const AcRechargeApp: AppOEMNart = {
    id: "AcRecharge",
    type: "APPOEMNART",
};

const BatteryReplacementApp: AppOEMNart = {
    id: "BatteryReplacement",
    type: "APPOEMNART",
};

type AppOEMConfig = {
    id: string;
    category: string;
    genarts: string;
};

type Props = {
    brand: string | undefined;
    criterias: Record<string, string>;
    items: TamaArticle[];
    vtype: Vtype;
    mid: string | null | undefined;
    title: React.ReactElement;
    toggleTab(tabId: string): void;
};

type AppOEMProps = {
    app: AppOEM;
    isSelected: boolean;
    onSingleClick(): void;
    onDoubleClick(): void;
    imgURL: string;
    imgAlt: string;
};

const AppOEMElement = ({
    app,
    isSelected,
    onSingleClick,
    onDoubleClick,
    imgURL,
    imgAlt,
}: AppOEMProps) => {
    const [clicks, setClicks] = React.useState(0);
    const expertDataMode = useExpertDataMode();
    const { hasAccess } = useAuth();

    React.useEffect(() => {
        let singleClickTimer: NodeJS.Timeout;
        if (clicks === 1) {
            singleClickTimer = setTimeout(() => {
                onSingleClick();
                setClicks(0);
            }, 250);
        } else if (clicks === 2) {
            onDoubleClick();
            setClicks(0);
        }
        return () => clearTimeout(singleClickTimer);
    }, [clicks, onSingleClick, onDoubleClick]);

    const logoURL =
        /^\d/.test(app.id) && (hasAccess("tecdocKtypnr") || expertDataMode)
            ? `${IMAGES_SERVER}/logos/tecdoc.jpg`
            : imgURL;

    return (
        <div
            key={app.id}
            className={classnames("marque", isSelected && "tooltip-arrow")}
            onClick={() => setClicks((c) => c + 1)}
        >
            <img src={logoURL} alt={imgAlt} />
            <Badge color="oem-vendors">
                {app.vendors} | {app.items.length}
            </Badge>
            <Badge color="oem-count">{app.oems}</Badge>
            <Badge color="secondary">{app.mddOems}</Badge>
        </div>
    );
};

export const AppOEMList = ({
    brand,
    criterias,
    items,
    vtype,
    children,
    mid,
    title,
    toggleTab,
}: React.PropsWithChildren<Props>): JSX.Element => {
    const [selectedApp, setSelectedApp] = React.useState<App | null>(null);
    const [showDetails, setShowDetails] = React.useState(false);
    const { itemId: genartId } = useParams<{ itemId: string }>();
    const IMG_URL = getLogoURL(brand, vtype);
    const apps = items.map(makeApp);

    const data = useAPI<AppOEMConfig[]>("appOEMConfig");
    const appOEMconfig = data.data || [];

    React.useEffect(() => {
        setShowDetails(false);
    }, [items]);

    const handleAppClick = (app: App) => {
        if (selectedApp === null || selectedApp.id === app.id) {
            setShowDetails((show) => !show);
        }
        setSelectedApp(app);
    };

    const article = items.find(
        (tamaArticle) => tamaArticle.id === selectedApp?.id
    );

    const showServiceBrakes =
        mid &&
        appOEMconfig.find(
            (appOEM) =>
                appOEM.id === "ServiceBrakes" &&
                appOEM.genarts.split(",").includes(genartId)
        );

    const showAcRecharge =
        mid &&
        appOEMconfig.find(
            (appOEM) =>
                appOEM.id === "AcRecharge" &&
                appOEM.genarts.split(",").includes(genartId)
        );

    const showBatteryReplacement =
        mid &&
        appOEMconfig.find(
            (appOEM) =>
                appOEM.id === "BatteryReplacement" &&
                appOEM.genarts.split(",").includes(genartId)
        );

    return (
        <div className="app-oem-block d-flex flex-column">
            <div
                className={classnames(
                    "apps flex-grow-1 d-inline-flex",
                    apps.length < 2 && "single-app"
                )}
            >
                {title}
                <div
                    className="d-inline-flex flex-column"
                    style={{ flexGrow: apps.length }}
                >
                    <div className="d-flex justify-content-around">
                        {apps.map((app) => (
                            <AppOEMElement
                                key={app.id}
                                app={app}
                                isSelected={selectedApp?.id === app.id}
                                imgURL={IMG_URL}
                                imgAlt={brand ?? ""}
                                onSingleClick={() => handleAppClick(app)}
                                onDoubleClick={() => toggleTab(app.id)}
                            />
                        ))}
                    </div>
                    {children}
                </div>
                <div className="d-flex flex-grow-1 justify-content-center align-items-baseline">
                    {showServiceBrakes && (
                        <div
                            className={classnames(
                                "marque",
                                selectedApp === ServiceBrakesApp &&
                                    "tooltip-arrow"
                            )}
                            onClick={() => handleAppClick(ServiceBrakesApp)}
                        >
                            <img
                                src={`${IMAGES_SERVER}/logos/appOEM/ServiceBrakes.png`}
                                alt="ServiceBrakes"
                            />
                            <Badge color="secondary">Autodata</Badge>
                        </div>
                    )}
                    {showAcRecharge && (
                        <div
                            className={classnames(
                                "marque",
                                selectedApp === AcRechargeApp && "tooltip-arrow"
                            )}
                            onClick={() => handleAppClick(AcRechargeApp)}
                        >
                            <img
                                src={`${IMAGES_SERVER}/logos/appOEM/AcRecharge.png`}
                                alt="AcRecharge"
                            />
                            <Badge color="secondary">Autodata</Badge>
                        </div>
                    )}
                    {showBatteryReplacement && (
                        <div
                            className={classnames(
                                "marque",
                                selectedApp === BatteryReplacementApp &&
                                    "tooltip-arrow"
                            )}
                            onClick={() =>
                                handleAppClick(BatteryReplacementApp)
                            }
                        >
                            <img
                                src={`${IMAGES_SERVER}/logos/appOEM/BatteryReplacement.png`}
                                alt="AcRecharge"
                            />
                            <Badge color="secondary">Autodata</Badge>
                        </div>
                    )}
                </div>
            </div>
            <CSSTransition
                in={showDetails}
                classNames="app-oem-details"
                timeout={300}
                unmountOnExit
                onExited={() => setSelectedApp(null)}
            >
                <div className="app-oem-details position-relative">
                    <ErrorBoundary>
                        {selectedApp?.type === "APPOEM" && article && (
                            <AppOEMDetails
                                article={article}
                                criterias={criterias}
                            />
                        )}
                        {selectedApp === ServiceBrakesApp && mid && (
                            <ServiceBrakes mid={mid} />
                        )}
                        {selectedApp === AcRechargeApp && mid && (
                            <AcRecharge mid={mid} />
                        )}
                        {selectedApp === BatteryReplacementApp && mid && (
                            <BatteryReplacement mid={mid} />
                        )}
                    </ErrorBoundary>
                </div>
            </CSSTransition>
        </div>
    );
};
