import * as React from "react";
import * as ReactGA from "react-ga";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

import "@/config/i18n";

import { CaiaApp } from "@/app/CaiaApp";
import { AutoLogin } from "@/components/AutoLogin";
import { IdCar } from "@/components/IdCar";
import { LoadCustomer } from "@/components/LoadCustomer";
import { Login } from "@/components/Login";
import { PrivateRoute } from "@/components/PrivateRoute";
import { RegisterEvents } from "@/components/RegisterEvents";
import { SmartphoneScanner } from "@/components/SmartphoneScanner";
import { SpeechButton } from "@/components/SpeechRecognition";
import { ScreenSSEHandler, SSEHandler } from "@/components/SSEHandler";
import { UpdateAvailable } from "@/components/UpdateAvailable";
import { WORKSHOP_PATH } from "@/config/settings";
import { AuthProvider } from "@/context/AuthContext";
import { CartItems } from "@/context/CartItems";
import { CustomerProvider } from "@/context/CustomerContext";
import { ExpertDataProvider } from "@/context/ExpertDataContext";
import { ServiceWorkerProvider } from "@/context/ServiceWorkerProvider";
import { StatsProvider } from "@/context/StatsContext";
import { UserSettingsProvider } from "@/context/UserSettingsContext";
import { VehicleDataProvider } from "@/context/VehicleDataContext";
import { IframeViewer } from "@/pages/IframeViewer/IframeViewer";
import { PublicIframe } from "@/pages/PublicIframe/PublicIframe";
import { RTPE } from "@/pages/RTPE/RTPE";
import { Screen } from "@/pages/Screen";
import { SilentSSO } from "@/pages/SilentSSO";
import { WordpressAuthent } from "@/pages/WordpressAuthent";
import { useGoogleOptimize } from "@/utils/useGoogleOptimize";
import { App as AppV2 } from "@/v2/AppV2";

import { WonderPushProvider } from "./WonderPushProvider";

const Dashboard = React.lazy(() => import("@/pages/Dashboard/Dashboard"));

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
}

const EmptyApp = () => (
    <div className="app">
        <div className="main-content"></div>
    </div>
);

const RootApp = () => {
    const location = useLocation();
    useGoogleOptimize();

    React.useEffect(() => {
        if (TRACKING_ID) {
            ReactGA.pageview(location.pathname);
        }
    }, [location]);

    return (
        <Switch>
            <Route path={WORKSHOP_PATH}>
                <AppV2 />
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/idcar/:vin">
                <IdCar />
            </Route>
            <Route path="/gcd">
                <RTPE />
            </Route>
            <Route path="/wp-auth">
                <React.Suspense fallback={null}>
                    <WordpressAuthent />
                </React.Suspense>
            </Route>
            <Route path="/s/:userId/:timestamp">
                <React.Suspense fallback={<div>Loading translations…</div>}>
                    <AutoLogin />
                </React.Suspense>
            </Route>
            <Route path="/public/:id">
                <PublicIframe />
            </Route>
            <Route path="/viewer/:id">
                <IframeViewer />
            </Route>
            <Route path="/screen/:id">
                <ScreenSSEHandler />
                <Screen />
            </Route>
            <PrivateRoute needAccess="CaiaApp" loading={<EmptyApp />}>
                <Switch>
                    <Route path="/rvin">
                        <SmartphoneScanner type="vin" />
                    </Route>
                    <Route path="/rean">
                        <SmartphoneScanner type="ean" />
                    </Route>
                    <Route path="/sv5actia">
                        <ToastContainer
                            closeButton={false}
                            transition={Slide}
                        />
                        <React.Suspense fallback={null}>
                            <Dashboard />
                        </React.Suspense>
                    </Route>
                    <Route>
                        <CustomerProvider>
                            <RegisterEvents />
                            <SSEHandler />
                            <ExpertDataProvider>
                                <CartItems>
                                    <VehicleDataProvider>
                                        <LoadCustomer />
                                        <UserSettingsProvider>
                                            <StatsProvider>
                                                <WonderPushProvider>
                                                    <CaiaApp />
                                                </WonderPushProvider>
                                            </StatsProvider>
                                        </UserSettingsProvider>
                                    </VehicleDataProvider>
                                </CartItems>
                            </ExpertDataProvider>
                        </CustomerProvider>
                        <SpeechButton />
                    </Route>
                </Switch>
            </PrivateRoute>
        </Switch>
    );
};

export const App = (): JSX.Element => (
    <BrowserRouter>
        <Switch>
            <Route path="/silent-sso">
                <SilentSSO />
            </Route>
            <Route>
                <ServiceWorkerProvider>
                    <AuthProvider>
                        <RecoilRoot>
                            <RootApp />
                        </RecoilRoot>
                    </AuthProvider>
                    <UpdateAvailable />
                </ServiceWorkerProvider>
            </Route>
        </Switch>
    </BrowserRouter>
);
