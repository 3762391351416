import * as React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import type { TFuncKey } from "i18next";

const SPINNER_STYLE = { color: "black", marginRight: "5px" };

type Props = {
    messageKey?: TFuncKey;
    className?: string;
};

export const Loading = ({
    messageKey = "common.loading",
    className,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={classnames(className)}>
            <FontAwesomeIcon
                className="spinner"
                icon="spinner"
                spin
                style={SPINNER_STYLE}
            />
            {t(messageKey) as string}
        </div>
    );
};
