import * as React from "react";
import classnames from "classnames";
import { useBarcode } from "next-barcode";

import { HubIdlpButton } from "@/components/Item/HubIdlpButton";

export const Barcode = ({ ean }: { ean: string }): JSX.Element => {
    const { inputRef } = useBarcode({
        value: ean || "This crashes if empty",
    });
    return (
        <div className={classnames("barcode d-flex", !ean && "hidden")}>
            <svg ref={inputRef} />
            <HubIdlpButton value={ean} />
        </div>
    );
};
