import * as React from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";

import type { DBApp } from "shared/dist/types/apps";

import {
    getAppURL,
    getDataAttribute,
    getMinDelay,
    isValidApp,
} from "@/components/AppsWidget";
import { ErrorMessage } from "@/components/ErrorMessage";
import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { Loading } from "@/components/Loading";
import { USE_KEYCLOAK } from "@/config/settings";
import { useAPI as useOldAPI } from "@/utils/hooks";
import { useAPIwithKeycloak } from "@/utils/useAPIwithKeycloak";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "./NewsSlider.css";

SwiperCore.use([Autoplay, Pagination, Navigation]);

const NewsSliderContent = ({ apps }: { apps: DBApp[] }): JSX.Element => {
    const { addIframeSlide } = useAddItemSlide();
    const onClickHandler = (app: DBApp) => () => {
        const { url, dateTo, alertEnd } = getAppURL(app);
        if (url) {
            const sandbox = getDataAttribute(app, "sandbox") ?? true;
            addIframeSlide(url, sandbox, { dateTo, alertEnd });
        }
    };
    return (
        <Swiper
            centeredSlides
            autoplay={{
                delay: 5000,
            }}
            pagination={{
                clickable: true,
            }}
            navigation
            className="news-slider"
        >
            {apps.map((app, i) => (
                <SwiperSlide key={i}>
                    <div
                        role={app.url ? "button" : undefined}
                        onClick={onClickHandler(app)}
                    >
                        <img
                            src={app.urlCover || app.img || undefined}
                            className="mw-100"
                            height="200"
                        />
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

const useAPI = USE_KEYCLOAK ? useAPIwithKeycloak : useOldAPI;
export const NewsSlider = (): JSX.Element | null => {
    const { data, error, setData } = useAPI<DBApp[]>("apps/highlighted");

    React.useEffect(() => {
        if (!data) return;
        let timeout: NodeJS.Timeout;
        const delay = getMinDelay(data);
        if (delay !== Infinity) {
            console.log(`NewsSlider: Refresh apps in ${delay / 1000}s`);
            setTimeout(() => {
                setData([...data]);
            }, delay);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [data, setData]);

    if (error) {
        return <ErrorMessage />;
    }

    if (!data) {
        return <Loading />;
    }

    const apps = data.filter(isValidApp);

    if (apps.length === 0) {
        return null;
    }

    return <NewsSliderContent apps={apps} />;
};
