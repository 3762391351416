import * as React from "react";
import clsx from "classnames";

import { Lazy } from "@/components/Lazy";

import "./AppAlbums.css";

type AlbumContextValue = [
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>
];

const AlbumContext = React.createContext<AlbumContextValue | undefined>(
    undefined
);

export const AppAlbums = ({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) => {
    const albumContext = React.useState<string>();
    return (
        <ul className={clsx("app-albums", className)}>
            <AlbumContext.Provider value={albumContext}>
                {children}
            </AlbumContext.Provider>
        </ul>
    );
};

const useAlbumContext = () => {
    const context = React.useContext(AlbumContext);
    if (!context) {
        throw new Error(
            "useAlbumContext must be used within AlbumContext.Provider"
        );
    }
    return context;
};

const AlbumIdContext = React.createContext<string | undefined>(undefined);

const useId = () => {
    const context = React.useContext(AlbumIdContext);
    if (context === undefined) {
        throw new Error("useId must be used within a Provider");
    }
    return context;
};

export const AppAlbum = ({
    id,
    children,
}: {
    id: string;
    children: React.ReactNode;
}) => {
    const [selected] = useAlbumContext();
    const isSelected = selected === id;
    return (
        <li className={clsx({ selected: isSelected })}>
            <AlbumIdContext.Provider value={id}>
                {children}
            </AlbumIdContext.Provider>
        </li>
    );
};

export const AppDetails = ({
    children,
    className,
}: {
    className?: string;
    children: React.ReactNode;
}) => {
    const [selected] = useAlbumContext();
    const id = useId();
    const isSelected = selected === id;
    return (
        <div className={clsx("app-albums-details", className)}>
            <Lazy visible={isSelected}>{children}</Lazy>
        </div>
    );
};

export const AppButton = ({
    children,
    className,
    disabled,
}: {
    className?: string;
    children: React.ReactNode;
    disabled?: boolean;
}) => {
    const [, setSelected] = useAlbumContext();
    const id = useId();
    const handleClick = disabled
        ? () => {}
        : () => setSelected((s) => (s === id ? undefined : id));
    return (
        <button
            type="button"
            className={clsx("marque new-app tooltip-arrow", className)}
            onClick={handleClick}
        >
            {children}
        </button>
    );
};
