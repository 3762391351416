import * as React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import SwiperCore, { Mousewheel, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import type { App } from "shared/dist/types/apps";

import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { useAPI } from "@/utils/hooks";

import { CarouselContext } from "../CarouselContext";
import { CAIA_LOGO_URL } from "../settings";

import { IdentifyVehicle } from "./IdentifyVehicle";
import { SearchBar } from "./SearchBar";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

SwiperCore.use([Mousewheel, Pagination]);

const SlideImage = ({ app }: { app: App }) => {
    const { addIframeSlide } = useAddItemSlide();
    if (!app || !app.img || !app.url) {
        return null;
    }
    if (app.appType === "iframe") {
        return (
            <div className="carselector-slide-img">
                <Button
                    color="link"
                    onClick={() => app.url && addIframeSlide(app.url)}
                >
                    <img src={app.img} title={app.name} />
                </Button>
            </div>
        );
    }
    if (app.appType === "linkTo") {
        return (
            <div className="carselector-slide-img">
                <Link to={app.url}>
                    <img src={app.img} title={app.name} />
                </Link>
            </div>
        );
    }
    return null;
};

interface Props {
    onLogoClick: () => void;
}

export const CarSelectorSwiper = ({ onLogoClick }: Props): JSX.Element => {
    const [slideId, setCarouselIndex] = React.useContext(CarouselContext);
    const { data: apps } = useAPI<App[]>("widget/v2-carselector", {
        initialData: [],
    });

    const handleSlideChange = (swiper: SwiperCore) => {
        setCarouselIndex(swiper.realIndex);
    };

    return (
        <>
            <SearchBar slideId={slideId} />
            <div className="menu-bloc d-flex">
                <div className="menu-bloc-image" onClick={onLogoClick}>
                    <img src={CAIA_LOGO_URL} alt="C@iA" />
                </div>
                <Swiper
                    spaceBetween={30}
                    effect="slide"
                    mousewheel
                    direction="vertical"
                    pagination={{ clickable: true }}
                    height={220}
                    onSlideChange={handleSlideChange}
                    initialSlide={slideId}
                >
                    <SwiperSlide>
                        <IdentifyVehicle />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="bloc-slide-title">
                            Sélection véhicule
                        </div>
                        <SlideImage app={apps[0]} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="bloc-slide-title">Sélection Pièces</div>
                        <SlideImage app={apps[1]} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="bloc-slide-title">Pneus</div>
                        <SlideImage app={apps[2]} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="bloc-slide-title">Diag</div>
                        <SlideImage app={apps[3]} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="bloc-slide-title">Contact</div>
                        <SlideImage app={apps[4]} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="bloc-slide-title">
                            Sélection Services
                        </div>
                        <div>Type de Service: Expertise</div>
                        <SlideImage app={apps[5]} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="bloc-slide-title">
                            Sélection Services
                        </div>
                        <div>Type de Service: Support</div>
                        <SlideImage app={apps[6]} />
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    );
};
