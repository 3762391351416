import type * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import type { Tire } from "shared/dist/types/parts";

import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { apiRootURL } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import { useAPI } from "@/utils/hooks";

import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";
import { TireStock } from "./TireStock";

import "react-table/react-table.css";
import "./TireStock.css";

const TIRE_IMAGE = `${apiRootURL}/media/img/tire.png`;

type TireStockWrapperProps = {
    breadcrumbsProps: {
        params: {
            brand: string | undefined;
            model: string | undefined;
            categories: string;
            categoryId: string;
        };
    };
    children: React.ReactNode;
};
const TireStockWrapper = ({
    breadcrumbsProps,
    children,
}: TireStockWrapperProps) => {
    const { t } = useTranslation();
    return (
        <div className="selection-bloc main-bloc item-bloc">
            <Breadcrumbs match={breadcrumbsProps} image={TIRE_IMAGE} />
            <h2>{t("albums.tires")}</h2>
            {children}
        </div>
    );
};

type TireStockData = Readonly<{
    record: string[][];
    stock: Tire[];
}>;

type Props = {
    searchForm?: { activeField?: string; immat?: string };
};

export const TireStockContainer = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [vehicleData] = useVehicleData();
    const match = useRouteMatch<{ categories: string }>();
    const { categories } = match.params;
    const { ktype, Marque: brand, Modèle: model } = vehicleData;

    const { searchForm = {} } = props;
    const { immat } = searchForm;
    const url = !ktype
        ? null
        : immat && searchForm.activeField === "immat"
        ? `tiresByImmat/${categories}/${immat}/${ktype}`
        : `tiresByKtype/${categories}/${ktype}`;
    const { data, error } = useAPI<TireStockData>(url);

    const params = {
        ...match.params,
        brand,
        model,
        categoryId: t("albums.tires"),
    };
    const breadcrumbsProps = { ...match, params };

    if (error) {
        return (
            <TireStockWrapper breadcrumbsProps={breadcrumbsProps}>
                <ErrorMessage />
            </TireStockWrapper>
        );
    }

    if (!data) {
        return (
            <TireStockWrapper breadcrumbsProps={breadcrumbsProps}>
                <Loading />
            </TireStockWrapper>
        );
    }

    if (data.stock.length === 0) {
        return (
            <TireStockWrapper breadcrumbsProps={breadcrumbsProps}>
                <p>{t("common.no-results")}</p>
            </TireStockWrapper>
        );
    }

    return (
        <TireStockWrapper breadcrumbsProps={breadcrumbsProps}>
            <TireStock tires={data.stock} />
        </TireStockWrapper>
    );
};
