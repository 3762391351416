import * as React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    faCartPlus,
    faPlus,
    faSearchPlus,
    faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PLUS_MENU_APPS } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useVehicleData } from "@/context/VehicleDataContext";

import { AppButton } from "./AppButton";
import { NewCustomSearch } from "./NewCustomSearch";

import "./PlusMenu.css";

const { Suspense, useState } = React;

const AddTipWizard = React.lazy(() => import("./AddTipWizard"));
const NewItemWizard = React.lazy(() =>
    import("./NewItemWizard").then((comp) => ({ default: comp.NewItemWizard }))
);

const doNothing = (): void => {};

export const PlusMenu = (): JSX.Element => {
    const [plusMenuOpen, setPlusMenuOpen] = useState(false);
    const [tipModalOpen, setTipModalOpen] = useState(false);
    const [partsSettingsModalOpen, setPartsSettingsModalOpen] = useState(false);
    const [newItemModalOpen, setNewItemModelOpen] = useState(false);
    const { t } = useTranslation();
    const { userInfo } = useAuth();
    const [vehicleData] = useVehicleData();

    const toggle = (): void => setPlusMenuOpen((open) => !open);
    const toggleAddTipModal = (): void => setTipModalOpen((open) => !open);
    const togglePartsSettingsModal = (): void =>
        setPartsSettingsModalOpen((open) => !open);
    const toggleNewItemModal = (): void => setNewItemModelOpen((open) => !open);

    const openModal = (): void => {
        setPlusMenuOpen(false);
        setTipModalOpen(true);
    };

    const openPartsSettingsModal = (): void => {
        setPlusMenuOpen(false);
        setPartsSettingsModalOpen(true);
    };

    const openNewItemModal = (): void => {
        setPlusMenuOpen(false);
        setNewItemModelOpen(true);
    };

    const displayAddTipButton = Boolean(vehicleData.Codif_Vin_PRF);
    const groups = (userInfo.stockGroups || []).filter(
        (group) => group.canUpdate === 1
    );
    const displayAddNewItemButton = groups.length > 0;
    return (
        <div>
            <div className="shortcut-menu-button" onClick={toggle}>
                <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className={plusMenuOpen ? "shortcut-menu" : "hidden"}>
                <div>{t("contactselector.social-network")}</div>
                <div>
                    {displayAddTipButton && (
                        <div
                            className="marque"
                            title={t("add-tip-wizard.add-a-tip")}
                        >
                            <FontAwesomeIcon
                                icon={faThumbsUp}
                                onClick={openModal}
                            />
                            <Modal
                                isOpen={tipModalOpen}
                                toggle={toggleAddTipModal}
                                size="lg"
                            >
                                <ModalHeader toggle={toggleAddTipModal}>
                                    {t("add-tip-wizard.add-a-tip")}
                                </ModalHeader>
                                <ModalBody>
                                    <Suspense
                                        fallback={
                                            <div className="add-tip-wizard">
                                                Loading…
                                            </div>
                                        }
                                    >
                                        <AddTipWizard
                                            close={toggleAddTipModal}
                                            modal
                                        />
                                    </Suspense>
                                </ModalBody>
                            </Modal>
                        </div>
                    )}
                    {displayAddNewItemButton && (
                        <div className="marque" title={t("plusmenu.new-item")}>
                            <FontAwesomeIcon
                                icon={faCartPlus}
                                onClick={openNewItemModal}
                            />
                            <Modal
                                isOpen={newItemModalOpen}
                                toggle={toggleNewItemModal}
                                size="xl"
                            >
                                <ModalHeader toggle={toggleNewItemModal}>
                                    {t("new-item-wizard.title")}
                                </ModalHeader>
                                <ModalBody>
                                    <Suspense
                                        fallback={
                                            <div className="new-item-wizard">
                                                Loading…
                                            </div>
                                        }
                                    >
                                        <NewItemWizard
                                            close={toggleNewItemModal}
                                        />
                                    </Suspense>
                                </ModalBody>
                            </Modal>
                        </div>
                    )}
                    <div
                        className="marque"
                        title={t("plusmenu.new-custom-search")}
                    >
                        <FontAwesomeIcon
                            icon={faSearchPlus}
                            onClick={openPartsSettingsModal}
                        />
                        <Modal
                            isOpen={partsSettingsModalOpen}
                            toggle={togglePartsSettingsModal}
                            size="lg"
                        >
                            <ModalHeader toggle={togglePartsSettingsModal}>
                                {t("partsettings.modal-title")}
                            </ModalHeader>
                            <ModalBody>
                                <NewCustomSearch
                                    close={togglePartsSettingsModal}
                                />
                            </ModalBody>
                        </Modal>
                    </div>
                    {PLUS_MENU_APPS.map((app) => (
                        <AppButton app={app} onClick={doNothing} key={app.id} />
                    ))}
                </div>
            </div>
        </div>
    );
};
