import type * as React from "react";
import { WonderPush } from "react-wonderpush";

import { useAuth, useRoles } from "@/context/AuthContext";

declare global {
    interface Window {
        WonderPush: {
            push(cb: () => void): void;
            addTag(...args: string[]): void;
        };
    }
}

export const WonderPushProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const { userInfo } = useAuth();
    const webKey = process.env.REACT_APP_WONDERPUSH_API_KEY;
    const roles = useRoles();

    if (webKey) {
        if (roles.length > 0) {
            window.WonderPush.push(() => {
                window.WonderPush.addTag(...roles);
            });
        }
        return (
            <WonderPush
                options={{
                    webKey,
                    userId: String(userInfo.userId),
                    serviceWorkerUrl: "/service-worker.js",
                }}
            >
                {children}
            </WonderPush>
        );
    }

    return <>{children}</>;
};
