import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowsAlt,
    faBarcode,
    faBuilding,
    faBullhorn,
    faCar,
    faCartPlus,
    faCertificate,
    faSearchPlus,
    faSpinner,
    faStar,
    faThumbsUp,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faArrowsAlt,
    faBarcode,
    faBuilding,
    faBullhorn,
    faCar,
    faCartPlus,
    faCertificate,
    faSearchPlus,
    faSpinner,
    faStar,
    faThumbsUp,
    faUsers
);
