import * as React from "react";
import { useCallback, useLayoutEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Quagga, { QuaggaJSResultObject } from "@ericblade/quagga2";
import {
    faBarcode,
    faImage,
    faPaperPlane,
    faPhotoVideo,
    faQrcode,
    faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import { IMAGES_SERVER } from "@/config/settings";

import { Iphone } from "./Iphone";
import { QRCode } from "./QRCode";
import { eventListener } from "./SSEHandler";

function getMedian(arr: number[]) {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
        return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
}

const isDefined = (code: { error?: number }): code is { error: number } => {
    return code.error !== undefined;
};

function getMedianOfCodeErrors(decodedCodes: { error?: number }[]) {
    const errors = decodedCodes.filter(isDefined).map((x) => x.error);
    const medianOfErrors = getMedian(errors);
    return medianOfErrors;
}

const parseResult = (result: QuaggaJSResultObject) => {
    const { code, decodedCodes } = result.codeResult;
    const err = getMedianOfCodeErrors(decodedCodes);
    const percentage = Math.round((1 - err) * 100);
    return { code: code || "", percentage };
};

const formatResult = ({ code, percentage }: BarcodeResult) =>
    percentage ? `${code} (${percentage}%)` : code;

const defaultConstraints = {
    width: 640,
    height: 480,
} as const;

const defaultLocatorSettings = {
    patchSize: "medium",
    halfSample: true,
} as const;

const handleProcessed =
    (container: HTMLElement) => (result: QuaggaJSResultObject) => {
        if (!result || !container) {
            return;
        }
        const drawingCanvas: HTMLCanvasElement | null =
            container.querySelector(".drawingBuffer");
        if (!drawingCanvas) {
            console.warn("drawingBuffer element not found");
            return;
        }
        const drawingCtx = drawingCanvas.getContext("2d");
        if (!drawingCtx) {
            return;
        }
        drawingCtx.font = "24px Arial";
        drawingCtx.fillStyle = "green";

        if (result.boxes) {
            drawingCtx.clearRect(
                0,
                0,
                parseInt(drawingCanvas.getAttribute("width") ?? ""),
                parseInt(drawingCanvas.getAttribute("height") ?? "")
            );
            result.boxes
                .filter((box) => box !== result.box)
                .forEach((box) => {
                    Quagga.ImageDebug.drawPath(
                        box,
                        { x: 0, y: 1 },
                        drawingCtx,
                        {
                            color: "green",
                            lineWidth: 2,
                        }
                    );
                });
        }
        if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                color: "blue",
                lineWidth: 2,
            });
        }
        if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(
                result.line,
                { x: "x", y: "y" },
                drawingCtx,
                { color: "red", lineWidth: 3 }
            );
        }
    };

const TOP0 = { top: 0 };

const DECODERS = {
    vin: ["code_39_vin_reader"],
    ean: ["ean_reader"],
};

type BarcodeResult = {
    code: string;
    percentage: number | null;
};

type ScannerProps = {
    type: "ean" | "vin";
    onSubmit: (value: string) => void;
    children: React.ReactNode;
};

const Scanner = ({ type, onSubmit, children }: ScannerProps) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [barcode, setBarcodeResult] = React.useState<BarcodeResult>({
        code: "",
        percentage: null,
    });
    const [hasImage, setHasImage] = React.useState(false);
    const [mode, setMode] = React.useState<"video" | "image">("video");
    const [deviceId, setDeviceId] = React.useState<string | null>(null);
    const [videoOptions, setVideoOptions] = React.useState<
        { label: string; value: string }[]
    >([]);

    useLayoutEffect(() => {
        if (!containerRef.current) return;
        const constraints = deviceId
            ? { deviceId }
            : { facingMode: "environment" };
        Quagga.init(
            {
                inputStream: {
                    type: "LiveStream",
                    constraints: { ...defaultConstraints, ...constraints },
                    target: containerRef.current,
                },
                locator: defaultLocatorSettings,
                numOfWorkers: navigator.hardwareConcurrency || 0,
                decoder: { readers: DECODERS[type] },
                locate: true,
            },
            (err) => {
                if (err) {
                    console.log("Error starting Quagga:", err);
                    setMode("image");
                    return;
                }
                Quagga.CameraAccess.enumerateVideoDevices()
                    .then((devices) => {
                        const options = devices.map((device) => ({
                            value: device.deviceId,
                            label: device.label || device.deviceId,
                        }));
                        setVideoOptions(options);
                    })
                    .catch((error) => console.error(error));
                Quagga.start();
            }
        );
        const onDetected = (result: QuaggaJSResultObject) => {
            setBarcodeResult(parseResult(result));
        };
        const onProcessed = handleProcessed(containerRef.current);
        Quagga.onProcessed(onProcessed);
        Quagga.onDetected(onDetected);
        return () => {
            Quagga.offProcessed(onProcessed);
            Quagga.offDetected(onDetected);
            try {
                Quagga.stop();
            } catch (error) {
                // fails if start() failed
            }
        };
    }, [deviceId, type]);

    const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0];
        if (file) {
            setHasImage(true);
            setBarcodeResult({ code: "", percentage: null });
            const src = URL.createObjectURL(file);
            Quagga.decodeSingle(
                {
                    inputStream: {
                        type: "ImageStream",
                        size: 1280,
                        target: containerRef.current ?? undefined,
                    },
                    locator: { patchSize: "medium", halfSample: true },
                    decoder: { readers: DECODERS[type] },
                    locate: true,
                    src,
                },
                () => {}
            );
        }
    };

    const streamLabel = Quagga.CameraAccess.getActiveStreamLabel();
    const isActive = (option: { label: string; value: string }) =>
        option.value === deviceId ||
        (!deviceId && option.label === streamLabel);

    return (
        <>
            <div
                className={`position-relative ${mode}-scanner`}
                ref={containerRef}
            >
                <canvas
                    className="drawingBuffer position-absolute"
                    height="480"
                    style={TOP0}
                />
                {mode === "image" && (
                    <canvas className="imgBuffer" height="480" />
                )}
                {barcode.code && (
                    <div className="barcode-result badge badge-info position-absolute">
                        {formatResult(barcode)}
                    </div>
                )}
                {mode === "image" && !hasImage && (
                    <img
                        className="position-absolute"
                        src={`${IMAGES_SERVER}/images/file-scan.gif`}
                        alt=""
                        height="50"
                        width="50"
                    />
                )}
            </div>
            <div className="barcode-apps d-flex justify-content-center btn-group-toggle">
                {videoOptions.length > 0 &&
                    videoOptions.map((option) => (
                        <label
                            key={option.value}
                            className={classnames(
                                "btn btn-outline-secondary me-2 app-button",
                                "d-inline-flex justify-content-center align-items-center",
                                { active: isActive(option) }
                            )}
                            title={option.label}
                        >
                            <input
                                type="radio"
                                name="videodevice"
                                value={option.value}
                                checked={isActive(option)}
                                onChange={(e) => {
                                    if (mode === "image") {
                                        setMode("video");
                                        Quagga.start();
                                    }
                                    setDeviceId(e.currentTarget.value);
                                }}
                            />
                            <FontAwesomeIcon icon={faVideo} size="2x" />
                        </label>
                    ))}
                {mode === "image" ? (
                    <label className="barcode-file-input btn btn-outline-secondary app-button overflow-hidden">
                        <FontAwesomeIcon icon={faImage} size="2x" />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={fileChangeHandler}
                            className="d-none"
                        />
                    </label>
                ) : (
                    <button
                        className="btn btn-outline-secondary me-2 app-button"
                        type="button"
                        onClick={() => {
                            setMode("image");
                            Quagga.stop();
                        }}
                    >
                        <FontAwesomeIcon icon={faPhotoVideo} size="2x" />
                    </button>
                )}
                {children}
                <button
                    type="button"
                    className="btn btn-primary app-button"
                    onClick={() => onSubmit(barcode.code)}
                    disabled={barcode.code === ""}
                >
                    <FontAwesomeIcon icon={faPaperPlane} size="2x" />
                </button>
            </div>
        </>
    );
};

export const ScannerContainer = ({
    onSubmit,
    type,
    showQRCode = false,
}: {
    onSubmit: (code: string) => void;
    type: "vin" | "ean";
    showQRCode?: boolean;
}): JSX.Element => {
    const [showQRCodePanel, setShowQRCodePanel] = React.useState(() => {
        eventListener.current = null;
        return false;
    });
    const toggleQRCodePanel = useCallback(
        () =>
            setShowQRCodePanel((show) => {
                const showPanel = !show;
                if (showPanel) {
                    eventListener.current = onSubmit;
                } else {
                    eventListener.current = null;
                }
                return showPanel;
            }),
        [onSubmit]
    );

    React.useEffect(() => {
        return () => {
            eventListener.current = null;
        };
    }, []);

    return (
        <>
            <div className="d-flex">
                <div className="overflow-hidden flex-grow-1">
                    <Scanner type={type} onSubmit={onSubmit}>
                        {showQRCode && (
                            <button
                                className="btn btn-outline-secondary me-2 app-button"
                                type="button"
                                onClick={toggleQRCodePanel}
                            >
                                <FontAwesomeIcon icon={faQrcode} size="2x" />
                            </button>
                        )}
                    </Scanner>
                </div>
                {showQRCodePanel && (
                    <Iphone className="ms-3">
                        <QRCode
                            size={160}
                            imageSize={50}
                            pathname={`/r${type}`}
                        />
                    </Iphone>
                )}
            </div>
        </>
    );
};

export const BarcodeReaderButton = (props: {
    onSubmit: (code: string) => void;
    type: "vin" | "ean";
    title?: string;
    className?: string;
    showQRCode?: boolean;
}): JSX.Element => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggle = useCallback(() => setModalOpen((o) => !o), []);

    const { title = "Barcode scanner", className, type } = props;

    return (
        <>
            <button
                className={classnames(
                    "btn btn-outline-secondary ms-2",
                    className
                )}
                type="button"
                onClick={toggle}
            >
                <FontAwesomeIcon icon={faBarcode} />
            </button>
            <Modal isOpen={modalOpen} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <ScannerContainer
                        type={type}
                        onSubmit={(code: string) => {
                            setModalOpen(false);
                            props.onSubmit(code);
                        }}
                        showQRCode={props.showQRCode}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};
