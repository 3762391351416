import * as React from "react";
import { Bounce, toast } from "react-toastify";
import { Button } from "reactstrap";

import { i18n } from "@/config/i18n";
import { IMAGES_SERVER } from "@/config/settings";
import { useServiceWorker } from "@/context/ServiceWorkerProvider";

const ShowToastOnUpdateAvailable = (): null => {
    const { assetsUpdateReady, updateAssets } = useServiceWorker();

    React.useEffect(() => {
        if (!assetsUpdateReady) return;
        toast(
            <div className="update-available d-flex align-items-center">
                <img
                    src={`${IMAGES_SERVER}/logos/logo_eyes.png`}
                    alt=""
                    className="mx-2"
                    width="100"
                    height="40"
                />
                <div>
                    <p className="mb-1">{i18n.t("update-available")}</p>
                    <Button color="caia" onClick={updateAssets}>
                        {i18n.t("common.refresh")}
                    </Button>
                </div>
            </div>,
            {
                autoClose: false,
                className: "update-available-toast",
                closeOnClick: false,
                position: "top-center",
                transition: Bounce,
            }
        );
    }, [assetsUpdateReady, updateAssets]);

    return null;
};

export const UpdateAvailable = (): JSX.Element => (
    <React.Suspense fallback={null}>
        <ShowToastOnUpdateAvailable />
    </React.Suspense>
);
