import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import { useRecoilState } from "recoil";

import { CountrySelector } from "@/components/CountrySelector";
import { Restricted } from "@/components/Restricted";
import { UserCompany } from "@/components/UserCompany";
import { UserName } from "@/components/UserName";
import { useAuth } from "@/context/AuthContext";
import { CriteriaContext } from "@/context/CriteriaContext";
import { useUserSettingsContext } from "@/context/UserSettingsContext";
import {
    exactSearchAtom,
    identificationAlbumModeAtom,
    partsAlbumModeAtom,
    selectionAlbumModeAtom,
    urlERPAtom,
    urlMyBoxAtom,
} from "@/globalState/atoms";

const MyAccountContent = (): JSX.Element => {
    const { t } = useTranslation();
    const [criterias, setCriterias] = React.useContext(CriteriaContext);
    const toggleCriterias = (): void => {
        setCriterias({ hide: !criterias.hide });
    };
    const [urlMyBox, updateUrlMyBox] = useRecoilState(urlMyBoxAtom);
    const [urlERP, updateUrlERP] = useRecoilState(urlERPAtom);
    const [exactSearchMode, setExactSearchMode] =
        useRecoilState(exactSearchAtom);
    const toggleSearchMode = () => {
        setExactSearchMode((m) => !m);
    };
    const [identificationAlbumMode, setIdentificationAlbumMode] =
        useRecoilState(identificationAlbumModeAtom);
    const [selectionAlbumMode, setSelectionAlbumMode] = useRecoilState(
        selectionAlbumModeAtom
    );
    const [partsAlbumMode, setPartsAlbumMode] =
        useRecoilState(partsAlbumModeAtom);
    const [userSettings, setUserSetting] = useUserSettingsContext();
    const { hasAccess } = useAuth();

    const onSearchBarChange = () => {
        setUserSetting("searchBar", (oldValue) =>
            oldValue === "show" ? "hide" : "show"
        );
    };

    const onIdentifyVehicleChange = () => {
        setUserSetting("identifyVehicle", (oldValue) =>
            oldValue === "hide" ? "show" : "hide"
        );
    };

    const onOEMTabChange = () => {
        setUserSetting("OEMTab", (oldValue) =>
            oldValue === "hide" ? "show" : "hide"
        );
    };

    const onToggleNavChange = () => {
        setUserSetting("toggleNav", (oldValue) =>
            oldValue === "hide" ? "show" : "hide"
        );
    };

    return (
        <div className="my-account">
            <div dangerouslySetInnerHTML={{ __html: t("my-account.title") }} />
            <UserName className="account-username" />
            <UserCompany />
            <CountrySelector />
            <div>
                <label role="button">
                    <span>Afficher la barre de recherche</span>
                    <input
                        type="checkbox"
                        className="ms-2"
                        checked={userSettings.searchBar === "show"}
                        onChange={onSearchBarChange}
                    />
                </label>
            </div>
            <div>
                <label role="button">
                    <span>Cacher le bloc Vehicle Identifier</span>
                    <input
                        type="checkbox"
                        className="ms-2"
                        checked={userSettings.identifyVehicle === "hide"}
                        onChange={onIdentifyVehicleChange}
                    />
                </label>
            </div>
            <div>
                <label className="cursor-pointer">
                    Afficher l&rsquo;onglet OEM si vin inconnu
                    <input
                        className="ms-2"
                        type="checkbox"
                        checked={userSettings.OEMTab !== "hide"}
                        onChange={onOEMTabChange}
                    />
                </label>
            </div>
            <div>
                <label className="cursor-pointer">
                    Afficher masquer les menus de navigation
                    <input
                        type="checkbox"
                        className="ms-2"
                        checked={userSettings.toggleNav !== "hide"}
                        onChange={onToggleNavChange}
                        disabled={!hasAccess("toggleNav") && !hasAccess("v3")}
                    />
                </label>
            </div>
            <div>
                <span>Mode album</span>
                <ul>
                    <li>
                        <label>
                            {t("my-account.identify-vehicle")}{" "}
                            <input
                                type="checkbox"
                                checked={identificationAlbumMode}
                                onChange={() =>
                                    setIdentificationAlbumMode((m) => !m)
                                }
                            />
                        </label>
                    </li>
                    <li>
                        <label>
                            {t("my-account.car-selector")}{" "}
                            <input
                                type="checkbox"
                                checked={selectionAlbumMode}
                                onChange={() =>
                                    setSelectionAlbumMode((m) => !m)
                                }
                            />
                        </label>
                    </li>
                    <li>
                        <label>
                            {t("my-account.parts-selector")}{" "}
                            <input
                                type="checkbox"
                                checked={partsAlbumMode}
                                onChange={() => setPartsAlbumMode((m) => !m)}
                                disabled
                            />
                        </label>
                    </li>
                </ul>
            </div>
            <div style={{ marginTop: 10 }}>
                <span>{t("my-account.partselector")}</span>
                <ul>
                    <li>
                        <label>
                            {t("my-account.exact-search")}{" "}
                            <input
                                type="checkbox"
                                checked={exactSearchMode}
                                onChange={toggleSearchMode}
                            />
                        </label>
                    </li>
                </ul>
            </div>
            <div style={{ marginTop: 10 }}>
                <span>{t("my-account.results")}</span>
                <ul>
                    <li>
                        <label>
                            {t("my-account.hide-criterias")}{" "}
                            <input
                                type="checkbox"
                                checked={criterias.hide}
                                onChange={toggleCriterias}
                            />
                        </label>
                    </li>
                </ul>
            </div>
            <div style={{ marginTop: 10 }}>
                <span>{t("my-account.urls")}</span>
                <ul>
                    <li>
                        <label>
                            {t("my-account.my-box")}{" "}
                            <Input
                                type="text"
                                value={urlMyBox}
                                onChange={(evt) =>
                                    updateUrlMyBox(evt.target.value)
                                }
                                style={{ width: "30em" }}
                            />
                        </label>
                    </li>
                    <li>
                        <label>
                            {t("my-account.my-erp")}{" "}
                            <Input
                                type="text"
                                value={urlERP}
                                onChange={(evt) =>
                                    updateUrlERP(evt.target.value)
                                }
                                style={{ width: "30em" }}
                            />
                        </label>
                    </li>
                </ul>
            </div>
            <div style={{ marginTop: 10 }}>
                <Link to="/password" style={{ color: "white" }}>
                    Changer de mot de passe
                </Link>
            </div>
        </div>
    );
};

export const MyAccount = (): JSX.Element => (
    <Restricted access="MyAccount">
        <MyAccountContent />
    </Restricted>
);
