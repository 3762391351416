import * as React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import clsx from "classnames";

type App = {
    id?: number;
    url: string | null;
    openInNewTab?: boolean;
    img: string | null;
    name: string;
};

type Props = {
    app: App;
    linkTo?: string | null;
    onClick?: (id: string) => void;
    children?: React.ReactNode;
    className?: string;
};

const AppImage = (props: JSX.IntrinsicElements["img"]) => (
    <img {...props} width="70" height="70" />
);

const SpansForHoverStyle = () => (
    <>
        <span />
        <span />
        <span />
        <span />
    </>
);

export const AppButton = (props: Props): JSX.Element => {
    const { app, linkTo } = props;
    const ref = React.useRef(null);

    const onClick = (): void => {
        props.onClick?.(app.url || "");
    };

    const title = app.name === "REPARCAR" ? "SivinCE" : app.name;
    const className = clsx("marque app-button", props.className);

    if (app.openInNewTab) {
        return (
            <>
                <a
                    className={className}
                    key={app.id}
                    href={app.url || undefined}
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={ref}
                >
                    <AppImage src={app.img || undefined} alt={app.name} />
                    <span className="app-label">{title}</span>
                    {props.children}
                </a>
                <UncontrolledTooltip target={ref}>{title}</UncontrolledTooltip>
            </>
        );
    }
    if (linkTo) {
        return (
            <>
                <Link className={className} to={linkTo} key={app.id} ref={ref}>
                    <AppImage src={app.img || undefined} alt={app.name} />
                    <span className="app-label">{title}</span>
                    {props.children}
                </Link>
                <UncontrolledTooltip target={ref}>{title}</UncontrolledTooltip>
            </>
        );
    }
    return (
        <>
            <div className={className} key={app.id} ref={ref} onClick={onClick}>
                <SpansForHoverStyle />
                <AppImage src={app.img || undefined} alt={app.name} />
                <span className="app-label">{title}</span>
                {props.children}
            </div>
            <UncontrolledTooltip target={ref}>{title}</UncontrolledTooltip>
        </>
    );
};
