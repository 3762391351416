import * as React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Pass } from "shared/dist/types/passkit";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { Restricted } from "@/components/Restricted";
import { postContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";

const updatePass = async (id: string, vehicle: string) => {
    await postContent(`updatePass/${id}`, { vehicle });
};

type PassModalProps = {
    pass: Pass;
    isOpen: boolean;
    toggle(): void;
    onSuccess(): void;
};

const PassModal = ({ pass, isOpen, toggle, onSuccess }: PassModalProps) => {
    const { handleSubmit, register } = useForm<{ vehicle: string }>();
    const submit = async (data: { vehicle: string }) => {
        console.log(data);
        try {
            await updatePass(pass.serialNumber, data.vehicle);
            toast.success("Pass mis à jour");
            toggle();
            onSuccess();
        } catch (error) {
            console.error(error);
            toast.error("Impossible de mettre à jour le pass");
        }
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <form onSubmit={handleSubmit(submit)}>
                <ModalBody>
                    <div>
                        <textarea
                            defaultValue={pass.vehicle}
                            {...register("vehicle")}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="link">
                        <FontAwesomeIcon
                            icon={faCheck}
                            size="2x"
                            className="me-4 text-success"
                        />
                    </Button>
                    <Button type="button" onClick={toggle} color="link">
                        <FontAwesomeIcon
                            icon={faTimes}
                            role="button"
                            size="2x"
                            className="text-secondary"
                        />
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

const PassRow = ({ pass, onSuccess }: { pass: Pass; onSuccess(): void }) => {
    const [isOpen, setOpen] = React.useState(false);
    const toggle = () => setOpen((o) => !o);
    return (
        <div>
            <span className="me-2">{pass.serialNumber}</span>
            <span className="me-2">{pass.vehicle}</span>
            <span className="me-2">{pass.updated}</span>
            <Button color="link" onClick={toggle}>
                <FontAwesomeIcon icon={faEdit} />
            </Button>
            <PassModal
                pass={pass}
                isOpen={isOpen}
                toggle={toggle}
                onSuccess={onSuccess}
            />
        </div>
    );
};

const PassList = (): JSX.Element => {
    const { data, error, fetchData } = useAPI<Pass[]>("pkpass", {
        ignoreCache: true,
    });
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    return (
        <ol>
            {data.map((pass) => (
                <li key={pass.serialNumber}>
                    <PassRow pass={pass} onSuccess={fetchData} />
                </li>
            ))}
        </ol>
    );
};

const AdminIdCarWrapper = (): JSX.Element => {
    return (
        <div className="admin-idcar flex-grow-1 d-flex mt-5 bg-light m-2 p-3 flex-column position-relative">
            <h1>Liste des pass</h1>
            <div>
                <PassList />
            </div>
        </div>
    );
};

export const AdminIdCar = (): JSX.Element => (
    <Restricted access="admin">
        <AdminIdCarWrapper />;
    </Restricted>
);
