import type * as React from "react";

import "./ToggleComponent.css";

type ToggleComponentProps = {
    className?: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
};

export const ToggleComponent = ({
    className = "",
    onChange,
    checked,
    children,
}: ToggleComponentProps): JSX.Element => {
    return (
        <label className={className} role="button">
            {children}
            <span className="toggle-component">
                <input
                    type="checkbox"
                    onChange={onChange}
                    checked={checked}
                    className="d-none"
                />
                <span />
            </span>
        </label>
    );
};
