import * as React from "react";
import { useTranslation } from "react-i18next";

import type { TamaArticle } from "./Item";

export const TabLabel = ({
    article,
}: {
    article: TamaArticle;
}): JSX.Element => {
    const { t } = useTranslation();
    const label = /^\d/.test(article.id)
        ? t("item.tab-ktypnr", { ktypnr: article.id })
        : "TAMA: " + article.id;
    return <>{label}</>;
};
