import * as React from "react";
import { Nav, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";

import "./WizardNavigation.css";

type MovingTabProps = {
    num: number;
    style: React.CSSProperties;
    labels?: string[];
};

const MovingTab = ({ num, labels = [], style }: MovingTabProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    return (
        <>
            <div
                ref={ref}
                className="moving-tab"
                style={{
                    ...style,
                    transform: "translate(" + (num - 1) * 100 + "%)",
                }}
            >
                {labels?.[0] ?? num}
            </div>
            {labels?.length > 1 && (
                <UncontrolledTooltip target={ref}>
                    {labels.map((label, i) => (
                        <div key={i}>{label}</div>
                    ))}
                </UncontrolledTooltip>
            )}
        </>
    );
};

type Props<T extends string | number> = {
    activeTab: T | undefined;
    updateActiveTab: (id: T) => void;
    tabIds: T[];
    tabLabels?: Record<T, string[]>;
};

export const WizardNavigation = <T extends string | number = number>({
    activeTab,
    updateActiveTab,
    tabIds,
    tabLabels,
}: Props<T>): JSX.Element => {
    if (tabIds.length < 2) {
        return <div style={{ height: 20 }} />;
    }

    const width = `${100 / tabIds.length}%`;

    const TabItem = ({ id, num }: { id: T; num: number }) => (
        <NavItem style={{ maxWidth: width }}>
            <NavLink
                className={classnames({ active: activeTab === id })}
                onClick={(): void => updateActiveTab(id)}
            >
                {tabLabels?.[id]?.[0] ?? num}
            </NavLink>
        </NavItem>
    );

    return (
        <div className="wizard-navigation">
            <Nav pills>
                {tabIds.map((id, num) => (
                    <TabItem id={id} num={num + 1} key={id} />
                ))}
            </Nav>
            <MovingTab
                num={tabIds.findIndex((id) => id === activeTab) + 1}
                style={{ width }}
                labels={activeTab && tabLabels?.[activeTab]}
            />
        </div>
    );
};
