import * as React from "react";

import { addClient, removeClient } from "@/components/SSEHandler";
import { useCart } from "@/context/SimpleCartContext";

export const RegisterCartEvents = () => {
    const [, setCartItems] = useCart();

    React.useEffect(() => {
        const clientId = addClient((data) => {
            console.log("Event received", data);
            if (data.type === "Cart items") {
                setCartItems(data.items);
            }
        });
        return () => {
            removeClient(clientId);
        };
    }, [setCartItems]);

    return null;
};
