import * as React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Badge, Button } from "reactstrap";

import { postContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";
import { getManufacturerLogoURL } from "@/utils/utils";

import { HubIdlpButton } from "./Item/HubIdlpButton";
import { OEWithTooltip } from "./Item/OEWithTooltip";
import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";

import "./ProductAlternative.css";

const { useState, useEffect } = React;

type Props = {
    oeList: string[];
    onOEClick: (oe: string) => void;
    criterias: Record<string, string>;
};

type Brand = {
    id: number;
    name: string;
    label: string;
    oes: string[];
};

export const ProductAlternative = ({
    oeList,
    onOEClick,
    criterias,
}: Props): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
    const { t } = useTranslation();
    const lang = useLanguage();

    useEffect(() => {
        let ignore = false;
        if (!oeList || !oeList.filter(Boolean).length) {
            return;
        }
        setIsLoading(true);
        postContent(`getOEbrands/${lang}`, oeList)
            .then((res) => res.json())
            .then((results: Brand[]) => {
                if (!ignore) {
                    setIsLoading(false);
                    setBrands(results);
                }
            })
            .catch(() => setHasError(true));
        return (): void => {
            ignore = true;
        };
    }, [lang, oeList]);

    if (hasError) {
        return <ErrorMessage />;
    }

    if (isLoading) {
        return <Loading />;
    }

    if (brands.length === 0) {
        return <Alert color="info">{t("common.no-results")}</Alert>;
    }

    if (selectedBrand === null) {
        return (
            <div className="product-alternative">
                {brands.map((brand) => (
                    <div
                        className="marque"
                        key={brand.id}
                        onClick={(): void => {
                            setSelectedBrand(brand);
                        }}
                    >
                        <img
                            src={getManufacturerLogoURL(brand.name)}
                            alt={brand.label}
                            title={brand.label}
                        />
                        <Badge>{brand.oes.length}</Badge>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="product-alternative-oes">
            <span>{selectedBrand.label}</span>
            <ul className="product-alternative-list">
                {selectedBrand.oes.map((oe) => (
                    <li key={oe}>
                        <OEWithTooltip
                            oe={criterias[oe] ? `${oe} - ${criterias[oe]}` : oe}
                            criteria={criterias[oe]}
                            onClick={(): void => onOEClick(oe)}
                        />
                        <HubIdlpButton value={oe} className="" />
                    </li>
                ))}
            </ul>
            <Button
                color="primary"
                onClick={(): void => setSelectedBrand(null)}
            >
                Retour
            </Button>
        </div>
    );
};
ProductAlternative.defaultProps = {
    onOEClick: (): void => {},
    criterias: {},
};
