import * as React from "react";
import { Button } from "reactstrap";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSlidesContext } from "@/components/SlidesStack";

export const HideRSEButton = ({ callback }: { callback(): void }) => {
    const { slides } = useSlidesContext();
    if (slides.length === 0) {
        return null;
    }
    return (
        <Button color="none" className="p-1" onClick={callback}>
            <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        </Button>
    );
};
