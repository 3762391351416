import * as React from "react";

import { useAuth } from "@/context/AuthContext";
import { useLanguage } from "@/utils/hooks";

export const UserName = ({
    className = "",
}: {
    className?: string;
}): JSX.Element | null => {
    const { userInfo } = useAuth();
    const shortLang = useLanguage();
    if (!userInfo.name) return null;

    const lastNameFirst = shortLang === "zh";
    const userClassName = `${className} username ${
        lastNameFirst ? " name-reversed" : ""
    }`;

    return (
        <div className={userClassName}>
            <span>{userInfo.name}</span>
        </div>
    );
};
