import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Astuces } from "@/components/Astuces";
import { GetCustomerData } from "@/components/GetCustomerData";
import { IdCarPanel } from "@/components/IdCar";
import { IdentifyVehicle } from "@/components/IdentifyVehicle";
import { RedirectToGenarts } from "@/components/RedirectToGenarts";
import { Restricted } from "@/components/Restricted";
import { TabsIdentVehicle } from "@/components/TabsIdentVehicle";
import { identificationAlbumModeAtom } from "@/globalState/atoms";

const TabsCarIdentifier = () => {
    const identificationAlbumMode = useRecoilValue(identificationAlbumModeAtom);
    return <TabsIdentVehicle albumMode={identificationAlbumMode} />;
};

export const CarIdentifier = (): JSX.Element => {
    return (
        <Restricted access="CarIdentifier">
            <Switch>
                <Route path="/identification-vehicule/search/(operation|MID)/:value/search/MD360dtc/:dtc">
                    <RedirectToGenarts />
                </Route>
                <Route path="/identification-vehicule/search/:field/:value">
                    <IdentifyVehicle />
                </Route>
                <Route path="/identification-vehicule">
                    <IdentifyVehicle />
                </Route>
            </Switch>
            <Route path="/identification-vehicule/search/:field/:value">
                <TabsCarIdentifier />
            </Route>
            <GetCustomerData />
            <Astuces />
            <IdCarPanel />
        </Restricted>
    );
};
