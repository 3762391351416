import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Collapse } from "reactstrap";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
    faBoxOpen,
    faDesktop,
    faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { AppsWidget } from "@/components/AppsWidget";
import { DiagSearch } from "@/components/DiagSearch";
import { Photo360 } from "@/components/Photo360";
import { Restricted } from "@/components/Restricted";
import { SlidesStack, useSlides } from "@/components/SlidesStack";
import { DIAG_OTHER_BRANDS, IMAGES_SERVER } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import {
    identificationVehiculePageAtom,
    urlMyBoxAtom,
} from "@/globalState/atoms";
import { cache } from "@/utils/cache";

import "./Diag.css";

import history from "@/img/history.png";
import star from "@/img/star.png";
import suppliers from "@/img/Suppliers.jpg";

const { useState, useCallback } = React;

const DIAG_PHOTO_PREFIX = "diag-photo360-";
const PHOTOS_ROOT_URL = `${IMAGES_SERVER}/360_ADAS/standard/`;
const ADAS_IMG_URL = `${IMAGES_SERVER}/360_ADAS/img/adas.jpeg`;

export const DiagIframe = ({ url }: { url: string }): JSX.Element => {
    const allow = "allow-scripts allow-same-origin allow-forms allow-popups";
    const slide = (
        <iframe
            title={"Diag-" + url}
            src={url}
            frameBorder="0"
            allow="microphone; camera"
            sandbox={allow}
        />
    );
    return slide;
};

const DiagSearchV1 = () => {
    const [, updateVehicleData] = useVehicleData();
    const setIdentificationVehiculePage = useSetRecoilState(
        identificationVehiculePageAtom
    );

    const onVinSearch = (vin: string) => {
        setIdentificationVehiculePage(
            `identification-vehicule/search/vin/${vin}`
        );
    };

    return (
        <DiagSearch
            updateVehicleData={updateVehicleData}
            onVinSearch={onVinSearch}
        />
    );
};

const DiagContent = (): JSX.Element => {
    const [otherBrandsOpen, setOtherBrandsOpen] = useState(false);
    const { t } = useTranslation();
    const { addSlide } = useSlides();
    const showMyBoxLink = useRecoilValue(urlMyBoxAtom) !== "";
    const [vehicleData] = useVehicleData();

    const toggleOtherBrands = (): void => setOtherBrandsOpen((open) => !open);

    const addIframeSlide = useCallback(
        (url: string | undefined) => {
            if (!url) return;
            addSlide({ id: url, content: <DiagIframe url={url} /> });
        },
        [addSlide]
    );

    const addPhoto360Slide = useCallback(
        (ref: string): void => {
            const vin = ref.replace(DIAG_PHOTO_PREFIX, "");
            const data = cache.vehicleData[vin];
            if (!data) {
                console.warn("No vehicleData found for " + vin);
                return;
            }
            const { Marque, Modèle } = data;
            const slide = (
                <div className="adas-360">
                    <div className="vehicule-banner">
                        <div className="vehicule-brand">
                            <span>{`${Marque} ${Modèle}`}</span>
                        </div>
                        <div className="adas-triangle">
                            <div>360° ADAS</div>
                        </div>
                        <div>VIN: {vin}</div>
                    </div>
                    <Photo360
                        vehicleData={data}
                        showCritair={false}
                        showColorBanner={false}
                        photosRootURL={PHOTOS_ROOT_URL}
                    />
                </div>
            );
            console.log("Add slide " + ref);

            addSlide({ id: ref, content: slide, className: "adas-slide" });
        },
        [addSlide]
    );

    const addNewPhoto360Slide = (): void => {
        console.log("360°");
        const vin = vehicleData.Codif_Vin_PRF;
        if (!vin) {
            console.log("No vin");
            return;
        }
        const ref = `${DIAG_PHOTO_PREFIX}${vin}`;
        cache.vehicleData[vin] = vehicleData;
        addPhoto360Slide(ref);
    };

    const handleOtherBrandClick = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => addIframeSlide(event.currentTarget.dataset.url);

    const hasVin = Boolean(vehicleData.Codif_Vin_PRF);

    return (
        <React.Fragment>
            <div className="inner-content">
                <div className="diag-top">
                    <DiagSearchV1 />
                    <Button
                        color="primary"
                        className="adas-button"
                        onClick={addNewPhoto360Slide}
                        disabled={!hasVin}
                    >
                        ADAS
                    </Button>
                </div>
                <div className="main-bloc diag">
                    <div>
                        <div>{t("diag.car-manufacturer-tools")}</div>
                        <div className="main-brands">
                            <AppsWidget widget="diag-manufacturers" />
                        </div>
                        <div className="brand-buttons">
                            <div className="marque" onClick={toggleOtherBrands}>
                                <img src={suppliers} alt="Suppliers" />
                                <FontAwesomeIcon icon={faBoxOpen} />
                                <span> {t("partselector.other-brands")}</span>
                            </div>
                            <div className="marque">
                                <img src={history} alt="history" />
                                <FontAwesomeIcon icon={faHistory} />
                                <span> {t("carselector.history")}</span>
                            </div>
                            <div className="marque">
                                <img src={star} alt="favorites" />
                                <FontAwesomeIcon icon={faStar} />
                                <span> {t("carselector.bookmarks")}</span>
                            </div>
                        </div>
                        <Collapse isOpen={otherBrandsOpen}>
                            <div className="other-brands tooltip-content">
                                <ul>
                                    {DIAG_OTHER_BRANDS.map((brand, i) => (
                                        <li key={brand.name + i}>
                                            <div
                                                data-url={brand.url}
                                                onClick={handleOtherBrandClick}
                                            >
                                                <span>{brand.name}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Collapse>
                    </div>
                    <div className="adas-image">
                        <img src={ADAS_IMG_URL} alt="ADAS" />
                    </div>
                    <div className="diag-tools-manufacturers">
                        <div>{t("diag.diag-tools-manufacturer")}</div>
                        {showMyBoxLink && (
                            <Link
                                to="/app/mybox"
                                className="square-app"
                                title={t("leftmenu.app/mybox")}
                            >
                                <FontAwesomeIcon icon={faDesktop} />
                            </Link>
                        )}
                        <AppsWidget widget="diag-tools" />
                    </div>
                </div>
            </div>
            <SlidesStack className="iframe-wrapper iframe-partselector" />
        </React.Fragment>
    );
};

export const Diag = (): JSX.Element => (
    <Restricted access="Diag">
        <DiagContent />
    </Restricted>
);
