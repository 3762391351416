import { useAPI } from "./hooks";

export type PartBrand = {
    name: string;
    id: string;
    order: number;
    type: "MDD" | "tecdoc";
};

export const usePartsBrands = (): {
    partsBrands: PartBrand[];
    otherParts: string[];
    favoritePartsBrands: PartBrand[];
    partsBrandsNames: Record<string, string>;
} => {
    const { data: partsBrands } = useAPI<PartBrand[]>("partsBrands", {
        initialData: [],
    });
    const otherParts = partsBrands
        .filter((brand) => brand.order === 0)
        .map((brand) => brand.name);
    const favoritePartsBrands = partsBrands
        .filter((brand) => brand.order > 0 && brand.type !== "MDD")
        .sort((a, b) => a.order - b.order);

    const partsBrandsNames = partsBrands.reduce(
        (dic: Record<string, string>, brand) => {
            dic[brand.id] = brand.name;
            return dic;
        },
        {}
    );

    return { partsBrands, otherParts, favoritePartsBrands, partsBrandsNames };
};
