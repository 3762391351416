import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import type { ExpertData, ExpertDataAttribute } from "shared/dist/types/parts";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { Item, TamaArticle } from "@/components/Item/Item";
import { useExpertDataMode } from "@/context/ExpertDataContext";
import { getContent } from "@/utils/fetch";
import { useAPI, useGenarts, useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

const { useEffect } = React;

export type ItemAttributes = Record<string, Record<string, string>>;

const reduceAttributes = (attributes: ExpertDataAttribute[]): ItemAttributes =>
    attributes.reduce<ItemAttributes>((o, row) => {
        const key = row.articleId + "/" + row.refId;
        o[key] = o[key] || {};
        o[key][row.attribute] = row.value;
        return o;
    }, {});

export const parseAttributes = (
    attributes: ExpertDataAttribute[]
): ItemAttributes[] => {
    const localAttributes = attributes.filter((row) => row.ktype !== "GLOBAL");
    const globalAttributes = attributes.filter((row) => row.ktype === "GLOBAL");
    return [localAttributes, globalAttributes].map(reduceAttributes);
};

type Props = {
    ktype?: string;
    hmdnr?: string;
    brand?: string;
    model?: string;
    itemId?: string;
    vehicleData: VehicleData;
    match: {
        params: {
            family: string;
            subFamily: string;
            categoryId: string;
            itemId: string;
            ktype?: string;
            genartIds?: string;
            hmdnr: string;
        };
    };
};

export const hasCorrectEngineCode = (
    item: TamaArticle,
    enginecode: string | undefined
): boolean => {
    const { "For Enginecode": enginecodeTama } = item.discriminants || {};
    if (enginecode && enginecodeTama) {
        const showItem = enginecodeTama.value.split("/").includes(enginecode);
        if (!showItem) {
            return false;
        }
    }
    return true;
};

export const hasCorrectDate = (
    item: TamaArticle,
    vehicleData: VehicleData
): boolean => {
    const { "Année 1ère MEC": year, "Mois 1ère MEC": month } = vehicleData;
    const dateFrom = item.discriminants?.["Date From"]?.value ?? "0000-00";
    const dateTo = item.discriminants?.["Date To"]?.value ?? "9999-99";
    if (year && month) {
        const dateMEC = `${year}-${month}`;
        const showItem = dateFrom <= dateMEC && dateMEC <= dateTo;
        if (!showItem) {
            return false;
        }
    }
    return true;
};

type PartsData = {
    criterias: Record<string, string>;
    id: number;
    tamaArticles: TamaArticle[];
};

export const ItemContainer = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const lang = useLanguage(true);
    const expertDataMode = useExpertDataMode();
    const { genartsMap } = useGenarts();

    let { vtype } = useParams<{ vtype: string }>();
    if (!vtype) {
        vtype = props.vehicleData.ntypnr ? "pl" : "vl";
    }
    const typnr = props.ktype || props.vehicleData.ntypnr;

    const apiRoute = expertDataMode ? "vehicle-parts-full" : "vehicle-parts";

    const { genartIds } = props.match.params;

    const { ktype, itemId } = props;
    const { data: expertData, error: expertDataError } = useAPI<ExpertData>(
        typnr && itemId ? `expertData/${ktype}/${itemId}` : null
    );
    const url =
        typnr && itemId
            ? `${apiRoute}/${vtype}/${lang}/${typnr}/${itemId}`
            : null;
    const { data, error } = useAPI<PartsData>(url);

    useEffect(() => {
        if (!data || !genartIds) {
            return;
        }
        // preload other genarts data in cache
        const genarts = genartIds.split("-");
        genarts.forEach((genartId) => {
            const endpoint = `${apiRoute}/${vtype}/${lang}/${typnr}/${genartId}`;
            getContent(endpoint).catch((err) => console.error(err));
        });
    }, [apiRoute, data, genartIds, lang, typnr, vtype]);

    useEffect(() => {
        if (!data) {
            return;
        }
        const { tamaArticles } = data;
        if (
            tamaArticles.length > 2 &&
            tamaArticles.some((tab) => !hasCorrectDate(tab, props.vehicleData))
        ) {
            toastr.info(t("item.toast.filter-date"));
        }
        if (
            tamaArticles.length > 2 &&
            tamaArticles.some(
                (tab) =>
                    !hasCorrectEngineCode(tab, props.vehicleData.Code_Moteur)
            )
        ) {
            toastr.info(t("item.toast.filter-engine-code"));
        }
    }, [data, props.vehicleData, t]);

    if (error || expertDataError) {
        return <Item {...props} error label="" items={[]} />;
    }

    if (!data || !expertData) {
        return <Item {...props} isLoading label="" items={[]} />;
    }

    const [localAttributes, globalAttributes] = parseAttributes(
        expertData.attributes
    );

    return (
        <Item
            {...props}
            label={genartsMap[Number(itemId)]?.label}
            localAttributes={localAttributes}
            globalAttributes={globalAttributes}
            criterias={data.criterias}
            items={data.tamaArticles}
            expertData={expertData.hidden}
        />
    );
};

ItemContainer.defaultProps = {
    vehicleData: {},
};
