import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import en from "@/config/translations.json";

export const defaultNS = "translations";
export const resources = { en: { [defaultNS]: en } };

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        compatibilityJSON: "v3",
        ns: [defaultNS],
        defaultNS,

        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },
    });

export { i18n };
