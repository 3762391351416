import * as React from "react";

import { apiRootURL } from "@/config/settings";
import { useScripts } from "@/v2/hooks";

import { Restricted } from "./Restricted";

import "./SpeechRecognition.css";

const SpeechButtonElement = (): JSX.Element => {
    useScripts([
        "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js",
        `${apiRootURL}/media/js/TweenMax.min.js`,
        `${apiRootURL}/media/js/index_siri.js`,
    ]);
    return (
        <div className="speech-button">
            <div id="results">
                <span id="final_span" className="final"></span>
                <span id="interim_span" className="interim"></span>
                <p />
            </div>
            <div id="visualization"></div>
            <div id="button" style={{ width: 50, height: 50 }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="microphone"
                    data-container-transform="translate(3)"
                    viewBox="0 0 16 20"
                    x="0px"
                    y="0px"
                >
                    <path
                        d="M4.5 0c-1.4 0-2.5 1.1-2.5 2.5v5c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5v-5c0-1.4-1.1-2.5-2.5-2.5zm-4.125 6.188a.5.5 0 0 0-.375.5v.813c0 2.302 1.763 4.184 4 4.438v3.063h-2c-.6 0-1 .4-1 1h7c0-.6-.4-1-1-1h-2v-3.063c2.237-.254 4-2.136 4-4.438v-.813a.5.5 0 1 0-1 0v.813c0 1.927-1.573 3.5-3.5 3.5s-3.5-1.573-3.5-3.5v-.813a.5.5 0 0 0-.563-.5.5.5 0 0 0-.063 0z"
                        transform="translate(3)"
                    />
                </svg>
                <div id="contents"></div>
            </div>
            <div id="viz1" className="visual"></div>
            <div id="viz2" className="visual"></div>
            <div id="viz3" className="visual"></div>
            <div id="viz4" className="visual"></div>
            <div id="tip">Maintenez appuyez pour parler</div>
        </div>
    );
};

export const SpeechButton = (): JSX.Element => (
    <Restricted fallback={null} access="SpeechButton">
        <SpeechButtonElement />
    </Restricted>
);
