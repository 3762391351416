import * as React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useAPI } from "@/utils/hooks";

const UserScript = ({ code }: { code: string }) => {
    const { location } = window;
    const src = `${location.protocol}//${location.host}/node-sivin.js`;
    const html = `<script src="${src}" data-code="${code}" async defer></script>`;
    const copyToClipboard = () => navigator.clipboard.writeText(html);
    return (
        <>
            <pre>
                <code>{html}</code>
            </pre>
            <button
                type="button"
                className="btn btn-secondary"
                title="Copier"
                onClick={copyToClipboard}
            >
                <FontAwesomeIcon icon={faClipboard} />
            </button>
        </>
    );
};

const UserCodeButton = ({ code }: { code: string }) => {
    const [isOpen, toggle] = React.useReducer((s) => !s, false);
    return (
        <>
            <button
                type="button"
                className="btn btn-secondary ms-2 align-self-start"
                onClick={toggle}
                title={`Afficher le script pour le code ${code}`}
            >
                <FontAwesomeIcon icon={faCode} />
            </button>
            <Modal isOpen={isOpen} size="xl" toggle={toggle}>
                <ModalHeader toggle={toggle}>Code</ModalHeader>
                <ModalBody className="d-flex justify-content-between">
                    <UserScript code={code} />
                </ModalBody>
            </Modal>
        </>
    );
};

type UserCodesProps = {
    userId: number;
    companyId: number;
};

const UserCodesContent = ({ userId, companyId }: UserCodesProps) => {
    const { data: codes, error } = useAPI<string[]>(
        `users/codes/${companyId}/${userId}`
    );
    if (error) {
        return <ErrorMessage />;
    }
    if (!codes) {
        return <Loading />;
    }
    return (
        <>
            {codes.map((code) => (
                <UserCodeButton code={code} key={code} />
            ))}
        </>
    );
};

export const UserCodes = (props: UserCodesProps) => (
    <div className="d-flex py-2">
        <UserCodesContent {...props} />
    </div>
);
