import * as React from "react";
import {
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from "react-router-dom";

import type { UserAccess } from "shared/dist/types/auth";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { BlocWithAlbums } from "@/components/BlocWithAlbums";
import { DiagSearch } from "@/components/DiagSearch";
import { getPhotoPath } from "@/components/Photo360";
import { i18n } from "@/config/i18n";
import {
    DOCUMENT_TITLE,
    IMAGES_SERVER,
    WORKSHOP_PATH,
} from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import {
    emptyVehicleData,
    VehicleDataContext,
} from "@/context/VehicleDataContext";
import { getContent } from "@/utils/fetch";
import { useActiaSession, useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";
import { getLogoURL } from "@/utils/utils";

import { threesixty } from "./lib/threesixty";
import { Button360IAM } from "./Button360IAM";
import { Button360OEM } from "./Button360OEM";
import { CarIdentifierApps } from "./CarIdentifierApps";
import { CarIdentifierImmat } from "./CarIdentifierImmat";
import { useScripts } from "./hooks";
import { QRCodeButton } from "./QRCodeButton";
import VehicleDataToggler from "./VehicleDataToggler";

import "./style.css";
import "./style-item.css";
import "./style-slider.css";
import "./v2-style.css";

const { useContext, useEffect, useState, useRef } = React;

const getVehicleDataFromKtype = (ktype: string): Promise<VehicleData> =>
    Promise.all([
        getContent<string[]>(`vehicle/hmdnr/${ktype}`),
        getContent<{ marque: string; modele: string }>(`makeAndModel/${ktype}`),
    ]).then(([hmdnrs, { marque, modele }]) => {
        const hmd_nr =
            Array.isArray(hmdnrs) && hmdnrs.length > 0
                ? hmdnrs.join("-")
                : "Not found";
        const Modèle = (modele || "").replace(/\//g, "");
        return { hmd_nr, ktype, Marque: marque, Modèle };
    });

type PartsApp = {
    type: "app";
    title: string;
    img: string;
    path: string;
    access?: UserAccess;
};

type GenartApp = {
    type: "genart";
    imgIndex: string;
    id: number;
    access?: UserAccess;
};

type IframeApp = {
    type: "iframe";
    title: string;
    id: string;
    img: string;
    access?: UserAccess;
};

type HubIdlpApp = {
    type: "hubidlp";
    img: string;
    access?: UserAccess;
};

export type CarouselApp = PartsApp | GenartApp | IframeApp | HubIdlpApp;

type DTCResult = {
    code: string;
    label: string;
    genarts: string[];
};
type MD360 = DTCResult[] | { code: string };

const useDTCSearch = (mid: string | null | undefined) => {
    const history = useHistory();
    const match = useRouteMatch();
    const lang = useLanguage();
    const onDTCSearch = async (_label: string, dtc: string) => {
        if (!mid) {
            toastr.info("Pas de MID pour ce véhicule");
            return;
        }
        const dtcResults = await getContent<MD360>(
            `/md360dtcMID/${lang}/${mid}/${dtc}`
        );
        if ("code" in dtcResults) {
            toastr.warning("Quota de requêtes dépassé");
            return;
        }
        const genartIds = dtcResults[0].genarts;
        if (genartIds.length === 0) {
            toastr.info("Aucune donnée pour ce code");
            return;
        }
        const url = `${match.url}/search/dtc/${genartIds.join("-")}/${
            genartIds[0]
        }`;
        history.push(url);
    };
    return onDTCSearch;
};

type Props = {
    reduced: boolean;
    toggleReduced(): void;
};

export const CarIdentifier = ({
    reduced,
    toggleReduced,
}: Props): JSX.Element => {
    useScripts([
        "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js",
    ]);

    const { field, value } = useParams<{ field: string; value: string }>();

    const [vehicleData, setVehicleData] = useContext(VehicleDataContext);
    const brandLogo = getLogoURL(vehicleData.Marque);

    const { hasAccess } = useAuth();

    const [swiperIndex, setSwiperIndex] = useState(0);
    const [showQRCode, setShowQRCode] = useState(false);
    const toggleQRCode = () => setShowQRCode((show) => !show);
    const onDTCSearch = useDTCSearch(vehicleData.Mid_Autodata);

    useEffect(() => {
        document.title = `${DOCUMENT_TITLE} ${value}`;
    }, [value]);

    const searchParams = new URLSearchParams(window.location.search);
    const vtype = searchParams.get("vtype");

    useEffect(() => {
        const getData =
            field === "ktype"
                ? getVehicleDataFromKtype
                : (search: string): Promise<VehicleData> =>
                      getContent(`${field}/${search}`);

        getData(value)
            .then((data) => {
                setVehicleData(data);
                const isPL = vtype === "pl" || data.ntypnr != null;
                return getPhotoPath(data, isPL);
            })
            .then((photoPath) => {
                threesixty(`${IMAGES_SERVER}/360/standard/${photoPath}`);
            })
            .catch((error) => {
                console.error(error);
                setVehicleData(emptyVehicleData);
                const message = i18n.t("identification.toast.error", { value });
                toastr.error(message);
            });
    }, [setVehicleData, field, value, vtype]);

    useActiaSession(vehicleData);

    const match = useRouteMatch<{ ktype: string }>();
    const history = useHistory();
    const location = useLocation();
    const matchIframe = useRouteMatch<{ appId: string }>({
        path: `${match.url}/app/:appId`,
    });

    const firstSlideURL = useRef(match.url);

    const showParts =
        !match.isExact && swiperIndex === 0 && !matchIframe?.isExact;

    const handleSwipeChange = (index: number): void => {
        if (swiperIndex === 0) {
            firstSlideURL.current = location.pathname;
        }
        setSwiperIndex(index);
        const redirectTo =
            index === 0
                ? firstSlideURL.current
                : `${match.url}/app/${index - 1}`;
        history.push(redirectTo);
    };

    const redirectToVehicle = (vin: string) => {
        const redirectTo = `${WORKSHOP_PATH}/CarSelector/search/vin/${vin}`;
        history.push(redirectTo);
    };

    return (
        <>
            <div id="threesixty" className={showParts ? "hidden" : ""}>
                <div id="spinner">
                    <span>0%</span>
                </div>
                <ol id="threesixty_images"></ol>
            </div>

            {showParts && <BlocWithAlbums />}

            {!reduced && <VehicleDataToggler />}

            {reduced && (
                <div
                    className="blog-slider__img brand-toggle-active"
                    onClick={toggleReduced}
                >
                    <img src={brandLogo} alt="" />
                </div>
            )}
            <div className={`car-identifier ${reduced ? "hidden" : ""}`}>
                {swiperIndex === 0 && <Button360IAM />}
                {swiperIndex === 0 && <Button360OEM />}
                {hasAccess("QRCode") && <QRCodeButton onClick={toggleQRCode} />}
                <div className="search-bar d-flex">
                    <DiagSearch
                        onVinSearch={redirectToVehicle}
                        onDTCSearch={onDTCSearch}
                    />
                </div>
                <CarIdentifierImmat
                    toggleBottomDiv={toggleReduced}
                    onSwipeChange={handleSwipeChange}
                    showQRCode={showQRCode}
                />
            </div>
            <CarIdentifierApps show={!reduced && swiperIndex === 0} />
        </>
    );
};
