import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { GroupBase, OptionsOrGroups } from "react-select";
import { BreadcrumbItem } from "reactstrap";
import type { TFunction } from "i18next";

import type { OE } from "shared/dist/types/item";
import type { CompletionEntry, Genart } from "shared/dist/types/types";

import { IMAGES_SERVER } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import { useAPI, useFamilies, useGenarts, useLanguage } from "@/utils/hooks";
import { isDefined } from "@/utils/utils";

import { ArticleTable } from "./Item/ArticleTable";
import { customComponents, FilterOption, filterSelectedOEs } from "./Item/Item";
import { ErrorMessage } from "./ErrorMessage";
import { FilterSelect } from "./FilterSelect";
import { Loading } from "./Loading";
import { NoResults } from "./NoResults";
import { SlidesStack } from "./SlidesStack";
import { StateBreadcrumbs } from "./StateBreadcrumbs";

const openModal = () => () => {};

const CertificateOfUnavailability = React.lazy(() => import("./SivinCEPDF"));

const makeFilterOptions = (
    items: OE[] | null,
    allGenarts: Genart[],
    t: TFunction
): OptionsOrGroups<FilterOption, GroupBase<FilterOption>> => {
    const brands = new Map<string, string>();
    const genartIds = new Set<string>();
    for (const oe of items || []) {
        if (oe.articleId) {
            genartIds.add(oe.articleId);
        }
        brands.set(
            oe.makeId,
            oe.makeLabel === "REPARCAR" ? "SivinCE" : oe.makeLabel
        );
    }
    const genarts: FilterOption[] = [];
    for (const genartId of genartIds) {
        const genart = allGenarts.find((g) => g.value === Number(genartId));
        if (genart) {
            genarts.push({
                label: genart.label,
                value: genartId,
                familyId: genart.family,
                type: "genart",
            });
        }
    }
    genarts.sort((a, b) => a.label.localeCompare(b.label));
    const brandIds = [...brands.keys()];
    const brandOptions: FilterOption[] = brandIds.map((brandId) => ({
        label: brands.get(brandId) ?? "",
        value: brandId,
        type: "brand",
    }));

    const oenrs = Array.from(
        new Set((items || []).flatMap((item) => item.oeNrs))
    ).sort();
    const oemOptions: FilterOption[] = oenrs.map((oe) => ({
        label: oe,
        value: oe,
        type: "oem",
    }));
    return [
        {
            label: t("new-item-wizard.genart-label"),
            options: genarts,
        },
        {
            label: t("item.make"),
            options: brandOptions,
        },
        {
            label: t("item.manufacturer-reference"),
            options: oemOptions,
        },
    ];
};

type SivinCEBlocProps = {
    children: React.ReactNode;
};

const SivinCEBloc = ({ children }: SivinCEBlocProps): JSX.Element => (
    <div className="main-bloc mt-0 d-flex overflow-hidden flex-grow-1 align-self-stretch">
        <SivinCEBreadcrumbs />
        {children}
    </div>
);

type PartsTableProps = { data: OE[]; ktypnr?: string; vin?: string };
const PartsTable = ({ data, ktypnr, vin }: PartsTableProps) => {
    const [selectedFilters, setSelectedFilters] = useState<
        readonly FilterOption[]
    >([]);
    const { t } = useTranslation();
    const { genarts } = useGenarts();

    const options = React.useMemo(
        () => makeFilterOptions(data, genarts, t),
        [data, genarts, t]
    );

    const items = filterSelectedOEs(data, selectedFilters);
    const count = data.length;
    let label = t("partselector.resultsWithCount", { count });
    if (count > items.length) {
        label = `${items.length}/${label}`;
    }

    return (
        <div className="d-flex flex-column flex-grow-1">
            <h2>{label}</h2>
            {data.length > 2 && (
                <FilterSelect
                    placeholder={t("item.filter-placeholder")}
                    options={options}
                    value={selectedFilters}
                    onChange={setSelectedFilters}
                    components={customComponents}
                />
            )}
            {data.length > 0 ? (
                <ArticleTable
                    oes={items}
                    ktype={ktypnr}
                    vin={vin}
                    openModal={openModal}
                    displayLinkCEColumn
                    className="flex-grow-1 overflow-hidden"
                />
            ) : null}
        </div>
    );
};

const SubFamilyLabel = ({ id }: { id: string }) => {
    const lang = useLanguage(true);
    const { data } = useAPI<CompletionEntry[]>(`subFamilies/${lang}`, {
        initialData: [],
    });
    const label = data.find((subFamily) => subFamily.value === id)?.label;
    return <span>{label ?? "…"}</span>;
};

const SubFamilyAlbum = ({
    genarts,
    selectSubFamily,
}: {
    genarts: Genart[];
    selectSubFamily(subFamilyId: string): void;
}) => {
    const lang = useLanguage(true);
    const { data } = useAPI<CompletionEntry[]>(`subFamilies/${lang}`, {
        initialData: [],
    });

    const subFamilyIds = [
        ...new Set(genarts.map((genart) => genart.subFamily)),
    ];
    const subFamilies = data.filter((subFamily) =>
        subFamilyIds.includes(subFamily.value)
    );

    return (
        <div className="select-sub-family">
            <ul>
                {subFamilies.map((subFamily) => (
                    <li key={subFamily.value}>
                        <button
                            className="btn"
                            onClick={() => selectSubFamily(subFamily.value)}
                        >
                            {subFamily.label}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const AlbumFamily = ({
    family,
    label,
    isSelected,
    onClick,
    genarts,
    selectSubFamily,
}: {
    family: string;
    label: string;
    onClick(): void;
    isSelected: boolean;
    genarts: Genart[];
    selectSubFamily(subFamilyId: string): void;
}) => {
    const imgSrc = `${IMAGES_SERVER}/gif/${family}.png`;
    return (
        <li className={isSelected ? "selected" : ""}>
            <button
                className="btn border-0 family-album tooltip-arrow"
                onClick={onClick}
            >
                <img alt="" src={imgSrc} />
                <div>{label}</div>
            </button>
            <div className="details">
                {isSelected && (
                    <SubFamilyAlbum
                        genarts={genarts}
                        selectSubFamily={selectSubFamily}
                    />
                )}
                <img className="illustration" alt="" src={imgSrc} />
            </div>
        </li>
    );
};

const SivinCEBreadcrumbs = ({
    family,
    children,
}: {
    family?: string;
    children?: React.ReactNode;
}) => {
    const [vehicleData] = useVehicleData();
    const { Marque, Modèle } = vehicleData;
    return (
        <StateBreadcrumbs
            brand={Marque}
            model={Modèle}
            family={family}
            image={`${IMAGES_SERVER}/logos/sivince.png`}
            className="mt-n1"
        >
            {children}
        </StateBreadcrumbs>
    );
};

const Albums = ({ data }: { data: OE[] }) => {
    const { genartsMap } = useGenarts();
    const { familyMap } = useFamilies();
    const [selected, setSelected] = useState<string | null>(null);
    const [subFamily, setSubFamily] = useState<string | null>(null);

    const genarts = data.map((oe) => oe.articleId).filter(isDefined);
    const uniqueGenarts = [...new Set(genarts)]
        .map((genart) => genartsMap[Number(genart)])
        .filter(isDefined);
    const families = uniqueGenarts
        .map((genart) => genart.family)
        .filter(isDefined);
    const uniqueFamilies = [...new Set(families)].sort((a, b) =>
        familyMap[a]?.localeCompare(familyMap[b])
    );

    if (selected && subFamily) {
        const selectedGenarts = uniqueGenarts
            .filter(
                (genart) =>
                    genart.family === selected && genart.subFamily === subFamily
            )
            .map((genart) => genart.value);
        const items = data.filter((oe) =>
            selectedGenarts.includes(Number(oe.articleId))
        );
        return (
            <div className="main-bloc mt-0 d-flex overflow-hidden flex-grow-1 align-self-stretch">
                <SivinCEBreadcrumbs family={selected || undefined}>
                    <BreadcrumbItem>
                        <button
                            className="btn p-0"
                            onClick={() => {
                                setSelected(null);
                                setSubFamily(null);
                            }}
                        >
                            {familyMap[selected]}
                        </button>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <button
                            className="btn p-0"
                            onClick={() => setSubFamily(null)}
                        >
                            <SubFamilyLabel id={subFamily} />
                        </button>
                    </BreadcrumbItem>
                </SivinCEBreadcrumbs>
                <PartsTable data={items} />
                <SlidesStack className="oes-slider" />
            </div>
        );
    }

    return (
        <div className="main-bloc mt-0 d-flex overflow-hidden flex-grow-1 align-self-stretch">
            <SivinCEBreadcrumbs family={selected || undefined}>
                {selected && (
                    <BreadcrumbItem>
                        <button
                            className="btn p-0"
                            onClick={() => {
                                setSelected(null);
                                setSubFamily(null);
                            }}
                        >
                            {familyMap[selected]}
                        </button>
                    </BreadcrumbItem>
                )}
            </SivinCEBreadcrumbs>
            <ul className="albums families">
                {uniqueFamilies.map((family) => (
                    <AlbumFamily
                        key={family}
                        family={family}
                        label={familyMap[family]}
                        onClick={() =>
                            setSelected((oldFamily) =>
                                oldFamily === family ? null : family
                            )
                        }
                        isSelected={selected === family}
                        genarts={uniqueGenarts.filter(
                            (genart) => genart.family === family
                        )}
                        selectSubFamily={(id) => setSubFamily(id)}
                    />
                ))}
            </ul>
        </div>
    );
};

export const SivinCE = (): JSX.Element => {
    const [vehicleData] = useVehicleData();
    const { vinEC, vinCE, ktype } = vehicleData;

    let apiURL = null;
    if (vinEC) {
        apiURL = `parts/mdd-pre/${ktype || "null"}/${vinEC}/${vinCE}`;
    } else if (ktype) {
        apiURL = `parts/mdd-pre/${ktype}`;
    }
    const { data, error } = useAPI<OE[]>(apiURL);

    if (error) {
        return (
            <SivinCEBloc>
                <ErrorMessage className="align-self-start" />
            </SivinCEBloc>
        );
    }

    if (!apiURL) {
        return (
            <SivinCEBloc>
                <NoResults />
            </SivinCEBloc>
        );
    }

    if (!data) {
        return (
            <SivinCEBloc>
                <Loading className="align-self-start" />
            </SivinCEBloc>
        );
    }

    if (data.length === 0) {
        return (
            <SivinCEBloc>
                <div className="d-flex flex-column flex-grow-1">
                    <NoResults />
                    <React.Suspense fallback={null}>
                        <CertificateOfUnavailability
                            vehicleData={vehicleData}
                        />
                    </React.Suspense>
                </div>
            </SivinCEBloc>
        );
    }

    return <Albums data={data} />;
};
