import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactTable, { Column } from "react-table";
import { UncontrolledTooltip } from "reactstrap";
import { faLeaf, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import type { OEChannel } from "shared/dist/types/cart";
import type { OE } from "shared/dist/types/item";
import type { GenartAttribute } from "shared/dist/types/types";

import { ErrorMessage } from "@/components/ErrorMessage";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useAddToCart } from "@/context/CartItems";
import {
    getAvailability,
    IdlpData,
    IdlpDataWithProvenance,
    useAvailability,
} from "@/utils/availability";
import { getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";
import { PartBrand, usePartsBrands } from "@/utils/usePartsBrands";
import { getSupplierLogoURL } from "@/utils/utils";

import { Attributes } from "./Attributes";
import { AvailabilityContainer } from "./Availability";
import { HubIdlpButton } from "./HubIdlpButton";
import { SearchType, useAddItemSlide } from "./ItemSlides";
import { MddIcon } from "./MddIcon";
import { OEWithTooltip } from "./OEWithTooltip";
import { PartImg } from "./PartImg";
import { PDFList } from "./PDFList";

import PICTO_CAR from "@/img/pictoCar.png";

const { useState, useEffect } = React;

const PICTO_CAR_STYLE = { cursor: "pointer", marginLeft: 5 };

const IMAGE_EXTENSIONS = ["BMP", "JPG", "PNG", "GIF"];

export const getImgList = (oe: OE): string[] => {
    if (oe.label_brand && !oe.photos) {
        return [`${IMAGES_SERVER}/suppliers/${oe.label_brand}.png`];
    }
    if (oe.photos) {
        let path: string | null = null;
        if (oe.makeId === "REPARCAR") {
            path = "photos";
        }
        if (oe.makeId === "CARECO") {
            path = "metb/images";
        }
        if (oe.from === "caia") {
            path = "userDocs/photos";
        }
        return oe.photos
            .split(",")
            .map((photo) =>
                path ? `${IMAGES_SERVER}/${path}/${photo}` : photo
            );
    }
    const photos = (oe.mediaDtos || [])
        .filter((media) => IMAGE_EXTENSIONS.includes(media.extension))
        .map(
            (dto) =>
                `${IMAGES_SERVER}/tecdoc/${String(dto.dLNr).padStart(
                    4,
                    "0"
                )}_PIC/${dto.bildname}.${dto.extension}`
        );
    return photos.length > 0 ? photos : [`${IMAGES_SERVER}/gif/DEFAULT.png`];
};

export const getBrandName = (partsBrands: PartBrand[], oe: OE): string =>
    (
        partsBrands.find((brand) => String(brand.id) === String(oe.makeId)) || {
            name: oe.makeLabel,
        }
    ).name;

const IdlpPrice = (
    prix: Partial<IdlpData["prix"]> & {
        from: string;
        currency?: string;
        status?: string;
        className: string;
    }
): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    const currency = prix.currency || "€";
    const text = prix.status === "ko" ? "" : `${prix.prixBase}${currency}`;
    return (
        <div className={prix.className}>
            <div ref={ref}>{text}</div>
            <UncontrolledTooltip target={ref}>
                <div>{prix.from}</div>
                <div>remise 1: {prix.remise1}%</div>
                {prix.remise2 && <div>remise 2: {prix.remise2}%</div>}
                {prix.consigne && (
                    <div>
                        consigne: {prix.consigne}
                        {currency}
                    </div>
                )}
            </UncontrolledTooltip>
        </div>
    );
};

type ColumnComponent = (props: { original: OE }) => JSX.Element | null;

export const PriceList: ColumnComponent = ({ original: oe }) => {
    const { availability } = useAvailability(oe);
    const { userInfo } = useAuth();
    const groups = (userInfo?.stockGroups || []).filter(
        (group) => group.canUpdate === 1
    );
    const isLocal = oe.itemType === "clone" || oe.from === "caia";
    const className = classnames("oe-price", {
        "local-price": isLocal || groups.length === 0,
    });
    if (oe.makeId === "GPA26" || oe.makeId === "Indra") {
        return (
            <>
                <div className={className}>
                    <div>{oe.price}€</div>
                </div>
                {oe.makeId === "GPA26" && <div>{oe.price}€</div>}
            </>
        );
    }
    if (oe.from === "caia" || availability.length === 0) {
        return oe.price ? <span className={className}>{oe.price}€</span> : null;
    }
    const prices = availability.map((data, i) => (
        <IdlpPrice
            key={i}
            {...data.prix}
            from={data.from}
            currency={data.currency}
            status={data.status}
            className={className}
        />
    ));
    if (oe.itemType === "clone" || oe.makeId === "REPARCAR") {
        prices.unshift(
            <div key="localPrice" className={className}>
                <div>{oe.price}€</div>
            </div>
        );
    }
    return <>{prices}</>;
};

type CartButtonProps = {
    onClick(): Promise<void>;
    cartName: string;
    className?: string;
    disabled?: boolean;
};

const SPINNER_STYLE = { color: "black" };
export const CartButton = ({
    onClick,
    cartName,
    className = "cart-button",
    disabled = false,
}: CartButtonProps): React.ReactElement => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const handleClick = (): void => {
        if (loading) return;
        setLoading(true);
        onClick().finally(() => setLoading(false));
    };
    return (
        <button
            className={classnames(className, "btn")}
            onClick={handleClick}
            disabled={disabled}
        >
            {loading ? (
                <FontAwesomeIcon
                    className="spinner"
                    icon="spinner"
                    spin
                    style={SPINNER_STYLE}
                />
            ) : (
                <FontAwesomeIcon
                    icon="cart-plus"
                    role={disabled ? undefined : "button"}
                    title={`${t("item.add-to-cart")} ${cartName}`}
                />
            )}
        </button>
    );
};

const getProviders = (
    oe: OE,
    availability: IdlpDataWithProvenance[]
): { name: OEChannel; status: string }[] => {
    if (oe.from === "caia") {
        return [{ name: "Caia", status: "ok" }];
    }
    if (oe.makeId === "GPA26") {
        return (["GPA26", "Reparcar"] as const).map((name) => ({
            name,
            status: "ok",
        }));
    }
    const providers = availability.map((data) => ({
        name: data.from.replace(/\d+/g, "") as OEChannel,
        status: data.status,
    }));
    if (oe.makeId === "REPARCAR") {
        providers.unshift({ name: "Reparcar", status: "ok" });
    }
    if (oe.itemType === "clone") {
        providers.unshift({ name: "Caia", status: "ok" });
    }
    return providers;
};

export const CartButtons = ({
    original: oe,
    vin,
    ktype,
}: {
    original: OE;
    vin?: string | undefined;
    ktype?: string | undefined;
}) => {
    const addItemToCart = useAddToCart();
    const { availability } = useAvailability(oe);
    const providers = getProviders(oe, availability);
    const { userInfo } = useAuth();
    const groups = (userInfo?.stockGroups || []).filter(
        (group) => group.canUpdate === 1
    );
    const isLocal = oe.itemType === "clone" || oe.from === "caia";
    const className = classnames("cart-button", {
        "local-item": isLocal || groups.length === 0,
    });
    return (
        <>
            {providers.map((provider) => (
                <CartButton
                    key={provider.name}
                    cartName={provider.name}
                    className={className}
                    disabled={provider.status === "ko"}
                    onClick={(): Promise<void> =>
                        addItemToCart(oe, provider.name, vin, ktype)
                    }
                />
            ))}
        </>
    );
};

export const sortAvailability = (
    a: IdlpData[],
    b: IdlpData[],
    desc: boolean
): number => {
    if (!a && !b) {
        return 0;
    }
    if (!a) {
        return desc ? -1 : 1;
    }
    if (!b) {
        return desc ? 1 : -1;
    }
    const scoreA = a.reduce(
        (sum, v) => sum + Number(Number(v.disponibilite.quantite) > 0),
        0
    );
    const scoreB = b.reduce(
        (sum, v) => sum + Number(Number(v.disponibilite.quantite) > 0),
        0
    );
    return scoreB - scoreA;
};

const getPrice = (oe: OE, min: boolean): number => {
    const availability = getAvailability(oe);
    if (availability.length === 0) {
        return oe.price ?? 0;
    }
    const prices = availability
        .map((data) => Number(data.prix?.prixBase?.replace(",", ".") || 0))
        .filter(Boolean);
    if (oe.price) {
        prices.push(oe.price);
    }
    if (!prices.length) return 0;
    const price = min ? Math.min(...prices) : Math.max(...prices);
    return price;
};
const sortPrice = (a: OE, b: OE, desc: boolean) => {
    return getPrice(a, desc) - getPrice(b, desc);
};

export const ItemColumnMake: ColumnComponent = ({ original: oe }) => {
    const { hasAccess } = useAuth();
    const { partsBrands, partsBrandsNames } = usePartsBrands();
    let label = oe.makeLabel || partsBrandsNames[oe.makeId];
    if (label === "REPARCAR") label = "sivinCE";
    return (
        <>
            <a
                href={`/pieces/${getBrandName(partsBrands, oe)}`}
                rel="noopener noreferrer"
                className="oe-make-label text-nowrap"
                target="_blank"
                title={label}
            >
                {label}
            </a>
            <br />
            <a
                href={`/pieces/${getBrandName(partsBrands, oe)}`}
                target="_blank"
                rel="noopener noreferrer"
                title={label}
            >
                <img
                    src={getSupplierLogoURL(oe.makeId)}
                    alt={oe.makeId}
                    width="60px"
                />
            </a>
            {oe.vhu && hasAccess("vhu") && <div>{oe.vhu}</div>}
        </>
    );
};

export const ItemColumnPhoto: ColumnComponent = ({ original: oe }) => (
    <PartImg imgList={getImgList(oe)} title={oe.id} />
);

export const ItemDesc = ({
    item,
    ktypnr,
}: {
    item: OE;
    ktypnr: string | undefined | null;
}): JSX.Element => {
    const { t } = useTranslation();
    const { addFicheArticleSlide } = useAddItemSlide();
    return (
        <>
            <strong
                className="oe-name"
                onClick={() => addFicheArticleSlide(item, ktypnr)}
            >
                {item.id}
            </strong>
            <br />
            {item.ean && (
                <span className="item-ean" data-ean={item.ean}>
                    {t("item.EAN")}: {item.ean}
                </span>
            )}
            {item.label && <p>{item.label}</p>}
            <br />
            <Attributes attributes={item.attributes} />
        </>
    );
};

const ItemColumnType: ColumnComponent = ({ original: oe }) => (
    <span>{oe.partType}</span>
);

const ItemColumnLink: ColumnComponent = ({ original: oe }) => (
    <FontAwesomeIcon
        icon={faLink}
        className={
            oe.recommandé ? "compat-recommanded" : "compat-not-recommanded"
        }
        title={oe.recommandé ? "Recommandé" : undefined}
    />
);

type Props = {
    globalAttributes?: Record<string, Record<string, string>>;
    localAttributes?: Record<string, Record<string, string>>;
    openModal: (oe: OE) => () => void;
    id?: string;
    oes: OE[];
    criterias?: Record<string, string>;
    oeColumnName?: string;
    genart?: number;
    displayLinkCEColumn?: boolean;
    ktype: string | undefined;
    hmdnr?: string;
    vin: string | undefined;
    className?: string;
};

export const ArticleTable = (props: Props): JSX.Element => {
    const [hasError, setHasError] = useState(false);
    const [genartAttributes, setGenartAttributes] =
        useState<GenartAttribute[]>();
    const { t } = useTranslation();
    const lang = useLanguage();
    const { addIframeSlide, addPartsSearchSlide, addFicheArticleSlide } =
        useAddItemSlide();

    const { genart, id } = props;

    const onOEClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
        const { ref } = event.currentTarget.dataset;
        if (ref) {
            addPartsSearchSlide(ref);
        }
    };

    const makeOELink = (oe: OE, ref: string): string | React.ReactElement => {
        if (oe.provenance === "stock") {
            return ref;
        }
        const { remanufacturedOes = [] } = oe;
        const remanufacturedOe = remanufacturedOes.find(
            (item) => item.oeNr === ref
        );
        const button = (
            <OEWithTooltip
                key={ref}
                oe={ref}
                onClick={onOEClick}
                criteria={props.criterias?.[ref]}
            />
        );
        return remanufacturedOe ? (
            <React.Fragment key={ref}>
                {" "}
                {button}
                <FontAwesomeIcon
                    icon={faLeaf}
                    title={remanufacturedOe.remanufactured}
                    className="leaf-icon"
                />
            </React.Fragment>
        ) : (
            button
        );
    };

    const fetchCriterias = (): (() => void) => {
        let mounted = true;
        if (!genart) {
            return () => {
                mounted = false;
            };
        }
        getContent<GenartAttribute[]>(`genartAttributes/${lang}/${genart}`)
            .then((newGenartAttributes) => {
                if (mounted) {
                    setGenartAttributes(newGenartAttributes);
                }
            })
            .catch((error) => {
                console.error(error);
                if (mounted) {
                    setHasError(true);
                }
            });
        return (): void => {
            mounted = false;
        };
    };

    useEffect(fetchCriterias, [lang, genart]);

    if (hasError) {
        return <ErrorMessage />;
    }

    const onEANClick = (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ): void => {
        const { ean, from } = event.currentTarget.dataset;
        if (ean) {
            addPartsSearchSlide(ean, (from as SearchType) || "ean");
        }
    };

    const ItemColumnDesc: ColumnComponent = ({ original: oe }) => (
        <>
            <div className="item-header d-flex align-items-center flex-wrap">
                {oe.from === "mdd" && <MddIcon mdd={oe.makeId} />}
                <strong
                    className="oe-name"
                    onClick={(): void =>
                        addFicheArticleSlide(
                            { ...oe, criterias: props.criterias },
                            props.ktype,
                            props.hmdnr
                        )
                    }
                >
                    {oe.id}
                </strong>
                <img
                    src={PICTO_CAR}
                    alt=""
                    style={PICTO_CAR_STYLE}
                    onClick={props.openModal(oe)}
                />
                <PDFList medias={oe.mediaDtos} onClick={addIframeSlide} />
            </div>
            {oe.ean && (
                <>
                    <span
                        className="oe-ean"
                        onClick={onEANClick}
                        data-ean={oe.ean}
                        data-from={oe.from}
                    >
                        {t("item.EAN")}: {oe.ean}
                    </span>
                    <HubIdlpButton value={oe.ean} />
                </>
            )}
            {oe.label && <p>{oe.label}</p>}
            <br />
            <Attributes
                attributes={oe.attributes}
                localAttributes={
                    props.localAttributes?.[id + "/" + oe.id] || {}
                }
                globalAttributes={
                    props.globalAttributes?.[id + "/" + oe.id] || {}
                }
                genartAttributes={genartAttributes}
            />
        </>
    );

    const ItemColumnOE: ColumnComponent = ({ original: oe }) => (
        <div>
            {oe.oeNrs &&
                oe.oeNrs.length > 0 &&
                oe.oeNrs.sort().map((ref, i) => (
                    <React.Fragment key={i}>
                        {i > 0 && " > "}
                        {makeOELink(oe, ref)}
                    </React.Fragment>
                ))}
        </div>
    );

    const ItemColumnAvailability: ColumnComponent = ({ original: oe }) => (
        <AvailabilityContainer oe={oe} />
    );

    const ItemColumnCart: ColumnComponent = ({ original }) => (
        <CartButtons original={original} vin={props.vin} ktype={props.ktype} />
    );

    const columns: Column[] = [
        {
            Header: t("item.make"),
            width: 200,
            Cell: ItemColumnMake,
            accessor: "makeLabel",
            sortable: true,
        },
        {
            Header: t("item.photo"),
            width: 100,
            Cell: ItemColumnPhoto,
        },
        {
            Header: t("item.desc"),
            Cell: ItemColumnDesc,
            accessor: "id",
            sortable: true,
        },
        {
            Header: props.oeColumnName,
            Cell: ItemColumnOE,
            sortable: true,
            accessor: (oe: OE): string => oe.oeNrs && oe.oeNrs[0],
            id: "OEs",
        },
        {
            Header: t("item.availability"),
            width: 100,
            accessor: getAvailability,
            Cell: ItemColumnAvailability,
            id: "availability",
            sortable: true,
            sortMethod: sortAvailability,
        },
        {
            Header: t("item.price"),
            width: 90,
            Cell: PriceList,
            accessor: (oe: OE) => oe,
            sortable: true,
            sortMethod: sortPrice,
            id: "price",
        },
        {
            Header: t("item.cart"),
            Cell: ItemColumnCart,
            width: 100,
            sortable: false,
        },
    ];
    if (props.oeColumnName !== "OEs") {
        columns.splice(3, 0, {
            Header: "Type",
            Cell: ItemColumnType,
            accessor: "partType",
            sortable: true,
        });
    }
    if (props.displayLinkCEColumn) {
        columns.splice(5, 0, {
            Header: <FontAwesomeIcon icon={faLink} />,
            width: 50,
            Cell: ItemColumnLink,
            accessor: "recommandé",
            sortable: true,
        });
    }

    return (
        <ReactTable
            data={props.oes}
            columns={columns}
            className={classnames("ArticleTable", props.className)}
            defaultPageSize={50}
            minRows={2}
            sortable={false}
        />
    );
};

ArticleTable.defaultProps = {
    criterias: {},
    oeColumnName: "OEs",
};
