import type * as React from "react";
import { Button } from "reactstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth } from "@/context/AuthContext";
import { makeHubidlpURL } from "@/utils/utils";

import { useAddItemSlide } from "./ItemSlides";

type Props = {
    value: string;
    className?: string;
};

export const HubIdlpButton = ({
    value,
    className = "color-green",
}: Props): JSX.Element | null => {
    const { addIframeSlide } = useAddItemSlide();
    const { hasAccess, userInfo } = useAuth();
    const hubIdlpHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        addIframeSlide(makeHubidlpURL(userInfo.userId, value));
    };
    if (!value || !hasAccess("HubIdlp")) {
        return null;
    }
    return (
        <Button className={className} color="link" onClick={hubIdlpHandler}>
            <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
    );
};
