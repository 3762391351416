// original code: https://github.com/szepeshazi/print-elements/blob/master/print_elements.js
// need "@media print" css to work

const HIDE_FROM_PRINT_CLASS = "pe-no-print";
const PRESERVE_PRINT_CLASS = "pe-preserve-print";
const PRESERVE_ANCESTOR_CLASS = "pe-preserve-ancestor";
const BODY_ELEMENT_NAME = "BODY";

const hide = function (element: Element) {
    if (!element.classList.contains(PRESERVE_PRINT_CLASS)) {
        element.classList.add(HIDE_FROM_PRINT_CLASS);
    }
};

const preserve = function (element: Element, isStartingElement: boolean) {
    element.classList.remove(HIDE_FROM_PRINT_CLASS);
    element.classList.add(PRESERVE_PRINT_CLASS);
    if (!isStartingElement) {
        element.classList.add(PRESERVE_ANCESTOR_CLASS);
    }
};

const clean = function (element: Element) {
    element.classList.remove(HIDE_FROM_PRINT_CLASS);
    element.classList.remove(PRESERVE_PRINT_CLASS);
    element.classList.remove(PRESERVE_ANCESTOR_CLASS);
};

const walkSiblings = function (
    element: Element,
    callback: (node: Element) => void
) {
    let sibling = element.previousElementSibling;
    while (sibling) {
        callback(sibling);
        sibling = sibling.previousElementSibling;
    }
    sibling = element.nextElementSibling;
    while (sibling) {
        callback(sibling);
        sibling = sibling.nextElementSibling;
    }
};

const attachPrintClasses = function (
    element: Element,
    isStartingElement: boolean
) {
    preserve(element, isStartingElement);
    walkSiblings(element, hide);
};

const cleanup = function (element: Element) {
    clean(element);
    walkSiblings(element, clean);
};

const walkTree = function (
    element: Element,
    callback: (el: Element, b: boolean) => void
) {
    let currentElement: Element | null = element;
    callback(currentElement, true);
    currentElement = currentElement.parentElement;
    while (currentElement && currentElement.nodeName !== BODY_ELEMENT_NAME) {
        callback(currentElement, false);
        currentElement = currentElement.parentElement;
    }
};

export const printElements = function (elements: Element[]): void {
    for (let i = 0; i < elements.length; i++) {
        walkTree(elements[i], attachPrintClasses);
    }
    window.print();
    for (let i = 0; i < elements.length; i++) {
        walkTree(elements[i], cleanup);
    }
};
