import * as React from "react";

import type { PartialVehicleData } from "shared/dist/types/vehicleData";

export const emptyVehicleData: PartialVehicleData = {
    Marque: "",
    Modèle: "",
    Codif_Vin_PRF: "",
    Immat_SIV: "",
    Mid_Autodata: "",
    ktype: "",
};

export type VehicleDataContext = [
    PartialVehicleData,
    React.Dispatch<React.SetStateAction<PartialVehicleData>>
];
export const VehicleDataContext = React.createContext<VehicleDataContext>([
    emptyVehicleData,
    (): void => {},
]);

export const VehicleDataProvider = ({
    value = emptyVehicleData,
    children,
}: {
    value?: PartialVehicleData;
    children: React.ReactNode;
}): JSX.Element => {
    const vehicleData = React.useState(value);
    return (
        <VehicleDataContext.Provider value={vehicleData}>
            {children}
        </VehicleDataContext.Provider>
    );
};

export const useVehicleData = (): VehicleDataContext => {
    const context = React.useContext(VehicleDataContext);
    if (context === undefined) {
        throw new Error(
            "useVehicleData must be used within a VehicleDataProvider"
        );
    }
    return context;
};
