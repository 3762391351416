import * as React from "react";
import { useForm } from "react-hook-form";

import type { CustomerInfo } from "shared/dist/types/customer";

import { i18n } from "@/config/i18n";
import { useCustomer } from "@/context/CustomerContext";
import { postPublic } from "@/utils/postPublic";
import { toastr } from "@/utils/toastr";
import { useSearchParams } from "@/utils/useSearchParams";

import "./CustomerDataForm.css";

export const CustomerDataForm = () => {
    const params = useSearchParams();
    const { register, handleSubmit, reset } = useForm<CustomerInfo>();
    const [customer] = useCustomer();
    const info = customer?.info;
    React.useEffect(() => {
        if (info) {
            reset(info);
        }
    }, [info, reset]);

    const submit = async (data: CustomerInfo) => {
        const res = await postPublic("vc/info", {
            ...data,
            clientId: params.get("id"),
        });
        if (res.ok) {
            toastr.success(i18n.t("vehicle-data.toast.update-data-success"));
        } else {
            toastr.error(i18n.t("vehicle-data.toast.update-data-error"));
        }
    };

    return (
        <div className="c-d-f p-4 d-flex justify-content-center mt-2">
            <form
                onSubmit={handleSubmit(submit)}
                className="container flex-grow-1"
            >
                <div className="row form-group">
                    <label className="col-sm-2 col-form-label" htmlFor="title">
                        Titre
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="title"
                            type="text"
                            className="form-control col-sm-10"
                            required
                            {...register("title")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label
                        className="col-sm-2 col-form-label"
                        htmlFor="firstname"
                    >
                        Prénom
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="firstname"
                            type="text"
                            className="form-control"
                            required
                            {...register("firstname")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label className="col-sm-2 col-form-label" htmlFor="name">
                        Nom de famille
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="name"
                            type="text"
                            className="form-control"
                            required
                            {...register("name")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label className="col-sm-2 col-form-label" htmlFor="phone">
                        Téléphone
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="phone"
                            type="tel"
                            className="form-control"
                            required
                            {...register("phone")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label className="col-sm-2 col-form-label" htmlFor="email">
                        Adresse email
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="email"
                            type="email"
                            className="form-control"
                            required
                            {...register("email")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label
                        className="col-sm-2 col-form-label"
                        htmlFor="company"
                    >
                        Société
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="company"
                            type="text"
                            className="form-control"
                            required
                            {...register("company")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label
                        className="col-sm-2 col-form-label"
                        htmlFor="address"
                    >
                        Adresse
                    </label>
                    <div className="col-sm-10">
                        <textarea
                            id="address"
                            className="form-control"
                            {...register("address")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label
                        className="col-sm-2 col-form-label"
                        htmlFor="postalCode"
                    >
                        Code postal
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="postalCode"
                            type="text"
                            className="form-control"
                            required
                            {...register("postalCode")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label className="col-sm-2 col-form-label" htmlFor="city">
                        Ville
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="city"
                            type="text"
                            className="form-control"
                            required
                            {...register("city")}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <label
                        className="col-sm-2 col-form-label"
                        htmlFor="country"
                    >
                        Pays
                    </label>
                    <div className="col-sm-10">
                        <input
                            id="country"
                            type="text"
                            className="form-control"
                            required
                            {...register("country")}
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary mr-2">
                    Valider
                </button>
            </form>
        </div>
    );
};
