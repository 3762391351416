import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactTable from "react-table";
import { Button, UncontrolledTooltip } from "reactstrap";
import {
    faCloudShowersHeavy,
    faGasPump,
    faSnowflake,
    faSun,
    faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Tire } from "shared/dist/types/parts";

import { IMAGES_SERVER } from "@/config/settings";
import { useAddTireToCart } from "@/context/CartItems";
import { getKeys } from "@/utils/utils";

import { PartImg } from "./Item/PartImg";
import { FilterSelect } from "./FilterSelect";

import "react-table/react-table.css";
import "./TireStock.css";

import PICTO_CAR from "@/img/pictoCar.png";

const formatToNetValue = (ttc: number): string => (ttc / 1.2).toFixed(2) + "€";
const getPhoto = (photoString: string): string | undefined =>
    photoString && photoString.split("|")[0].split("/").pop();

export const PhotoCell = ({
    original: tire,
}: {
    original: Tire;
}): JSX.Element => (
    <PartImg
        imgList={[`${IMAGES_SERVER}/pneus/photos/${getPhoto(tire.photo)}`]}
        title={tire.designation}
    />
);

export const TireAvailabilityCell = (): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    return (
        <div className="oe-availability">
            <div className={"availability available"} ref={ref} />
            <UncontrolledTooltip target={ref}>
                <div>Oxylio H+2</div>
                <div>CarterCash H+4</div>
            </UncontrolledTooltip>
        </div>
    );
};

const AddToCartCell = ({ original: tire }: { original: Tire }): JSX.Element => {
    const addTire = useAddTireToCart();
    // TODO: get vehicle context
    return (
        <FontAwesomeIcon
            icon="cart-plus"
            style={{ cursor: "pointer" }}
            onClick={(): Promise<void> => addTire(tire, null, null)}
        />
    );
};

const getTireRef = (tire: Tire): string =>
    `${tire.largeur}/${tire.serie} ${tire.diametre} ${tire.indice_charge}${tire.indice_vitesse}`;

const getTireSize = (tire: Tire): string =>
    `${tire.largeur}.${tire.serie}.R.${tire.diametre}.${tire.indice_charge}.${tire.indice_vitesse}`;

const RefCell = ({ original: tire }: { original: Tire }) => (
    <Button color="link">{getTireRef(tire)}</Button>
);

export const MakeCell = (row: { value: string }): React.ReactElement => (
    <>
        <div>{row.value}</div>
        <img
            src={`${IMAGES_SERVER}/pneus/${row.value.toLowerCase()}.png`}
            alt={row.value}
            width={80}
        />
    </>
);

type MinimalTire = {
    carburant: string;
    adherence: string;
    decibel: number;
    saison: string;
};

const TireSymbols = ({ tire }: { tire: MinimalTire }): JSX.Element => (
    <div className="tire-symbols">
        {tire.carburant && (
            <span className={`tire-index-${tire.carburant}`}>
                <FontAwesomeIcon icon={faGasPump} />
                <span>{tire.carburant}</span>
            </span>
        )}
        {tire.adherence && (
            <span className={`tire-index-${tire.adherence}`}>
                <FontAwesomeIcon icon={faCloudShowersHeavy} />
                {tire.adherence}
            </span>
        )}
        {tire.decibel > 0 && (
            <span className="tire-decibel">
                <FontAwesomeIcon icon={faVolumeUp} />
                {tire.decibel}
            </span>
        )}
        <span>
            {tire.saison === "ETE" && (
                <FontAwesomeIcon icon={faSun} style={{ color: "#FBCB00" }} />
            )}
            {tire.saison === "HIVER" && <FontAwesomeIcon icon={faSnowflake} />}
            {tire.saison}
        </span>
    </div>
);

export const DescriptionCell = ({
    original: tire,
    hideCarPicto = false,
}: {
    original: Tire;
    hideCarPicto?: boolean;
}): JSX.Element => (
    <>
        <div>
            <strong className="tire-label">{tire.designation}</strong>
            {!hideCarPicto && (
                <img src={PICTO_CAR} alt="Applicabilité véhicule" />
            )}
        </div>
        <div className="tire-ean">EAN: {tire.ean}</div>
        <TireSymbols tire={tire} />
        <ul>
            <li>Taille: {getTireSize(tire)}</li>
            <li>RunFlat: {tire["run on flat"] === "True" ? "Oui" : "Non"}</li>
            <li>Catégorie: {tire.categorie}</li>
            <li>Profil: {tire.profil}</li>
            {tire.ind_bruit && <li>Indice bruit: {tire.ind_bruit}</li>}
        </ul>
    </>
);

const columns = [
    { Header: "Marque", accessor: "marque", Cell: MakeCell, width: 200 },
    {
        Header: "Photo",
        accessor: "photo",
        Cell: PhotoCell,
        width: 100,
    },
    {
        Header: "Description",
        accessor: "designation",
        Cell: DescriptionCell,
        width: 300,
    },
    { Header: "Type", accessor: "catégorie de marque" },
    {
        Header: "Ref équivalente",
        Cell: RefCell,
        accessor: getTireRef,
        id: "ref",
    },
    {
        Header: "Disponibilite",
        Cell: TireAvailabilityCell,
        width: 150,
        sortable: false,
    },
    {
        Header: "Prix",
        accessor: "prix vente moyen TTC",
        Cell: (row: { value: number }): string => formatToNetValue(row.value),
        width: 100,
    },
    { Header: "Panier", Cell: AddToCartCell, width: 100, sortable: false },
];

type FilterOption = {
    label: string;
    value: string;
    type:
        | "categories"
        | "makes"
        | "seasons"
        | "sizes"
        | "vehicleTypes"
        | "runflat";
};

const makeFilterOptions = (tires: Tire[]): FilterOption[] => {
    const filterTags = tires.reduce(
        (acc, tire) => {
            acc.categories.add(tire["catégorie de marque"]);
            acc.makes.add(tire.marque);
            acc.seasons.add(tire.saison);
            acc.sizes.add(getTireSize(tire));
            acc.vehicleTypes.add(tire.categorie);
            return acc;
        },
        {
            categories: new Set<string>(),
            makes: new Set<string>(),
            seasons: new Set<string>(),
            sizes: new Set<string>(),
            vehicleTypes: new Set<string>(),
        }
    );

    const tagsList: FilterOption[][] = getKeys(filterTags).map((key) => {
        return [...filterTags[key]].map((label) => ({
            label,
            value: label,
            type: key,
        }));
    });
    tagsList.push([
        {
            label: "RunFlat",
            value: "True",
            type: "runflat",
        },
    ]);

    return tagsList.flat();
};

const filterTires = (
    tires: Tire[],
    filterOptions: ReadonlyArray<FilterOption>
): Tire[] => {
    const categoryFilters = filterOptions.filter(
        (filter) => filter.type === "categories"
    );
    const makeFilters = filterOptions.filter(
        (filter) => filter.type === "makes"
    );
    const seasonFilters = filterOptions.filter(
        (filter) => filter.type === "seasons"
    );
    const sizeFilters = filterOptions.filter(
        (filter) => filter.type === "sizes"
    );
    const vehicleTypesFilters = filterOptions.filter(
        (filter) => filter.type === "vehicleTypes"
    );
    const runflatFilters = filterOptions.filter(
        (filter) => filter.type === "runflat"
    );
    return tires.filter((tire) => {
        const size = getTireSize(tire);
        return (
            (categoryFilters.length === 0 ||
                categoryFilters.some(
                    (filter) => tire["catégorie de marque"] === filter.value
                )) &&
            (makeFilters.length === 0 ||
                makeFilters.some((filter) => tire.marque === filter.value)) &&
            (seasonFilters.length === 0 ||
                seasonFilters.some((filter) => tire.saison === filter.value)) &&
            (sizeFilters.length === 0 ||
                sizeFilters.some((filter) => size === filter.value)) &&
            (vehicleTypesFilters.length === 0 ||
                vehicleTypesFilters.some(
                    (filter) => tire.categorie === filter.value
                )) &&
            (runflatFilters.length === 0 ||
                runflatFilters.some(
                    (filter) => tire["run on flat"] === filter.value
                ))
        );
    });
};

type Props = {
    tires: Tire[];
};

export const TireStock = ({ tires }: Props): JSX.Element => {
    const [filter, setFilter] = React.useState<ReadonlyArray<FilterOption>>([]);
    const [filteredTires, setFilteredTires] = React.useState(tires);
    const { t } = useTranslation();

    const filterHandler = (newFilter: ReadonlyArray<FilterOption>): void => {
        let matchingTires = tires;
        if (newFilter?.length > 0) {
            matchingTires = filterTires(tires, newFilter);
        }
        setFilter(newFilter);
        setFilteredTires(matchingTires);
    };

    const options = React.useMemo(() => makeFilterOptions(tires), [tires]);

    const count = filteredTires.length;
    const totalCount = tires.length;

    let label = t("partselector.resultsWithCount", { count: totalCount });
    if (count < totalCount) {
        label = `${count}/${label}`;
    }

    return (
        <>
            <h2>{label}</h2>
            <FilterSelect
                placeholder="Filtrer…"
                options={options}
                value={filter}
                onChange={filterHandler}
            />
            <ReactTable
                columns={columns}
                defaultPageSize={50}
                data={filteredTires}
                className="tires-table"
            />
        </>
    );
};
