import type * as React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import classnames from "classnames";

import { Lazy } from "@/components/Lazy";
import { IMAGES_SERVER } from "@/config/settings";

const DEFAULT_IMG_URL = `${IMAGES_SERVER}/gif/DEFAULT.png`;
const setDefaultImage = (
    event: React.SyntheticEvent<HTMLImageElement>
): void => {
    event.currentTarget.src = DEFAULT_IMG_URL;
};

type FamilyAlbumProps = {
    name: React.ReactNode;
    isSelected: boolean;
    path: string;
    children: React.ReactNode;
    detailsClassName?: string;
    imgURL: string;
    bigImgUrl?: string;
};

export const FamilyAlbum = ({
    name,
    isSelected,
    path,
    children,
    detailsClassName,
    imgURL,
    bigImgUrl = imgURL,
}: FamilyAlbumProps): JSX.Element => {
    const match = useRouteMatch();
    return (
        <li className={isSelected ? "selected" : ""}>
            <Link
                className="family-album tooltip-arrow"
                to={isSelected ? match.url : `${match.url}${path}`}
            >
                <img src={imgURL} alt="" onError={setDefaultImage} />
                <div>{name}</div>
            </Link>
            {/* The .details div has to be present in the DOM for the CSS transition to work. */}
            <div className={classnames("details", detailsClassName)}>
                <Lazy visible={isSelected}>{children}</Lazy>
                <img className="illustration" src={bigImgUrl} alt="" />
            </div>
        </li>
    );
};
