import * as React from "react";
import Select, { createFilter, Props as NamedProps } from "react-select";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props<Option> = Pick<
    NamedProps<Option, true>,
    "placeholder" | "options" | "value" | "onChange" | "components"
>;

const MARGIN_ICON = { marginRight: 5, marginTop: 8 };

const fastFilter = createFilter({ ignoreAccents: false });

export const FilterSelect = <Option,>(props: Props<Option>): JSX.Element => {
    return (
        <div className="d-flex oes-breadcrumbs">
            <FontAwesomeIcon icon={faFilter} style={MARGIN_ICON} />
            <Select
                placeholder={props.placeholder}
                options={props.options}
                value={props.value}
                onChange={props.onChange}
                isMulti
                className="flex-grow-1"
                components={props.components}
                filterOption={fastFilter}
            />
        </div>
    );
};
