import * as React from "react";
import { Button } from "reactstrap";

import { useSlides } from "@/components/SlidesStack";
import { CartContext } from "@/context/CartItems";
import { TechautoOrder } from "@/pages/ContactSelector/TechautoOrder";
import { postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

type CheckoutData = {
    error?: string;
    msg: string;
    data?: {
        price: number;
        orderId: string;
    };
};

export const CheckoutButton = (): JSX.Element => {
    const [loading, setLoading] = React.useState(false);
    const { cart, updateCart } = React.useContext(CartContext);
    const { addSlide } = useSlides();
    const disabled =
        loading || cart.every((item) => item.channel !== "Techauto");

    const clearCart = () => {
        const newCart = cart.filter((item) => item.channel !== "Techauto");
        updateCart(newCart);
    };

    const submitCart = async () => {
        setLoading(true);
        let response: Response | null = null;
        try {
            response = await postContent("sendTechautoCart", {});
        } catch (error) {
            toastr.error("Impossible de passer la commande");
            setLoading(false);
            return;
        }
        setLoading(false);
        const data: CheckoutData = await response.json();
        if (data.error) {
            toastr.error(data.error);
            return;
        }
        const orderId = data.data?.orderId;
        if (orderId) {
            addSlide({
                id: `techauto-order-${orderId}`,
                content: <TechautoOrder orderId={orderId} />,
            });
            toastr.success("Commande passée avec succès");
            clearCart();
        }
    };

    return (
        <Button disabled={disabled} onClick={submitCart}>
            Checkout
        </Button>
    );
};
