import * as React from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";

import { useAPI } from "@/utils/hooks";
import { usePartsURL } from "@/utils/usePartsURL";

import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";

import "./SelectPart.css";

type CategoryParts = {
    id: string;
    label: string;
    articles: { id: string; label: string }[];
};

type Props = {
    isOE?: boolean;
    brand: string | undefined;
    family: string;
    ktype: string | undefined;
    subFamily: string;
    ntypnr?: number;
};

export const SelectPart = (props: Props): JSX.Element => {
    const match = useRouteMatch();
    const { brand, family, subFamily, ktype, ntypnr, isOE = false } = props;
    const typnr = ktype || ntypnr;

    const params = useParams<{ vtype: string; 0: string }>();
    let { vtype } = params;
    if (!vtype) {
        vtype = ntypnr ? "pl" : "vl";
    }
    const isStock = params[0] === "pieces-stock";

    const partsURL = usePartsURL({ brand, typnr, vtype, isOE, isStock });
    const url = partsURL && `${partsURL}/${family}/${subFamily}`;

    const { data: parts, error } = useAPI<CategoryParts[]>(url);

    if (error) {
        return <ErrorMessage />;
    }

    if (!parts) {
        return <Loading messageKey="carselector.loading-parts" />;
    }

    return (
        <div className="select-parts">
            <ul className="part-list">
                {parts.map((part) => (
                    <li key={part.id}>
                        {part.articles ? (
                            <>
                                <span>{part.label}</span>
                                <ul>
                                    {part.articles.map((article) => (
                                        <li key={article.id}>
                                            <Link
                                                to={`${match.url}/${part.id}/${article.id}`}
                                            >
                                                <span>{article.label}</span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <Link to={`${match.url}/${part.id}`}>
                                <span>{part.label}</span>
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};
