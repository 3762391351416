import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import SwiperCore, { Mousewheel, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { QRCode } from "@/components/QRCode";
import { WORKSHOP_PATH } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import {
    emptyVehicleData,
    VehicleDataContext,
} from "@/context/VehicleDataContext";
import { getLogoURL } from "@/utils/utils";

import { setImageIndex } from "./lib/threesixty";
import { OEMBloc } from "./OEMBloc";
import { iframeSlidesCaridentifier } from "./settings";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

SwiperCore.use([Mousewheel, Pagination]);

interface Props {
    toggleBottomDiv: () => void;
    onSwipeChange: (index: number) => void;
    showQRCode: boolean;
}

export const CarIdentifierImmat = ({
    toggleBottomDiv,
    onSwipeChange,
    showQRCode,
}: Props): JSX.Element => {
    const [vehicleData, setVehicleData] = React.useContext(VehicleDataContext);
    const { t } = useTranslation();
    const { hasAccess } = useAuth();

    const handleSlideChange = (swiper: SwiperCore) => {
        onSwipeChange(swiper.realIndex);
    };

    const brandLogo = getLogoURL(vehicleData.Marque);

    const handleChangeVehicleClick = (): void => {
        setVehicleData(emptyVehicleData);
    };

    const { field, value } = useParams<{ field: string; value: string }>();
    const immat = vehicleData.Immat_SIV || (field === "immat" && value) || "";
    const vin = vehicleData.Codif_Vin_PRF || (field === "vin" && value) || "";

    return (
        <div className="menu-bloc d-flex">
            <div className="menu-bloc-image" onClick={toggleBottomDiv}>
                <img src={brandLogo} alt={vehicleData.Marque} />
            </div>
            {showQRCode ? (
                <QRCode size={220} />
            ) : (
                <Swiper
                    spaceBetween={30}
                    effect="slide"
                    mousewheel
                    direction="vertical"
                    pagination={{ clickable: true }}
                    height={220}
                    onSlideChange={handleSlideChange}
                >
                    <SwiperSlide>
                        <div
                            className="bloc-slide-title"
                            onClick={(): void => setImageIndex(0)}
                        >
                            {t("caridentifier.identified-vehicle")}
                        </div>
                        <div className="immatriculation">
                            <div className="immat-wrapper">
                                <input
                                    type="text"
                                    id="immat"
                                    name="immat"
                                    maxLength={11}
                                    value={immat}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="blog-slider__text">
                            {t("vehicle-data.vin")}: {vin}
                        </div>
                        <button className="blog-slider__button">
                            <Link
                                to={`${WORKSHOP_PATH}/CarSelector`}
                                onClick={handleChangeVehicleClick}
                            >
                                {t("identification.switch-vehicle")}
                            </Link>
                        </button>
                    </SwiperSlide>
                    {hasAccess("OEM") && (
                        <SwiperSlide>
                            <OEMBloc />
                        </SwiperSlide>
                    )}
                    {iframeSlidesCaridentifier.map((iframeSlide) => (
                        <SwiperSlide key={iframeSlide.title}>
                            <div className="bloc-slide-title">
                                {iframeSlide.title}
                            </div>
                            <img
                                className="bloc-slide-img"
                                src={iframeSlide.img}
                                alt=""
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    );
};
