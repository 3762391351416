import * as React from "react";
import {
    Link,
    Route,
    useLocation,
    useParams,
    useRouteMatch,
} from "react-router-dom";

import { useAPI } from "@/utils/hooks";
import { usePartsURL } from "@/utils/usePartsURL";

import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";
import { SelectPart } from "./SelectPart";

import "./SelectPartFamily.css";

type Props = {
    isOE: boolean | undefined;
    ktype: string | undefined;
    brand: string | undefined;
    ntypnr: number | undefined;
};

type Family = {
    id: string;
    label: string;
};

const SubFamily = ({ families, ...props }: Props & { families: Family[] }) => {
    const { family, subFamily } = useParams<{
        family: string;
        subFamily: string;
    }>();
    const currentFamily = families.find((fam) => fam.id === subFamily);
    const label = currentFamily ? currentFamily.label : "Not found";
    return (
        <div className="select-part">
            <h2>{label}</h2>
            <SelectPart {...props} family={family} subFamily={subFamily} />
        </div>
    );
};

export const SelectAlbumFamily = (props: Props): JSX.Element => {
    const location = useLocation();
    const { isOE = false, ktype, brand, ntypnr } = props;
    const typnr = ktype || ntypnr;
    const routeMatch = useRouteMatch();
    const params = useParams<{ family: string; vtype: string; 0: string }>();
    let { vtype } = params;
    if (!vtype) {
        vtype = ntypnr ? "pl" : "vl";
    }
    const isStock = params[0] === "pieces-stock";

    const partsURL = usePartsURL({ brand, typnr, vtype, isOE, isStock });
    const url = partsURL && `${partsURL}/${params.family}`;

    const { data: families, error } = useAPI<Family[]>(url);

    if (error) {
        return <ErrorMessage />;
    }

    if (!families) {
        return (
            <Loading
                messageKey="partselector.loading-part-families"
                className="select-sub-family"
            />
        );
    }

    const tokens = location.pathname.split("/");
    const subFamily = tokens.pop();
    return (
        <>
            <div className="select-sub-family">
                <ul>
                    {families.map((fam) => (
                        <li
                            key={fam.id}
                            className={
                                fam.id === subFamily
                                    ? "selected-sub-family"
                                    : ""
                            }
                        >
                            <Link to={`${routeMatch.url}/${fam.id}`}>
                                <span>{fam.label}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <Route path={`${routeMatch.path}/:subFamily`} exact>
                <SubFamily families={families} {...props} />
            </Route>
        </>
    );
};
