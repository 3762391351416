import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";

import { apiRootURL, IMAGES_SERVER } from "@/config/settings";

import { FamilyAlbum } from "./FamilyAlbum";

const MARGIN_RIGHT_250 = { marginRight: 250 };

export const PartsForReuseAlbum = (): JSX.Element => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const match = useRouteMatch();
    const history = useHistory();
    const { register, handleSubmit } = useForm();

    const submitRecycling = (values: Record<string, boolean>): void => {
        const ids = Object.keys(values)
            .filter((key) => values[key])
            .map((key) => ({ physical: 1, virtual: 0 }[key]));
        if (ids.length === 0) {
            return;
        }
        const url = `${match.url}/${ids.join("-")}/recycling`;
        history.push(url);
    };

    return (
        <FamilyAlbum
            name={t("albums.recycling")}
            isSelected={pathname.endsWith("recycling")}
            path="/recycling"
            imgURL={`${IMAGES_SERVER}/sivince/pimEC.png`}
            bigImgUrl={`${apiRootURL}/media/img/recycling.jpg`}
            detailsClassName="recycling"
        >
            <form
                className="d-flex flex-grow-1 justify-content-between"
                onSubmit={handleSubmit(submitRecycling)}
                style={MARGIN_RIGHT_250}
            >
                <ul className="details-list">
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked
                                {...register("physical")}
                            />{" "}
                            <span>{t("albums.physical-stock")}</span>
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked
                                {...register("virtual")}
                            />{" "}
                            <span>{t("albums.virtual-stock")}</span>
                        </label>
                    </li>
                </ul>
                <div className="d-flex align-items-end">
                    <Button color="primary">{t("common.submit")}</Button>
                </div>
            </form>
        </FamilyAlbum>
    );
};
