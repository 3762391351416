import * as React from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { faAccusoft } from "@fortawesome/free-brands-svg-icons";
import { faUser, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QRCodeSVG } from "qrcode.react";
import { useRecoilState } from "recoil";

import type { VehicleData } from "shared/dist/types/vehicleData";

import { apiRootURL, IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useVehicleData } from "@/context/VehicleDataContext";
import { rtpeModalOpen } from "@/globalState/atoms";
import { usePublicAPI } from "@/utils/hooks";

import { Iphone } from "./Iphone";
import { RTPEPanel } from "./RTPEPanel";
import { getVehicleDataLabels } from "./VehicleDataBloc";

import "./IdCar.css";

const PKPASS_SERVER = "https://pass.ia.parts";
const IMAGE_SETTINGS = {
    height: 65,
    width: 65,
    excavate: true,
    src: `${apiRootURL}/media/logos/C@IA.png`,
};

type IdCarProps = {
    vin: string;
};

const IdCarCard = ({ vin }: IdCarProps): JSX.Element => {
    const qrcodeURL = `${window.location.origin}/idcar/${vin}`;

    return (
        <div className="idcar-cb d-flex">
            <QRCodeSVG
                value={qrcodeURL}
                size={210}
                imageSettings={IMAGE_SETTINGS}
                level="Q"
            />
            <img
                className="idcar-cb-sivince"
                src={`${IMAGES_SERVER}/logos/sivincecard.png`}
                alt="sivince"
            />
        </div>
    );
};

const PkPassCard = ({ vin }: IdCarProps): JSX.Element => {
    const url = `${PKPASS_SERVER}/v/${vin}`;
    return (
        <div className="pkpass-card d-flex justify-content-center">
            <Iphone className="d-flex">
                <div className="pkpass-images d-flex justify-content-around mb-4">
                    <img src={`${IMAGES_SERVER}/idcar/ios-wallet.png`} />
                    <img src={`${IMAGES_SERVER}/idcar/android-wallet.png`} />
                </div>
                <QRCodeSVG value={url} size={150} />
            </Iphone>
        </div>
    );
};

const IdCarContent = ({ vin }: IdCarProps): JSX.Element => {
    const { data } = usePublicAPI<VehicleData>(`vin/${vin}`);
    const labels = data ? getVehicleDataLabels(data) : null;
    const [showPkPass, setShowPkPass] = React.useState(false);
    const [showCustomer, setShowCustomer] = useRecoilState(rtpeModalOpen);
    const toggleModal = () => setShowPkPass((s) => !s);
    const toggleCustomerModal = () => setShowCustomer((s) => !s);

    return (
        <div className="idcar-content">
            <h3>vinCE | IDC@R</h3>
            <p>
                <strong>{vin}</strong>
            </p>
            {labels && data && (
                <p>
                    {data.Marque} {data.Modèle}{" "}
                    {labels["date-mec"].split("#")[0]} {labels.motorisation}
                </p>
            )}
            <div className="d-flex align-items-end">
                <IdCarCard vin={vin} />
                <div className="d-flex flex-column ms-auto">
                    <Button type="button" onClick={toggleCustomerModal}>
                        <FontAwesomeIcon icon={faUser} size="2x" />
                    </Button>
                    <Button
                        type="button"
                        className="mt-2"
                        onClick={toggleModal}
                    >
                        <FontAwesomeIcon icon={faWallet} size="2x" />
                    </Button>
                </div>
            </div>
            <Modal isOpen={showPkPass} toggle={toggleModal}>
                <ModalHeader>IDC@R</ModalHeader>
                <ModalBody>
                    <PkPassCard vin={vin} />
                </ModalBody>
            </Modal>
            <Modal isOpen={showCustomer} toggle={toggleCustomerModal} size="lg">
                <ModalHeader>R-TPE</ModalHeader>
                <ModalBody className="tall-modal-body overflow-auto">
                    <RTPEPanel />
                </ModalBody>
            </Modal>
        </div>
    );
};

export const IdCar = (): JSX.Element | null => {
    const { vin } = useParams<{ vin: string }>();
    if (!vin) {
        return null;
    }
    return (
        <div className="idcar-public">
            <React.Suspense fallback={null}>
                <IdCarContent vin={vin} />
            </React.Suspense>
        </div>
    );
};

export const IdCarPanel = (): JSX.Element | null => {
    const [open, setOpen] = React.useState(false);
    const { hasAccess } = useAuth();
    const [vehicleData] = useVehicleData();
    const vin = vehicleData.Codif_Vin_PRF;

    if (!vin || !hasAccess("IdCar")) {
        return null;
    }

    return (
        <div className="idcar-panel">
            <button onClick={() => setOpen((s) => !s)}>
                <FontAwesomeIcon icon={faAccusoft} size="3x" />
            </button>
            <div className={open ? "" : "hidden"}>
                <IdCarContent vin={vin} />
            </div>
        </div>
    );
};
