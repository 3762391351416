import * as React from "react";

import { IMAGES_SERVER } from "@/config/settings";
import { useCountry } from "@/context/CountryContext";
import { getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";

import "./CountrySelector.css";

export type Country = {
    id: string;
    label: string;
    ISOcode2: string;
};

type Props = {
    onSelect?: (country: Country) => void;
};

const getFlagSrc = (country: Country): string =>
    `${IMAGES_SERVER}/countryFlags/${country.ISOcode2.toLowerCase()}.svg`;

export const CountrySelector = (props: Props): JSX.Element => {
    const [countries, setCountries] = React.useState<Country[]>([]);
    const [openOptions, setOpenOptions] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = useCountry();

    const toggleOptions = () => setOpenOptions((open) => !open);

    const onSelect = (country: Country) => {
        setSelectedCountry(country);
        setOpenOptions(false);
        props.onSelect && props.onSelect(country);
    };

    const lang = useLanguage();

    const initCountries = () => {
        getContent<Country[]>(`countries/${lang}`)
            .then((list) => setCountries(list))
            .catch((error) => console.error(error));
    };

    React.useEffect(initCountries, [lang]);

    React.useEffect(() => {
        if (countries.length > 0) {
            const countryId = (selectedCountry && selectedCountry.id) || "F";
            const country = countries.find((c) => c.id === countryId);
            if (country) {
                setSelectedCountry(country);
            }
        }
    }, [countries, selectedCountry, setSelectedCountry]);

    return (
        <div className="flag-select">
            <button
                className="flag-select__btn"
                onClick={toggleOptions}
                id="select_flag_button"
                type="button"
                aria-haspopup="listbox"
                aria-expanded={openOptions}
                aria-labelledby="select_flag_button"
            >
                {selectedCountry && (
                    <span className="flag-select__option">
                        <img
                            className="flag-select__option__icon"
                            src={getFlagSrc(selectedCountry)}
                            alt={selectedCountry.ISOcode2}
                            loading="lazy"
                        />
                        <span className="flag-select__option__label">
                            {selectedCountry.label}
                        </span>
                    </span>
                )}
            </button>

            {openOptions && (
                <ul
                    tabIndex={-1}
                    role="listbox"
                    className="flag-select__options"
                >
                    {countries.map((country) => (
                        <li
                            key={country.id}
                            role="option"
                            aria-selected={selectedCountry === country}
                            tabIndex={0}
                            id={`select_flag_${country}`}
                            className={`flag-select__option has-label`}
                            onClick={() => onSelect(country)}
                        >
                            <span>
                                <img
                                    className="flag-select__option__icon"
                                    alt={country.ISOcode2}
                                    src={getFlagSrc(country)}
                                    loading="lazy"
                                />
                                <span className="flag-select__option__label">
                                    {country.label}
                                </span>
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
