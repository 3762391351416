import * as React from "react";
import type { OnSuggestionSelected } from "react-autosuggest";
import { useTranslation } from "react-i18next";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { IMAGES_SERVER } from "@/config/settings";
import { getContent } from "@/utils/fetch";
import { getSupplierLogoURL, replaceIgnoreCase } from "@/utils/utils";

import { BarcodeReaderButton } from "./BarcodeScanner";
import { ToggleComponent } from "./ToggleComponent";

import "./PartSelectorSearch.css";

type TireCompletion = CompletionEntry & {
    photo: string;
    type: "Tire";
    dimensions: string;
};
type PartCompletion = CompletionEntry & {
    dlnr: number | string;
    photo?: string;
};
type SivinceCompletion = CompletionEntry & { type: "SivinCE" };
type PartSuggestion = PartCompletion | SivinceCompletion | TireCompletion;

const autocomplete = (input: string) =>
    getContent<PartSuggestion[]>(
        `completion/part/${encodeURIComponent(input)}`
    );

type Props = {
    exactSearchMode?: boolean;
    toggleSearchMode?: (e: React.ChangeEvent) => void;
    show?: boolean;
    onSubmit: (search: string) => void;
    className?: string;
};

const renderSuggestion = (
    suggestion: PartSuggestion,
    { query }: { query: string }
): JSX.Element => {
    let imgSrc = null;
    if ("dlnr" in suggestion && suggestion.dlnr) {
        imgSrc = getSupplierLogoURL(suggestion.dlnr);
    }
    if ("type" in suggestion) {
        imgSrc = `${IMAGES_SERVER}/logos/sivince.png`;
    }
    if ("photo" in suggestion && suggestion.photo) {
        imgSrc = suggestion.photo;
    }
    return (
        <div className="d-flex align-items-center">
            {imgSrc && (
                <div className="img-app-small me-1">
                    <img src={imgSrc} alt="" />
                </div>
            )}
            <span
                dangerouslySetInnerHTML={{
                    __html: replaceIgnoreCase(
                        suggestion.label,
                        query,
                        '<span class="autosuggest-query">' + query + "</span>"
                    ),
                }}
            />
        </div>
    );
};

export const PartSelectorSearch = ({
    exactSearchMode = false,
    toggleSearchMode = (): void => {},
    show = true,
    onSubmit,
}: Props): JSX.Element => {
    const [value, setValue] = React.useState("");
    const { t } = useTranslation();

    const onChange = (
        event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => setValue(newValue);

    const submit = (evt: React.FormEvent<HTMLFormElement>): void => {
        evt.preventDefault();
        if (!value) {
            return;
        }
        onSubmit(value);
    };

    return (
        <div
            className={classnames("part-selector-search", {
                ["d-none"]: !show,
            })}
        >
            <form className="d-flex flex-grow-1 mt-1 ms-1" onSubmit={submit}>
                <Autocomplete
                    value={value}
                    onChange={onChange}
                    getSuggestions={autocomplete}
                    placeholder={t("partselector.placeholder")}
                    renderSuggestion={renderSuggestion}
                />
                <BarcodeReaderButton
                    type="ean"
                    onSubmit={onSubmit}
                    className="align-self-start"
                    showQRCode
                />
            </form>
            <ToggleComponent
                className="toggle-exact-search"
                checked={exactSearchMode}
                onChange={toggleSearchMode}
            >
                {t("partselector.exact-search")}
            </ToggleComponent>
        </div>
    );
};

export const PartsCompletion = ({
    onSubmit,
    className,
    children,
}: React.PropsWithChildren<Props>): JSX.Element => {
    const [value, setValue] = React.useState("");
    const { t } = useTranslation();
    const { addTireSlide } = useAddItemSlide();
    const selected = React.useRef<PartSuggestion>();

    const onChange = (
        event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => setValue(newValue);

    const submit = (evt: React.FormEvent<HTMLFormElement>): void => {
        evt.preventDefault();
        if (!value) {
            return;
        }
        if (
            selected.current &&
            "type" in selected.current &&
            selected.current.type === "Tire" &&
            selected.current.value === value
        ) {
            addTireSlide(selected.current.dimensions);
        } else {
            onSubmit(value);
        }
    };

    const onSuggestionSelected: OnSuggestionSelected<PartSuggestion> = (
        event,
        data
    ) => {
        selected.current = data.suggestion;
    };

    return (
        <div className={className}>
            <form
                className="d-flex flex-grow-1 parts-completion"
                onSubmit={submit}
            >
                <Autocomplete
                    value={value}
                    onChange={onChange}
                    getSuggestions={autocomplete}
                    placeholder={t("partselector.placeholder")}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={onSuggestionSelected}
                />
                <button className="btn btn-outline-secondary ms-2 align-self-center">
                    <FontAwesomeIcon icon={faSearch} />
                </button>
                <BarcodeReaderButton
                    type="ean"
                    onSubmit={onSubmit}
                    className="align-self-center"
                    showQRCode
                />
                {children}
            </form>
        </div>
    );
};
