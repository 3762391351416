import type * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
    onClick: (e: React.MouseEvent) => void;
};

export const ToggleFullscreen = ({ onClick }: Props): JSX.Element => (
    <div className="toggle-fullscreen">
        <FontAwesomeIcon
            icon="arrows-alt"
            transform={{ rotate: 45 }}
            onClick={onClick}
        />
    </div>
);
