import * as React from "react";
import { format } from "date-fns";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import type { TableCell, TDocumentDefinitions } from "pdfmake/interfaces";

import type { RawCartItem } from "shared/dist/types/cart";
import type { OE } from "shared/dist/types/item";
import type { Tire } from "shared/dist/types/parts";
import type { QuoteData } from "shared/dist/types/quote";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { IMAGES_SERVER } from "@/config/settings";
import { formatPrice } from "@/pages/ShoppingCart/CartTable";

(pdfMake as Record<string, unknown>).vfs = pdfFonts.pdfMake.vfs;

const makeLabel = (data: VehicleData) => {
    const label = `${data.Marque} ${data.Modèle} ${data.Version || ""}`;
    return label;
};

const DISCOUNT = 10;
const TVA = 20;
const tireItemToRow = (name: string, item: Tire) => [
    { text: 4400, alignment: "center" },
    item.ean,
    name,
];
const oeItemToRow = (name: string, item: OE) => [
    { text: item.genartId || "", alignment: "center" },
    item.id,
    item.label || item.genArt || name,
];
const cartItemToRow = (item: RawCartItem) => {
    const labels: TableCell[] =
        item.channel === "Tire"
            ? tireItemToRow(item.name, item.item)
            : oeItemToRow(item.name, item.item);
    const columns: TableCell[] = [
        { text: item.quantity, alignment: "right" },
        { text: formatPrice(item.price), alignment: "right" },
        { text: DISCOUNT, alignment: "right" },
        {
            text: formatPrice(item.price * (1 - DISCOUNT / 100)),
            alignment: "right",
        },
        {
            text: formatPrice(
                item.price * (1 - DISCOUNT / 100) * item.quantity
            ),
            alignment: "right",
        },
        { text: 1, alignment: "right" },
    ];
    return labels.concat(columns);
};

const createDoc = ({
    cart,
    company,
    customerInfo,
    vehicleData,
    quoteId,
    customerId,
}: QuoteData) => {
    const now = new Date();
    const date = format(now, "dd/MM/yyyy");
    const timeShort = format(now, "HH:mm:ss");
    const vehicleLabel = makeLabel(vehicleData);
    const customerLabel = `${customerInfo.title} ${customerInfo.firstname} ${customerInfo.name}`;
    const total = cart.reduce(
        (sum, item) =>
            sum + (item.quantity * item.price * (100 - DISCOUNT)) / 100,
        0
    );
    const totalHT = (total / (100 + TVA)) * 100;
    const mtTVA = total - totalHT;
    const totalBlock = `   H.T.:    ${(totalHT / 100).toFixed(2)}\nT.V.A:    ${(
        mtTVA / 100
    ).toFixed(2)}`;

    const customerBlock = `\n${customerLabel}\n${customerInfo.address}\n${customerInfo.postalCode} ${customerInfo.city}\n\n`;
    const qrcodeURL = `${window.location.origin}/gcd?id=${customerId}`;
    const doc: TDocumentDefinitions = {
        pageMargins: [40, 20, 40, 0],
        content: [
            {
                columns: [
                    {
                        image: "companyLogo",
                        fit: [250, 100],
                    },
                    {
                        text: company.address,
                        fontSize: 12,
                        margin: [80, 0, 0, 0],
                    },
                ],
            },
            {
                columns: [
                    {
                        qr: qrcodeURL,
                        eccLevel: "Q",
                        width: 100,
                        fit: 100,
                        margin: [0, 10],
                    },
                    {
                        text: "Devis",
                        bold: true,
                        fontSize: 48,
                        margin: [40, 20, 0, 0],
                    },
                    {
                        table: {
                            widths: ["*"],
                            body: [[customerBlock]],
                        },
                        margin: [20, 10, 0, 0],
                    },
                ],
            },
            {
                columns: [
                    {
                        stack: [
                            {
                                text: `${date}   ${timeShort}`,
                                margin: [0, 10, 0, 0],
                            },
                            {
                                text: `Devis N°: ${quoteId}`,
                                margin: [0, 10, 0, 20],
                            },
                        ],
                    },
                    {
                        stack: [
                            {
                                text: vehicleLabel,
                            },
                            {
                                text: vehicleData.Immat_SIV || "",
                                bold: true,
                                fontSize: 16,
                                margin: [25, 0, 0, 0],
                            },
                        ],
                        margin: [80, 10, 0, 0],
                    },
                ],
            },
            {
                table: {
                    headerRows: 1,
                    body: [
                        [
                            "Article",
                            "REF",
                            "DÉSIGNATION",
                            "Qté",
                            "PU TTC",
                            "% REM",
                            "Net TTC",
                            "MT TTC",
                            "TVA",
                        ],
                        ...cart.map(cartItemToRow),
                    ],
                },
                layout: {
                    fillColor: (rowIndex) =>
                        rowIndex === 0 ? "#E0E0E0" : null,
                },
                margin: [0, 0, 0, 30],
            },
            {
                columns: [
                    {
                        table: {
                            headerRows: 1,
                            widths: ["*", "*", "auto", "auto", "auto"],
                            body: [
                                [
                                    {
                                        text: "BASES HT",
                                        style: "header",
                                        colSpan: 2,
                                    },
                                    "",
                                    { text: "MT TVA", style: "header" },
                                    { text: "% TVA", style: "header" },
                                    { text: "TOTAUX", style: "header" },
                                ],
                                [
                                    {
                                        text: 1,
                                        alignment: "center",
                                        margin: [0, 7, 0, 7],
                                    },
                                    {
                                        text: (totalHT / 100).toFixed(2),
                                        alignment: "center",
                                        margin: [0, 7, 0, 7],
                                    },
                                    {
                                        text: (mtTVA / 100).toFixed(2),
                                        alignment: "center",
                                        margin: [0, 7, 0, 7],
                                    },
                                    {
                                        text: TVA.toFixed(2),
                                        alignment: "center",
                                        margin: [0, 7, 0, 7],
                                    },
                                    { text: totalBlock, alignment: "right" },
                                ],
                            ],
                        },
                        width: 350,
                    },
                    {
                        table: {
                            body: [
                                [{ text: "TOTAL TTC", style: "header" }],
                                [
                                    {
                                        text: formatPrice(total),
                                        alignment: "center",
                                        margin: [0, 7, 0, 7],
                                    },
                                ],
                            ],
                        },
                        margin: [10, 0, 0, 0],
                    },
                    {
                        table: {
                            body: [
                                [{ text: "NET À PAYER", style: "header" }],
                                [
                                    {
                                        text: formatPrice(total),
                                        alignment: "center",
                                        bold: true,
                                        margin: [0, 7, 0, 7],
                                    },
                                ],
                            ],
                        },
                        margin: [5, 0, 0, 0],
                    },
                ],
            },

            {
                text: "Ce devis est valable 15 jours",
                margin: [100, 20, 0, 0],
            },
            {
                text: "(Hors promotion et dans la limite des stocks)",
                margin: [50, 0, 0, 0],
            },
        ],
        images: {
            companyLogo: `${IMAGES_SERVER}/userDocs/logos/${company.logo}`,
        },
        styles: {
            header: {
                fillColor: "#E0E0E0",
                alignment: "center",
            },
        },
    };
    return doc;
};

const Quote = ({
    customerInfo,
    company,
    vehicleData,
    cart,
    quoteId,
    className = "flex-grow-1",
    customerId,
}: QuoteData & { className?: string }): JSX.Element => {
    const ref = React.useRef<HTMLIFrameElement>(null);

    React.useEffect(() => {
        if (!company) {
            return;
        }
        const doc = createDoc({
            vehicleData,
            company,
            customerInfo,
            cart,
            quoteId,
            customerId,
        });
        pdfMake.createPdf(doc).getDataUrl((dataURL) => {
            if (ref.current) {
                ref.current.src = dataURL;
            }
        });
    }, [vehicleData, company, customerInfo, cart, quoteId, customerId]);

    return <iframe ref={ref} className={className} />;
};

/* default export is needed for lazy import */
export default Quote;
