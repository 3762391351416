import * as React from "react";

import { useAPI } from "@/utils/hooks";

export const MddIcon = ({ mdd }: { mdd: string }): JSX.Element | null => {
    const { data } = useAPI<Record<string, string>>("mddIcons");
    if (!data) {
        return null;
    }
    const url = data[mdd];
    if (!url) {
        return null;
    }
    return <img className="mdd-icon me-1" src={url} alt="" width="16" />;
};
