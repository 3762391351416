import * as React from "react";
import { useTranslation } from "react-i18next";
import { lightFormat, parse } from "date-fns";

import { IMAGES_SERVER } from "@/config/settings";
import { useLanguage } from "@/utils/hooks";

export type TecdocPrice = {
    price: string;
    currency: string;
    priceType: string;
    priceUnit: string;
    quantityUnit: string;
    from: string;
    to: string;
    countryCode: string;
    countryName: string;
};

const makeFlagURL = (ISOcode: string): string =>
    `${IMAGES_SERVER}/countries/${ISOcode.toUpperCase()}.png`;

const formatPrice = (strPrice: string, currency: string, lang: string) => {
    const price = Number(strPrice) / 100;
    if (Intl && "NumberFormat" in Intl) {
        return new Intl.NumberFormat(lang, {
            style: "currency",
            currency,
        }).format(price);
    }
    return `${price} ${currency}`;
};

export const TecdocPriceComponent = ({
    price,
}: {
    price: TecdocPrice;
}): JSX.Element => {
    const lang = useLanguage();
    const { t } = useTranslation();
    const from = lightFormat(
        parse(price.from, "yyyyMMdd", new Date()),
        "dd/MM/yyyy"
    );
    const to = price.to
        ? lightFormat(parse(price.to, "yyyyMMdd", new Date()), "dd/MM/yyyy")
        : "";
    const dateRange = t("fiche-article.date-range", { from, to });
    return (
        <div className="tecdoc-price">
            <img
                className="flag-select__option__icon"
                src={makeFlagURL(price.countryCode)}
                alt={price.countryCode}
                title={price.countryName}
            />
            <span className="price-date">{dateRange}</span>
            <span>{price.priceType}: </span>
            <span className="formatted-price">
                {formatPrice(price.price, price.currency, lang)}
            </span>
            <span>{price.priceUnit}</span>
            <span className="quantity-unit">({price.quantityUnit})</span>
        </div>
    );
};
