import type * as React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    PieLabel,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import type { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

import { ErrorBoundary } from "@/components/ErrorBoundary";

import type { DashboardData, DataPoint } from "./Dashboard";
import { TopBrands } from "./TopBrands";

const CHART_HEIGHT = 300;
const CHART_COLORS = ["#7DB3FF", "#49457B", "#FF7C78"];

const numberFormatter = (item: number | string) => String(item); //.format("0,0");
const dateFormatter = (item: number | Date) =>
    format(item, "EEEEEE dd/MM", { locale: fr });
const xAxisFormatter = (item: string) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(item)) {
        return dateFormatter(new Date(item));
    }
    return item;
};

// https://recharts.org/en-US/examples/PieChartWithCustomizedLabel
const RADIAN = Math.PI / 180;
const renderCustomizedLabel: PieLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

type CartesianChartProps = {
    data: unknown[];
    children?: React.ReactNode;
    ChartComponent: React.ComponentType<CategoricalChartProps>;
    title: string;
};

const CartesianChart = ({
    data,
    children,
    ChartComponent,
    title,
}: CartesianChartProps) => (
    <Card>
        <CardTitle>{title}</CardTitle>
        <CardBody>
            <ResponsiveContainer height={CHART_HEIGHT}>
                <ChartComponent data={data}>
                    <XAxis
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={xAxisFormatter}
                        dataKey="x"
                        minTickGap={20}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={numberFormatter}
                    />
                    <CartesianGrid vertical={false} />
                    {children}
                    <Legend />
                    <Tooltip
                        // labelFormatter={dateFormatter}
                        formatter={numberFormatter}
                    />
                </ChartComponent>
            </ResponsiveContainer>
        </CardBody>
    </Card>
);

const HitsAreaChart = ({ data }: { data: DataPoint[] }) => (
    <CartesianChart
        ChartComponent={AreaChart}
        data={data}
        title="Total des hits mensuels (de juin à +6)"
    >
        <Area
            dataKey="hits"
            name="Volume des hits par mois"
            fill={CHART_COLORS[0]}
            stroke={CHART_COLORS[0]}
        />
    </CartesianChart>
);

const HitsLineChart = ({ data }: { data: DataPoint[] }) => (
    <CartesianChart
        ChartComponent={LineChart}
        data={data}
        title="Total des hits sur les 30 derniers jours glissant"
    >
        <Line
            dataKey="hits"
            stroke={CHART_COLORS[0]}
            name="Volume d'appels global"
        />
    </CartesianChart>
);

const DeploymentChart = ({ data }: { data: DataPoint[] }) => (
    <Card>
        <CardTitle>Taux de déploiement</CardTitle>
        <CardBody>
            <ResponsiveContainer height={CHART_HEIGHT}>
                <PieChart>
                    <Pie
                        isAnimationActive={false}
                        data={data}
                        nameKey="x"
                        dataKey="hits"
                        fill="#8884d8"
                        startAngle={180}
                        endAngle={0}
                        label={renderCustomizedLabel}
                        labelLine={false}
                    >
                        <Cell key={0} fill={CHART_COLORS[1]} />
                        <Cell key={1} fill={CHART_COLORS[0]} />
                    </Pie>
                    <Legend />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </CardBody>
    </Card>
);

const RepeatHitsChart = ({ data }: { data: DataPoint[] }) => (
    <Card>
        <CardTitle>Proportion des new hits vs repeat</CardTitle>
        <CardBody>
            <ResponsiveContainer height={CHART_HEIGHT}>
                <PieChart>
                    <Pie
                        isAnimationActive={false}
                        data={data}
                        nameKey="x"
                        dataKey="hits"
                        fill="#8884d8"
                        label={renderCustomizedLabel}
                        labelLine={false}
                    >
                        <Cell key={0} fill={CHART_COLORS[0]} />
                        <Cell key={1} fill={CHART_COLORS[1]} />
                    </Pie>
                    <Legend />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </CardBody>
    </Card>
);

const AdnCatalogChart = ({
    data,
}: {
    data: DashboardData["stackedBarData"];
}) => (
    <CartesianChart
        ChartComponent={BarChart}
        data={data}
        title="Volume des Adn-Catalog"
    >
        <Bar
            dataKey="adnOK"
            stackId="a"
            name="AdnCatalog: OK"
            fill={CHART_COLORS[0]}
        />
        <Bar
            dataKey="adn0"
            stackId="a"
            name="AdnCatalog: 0"
            fill={CHART_COLORS[1]}
        />
        <Bar
            dataKey="adnNull"
            stackId="a"
            name="AdnCatalog: null"
            fill={CHART_COLORS[2]}
        />
    </CartesianChart>
);

const TopVCIChart = ({ data }: { data: DataPoint[] }) => (
    <CartesianChart
        ChartComponent={BarChart}
        data={data}
        title="Top 50 des VCI"
    >
        <Bar
            dataKey="newHits"
            name="new hits"
            stackId="a"
            fill={CHART_COLORS[0]}
        />
        <Bar
            dataKey="repeatHits"
            name="repeat"
            fill={CHART_COLORS[1]}
            stackId="a"
        />
    </CartesianChart>
);

const NewUsersChart = ({ data }: { data: DashboardData["newUsers"] }) => (
    <CartesianChart ChartComponent={BarChart} data={data} title="New users">
        <Bar
            dataKey="nbUsers"
            name="returning users"
            fill={CHART_COLORS[1]}
            stackId="a"
        />
        <Bar
            dataKey="newUsers"
            name="new users"
            stackId="a"
            fill={CHART_COLORS[0]}
        />
    </CartesianChart>
);

export const DashboardContent = (props: DashboardData): JSX.Element => {
    return (
        <>
            <div className="dashboard-row">
                <HitsAreaChart data={props.areaData} />
                <HitsLineChart data={props.sliding30daysData} />
            </div>
            <div className="dashboard-row">
                <div className="d-flex flex-grow-1">
                    <DeploymentChart data={props.deploymentData} />
                    <RepeatHitsChart data={props.pieData} />
                </div>
                <AdnCatalogChart data={props.stackedBarData} />
            </div>
            <div className="dashboard-row">
                <ErrorBoundary>
                    <TopBrands />
                </ErrorBoundary>
            </div>
            <div className="dashboard-row">
                <TopVCIChart data={props.topVCIData} />
            </div>
            <div className="dashboard-row">
                <NewUsersChart data={props.newUsers} />
            </div>
        </>
    );
};
