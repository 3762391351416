import * as React from "react";

type Props = {
    className?: string;
    visible: boolean;
    restricted?: boolean;
    title: string;
    src?: string;
};

export const LazyIframe = ({
    className = "",
    visible = false,
    restricted = false,
    title,
    src,
}: Props): JSX.Element | null => {
    const [initialized, setInitialized] = React.useState(false);

    React.useEffect(() => {
        if (visible && !initialized) {
            setInitialized(true);
        }
    }, [visible, initialized]);

    if (!initialized || !src) return null;

    const iframeClassName = `${className} ${visible ? "" : "hidden"}`;

    const iframeParams = restricted
        ? {}
        : {
              frameBorder: "0",
              allow: "microphone; camera",
              allowFullScreen: true,
          };

    return (
        <iframe
            className={iframeClassName}
            title={title}
            src={src}
            {...iframeParams}
        ></iframe>
    );
};
