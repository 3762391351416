import * as React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";

type Props = {
    oe: string;
    criteria: string | undefined;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const OEWithTooltip = ({
    oe,
    criteria,
    onClick,
}: Props): JSX.Element => {
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <>
            <Button color="link" data-ref={oe} innerRef={ref} onClick={onClick}>
                {oe}
            </Button>
            {criteria && (
                <UncontrolledTooltip target={ref}>
                    {criteria}
                </UncontrolledTooltip>
            )}
        </>
    );
};
