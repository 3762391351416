import * as React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams } from "react-router-dom";
import ReactTable, { CellInfo, Column } from "react-table";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import type { TFunction } from "i18next";

import type { OE } from "shared/dist/types/item";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { getAvailability } from "@/utils/availability";

import {
    CartButtons,
    ItemColumnMake,
    ItemColumnPhoto,
    PriceList,
    sortAvailability,
} from "./ArticleTable";
import { Attributes } from "./Attributes";
import { AvailabilityContainer } from "./Availability";
import { HubIdlpButton } from "./HubIdlpButton";
import type { OEItem } from "./Item";
import { SearchType, useAddItemSlide } from "./ItemSlides";
import { MddIcon } from "./MddIcon";
import { PDFList } from "./PDFList";
import { VehicleApplicability } from "./VehicleApplicability";

import pictoCar from "@/img/pictoCar.png";

const { useState } = React;

type ColumnComponent = ({ original }: { original: OE }) => JSX.Element;

type DescriptionCellParams = {
    addFicheArticleSlide(oe: OE): void;
    openModal(oe: OE): () => void;
    addIframeSlide(url: string): void;
    onEANClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    t: TFunction;
};

const makeDescriptionCell = ({
    addFicheArticleSlide,
    openModal,
    addIframeSlide,
    onEANClick,
    t,
}: DescriptionCellParams) => {
    const DescriptionCell: ColumnComponent = ({ original: oe }) => (
        <>
            <div className="item-header d-flex align-items-center flex-wrap">
                {oe.from === "mdd" && <MddIcon mdd={oe.makeId} />}
                <strong
                    className="oe-name"
                    onClick={(): void => addFicheArticleSlide(oe)}
                >
                    {oe.id}
                </strong>
                <img
                    src={pictoCar}
                    alt=""
                    style={{ cursor: "pointer", marginLeft: 5 }}
                    onClick={openModal(oe)}
                />
                <PDFList medias={oe.mediaDtos} onClick={addIframeSlide} />
            </div>
            <div className="oe-genart">{oe.genArt}</div>
            <span
                className="oe-ean"
                onClick={onEANClick}
                data-ean={oe.ean}
                data-from={oe.from}
            >
                {t("item.EAN")}: {oe.ean}
            </span>
            <HubIdlpButton value={oe.ean} />
            {oe.label && <p>{oe.label}</p>}
            <br />
            <Attributes attributes={oe.attributes} />
        </>
    );
    return DescriptionCell;
};

const makeAvailabilityCell = () => {
    const AvailabilityCell = ({
        original: oe,
    }: {
        original: OE;
    }): React.ReactElement => <AvailabilityContainer oe={oe} />;
    return AvailabilityCell;
};

const OELinks = ({
    oeRefs,
    onClick,
}: {
    oeRefs: string;
    onClick: (ref: string) => () => void;
}): JSX.Element => (
    <>
        {oeRefs &&
            oeRefs.split(",").map((ref, j) => (
                <React.Fragment key={j}>
                    {j > 0 && " > "}
                    <Button color="link" onClick={onClick(ref)}>
                        {ref}
                    </Button>
                </React.Fragment>
            ))}
    </>
);

const makeOELinksCell = (onClick: (ref: string) => () => void) => {
    const OELinksCell = (row: CellInfo) => (
        <OELinks oeRefs={row.value} onClick={onClick} />
    );
    return OELinksCell;
};

type GenartState = {
    makeId: string;
    partId: string;
    itemId: string | undefined;
    refOem: string | undefined;
};

type Props = {
    oes: OE[];
    isCart?: boolean;
    className?: string;
};

export const ItemTable = (props: Props): JSX.Element => {
    const [modalOpen, setModalOpen] = useState(false);
    const [genartState, setGenartState] = useState<GenartState>({
        makeId: "",
        partId: "",
        itemId: undefined,
        refOem: undefined,
    });
    const slidesHelper = useAddItemSlide();
    const { addIframeSlide, addPartsSearchSlide } = slidesHelper;

    const openModal = (oe: OEItem) => (): void => {
        const makeId = String(oe.id_fournisseur || oe.makeId);
        const partId = String(oe.ArtNr || oe.id);
        const itemId = oe.genart || oe.articleId;
        setGenartState({
            makeId,
            partId,
            itemId,
            refOem: oe.ref_oem,
        });
        setModalOpen(true);
    };

    const toggleModal = (): void => setModalOpen((open) => !open);

    const onEANClick = (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ): void => {
        const { ean, from } = event.currentTarget.dataset;
        if (ean) {
            addPartsSearchSlide(ean, (from as SearchType) || "ean");
        }
    };

    const { itemId } = useParams<{ itemId: string }>();

    const addFicheArticleSlide = (oe: OE): void => {
        const item = { ...oe, itemId };
        slidesHelper.addFicheArticleSlide(item);
    };

    const { t } = useTranslation();

    const { oes, isCart } = props;
    const displayOEColumn =
        oes.length > 0 && Object.prototype.hasOwnProperty.call(oes[0], "oes");

    const columns: Column[] = [
        {
            Header: t("item.make"),
            width: 80,
            Cell: ItemColumnMake,
            accessor: "makeLabel",
        },
        {
            Header: t("item.photo"),
            width: 100,
            Cell: ItemColumnPhoto,
            sortable: false,
        },
        {
            Header: t("item.desc"),
            width: 300,
            Cell: makeDescriptionCell({
                addFicheArticleSlide,
                openModal,
                addIframeSlide,
                onEANClick,
                t,
            }),
            accessor: "genArt",
        },
    ];
    if (displayOEColumn) {
        columns.push({
            Header: <FontAwesomeIcon icon="barcode" />,
            accessor: "oes",
            width: 300,
            Cell: makeOELinksCell(
                (ref: string) => () => addPartsSearchSlide(ref)
            ),
        });
    }
    columns.push({
        Header: t("item.availability"),
        sortable: true,
        width: 100,
        accessor: getAvailability,
        Cell: makeAvailabilityCell(),
        id: "availability",
        sortMethod: sortAvailability,
    });
    columns.push({
        Header: t("item.price"),
        width: 90,
        Cell: PriceList,
        accessor: "price",
    });
    columns.push({
        Header: t("item.cart"),
        Cell: isCart ? () => null : CartButtons,
        width: 100,
        sortable: false,
    });

    const className = classnames("oes-table", props.className);

    return (
        <div className={className}>
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>
                    <span>
                        Liste des véhicules logiques compatibles pour la ref{" "}
                        {genartState.partId}
                    </span>
                </ModalHeader>
                <ModalBody>
                    <Switch>
                        <Route path="/(pieces|pieces-oe)/:brand/parts/:family/:subFamily/:itemId">
                            <VehicleApplicability
                                makeId={genartState.makeId}
                                partId={genartState.partId}
                                refOem={genartState.refOem}
                                toggle={toggleModal}
                            />
                        </Route>
                        <Route>
                            <VehicleApplicability
                                {...genartState}
                                toggle={toggleModal}
                            />
                        </Route>
                    </Switch>
                </ModalBody>
            </Modal>
            <ErrorBoundary>
                <ReactTable
                    data={props.oes}
                    columns={columns}
                    defaultPageSize={50}
                    minRows={1}
                    showPagination={oes.length > 50}
                />
            </ErrorBoundary>
        </div>
    );
};
