import { useEffect } from "react";

import { useAuth } from "@/context/AuthContext";

declare global {
    interface Window {
        chatwootSDK: { run: (conf: unknown) => void };
    }
}

export const useChatwoot = (): void => {
    const { hasAccess } = useAuth();
    useEffect(() => {
        const { REACT_APP_CHATWOOT_TOKEN } = process.env;
        if (!REACT_APP_CHATWOOT_TOKEN || !hasAccess("Chatwoot")) return;
        if (["/rvin", "/rean"].includes(window.location.pathname)) return;

        const BASE_URL = "https://chat.cloudia.run";
        const g = document.createElement("script");
        const s = document.getElementsByTagName("script")[0];
        g.src = BASE_URL + "/packs/js/sdk.js";
        s.parentNode?.insertBefore(g, s);
        g.onload = function () {
            window.chatwootSDK.run({
                websiteToken: REACT_APP_CHATWOOT_TOKEN,
                baseUrl: BASE_URL,
            });
        };
    }, [hasAccess]);
};
