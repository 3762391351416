import type * as React from "react";
import { useTranslation } from "react-i18next";
import type { MultiValueGenericProps, OptionProps } from "react-select";
import Select, { createFilter, OnChangeValue } from "react-select";
import classnames from "classnames";

import type { Genart } from "shared/dist/types/types";

import { IMAGES_SERVER } from "@/config/settings";
import { useGenarts } from "@/utils/hooks";

import { Loading } from "./Loading";
import { MenuList } from "./OptimizedMenuList";

const DEFAULT_IMG_URL = `${IMAGES_SERVER}/gif/DEFAULT.png`;

const familiesWithoutImage = new Set<string>();

const handleImageError =
    (family: string) =>
    (e: React.SyntheticEvent<HTMLImageElement>): void => {
        e.currentTarget.src = `${IMAGES_SERVER}/gif/DEFAULT.png`;
        familiesWithoutImage.add(family);
    };

const getFamilyImageURL = (option: Genart) => {
    const { family } = option;
    if (!family || familiesWithoutImage.has(family)) {
        return DEFAULT_IMG_URL;
    }
    return `${IMAGES_SERVER}/gif/${family}.png`;
};

const CustomOption = (props: OptionProps<Genart, boolean>) => {
    const { innerRef, innerProps, label, isDisabled, isFocused, isSelected } =
        props;
    const data: Genart = props.data;

    const className = classnames({
        "filter-option": true,
        "option--is-disabled": isDisabled,
        "option--is-focused": isFocused,
        "option--is-selected": isSelected,
    });

    return (
        <div className={className} ref={innerRef} {...innerProps}>
            <img
                src={getFamilyImageURL(data)}
                alt=""
                width="30"
                onError={handleImageError(data.family)}
            />
            <span>{label}</span>
        </div>
    );
};

const FilterLabel = (props: MultiValueGenericProps<Genart>) => {
    const { data } = props;

    return (
        <div style={{ gridArea: "1/1/2/3" }}>
            <img
                src={getFamilyImageURL(data)}
                alt=""
                width="30"
                onError={handleImageError(data.family)}
            />
            <span>{data.label}</span>
        </div>
    );
};

const customComponents = {
    Option: CustomOption,
    MultiValueLabel: FilterLabel,
    SingleValue: FilterLabel,
    MenuList,
};
const filterOption = createFilter({ ignoreAccents: false });

type GenartProps<IsMulti extends boolean> = {
    onSubmit?: () => void;
    placeholder?: string;
    multi: IsMulti;
    genart: OnChangeValue<Genart, IsMulti>;
    onGenartChange: (genart: OnChangeValue<Genart, IsMulti>) => void;
};

const noop = () => {};

export const GenartSelect = <IsMulti extends boolean = true>(
    props: GenartProps<IsMulti>
): React.ReactElement => {
    const { t } = useTranslation();
    const { genarts } = useGenarts();

    const { genart, onGenartChange, onSubmit = noop, multi } = props;
    const handleKeydown: React.KeyboardEventHandler = (event) => {
        if (event.key === "Enter") {
            onSubmit();
        }
    };

    const placeholder = props.placeholder || t("partsettings.placeholder");

    const value = genart || (multi ? ([] as Genart[]) : null);

    if (genarts.length === 0) {
        return <Loading />;
    }

    return (
        <Select
            name="selectGenart"
            placeholder={placeholder}
            options={genarts}
            filterOption={filterOption}
            value={value}
            onChange={onGenartChange}
            onKeyDown={handleKeydown}
            isMulti={multi}
            className="Select"
            components={customComponents}
        />
    );
};
