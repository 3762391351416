import * as React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { i18n } from "@/config/i18n";
import { fetchWithAuth } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

export const LPRButton = ({
    onSuccess,
}: {
    onSuccess(immat: string): void;
}) => {
    const [isOpen, toggle] = React.useReducer((o) => !o, false);
    const handleSuccess = (immat: string) => {
        toggle();
        onSuccess(immat);
    };
    return (
        <>
            <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggle}
            >
                <FontAwesomeIcon icon={faBarcode} />
            </button>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Lire une plaque d&apos;immatriculation
                </ModalHeader>
                <ModalBody>
                    <LPR onSuccess={handleSuccess} />
                </ModalBody>
            </Modal>
        </>
    );
};

type LPRData = [string, number][];

const LPR = ({ onSuccess }: { onSuccess(immat: string): void }) => {
    const [isLoading, setLoading] = React.useState(false);
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = async (
        event
    ) => {
        const file = event.currentTarget.files?.[0];
        if (!file) return;
        setLoading(true);
        try {
            const formData = new FormData();
            const reader = new FileReader();
            reader.readAsDataURL(file);
            formData.set("image", file);
            const response = await fetchWithAuth("lpr", {
                method: "POST",
                body: formData,
            });
            const data: LPRData = await response.json();
            if (data.length === 0) {
                toastr.info("Impossible de lire la plaque");
                return;
            }
            const immat = data[0][0];
            onSuccess(immat);
        } catch (error) {
            toastr.error(i18n.t("common.error"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="lpr">
            <input
                type="file"
                name="plate-img"
                accept=".jpg,.jpeg"
                onChange={handleChange}
                disabled={isLoading}
            />
        </form>
    );
};
