import * as React from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSlides } from "@/components/SlidesStack";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";

import { MyProfile } from "./MyProfile";

export const ProfilePictureAndUserName = (): JSX.Element | null => {
    const { userInfo } = useAuth();
    const { addSlide } = useSlides();
    if (!userInfo) return null;
    const slide = {
        id: "my-profile",
        content: <MyProfile />,
    };
    const url = `${IMAGES_SERVER}/userDocs/profile/${userInfo.picture}`;
    return (
        <div className="user-profile-bloc d-flex sticky-top mb-5">
            <div className="user-profile-picture">
                <img src={url} alt="" />
            </div>
            <div className="user-name-bloc d-flex align-items-center">
                <span className="ms-4 me-2">{userInfo.name}</span>
                <FontAwesomeIcon
                    icon={faUser}
                    role="button"
                    className="ms-auto me-2"
                    onClick={() => addSlide(slide)}
                />
            </div>
        </div>
    );
};
