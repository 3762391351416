import * as React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { faFileImage } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { i18n } from "@/config/i18n";
import { useAuth, useUpdateUser } from "@/context/AuthContext";
import { fetchWithAuth } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

export const SetCompanyCoverImage = (): JSX.Element | null => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [imgSrc, setImgSrc] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const { company, userInfo, hasAccess } = useAuth();
    const { setUserInfo } = useUpdateUser();

    if (!hasAccess("changeCompanyCover") || !company) {
        return null;
    }

    const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setImgSrc(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    const toggle = () => {
        setImgSrc("");
    };

    const submitPicture = () => {
        const file = inputRef.current?.files?.[0];
        if (isSubmitting || !file) return;
        const formData = new FormData();
        formData.append("image", file);
        setIsSubmitting(true);
        fetchWithAuth(`set-company-cover/${company.id}`, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then(({ filename }) => {
                setIsSubmitting(false);
                company.cover = filename;
                setUserInfo(userInfo);
                toggle();
            })
            .catch((error) => {
                console.error(error);
                setIsSubmitting(false);
                toastr.error(i18n.t("common.error"));
            });
    };

    return (
        <>
            <button
                className="btn p-1"
                onClick={() => inputRef.current?.click()}
            >
                <FontAwesomeIcon icon={faFileImage} size="2x" />
            </button>
            <input
                type="file"
                className="d-none"
                accept="image/*"
                ref={inputRef}
                onChange={fileChangeHandler}
            />
            <Modal
                isOpen={imgSrc !== ""}
                toggle={toggle}
                size="xl"
                className="max-height-modal"
            >
                <ModalBody>
                    <img src={imgSrc} alt="" className="mw-100" />
                </ModalBody>
                <ModalFooter>
                    <div>
                        {isSubmitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="me-4"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCheck}
                                role="button"
                                size="2x"
                                className="me-4 text-success"
                                onClick={submitPicture}
                            />
                        )}
                        <FontAwesomeIcon
                            icon={faTimes}
                            role="button"
                            onClick={toggle}
                            size="2x"
                            className="text-secondary"
                        />
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};
