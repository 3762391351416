import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { IMAGES_SERVER } from "@/config/settings";

export const CAIA_LOGO_URL = `${IMAGES_SERVER}/v2/items/idlp.png`;

export const iframeSlidesLogin = [
    "https://plus.ia.parts/ie.php?www.idlp.fr/",
    "about:_blank",
];
export const iframeSlides = [
    "https://splash.ia.parts",
    "https://splash.ia.parts",
    "https://splash.ia.parts",
    "https://splash.ia.parts",
    "https://splash.ia.parts",
    "https://splash.ia.parts",
    "https://splash.ia.parts",
];
export const iframeSlidesCaridentifier = [
    {
        title: "Live Car",
        img: `${IMAGES_SERVER}/v2/carH/idlp.png`,
        makeURL: (vd: VehicleData): string =>
            `https://facetime.n.parts/${vd.Codif_Vin_PRF}`,
    },
];
