import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import type { CompletionEntry, Genart } from "shared/dist/types/types";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { i18n } from "@/config/i18n";
import { apiRootURL, IMAGES_SERVER, LANGUAGES } from "@/config/settings";
import { getContent, postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

const { useCallback, useEffect, useRef, useState } = React;

export const useActiaSession = (vehicleData: VehicleData): void => {
    const { field } = useParams<{ field: string }>();

    const createActiaSession = useCallback((): void => {
        const {
            Immat_SIV: immat,
            Codif_Vin_PRF: vin,
            Marque: brand,
            Modèle: model,
            ktype,
        } = vehicleData;
        if (!ktype || field === "operation" || field === "MID") return;

        postContent("createActiaSession", {
            immat,
            vin,
            brand,
            model,
            ktype,
        })
            .then((res) => res.json())
            .then(({ result }) => {
                if (result === "Error") {
                    toastr.error(i18n.t("actia.toast.error"));
                } else if (result === "Success") {
                    const logoURL = `${IMAGES_SERVER}/v2/items/actia.png`;
                    toastr.info(
                        <div className="create-actia-session-toast">
                            <img
                                className="logo-actia"
                                alt="logo Actia"
                                src={logoURL}
                            />
                            <span>{i18n.t("actia.toast.success")}</span>
                        </div>
                    );
                }
                // No token for this user, don't do anything
            })
            .catch((error) => {
                console.error(error);
                toastr.error(i18n.t("actia.toast.error"));
            });
    }, [vehicleData, field]);

    useEffect(createActiaSession, [vehicleData, createActiaSession]);
};

export const useIsMounted = (): (() => boolean) => {
    const ref = useRef(false);
    useEffect(() => {
        ref.current = true;
        return (): void => {
            ref.current = false;
        };
    }, []);
    return useCallback(() => ref.current, [ref]);
};

export const useLanguage = (withoutFallback = false): string => {
    const { i18n: i18next } = useTranslation();
    const shortLang = i18next.language.substring(0, 2);
    const lang = LANGUAGES.find((language) => language.id === shortLang);
    return withoutFallback ? shortLang : lang?.tecdocLanguage || shortLang;
};

type GenartData = {
    genarts: Genart[];
    genartsMap: Record<number, Genart>;
};

export const useGenarts = (): GenartData => {
    const lang = useLanguage(true);
    const { data: genarts } = useAPI<Genart[]>(`genarts/${lang}`, {
        initialData: [],
    });
    return React.useMemo(() => {
        const genartsMap: Record<number, Genart> = {};
        for (const genart of genarts) {
            genartsMap[genart.value] = genart;
        }
        return { genarts, genartsMap };
    }, [genarts]);
};

type FamilyData = {
    families: CompletionEntry[];
    familyMap: Record<string, string>;
};
export const useFamilies = (): FamilyData => {
    const lang = useLanguage(true);
    const { data: families } = useAPI<CompletionEntry[]>(`families/${lang}`, {
        initialData: [],
    });
    const familyData = React.useMemo(() => {
        const familyMap: Record<string, string> = {};
        for (const family of families) {
            familyMap[family.value] = family.label;
        }
        return { families, familyMap };
    }, [families]);
    return familyData;
};

type UseAPIOptions<Data = unknown> = Partial<{
    showToastOnError: boolean;
    initialData: Data;
    ignoreCache: boolean;
}>;

type UseAPIReturn<Data> = {
    setData: React.Dispatch<React.SetStateAction<Data>>;
    fetchData: () => void;
    data: Data;
    error: unknown;
    isFetching: boolean;
};

export function useAPI<Data>(
    url: string | null,
    options: UseAPIOptions<Data> & { initialData: Data }
): UseAPIReturn<Data>;
export function useAPI<Data>(
    url: string | null,
    options?: UseAPIOptions<Data>
): UseAPIReturn<Data | null>;
export function useAPI<Data>(
    url: string | null,
    options: UseAPIOptions<Data> = {}
): UseAPIReturn<Data | null> {
    const [data, setData] = useState<Data | null>(options.initialData ?? null);
    const [error, setError] = useState<unknown>(null);
    const [isFetching, setIsFetching] = useState(false);
    const isMounted = useIsMounted();

    const { ignoreCache = false } = options;

    const fetchData = useCallback(() => {
        if (!url) return;
        setIsFetching(true);
        return getContent<Data>(url, { ignoreCache })
            .then((results) => {
                if (isMounted()) {
                    setData(results);
                }
            })
            .catch((err: unknown) => {
                console.error(err);
                if (isMounted()) {
                    setError(err);
                }
            })
            .finally(() => {
                setIsFetching(false);
            });
    }, [ignoreCache, isMounted, url]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (options.showToastOnError && error) {
            toastr.error(i18n.t("common.error"));
        }
    }, [error, options.showToastOnError]);

    return {
        data: url ? data : options.initialData ?? null,
        setData,
        fetchData,
        error,
        isFetching,
    };
}

type Options<T> = {
    initialData: T;
};

export function usePublicAPI<Data>(
    url: string | null
): UseAPIReturn<Data | null>;
export function usePublicAPI<Data>(
    url: string | null,
    options: Options<Data>
): UseAPIReturn<Data>;
export function usePublicAPI<Data>(
    url: string | null,
    options?: Options<Data>
): UseAPIReturn<Data | null> {
    const [data, setData] = useState<Data | null>(options?.initialData ?? null);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState<unknown>(null);
    const isMounted = useIsMounted();

    const fetchData = useCallback(() => {
        if (!url) return;
        setIsFetching(true);
        fetch(`${apiRootURL}/api/${url}`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(res.statusText);
                }
                return res.json();
            })
            .then((results) => {
                if (isMounted()) {
                    setData(results);
                }
            })
            .catch((err: unknown) => {
                console.error(err);
                if (isMounted()) {
                    setError(err);
                }
            })
            .finally(() => {
                setIsFetching(false);
            });
    }, [isMounted, url]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        data: url ? data : options?.initialData ?? null,
        setData,
        fetchData,
        error,
        isFetching,
    };
}
