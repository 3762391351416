import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import type { App } from "shared/dist/types/apps";

import { useGenarts } from "@/utils/hooks";

interface AppButtonProps {
    onClick: () => void;
    imgSrc: string;
    alt: string;
    title: string;
}

const AppButtonV2 = ({ onClick, imgSrc, alt, title }: AppButtonProps) => (
    <div onClick={onClick}>
        <img className="genart-img" src={imgSrc} alt={alt} title={title} />
    </div>
);

export const V2App = (app: App) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<{ ktype: string }>();

    const togglePath = (): void => {
        const path = app.url || "";
        if (location.pathname.endsWith(path)) {
            history.push(match.url);
            return;
        }
        history.push(`${match.url}${path}`);
    };
    return (
        <AppButtonV2
            onClick={togglePath}
            imgSrc={app.img || ""}
            title={app.name}
            alt={app.name}
        />
    );
};

export const GenartApp = (app: App) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<{ ktype: string }>();
    const { genartsMap } = useGenarts();
    const genartId = Number(app.brandId);

    const handleGenartClick = () => {
        if (
            location.pathname.endsWith(String(genartId)) &&
            location.pathname.match("/parts/")
        ) {
            history.push(match.url);
            return;
        }
        const genart = genartsMap[genartId];
        if (genart) {
            const { family, subFamily, categoryId } = genart;
            const url = `${match.url}/parts/${family}/${subFamily}/${categoryId}/${genartId}`;
            history.push(url);
        }
    };
    return (
        <AppButtonV2
            onClick={handleGenartClick}
            imgSrc={app.img || ""}
            alt={app.name}
            title={app.name}
        />
    );
};

export const V2IframeApp = (app: App) => {
    const history = useHistory();
    const match = useRouteMatch<{ ktype: string }>();
    const matchIframe = useRouteMatch<{ appId: string }>({
        path: `${match.url}/app/:appId`,
    });

    const toggleIframe = () => {
        const redirectTo =
            matchIframe?.params.appId === app.url
                ? match.url
                : `${match.url}/app/${app.url}`;
        history.push(redirectTo);
    };
    return (
        <AppButtonV2
            onClick={toggleIframe}
            imgSrc={app.img || ""}
            title={app.name}
            alt={app.name}
        />
    );
};
