import * as React from "react";

import type { Customer } from "shared/dist/types/customer";

export const CustomerLabel = ({ customer }: { customer: Customer }) => {
    let label = `#${customer.id}`;
    if (customer.info) {
        const { info } = customer;
        label = `${info.title} ${info.firstname} ${info.name}`;
    }
    return <p>Client: {label}</p>;
};
