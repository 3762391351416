import * as React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import { useAuth } from "@/context/AuthContext";

export const ErrorMessage = ({
    className,
}: {
    className?: string;
}): JSX.Element => {
    const { t } = useTranslation();
    const { userInfo } = useAuth();
    const messageKey = userInfo.userId ? "common.error" : "login.disconnected";
    const color = userInfo.userId ? "danger" : "warning";
    return (
        <Alert color={color} className={className}>
            {t(messageKey)}
        </Alert>
    );
};
