import * as React from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LANGUAGES } from "@/config/settings";
import { useLanguage } from "@/utils/hooks";

import flagChina from "@/img/flags/china.svg";
import flagFrance from "@/img/flags/france.svg";
import flagDE from "@/img/flags/germany.svg";
import flagIT from "@/img/flags/italy.svg";
import flagSpain from "@/img/flags/spain.svg";
import flagUK from "@/img/flags/united-kingdom.svg";

const MAIN_LANGUAGES: Record<string, { name: string; flag: string }> = {
    fr: { name: "Français", flag: flagFrance },
    en: { name: "English", flag: flagUK },
    de: { name: "Deutsch", flag: flagDE },
    it: { name: "Italiano", flag: flagIT },
    es: { name: "Español", flag: flagSpain },
    zh: { name: "Chinese", flag: flagChina },
};

const NO_PADDING = { padding: 0 };

export const LanguageSelector = ({
    className = "",
}: {
    className?: string;
}): JSX.Element => {
    const [modalOpen, setModalOpen] = React.useState(false);

    const openModal = (): void => setModalOpen(true);
    const toggleModal = (): void => setModalOpen((open) => !open);

    const { i18n, t } = useTranslation();
    const shortLang = useLanguage(true);
    const language = MAIN_LANGUAGES[shortLang];

    return (
        <UncontrolledDropdown className={className}>
            <Modal isOpen={modalOpen} toggle={toggleModal} size="xl">
                <ModalHeader toggle={toggleModal}>
                    {t("choose-language")}
                </ModalHeader>
                <ModalBody>
                    <div className="languages">
                        {LANGUAGES.map((lang) => (
                            <div key={lang.id}>
                                <Button
                                    color="none"
                                    onClick={() => {
                                        i18n.changeLanguage(lang.id);
                                    }}
                                    style={NO_PADDING}
                                >
                                    <FontAwesomeIcon icon={faGlobeAmericas} />
                                </Button>
                                {lang.name}
                            </div>
                        ))}
                    </div>
                </ModalBody>
            </Modal>
            <DropdownToggle color="none">
                {language ? (
                    <img src={language.flag} alt={language.name} width="20" />
                ) : (
                    <FontAwesomeIcon icon={faGlobeAmericas} />
                )}
            </DropdownToggle>
            <span style={{ color: "white" }}> {shortLang}</span>
            <DropdownMenu className="narrow-dropdown">
                {Object.keys(MAIN_LANGUAGES)
                    .filter((lang) => lang !== shortLang)
                    .map((lang) => (
                        <DropdownItem
                            key={lang}
                            onClick={(): void => {
                                i18n.changeLanguage(lang);
                            }}
                        >
                            <img
                                src={MAIN_LANGUAGES[lang].flag}
                                alt={MAIN_LANGUAGES[lang].name}
                                width="20"
                            />
                        </DropdownItem>
                    ))}
                <DropdownItem onClick={openModal}>
                    <FontAwesomeIcon icon={faGlobeAmericas} />
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
