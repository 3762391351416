import * as React from "react";
import classnames from "classnames";

import { ContactSelectorSearch } from "@/components/ContactSelectorSearch";
import { DiagSearch } from "@/components/DiagSearch";
import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { PartSelectorSearch } from "@/components/PartSelectorSearch";
import { CarSelectorSearch } from "@/pages/CarSelector/CarSelectorSearch";
import { useHubidlp } from "@/v2/HubIdlpContext";

import { SearchTires } from "./SearchTires";
import { SearchVin } from "./SearchVin";

export const SearchBar = ({ slideId }: { slideId: number }): JSX.Element => {
    const { addPartsSearchSlide } = useAddItemSlide();
    const { setSearch } = useHubidlp();
    const onSubmit = (search: string) => {
        addPartsSearchSlide(search);
        setSearch(search);
    };
    return (
        <div className="search-bar d-flex">
            <SearchVin show={slideId === 0} />
            <CarSelectorSearch show={slideId === 1} />
            <PartSelectorSearch show={slideId === 2} onSubmit={onSubmit} />
            <SearchTires show={slideId === 3} />
            <div
                className={classnames(
                    "flex flex-grow-1",
                    slideId === 4 ? "d-flex" : "d-none"
                )}
            >
                <DiagSearch />
            </div>
            <ContactSelectorSearch show={slideId === 5} />
        </div>
    );
};
