import * as React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Nav, TabContent, TabPane } from "reactstrap";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Channel, RawCartItem } from "shared/dist/types/cart";

import { ItemTable } from "@/components/Item/ItemTable";
import { NavItem } from "@/components/NavItem";
import { CART_TABS, HUB_IDLP_HOST } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { CartContext } from "@/context/CartItems";
import { Orders } from "@/pages/ContactSelector/Orders";

import { CartTable } from "./CartTable";

import "./ShoppingCart.css";

type Carts = Partial<Record<Channel, RawCartItem[]>>;

export const groupByChannel = (cart: RawCartItem[]): Carts =>
    cart.reduce<Carts>((carts, item) => {
        carts[item.channel] = carts[item.channel] || [];
        carts[item.channel]?.push(item);
        return carts;
    }, {});

const ForceRefresh = ({
    visible,
    children,
}: {
    visible: boolean;
    children: React.ReactNode;
}) => {
    if (!visible) {
        return null;
    }
    return <>{children}</>;
};

export const ShoppingCart = (): JSX.Element => {
    const [activeTab, setActiveTab] = React.useState(
        CART_TABS.length > 0 ? CART_TABS[0] : "hubidlp"
    );

    const { wishlist, comparelist } = React.useContext(CartContext);
    const { t } = useTranslation();
    const { hasAccess } = useAuth();

    return (
        <div className="shopping-cart">
            <Breadcrumb>
                <BreadcrumbItem>
                    <FontAwesomeIcon
                        icon="barcode"
                        style={{ marginRight: 5 }}
                    />
                    Gestionnaire des listes
                </BreadcrumbItem>
                <BreadcrumbItem>
                    Pièces &amp; services sélectionnés
                </BreadcrumbItem>
                <div className="breadcrumb-img">
                    <FontAwesomeIcon icon={faShoppingCart} />
                </div>
            </Breadcrumb>

            <Nav tabs>
                {CART_TABS.map((tab) => (
                    <NavItem
                        key={tab}
                        tabId={tab}
                        activeTab={activeTab}
                        toggleTab={setActiveTab}
                        tabName={t(`shopping-cart.${tab}`)}
                    />
                ))}
                {hasAccess("HubIdlp") && (
                    <NavItem
                        tabId="hubidlp"
                        activeTab={activeTab}
                        toggleTab={setActiveTab}
                        tabName={t("shopping-cart.cart") + " Hub IDLP"}
                    />
                )}
            </Nav>

            <TabContent
                activeTab={activeTab}
                className="d-flex flex-grow-1 overflow-hidden"
            >
                <TabPane
                    tabId="cart"
                    className="flex-grow-1 overflow-hidden d-flex flex-column"
                >
                    <CartTable />
                </TabPane>
                <TabPane tabId="wishlist" className="flex-grow-1">
                    <ItemTable oes={wishlist} isCart />
                </TabPane>
                <TabPane tabId="aside" className="flex-grow-1">
                    <ItemTable oes={comparelist} isCart />
                </TabPane>
                <TabPane tabId="order">
                    <ForceRefresh visible={activeTab === "order"}>
                        <Orders />
                    </ForceRefresh>
                </TabPane>
                <TabPane tabId="delivery"></TabPane>
                <TabPane tabId="shipping"></TabPane>
                <TabPane tabId="waste-d3e"></TabPane>
                {hasAccess("HubIdlp") && (
                    <TabPane tabId="hubidlp" className="flex-grow-1">
                        <iframe
                            src={`${HUB_IDLP_HOST}/shopping-cart`}
                            className="h-100 w-100"
                        />
                    </TabPane>
                )}
            </TabContent>
        </div>
    );
};
