import * as React from "react";
import { Button } from "reactstrap";

import type { QuoteData } from "shared/dist/types/quote";

import Quote from "@/components/Quote";
import { addClient, removeClient } from "@/components/SSEHandler";
import { i18n } from "@/config/i18n";
import { postPublic } from "@/utils/postPublic";
import { toastr } from "@/utils/toastr";

import { Cart } from "./Cart";

export const CartOrQuote = () => {
    type Display = "cart" | "quote";
    const [display, setDisplay] = React.useState<Display>("cart");
    const [quote, setQuote] = React.useState<QuoteData>();

    React.useEffect(() => {
        const clientId = addClient((data) => {
            console.log("Event received", data);
            if (data.type === "Quote") {
                setQuote(data.quote);
                setDisplay("quote");
            }
        });
        return () => {
            removeClient(clientId);
        };
    }, []);

    if (display === "cart" || !quote) {
        return <Cart />;
    }
    const sendAnswer = async (answer: "accepted" | "rejected") => {
        const res = await postPublic("quote/status", {
            status: answer,
            quoteId: quote.quoteId,
        });
        if (!res.ok) {
            toastr.error(i18n.t("common.error"));
            return;
        }
        setDisplay("cart");
    };
    return (
        <>
            <Quote {...quote} className="mobile-quote" />
            <div className="d-flex my-2 justify-content-around">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => sendAnswer("accepted")}
                >
                    Accepter
                </Button>
                <Button
                    color="secondary"
                    type="button"
                    onClick={() => sendAnswer("rejected")}
                >
                    Refuser
                </Button>
            </div>
        </>
    );
};
