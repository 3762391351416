import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import {
    APP_TYPES,
    AppType,
    WIDGET_TYPES,
    WidgetType,
} from "shared/dist/types/apps";

import { postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

const APP_DESCRIPTIONS: Record<AppType, string> = {
    databox: "Databox",
    diag: "Ouvre une iframe Diag",
    iconLink:
        "Lien interne à C@iA. Utilise une icône de fontawesome (suivant le nom). Pour une image, utiliser linkTo.",
    iframe: "Lien externe vers n'importe quelle URL.",
    iframeCarSelector:
        "Lien externe, s'affiche dans le bloc de droite du CarSelector.",
    levam: "Lien externe vers la page marque de Levam. Le nom de l'app est la marque affichée.",
    linkTo: "Lien interne à C@iA. Utilise une image. Pour une icône, utiliser iconLink.",
    stock: 'Lien vers "Mon stock".',
    internal: "App interne à C@iA",
} as const;

const APPTYPE_WITH_URL: AppType[] = [
    "diag",
    "iconLink",
    "iframe",
    "iframeCarSelector",
    "linkTo",
];

type Props = {
    widget: WidgetType;
    toggle(): void;
    isOpen: boolean;
};

const Wizard = ({ widget, toggle }: Omit<Props, "isOpen">): JSX.Element => {
    const { register, handleSubmit, watch } = useForm();
    const onSubmit = async (data: Record<string, string>) => {
        if (!data.name) {
            return;
        }
        try {
            await postContent("newApp", data);
            // TODO: refresh app list
            toastr.success("App ajoutée.");
            toggle();
        } catch (error) {
            console.error(error);
            toastr.error("Impossible d'ajouter l'app");
        }
    };

    const appType: AppType = watch("appType", "iframe");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader toggle={toggle}>Ajouter une app</ModalHeader>
            <ModalBody>
                <div className="p-4">
                    <div className="d-flex mb-2">
                        <label className="me-4">
                            <span>Widget</span>
                        </label>
                        <select defaultValue={widget} {...register("widget")}>
                            {WIDGET_TYPES.map((type) => (
                                <option value={type} key={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="d-flex mb-2">
                        <label className="me-4">
                            <span>Type d&rsquo;app</span>
                        </label>
                        <select defaultValue="iframe" {...register("appType")}>
                            {APP_TYPES.map((type) => (
                                <option value={type} key={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="ms-4">{APP_DESCRIPTIONS[appType]}</div>
                    <div
                        className={classnames(
                            "mb-2",
                            appType === "databox" ? "d-none" : "d-flex"
                        )}
                    >
                        <label className="me-4">
                            <span>Nom</span>
                        </label>
                        <input type="text" {...register("name")} />
                    </div>
                    <div
                        className={classnames(
                            "mb-2",
                            APPTYPE_WITH_URL.includes(appType)
                                ? "d-flex"
                                : "d-none"
                        )}
                    >
                        <label className="me-4">
                            <span>URL</span>
                        </label>
                        <input type="text" {...register("url")} />
                    </div>
                    <div
                        className={classnames(
                            "mb-2",
                            ["databox", "iconLink"].includes(appType)
                                ? "d-none"
                                : "d-flex"
                        )}
                    >
                        <label className="me-4">
                            <span>URL de l&rsquo;image</span>
                        </label>
                        <input type="text" {...register("img")} />
                    </div>
                    <div
                        className={classnames(
                            "mb-2",
                            appType === "stock" ? "d-flex" : "d-none"
                        )}
                    >
                        <label className="me-4">
                            <span>CaiaItemGroup id</span>
                        </label>
                        <input type="number" {...register("brandId")} />
                    </div>
                    <div className="d-flex mb-2">
                        <label className="me-4">
                            <span>Droit d&rsquo;accès</span>
                        </label>
                        <input type="text" {...register("accessRight")} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="none">
                    <FontAwesomeIcon
                        icon={faCheck}
                        role="button"
                        size="2x"
                        className="text-success"
                    />
                </Button>
                <Button color="none" type="button">
                    <FontAwesomeIcon
                        icon={faTimes}
                        role="button"
                        onClick={toggle}
                        size="2x"
                        className="text-secondary"
                    />
                </Button>
            </ModalFooter>
        </form>
    );
};

export const NewAppWizard = ({
    isOpen,
    ...props
}: Props): JSX.Element | null => {
    return (
        <Modal isOpen={isOpen} size="lg" toggle={props.toggle}>
            {isOpen ? <Wizard {...props} /> : null}
        </Modal>
    );
};
