import type * as React from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";

import type { App } from "shared/dist/types/apps";

import { Loading } from "@/components/Loading";
import { iframeCarSelector } from "@/config/settings";
import { useAPI, useLanguage } from "@/utils/hooks";

const getIframeUrl = (apps: App[], name: string): string | undefined =>
    (apps.find((app) => app.name === name) || {}).url || undefined;

type Props = {
    fullscreen: boolean;
    className?: string;
    isApp?: boolean;
};

export const IframeCarSelector = ({
    fullscreen,
    className,
    isApp,
}: Props): React.ReactElement => {
    const { app } = useParams<{ app: string }>();
    const lang = useLanguage(true);
    const { data: apps } = useAPI<App[]>("widget/carselector-apps");

    if (!apps) {
        return <Loading />;
    }

    const src = isApp ? getIframeUrl(apps, app) : iframeCarSelector[lang];
    const allClasses = classnames(
        "iframe-wrapper",
        "iframe-carselector",
        className,
        fullscreen && "fullscreen-iframe"
    );

    return (
        <div className={allClasses}>
            <iframe
                title="CarSelector"
                allow="microphone; camera"
                src={src}
                frameBorder="0"
            />
        </div>
    );
};
