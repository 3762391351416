import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { Badge, Button } from "reactstrap";

import { Loading } from "@/components/Loading";
import { NoResults } from "@/components/NoResults";
import { useAPI, useGenarts } from "@/utils/hooks";
import { getLogoURL } from "@/utils/utils";

import { ErrorMessage } from "../ErrorMessage";

import "./VehicleApplicability.css";

const { useState } = React;

type TypnrSuggestion = {
    Suggestion: string;
    typnr: number;
    vtype: "vl" | "pl";
    hmd_nr: number;
    make: string;
    model: string;
};

type Make = {
    name: string;
    logo: string;
    modelsCount: number;
    versionsCount: number;
};

type Params = {
    family: string;
    subFamily: string;
    itemId: string;
    categoryId: string;
};

type Props = {
    makeId: string;
    partId: string;
    toggle?: () => void;
    refOem?: string;
    itemId?: string | number;
};

type MakeObject = Record<
    string,
    { name: string; logo: string; keys: { [key: string]: number } }
>;

function computeMakes(data: TypnrSuggestion[]): Make[] {
    const makesObject: MakeObject = data.reduce(
        (makeAndModels: MakeObject, row) => {
            const { make, model, vtype } = row;
            if (!makeAndModels[make]) {
                makeAndModels[make] = {
                    name: make,
                    logo: getLogoURL(make, vtype),
                    keys: {},
                };
            }
            const key = `KEY - ${make} - ${model}`;
            const value = makeAndModels[make].keys[key] || 0;
            makeAndModels[make].keys[key] = value + 1;
            return makeAndModels;
        },
        {}
    );
    const makes = Object.values(makesObject).map((o) => ({
        name: o.name,
        logo: o.logo,
        modelsCount: Object.keys(o.keys).length,
        versionsCount: Object.keys(o.keys).reduce(
            (sum, key) => sum + o.keys[key],
            0
        ),
    }));
    return makes;
}

type VehicleBrandsProps = {
    models: TypnrSuggestion[];
    toggle?: () => void;
    itemId?: string | number;
};

const noop = () => {};

const VehicleBrands = (props: VehicleBrandsProps) => {
    const [display, setDisplay] = useState("makes");
    const [currentMake, setCurrentMake] = useState("");
    const params = useParams<Params>();
    const { genartsMap } = useGenarts();

    const setDisplayToMakes = (): void => setDisplay("makes");

    const { models, toggle = noop } = props;

    if (display === "makes") {
        const makes = computeMakes(models);
        return (
            <div className="vehicle-applicability main-brands">
                {makes.map((make) => (
                    <div
                        className="marque"
                        key={make.name}
                        onClick={(): void => {
                            setDisplay("rows");
                            setCurrentMake(make.name);
                        }}
                    >
                        <img
                            src={make.logo}
                            alt={make.name}
                            title={make.name}
                        />
                        <Badge color="caia">
                            {make.modelsCount} | {make.versionsCount}
                        </Badge>
                    </div>
                ))}
            </div>
        );
    }

    let family = params.family;
    let subFamily = params.subFamily;
    let categoryId = params.categoryId || -1;
    const itemId = params.itemId ?? props.itemId;
    if (itemId && (!family || !subFamily)) {
        const genart = genartsMap[Number(itemId)];
        family = genart.family;
        subFamily = genart.subFamily;
        categoryId = genart.categoryId;
    }
    const makeUrl = ({
        typnr,
        vtype,
        make,
        model,
        hmd_nr,
    }: TypnrSuggestion): string =>
        `/selection-vehicule/${vtype}/${make}/${model}/${hmd_nr}/${typnr}/parts/${family}/${subFamily}/${categoryId}/${itemId}`;

    return (
        <React.Fragment>
            <ul className="vehicle-applicability-list">
                {models
                    .filter((row) => row.make === currentMake)
                    .map((row) => (
                        <Link
                            key={row.typnr}
                            to={makeUrl(row)}
                            onClick={toggle}
                        >
                            <li>{row.Suggestion}</li>
                        </Link>
                    ))}
            </ul>
            <Button color="primary" onClick={setDisplayToMakes}>
                Retour
            </Button>
        </React.Fragment>
    );
};

export const VehicleApplicability = (props: Props): JSX.Element => {
    const { makeId, toggle, itemId } = props;

    const partId = encodeURIComponent(props.refOem || props.partId);
    const partType = props.refOem ? "oemPart" : "part";

    const { data: models, error } = useAPI<TypnrSuggestion[]>(
        `vehicle/find/${partType}/${makeId}/${partId}`
    );

    if (error) {
        return <ErrorMessage />;
    }

    if (!models) {
        return <Loading />;
    }

    if (models.length === 0) {
        return <NoResults />;
    }

    return <VehicleBrands models={models} toggle={toggle} itemId={itemId} />;
};
