import * as React from "react";
import { useTranslation } from "react-i18next";

import type { CompletionEntry } from "shared/dist/types/types";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { Autocomplete } from "@/components/Autocomplete";
import { DTC_IMAGE } from "@/config/settings";
import { emptyVehicleData } from "@/context/VehicleDataContext";
import { renderSuggestion as renderVehicleSuggestion } from "@/pages/CarSelector/CarSelectorSearch";
import { getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";
import { replaceIgnoreCase } from "@/utils/utils";

import { BarcodeReaderButton } from "./BarcodeScanner";
import { IdCarReaderButton } from "./QRCodeReader";

type VinCompletionEntry = CompletionEntry & { make: string };

const autocomplete =
    (lang: string) =>
    (input: string): Promise<(CompletionEntry | VinCompletionEntry)[]> =>
        getContent(
            `completion/${
                /^P/.test(input) ? "code/" + lang + "/" + input : "vin/" + input
            }`
        );

type Props = {
    updateVehicleData?: (data: VehicleData) => void;
    onVinSearch?: (vin: string) => void;
    onDTCSearch?: (label: string, dtc: string) => void;
};

const noop = () => {};

const showDTCToast = (dtc: string) => {
    toastr.info(dtc, {
        closeButton: true,
        autoClose: false,
        className: "toast-diag-dtc",
    });
};

const renderSuggestion = (
    suggestion: VinCompletionEntry | CompletionEntry,
    { query }: { query: string }
): JSX.Element => {
    if ("make" in suggestion) {
        return renderVehicleSuggestion(suggestion, { query });
    }
    return (
        <div className="d-flex align-items-center">
            <div className="img-app-small me-2">
                <img src={DTC_IMAGE} alt="" />
            </div>
            <div>
                <span
                    dangerouslySetInnerHTML={{
                        __html: replaceIgnoreCase(
                            suggestion.label,
                            query,
                            '<span class="autosuggest-query">' +
                                query +
                                "</span>"
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export const DiagSearch = (props: Props): JSX.Element => {
    const [currentValue, setCurrentValue] = React.useState("");
    const { t } = useTranslation();
    const lang = useLanguage();
    const formRef = React.useRef<HTMLFormElement>(null);

    const {
        onDTCSearch = noop,
        onVinSearch = noop,
        updateVehicleData = noop,
    } = props;

    const onChange = (
        _event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => setCurrentValue(newValue);

    const submit = (evt: React.FormEvent<HTMLFormElement>): void => {
        evt.preventDefault();
        const value = formRef.current?.querySelector("input")?.value;
        if (value && value.length === 17) {
            search(value);
        }
    };

    const search = (value: string) => {
        getContent<VehicleData | { error: string }>(`vin/${value}`)
            .then((data) => {
                if ("error" in data) {
                    throw new Error(data.error);
                }
                updateVehicleData(data);
                onVinSearch(value);
                const message = t("identification.toast.success", {
                    value,
                });
                const label = `${value} (${data.Marque} ${data.Modèle} ${
                    data.Version || ""
                } ${data.Énergie})`;
                toastr.info(label, {
                    closeButton: true,
                    autoClose: false,
                    className: "toast-diag-dtc",
                });
                toastr.success(message);
            })
            .catch((error) => {
                console.error(error);
                updateVehicleData(emptyVehicleData);
                const message = t("identification.toast.error", { value });
                toastr.error(message);
            });
    };

    const onSuggestionSelected = (
        _evt: unknown,
        { suggestion }: { suggestion: CompletionEntry }
    ): void => {
        const { label, value } = suggestion;
        if (value.startsWith("P")) {
            showDTCToast(label);
            onDTCSearch(label, value);
        } else {
            search(value);
        }
    };

    return (
        <>
            <form
                className="car-selector-search"
                onSubmit={submit}
                ref={formRef}
            >
                <Autocomplete
                    value={currentValue}
                    onChange={onChange}
                    getSuggestions={autocomplete(lang)}
                    onSuggestionSelected={onSuggestionSelected}
                    placeholder={t("diag.placeholder")}
                    renderSuggestion={renderSuggestion}
                />
            </form>
            <IdCarReaderButton cb={search} />
            <BarcodeReaderButton
                type="vin"
                onSubmit={search}
                title="VIN Scanner"
            />
        </>
    );
};
