import * as React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { faAccusoft } from "@fortawesome/free-brands-svg-icons";
import {
    faBuilding,
    faIndustry,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {
    differenceInMonths,
    differenceInYears,
    format,
    isEqual,
} from "date-fns";
import type { TFuncKey } from "i18next";

import type { UserAccess } from "shared/dist/types/auth";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";
import {
    getCO2Emissions,
    getCritAir,
    getDateDCG,
    getDateMec,
    getEuroNorm,
} from "shared/dist/utils/vehicleDataLabels";

import { i18n } from "@/config/i18n";
import { COLOR_MAP, IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useExpertDataMode } from "@/context/ExpertDataContext";
import { useVehicleData } from "@/context/VehicleDataContext";
import { setDefaultVehicleImage } from "@/pages/CarSelector/Albums";
import { fetchWithAuth, setCachedContent } from "@/utils/fetch";
import { showLegalInfos } from "@/utils/sirenSearch";
import { toastr } from "@/utils/toastr";
import { useOEMData } from "@/utils/useOEMData";
import { getKeys, getModelImage, isValidColor } from "@/utils/utils";

import { OEMDataRows } from "./OEMDataRows";
import { QRCode } from "./QRCode";

import "./VehicleData.css";

const { useState, useEffect, useRef } = React;

const getBackgroundColor = (
    vehicleColor: string | undefined
): React.CSSProperties => {
    if (!vehicleColor || vehicleColor.length === 0) {
        return { display: "none" };
    }
    const colorStyle = isValidColor(vehicleColor)
        ? { backgroundColor: COLOR_MAP[vehicleColor] }
        : { display: "none" };
    return colorStyle;
};

type VehicleDataLabels = {
    vin: string | undefined;
    vehicle: string;
    motorisation: string;
    gearbox: string;
    "drive-wheels": string;
    "car-body": string;
    "date-mec": string;
    "date-dcg": string;
    "cubic-capacity": string | undefined;
    cnit: string | undefined;
    "co2-index": string | undefined;
    FAP: string | undefined;
    PTR_PRF: string | undefined;
    PTR: string | undefined;
    "tare-weight": string | undefined;
    "nb-volume": string | undefined;
    width: string | undefined;
    length: string | undefined;
    height: string | undefined;
    empat: string | undefined;
    genre: string | undefined;
    "urban-consumption": string | undefined;
    "combined-consumption": string | undefined;
    "exurb-consumption": string | undefined;
    "vehicle-test": string;
    "co2-emissions": string;
    "euro-norm": string;
    critair: number | "";
    vinEC: string | undefined;
    vinCE: string | undefined;
    ktype: string | undefined;
    hmdnr: string | number | null | undefined;
    "mid-autodata": string | null | undefined;
    "id-vehiculo-kromeda": number | undefined;
};

export const getVehicleDataLabels = (data: VehicleData): VehicleDataLabels => {
    const { t } = i18n;
    const dateMec = getDateMec(data);
    const dateDcg = getDateDCG(data);
    const DATE_FORMAT = "dd/MM/yyyy";
    const now = Date.now();
    const ageMec = differenceInYears(now, dateMec);
    const ageDcg = differenceInYears(now, dateDcg);
    let ageDcgLabel = `${ageDcg} ${t("time.year", { count: ageDcg })}`;
    if (ageDcg === 0) {
        const ageDcgInMonths = differenceInMonths(now, dateDcg);
        ageDcgLabel = `${ageDcgInMonths} ${t("time.month", {
            count: ageDcgInMonths,
        })}`;
    }
    const ageMecLabel = `${ageMec} ${t("time.year", { count: ageMec })}`;
    if (ageMec === 0) {
        const ageMecInMonths = differenceInMonths(now, dateMec);
        ageDcgLabel = `${ageMecInMonths} ${t("time.month", {
            count: ageMecInMonths,
        })}`;
    }
    const isInvalidDate = isNaN(dateMec.getTime());
    const labelDateMec = isInvalidDate
        ? ""
        : `${format(dateMec, DATE_FORMAT)}#${ageMecLabel}`;
    const firstHandLabel = isInvalidDate
        ? ""
        : isEqual(dateMec, dateDcg)
        ? t("vehicle-data.first-hand")
        : t("vehicle-data.second-hand");
    const labelDateDcg = isInvalidDate
        ? ""
        : `${format(dateDcg, DATE_FORMAT)}#${firstHandLabel} | ${ageDcgLabel}`;
    const power = data.Puis_Kw || Math.round(Number(data.Puis_Ch) * 0.735);
    const co2Emissions = getCO2Emissions(Number(data.CO2));
    const euroNorm = getEuroNorm(dateMec);
    const vehicleTestLabel = isInvalidDate
        ? ""
        : ageMec >= 4
        ? t("vehicle-data.required")
        : t("vehicle-data.unrequired");
    const capacity = Math.ceil(Number(data.Cylindree) / 100) / 10;
    const motorisation = `${capacity || ""} ${data.Énergie || ""} ${
        data.Code_Moteur || ""
    } ${data.NB_Cylind ? data.NB_Cylind + "S" : ""}${
        data.Puis_Ch ? " de " + data.Puis_Ch + "ch" : ""
    } ${power ? power + "kw" : ""} ${
        data.Puis_Fisc ? data.Puis_Fisc + "cv" : ""
    } ${data.Turbo_Compr || ""}`;
    return {
        vin: data.Codif_Vin_PRF,
        vehicle: `${data.Marque || ""} ${data.Modèle || ""} ${
            data.Version || ""
        }`,
        motorisation,
        gearbox: `${data["Type boîte de vitesse"] || ""} ${
            data.NB_Vitesse || ""
        } ${t("vehicle-data.gears")} ${data.Mode_Inject || ""}`,
        "drive-wheels": `${data.Propulsion || ""}`,
        "car-body": ` ${data.Carrosserie || ""} ${
            data.NB_Portes
                ? `${data.NB_Portes} ${t("vehicle-data.doors")},`
                : ""
        } ${
            data.NB_PL_Ass
                ? `${data.NB_PL_Ass} ${t("vehicle-data.seats")},`
                : ""
        } ${data.Couleur_Vehic || ""}`,
        "date-mec": labelDateMec,
        "date-dcg": labelDateDcg,
        "cubic-capacity": data.Cylindree,
        cnit: data.Type,
        "co2-index": data.CO2,
        FAP: data.Depollution,
        PTR_PRF: data.PTR_PRF,
        PTR: data.PTR,
        "tare-weight": data.Poids_Vide,
        "nb-volume": data.NB_Volume,
        width: data.Largeur,
        length: data.Longueur,
        height: data.Hauteur,
        empat: data.Empat,
        genre: data.Genre_V,
        "urban-consumption": data.Cons_Urb,
        "combined-consumption": data.Cons_Mixte,
        "exurb-consumption": data.Cons_Exurb,
        "vehicle-test": vehicleTestLabel,
        "co2-emissions": co2Emissions,
        "euro-norm": euroNorm,
        critair: getCritAir(data.Énergie, dateMec),
        vinEC: data.vinEC,
        vinCE: data.vinCE,
        ktype: data.ktype,
        hmdnr: data.hmd_nr,
        "mid-autodata": data.Mid_Autodata,
        "id-vehiculo-kromeda": data.id_vehiculo_kromeda,
    };
};

const ROW_CLASSES = {
    "euro-norm": "vehicle-data-euro-norm",
    "co2-emissions": "vehicle-data-co2-emissions",
    "co2-index": "vehicle-data-co2-emissions vehicle-data-co2-index",
    critair: "critair",
    "vehicle-test": "vehicle-test",
};

const isRowClass = (key: string): key is keyof typeof ROW_CLASSES =>
    key in ROW_CLASSES;

const getClassName = (key: string, labels: VehicleDataLabels): string => {
    const classes = [];
    if (isRowClass(key)) {
        classes.push(ROW_CLASSES[key]);
    }
    if (key === "co2-emissions" || key === "co2-index") {
        classes.push(`co2-emissions-${labels["co2-emissions"]}`);
    }
    return classes.join(" ");
};

const renderLabel = (
    labels: VehicleDataLabels,
    key: keyof VehicleDataLabels,
    expertDataMode: boolean
): JSX.Element => {
    const label = labels[key];
    if (key === "co2-emissions") {
        return <span>{label}</span>;
    }
    if (key === "co2-index") {
        return (
            <>
                {label}
                <span>{labels["co2-emissions"]}</span>
            </>
        );
    }
    if (key === "critair" && label != null) {
        return (
            <>
                {expertDataMode && <span>{label}</span>}
                <img
                    alt={"critair-" + label}
                    className="critair-image"
                    src={`${IMAGES_SERVER}/EU/FR/vignette-critair-${label}.png`}
                />
            </>
        );
    }
    if (key !== "date-mec" && key !== "date-dcg") {
        return <>{label}</>;
    }
    const parts = String(label).split("#");
    return (
        <>
            <span>{parts[0]}</span>
            <span className="badge badge-primary">{parts[1]}</span>
        </>
    );
};

const RESTRICTED_LINES_ACCESS = {
    "co2-emissions": "CO2",
    "euro-norm": "EuroNorm",
    "vehicle-test": "VehicleTest",
    critair: "CritAir",
    vinEC: "vinEC",
    vinCE: "vinCE",
} as const;

const isRestricted = (
    key: string
): key is keyof typeof RESTRICTED_LINES_ACCESS =>
    key in RESTRICTED_LINES_ACCESS;

const lineByAccessRight =
    (hasAccess: (to: UserAccess) => boolean) =>
    (key: string): boolean => {
        const accessName = isRestricted(key)
            ? RESTRICTED_LINES_ACCESS[key]
            : "";
        return accessName ? hasAccess(accessName) : true;
    };

const updateVehicleCache = (data: unknown) => {
    const match = location.pathname.match(
        /^\/(?:identification|selection)-vehicule\/search\/([^/]+)\/([^/?#]+)/
    );
    if (!match) {
        console.warn("Unable to set vehicle cache (no match for current url)");
        return;
    }
    const [, field, value] = match;
    console.log(`Set cache for ${field} ${value}`);
    setCachedContent(`${field}/${value}`, data);
};

const VehicleDataRows = () => {
    const expertDataMode = useExpertDataMode();
    const { hasAccess } = useAuth();
    const { t } = useTranslation();

    const ktypeInput = useRef<HTMLInputElement>(null);
    const hmdnrInput = useRef<HTMLInputElement>(null);
    const midAutodataInput = useRef<HTMLInputElement>(null);
    const idKromedaInput = useRef<HTMLInputElement>(null);

    const [submitButtonDisabled, disableSubmitButton] = useState(false);
    const [data, updateVehicleData] = useVehicleData();

    useEffect(() => {
        if (
            hmdnrInput.current &&
            midAutodataInput.current &&
            idKromedaInput.current
        ) {
            hmdnrInput.current.value = String(data.hmd_nr ?? "");
            midAutodataInput.current.value = data.Mid_Autodata ?? "";
            idKromedaInput.current.value = String(
                data.id_vehiculo_kromeda ?? ""
            );
        }
    }, [data.hmd_nr, data.Mid_Autodata, data.id_vehiculo_kromeda]);

    const submitKtype = (): void => {
        const ktype = ktypeInput.current?.value;
        const vin = data.Codif_Vin_PRF;
        disableSubmitButton(true);
        fetchWithAuth(`addKtype/${vin}/${ktype}`)
            .then((res) => res.json())
            .then((results) => {
                disableSubmitButton(false);
                const newData = { ...data, ...results, ktype };
                updateVehicleData(newData);
                updateVehicleCache(newData);
                const message = i18n.t(
                    "vehicle-data.toast.update-ktype-success",
                    {
                        KTYPEnr: ktype,
                    }
                );
                toastr.success(message);
            })
            .catch((error) => {
                disableSubmitButton(false);
                toastr.error(i18n.t("vehicle-data.toast.update-ktype-error"));
                console.error(error);
            });
    };

    const submitData = (): void => {
        const displayKtypeForm =
            expertDataMode && Boolean(data.Codif_Vin_PRF) && !data.ktype;
        if (displayKtypeForm) {
            submitKtype();
            return;
        }
        const hmd_nr = Number(hmdnrInput.current?.value) || null;
        const mid = midAutodataInput.current?.value || null;
        const idKromeda = idKromedaInput.current?.value || null;
        const url = `addData/${data.ktype}/${hmd_nr}/${mid}/${idKromeda}`;
        disableSubmitButton(true);
        fetchWithAuth(url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(() => {
                const vehicleData = {
                    ...data,
                    hmd_nr,
                    Mid_Autodata: mid,
                    id_vehiculo_kromeda: Number(idKromeda),
                };
                updateVehicleData(vehicleData);
                updateVehicleCache(vehicleData);
                toastr.success(
                    i18n.t("vehicle-data.toast.update-data-success")
                );
            })
            .catch((error) => {
                toastr.error(i18n.t("vehicle-data.toast.update-data-error"));
                console.error(error);
            })
            .then(() => {
                disableSubmitButton(false);
            });
    };

    const labels = getVehicleDataLabels(data);
    const lines = getKeys(labels)
        .filter(lineByAccessRight(hasAccess))
        .map((key) => (
            <tr key={key}>
                <td>{t(("vehicle-data." + key) as TFuncKey) as string}</td>
                <td className={getClassName(key, labels)}>
                    {renderLabel(labels, key, expertDataMode)}
                </td>
            </tr>
        ));

    const displayKtypeForm =
        expertDataMode &&
        hasAccess("VehiculeLogique") &&
        Boolean(data.Codif_Vin_PRF) &&
        !data.ktype;
    const displayOtherIds =
        expertDataMode && hasAccess("VehiculeLogique") && !displayKtypeForm;
    if (displayKtypeForm || displayOtherIds || !hasAccess("VehiculeLogique")) {
        lines.pop(); // remove id vehiculo kromeda line
        lines.pop(); // remove mid autodata line
        lines.pop(); // remove hmdnr line
    }
    if (displayKtypeForm || !hasAccess("VehiculeLogique")) {
        lines.pop(); // remove ktype line
    }
    const submitButtonStyle = submitButtonDisabled ? { cursor: "wait" } : {};

    return (
        <>
            <table className="vehicle-data-rows">
                <tbody>
                    {lines}
                    {displayKtypeForm && (
                        <tr>
                            <td>{t("vehicle-data.ktype")}</td>
                            <td>
                                <input
                                    type="text"
                                    ref={ktypeInput}
                                    defaultValue={data.ktype}
                                />
                            </td>
                        </tr>
                    )}
                    {displayOtherIds && (
                        <>
                            <tr>
                                <td>{t("vehicle-data.hmdnr")}</td>
                                <td>
                                    <input
                                        type="number"
                                        ref={hmdnrInput}
                                        defaultValue={data.hmd_nr ?? ""}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{t("vehicle-data.mid-autodata")}</td>
                                <td>
                                    <input
                                        type="text"
                                        maxLength={20}
                                        ref={midAutodataInput}
                                        defaultValue={data.Mid_Autodata ?? ""}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{t("vehicle-data.id-vehiculo-kromeda")}</td>
                                <td>
                                    <input
                                        type="number"
                                        ref={idKromedaInput}
                                        defaultValue={data.id_vehiculo_kromeda}
                                    />
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
            {(displayKtypeForm || displayOtherIds) && (
                <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submitButtonDisabled}
                    onClick={submitData}
                    style={submitButtonStyle}
                >
                    {t("vehicle-data.update")}
                </button>
            )}
        </>
    );
};

const VehicleDataTabs = () => {
    const { data: oemData } = useOEMData("VinOEM");
    const [activeTab, setActiveTab] = useState("carplate");

    if (!oemData || oemData.error) {
        return <VehicleDataRows />;
    }

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: activeTab === "carplate",
                        })}
                        onClick={() => setActiveTab("carplate")}
                    >
                        Carplate
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: activeTab === "oem",
                        })}
                        onClick={() => setActiveTab("oem")}
                    >
                        OEM
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="carplate">
                    <VehicleDataRows />
                </TabPane>
                <TabPane tabId="oem">
                    <OEMDataRows data={oemData} />
                </TabPane>
            </TabContent>
        </>
    );
};

export const VehicleDataBloc = (): JSX.Element | null => {
    const [showQRCode, setShowQRCode] = useState(false);
    const toggleQRCode = () => setShowQRCode((show) => !show);
    const { hasAccess } = useAuth();
    const [data] = useVehicleData();

    const showLegalInfosToast = (): void => {
        if (data.Siren && data.Siren !== "000000000") {
            showLegalInfos(data.Siren);
        }
    };

    if (!data.Codif_Vin_PRF) {
        return null;
    }
    const vtype = data.ntypnr != null ? "pl" : "vl";
    const modelImage =
        data.Marque && data.Modèle
            ? getModelImage(data.Marque, data.Modèle)
            : `${IMAGES_SERVER}/brands/${vtype}/default.jpg`;

    const colorStyle = getBackgroundColor(data.Couleur_Vehic);
    let sirenIcon = faUser;
    let sirenLabel = "Personne physique propriétaire";
    if (data.Siren === "000000000") {
        sirenIcon = faIndustry;
        sirenLabel = "Véhicule de location";
    } else if (data.Siren) {
        sirenIcon = faBuilding;
        sirenLabel = `SIREN: ${data.Siren}`;
    }

    return (
        <div className="vehicle-data-wrapper">
            <div className="vehicle-data-header d-flex align-items-center">
                <div>
                    <img
                        className="mw-100"
                        src={modelImage}
                        alt=""
                        onError={setDefaultVehicleImage(vtype)}
                    />
                </div>
                <div
                    className="color-circle flex-shrink-0"
                    style={colorStyle}
                />
                <FontAwesomeIcon
                    className="siren-indicator"
                    icon={sirenIcon}
                    role="button"
                    size="2x"
                    title={sirenLabel}
                    onClick={showLegalInfosToast}
                />
                {hasAccess("QRCode") && (
                    <FontAwesomeIcon
                        className="ms-1"
                        icon={faAccusoft}
                        role="button"
                        onClick={toggleQRCode}
                    />
                )}
            </div>
            {showQRCode ? <QRCode className="m-3" /> : <VehicleDataTabs />}
        </div>
    );
};
