import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Vtype } from "shared/dist/types/vehicle";

import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { Loading } from "@/components/Loading";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { getContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { getModelImage } from "@/utils/utils";

import { Albums, Model } from "./Albums";

type ModelStat = { nb: number; model: string };

export const SelectModel = (): React.ReactElement => {
    const match = useRouteMatch<{
        brand: string;
        model: string;
        vtype: Vtype;
    }>();
    const { brand: brandName, vtype: vehicleType } = match.params;

    const [models, setModels] = React.useState<Model[]>([]);
    const [isLoading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const { hasAccess } = useAuth();
    const { data: stats } = useAPI<ModelStat[]>(
        hasAccess("statsBadges") ? `modelStats/${brandName}` : null
    );

    React.useEffect(() => {
        setLoading(true);
        const route = vehicleType === "pl" ? "plModels" : "models";
        getContent<{ label: string }[]>(`${route}/${brandName}`)
            .then((data) => {
                const currentModels = data.map((model) => ({
                    name: model.label,
                    image: getModelImage(brandName, model.label),
                    bigImage: `${IMAGES_SERVER}/photo_models/${brandName.toLowerCase()}_${model.label.toLowerCase()}_1.jpg`,
                }));
                setModels(currentModels);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [brandName, vehicleType]);

    const { model } = match.params;

    const mainBloc = isLoading ? (
        <Loading messageKey="carselector.loading-models" />
    ) : (
        [
            <h4 key="0">{t("carselector.models-title")}</h4>,
            <Albums
                key="1"
                models={models}
                brandName={brandName}
                modelName={model}
                stats={stats || []}
            />,
        ]
    );
    return (
        <div className="selection-bloc main-bloc select-model">
            <Breadcrumbs match={match}>
                {process.env.REACT_APP_SHOW_CARSELECTOR_BACKBUTTON && (
                    <Link to={`/selection-vehicule/${vehicleType}`}>
                        <FontAwesomeIcon
                            className="partselector-cross"
                            icon={faTimesCircle}
                        />
                    </Link>
                )}
            </Breadcrumbs>
            {mainBloc}
        </div>
    );
};
