import * as React from "react";
import { Button } from "reactstrap";
import { faAccusoft } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const QRCodeButton = ({ onClick }: { onClick(): void }): JSX.Element => {
    return (
        <Button className="qrcode-button" onClick={onClick}>
            <FontAwesomeIcon icon={faAccusoft} />
        </Button>
    );
};
