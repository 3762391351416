import * as React from "react";

import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";

export const CompanyCover = (): JSX.Element | null => {
    const { company } = useAuth();

    if (company?.video) {
        const url = `${IMAGES_SERVER}/userDocs/company/${company.video}`;
        return (
            <div className="company-cover company-cover-video d-flex">
                <video
                    controls
                    controlsList="nodownload"
                    muted
                    autoPlay
                    loop
                    src={url}
                    className="mw-100"
                />
            </div>
        );
    }

    if (company?.cover) {
        const url = `${IMAGES_SERVER}/userDocs/company/${company.cover}`;
        return (
            <div className="company-cover company-cover-img d-flex">
                <img src={url} alt={company.name} className="mw-100" />
            </div>
        );
    }

    return null;
};
