import * as React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import type { VehicleData } from "shared";

import type { TamaArticle } from "./Item";
import { hasCorrectDate, hasCorrectEngineCode } from "./ItemContainer";

const makeAttrLabel = (attrKey: string, attrValue: string) =>
    attrValue == null ? attrKey : `${attrKey}: ${attrValue}`;

/* If it starts with a digit, it's a KTYPE tab */
const isTAMATab = (tab: TamaArticle): boolean => !/^\d/.test(tab.id);

const hasDates = (article: TamaArticle) => {
    const dateFrom = article.discriminants?.["Date From"]?.value;
    const dateTo = article.discriminants?.["Date To"]?.value;
    return Boolean(dateFrom && dateTo);
};

const hasEngineCode = (article: TamaArticle) =>
    Boolean(article.discriminants?.["For Enginecode"]?.value);

type PartsTabDetailsProps = {
    article: TamaArticle;
    vehicleData: Partial<VehicleData>;
};

export const PartsTabDetails = ({
    article,
    vehicleData,
}: PartsTabDetailsProps): JSX.Element => {
    const { t } = useTranslation();
    const dateColor = hasCorrectDate(article, vehicleData)
        ? "success"
        : "warning";
    const engineColor = hasCorrectEngineCode(article, vehicleData.Code_Moteur)
        ? "success"
        : "warning";
    return (
        <div className="d-flex p-2">
            <div>
                <ul className="article-attributes">
                    {Object.keys(article.attributes || {}).map((attribute) => (
                        <li key={attribute}>
                            {makeAttrLabel(
                                attribute,
                                article.attributes[attribute]
                            )}
                        </li>
                    ))}
                </ul>
                <ul className="article-discriminants">
                    {Object.keys(article.discriminants || {}).map(
                        (discriminant) => (
                            <li key={discriminant}>
                                {makeAttrLabel(
                                    article.discriminants[discriminant]
                                        .translatedName,
                                    article.discriminants[discriminant]
                                        .translatedValue
                                )}
                            </li>
                        )
                    )}
                </ul>
            </div>
            {isTAMATab(article) && (
                <div className="ms-3">
                    {hasDates(article) && (
                        <Alert color={dateColor}>
                            <span>{t("item.toast.filter-date")}</span>
                        </Alert>
                    )}
                    {hasEngineCode(article) && (
                        <Alert color={engineColor}>
                            <span>{t("item.toast.filter-engine-code")}</span>
                        </Alert>
                    )}
                </div>
            )}
        </div>
    );
};
