import * as React from "react";
import { Link, Route } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { leftMenuState } from "@/globalState/atoms";
import { ToggleSidebarButton } from "@/pages/v3/ToggleSidebarButton";

import { TitleWithIcon } from "../TitleWithIcon";
import { TopIcon } from "../TopIcon";

import { HeaderRight } from "./HeaderRight";
import { IframeTitle } from "./IframeTitle";
import { SearchBar } from "./SearchBar";

import logoEyes from "@/img/logo_eyes.png";

const { Suspense } = React;

const MenuToggle = () => {
    const setLeftMenuState = useSetRecoilState(leftMenuState);
    const onClick = () => {
        setLeftMenuState((state) =>
            state === "collapsed" ? "expanded" : "collapsed"
        );
    };
    return <TopIcon onClick={onClick} />;
};

export const Header = (): JSX.Element | null => {
    return (
        <>
            <nav className="navbar-left">
                <MenuToggle />
                <Link className="caia-logo" to="/">
                    <img src={logoEyes} alt="C@iA" width="100" />
                </Link>
                <Suspense fallback={null}>
                    <TitleWithIcon />
                    <Route path="/identification-vehicule">
                        <SearchBar />
                    </Route>
                </Suspense>
                <ToggleSidebarButton className="text-white" />
            </nav>
            <Suspense fallback={<div className="title-iframe">…</div>}>
                <Route path="/(pieces|pieces-oe)">
                    <IframeTitle />
                </Route>
                <Route path="/selection-vehicule">
                    <IframeTitle />
                </Route>
            </Suspense>
            <HeaderRight />
        </>
    );
};
