import * as React from "react";
import classnames from "classnames";

import { Restricted } from "@/components/Restricted";
import { RSESearchBar } from "@/components/RSE/RSESearchBar";
import { SetInitialSlides } from "@/components/RSE/SetInitialSlides";
import { SlidesStack } from "@/components/SlidesStack";

import { PanelSmart } from "./PanelSmart";

export const Smart = (): JSX.Element => {
    const [visible, setVisible] = React.useState(true);
    const className = classnames("iframe-wrapper", { "d-none": !visible });
    const wrapperName = classnames("inner-content", { "d-none": !visible });
    return (
        <Restricted access="Smart">
            <RSESearchBar setVisible={setVisible} />
            <div className={wrapperName}>
                <PanelSmart setVisible={setVisible} />
            </div>
            <SetInitialSlides widget="smart-initial-slides" />
            <SlidesStack className={className} />
        </Restricted>
    );
};
