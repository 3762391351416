import * as React from "react";
import { useTranslation } from "react-i18next";

import type { OE } from "shared/dist/types/item";
import type { Genart, GenartAttribute } from "shared/dist/types/types";

import { getImgList } from "@/components/Item/ArticleTable";
import { EditableAttributes } from "@/components/Item/EditableAttributes";
import { HideShowButton } from "@/components/Item/HideShowButton";
import type { OEItem } from "@/components/Item/Item";
import { ItemCountries } from "@/components/Item/ItemCountries";
import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { PartImg } from "@/components/Item/PartImg";
import { PDFList } from "@/components/Item/PDFList";
import { VehicleApplicability } from "@/components/Item/VehicleApplicability";
import { ProductAlternative } from "@/components/ProductAlternative";
import { apiRootURL, IMAGES_SERVER } from "@/config/settings";
import { IdlpDataWithProvenance, useAvailability } from "@/utils/availability";

import { Availability } from "./Availability";
import { Barcode } from "./Barcode";
import { BrandLogo } from "./BrandLogo";
import { CartButtons } from "./CartButtons";
import { LocalPrice } from "./LocalPrice";
import { Price } from "./Price";
import { Widget } from "./Widget";
import { WorldMarketPrices } from "./WorldMarketPrices";

import "./FicheArticle.css";

const Description = React.lazy(() =>
    import("./Description").then((comp) => ({ default: comp.Description }))
);

const { useState } = React;

const getDefaultSupplier = (
    oe: OE,
    availability: IdlpDataWithProvenance[]
): string => {
    if (oe.itemType === "clone" || oe.from === "caia") {
        return "local";
    }
    if (oe.makeLabel === "REPARCAR") {
        return "Reparcar";
    }
    if (oe.label_brand === "GPA26") {
        return "GPA26";
    }
    if (availability.length === 0) {
        return "";
    }
    const selectedIndex = availability.findIndex(
        (data) => Number(data.disponibilite?.quantite) > 0
    );
    const index = selectedIndex === -1 ? 0 : selectedIndex;
    return availability[index].from;
};

const DEFAULT_IMG_URL = `${IMAGES_SERVER}/gif/DEFAULT.png`;
const setDefaultImage = (
    event: React.SyntheticEvent<HTMLImageElement>
): void => {
    event.currentTarget.src = DEFAULT_IMG_URL;
};

export type GenartFicheArticle = Genart & {
    id: number;
};

type Props = {
    oe: OEItem;
    similarItems: GenartFicheArticle[];
    relatedKits: GenartFicheArticle[];
    genartAttributes: GenartAttribute[];
    label: string;
    hmdnr: string | undefined;
    ktypnr: string | undefined;
};

export const FicheArticle = ({
    oe,
    similarItems,
    relatedKits,
    genartAttributes,
    label,
    hmdnr,
    ktypnr,
}: Props): JSX.Element => {
    const imgList = getImgList(oe);
    if (imgList.length === 0) {
        imgList.push(`${IMAGES_SERVER}/gif/DEFAULT.png`);
    }

    const { availability } = useAvailability(oe);
    const [editMode, setEditMode] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(
        getDefaultSupplier(oe, availability)
    );
    const { t } = useTranslation();

    const makeId = String(oe.id_fournisseur || oe.makeId);

    const { addIframeSlide, addPartsSearchSlide } = useAddItemSlide();

    const carContext =
        hmdnr && ktypnr ? `TecDOC: K-TYPEnr(${ktypnr}) HMDnr(${hmdnr})` : "";

    return (
        <div className="fiche-article">
            <div className="folderTab clearFix">
                <div className="breadCrumbs">
                    <span>Ref</span> &gt;
                    <span>{oe.id}</span> &gt;
                    <span>{carContext}</span>
                </div>
            </div>
            <div className="botBorder clearFix">
                <div className="article-details">
                    <div className="productImage">
                        <PartImg imgList={imgList} title={oe.id} big />
                        <ul className="imageList">
                            {imgList.slice(1, 3).map((url) => (
                                <li key={url}>
                                    <img
                                        src={url}
                                        alt=""
                                        width="92"
                                        height="92"
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="overview">
                        <h2>{label || oe.genArt}</h2>
                        <h1>
                            <span>{oe.id}</span>
                            <PDFList
                                medias={oe.mediaDtos}
                                onClick={addIframeSlide}
                            />
                        </h1>
                        <div className="d-flex justify-content-between">
                            <div>
                                <span className="rating">
                                    <img
                                        src={`${apiRootURL}/media/img/blue-stars.gif`}
                                        alt="stars"
                                    />
                                </span>
                                <Price oe={oe} supplier={selectedSupplier} />
                                <Availability
                                    oe={oe}
                                    supplier={selectedSupplier}
                                />
                            </div>
                            <BrandLogo oe={oe} />
                        </div>
                        <CartButtons
                            oe={oe}
                            supplier={selectedSupplier}
                            setSupplier={setSelectedSupplier}
                            ktypnr={ktypnr}
                        />
                        <Barcode ean={oe.ean} />
                    </div>
                    <React.Suspense
                        fallback={<div className="description">Loading…</div>}
                    >
                        <Description
                            editMode={editMode}
                            setEditMode={setEditMode}
                            oe={oe}
                        />
                    </React.Suspense>
                    <LocalPrice artNr={oe.id} DLNr={oe.makeId} />
                </div>
                <Widget name={t("fiche-article.criteria-tecdoc")}>
                    <EditableAttributes
                        attributes={oe.attributes}
                        genartAttributes={genartAttributes}
                        showSuggestions={editMode}
                        genartId={oe.genartId}
                        articleId={oe.articleId}
                        refId={oe.id}
                        ktypnr={ktypnr}
                    />
                </Widget>

                <Widget name={t("fiche-article.car-applicability")}>
                    <div className="d-flex">
                        <VehicleApplicability
                            makeId={makeId}
                            partId={oe.ArtNr || oe.id}
                            refOem={oe.ref_oem}
                            itemId={
                                oe.genartId ||
                                oe.itemId ||
                                oe.genart ||
                                oe.articleId
                            }
                        />
                        <HideShowButton
                            articleId={oe.articleId}
                            refId={oe.id}
                            genartId={oe.genartId}
                            ktypnr={ktypnr}
                        />
                    </div>
                </Widget>
                <Widget name={t("fiche-article.product-alternative-and-cross")}>
                    <ProductAlternative
                        oeList={oe.oeNrs || (oe.oes ? oe.oes.split(",") : [])}
                        criterias={oe.criterias}
                        onOEClick={addPartsSearchSlide}
                    />
                </Widget>

                <Widget name={t("fiche-article.similar-items")}>
                    {similarItems.map((genart) => (
                        <div className="product vtop soft" key={genart.id}>
                            <div className="smallBox">
                                <img
                                    src={`${IMAGES_SERVER}/gif/${genart.family}.png`}
                                    alt=""
                                    onError={setDefaultImage}
                                />
                            </div>
                            <span className="manuName">{genart.label}</span>
                            {/* <span className="prodNameIAM">IAM: XXXXXXXX</span>
                                    <span className="prodNameEAN">EAN: XXXXXXXXXXXX</span>
                                    <span className="prodPrice">IAM pcp : XXX.xx€</span> */}
                        </div>
                    ))}
                </Widget>

                <Widget name={t("fiche-article.related-kits")}>
                    {relatedKits.map((genart) => (
                        <div className="product vtop soft" key={genart.id}>
                            <div className="smallBox">
                                <img
                                    src={`${IMAGES_SERVER}/gif/${genart.family}.png`}
                                    alt=""
                                    onError={setDefaultImage}
                                />
                            </div>
                            <span className="manuName">{genart.label}</span>
                            {/* <span className="prodNameIAM">IAM: XXXXXXXX</span>
                                    <span className="prodNameEAN">EAN: XXXXXXXXXXXX</span>
                                    <span className="prodPrice">IAM pcp : XXX.xx€</span> */}
                        </div>
                    ))}
                </Widget>

                <ItemCountries artNr={oe.id} dlnr={oe.makeId} />

                <Widget name="World MarketPrices">
                    <WorldMarketPrices artNr={oe.id} DLNr={oe.makeId} />
                </Widget>
            </div>
        </div>
    );
};
