import * as React from "react";

import type { OE } from "shared/dist/types/item";

import { getBrandName } from "@/components/Item/ArticleTable";
import { usePartsBrands } from "@/utils/usePartsBrands";
import { getSupplierLogoURL } from "@/utils/utils";

export const BrandLogo = ({ oe }: { oe: OE }): JSX.Element => {
    const { partsBrands } = usePartsBrands();
    return (
        <a
            href={`/pieces/${getBrandName(partsBrands, oe)}`}
            className="marque"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={getSupplierLogoURL(oe.makeId)} alt={oe.makeId} />
        </a>
    );
};
