import * as React from "react";
import { createRoot } from "react-dom/client";

import "bootstrap/dist/css/bootstrap.css";

import { App } from "@/app/App";

import "./index.css";

const container = document.getElementById("root");
if (!container) {
    throw new Error("Element #root not found");
}
const root = createRoot(container);
root.render(<App />);
