import * as React from "react";

import { LazyIframe } from "@/components/LazyIframe";
import { useAuth } from "@/context/AuthContext";

import { CarouselContext } from "./CarouselContext";
import { useHubidlp } from "./HubIdlpContext";
import { iframeSlides } from "./settings";

interface Props {
    visible: boolean;
}

export const IframesCarSelector = ({
    visible: iframeVisible,
}: Props): JSX.Element => {
    const [carouselIndex] = React.useContext(CarouselContext);
    const { hasAccess } = useAuth();
    const { url: hubIdlpURL } = useHubidlp();

    const urls = hasAccess("HubIdlp") ? [hubIdlpURL] : iframeSlides;

    return (
        <>
            {urls.map((url, i) =>
                url ? (
                    <LazyIframe
                        key={url}
                        className={`iframe-slide iframe-slide-${i}`}
                        title={`iframe-slide-${i}`}
                        src={url}
                        visible={
                            iframeVisible &&
                            (hasAccess("HubIdlp") || carouselIndex === i)
                        }
                    />
                ) : null
            )}
        </>
    );
};
