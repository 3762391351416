import * as React from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "classnames";
import { useSetRecoilState } from "recoil";

import { useAuth } from "@/context/AuthContext";
import { useUserSettings } from "@/context/UserSettingsContext";
import { sidebarVisibleAtom } from "@/globalState/atoms";

export const ToggleSidebarButton = ({ className }: { className?: string }) => {
    const setVisible = useSetRecoilState(sidebarVisibleAtom);
    const { hasAccess } = useAuth();
    const userSettings = useUserSettings();

    const show =
        userSettings.toggleNav !== "hide" &&
        (hasAccess("toggleNav") || hasAccess("v3"));
    if (!show) return null;

    return (
        <button
            type="button"
            className={clsx("btn", className)}
            onClick={() => setVisible((v) => !v)}
        >
            <FontAwesomeIcon icon={faEye} />
        </button>
    );
};
