import * as React from "react";
import {
    ChonkyActions,
    FileActionHandler,
    FileArray,
    FileData,
    FullFileBrowser,
    setChonkyDefaults,
} from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import FileSaver from "file-saver";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { i18n } from "@/config/i18n";
import { postContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

const WRAPPER_STYLE: React.CSSProperties = { height: 300 };

const downloadFile = async (file: FileData) => {
    try {
        const filename = file.id;
        const response = await postContent("statFile", { filename });
        const blob = await response.blob();
        FileSaver.saveAs(blob, filename);
    } catch (error) {
        const message = i18n.t("common.error");
        toastr.error(message);
    }
};

const ROOT_FOLDER = { id: "ROOT_FOLDER", name: "Raw data", isDir: true };

const FileBrowserContent = ({ files }: { files: FileArray }) => {
    const [folderChain, setFolderChain] = React.useState<FileData[]>([
        { ...ROOT_FOLDER, children: files },
    ]);
    const currentFiles: FileArray =
        folderChain[folderChain.length - 1].children;
    const handleAction: FileActionHandler = (data) => {
        if (data.id !== ChonkyActions.OpenFiles.id) {
            return;
        }
        const file = data.payload.targetFile;
        if (!file) {
            return;
        }
        if (file.isDir) {
            const index = folderChain.findIndex((f) => f.id === file.id);
            if (index === -1) {
                setFolderChain((oldChain) => oldChain.concat(file));
            } else {
                setFolderChain(folderChain.slice(0, index + 1));
            }
        } else {
            downloadFile(file);
        }
    };
    return (
        <div className="" style={WRAPPER_STYLE}>
            <FullFileBrowser
                files={currentFiles}
                folderChain={folderChain}
                onFileAction={handleAction}
                disableDragAndDrop
            />
        </div>
    );
};

const FileLoader = () => {
    const { data, error } = useAPI<FileArray>("csvFiles");
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    return <FileBrowserContent files={data} />;
};

export const FileBrowser = (): JSX.Element => {
    return (
        <div className="file-browser no-print">
            <FileLoader />
        </div>
    );
};
