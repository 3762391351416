import * as React from "react";
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useParams,
} from "react-router-dom";
import { Nav, NavItem, TabContent, TabPane } from "reactstrap";
import {
    faExternalLinkAlt,
    faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import type { Vtype } from "shared/dist/types/vehicle";

import { AdnCatalog } from "@/components/AdnCatalog/AdnCatalog";
import {
    apiRootURL,
    DATABOX_IMG,
    iframes,
    IMAGES_SERVER,
    LEVAM_URL,
    PROXY_DATABOX_URL,
    PROXY_PORT,
    TABLETTE_IMG,
    WORKSHOP_PATH,
} from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useExpertDataMode } from "@/context/ExpertDataContext";
import { useUserSettings } from "@/context/UserSettingsContext";
import { useVehicleData } from "@/context/VehicleDataContext";
import { IframeCarSelector } from "@/pages/CarSelector/IframeCarSelector";
import { CartTable } from "@/pages/ShoppingCart/CartTable";
import { fetchWithAuth } from "@/utils/fetch";
import { useAPI, useGenarts, useLanguage } from "@/utils/hooks";
import { useOEMData } from "@/utils/useOEMData";

import { BlocWithAlbums } from "./BlocWithAlbums";
import { ErrorBoundary } from "./ErrorBoundary";
import { Lazy } from "./Lazy";
import { SelectPartFamily } from "./SelectPartFamily";
import { SelectPartSubFamily } from "./SelectPartSubFamily";
import { SivinCE } from "./SivinCE";

import autodata from "@/img/tab_icons/autodata.jpg";
import gtestimate from "@/img/tab_icons/gt-estimate.jpg";
import workshopdata from "@/img/tab_icons/haynes-pro.jpg";
import tecdocLogo from "@/img/tab_icons/tecdoc.jpg";
import tecrmiLogo from "@/img/tab_icons/tecrmi.jpg";

const { useEffect, useState } = React;

const IframeTecvrc = ({ url }: { url: string | null }): JSX.Element => {
    return (
        <div className="iframe-wrapper">
            {url ? <iframe src={url} title="tecvrc" frameBorder="0" /> : null}
        </div>
    );
};

const BlocSelectParts = (): JSX.Element => (
    <div className="with-short-bloc">
        <Route
            path="/(identification|selection)-vehicule/:brand/:model/:version/:ktype"
            exact
        >
            <SelectPartFamily />
        </Route>
        <Route path="/(identification|selection)-vehicule/:brand/:model/:version/:ktype/parts/:family">
            <SelectPartSubFamily />
        </Route>
    </div>
);

const getWorkshopDataURL = (
    vin: string | undefined,
    ktype: string | undefined,
    pathname: string,
    userId: number
): string => {
    const PROXY_WORKSHOPDATA_URL = process.env.REACT_APP_PROXY_WORKSHOPDATA_URL;
    const url = `${PROXY_WORKSHOPDATA_URL}/catia-${userId}/search.do?method=make&search=true&keyword=`;
    const workshopdataURL =
        vin && /^\/identification-vehicule/.test(pathname)
            ? `${url}${vin}&termType=5`
            : `${url}${ktype}&termType=2`;
    return workshopdataURL;
};

const RedirectGenartToParts = () => {
    const { field, value, genartId } = useParams<{
        field: string;
        value: string;
        genartId: string;
    }>();
    const { genartsMap } = useGenarts();
    const genart = genartsMap[Number(genartId)];
    if (!genart) {
        return null;
    }
    const { family, subFamily, categoryId } = genart;
    const url = `/identification-vehicule/search/${field}/${value}/parts/${family}/${subFamily}/${categoryId}/${genartId}`;
    return <Redirect to={url} />;
};

type Tab = {
    id: string;
    display: boolean;
    navItem: JSX.Element;
    link?: string;
    label?: string;
    image?: string;
    content: JSX.Element;
};

const TabHeader = ({
    id,
    activeTab,
    onClick,
    link,
    children,
}: {
    id: string;
    activeTab: string;
    onClick(): void;
    link?: string;
    children: React.ReactNode;
}) => (
    <NavItem className={`tab-${id.toLowerCase()}`}>
        <div
            className={classnames("nav-link", {
                active: activeTab === id,
            })}
            onClick={onClick}
        >
            {children}
            {link ? (
                <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="ms-2"
                >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
            ) : null}
        </div>
    </NavItem>
);

const OEMTabHeader = ({
    activeTab,
    onClick,
}: {
    activeTab: string;
    onClick(): void;
}) => {
    const { data: oemData } = useOEMData("OEM");
    const userSettings = useUserSettings();
    const { hasAccess } = useAuth();
    if (!hasAccess("OEM")) {
        return null;
    }
    if (userSettings.OEMTab === "hide" && (!oemData || oemData.error)) {
        return null;
    }
    return (
        <TabHeader id="oem" activeTab={activeTab} onClick={onClick}>
            <img src={`${IMAGES_SERVER}/logos/C@IA.png`} alt="" />
            <span>OEM</span>
        </TabHeader>
    );
};

const OEMTabPane = ({
    visible,
    vin,
}: {
    visible: boolean;
    vin: string | undefined;
}) => {
    const lang = useLanguage();

    const iframeURL = `${LEVAM_URL}?lang=${lang}${vin ? `&vin=${vin}` : ""}`;

    return (
        <TabPane tabId="oem">
            <ErrorBoundary>
                <Lazy visible={visible}>
                    <div className="iframe-wrapper bg-light">
                        <iframe src={iframeURL} title="oem" />
                    </div>
                </Lazy>
            </ErrorBoundary>
        </TabPane>
    );
};

type Props = {
    className?: string;
    albumMode: boolean;
    showIframe?: boolean;
};

export const TabsIdentVehicle = (props: Props): JSX.Element => {
    const [vehicleData] = useVehicleData();
    const { showIframe = false } = props;
    const { ktype, hmd_nr: hmdnr, ntypnr } = vehicleData;
    const typnr = ktype || ntypnr;

    const [autodataURL, setAutodataURL] = useState<string>();
    const { userInfo, hasAccess } = useAuth();
    const expertData = useExpertDataMode();
    let { vtype } = useParams<{ vtype: Vtype }>();
    if (!vtype) {
        vtype = ntypnr ? "pl" : "vl";
    }

    const hasKtype = ktype !== null && ktype !== undefined;

    const showTecRMI =
        hasAccess("TecRMI") && hasKtype && !["pl", "motos"].includes(vtype);
    const { data: tecrmi } = useAPI<{ id: string }>(
        showTecRMI ? "tecrmiId" : null
    );
    const tecrmiURL = tecrmi?.id
        ? `${iframes.tecrmi}/search.php?id=${tecrmi.id}&type=ktyp&val=${ktype}`
        : null;

    const updateAutodatURL = (): (() => void) => {
        if (!hasAccess("AutoData")) {
            return () => {};
        }
        let unmounted = false;
        fetchWithAuth("requestAutodataToken")
            .then((res) => res.text())
            .then((url) => {
                if (unmounted) {
                    return;
                }
                const mid = vehicleData.Mid_Autodata;
                const newAutodataURL = mid
                    ? `${url}&VehicleId=${mid}&VehicleIdType=MID`
                    : `${url}&VehicleId=${ktype}&VehicleIdType=TECDOC`;
                setAutodataURL(newAutodataURL);
            })
            .catch((error) => {
                console.error(error);
            });
        return (): void => {
            unmounted = true;
        };
    };

    useEffect(updateAutodatURL, [ktype, vehicleData.Mid_Autodata, hasAccess]);

    const location = useLocation();

    const { Codif_Vin_PRF: vin } = vehicleData;
    const gtestimateURL = `${process.env.REACT_APP_PROXY_SERVER_HOST}:${PROXY_PORT.GtEstimate}/gtp/Login.aspx?settings=fullscreen`;
    const workshopdataURL = getWorkshopDataURL(
        vin,
        ktype,
        location.pathname,
        userInfo.userId
    );
    const { protocol, host } = window.location;
    const tabletteURL = vin
        ? `${protocol}//${host}${WORKSHOP_PATH}/CarSelector/search/vin/${vin}?mode=tablette&vtype=${vtype}`
        : hasKtype
        ? `${protocol}//${host}${WORKSHOP_PATH}/CarSelector/search/ktype/${ktype}?mode=tablette&vtype=${vtype}`
        : "";

    const showWorkshopData =
        hasAccess("WorkshopData") && !["pl", "motos"].includes(vtype);

    const labelTecdoc = !expertData
        ? "TecDOC"
        : vtype === "pl"
        ? `TecDOC: N-TYPnr(${typnr})`
        : `TecDOC: K-TYPnr(${typnr}) HMDnr(${hmdnr})`;

    const labelTecRMI = expertData ? `TecRMI: KTYPEnr(${ktype})` : "TecRMI";

    const databoxUrl = `${PROXY_DATABOX_URL}?id=${userInfo.userId}${
        vin ? `&vin=${vin}` : ""
    }`;

    const tabs: ReadonlyArray<Tab> = [
        {
            id: "tecdoc",
            display: hasAccess("TecDOC"),
            navItem: (
                <>
                    <img src={tecdocLogo} alt="" />
                    <span>{labelTecdoc}</span>
                </>
            ),
            content: props.albumMode ? <BlocWithAlbums /> : <BlocSelectParts />,
        },
        {
            id: "sivinCE",
            display: hasAccess("SivinCETab"),
            navItem: (
                <>
                    <img src={`${IMAGES_SERVER}/logos/sivince.png`} alt="" />
                    <span>SivinCE</span>
                </>
            ),
            content: <SivinCE />,
        },
        {
            id: "tecrmi",
            display: showTecRMI && tecrmiURL != null,
            navItem: (
                <>
                    <img src={tecrmiLogo} alt="" />
                    <span>{labelTecRMI}</span>
                </>
            ),
            content: <IframeTecvrc url={tecrmiURL} />,
        },
        {
            id: "autodata",
            display: hasAccess("AutoData") && hasKtype && vtype !== "pl",
            navItem: (
                <>
                    <img src={autodata} alt="" />
                    <span>AutoData</span>
                </>
            ),
            link: autodataURL,
            content: (
                <div className="iframe-wrapper">
                    <iframe src={autodataURL} title="autodata" />
                </div>
            ),
        },
        {
            id: "workshopdata",
            display: showWorkshopData,
            navItem: (
                <>
                    <img src={workshopdata} alt="" />
                    <span>HaynesPro</span>
                </>
            ),
            link: workshopdataURL,
            content: (
                <div className="iframe-wrapper">
                    {(vin || ktype) && (
                        <iframe src={workshopdataURL} title="HaynesPro" />
                    )}
                </div>
            ),
        },
        {
            id: "gtestimate",
            display: hasAccess("GTEstimate"),
            navItem: (
                <>
                    <img src={gtestimate} alt="" />
                    <span>GTEstimage</span>
                </>
            ),
            link: gtestimateURL,
            content: (
                <div className="iframe-wrapper">
                    <iframe src={gtestimateURL} title="gtestimate" />
                </div>
            ),
        },
        {
            id: "tablette",
            display: hasAccess("Tablette"),
            label: "Tablette",
            image: TABLETTE_IMG,
            navItem: (
                <>
                    <img src={TABLETTE_IMG} alt="" />
                    <span>Tablette</span>
                </>
            ),
            link: tabletteURL,
            content: (
                <div className="iframe-wrapper">
                    <iframe src={tabletteURL} title="tablette" />
                </div>
            ),
        },
        {
            id: "adnCatalog",
            display: hasAccess("AdnCatalogTab") && hasKtype,
            navItem: (
                <>
                    <img
                        src={`${apiRootURL}/media/brands/90/C@IA.png`}
                        alt=""
                    />
                    <span>AdnCatalog</span>
                </>
            ),
            content: <AdnCatalog />,
        },
        {
            id: "iframe-carselector",
            display: showIframe,
            navItem: <FontAwesomeIcon icon="car" />,
            content: <IframeCarSelector fullscreen={false} />,
        },
        {
            id: "databox",
            display: hasAccess("Databox") && vtype !== "pl",
            navItem: (
                <>
                    <img src={DATABOX_IMG} alt="" />
                    <span>Databox</span>
                </>
            ),
            link: databoxUrl,
            content: (
                <div className="iframe-wrapper">
                    <iframe src={databoxUrl} title="databox" />
                </div>
            ),
        },
        {
            id: "cart",
            display: hasAccess("CartTab"),
            navItem: <FontAwesomeIcon icon={faShoppingCart} />,
            content: (
                <div className="d-flex flex-column flex-grow-1 bg-white p-3 overflow-hidden">
                    <CartTable />
                </div>
            ),
        },
    ];

    const visibleTabs = tabs.filter((tab) => tab.display);

    const initialTab = visibleTabs[0]?.id;
    const [activeTab, setActiveTab] = useState<string>(initialTab);

    useEffect(() => {
        if (typnr && hasAccess("TecDOC")) {
            setActiveTab("tecdoc");
        } else {
            setActiveTab(initialTab);
        }
    }, [hasAccess, initialTab, typnr]);

    return (
        <Switch>
            <Route path="/identification-vehicule/search/:field/:value/genart/:genartId">
                <RedirectGenartToParts />
            </Route>
            <Route>
                <div className={classnames("tabs", props.className)}>
                    <Nav tabs>
                        {visibleTabs.map((tab) => (
                            <TabHeader
                                key={tab.id}
                                id={tab.id}
                                activeTab={activeTab}
                                onClick={() => setActiveTab(tab.id)}
                                link={tab.link}
                            >
                                {tab.navItem}
                            </TabHeader>
                        ))}
                        <OEMTabHeader
                            activeTab={activeTab}
                            onClick={() => setActiveTab("oem")}
                        />
                    </Nav>
                    <TabContent
                        activeTab={activeTab}
                        className="overflow-hidden"
                    >
                        {visibleTabs.map((tab) => (
                            <TabPane
                                tabId={tab.id}
                                key={tab.id}
                                className="overflow-hidden"
                            >
                                <ErrorBoundary>
                                    <Lazy visible={activeTab === tab.id}>
                                        {tab.content}
                                    </Lazy>
                                </ErrorBoundary>
                            </TabPane>
                        ))}
                        <OEMTabPane visible={activeTab === "oem"} vin={vin} />
                    </TabContent>
                </div>
            </Route>
        </Switch>
    );
};
