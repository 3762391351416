import * as React from "react";
import { useLocation } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

import { IMAGES_SERVER } from "@/config/settings";
import { COMPANY_LOGO_PATH } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { postContent } from "@/utils/fetch";

import { ToggleComponent } from "./ToggleComponent";

type QRCodeProps = {
    size?: number;
    imageSize?: number;
    className?: string;
    pathname?: string;
};

export const QRCode = (props: QRCodeProps): JSX.Element | null => {
    const { size = 256, imageSize = 70, className } = props;
    const location = useLocation();
    const [timestamp, setTimestamp] = React.useState<number | null>(null);
    const [autologin, setAutologin] = React.useState(true);
    const toggleAutologin = () => setAutologin((s) => !s);

    const pathname = props.pathname || location.pathname;

    React.useEffect(() => {
        const now = Date.now();
        postContent("shortURL", { timestamp: now, pathname, autologin })
            .then(() => {
                setTimestamp(now);
            })
            .catch((error) => {
                console.error(error);
                setTimestamp(null);
            });
    }, [pathname, autologin]);

    const { origin } = window.location;
    const { userInfo, company } = useAuth();
    const url = `${origin}/s/${userInfo.userId}/${timestamp}`;

    const imageSettings:
        | Parameters<typeof QRCodeSVG>[0]["imageSettings"]
        | undefined = company
        ? {
              width: imageSize,
              height: imageSize,
              excavate: true,
              src: `${IMAGES_SERVER}/${COMPANY_LOGO_PATH}/${company.logo}`,
          }
        : undefined;

    if (timestamp == null) return null;

    return (
        <div className="qrcode d-flex flex-column align-items-center">
            <QRCodeSVG
                value={url}
                size={size}
                className={className}
                imageSettings={imageSettings}
                level="Q"
            />
            <ToggleComponent
                checked={autologin}
                onChange={toggleAutologin}
                className="mt-1"
            >
                <span>Autologin</span>
            </ToggleComponent>
        </div>
    );
};
