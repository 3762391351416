import * as React from "react";

import { useSlidesContext } from "@/components/SlidesStack";

const searchBarWasClicked = (event: MouseEvent) => {
    if (!(event.target instanceof Node)) return false;
    const searchbar = document.querySelector(".rse-searchbar");
    if (!searchbar) return false;
    return searchbar.contains(event.target);
};

const wpSearchBarWasClicked = (event: MouseEvent) => {
    if (!(event.target instanceof Node)) return false;
    const searchbar = document.querySelector(".wp-search-bar");
    if (!searchbar) return false;
    return searchbar.contains(event.target);
};

type Props = {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    href: string;
    className: string;
};

export const SlideVisibilityWrapper = ({
    setVisible,
    children,
    href,
    className,
}: Props): JSX.Element => {
    const container = React.useRef<HTMLDivElement>(null);
    const { slides } = useSlidesContext();
    const { length } = slides;
    React.useLayoutEffect(() => {
        const clickHandler = (event: MouseEvent) => {
            const rseBlocWasClicked =
                event.target instanceof Node &&
                container.current?.contains(event.target);
            if (
                !rseBlocWasClicked &&
                !searchBarWasClicked(event) &&
                !wpSearchBarWasClicked(event)
            ) {
                const rseLink = document.querySelector(
                    `.sidebar > a[href='${href}']`
                );
                const rseLinkWasClicked =
                    event.target instanceof Node &&
                    rseLink?.contains(event.target);
                setVisible((wasVisible) => {
                    if (rseLinkWasClicked) {
                        return true;
                    }
                    if (wasVisible) {
                        return length > 0;
                    }
                    return false;
                });
            }
        };
        window.addEventListener("click", clickHandler);
        return () => {
            window.removeEventListener("click", clickHandler);
        };
    }, [length, setVisible, href]);

    return (
        <div className={`main-bloc ${className}`} ref={container}>
            {children}
        </div>
    );
};
