import * as React from "react";
import classnames from "classnames";

import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";
import { getCritAir, getDateMec } from "shared/dist/utils/vehicleDataLabels";

import { apiRootURL, COLOR_MAP, IMAGES_SERVER } from "@/config/settings";
import { getContent } from "@/utils/fetch";
import { debounce, isValidColor } from "@/utils/utils";

import "./Photo360.css";

const IMG_STYLE: React.CSSProperties = {
    width: "100%",
    position: "static",
    marginBottom: 5,
};
const NB_OF_PHOTOS = 50;
export const PHOTOS_ROOT_URL = `${IMAGES_SERVER}/360/standard/`;

const mod = (n: number, m: number): number => ((n % m) + m) % m;

export const getPhotoPath = async (
    vehicleData: VehicleData,
    isPL = false
): Promise<string | undefined> => {
    const {
        NB_Portes: nbDoors = "4",
        Carrosserie = "INCONNUE",
        Carrosserie_CG,
        ktype,
        ntypnr,
    } = vehicleData;
    const typnr = ktype || ntypnr;
    const paths = typnr
        ? await getContent<{ filename: string }[]>(
              `photos360/${typnr}/${nbDoors}/${Number(isPL)}`
          )
        : undefined;
    const photosPath =
        paths && paths.length
            ? paths[0].filename.replace(/1.jpg$/, "")
            : undefined;
    if (photosPath) {
        return photosPath;
    }
    if (Carrosserie === "INCONNUE" && Carrosserie_CG !== "") {
        return `Katia/generique_INCONNUE_${Carrosserie_CG}_`;
    }
    if (["INCONNUE", "AUTRES", "AUTRES CARROSSERIE"].includes(Carrosserie)) {
        if (nbDoors === "2" || nbDoors === "3") {
            return "Katia/generique_2-3Portes_";
        }
        if (nbDoors === "4" || nbDoors === "5") {
            return "Katia/generique_4-5Portes_";
        }
    } else {
        const carrosserie = Carrosserie.replace(/\//g, "_");
        return `Katia/generique_${carrosserie}_`;
    }
};

type Props = {
    vehicleData: VehicleData;
    photosRootURL?: string;
    onChange?: (index: number) => void;
    showCritair?: boolean;
    showColorBanner?: boolean;
    isPL?: boolean;
};

export const Photo360 = (props: Props) => {
    const [photosPath, setPhotosPath] = React.useState<string>();

    const { vehicleData, isPL } = props;

    React.useEffect(() => {
        const loadPath = async () => {
            try {
                const path = await getPhotoPath(vehicleData, isPL);
                setPhotosPath(path);
            } catch (error) {
                console.error(error);
            }
        };
        loadPath();
    }, [isPL, vehicleData]);

    React.useEffect(() => {
        if (!photosPath) return;
        // Preload images
        for (let photoNb = 1; photoNb <= NB_OF_PHOTOS; photoNb++) {
            const img = new Image();
            img.src = `${props.photosRootURL}${photosPath}${photoNb}.jpg`;
        }
    }, [photosPath, props.photosRootURL]);

    if (photosPath) {
        return <Viewer360 {...props} photosPath={photosPath} />;
    }
    return (
        <img
            className="illustration"
            src={`${apiRootURL}/media/models/default.jpg`}
            alt=""
            style={IMG_STYLE}
        />
    );
};

const noop = () => {};

const Viewer360 = (props: Props & { photosPath: string }) => {
    const [photoNb, setPhotoNb] = React.useState(1);
    const startingPosition = React.useRef(0);
    const isDragging = React.useRef(false);
    const { onChange = noop } = props;

    const onPhotoChange = React.useMemo(
        () => debounce(onChange, 100),
        [onChange]
    );

    React.useEffect(() => {
        onPhotoChange(photoNb);
    }, [onPhotoChange, photoNb]);

    React.useEffect(() => {
        const onMove = (position: number) => {
            if (!isDragging.current) return;
            const diff = position - startingPosition.current;
            const DISTANCE_PER_VIEW = 20;
            const nb = Math.floor(diff / DISTANCE_PER_VIEW);
            if (nb !== 0) {
                startingPosition.current = position;
                setPhotoNb((oldPhotoNb) => {
                    // Starts at 1, and need to handle negative results
                    const newPhotoNb =
                        mod(oldPhotoNb + nb - 1, NB_OF_PHOTOS) + 1;
                    return newPhotoNb;
                });
            }
        };

        const onEnd = () => {
            document.body.classList.remove("dragging");
            isDragging.current = false;
        };

        const onMouseMove = (event: MouseEvent) => {
            onMove(event.pageX);
        };

        const onTouchMove = (event: TouchEvent) => {
            if (
                event.touches.length > 1 ||
                (event.type.toLowerCase() === "touchend" &&
                    event.touches.length > 0)
            ) {
                onEnd();
                return;
            }
            onMove(event.touches[0].pageX);
        };

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onEnd);
        document.addEventListener("touchmove", onTouchMove);
        document.addEventListener("touchend", onEnd);
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onEnd);
            document.removeEventListener("touchmove", onTouchMove);
            document.removeEventListener("touchend", onEnd);
        };
    }, []);

    const onMouseDown = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        event.preventDefault();
        startingPosition.current = event.pageX;
        document.body.classList.add("dragging");
        isDragging.current = true;
    };

    const onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        event.preventDefault();
        startingPosition.current = event.touches[0].pageX;
        document.body.classList.add("dragging");
        isDragging.current = true;
    };

    const {
        vehicleData,
        showCritair = true,
        showColorBanner = true,
        photosRootURL = PHOTOS_ROOT_URL,
        photosPath,
    } = props;

    const color = isValidColor(vehicleData.Couleur_Vehic)
        ? COLOR_MAP[vehicleData.Couleur_Vehic]
        : "black";

    const css = `
            .photo360 {
                --vehicleColor: ${color};
            }
        `;
    const imgSrc = `${photosRootURL}${photosPath}${photoNb}.jpg`;
    const critair =
        showCritair && getCritAir(vehicleData.Énergie, getDateMec(vehicleData));
    const critairImgSrc = `${IMAGES_SERVER}/EU/FR/vignette-critair-${critair}.png`;

    return (
        <div
            className={classnames("photo360", {
                "without-color-banner": !showColorBanner,
            })}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
        >
            <style>{css}</style>
            {showColorBanner && <div className="color-banner"></div>}
            <img src={imgSrc} alt="" />
            {critair && (
                <img
                    className="critair-image-360"
                    src={critairImgSrc}
                    alt={"critair-" + critair}
                />
            )}
        </div>
    );
};
