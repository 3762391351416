import * as React from "react";
import { useRouteMatch } from "react-router-dom";

import type {
    Branch,
    LevamNode,
    PartsLevam,
    TreeNodeRoot,
} from "shared/dist/types/levam";

import { IMAGES_SERVER } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import { useAPI, useLanguage } from "@/utils/hooks";

import { useAddItemSlide } from "./Item/ItemSlides";
import { ErrorMessage } from "./ErrorMessage";
import { NoResults } from "./NoResults";
import { Restricted } from "./Restricted";
import { SlidesStack } from "./SlidesStack";
import { StateBreadcrumbs } from "./StateBreadcrumbs";

export const LevamOEMTree = () => (
    <Restricted access="OEMFamily">
        <div className="selection-bloc main-bloc item-bloc oem-list d-flex flex-column">
            <OEMBreadcrumbs />
            <OEMTree />
            <SlidesStack className="oes-slider gui-selector-oe-results" />
        </div>
    </Restricted>
);

export const OEMTree = () => {
    const [vehicleData] = useVehicleData();
    const lang = useLanguage();
    const url = vehicleData.Codif_Vin_PRF
        ? `naiTree/${vehicleData.Codif_Vin_PRF}/${lang}`
        : null;
    const { data, error } = useAPI<TreeNodeRoot>(url);

    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return null;
    }
    if (data.error === "No results" || Object.keys(data.tree).length === 0) {
        return <NoResults />;
    }
    return (
        <ul className="overflow-auto mh-100 mb-0">
            {Object.keys(data.tree).map((key) => (
                <li key={key}>
                    <LevamBranch
                        branch={data.tree[key]}
                        ssd={data.client.ssd}
                        link={data.mod_link}
                    />
                </li>
            ))}
        </ul>
    );
};

const LevamBranch = ({
    branch,
    ssd,
    link,
}: {
    branch: Branch;
    ssd: string;
    link: string;
}) => {
    if ("nodes" in branch) {
        return (
            <div>
                <h6>{branch.name}</h6>
                <ul>
                    {branch.nodes.map((node) => (
                        <li key={node.node_id}>
                            <span>{node.node_name}</span>
                            <LevamParts node={node} ssd={ssd} link={link} />
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
    return (
        <div>
            <h6>{branch.name}</h6>
            <ul>
                {Object.keys(branch.branch).map((key) => (
                    <li key={key}>
                        <LevamBranch
                            branch={branch.branch[key]}
                            ssd={ssd}
                            link={link}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

const OEMBreadcrumbs = () => {
    const [vehicleData] = useVehicleData();
    const { Marque, Modèle } = vehicleData;
    const match = useRouteMatch();
    // Remove /oem from url
    const url = match.url.split("/").slice(0, -1).join("/");
    return (
        <StateBreadcrumbs
            brand={Marque}
            model={Modèle}
            image={`${IMAGES_SERVER}/logos/C@iA.png`}
            className="mt-n1"
            url={url}
        />
    );
};

const MARGIN_LEFT = { marginLeft: "8px" };

const LevamParts = ({
    link,
    node,
    ssd,
}: {
    node: LevamNode;
    link: string;
    ssd: string;
}) => {
    const lang = useLanguage();
    const url = `naiParts/${lang}/${ssd}/${link}/${encodeURIComponent(
        node.node_id
    )}`;
    const { data, error } = useAPI<PartsLevam>(url);
    const { addPartsSearchSlide } = useAddItemSlide();
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) return null;
    return (
        <ul>
            {data.parts.parts.map((part, i) => (
                <li key={part.standart.part_code + i}>
                    <span
                        className="oe-name"
                        onClick={() =>
                            addPartsSearchSlide(part.standart.part_code, "ref")
                        }
                    >
                        {part.standart.part_code}
                    </span>
                    <span style={MARGIN_LEFT}>
                        {part.standart.part_name.replaceAll("<br />", "\n")}
                    </span>
                </li>
            ))}
        </ul>
    );
};
