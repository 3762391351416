import type { Vtype } from "shared/dist/types/vehicle";

import { COLOR_MAP, HUB_IDLP_HOST, IMAGES_SERVER } from "@/config/settings";

export function debounce<R, F extends (...args: never[]) => R>(
    inner: F,
    ms = 0
): (...args: Parameters<F>) => Promise<R> {
    let timer: NodeJS.Timeout;

    return function (...args: Parameters<F>): Promise<R> {
        clearTimeout(timer);
        return new Promise((resolve) => {
            timer = setTimeout(() => resolve(inner(...args)), ms);
        });
    };
}

export const getLogoURL = (
    label: string | undefined,
    vehicleType: Vtype = "vl"
): string => {
    if (!label) return "";
    let filename = label.substring(0, 5).trim().toUpperCase();
    // Special case to differenciate Alpine from Alpina
    if (label.toUpperCase() === "ALPINE") filename = "ALPINE";
    const url = `${IMAGES_SERVER}/brands/${vehicleType}/${filename}.png`;
    return url;
};

export const getManufacturerLogoURL = (label: string): string => {
    if (!label) return "";
    let filename = label.substring(0, 5).trim().toUpperCase();
    // Special case to differenciate Alpine from Alpina
    if (label.toUpperCase() === "ALPINE") filename = "ALPINE";
    const url = `${IMAGES_SERVER}/logos/manufacturers/${filename}.png`;
    return url;
};

export const getModelImage = (brandName: string, modelName: string): string =>
    `${IMAGES_SERVER}/models/${brandName.toLowerCase()}/${modelName
        .split(" ")[0]
        .toUpperCase()}.jpg`;

export const getSupplierLogoURL = (makeId: number | string): string =>
    `${IMAGES_SERVER}/logos/${String(makeId).padStart(4, "0")}.JPG`;

export const replaceIgnoreCase = (
    string: string,
    strReplace: string,
    strWith: string
): string => {
    const esc = strReplace.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const reg = new RegExp(esc, "i");
    return string.replace(reg, strWith);
};

export const getKeys = <T extends Record<string, unknown>>(
    object: T
): Array<keyof T> => Object.keys(object) as Array<keyof T>;

export const makeHubidlpURL = (userId: number, search: string): string =>
    `${HUB_IDLP_HOST}/C@iA-${userId}/${search}`;

export const isDefined = <T,>(element: T): element is NonNullable<T> =>
    element != null;

// https://github.com/microsoft/TypeScript/issues/26255
export const isInArray = <Item, ArrayElement extends Item>(
    item: Item,
    array: ReadonlyArray<ArrayElement>
): item is ArrayElement => array.includes(item as ArrayElement);

export function isIn<P extends PropertyKey, K extends P>(
    target: Record<K, unknown>,
    property: P
): property is K {
    return property in target;
}

export const isObject = (value: unknown): value is Record<string, unknown> =>
    value !== null && typeof value === "object";

export const isValidColor = (
    color: string | undefined
): color is keyof typeof COLOR_MAP => color != undefined && color in COLOR_MAP;
