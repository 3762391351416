import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Alert } from "reactstrap";

import { Loading } from "@/components/Loading";
import { useIsLogged } from "@/context/AuthContext";
import { useAPI } from "@/utils/hooks";
import { CAIA_LOGO_URL } from "@/v2/settings";
import { UserPictureAndName } from "@/v2/UserPictureAndName";

import "./IframeViewer.css";

const EnvLogo = () => (
    <div className="env-logo">
        <Link to="/">
            <img src={CAIA_LOGO_URL} alt="" width={70} height={70} />
        </Link>
    </div>
);

const ErrorMessage = () => {
    const { t } = useTranslation();
    return <Alert color="danger">{t("common.error")}</Alert>;
};

const IframeLoader = () => {
    const { id } = useParams<{ id: string }>();
    const { data, error } = useAPI<{ url: string | null; target: string }>(
        `notifURL/${id}`
    );
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    if (!data.url || data.target !== "viewer") {
        return <Alert color="warning">Ce lien est invalide</Alert>;
    }
    return <iframe className="h-100 w-100" src={data.url} />;
};

const UserProfile = () => {
    const isLogged = useIsLogged();
    if (!isLogged) return null;
    return <UserPictureAndName reduced={false} />;
};

export const IframeViewer = (): JSX.Element => (
    <div className="iframe-viewer d-flex align-items-center justify-content-center h-100">
        <React.Suspense fallback={null}>
            <IframeLoader />
        </React.Suspense>
        <EnvLogo />
        <UserProfile />
    </div>
);
