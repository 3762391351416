import * as React from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { faSpinner, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { User } from "shared/dist/types/auth";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { i18n } from "@/config/i18n";
import { USE_KEYCLOAK } from "@/config/settings";
import { FetchError, postContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

const LoadAddUser = ({ toggle }: { toggle(): void }) => {
    const { data, error } = useAPI<User["companies"]>("users/companies");
    if (error) {
        return (
            <ModalBody>
                <ErrorMessage />
            </ModalBody>
        );
    }
    if (!data) {
        return (
            <ModalBody>
                <Loading />
            </ModalBody>
        );
    }
    return <AddUser companies={data} toggle={toggle} />;
};

type FormValues = {
    name: string;
    password: string;
    email: string;
    companyId: string;
};

const AddUser = ({
    companies,
    toggle,
}: {
    companies: User["companies"];
    toggle(): void;
}) => {
    const { register, handleSubmit } = useForm<FormValues>();
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const { ref: refName, ...restName } = register("name");
    const { ref: refPassword, ...restPassword } = register("password");
    const { ref: refEmail, ...restEmail } = register("email");
    const { ref: refCompany, ...restCompany } = register("companyId");

    const submit = async (data: FormValues) => {
        setIsSubmitting(true);
        try {
            await postContent("users/add", {
                user: data,
                useKeycloak: USE_KEYCLOAK,
            });
            toastr.success("L'utilisateur a été créé");
            toggle();
        } catch (error) {
            if (error instanceof FetchError && error.status === 409) {
                toastr.warning(
                    "Un utilisateur avec ce nom ou email existe déjà"
                );
                return;
            }
            toastr.error(i18n.t("common.error"));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                <FormGroup row>
                    <Label sm={3}>Nom</Label>
                    <Col sm={9}>
                        <Input
                            type="text"
                            {...restName}
                            innerRef={refName}
                            maxLength={100}
                            required
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>Mot de passe</Label>
                    <Col sm={9}>
                        <Input
                            type="password"
                            {...restPassword}
                            innerRef={refPassword}
                            required
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>email</Label>
                    <Col sm={9}>
                        <Input
                            type="email"
                            {...restEmail}
                            innerRef={refEmail}
                            maxLength={100}
                            required
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3} htmlFor="selectUserCompany">
                        Société
                    </Label>
                    <Col sm={9}>
                        <Input
                            type="select"
                            id="selectUserCompany"
                            {...restCompany}
                            innerRef={refCompany}
                        >
                            {companies.map((company) => (
                                <option key={company.id} value={company.id}>
                                    {company.name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                    {isSubmitting && (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="me-2"
                        />
                    )}
                    Valider
                </Button>
            </ModalFooter>
        </Form>
    );
};

export const AddUserButton = () => {
    const [isOpen, toggle] = React.useReducer((s) => !s, false);
    return (
        <>
            <button
                type="button"
                onClick={toggle}
                className="ms-2 btn btn-outline-secondary"
                title="Ajouter un nouvel utilisateur"
            >
                <FontAwesomeIcon icon={faUserPlus} />
            </button>
            <Modal isOpen={isOpen} size="xl" toggle={toggle}>
                <ModalHeader toggle={toggle}>Nouvel utilisateur</ModalHeader>
                <LoadAddUser toggle={toggle} />
            </Modal>
        </>
    );
};
