import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { Badge } from "reactstrap";
import classnames from "classnames";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { AcRecharge } from "@/components/Item/AppOEM/AcRecharge";
import { BatteryReplacement } from "@/components/Item/AppOEM/BatteryReplacement";
import { ServiceBrakes } from "@/components/Item/AppOEM/ServiceBrakes";
import { Lazy } from "@/components/Lazy";
import { DTC_IMAGE, IMAGES_SERVER } from "@/config/settings";
import { DTCSearchContent } from "@/pages/CarSelector/DTCSearch";

type AppOEMAutodata = "ServiceBrakes" | "AcRecharge" | "BatteryReplacement";
type AppOEM = AppOEMAutodata | "DTCSearch";

type AppButtonProps = {
    name: AppOEM;
    isSelected: boolean;
    onClick(app: AppOEM): void;
    img: string;
    children?: React.ReactNode;
};
const AppButton = ({
    name,
    isSelected,
    onClick,
    img,
    children,
}: AppButtonProps) => (
    <div
        className={classnames("marque", isSelected && "tooltip-arrow")}
        onClick={() => onClick(name)}
    >
        <img src={img} alt={name} />
        {children}
    </div>
);

type AutodataAppProps = {
    name: AppOEMAutodata;
    isSelected: boolean;
    onClick(app: AppOEMAutodata): void;
};
const AutodataApp = (props: AutodataAppProps) => (
    <AppButton
        {...props}
        img={`${IMAGES_SERVER}/logos/appOEM/${props.name}.png`}
    >
        <Badge color="secondary">Autodata</Badge>
    </AppButton>
);

type Props = {
    mid: string;
};

export const AppOEMs = ({ mid }: Props): JSX.Element => {
    const [selectedApp, setSelectedApp] = React.useState<AppOEM | null>(null);
    const handleAppClick = (app: AppOEM) => {
        setSelectedApp((currentApp) => (currentApp === app ? null : app));
    };
    return (
        <div className="app-oem-block d-flex flex-column flex-grow-1 mt-0 mb-4">
            <div className="apps flex-grow-1 d-inline-flex">
                <div className="d-flex flex-grow-1 justify-content-evenly align-items-baseline">
                    <AppButton
                        name="DTCSearch"
                        isSelected={selectedApp === "DTCSearch"}
                        onClick={handleAppClick}
                        img={DTC_IMAGE}
                    >
                        <Badge color="secondary">𝑥-DTC</Badge>
                    </AppButton>
                    <AutodataApp
                        name="ServiceBrakes"
                        isSelected={selectedApp === "ServiceBrakes"}
                        onClick={handleAppClick}
                    />
                    <AutodataApp
                        name="AcRecharge"
                        isSelected={selectedApp === "AcRecharge"}
                        onClick={handleAppClick}
                    />
                    <AutodataApp
                        name="BatteryReplacement"
                        isSelected={selectedApp === "BatteryReplacement"}
                        onClick={handleAppClick}
                    />
                </div>
            </div>
            <CSSTransition
                in={selectedApp !== null}
                classNames="app-oem-details"
                timeout={300}
                unmountOnExit
                onExited={() => setSelectedApp(null)}
            >
                <div
                    className={classnames(
                        "app-oem-details position-relative overflow-visible",
                        selectedApp === "DTCSearch" && "flex-column"
                    )}
                >
                    <ErrorBoundary>
                        <Lazy visible={selectedApp === "DTCSearch"}>
                            <DTCSearchContent
                                mid={mid}
                                buttonLabel="Synchro DEAL"
                                hide={selectedApp !== "DTCSearch"}
                            />
                            <img
                                className={classnames(
                                    "illustration",
                                    selectedApp !== "DTCSearch" && "d-none"
                                )}
                                src={DTC_IMAGE}
                                alt=""
                            />
                        </Lazy>
                        <Lazy visible={selectedApp === "ServiceBrakes"}>
                            <ServiceBrakes
                                mid={mid}
                                hide={selectedApp !== "ServiceBrakes"}
                            />
                        </Lazy>
                        <Lazy visible={selectedApp === "AcRecharge"}>
                            <AcRecharge
                                mid={mid}
                                hide={selectedApp !== "AcRecharge"}
                            />
                        </Lazy>
                        <Lazy visible={selectedApp === "BatteryReplacement"}>
                            <BatteryReplacement
                                mid={mid}
                                hide={selectedApp !== "BatteryReplacement"}
                            />
                        </Lazy>
                    </ErrorBoundary>
                </div>
            </CSSTransition>
        </div>
    );
};
