import * as React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/context/AuthContext";
import { ExpertDataContext } from "@/context/ExpertDataContext";

import "./ExpertDataSwitch.css";

export const ExpertDataSwitch = (): JSX.Element | null => {
    const { t } = useTranslation();
    const { hasAccess } = useAuth();
    const [expertDataMode, setExpertDataMode] =
        React.useContext(ExpertDataContext);
    const handleInputChange = () => setExpertDataMode((mode) => !mode);
    return hasAccess("expertData") ? (
        <div className="expert-data-switch">
            <input
                type="checkbox"
                id="expert-data-button"
                onChange={handleInputChange}
            />
            <label htmlFor="expert-data-button">
                <span>
                    {expertDataMode
                        ? t("header.expert-data-on")
                        : t("header.expert-data-off")}
                </span>
                <i />
                <div>{t("header.expert-data")}</div>
            </label>
        </div>
    ) : null;
};
