import * as React from "react";
import { useForm } from "react-hook-form";

import { ErrorMessage } from "@/components/ErrorMessage";
import { i18n } from "@/config/i18n";
import { useAuth } from "@/context/AuthContext";
import { FetchError, postContent } from "@/utils/fetch";
import { useAPI, useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import "./Home.css";

const CGV = () => {
    const lang = useLanguage();
    const { data, error } = useAPI<{ url: string }>(`cgv/${lang}`);
    const label = "Condition Générales de vente";
    if (error) {
        console.error(error);
        return <span>{label}</span>;
    }
    if (data) {
        return (
            <a className="cgv" href={data.url} target="_blank" rel="noreferrer">
                {label}
            </a>
        );
    }
    return <span>{label}</span>;
};

const Required = () => <span className="required-field">*</span>;

type IFormData = {
    accept: boolean;
    storeName: string;
    adress1: string;
    adress2: string;
    country: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
};
const VendorRegistration = (props: { onCancel(): void; onSuccess(): void }) => {
    const { userInfo } = useAuth();
    const { register, handleSubmit } = useForm<IFormData>({
        defaultValues: {
            accept: false,
            storeName: "",
            adress1: "",
            adress2: "",
            country: "",
            city: "",
            postalCode: "",
            phoneNumber: "",
        },
    });
    const submit = async (data: IFormData) => {
        console.log(data);
        if (!data.accept) {
            toastr.info(
                "Vous devez accepter les conditions générales de vente pour continuer."
            );
            return;
        }
        try {
            await postContent("vendor/register", data);
            props.onSuccess();
        } catch (error) {
            if (error instanceof FetchError && error.status === 409) {
                toastr.warn(
                    "Ce magasin existe déjà, veuillez choisir un autre nom"
                );
                return;
            }
            toastr.error(i18n.t("common.error"));
        }
    };
    return (
        <form
            onSubmit={handleSubmit(submit)}
            className="vendor-registration-form w-75 mx-auto"
        >
            <h2 className="mb-4">Inscription du vendeur</h2>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">Nom</label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        disabled
                        value={userInfo.name}
                    />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">Email</label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        disabled
                        value={userInfo.email}
                    />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">
                    Nom du magasin <Required />
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        maxLength={50}
                        {...register("storeName", { required: true })}
                    />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">
                    Adresse 1 <Required />
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        {...register("adress1", { required: true })}
                    />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">Adresse 2</label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        {...register("adress2")}
                    />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">
                    Pays <Required />
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        {...register("country", { required: true })}
                    />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">Ville</label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        {...register("city")}
                    />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">
                    Code Postal <Required />{" "}
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        {...register("postalCode", { required: true })}
                    />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-sm-4 col-form-label">
                    Téléphone de l&rsquo;entreprise <Required />
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        {...register("phoneNumber", { required: true })}
                    />
                </div>
            </div>

            <label className="cgv-row my-3">
                <input type="checkbox" {...register("accept")} />
                <span className="ms-2 me-1">Accepter les</span>
                <CGV />
            </label>
            <div className="text-right">
                <button className="btn btn-primary me-2">Confirmer</button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={props.onCancel}
                >
                    Annuler
                </button>
            </div>
        </form>
    );
};

const WaitingForApproval = () => {
    const { userInfo } = useAuth();
    return (
        <div className="waiting-for-approval">
            <p>Bonjour {userInfo.name}.</p>
            <p>
                Votre demande de création de compte vendeur a été soumise avec
                succès.
            </p>
            <p>Votre demande est en cours de vérification.</p>
        </div>
    );
};

const MyStore = () => {
    const { data } = useAPI<{ storeName: string }>("vendor/store");
    if (data) {
        return <p>Mon magasin: {data.storeName}</p>;
    }
    return null;
};

const VendorAccount = () => {
    return (
        <div className="vendor-account">
            <h2>Gérer mon compte vendeur</h2>
            <MyStore />
            <button className="btn btn-primary" disabled>
                Définir mon catalogue
            </button>
        </div>
    );
};

type VendorStatus = "new" | "approuved" | "waiting-for-approval";
type Status = "loading" | "error" | "vendor" | VendorStatus;

export const Home = (): JSX.Element => {
    const { data, error } = useAPI<{ status: VendorStatus }>("vendor/status");
    const status = error ? "error" : data ? data.status : "loading";
    const [state, setState] = React.useState<Status>(status);
    const { userInfo } = useAuth();
    React.useEffect(() => {
        setState(status);
    }, [status]);

    return (
        <div className="main-bloc w-100 h-100 me-3 d-flex">
            {state === "error" && <ErrorMessage />}
            {state === "new" && (
                <div className="mx-auto" style={{ width: 300 }}>
                    <p>Bonjour {userInfo.name}.</p>
                    <button
                        className="btn btn-primary"
                        onClick={() => setState("vendor")}
                    >
                        Devenir vendeur
                    </button>
                </div>
            )}
            {state === "vendor" && (
                <VendorRegistration
                    onCancel={() => setState("new")}
                    onSuccess={() => setState("waiting-for-approval")}
                />
            )}
            {state === "waiting-for-approval" && <WaitingForApproval />}
            {state === "approuved" && <VendorAccount />}
        </div>
    );
};
