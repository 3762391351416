import * as React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LanguageSelector } from "@/components/LanguageSelector";
import { ProfilePicture } from "@/components/ProfilePicture";
import { UserNameBloc, UserProfileBloc } from "@/components/UserProfileBloc";
import { useAuth } from "@/context/AuthContext";
import { CartContext } from "@/context/CartItems";

import { ToggleSidebarButton } from "./ToggleSidebarButton";

const CartButton = (): JSX.Element => {
    const { cart } = React.useContext(CartContext);
    return (
        <Link to="/cart" className="cart-link" rel="noreferrer">
            <FontAwesomeIcon icon={faShoppingCart} />
            {cart.length > 0 && <Badge pill>{cart.length}</Badge>}
        </Link>
    );
};

type Props = {
    reduced: boolean;
};

export const UserPictureAndName = ({ reduced }: Props): JSX.Element => {
    const { userInfo } = useAuth();

    return (
        <UserProfileBloc reduced={reduced}>
            <ProfilePicture />
            <UserNameBloc>
                <span className="ms-4 me-2">{userInfo.name}</span>
                <CartButton />
                <ToggleSidebarButton />
                <LanguageSelector className="me-2" />
            </UserNameBloc>
        </UserProfileBloc>
    );
};
