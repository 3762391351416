import * as React from "react";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SlidesStack } from "@/components/SlidesStack";
import { DOCUMENT_TITLE } from "@/config/settings";
import { CarouselContext } from "@/v2/CarouselContext";

import { CAIA_LOGO_URL, iframeSlides } from "../settings";

import { CarSelectorSwiper } from "./CarSelectorSwiper";

import "../style.css";
import "../style-item.css";
import "../style-slider.css";
import "../v2-style.css";

interface Props {
    setIframeVisible: React.Dispatch<React.SetStateAction<boolean>>;
    reduced: boolean;
    toggleReduced(): void;
}

const CarSelector = ({
    setIframeVisible,
    reduced,
    toggleReduced,
}: Props): JSX.Element => {
    const [carouselIndex] = React.useContext(CarouselContext);

    const toggleIframes = (): void => setIframeVisible((v) => !v);

    React.useEffect(() => {
        document.title = DOCUMENT_TITLE + "- Sélectionner un véhicule";
    }, []);

    React.useEffect(() => {
        setIframeVisible(true);
    }, [setIframeVisible]);

    if (reduced) {
        return (
            <>
                <div
                    className="blog-slider__img brand-toggle-active"
                    onClick={toggleReduced}
                >
                    <img src={CAIA_LOGO_URL} alt="C@iA" />
                </div>
                <button
                    type="button"
                    className={`iframe-toggle-button ${
                        iframeSlides[carouselIndex] ? "" : "hidden"
                    }`}
                    onClick={toggleIframes}
                >
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
            </>
        );
    }

    return (
        <>
            <CarSelectorSwiper onLogoClick={toggleReduced} />
            <button
                type="button"
                className={`iframe-toggle-button ${
                    iframeSlides[carouselIndex] ? "" : "hidden"
                }`}
                onClick={toggleIframes}
            >
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
            <SlidesStack className="oes-slider" />
        </>
    );
};

export default CarSelector;
