import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { CustomSearch } from "shared/dist/types/userSettings";

import { IMAGES_SERVER } from "@/config/settings";
import { useAPI, useGenarts } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { FamilyAlbum } from "./FamilyAlbum";

const MARGIN_RIGHT_5 = { marginRight: 5 };
export const DEFAULT_IMG_URL = `${IMAGES_SERVER}/gif/DEFAULT.png`;
export const CUSTOM_SEARCH_IMG_PATH = `${IMAGES_SERVER}/userDocs/customSearch/`;

type Props = {
    selected: string;
};

export const CustomSearches = ({ selected }: Props): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch<{ vtype: string; 0: string }>();
    const { genartsMap } = useGenarts();
    const { data: customSearches, error } = useAPI<CustomSearch[]>(
        "custom-searches",
        { initialData: [] }
    );
    const { register, handleSubmit } = useForm();

    if (error) {
        toastr.error(t("albums.error"));
    }

    const submit = (values: Record<string, boolean>): void => {
        const genartIds = Object.keys(values).filter((key) => values[key]);
        if (genartIds.length === 0) {
            return;
        }
        const url = `${match.url}/search/${selected}/${genartIds.join("-")}/${
            genartIds[0]
        }`;
        history.push(url);
    };

    return (
        <>
            {customSearches.map((search, i) => (
                <FamilyAlbum
                    key={i}
                    isSelected={selected === search.name}
                    name={
                        <>
                            <FontAwesomeIcon
                                icon={faSearchPlus}
                                style={MARGIN_RIGHT_5}
                            />
                            {search.name}
                        </>
                    }
                    path={`/custom-search/${search.name}`}
                    imgURL={
                        search.image
                            ? CUSTOM_SEARCH_IMG_PATH + search.image
                            : DEFAULT_IMG_URL
                    }
                    detailsClassName="custom-search"
                >
                    <form
                        className="d-flex flex-grow-1 justify-content-between"
                        onSubmit={handleSubmit(submit)}
                    >
                        <ul className="details-list">
                            {search.genartIds.split("-").map((genartId) => (
                                <li key={genartId}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            defaultChecked
                                            {...register(genartId)}
                                        />{" "}
                                        <span>
                                            {
                                                genartsMap[Number(genartId)]
                                                    ?.label
                                            }
                                        </span>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <div className="align-self-end">
                            <Button color="primary">
                                {t("common.submit")}
                            </Button>
                        </div>
                    </form>
                </FamilyAlbum>
            ))}
        </>
    );
};
