import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import {
    identificationVehiculePageAtom,
    piecesPageAtom,
    selectionVehiculePageAtom,
    socialPageAtom,
} from "@/globalState/atoms";

const usePersistPages = () => {
    const { pathname } = useLocation();
    const setIdentificationVehiculePage = useSetRecoilState(
        identificationVehiculePageAtom
    );
    const setSelectionVehiculePage = useSetRecoilState(
        selectionVehiculePageAtom
    );
    const setPiecesPage = useSetRecoilState(piecesPageAtom);
    const setSocialPage = useSetRecoilState(socialPageAtom);

    React.useEffect(() => {
        if (pathname.startsWith("/identification-vehicule")) {
            setIdentificationVehiculePage(pathname);
        } else if (pathname.startsWith("/selection-vehicule")) {
            setSelectionVehiculePage(pathname);
        } else if (pathname.startsWith("/pieces")) {
            setPiecesPage(pathname);
        } else if (pathname.startsWith("/social")) {
            setSocialPage(pathname);
        }
    }, [
        pathname,
        setIdentificationVehiculePage,
        setPiecesPage,
        setSelectionVehiculePage,
        setSocialPage,
    ]);
};

export const PersistPages = (): null => {
    usePersistPages();
    return null;
};
