import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";

import { VehicleDataContext } from "@/context/VehicleDataContext";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { getCurrentImageIndex } from "./lib/threesixty";

type ItemIds = { [id: string]: string[] };

export const Button360OEM = (): JSX.Element | null => {
    const [vehicleData] = React.useContext(VehicleDataContext);

    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();

    const { data: itemIds } = useAPI<ItemIds>("katia/ids", { initialData: {} });

    const handleClick = (): void => {
        const photoNb = getCurrentImageIndex();
        if (photoNb === -1) return;
        const katiaItems = itemIds[photoNb];
        if (katiaItems && katiaItems.length > 0) {
            const newURL = `${match.url}/katia/${katiaItems.join("-")}`;
            history.push(newURL === location.pathname ? match.url : newURL);
        } else {
            toastr.info(`Aucun OEM défini pour la vue en cours (${photoNb})`);
        }
    };

    return vehicleData.id_vehiculo_kromeda ? (
        <Button className="button-360 button-360-oem" onClick={handleClick}>
            360° OEM
        </Button>
    ) : null;
};
