import * as React from "react";
import { useTranslation } from "react-i18next";

import { AppsWidget } from "@/components/AppsWidget";
import { DiagSearch } from "@/components/DiagSearch";
import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { Restricted } from "@/components/Restricted";
import { SlidesStack } from "@/components/SlidesStack";
import { LEVAM_URL } from "@/config/settings";
import { useLanguage } from "@/utils/hooks";

import "./OESelector.css";

const SearchField = () => {
    const { addIframeSlide } = useAddItemSlide();
    const lang = useLanguage();

    const onVinSearch = (vin: string) => {
        addIframeSlide(`${LEVAM_URL}?vin=${vin}&lang=${lang}`);
    };

    return <DiagSearch onVinSearch={onVinSearch} />;
};

const OESelectorContent = () => {
    const { t } = useTranslation();
    return (
        <div className="inner-content">
            <div className="oeselector-top">
                <SearchField />
            </div>
            <div className="main-bloc oe-selector">
                <AppsWidget widget="oeselector-brands" />
                <div className="mt-4">
                    <div>{t("vehicles.CV")}</div>
                    <AppsWidget widget="oeselector-trucks" />
                </div>
            </div>
        </div>
    );
};

export const OESelector = (): JSX.Element => (
    <Restricted access="OESelector">
        <OESelectorContent />
        <SlidesStack className="iframe-wrapper iframe-partselector" />
    </Restricted>
);
