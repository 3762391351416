import * as React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { QRCodeSVG } from "qrcode.react";

import { DatePicker } from "@/components/DatePicker";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Restricted } from "@/components/Restricted";
import { ToggleComponent } from "@/components/ToggleComponent";
import { getContent, postContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";

import "react-datepicker/dist/react-datepicker.css";

type AccessToken = {
    token: string;
    userId: number;
    enabled: 0 | 1;
    dateFrom: number;
    dateTo: number;
};

type User = { userId: number; name: string };

type QRLogins = {
    accessTokens: AccessToken[];
    users: User[];
};

type QRCardProps = {
    accessToken: AccessToken;
    users: User[];
    onChange(): void;
};

const QRCard = ({ accessToken, users, onChange }: QRCardProps): JSX.Element => {
    const [enabled, setEnabled] = React.useState(Boolean(accessToken.enabled));
    const [userId, setUserId] = React.useState<number | null>(
        accessToken.userId
    );
    const [dateFrom, setDateFrom] = React.useState(
        accessToken.dateFrom ? new Date(accessToken.dateFrom) : null
    );
    const [dateTo, setDateTo] = React.useState(
        accessToken.dateTo ? new Date(accessToken.dateTo) : null
    );
    const options = users.map((user) => ({
        value: user.userId,
        label: user.name,
    }));
    const selectedUser = users.find((user) => user.userId === userId);
    const selectedOption = { value: userId, label: selectedUser?.name };

    const onSelectChange = (option: { value: number | null } | null) =>
        setUserId(option?.value ?? null);
    const onToggle = () => setEnabled((s) => !s);

    const { t } = useTranslation();
    const timestampFrom = dateFrom?.getTime() ?? null;
    const timestampTo = dateTo?.getTime() ?? null;

    const hasChanges =
        enabled !== Boolean(accessToken.enabled) ||
        userId !== accessToken.userId ||
        timestampFrom !== accessToken.dateFrom ||
        timestampTo !== accessToken.dateTo;

    const submitChanges = async () => {
        try {
            await postContent("qrlogin", {
                token: accessToken.token,
                userId,
                enabled,
                dateFrom: timestampFrom,
                dateTo: timestampTo,
            });
            await onChange();
        } catch (error) {
            console.error(error);
            toast.error(t("common.error"));
        }
    };

    const cancelChanges = () => {
        setEnabled(Boolean(accessToken.enabled));
        setUserId(accessToken.userId);
        setDateFrom(
            accessToken.dateFrom ? new Date(accessToken.dateFrom) : null
        );
        setDateTo(accessToken.dateTo ? new Date(accessToken.dateTo) : null);
    };

    return (
        <div className={classnames("qrcard d-flex", { disabled: !enabled })}>
            <QRCodeSVG value={accessToken.token} level="Q" />
            <div className="d-flex flex-column ms-3">
                <div className="d-flex align-items-center mb-1">
                    <span className="me-2">User</span>
                    <Select
                        value={selectedOption}
                        options={options}
                        className="qr-select"
                        onChange={onSelectChange}
                        isDisabled={!enabled}
                    />
                </div>
                <div className="d-flex align-items-center mb-1">
                    <span className="me-2">Début</span>
                    <DatePicker
                        selected={dateFrom}
                        onChange={(date: Date) => setDateFrom(date)}
                        disabled={!enabled}
                    />
                </div>
                <div className="d-flex align-items-center mb-1">
                    <span className="me-2">Fin</span>
                    <DatePicker
                        selected={dateTo}
                        onChange={(date: Date) => setDateTo(date)}
                        disabled={!enabled}
                    />
                </div>
                <div className="d-flex mt-auto mb-0">
                    {hasChanges && (
                        <>
                            <FontAwesomeIcon
                                icon={faCheck}
                                role="button"
                                size="2x"
                                className="me-4 text-success"
                                onClick={submitChanges}
                            />
                            <FontAwesomeIcon
                                icon={faTimes}
                                role="button"
                                size="2x"
                                className="text-secondary"
                                onClick={cancelChanges}
                            />
                        </>
                    )}

                    <ToggleComponent
                        className="ms-auto"
                        checked={enabled}
                        onChange={onToggle}
                    />
                </div>
            </div>
        </div>
    );
};

const Admin = (): JSX.Element => {
    const {
        data: qrlogins,
        error,
        fetchData,
    } = useAPI<QRLogins>("qrlogins", {
        initialData: { accessTokens: [], users: [] },
        ignoreCache: true,
    });
    if (error) {
        return <ErrorMessage />;
    }
    const { accessTokens, users } = qrlogins;
    const addQRCode = () => {
        getContent<string>("/newQRLogin", { ignoreCache: true })
            .then(fetchData)
            .catch((err) => {
                console.error(err);
            });
    };
    return (
        <>
            <Button onClick={addQRCode}>Nouveau QR Code</Button>
            <div className="overflow-auto mt-2 flex-grow-1">
                {accessTokens.map((token) => (
                    <QRCard
                        accessToken={token}
                        key={token.token}
                        users={users}
                        onChange={fetchData}
                    />
                ))}
            </div>
        </>
    );
};

export const QRCodesAdmin = (): JSX.Element => {
    return (
        <div className="qrcodes-admin position-relative">
            <h1>Liste des QR Codes</h1>
            <ErrorBoundary>
                <Restricted access="admin">
                    <Admin />
                </Restricted>
            </ErrorBoundary>
        </div>
    );
};
