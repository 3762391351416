import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useRouteMatch } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { apiRootURL, IMAGES_SERVER } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import { useAPI } from "@/utils/hooks";

import { PartImg } from "./Item/PartImg";
import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";
import { NoResults } from "./NoResults";
import type { StockCarecoCommon } from "./StockCareco";

import "./Recycling.css";

const { useState } = React;

const getReferences = (record: FicheCareco): string[] => [
    ...(["typeMot", "codeMot", "refBte", "codeBte"] as const).reduce(
        (set, attr) => {
            if (record[attr]) {
                record[attr]
                    .trim()
                    .toUpperCase()
                    .split("|")
                    .forEach((value) => set.add(value));
            }
            return set;
        },
        new Set<string>()
    ),
];

const getPhotosList = (photos: string): string[] =>
    photos.split(",").map((photo) => `${IMAGES_SERVER}/metb/images/${photo}`);
const BREADCRUMBS_IMAGE = `${IMAGES_SERVER}/sivince/parts-for-reuse.png`;

const showPart = (part: StockItem): JSX.Element => (
    <tr key={part.id}>
        <td>
            <div>{part.vendeur}</div>
            <img src={`${apiRootURL}/media/img/careco.jpg`} alt="careco" />
        </td>
        <td>
            {part.photos ? (
                <PartImg
                    imgList={getPhotosList(part.photos)}
                    title={part.numLivrePolice}
                />
            ) : (
                <img
                    src={`${apiRootURL}/media/img/${
                        part.typeDePiece === "Moteur" ? "engine" : "gearbox"
                    }.jpg`}
                    alt=""
                    height="60px"
                />
            )}
        </td>
        <td>
            <ul>
                <li>{part.refConstructeur}</li>
                <li>{part.numLivrePolice}</li>
                <li>
                    {part.marque} {part.modele} {part.version} {part.kms}
                    &nbsp;kms
                </li>
                <li>{part.garantie != null && part.garantie + " mois"}</li>
                <li>{part.emplacementCasier}</li>
                <li>{part.prixVente}€</li>
                <li>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: part.commentaireCommercial,
                        }}
                    />
                </li>
                <li>{part.status}</li>
            </ul>
        </td>
    </tr>
);

type Params = {
    virtual: "0" | "1" | "1-0";
};

type FicheCareco = {
    typeMot: string;
    codeMot: string;
    refBte: string;
    codeBte: string;
    obsMot: string;
    obsBte: string;
};

type StockItem = StockCarecoCommon & {
    typeDePiece: string;
    disponibilite: number;
    numLivrePolice: string;
    status: string;
};

type StockResults = {
    record: FicheCareco[];
    stock: StockItem[];
};

const RecyclingContent = ({ data }: { data: StockResults }): JSX.Element => {
    const [activeTab, setActiveTab] = useState(0);

    const { virtual } = useParams<Params>();

    const stockFilter = {
        "1": (part: StockItem): boolean => part.disponibilite === 1,
        "0": (part: StockItem): boolean =>
            part.disponibilite === null || part.disponibilite === 0,
        "1-0": (part: StockItem): boolean => Boolean(part),
    }[virtual];

    const tabs = data.record.map((r) => r.typeMot);
    const references = data.record.map(getReferences);

    return (
        <>
            <Nav tabs>
                {tabs.map((tab, i) => (
                    <NavItem key={tab}>
                        <NavLink
                            className={classnames({ active: activeTab === i })}
                            onClick={(): void => setActiveTab(i)}
                        >
                            Moteur: <span>{tab}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {tabs.map((tab, i) => (
                    <TabPane tabId={i} key={tab}>
                        <p>
                            Type/Code Moteur:{" "}
                            <span className="engine-type">
                                {data.record[i].typeMot}
                            </span>
                            {data.record[i].codeMot &&
                                data.record[i].codeMot
                                    .split("|")
                                    .map((codeMot) => (
                                        <React.Fragment key={codeMot}>
                                            <span>&gt;</span>
                                            <span className="engine-code">
                                                {codeMot}
                                            </span>
                                        </React.Fragment>
                                    ))}
                            <br />
                            Type/Code Boite de vitesse:{" "}
                            <span className="engine-type">
                                {data.record[i].refBte}
                            </span>
                            {data.record[i].codeBte &&
                                data.record[i].codeBte
                                    .split("|")
                                    .map((codeBte) => (
                                        <React.Fragment key={codeBte}>
                                            <span>&gt;</span>
                                            <span className="engine-code">
                                                {codeBte}
                                            </span>
                                        </React.Fragment>
                                    ))}
                        </p>
                        <div style={{ display: "flex" }}>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: data.record[i].obsMot,
                                }}
                            />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: data.record[i].obsBte,
                                }}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="oes-table">
                                <table>
                                    <tbody>
                                        {data.stock
                                            .filter(
                                                (part) =>
                                                    part.typeDePiece ===
                                                        "Moteur" &&
                                                    stockFilter(part) &&
                                                    references[i].includes(
                                                        part.refConstructeur.toUpperCase()
                                                    )
                                            )
                                            .map(showPart)}
                                    </tbody>
                                </table>
                            </div>
                            <div className="oes-table">
                                <table>
                                    <tbody>
                                        {data.stock
                                            .filter(
                                                (part) =>
                                                    part.typeDePiece ===
                                                        "Boite" &&
                                                    stockFilter(part) &&
                                                    references[i].includes(
                                                        part.refConstructeur.toUpperCase()
                                                    )
                                            )
                                            .map(showPart)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPane>
                ))}
            </TabContent>
        </>
    );
};

const useCnitAndDate = () => {
    const [vehicleData] = useVehicleData();
    let date = `${vehicleData["Année 1ère MEC"]}-${vehicleData["Mois 1ère MEC"]}-${vehicleData["Jour 1ère MEC"]}`;
    let typeCG = vehicleData.Type;
    const { ktype } = vehicleData;
    const { data } = useAPI<{ cnit: string; date: string }>(
        ktype && !typeCG ? `cnit-and-date/${ktype}` : null
    );

    if (!typeCG && data) {
        date = data.date;
        typeCG = data.cnit;
    }

    return { date, typeCG };
};

const DataLoader = () => {
    const { typeCG, date } = useCnitAndDate();
    const { data, error } = useAPI<StockResults>(
        typeCG && date ? `stock/${typeCG}/${date}` : null
    );

    if (error) {
        return <ErrorMessage />;
    }

    if (!data) {
        return <Loading />;
    }

    if (data.record.length === 0) {
        return <NoResults />;
    }

    return <RecyclingContent data={data} />;
};

export const RecyclingContainer = (): JSX.Element => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const [vehicleData] = useVehicleData();
    const { Marque: brand, Modèle: model } = vehicleData;

    const params = {
        ...match.params,
        brand,
        model,
        categoryId: t("albums.recycling"),
    };

    const breadcrumbsProps = { ...match, params };
    return (
        <div className="selection-bloc main-bloc item-bloc recycling">
            <Breadcrumbs match={breadcrumbsProps} image={BREADCRUMBS_IMAGE} />
            <h2>{t("albums.recycling")}</h2>
            <DataLoader />
        </div>
    );
};
