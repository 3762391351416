import * as React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useVehicleData } from "@/context/VehicleDataContext";

import { getTitleId, LEFT_MENU_BUTTONS } from "./LeftMenu";

import "./TitleWithIcon.css";

const ID_ICONS_MAP = [
    ...LEFT_MENU_BUTTONS,
    { id: "app/mybox", icon: faDesktop } as const,
];

const copyToClipboard = (text: string | undefined): void => {
    if (!text) return;
    const textArea = document.createElement("textarea");

    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = "0";
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        console.log("Copying text command was " + msg);
    } catch (err) {
        console.log("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
};

export const TitleWithIcon = (): JSX.Element => {
    const { t } = useTranslation();
    const [vehicleData] = useVehicleData();
    return (
        <div className="title-with-icon">
            <Switch>
                {ID_ICONS_MAP.map((route) => (
                    <Route key={route.id} path={"/" + route.id}>
                        <>
                            <FontAwesomeIcon icon={route.icon} />
                            <span>{t(getTitleId(route.id))}</span>
                            {route.id === "diag" &&
                                vehicleData.Codif_Vin_PRF && (
                                    <span
                                        onClick={(): void =>
                                            copyToClipboard(
                                                vehicleData.Codif_Vin_PRF
                                            )
                                        }
                                    >
                                        {" "}
                                        : {vehicleData.Codif_Vin_PRF}
                                    </span>
                                )}
                        </>
                    </Route>
                ))}
            </Switch>
        </div>
    );
};
