import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";

import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { Photo360 } from "@/components/Photo360";
import { getContent } from "@/utils/fetch";
import { useGenarts } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import type { Genart360 } from "./GenartSelectorSelect";

import "./GenartSelectorDeal.css";

const { useState, useEffect } = React;

const mockVehicleData: VehicleData = {
    Marque: "CITROEN",
    Modèle: "DS3",
    NB_Portes: "3",
    Carrosserie: "BERLINE",
    ktype: "32036",
};

type GenartWithId = {
    id: string;
    label: string;
    checked: boolean;
};

const createDealCommand = (opuid: string, dtc: string, ids: string) => {
    const url = `createDealCommand/${opuid}/${dtc || "-"}/${ids}`;
    return getContent<{ error?: string }>(url, { ignoreCache: true });
};

type Props = {
    selectedGenarts: string[];
};

type RouteParams = {
    0: string;
    value: string;
    dtc: string;
};

export const GenartSelectorDeal = ({ selectedGenarts }: Props): JSX.Element => {
    const { t } = useTranslation();

    const { path, params: mainParams } = useRouteMatch<RouteParams>();
    const { params } = useRouteMatch<RouteParams>(`${path}/md360dtc/:dtc`) || {
        params: mainParams,
    };
    const { "0": field, value, dtc } = params;

    const [photoNb, setPhotoNb] = useState(1);
    const [genartIds, setGenartIds] = useState<Genart360[]>([]);
    const [genarts, setGenarts] = useState<GenartWithId[][]>([]);
    const { genartsMap } = useGenarts();

    const opuid =
        field === "operation"
            ? value
            : new URLSearchParams(window.location.search).get("opuid");

    const onSubmit = (): void => {
        const ids = genarts
            .flatMap((genartsInPage) =>
                genartsInPage
                    .filter((genart) => genart.checked)
                    .map((genart) => genart.id)
            )
            .join("-");
        if (ids.length === 0 || !opuid) {
            return;
        }
        createDealCommand(opuid, dtc, ids)
            .then((data) => {
                if (data.error) {
                    throw new Error(data.error);
                }
                toastr.success("Panier Deal créée");
            })
            .catch(() => toastr.error("Impossible de créer le panier Deal"));
    };

    const onPhotoNbChange = (nb: number): void => setPhotoNb(nb);
    const currentGenarts =
        genarts.length > photoNb + 1 ? genarts[photoNb - 1] : [];

    const fetchGenartIds = (): void => {
        getContent<Genart360[]>("genart360ids").then((newGenartIds) => {
            setGenartIds(newGenartIds);
        });
    };

    const updateGenarts = (): void => {
        const newGenarts = genartIds.map((row) => {
            const itemIds = row.item_ids.split(",").map((id) => ({
                id,
                label: genartsMap[Number(id)].label,
                checked: selectedGenarts.includes(id),
            }));
            return itemIds;
        });
        setGenarts(newGenarts);
    };

    useEffect(fetchGenartIds, []);
    useEffect(updateGenarts, [genartIds, genartsMap, selectedGenarts]);

    return (
        <div className="genart-selector-deal d-flex">
            <div className="deal-left-panel">
                <ul>
                    {currentGenarts.map((genart) => (
                        <li key={genart.id}>
                            <label>
                                <input
                                    type="checkbox"
                                    defaultChecked={genart.checked}
                                    onChange={(e): void => {
                                        genart.checked = e.target.checked;
                                    }}
                                />{" "}
                                <span>{genart.label}</span>
                            </label>
                        </li>
                    ))}
                </ul>
                {currentGenarts.length > 0 && (
                    <Button
                        color="primary"
                        onClick={onSubmit}
                        disabled={!opuid}
                    >
                        {t("common.submit")}
                    </Button>
                )}
            </div>
            <div className="d-flex align-items-end justify-content-end deal-360">
                <Photo360
                    vehicleData={mockVehicleData}
                    onChange={onPhotoNbChange}
                />
            </div>
        </div>
    );
};
