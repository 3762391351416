import * as React from "react";

export type CarouselContext = [number, (index: number) => void];
export const CarouselContext = React.createContext<CarouselContext>([
    0,
    (): void => {},
]);

export const CarouselProvider = (props: {
    children: React.ReactNode;
}): JSX.Element => {
    const carousel = React.useState(0);
    return (
        <CarouselContext.Provider value={carousel}>
            {props.children}
        </CarouselContext.Provider>
    );
};
