import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { CriteriaProvider } from "@/context/CriteriaContext";
import { VehicleDataProvider } from "@/context/VehicleDataContext";
import { CarIdentifier } from "@/v2/CarIdentifierV2";
import CarSelector from "@/v2/CarSelector/CarSelector";

import { CarouselProvider } from "./CarouselContext";
import { HubIdlpProvider } from "./HubIdlpContext";
import { IframesCarSelector } from "./IframesCarSelector";
import { IframesWithContext } from "./IframesWithContext";

interface Props {
    setIframeVisible: (visible: boolean) => void;
}

const HideIframes = ({ setIframeVisible }: Props): null => {
    React.useEffect(() => {
        setIframeVisible(false);
    }, [setIframeVisible]);
    return null;
};

type CarSelectorRouterProps = {
    reduced: boolean;
    toggleReduced(): void;
};

export const CarSelectorRouter = ({
    reduced,
    toggleReduced,
}: CarSelectorRouterProps): JSX.Element => {
    const { path } = useRouteMatch();
    const [iframeVisible, setIframeVisible] = React.useState(true);

    return (
        <CriteriaProvider>
            <VehicleDataProvider>
                <CarouselProvider>
                    <HubIdlpProvider>
                        <IframesCarSelector visible={iframeVisible} />
                        <Switch>
                            <Route path={path + "/search/:field/:value"}>
                                <HideIframes
                                    setIframeVisible={setIframeVisible}
                                />
                                <CarIdentifier
                                    reduced={reduced}
                                    toggleReduced={toggleReduced}
                                />
                                <IframesWithContext />
                            </Route>
                            <Route>
                                <CarSelector
                                    setIframeVisible={setIframeVisible}
                                    reduced={reduced}
                                    toggleReduced={toggleReduced}
                                />
                            </Route>
                        </Switch>
                    </HubIdlpProvider>
                </CarouselProvider>
            </VehicleDataProvider>
        </CriteriaProvider>
    );
};
