import * as React from "react";

import type { App, WidgetType } from "shared/dist/types/apps";

import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { INITIAL_SLIDE_URL } from "@/components/NotificationRedirect";
import { USE_KEYCLOAK } from "@/config/settings";
import { useAPI as useOldAPI } from "@/utils/hooks";
import { useAPIwithKeycloak } from "@/utils/useAPIwithKeycloak";

export const useAPI = USE_KEYCLOAK ? useAPIwithKeycloak : useOldAPI;

export const SetInitialSlides = ({ widget }: { widget: WidgetType }) => {
    const { data } = useAPI<App[]>(`widget/${widget}`);
    const { addIframeSlide } = useAddItemSlide();
    React.useEffect(() => {
        if (data === null) {
            return;
        }
        for (const app of data) {
            if (app.appType === "iframe" && app.url) {
                addIframeSlide(app.url);
            }
        }
        const url = localStorage.getItem(INITIAL_SLIDE_URL);
        if (url) {
            addIframeSlide(url);
        }
        localStorage.removeItem(INITIAL_SLIDE_URL);
    }, [addIframeSlide, data]);
    return null;
};
