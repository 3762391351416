import * as React from "react";

import { ProfilePicture } from "@/components/ProfilePicture";
import { UserCompany } from "@/components/UserCompany";
import { useAuth } from "@/context/AuthContext";

import { CompanyCover } from "./CompanyCover";
import { SetCompanyCoverImage } from "./SetCompanyCoverImage";
import { SetCompanyCoverVideo } from "./SetCompanyCoverVideo";

export const MyProfile = (): JSX.Element => {
    const { userInfo } = useAuth();

    return (
        <div className="my-profile p-3">
            <CompanyCover />
            <div className="d-flex mt-5 mb-3 position-relative">
                <ProfilePicture />
                <div className="user-name-bloc d-flex align-items-center">
                    <span className="ms-4 me-2">{userInfo.name}</span>
                    <UserCompany />
                    <SetCompanyCoverImage />
                    <SetCompanyCoverVideo />
                </div>
            </div>
        </div>
    );
};
