import * as React from "react";

import type { RoleGroups } from "shared/dist/types/apps";

import { getContent } from "@/utils/fetch";

type RoleGroupContextValue = [
    RoleGroups,
    React.Dispatch<React.SetStateAction<RoleGroups>>
];

const RoleGroupContext = React.createContext<RoleGroupContextValue | undefined>(
    undefined
);

export const RoleGroupsProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const state = React.useState<RoleGroups>({});
    const [, setGroups] = state;
    React.useEffect(() => {
        const fetchData = async () => {
            const groups = await getContent<RoleGroups>("apps/groups", {
                ignoreCache: true,
            });
            setGroups(groups);
        };
        fetchData();
    }, [setGroups]);
    return (
        <RoleGroupContext.Provider value={state}>
            {children}
        </RoleGroupContext.Provider>
    );
};

export const useRoleGroupContext = () => {
    const context = React.useContext(RoleGroupContext);
    if (!context) {
        throw new Error(
            "useRoleGroupsContext must be used within a RoleGroupsProvider"
        );
    }
    return context;
};

export const useRoleGroups = () => {
    const [groups] = useRoleGroupContext();
    return groups;
};
