import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import Select from "react-select";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Vtype } from "shared";
import type { OE } from "shared/dist/types/item";

import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { STOCK_IMAGE_URL } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import { useAPI } from "@/utils/hooks";

import { ArticleTable } from "./Item/ArticleTable";
import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";

const { useState, useMemo } = React;

const MARGIN_ICON = { marginRight: 5, marginTop: 8 };

export type StockCarecoCommon = {
    id: number;
    refConstructeur: string;
    marque: string;
    modele: string;
    version: string;
    kms: number;
    emplacementCasier: string;
    vendeur: string;
    garantie: string;
    prixVente: number;
    prixCareco: number;
    commentaireCommercial: string;
    photos: string;
    codeCompatibilite: string;
};

type StockCareco = StockCarecoCommon & {
    provenance: "stock" | "reparcar";
    ean: string;
    recommandé: 0 | 1;
    vhu: string;
    nom: string;
    type: string;
};

const ATTRIBUTES = [
    "type",
    "marque",
    "modele",
    "version",
    "kms",
    "emplacementCasier",
    "garantie",
] as const;
const itemToOE = (item: StockCareco): OE => {
    const fromReparcar = item.provenance === "reparcar";
    let oeNrs = item.codeCompatibilite ? [item.codeCompatibilite] : [];
    const attributes = [];
    ATTRIBUTES.forEach((label) => {
        if (item[label]) {
            attributes.push({ label, labelId: label, value: item[label] });
        }
    });
    if (fromReparcar && item.codeCompatibilite) {
        const tokens = item.codeCompatibilite
            .split(">")
            .filter((ref) => ref !== "");
        oeNrs = [tokens[0]];
        if (tokens.length > 1) {
            const value = tokens.slice(1).join(">");
            attributes.push({ label: "ref", labelId: "ref", value });
        }
    }
    const id = fromReparcar
        ? item.ean.replace(/^\d+-/, "")
        : item.refConstructeur;
    return {
        ...item,
        type: "stockCareco",
        makeLabel: fromReparcar ? "REPARCAR" : item.vendeur,
        makeId: fromReparcar ? "REPARCAR" : "CARECO",
        id,
        oeNrs,
        attributes,
        price: item.prixVente,
        partType: item.type,
        ean: item.ean,
        recommandé: String(item.recommandé),
    };
};

type StockBlocProps = {
    children: React.ReactNode;
    parentProps: {
        match: { params: Record<string, string> };
        brand: string | undefined;
        model: string | undefined;
    };
};

const StockBloc = ({ children, parentProps }: StockBlocProps) => {
    const { t } = useTranslation();

    const params = {
        ...parentProps.match.params,
        brand: parentProps.brand,
        model: parentProps.model,
        categoryId: t("albums.stock"),
    };

    const breadcrumbsProps = { ...parentProps.match, params };

    return (
        <div className="selection-bloc main-bloc item-bloc">
            <Breadcrumbs match={breadcrumbsProps} image={STOCK_IMAGE_URL} />
            <h2>{t("albums.stock")}</h2>
            {children}
        </div>
    );
};

export const StockCareco = (): React.ReactElement => {
    const [selectedFilters, setSelectedFilters] = useState<
        Readonly<{ value: string }[]>
    >([]);
    const { t } = useTranslation();
    const [vehicleData] = useVehicleData();
    const match = useRouteMatch<{
        ktype: string;
        field: string;
        vtype?: Vtype;
    }>();
    if (match.params.field === "ntypnr") {
        match.params.vtype = "pl";
    }

    const { Type: cnit, Type_Vin_CG: vin } = vehicleData;
    const ktype = vehicleData.ktype || match.params.ktype;
    const props = {
        match,
        brand: vehicleData.Marque,
        model: vehicleData.Modèle,
    };

    const url = !ktype
        ? null
        : cnit
        ? `stockCareco/${cnit}/${vin || "null"}/${ktype}`
        : `stockCareco/${ktype}`;
    const { data: items, error } = useAPI<StockCareco[]>(url, {
        initialData: [],
    });

    const selectOptions = useMemo(() => {
        if (!items) return [];
        const filters = [
            ...new Set(
                items
                    .map((item) => item.type)
                    .concat(items.map((item) => item.refConstructeur))
            ),
        ].sort();
        return filters.map((type) => ({ label: type, value: type }));
    }, [items]);

    const oes = useMemo(() => items.map(itemToOE), [items]);

    if (error) {
        return (
            <StockBloc parentProps={props}>
                <ErrorMessage />
            </StockBloc>
        );
    }

    if (!items && ktype) {
        return (
            <StockBloc parentProps={props}>
                <Loading />
            </StockBloc>
        );
    }

    if (!ktype || items.length === 0) {
        return (
            <StockBloc parentProps={props}>
                <p>{t("common.no-results")}</p>
            </StockBloc>
        );
    }

    const filterSelectedOEs = (oe: OE): boolean =>
        selectedFilters === null ||
        selectedFilters.length === 0 ||
        (oe.partType &&
            selectedFilters.some((filter) => filter.value === oe.partType)) ||
        selectedFilters.some((filter) => filter.value === oe.id);

    const filteredOEs = oes.filter(filterSelectedOEs);

    return (
        <StockBloc parentProps={props}>
            <p>{items.length} article(s) trouvé(s).</p>
            {selectOptions.length > 1 && (
                <div className="d-flex">
                    <FontAwesomeIcon icon={faFilter} style={MARGIN_ICON} />
                    <Select
                        name="filter types"
                        placeholder={t("item.stock-filter-placeholder")}
                        options={selectOptions}
                        value={selectedFilters}
                        onChange={setSelectedFilters}
                        isMulti
                        className="flex-grow-1 select-above-table"
                    />
                </div>
            )}
            <div className="oes-table">
                <ArticleTable
                    oes={filteredOEs}
                    openModal={() => (): void => {}}
                    oeColumnName="Ref équivalente"
                    displayLinkCEColumn
                    ktype={ktype}
                    vin={vin}
                />
            </div>
        </StockBloc>
    );
};
