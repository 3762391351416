import * as React from "react";

type Props = {
    app: string;
    url: string;
};

export const Iframe = ({ app, url }: Props): JSX.Element | null => {
    if (!url) {
        return null;
    }
    return (
        <div className={`position-relative iframe-wrapper iframe-${app}`}>
            <iframe
                src={url}
                title="app"
                frameBorder="0"
                allow="microphone; camera"
                allowFullScreen
            ></iframe>
        </div>
    );
};
