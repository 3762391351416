import * as React from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilState, useRecoilValue } from "recoil";

import { astucesContentOpen, astucesState } from "@/globalState/atoms";

import "./Astuces.css";

export type Astuce = {
    typeMot: string;
    codeMot: string;
    obsMot: string;
    contributeur: string;
};

type CodeProps = {
    codes?: string;
};

const Codes = ({ codes }: CodeProps) => (
    <>
        {codes &&
            codes.split("|").map((ref, j) => (
                <React.Fragment key={j}>
                    {j > 0 && " > "}
                    <Button color="link">{ref}</Button>
                </React.Fragment>
            ))}
    </>
);

export const Astuces = (): JSX.Element | null => {
    const { t } = useTranslation();
    const astuces = useRecoilValue(astucesState);
    const [open, setOpen] = useRecoilState(astucesContentOpen);

    if (astuces.length === 0) {
        return null;
    }
    const toggle = () => {
        setOpen((o) => !o);
    };
    return (
        <div className="astuces">
            <div className="astuces-button" onClick={toggle}>
                <FontAwesomeIcon icon="users" size="3x" />
                <Badge color="info">{astuces.length}</Badge>
            </div>
            <div className={open ? "astuces-content" : "hidden"}>
                <h3>{t("tips")}</h3>
                <ul>
                    {astuces.map((astuce, i) => (
                        <li key={i}>
                            <strong>{astuce.typeMot}</strong>
                            <br />
                            <span>
                                <Codes codes={astuce.codeMot} />
                            </span>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: astuce.obsMot,
                                }}
                            />
                            <span>{astuce.contributeur}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
