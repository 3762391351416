import * as React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import type { PartialVehicleData } from "shared";

import { Cat4SearchBar } from "@/components/Header/Cat4SearchBar";
import { Restricted } from "@/components/Restricted";
import { SlidesStack } from "@/components/SlidesStack";
import { TabsIdentVehicle } from "@/components/TabsIdentVehicle";
import {
    defaultIframeStatsSelector,
    iframeStatsSelector,
} from "@/config/settings";
import {
    emptyVehicleData,
    useVehicleData,
    VehicleDataProvider,
} from "@/context/VehicleDataContext";
import { selectionAlbumModeAtom } from "@/globalState/atoms";
import { useAPI, useLanguage } from "@/utils/hooks";

import Dashboard from "../Dashboard/Dashboard";

import { CarSelectorSearch } from "./CarSelectorSearch";
import { SelectBrand } from "./SelectBrand";
import { SelectModel } from "./SelectModel";
import { VehicleBanner } from "./VehicleBanner";

type Params = {
    ktype: string;
    brand: string;
    model: string;
    version: string;
};

export const PATH = "/stats-selector";

const GetMidAutodata = () => {
    const { ktype } = useParams<Params>();
    const [, setVehicleData] = useVehicleData();
    const { data } = useAPI<{ mid: string }>(
        ktype ? `ktypnrToMid/${ktype}` : null
    );
    React.useEffect(() => {
        setVehicleData((vehicleData) => ({
            ...vehicleData,
            Mid_Autodata: data?.mid || " ",
        }));
    }, [data, setVehicleData]);
    return null;
};

const IdentVehicle = (): JSX.Element => {
    const { ktype, brand, model, version: hmdnr } = useParams<Params>();
    const albumMode = useRecoilValue(selectionAlbumModeAtom);
    const vehicleData: PartialVehicleData = {
        ...emptyVehicleData,
        ktype,
        hmd_nr: Number(hmdnr),
        Marque: brand,
        Modèle: model,
    };
    return (
        <VehicleDataProvider value={vehicleData}>
            <GetMidAutodata />
            <TabsIdentVehicle
                className="tabs-carselector"
                albumMode={albumMode}
                showIframe
            />
        </VehicleDataProvider>
    );
};

const InitialIframe = () => {
    const lang = useLanguage(true);
    const iframeSrc = iframeStatsSelector[lang] || defaultIframeStatsSelector;
    if (iframeSrc === defaultIframeStatsSelector) {
        return <Dashboard />;
    }
    return (
        <iframe
            title="StatsSelector initial slide"
            src={iframeSrc}
            frameBorder="0"
            className="flex-grow-1"
        />
    );
};

const StatsSelector = (): JSX.Element => {
    const blocRef = React.useRef<HTMLDivElement>(null);
    const toggleFullscreen = (): void => {
        if (document.fullscreenElement === null) {
            blocRef.current && blocRef.current.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    };

    return (
        <Restricted access="StatsSelector">
            <div className="inner-content" ref={blocRef}>
                <Cat4SearchBar />
                <Switch>
                    <Route
                        path={`${PATH}/:vtype/:brand/:model/:version/:ktype`}
                    >
                        <VehicleBanner toggleFullscreen={toggleFullscreen} />
                    </Route>
                    <Route
                        path={[PATH, `${PATH}/:vtype`, `${PATH}/app/:app`]}
                        exact
                    >
                        <CarSelectorSearch />
                    </Route>
                    <Route path={`${PATH}/:vtype/:make`}>
                        <CarSelectorSearch model />
                    </Route>
                </Switch>
                <Route path={[PATH, `${PATH}/:vtype`]} exact>
                    <SelectBrand />
                </Route>
                <Route path={`${PATH}/app/:app`} exact>
                    <SelectBrand />
                </Route>
                <div className="with-short-bloc">
                    <Switch>
                        <Route path={`${PATH}/app/:app`} exact />
                        <Route
                            path={[
                                `${PATH}/:vtype/:brand`,
                                `${PATH}/:vtype/:brand/:model`,
                            ]}
                            exact
                        >
                            <SelectModel />
                        </Route>
                    </Switch>
                    <Route
                        path={`${PATH}/:vtype/:brand/:model/:version/:ktype`}
                    >
                        <IdentVehicle />
                    </Route>
                </div>
            </div>
            <Switch>
                <Route path={`${PATH}/:vtype/:brand/:model/:version/:ktype`} />
                <Route path={PATH}>
                    <SlidesStack className="iframe-wrapper iframe-statsselector">
                        <InitialIframe />
                    </SlidesStack>
                </Route>
            </Switch>
        </Restricted>
    );
};

const MemoStatsSelector = React.memo(StatsSelector);
export { MemoStatsSelector as StatsSelector };
