import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { ParcoursMIDRedirect } from "@/components/ParcoursMIDRedirect";
import { PhoneRedirect } from "@/components/PhoneRedirect";
import { SlidesProvider } from "@/components/SlidesStack";
import { ShoppingCart } from "@/pages/ShoppingCart/ShoppingCart";
import { UserPictureAndName } from "@/pages/v3/UserPictureAndName";

import { CarSelectorRouter } from "./CarSelectorRouter";

export const PageV3 = (): JSX.Element => {
    const { path } = useRouteMatch();
    const searchParams = new URLSearchParams(window.location.search);
    const [reduced, toggleReduced] = React.useReducer(
        (r) => !r,
        searchParams.get("mode") === "tablette"
    );

    React.useEffect(() => {
        document.body.classList.add("v2");
        document.body.classList.add("v3");
        return (): void => {
            document.body.classList.remove("v2");
            document.body.classList.remove("v3");
        };
    }, []);

    return (
        <SlidesProvider>
            <Switch>
                <Route path={`${path}/call/:phoneNumber`}>
                    <PhoneRedirect path={path} />
                </Route>
                <Route
                    path={`${path}/parcoursMID/:mid/:genarts/:selectedGenart?`}
                >
                    <ParcoursMIDRedirect path={path} />
                </Route>
                <Route path={path}>
                    <CarSelectorRouter
                        reduced={reduced}
                        toggleReduced={toggleReduced}
                    />
                </Route>
                <Route path={path + "/cart"}>
                    <ShoppingCart />
                </Route>
            </Switch>
            <UserPictureAndName reduced={reduced} />
        </SlidesProvider>
    );
};
