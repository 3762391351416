import * as React from "react";
import { Button } from "reactstrap";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Customer } from "shared/dist/types/customer";

import { i18n } from "@/config/i18n";
import { useCustomer } from "@/context/CustomerContext";
import { useVehicleData } from "@/context/VehicleDataContext";
import { postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

import { CustomerLabel } from "./CustomerLabel";
import { NewCustomerButton } from "./NewCustomerButton";

import "./GetCustomerData.css";

export const GetCustomerData = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div className="get-customer-data">
            <button type="button" onClick={() => setOpen((s) => !s)}>
                <FontAwesomeIcon icon={faUser} size="3x" />
            </button>
            <div className={open ? "" : "hidden"}>
                <CustomerDataPanel />
            </div>
        </div>
    );
};

const AssociateButton = () => {
    const [vehicleData] = useVehicleData();
    const [customer, setCustomer] = useCustomer();

    if (!customer) return null;
    const isAssociated =
        customer.vehicles.findIndex(
            (v) => v.immat === vehicleData.Immat_SIV
        ) !== -1;
    if (isAssociated) {
        return null;
    }

    const associate = async () => {
        const vehicle = {
            immat: vehicleData.Immat_SIV || "",
            brand: vehicleData.Marque || "",
            model: vehicleData.Modèle || "",
        };
        try {
            await postContent("vc/associate", {
                customerId: customer.id,
                ...vehicle,
            });
        } catch (_error) {
            toastr.error(i18n.t("common.error"));
            return;
        }
        setCustomer(
            (currentCustomer) =>
                currentCustomer && {
                    ...currentCustomer,
                    vehicles: [
                        ...currentCustomer.vehicles,
                        { ...vehicle, link: "unknown" },
                    ],
                }
        );
    };

    return (
        <Button color="primary" onClick={associate}>
            Associer au véhicule {vehicleData.Immat_SIV}
        </Button>
    );
};

const Vehicles = ({ customer }: { customer: Customer }) => {
    let label = "Aucun";
    if (customer.vehicles.length > 0) {
        label = customer.vehicles.map((v) => v.immat).join(", ");
    }
    return (
        <div className="mb-3">
            <p>Véhicules associés: {label}</p>
            <AssociateButton />
        </div>
    );
};

const CustomerDataPanel = () => {
    const [customer] = useCustomer();
    return (
        <div className="gcd-content d-flex flex-column">
            {customer ? (
                <>
                    <CustomerLabel customer={customer} />
                    <Vehicles customer={customer} />
                </>
            ) : (
                <p>Aucun client sélectionné</p>
            )}
            <NewCustomerButton />
        </div>
    );
};
