import * as React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

import "./ChangePassword.css";

type FormData = {
    password1: string;
    password2: string;
};

const submit = (data: FormData): void => {
    postContent("updatePassword", data)
        .then(() => toastr.success("Mot de passe mis à jour"))
        .catch(() =>
            toastr.error("Impossible de mettre à jour le mot de passe")
        );
};

export const ChangePassword = (): JSX.Element => {
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm<FormData>();

    return (
        <div className="change-password">
            <h2>Changer de mot de passe</h2>
            <form onSubmit={handleSubmit(submit)}>
                <label>
                    <span>Nouveau mot de passe</span>
                    <input {...register("password1")} type="password" />
                </label>
                <label>
                    <span>Confirmer le mot de passe</span>
                    <input
                        {...register("password2", {
                            validate: (value) =>
                                value.length > 0 &&
                                value === watch("password1"),
                        })}
                        type="password"
                    />
                </label>
                {errors.password2 && watch("password1") && (
                    <p className="error-message">
                        Les mots de passe ne correspondent pas
                    </p>
                )}
                <button type="submit" className="btn btn-primary">
                    Enregistrer
                </button>
            </form>
            <div className="link-to-my-account">
                <Link to="/mon-compte">Mon compte</Link>
            </div>
        </div>
    );
};
