import type { App } from "shared/dist/types/apps";

import { useAuth } from "@/context/AuthContext";
import { useAPI, useLanguage } from "@/utils/hooks";
import { usePartsBrands } from "@/utils/usePartsBrands";

export const usePartsURL = ({
    brand,
    typnr,
    vtype,
    isOE = false,
    isStock,
}: {
    brand: string | undefined;
    typnr: string | number | undefined;
    vtype: string;
    isOE: boolean | undefined;
    isStock: boolean;
}): string | null => {
    const { userInfo } = useAuth();
    const lang = useLanguage(true);
    const { data: partsOrigin } = useAPI<App[]>("widget/parts-origin");
    const { partsBrands } = usePartsBrands();
    const groups = userInfo?.stockGroups || [];
    let brandId: string | number | null | undefined = null;
    const searchBrand = (item: { name: string }) => item.name === brand;
    if (isStock) {
        brandId = groups.find(searchBrand)?.groupId;
    } else if (isOE) {
        brandId = (partsOrigin || []).find(searchBrand)?.brandId;
    } else {
        brandId = partsBrands.find(searchBrand)?.id;
    }
    if (!typnr && !brandId) return null;
    const partType = isOE ? "oe" : isStock ? "stock" : "all";
    const url = typnr
        ? `parts-families/${vtype}/${lang}/${typnr}`
        : `parts/${partType}/make/${lang}/${brandId}`;
    return url;
};
