import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Alert, Modal, ModalBody } from "reactstrap";
import { QRCodeSVG } from "qrcode.react";

import { Loading } from "@/components/Loading";
import { addClient, removeClient } from "@/components/SSEHandler";
import { usePublicAPI } from "@/utils/hooks";

const ErrorMessage = () => {
    const { t } = useTranslation();
    return <Alert color="danger">{t("common.error")}</Alert>;
};

type ScreenData = {
    urls: { url: string }[];
    data: { type: "qrcode" | "photo"; data: string | null } | null;
};

const IframeLoader = () => {
    const { id } = useParams<{ id: string }>();
    const { data, error, setData } = usePublicAPI<ScreenData>(`screen/${id}`);

    React.useEffect(() => {
        const clientId = addClient((event) => {
            if (event.type === "Screen URLs") {
                setData(
                    (currentData) =>
                        currentData && { ...currentData, urls: event.urls }
                );
            } else if (event.type === "Screen Data") {
                setData(
                    (currentData) =>
                        currentData && { ...currentData, data: event.data }
                );
            }
        });
        return () => {
            removeClient(clientId);
        };
    }, [setData]);

    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    if (data.urls.length === 0) {
        return <Alert color="info">Cet écran n&rsquo;est pas configuré</Alert>;
    }
    return (
        <>
            <iframe className="h-100 w-100" src={data.urls[0].url} />
            {data.data?.type === "qrcode" && (
                <QRCodeModal url={data.data.data} />
            )}
        </>
    );
};

const QRCodeModal = ({ url }: { url: string | null }) => {
    if (!url) return null;
    return (
        <Modal isOpen size="lg">
            <ModalBody className="d-flex justify-content-center my-4">
                <QRCodeSVG value={url} size={400} level="Q" />
            </ModalBody>
        </Modal>
    );
};

export const Screen = (): JSX.Element => (
    <div className="d-flex align-items-center justify-content-center h-100">
        <React.Suspense fallback={null}>
            <IframeLoader />
        </React.Suspense>
    </div>
);
