import * as React from "react";
import type { Column, RowInfo } from "react-table";
import ReactTable from "react-table";
import { BreadcrumbItem, Modal, ModalBody, ModalHeader } from "reactstrap";
import format from "date-fns/format";
import prism from "prismjs";

import "prismjs/components/prism-json";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { StateBreadcrumbs } from "@/components/StateBreadcrumbs";
import { IMAGES_SERVER } from "@/config/settings";
import { useVehicleData } from "@/context/VehicleDataContext";
import { useAPI, useLanguage } from "@/utils/hooks";

import { AppOEMs } from "./AppOEMs";

import "./AdnCatalog.css";
import "prismjs/themes/prism-okaidia.css";

const DATE_FORMAT = "dd/MM/yyyy HH:mm:ss";

const CompanyLogo = () => {
    return (
        <div className="breadcrumb-img">
            <img src={`${IMAGES_SERVER}/logos/sv5Actia.png`} alt="" />
        </div>
    );
};

type BreadcrumbProps = { brand?: string; model?: string; ktype: number };
type VehicleVersion = {
    name: string;
    ktype: number;
    hmdnr: number;
};

const VehicleLabel = ({ brand, model, ktype }: BreadcrumbProps) => {
    const lang = useLanguage();
    const url = `vehicles/versions/${lang}/${brand}/${model}`;
    const { data } = useAPI<VehicleVersion[]>(url);

    const version = data?.find((v) => v.ktype === ktype);
    const label = version ? version.name : `${brand} ${model}`;

    return <>{label}</>;
};

const Breadcrumbs = ({ brand, model, ktype }: BreadcrumbProps) => {
    const brandURL = `/selection-vehicule/vl`;
    const modelURL = `${brandURL}/${brand}`;
    return (
        <StateBreadcrumbs
            brand={brand}
            model={model}
            hideBrandLabel
            brandURL={brandURL}
            modelURL={modelURL}
        >
            <BreadcrumbItem>
                <VehicleLabel brand={brand} model={model} ktype={ktype} />
            </BreadcrumbItem>
            <CompanyLogo />
        </StateBreadcrumbs>
    );
};

const Header = ({ ktype }: { ktype: number }) => {
    return <h2>AdnCatalog ({ktype})</h2>;
};

type Call = {
    date: string;
    immat: string;
    name: string;
    login: string;
    response: string;
};

const COLUMNS: Column[] = [
    {
        Header: "Date",
        id: "date",
        accessor: (row) => format(new Date(row.date), DATE_FORMAT),
        sortable: true,
    },
    {
        Header: "Utilisateur",
        accessor: "name",
        sortable: true,
    },
    {
        Header: "Login",
        accessor: "login",
        sortable: true,
    },
    {
        Header: "Immatriculation",
        accessor: "immat",
        sortable: true,
    },
];

const PrettyJSON = ({ json }: { json: string }) => {
    const ref = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (ref.current) {
            prism.highlightElement(ref.current);
        }
    }, [json]);
    let text = json;
    try {
        // Format JSON to be multi-lines
        const parsed = JSON.parse(json);
        text = JSON.stringify(parsed, null, 2);
    } catch (error) {
        // ignore
    }
    return (
        <pre>
            <code ref={ref} className="language-json">
                {text}
            </code>
        </pre>
    );
};

const CallsTableContent = ({ calls }: { calls: Call[] }) => {
    const [json, setJson] = React.useState("");
    const closeModal = () => setJson("");
    return (
        <>
            <ReactTable
                data={calls}
                columns={COLUMNS}
                className="adncatalog-table overflow-hidden"
                getTrProps={(state: unknown, rowInfo: RowInfo | undefined) => {
                    return {
                        onClick: () => {
                            setJson(rowInfo?.original.response || "");
                        },
                    };
                }}
            />
            <Modal isOpen={json !== ""} toggle={closeModal} size="lg">
                <ModalHeader toggle={closeModal}>
                    Réponse de l&rsquo;API
                </ModalHeader>
                <ModalBody>
                    <PrettyJSON json={json} />
                </ModalBody>
            </Modal>
        </>
    );
};

const CallsTable = ({ ktype }: { ktype: number }) => {
    const { data, error } = useAPI<Call[]>(
        ktype ? `stats/calls/${ktype}` : null
    );
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }

    return <CallsTableContent calls={data} />;
};

export const AdnCatalog = (): JSX.Element => {
    const [vehicleData] = useVehicleData();
    const { ktype, Marque: brand, Modèle: model, Mid_Autodata } = vehicleData;
    const ktypnr = Number(ktype);
    return (
        <div className="selection-bloc main-bloc adncatalog-tab">
            <Breadcrumbs brand={brand} model={model} ktype={ktypnr} />;
            <div className="d-flex">
                <Header ktype={ktypnr} />
                {Mid_Autodata && <AppOEMs mid={Mid_Autodata} />}
            </div>
            <CallsTable ktype={ktypnr} />
        </div>
    );
};
