import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import type { VehicleVersion } from "@/pages/CarSelector/VehicleBanner";

export const cache: {
    versions: Record<string, VehicleVersion[]>;
    vehicleData: Record<string, VehicleData>;
    [key: string]: unknown;
} = {
    vehicleData: {},
    models: {},
    versions: {},
};

export const clearCache = (): void => {
    for (const key in cache) {
        delete cache[key];
    }
    cache.models = {};
    cache.versions = {};
};
