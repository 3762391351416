import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";

import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { Lazy } from "@/components/Lazy";
import { Photo360 } from "@/components/Photo360";
import { CAIA_GUI_SELECTOR_IMAGE_URL } from "@/config/settings";
import { useAPI, useLanguage } from "@/utils/hooks";

type ItemLabel = {
    label: string;
    frontRear: string;
    leftRight: string;
};

type Props = {
    vehicleData: VehicleData;
    url: string;
};

const GuiSelectorSelect = (props: Props): JSX.Element => {
    const { vehicleData, url } = props;
    const { t } = useTranslation();
    const lang = useLanguage();
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const [photoNb, setPhotoNb] = React.useState(1);

    const { data: itemIds } = useAPI<Record<number, string[]>>("katia/ids", {
        initialData: {},
    });

    const { data: vehicleId } = useAPI<number>(
        vehicleData.id_vehiculo_kromeda || !vehicleData.ktype
            ? null
            : `katia/vehicleId/${vehicleData.ktype}`,
        { initialData: vehicleData.id_vehiculo_kromeda }
    );

    const { data: itemLabels } = useAPI<Record<number, ItemLabel>>(
        vehicleId ? `katia/labels/${lang}/${vehicleId}` : null,
        { initialData: {} }
    );

    const partsKatia = (itemIds[photoNb] || [])
        .filter((id) => itemLabels[Number(id)])
        .map((id) => ({ id, ...itemLabels[Number(id)] }));

    const id_vehiculo_kromeda = vehicleData.id_vehiculo_kromeda || vehicleId;
    const updatedVehicleData = Object.assign({}, vehicleData, {
        id_vehiculo_kromeda,
    });

    const submitGuiSelector = (values: Record<string, boolean>): void => {
        const ids = partsKatia
            .map((part) => part.id)
            .filter((id) => values[id]);
        if (ids.length === 0) {
            return;
        }
        const newURL = `${url}/katia/${ids.join("-")}`;
        history.push(newURL);
    };

    return (
        <form
            className="details gui-selector d-flex"
            onSubmit={handleSubmit(submitGuiSelector)}
        >
            <ul>
                {partsKatia.map((parts) => (
                    <li key={parts.id}>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked
                                {...register(parts.id)}
                            />{" "}
                            <span>{parts.label}</span>
                            <span>
                                {parts.frontRear || parts.leftRight
                                    ? " - "
                                    : ""}
                            </span>
                            <span>{parts.frontRear}</span>
                            <span>{" " + parts.leftRight}</span>
                        </label>
                    </li>
                ))}
            </ul>
            <div className="d-flex align-items-end justify-content-end">
                {partsKatia.length > 0 && (
                    <Button color="primary">{t("common.submit")}</Button>
                )}
                <Photo360
                    vehicleData={updatedVehicleData}
                    onChange={setPhotoNb}
                />
            </div>
        </form>
    );
};

export const GSSApp = ({
    vehicleData,
}: {
    vehicleData: VehicleData;
}): JSX.Element => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const isSelected = pathname.endsWith("katia");

    return (
        <li className={isSelected ? "big-li selected" : ""}>
            <Link
                className="family-album tooltip-arrow"
                to={isSelected ? url : `${url}/katia`}
            >
                <img src={CAIA_GUI_SELECTOR_IMAGE_URL} alt="" />
                <div>{t("albums.C@iA-gui-selector")}</div>
            </Link>
            <Lazy visible={isSelected}>
                <GuiSelectorSelect vehicleData={vehicleData} url={url} />
            </Lazy>
        </li>
    );
};
