import * as React from "react";
import { useForm } from "react-hook-form";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Restricted } from "@/components/Restricted";
import { SlidesStack, useSlides } from "@/components/SlidesStack";

import { LevamApplicability } from "./LevamApplicability";

import "./PartSelectorOE.css";

export const PartSelectorOE = () => (
    <Restricted access="PartSelectorOE">
        <PSOEContent />
        <SlidesStack className="iframe-wrapper iframe-partselector" />
    </Restricted>
);

const PSOEContent = () => {
    return (
        <div className="inner-content">
            <div className="part-selector-oe-top">
                <SearchField />
            </div>
            <div className="main-bloc part-selector-oe"></div>
        </div>
    );
};

type FormData = {
    refOEM: string;
};
const SearchField = () => {
    const { register, handleSubmit } = useForm<FormData>();
    const { addSlide } = useSlides();
    const onSubmit = ({ refOEM }: FormData) => {
        if (!refOEM) return;
        addSlide({
            id: `PartSelectorOE-${refOEM}`,
            content: <LevamApplicability refOEM={refOEM} />,
        });
    };
    return (
        <form className="d-flex flex-grow-1" onSubmit={handleSubmit(onSubmit)}>
            <input
                type="text"
                {...register("refOEM")}
                className="flex-grow-1"
            />
            <button className="btn btn-outline-secondary ms-2" type="submit">
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </form>
    );
};
