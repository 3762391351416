import * as React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { faFileVideo } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth, useUpdateUser } from "@/context/AuthContext";
import { fetchWithAuth } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

export const SetCompanyCoverVideo = (): JSX.Element | null => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [videoSrc, setVideoSrc] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const { t } = useTranslation();
    const { company, userInfo, hasAccess } = useAuth();
    const { setUserInfo } = useUpdateUser();

    if (!hasAccess("changeCompanyCover") || !company) {
        return null;
    }

    const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setVideoSrc(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    const toggle = () => {
        setVideoSrc("");
    };

    const submitPicture = () => {
        const file = inputRef.current?.files?.[0];
        if (isSubmitting || !file) return;
        const formData = new FormData();
        formData.append("video", file);
        setIsSubmitting(true);
        fetchWithAuth(`set-company-video/${company.id}`, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then(({ filename }) => {
                setIsSubmitting(false);
                company.video = filename;
                setUserInfo(userInfo);
                toggle();
            })
            .catch((error) => {
                console.error(error);
                setIsSubmitting(false);
                toastr.error(t("common.error"));
            });
    };

    return (
        <>
            <button
                className="btn p-1 me-2"
                onClick={() => inputRef.current?.click()}
            >
                <FontAwesomeIcon icon={faFileVideo} size="2x" />
            </button>
            <input
                type="file"
                className="d-none"
                accept="video/*"
                ref={inputRef}
                onChange={fileChangeHandler}
            />
            <Modal
                isOpen={videoSrc !== ""}
                toggle={toggle}
                size="xl"
                className="max-height-modal"
            >
                <ModalBody>
                    <video
                        controls
                        controlsList="nodownload"
                        muted
                        autoPlay
                        loop
                        src={videoSrc}
                        className="mw-100"
                    />
                </ModalBody>
                <ModalFooter>
                    <div>
                        {isSubmitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="me-4"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCheck}
                                role="button"
                                size="2x"
                                className="me-4 text-success"
                                onClick={submitPicture}
                            />
                        )}
                        <FontAwesomeIcon
                            icon={faTimes}
                            role="button"
                            onClick={toggle}
                            size="2x"
                            className="text-secondary"
                        />
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};
