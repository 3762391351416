import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { faAccusoft } from "@fortawesome/free-brands-svg-icons";
import { faStar as emptyStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useSetRecoilState } from "recoil";

import type { Bookmark } from "shared/dist/types/types";
import type { CompletionEntry } from "shared/dist/types/types";
import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { i18n } from "@/config/i18n";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useUserSettingsContext } from "@/context/UserSettingsContext";
import { emptyVehicleData, useVehicleData } from "@/context/VehicleDataContext";
import {
    astucesContentOpen,
    astucesState,
    loadingState,
} from "@/globalState/atoms";
import { renderSuggestion } from "@/pages/CarSelector/CarSelectorSearch";
import { FetchError, getContent } from "@/utils/fetch";
import { useActiaSession, useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";
import { getLogoURL, replaceIgnoreCase } from "@/utils/utils";

import type { Astuce } from "./Astuces";
import { Autocomplete } from "./Autocomplete";
import { BarcodeReaderButton } from "./BarcodeScanner";
import { ErrorBoundary } from "./ErrorBoundary";
import { LPRButton } from "./LicencePlateRecognition";
import { IdCarReaderButton } from "./QRCodeReader";
import { SwissVehicles } from "./SwissVehicles";
import { VehicleDataBloc } from "./VehicleDataBloc";

import "./IdentifyVehicle.css";

const { useState, useEffect, useRef, useCallback } = React;

type SearchForm = {
    activeField?:
        | "immat"
        | "ktype"
        | "ntypnr"
        | "numLivrePolice"
        | "swissPlate"
        | "targa"
        | "vin"
        | "vehiculeTechnique"
        | "ebayMoto";
    immat: string;
    vin: string;
    ktype: string;
    ntypnr: string;
    swissPlate: string;
    targa: string;
    vehiculeTechnique: string;
    numLivrePolice: string;
    ebayMoto: string;
};

const EMPTY_SEARCH_FORM: SearchForm = {
    immat: "",
    swissPlate: "",
    vin: "",
    vehiculeTechnique: "",
    numLivrePolice: "",
    ktype: "",
    ntypnr: "",
    targa: "",
    ebayMoto: "",
};

const autocomplete =
    (type: string) =>
    (input: string): Promise<CompletionEntry[]> =>
        getContent<CompletionEntry[]>(
            `completion/${type}/${encodeURIComponent(input)}`
        ).catch((error) => {
            console.error(error);
            return [];
        });

type Suggestion = CompletionEntry & {
    make: string;
    type: "vl" | "pl" | "moto";
};
const autocompleteTypnr =
    (type: "ktype" | "ntypnr" | "ebayMoto") => (input: string) =>
        getContent<Suggestion[]>(
            `completion/${type}/${encodeURIComponent(input)}`
        ).catch((error) => {
            console.log(error);
            return [] as Suggestion[];
        });

const getVehicleLabel = (data: VehicleData): string =>
    `${data.Marque} ${data.Modèle} ${data.Version}`;

type SwissPlateProps = {
    plate: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    modalOpen: boolean;
    toggleModal: () => void;
    setPlateType: (type: PlateType) => void;
    prefix?: string;
};

export const SwissPlate = ({
    plate,
    handleChange,
    modalOpen,
    toggleModal,
    setPlateType,
    prefix,
}: SwissPlateProps): JSX.Element => {
    const { hasAccess } = useAuth();
    const { t } = useTranslation();
    return (
        <div className="swiss-plate">
            <input
                type="text"
                name="swissPlate"
                value={plate}
                onChange={handleChange}
            />
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" scrollable>
                <ModalHeader toggle={toggleModal}>
                    {t("caridentifier.vehicles-for-plate", { plate })}
                </ModalHeader>
                <ModalBody>
                    <div className="swiss-vehicles p-3">
                        <SwissVehicles plate={plate} prefix={prefix} />
                    </div>
                </ModalBody>
            </Modal>
            {hasAccess("immatFR") && (
                <button
                    className="btn btn-none"
                    type="button"
                    onClick={() => setPlateType("fr")}
                >
                    <img
                        src={`${IMAGES_SERVER}/countryFlags/fr.svg`}
                        width="35"
                        alt=""
                    />
                </button>
            )}
        </div>
    );
};

type MotoSuggestion = CompletionEntry & {
    make: string;
    type: "FR_SCOOTERS" | "FR_MOTORCYCLES";
};

const renderMotoSuggestion = (
    suggestion: Partial<MotoSuggestion>,
    { query }: { query: string }
): JSX.Element => (
    <div className="d-flex align-items-center">
        {suggestion.make && (
            <div className="img-app-small me-2">
                <img src={getLogoURL(suggestion.make, "motos")} alt="" />
            </div>
        )}
        <div>
            <span
                dangerouslySetInnerHTML={{
                    __html: replaceIgnoreCase(
                        suggestion.label ?? "",
                        query,
                        '<span class="autosuggest-query">' + query + "</span>"
                    ),
                }}
            />
            <img
                src={
                    suggestion.type === "FR_MOTORCYCLES"
                        ? `${IMAGES_SERVER}/picto/moto.png`
                        : `${IMAGES_SERVER}/picto/scooter.jpg`
                }
                width={30}
                className="ms-1"
            />
        </div>
    </div>
);

export type PlateType = "fr" | "ch" | null;

export const IdentifyVehicle = (): JSX.Element => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const lang = useLanguage();
    const bookmarks = useRef<Bookmark[]>(
        JSON.parse(localStorage.bookmarks || "[]")
    );
    const { hasAccess } = useAuth();

    const [plateType, setPlateType] = useState<PlateType>(
        hasAccess("immatFR") ? "fr" : hasAccess("immatCH") ? "ch" : null
    );
    const [isDiagModalOpen, setDiagModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isBookmarked, setBookmarked] = useState(
        bookmarks.current.findIndex((v) => v.url === location.pathname) !== -1
    );

    const { field: fieldParam, value: valueParam } = useParams<{
        field: SearchForm["activeField"];
        value: string;
    }>();
    const initialSearchForm = { ...EMPTY_SEARCH_FORM };
    if (fieldParam) initialSearchForm[fieldParam] = valueParam;
    const [searchForm, updateSearchForm] = useState(initialSearchForm);
    const immatOrVinSearched = fieldParam === "immat" || fieldParam === "vin";
    const [vehicleData, updateVehicleData] = useVehicleData();
    const hasSearched = vehicleData !== emptyVehicleData;

    useEffect(() => {
        setBookmarked(
            bookmarks.current.findIndex((v) => v.url === location.pathname) !==
                -1
        );
    }, [location.pathname]);

    const lastSearchParams = useRef("");
    const isSearching = useRef(false);

    const setAstuces = useSetRecoilState(astucesState);
    const setAstucesContentOpen = useSetRecoilState(astucesContentOpen);
    const updateAstuces = useCallback(
        (newAstuces: Astuce[]): void => {
            setAstuces(newAstuces);
            if (newAstuces.length > 0) {
                const plural = newAstuces.length > 1 ? "s" : "";
                const tipsFoundMessage = i18n.t(
                    "identification.toast.tips-found",
                    {
                        nb: newAstuces.length,
                        plural,
                    }
                );
                toast.info(tipsFoundMessage, {
                    onClick: () => {
                        setAstucesContentOpen(true);
                    },
                });
            }
        },
        [setAstuces, setAstucesContentOpen]
    );
    const [userSettings, setUserSetting] = useUserSettingsContext();

    const toggleBookmark = (): void => {
        const url = location.pathname;
        if (isBookmarked) {
            console.log("Removing from bookmarks");
            const index = bookmarks.current.findIndex(
                (version) => version.url === url
            );
            if (index !== -1) {
                bookmarks.current.splice(index, 1);
                localStorage.bookmarks = JSON.stringify(bookmarks.current);
            }
        } else {
            console.log("Adding to bookmarks");
            const label = getVehicleLabel(vehicleData);
            bookmarks.current.push({ url, label });
            localStorage.bookmarks = JSON.stringify(bookmarks.current);
        }
        setBookmarked((b) => !b);
    };

    const toggleDiagModal = (): void => setDiagModalOpen((o) => !o);
    const toggleModal = () => setModalOpen((o) => !o);
    const setLoading = useSetRecoilState(loadingState);

    const search = useCallback(
        (field: SearchForm["activeField"], value: string) => {
            if (!value || isSearching.current) return;
            isSearching.current = true;
            setLoading(true);
            console.log(`Searching for ${field} ${value}`);
            getContent<VehicleData>(`${field}/${value}`)
                .then((data) => {
                    isSearching.current = false;
                    setLoading(false);
                    if (!data || Object.keys(data).length === 0) {
                        updateVehicleData(emptyVehicleData);
                        toastr.info(
                            i18n.t("common.no-results-for", { search: value })
                        );
                        return;
                    }
                    updateVehicleData(data);
                    const message = i18n.t("identification.toast.success", {
                        value,
                    });
                    toastr.success(message);
                    const url = `/identification-vehicule/search/${field}/${value}`;
                    if (!location.pathname.startsWith(url)) {
                        history.push(url);
                    }
                    const previousSearches: Bookmark[] = JSON.parse(
                        localStorage.previousSearches || "[]"
                    );
                    // use Map to remove duplicates from previous searches
                    const map = new Map<string, string>();
                    for (const previousSearch of previousSearches) {
                        map.set(previousSearch.url, previousSearch.label);
                    }
                    map.delete(url);
                    const searches = [...map.keys()].map((key) => ({
                        url: key,
                        label: map.get(key),
                    }));
                    const label = `${data.Marque} ${data.Modèle} ${data.Version} ${data.Énergie} ${data["Année 1ère MEC"]}`;
                    searches.unshift({ url, label });
                    localStorage.previousSearches = JSON.stringify(searches);
                    if (!data.ktype) {
                        toastr.warning(i18n.t("identification.toast.no-ktype"));
                    }
                    const dateMEC = `${data["Année 1ère MEC"]}-${data["Mois 1ère MEC"]}-${data["Jour 1ère MEC"]}`;
                    if (!data.Type || !data["Année 1ère MEC"]) {
                        return;
                    }
                    getContent<Astuce[]>(`astuces/${data.Type}/${dateMEC}`)
                        .then((astuces) => {
                            updateAstuces(astuces);
                            if (astuces.length === 0) {
                                toastr.warning(
                                    i18n.t("identification.toast.no-tip-found")
                                );
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            updateAstuces([]);
                        });
                })
                .catch((error) => {
                    isSearching.current = false;
                    console.error(error);
                    updateVehicleData(emptyVehicleData);
                    setLoading(false);
                    const message = i18n.t("identification.toast.error", {
                        value,
                    });
                    toastr.error(message);
                    history.push(`/identification-vehicule`);
                });
        },
        [
            setLoading,
            updateVehicleData,
            location.pathname,
            history,
            updateAstuces,
        ]
    );

    const searchKtype = useCallback(
        (ktype: string) => {
            if (isSearching.current) return;
            isSearching.current = true;
            type Result = {
                hmdnrs: number[];
                marque: string;
                modele: string;
                mid: string;
            };
            getContent<Result>(`vehicle/data/${ktype}`)
                .then(({ hmdnrs, marque, modele, mid }) => {
                    isSearching.current = false;
                    const hmd_nr =
                        Array.isArray(hmdnrs) && hmdnrs.length > 0
                            ? hmdnrs.join("-")
                            : "Not found";
                    const Modèle = (modele || "").replace(/\//g, "");
                    updateVehicleData({
                        hmd_nr,
                        ktype,
                        Marque: marque,
                        Modèle,
                        Mid_Autodata: mid,
                    });
                })
                .catch(() => {
                    isSearching.current = false;
                });
            updateAstuces([]);
            updateVehicleData(emptyVehicleData);
        },
        [updateAstuces, updateVehicleData]
    );

    const searchNtypnr = useCallback(
        async (ntypnr: string) => {
            if (isSearching.current) return;
            isSearching.current = true;
            const { make, model } = await getContent<{
                make: string;
                model: string;
            }>(`cvMakeAndModel/${ntypnr}`);
            isSearching.current = false;
            updateVehicleData({
                Marque: make,
                Modèle: model,
                ktype: ntypnr,
                ntypnr: Number(ntypnr),
            });
        },
        [updateVehicleData]
    );

    useEffect(() => {
        const searchParams = `${fieldParam}/${valueParam}`;
        if (
            isSearching.current ||
            !valueParam ||
            !fieldParam ||
            searchParams === lastSearchParams.current
        ) {
            return;
        }
        lastSearchParams.current = `${fieldParam}/${valueParam}`;
        updateSearchForm({
            ...EMPTY_SEARCH_FORM,
            [fieldParam]: valueParam,
            activeField: fieldParam,
        });
        if (fieldParam === "ktype") {
            searchKtype(valueParam);
        } else if (fieldParam === "ntypnr") {
            searchNtypnr(valueParam);
        } else if (fieldParam === "targa") {
            getContent<string>(`targa/${valueParam}`)
                .then((data) => {
                    if (data) {
                        searchKtype(data);
                    } else {
                        toastr.info(
                            i18n.t("common.no-results-for", {
                                search: valueParam,
                            })
                        );
                    }
                })
                .catch((error: unknown) => {
                    if (error instanceof FetchError && error.status === 403) {
                        toastr.info(i18n.t("common.unauthorized"));
                    } else {
                        toastr.error(i18n.t("common.error"));
                    }
                });
        } else {
            search(fieldParam, valueParam);
        }
    }, [fieldParam, valueParam, search, searchKtype, searchNtypnr]);

    useActiaSession(vehicleData);

    const handleChange = ({
        currentTarget,
    }: React.ChangeEvent<HTMLInputElement>): void => {
        updateSearchForm({
            ...EMPTY_SEARCH_FORM,
            [currentTarget.name]: currentTarget.value,
            activeField: currentTarget.name as SearchForm["activeField"],
        });
    };

    const handleAutosuggestChange =
        (activeField: SearchForm["activeField"]) =>
        (
            event: React.FormEvent<HTMLElement>,
            { newValue }: { newValue: string }
        ): void => {
            if (!activeField) {
                return;
            }
            updateSearchForm({
                ...EMPTY_SEARCH_FORM,
                [activeField]: newValue,
                activeField,
            });
        };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (!searchForm.activeField) {
            return;
        }

        const field = searchForm.activeField;
        const value = searchForm[field];
        if (
            ["vin", "immat", "swissPlate"].includes(field) &&
            field !== userSettings.searchType
        ) {
            setUserSetting("searchType", field);
        }
        if (field === "swissPlate") {
            if (value) {
                setModalOpen(true);
            }
            return;
        }
        history.push(`/identification-vehicule/search/${field}/${value}`);
    };

    const resetState = (): void => {
        updateSearchForm(EMPTY_SEARCH_FORM);
        setModalOpen(false);
        lastSearchParams.current = "";
        updateVehicleData(emptyVehicleData);
    };

    const showImmatAndVin = !hasSearched || immatOrVinSearched;
    const showOtherInputs = !hasSearched || !immatOrVinSearched;

    const handleBarcode = (vin: string) => {
        search("vin", vin);
    };

    const handleQRCode = (vin: string) => {
        history.push(`/identification-vehicule/search/vin/${vin}`);
    };

    const handleImmat = (immat: string) => {
        updateSearchForm({
            ...EMPTY_SEARCH_FORM,
            activeField: "immat",
            immat,
        });
    };

    const className = classnames("identification main-bloc", {
        "hide-bloc": userSettings.identifyVehicle === "hide",
    });

    return (
        <div className={className}>
            <ErrorBoundary>
                <form id="searchForm" onSubmit={handleSearchSubmit}>
                    {showImmatAndVin && (
                        <>
                            {plateType != null && (
                                <label htmlFor="immat">
                                    {t("vehicle-data.licence-plate")}
                                </label>
                            )}
                            {plateType === "fr" && (
                                <div className="immatriculation">
                                    <div className="immat-wrapper">
                                        <input
                                            type="text"
                                            id="immat"
                                            name="immat"
                                            maxLength={11}
                                            onChange={handleChange}
                                            value={searchForm.immat}
                                        />
                                    </div>
                                    {lang !== "fr" && (
                                        <div className="country-led" />
                                    )}
                                    <div className="plate-buttons">
                                        {hasAccess("immatCH") && (
                                            <button
                                                className="btn btn-none"
                                                type="button"
                                                onClick={() =>
                                                    setPlateType("ch")
                                                }
                                            >
                                                <img
                                                    src={`${IMAGES_SERVER}/countryFlags/ch.svg`}
                                                    width="35"
                                                    alt=""
                                                />
                                            </button>
                                        )}
                                        <LPRButton onSuccess={handleImmat} />
                                    </div>
                                </div>
                            )}
                            {plateType === "ch" && (
                                <SwissPlate
                                    plate={searchForm.swissPlate}
                                    handleChange={handleChange}
                                    modalOpen={modalOpen}
                                    toggleModal={toggleModal}
                                    setPlateType={setPlateType}
                                />
                            )}
                            <div className="form-group">
                                <label htmlFor="vin">
                                    {t("vehicle-data.vin")}
                                </label>
                                <div className="d-flex align-items-center">
                                    <Autocomplete
                                        value={searchForm.vin}
                                        onChange={handleAutosuggestChange(
                                            "vin"
                                        )}
                                        getSuggestions={autocomplete("vin")}
                                        placeholder={t(
                                            "vehicle-data.vin-placeholder"
                                        )}
                                        renderSuggestion={renderSuggestion}
                                    />
                                    <IdCarReaderButton cb={handleQRCode} />
                                    <BarcodeReaderButton
                                        type="vin"
                                        onSubmit={handleBarcode}
                                        title="VIN Scanner"
                                        showQRCode
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {showOtherInputs && (
                        <div className="form-group">
                            <label htmlFor="vehiculeTechnique">
                                {t("vehicle-data.tvv")}
                            </label>
                            <div className="d-flex">
                                <img
                                    src={`${IMAGES_SERVER}/countryFlags/fr.svg`}
                                    width="40"
                                    alt=""
                                    className="me-2"
                                />
                                <Autocomplete
                                    value={searchForm.vehiculeTechnique}
                                    onChange={handleAutosuggestChange(
                                        "vehiculeTechnique"
                                    )}
                                    getSuggestions={autocomplete("tvv")}
                                    placeholder={t(
                                        "vehicle-data.tvv-placeholder"
                                    )}
                                    renderSuggestion={renderSuggestion}
                                />
                            </div>
                        </div>
                    )}
                    {showOtherInputs && hasAccess("targa") && (
                        <div className="form-group">
                            <label htmlFor="targa">Targa</label>
                            <div className="d-flex">
                                <img
                                    src={`${IMAGES_SERVER}/countryFlags/ch.svg`}
                                    width="40"
                                    alt=""
                                    className="me-2"
                                />
                                <Autocomplete
                                    value={searchForm.targa}
                                    onChange={handleAutosuggestChange("targa")}
                                    getSuggestions={autocomplete("targa")}
                                    placeholder="Exemple: 1AA…"
                                    renderSuggestion={renderSuggestion}
                                />
                            </div>
                        </div>
                    )}
                    {!hasSearched && hasAccess("LivrePolice") && (
                        <div className="form-group">
                            <label htmlFor="numLivrePolice">
                                {t("vehicle-data.police-book-number")}
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                id="numLivrePolice"
                                name="numLivrePolice"
                                onChange={handleChange}
                                value={searchForm.numLivrePolice}
                                disabled
                            />
                        </div>
                    )}
                    {showOtherInputs && hasAccess("typnr") && (
                        <>
                            <div className="form-group">
                                <label htmlFor="ktype">
                                    {t("vehicle-data.ktype-label")}
                                </label>
                                <Autocomplete
                                    value={searchForm.ktype}
                                    onChange={handleAutosuggestChange("ktype")}
                                    getSuggestions={autocompleteTypnr("ktype")}
                                    placeholder={t(
                                        "vehicle-data.ktype-placeholder"
                                    )}
                                    pattern="[0-9]+"
                                    renderSuggestion={renderSuggestion}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ktype">NTYPNR</label>
                                <Autocomplete
                                    value={searchForm.ntypnr}
                                    onChange={handleAutosuggestChange("ntypnr")}
                                    getSuggestions={autocompleteTypnr("ntypnr")}
                                    placeholder={t(
                                        "vehicle-data.ktype-placeholder"
                                    )}
                                    pattern="[0-9]+"
                                    renderSuggestion={renderSuggestion}
                                />
                            </div>
                        </>
                    )}
                    {hasAccess("ebayMoto") && (
                        <div className="form-group">
                            <label>eBay Moto</label>
                            <Autocomplete
                                value={searchForm.ebayMoto}
                                onChange={handleAutosuggestChange("ebayMoto")}
                                getSuggestions={autocompleteTypnr("ebayMoto")}
                                placeholder={t(
                                    "vehicle-data.ktype-placeholder"
                                )}
                                pattern="[0-9]+"
                                renderSuggestion={renderMotoSuggestion}
                            />
                        </div>
                    )}
                    <button className="btn btn-primary" type="submit">
                        {t("common.search")}
                    </button>
                    {hasSearched && (
                        <>
                            <Link
                                to="/identification-vehicule"
                                title={t("identification.switch-vehicle")}
                                onClick={resetState}
                            >
                                <button
                                    className="btn btn-danger switch-vehicle"
                                    type="submit"
                                >
                                    <FontAwesomeIcon icon="certificate" />
                                </button>
                            </Link>
                            {hasAccess("DiagButton") && (
                                <button
                                    className="btn btn-diag"
                                    type="button"
                                    onClick={toggleDiagModal}
                                >
                                    <FontAwesomeIcon icon={faAccusoft} />{" "}
                                    {t("leftmenu.diag")}
                                </button>
                            )}
                            <FontAwesomeIcon
                                className="star"
                                icon={isBookmarked ? "star" : emptyStar}
                                size="2x"
                                onClick={toggleBookmark}
                            />
                        </>
                    )}
                    <Modal
                        isOpen={isDiagModalOpen}
                        toggle={toggleDiagModal}
                        className="diag-modal"
                    >
                        <ModalHeader toggle={toggleDiagModal}>
                            <FontAwesomeIcon icon={faAccusoft} />{" "}
                            {t("leftmenu.diag")} {vehicleData.Codif_Vin_PRF}
                        </ModalHeader>
                        <ModalBody>
                            <iframe
                                title="Diag Modal"
                                src="https://run.ia.parts"
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        </ModalBody>
                    </Modal>
                </form>
                <VehicleDataBloc />
            </ErrorBoundary>
        </div>
    );
};
