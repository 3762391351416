import * as React from "react";
import ImageGallery from "react-image-gallery";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { IMAGES_SERVER } from "@/config/settings";

type Props = {
    imgList: string[];
    title: string;
    big?: boolean;
};

export const PartImg = (props: Props): JSX.Element => {
    const [modalOpen, setModalOpen] = React.useState(false);

    const toggleModal = (): void => {
        setModalOpen((open) => !open);
    };
    const clickHandler = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation(); // This prevents interference with row onClick event
        toggleModal();
    };

    const { imgList, big } = props;
    const imgSize = big ? {} : { height: 60 };
    if (imgList.length === 0) {
        return (
            <img src={`${IMAGES_SERVER}/gif/DEFAULT.png`} alt="" {...imgSize} />
        );
    }
    const items = imgList.map((url) => ({ original: url }));
    return (
        <>
            <img
                src={imgList[0]}
                alt=""
                {...imgSize}
                onClick={clickHandler}
                style={{
                    cursor: "pointer",
                    maxWidth: "100%",
                    maxHeight: 300,
                }}
            />
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>{props.title}</ModalHeader>
                <ModalBody>
                    <ImageGallery
                        items={items}
                        showThumbnails={false}
                        showPlayButton={items.length > 1}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

PartImg.defaultProps = {
    big: false,
};
