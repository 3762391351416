import * as React from "react";
import { useTranslation } from "react-i18next";
import { Form, Nav, TabContent, TabPane } from "reactstrap";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { NavItem } from "@/components/NavItem";
import { toastr } from "@/utils/toastr";

import { Orders } from "./Orders";

const MARGIN_BOTTOM_5 = { marginBottom: "5px" };

const autocomplete = (): Promise<[]> => Promise.resolve([]);

const preventDefault = (evt: React.FormEvent<HTMLFormElement>): void => {
    evt.preventDefault();
};

const tabList = ["Panier", "Commandes", "Livraison", "Expéditions"];

export const ConnectionApps = (): JSX.Element => {
    const [activeTab, setActiveTab] = React.useState(tabList[0]);
    const [value, setValue] = React.useState("");
    const { t } = useTranslation();

    const toggleTab = (tab: string): void => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const onChange = (
        _event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => setValue(newValue);

    const onSuggestionSelected = (
        _evt: unknown,
        { suggestion }: { suggestion: CompletionEntry }
    ): void => {
        toastr.info(suggestion.label, {
            closeButton: true,
            autoClose: false,
        });
    };

    return (
        <div className="connections-apps">
            <div>{t("contactselector.connections")}</div>
            <Form inline style={MARGIN_BOTTOM_5} onSubmit={preventDefault}>
                <FontAwesomeIcon icon={faCircle} />
                <Autocomplete
                    value={value}
                    onChange={onChange}
                    getSuggestions={autocomplete}
                    onSuggestionSelected={onSuggestionSelected}
                />
            </Form>
            <Nav tabs>
                {tabList.map((tabId) => (
                    <NavItem
                        key={tabId}
                        tabId={tabId}
                        toggleTab={toggleTab}
                        activeTab={activeTab}
                    />
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="Panier">
                    <div></div>
                </TabPane>
                <TabPane tabId="Commandes">
                    <Orders />
                </TabPane>
                <TabPane tabId="Livraison">
                    <div></div>
                </TabPane>
                <TabPane tabId="Expéditions">
                    <div></div>
                </TabPane>
            </TabContent>
        </div>
    );
};
