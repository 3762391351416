import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { faCar, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { BarcodeReaderButton } from "@/components/BarcodeScanner";
import { IdentifyVehicle } from "@/components/IdentifyVehicle";
import { IdCarReaderButton } from "@/components/QRCodeReader";
import { SwissVehicles } from "@/components/SwissVehicles";
import { IMAGES_SERVER } from "@/config/settings";
import { useUserSettings } from "@/context/UserSettingsContext";
import { renderSuggestion } from "@/pages/CarSelector/CarSelectorSearch";
import { getContent } from "@/utils/fetch";

const autocomplete = (input: string): Promise<CompletionEntry[]> =>
    getContent<CompletionEntry[]>(
        `completion/vin/${encodeURIComponent(input)}`
    ).catch((error) => {
        console.error(error);
        return [];
    });

const VIN_LENGTH = 17;

const SearchVin = () => {
    const [input, setInput] = React.useState("");
    const { t } = useTranslation();
    const history = useHistory();

    const goToSearchPage = (vin: string) => {
        if (vin.length !== VIN_LENGTH) {
            return;
        }
        history.push(`/identification-vehicule/search/vin/${vin}`);
    };
    const onChange: React.ComponentProps<typeof Autocomplete>["onChange"] = (
        _event,
        { newValue }
    ) => setInput(newValue);
    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        goToSearchPage(input);
    };

    return (
        <form
            className="d-inline-flex align-items-center ms-1"
            onSubmit={onSubmit}
        >
            <Autocomplete
                value={input}
                onChange={onChange}
                getSuggestions={autocomplete}
                placeholder={t("vehicle-data.vin")}
                renderSuggestion={renderSuggestion}
            />
            <IdCarReaderButton cb={goToSearchPage} />
            <BarcodeReaderButton
                type="vin"
                onSubmit={goToSearchPage}
                title="VIN Scanner"
                showQRCode
            />
            <button className="btn btn-outline-secondary ms-2">
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </form>
    );
};

const SearchImmatFR = () => {
    const { register, handleSubmit } = useForm<{ immat: string }>();
    const history = useHistory();
    const onSubmit = ({ immat }: { immat: string }) => {
        history.push(`/identification-vehicule/search/immat/${immat}`);
    };
    return (
        <form
            className="d-inline-flex align-items-center ms-1"
            onSubmit={handleSubmit(onSubmit)}
        >
            <input
                type="text"
                placeholder="Immatriculation"
                maxLength={11}
                className="immat-fr"
                {...register("immat")}
            />
            <img
                src={`${IMAGES_SERVER}/countryFlags/fr.svg`}
                width="35"
                alt=""
                className="ms-2"
            />

            <button className="btn btn-outline-secondary ms-2">
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </form>
    );
};

const SearchImmatCH = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const { register, handleSubmit, watch } = useForm();
    const { t } = useTranslation();
    const plate: string = watch("immat");
    const onSubmit = () => {
        setModalOpen(true);
    };
    const toggleModal = () => setModalOpen((o) => !o);
    return (
        <form
            className="d-inline-flex align-items-center ms-1"
            onSubmit={handleSubmit(onSubmit)}
        >
            <input
                type="text"
                placeholder="Immatriculation"
                maxLength={11}
                className="immat-ch"
                {...register("immat")}
            />
            <img
                src={`${IMAGES_SERVER}/countryFlags/ch.svg`}
                width="35"
                alt=""
                className="ms-2"
            />

            <button className="btn btn-outline-secondary ms-2">
                <FontAwesomeIcon icon={faSearch} />
            </button>
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" scrollable>
                <ModalHeader toggle={toggleModal}>
                    {t("caridentifier.vehicles-for-plate", { plate })}
                </ModalHeader>
                <ModalBody>
                    <div className="swiss-vehicles p-3">
                        <SwissVehicles plate={plate} />
                    </div>
                </ModalBody>
            </Modal>
        </form>
    );
};

const SearchBarContent = (): JSX.Element => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const { searchType = "vin" } = useUserSettings();
    const toggle = () => setModalOpen((o) => !o);

    return (
        <>
            <button className="btn btn-outline-secondary ms-2" onClick={toggle}>
                <FontAwesomeIcon icon={faCar} />
            </button>
            {searchType === "vin" && <SearchVin />}
            {searchType === "immat" && <SearchImmatFR />}
            {searchType === "swissPlate" && <SearchImmatCH />}
            <Modal isOpen={modalOpen} size="lg" toggle={toggle}>
                <ModalBody>
                    <IdentifyVehicle />
                </ModalBody>
            </Modal>
        </>
    );
};

export const SearchBar = (): JSX.Element | null => {
    const userSettings = useUserSettings();
    return userSettings.searchBar === "show" ? <SearchBarContent /> : null;
};
