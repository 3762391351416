import { apiRootURL } from "@/config/settings";
import { getToken } from "@/context/AuthContext";

import { cache } from "./cache";

export class FetchError extends Error {
    status: number;
    constructor(status: number) {
        super();
        this.status = status;
    }
}

export const fetchWithAuth = async (
    path: string,
    options: RequestInit = {}
): Promise<Response> => {
    const Authorization = "Bearer " + getToken();
    const headers = { ...options.headers, Authorization };
    const url = `${apiRootURL}/api/${path}`;
    const res = await fetch(url, { ...options, headers });
    if (!res.ok) {
        throw new FetchError(res.status);
    }
    return res;
};

type GetContentOptions = Partial<{
    ignoreCache: boolean;
}>;

export const getContent = <T,>(
    path: string,
    options?: GetContentOptions
): Promise<T> => {
    const key = "url_" + path;
    if (cache[key] && !options?.ignoreCache) {
        return cache[key] as Promise<T>;
    }
    cache[key] = fetchWithAuth(path)
        .then((res) => res.json())
        .catch((error: unknown) => {
            if (error instanceof FetchError && error.status !== 404) {
                delete cache[key];
            }
            throw error;
        });
    return cache[key] as Promise<T>;
};

export const postContent = (path: string, data: unknown): Promise<Response> =>
    fetchWithAuth(path, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
    });

export const setCachedContent = (path: string, data: unknown): void => {
    const key = "url_" + path;
    cache[key] = Promise.resolve(data);
};

export const clearCacheStartingWith = (path: string): void => {
    Object.keys(cache).forEach((key) => {
        if (key.startsWith(path)) {
            cache[key] = undefined;
        }
    });
};
