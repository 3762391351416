import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, useRouteMatch } from "react-router-dom";
import clsx from "classnames";
import SwiperCore, { Mousewheel, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import {
    getTitleId,
    LEFT_MENU_BUTTONS,
    usePageURL,
} from "@/components/LeftMenu";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { emptyVehicleData, useVehicleData } from "@/context/VehicleDataContext";
import { getLogoURL } from "@/utils/utils";
import { Button360IAM } from "@/v2/Button360IAM";
import { Button360OEM } from "@/v2/Button360OEM";
import { QRCodeButton } from "@/v2/QRCodeButton";
import { CAIA_LOGO_URL } from "@/v2/settings";
import VehicleDataToggler from "@/v2/VehicleDataToggler";

import { CarIdentifier } from "./CarIdentifier";
import { SearchBar, SearchType } from "./SearchBar";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "./MenuSwiper.css";

SwiperCore.use([Mousewheel, Pagination]);

type Props = {
    reduced: boolean;
    onLogoClick: () => void;
};

// Starts at 1 to remove first slide (IdentifyVehicle)
const SLIDES = LEFT_MENU_BUTTONS.slice(1);

const CAIA_LOGO_WHITE = `${IMAGES_SERVER}/logos/C@IA.png`;

const SLIDE_SEARCH_TYPES: Record<string, SearchType> = {
    "identification-vehicule": "vin",
    "selection-vehicule": "carselector",
    pieces: "partselector",
    diag: "diag",
};

export const MenuSwiper = ({ onLogoClick, reduced }: Props): JSX.Element => {
    const [slideId, setSlideId] = React.useState(0);
    const { hasAccess } = useAuth();
    const { t } = useTranslation();
    const getPageURL = usePageURL();
    const [showQRCode, toggleQRCode] = React.useReducer((s) => !s, false);

    const handleSlideChange = (swiper: SwiperCore) => {
        setSlideId(swiper.realIndex);
    };
    const visibleSlides = LEFT_MENU_BUTTONS.filter((slide) =>
        hasAccess(slide.access)
    );
    const searchType = SLIDE_SEARCH_TYPES[visibleSlides[slideId].id];
    const className = clsx("menu-bloc d-flex menu-bloc-v3", {
        invisible: reduced,
    });
    const showButtons =
        visibleSlides[slideId].id === "identification-vehicule" && !reduced;

    return (
        <>
            {!reduced && <SearchBar searchType={searchType} />}
            {showButtons && <VehicleButtons onClick={toggleQRCode} />}
            <div className={className}>
                <MenuLogo onClick={onLogoClick} />
                <Swiper
                    spaceBetween={30}
                    effect="slide"
                    mousewheel
                    direction="vertical"
                    pagination={{ clickable: true }}
                    height={220}
                    onSlideChange={handleSlideChange}
                    initialSlide={slideId}
                >
                    {hasAccess("CarIdentifier") && (
                        <SwiperSlide>
                            <CarIdentifier showQRCode={showQRCode} />
                        </SwiperSlide>
                    )}
                    {SLIDES.filter((slide) => hasAccess(slide.access)).map(
                        (slide) => (
                            <SwiperSlide key={slide.id}>
                                <div className="bloc-slide-title">
                                    {t(getTitleId(slide.id))}
                                </div>
                                <div className="carselector-slide-img">
                                    <Link to={getPageURL(slide.id)}>
                                        <img src={CAIA_LOGO_WHITE} alt="" />
                                    </Link>
                                </div>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </div>
        </>
    );
};

const MenuLogo = ({ onClick }: { onClick(): void }) => {
    const [vehicleData] = useVehicleData();
    const imgSrc = getLogoURL(vehicleData.Marque) || CAIA_LOGO_URL;
    const alt = vehicleData.Marque || "C@iA";

    return (
        <div className="menu-bloc-image visible" onClick={onClick}>
            <img src={imgSrc} alt={alt} />
        </div>
    );
};

const VehicleButtons = ({ onClick }: { onClick(): void }) => {
    const [vehicleData] = useVehicleData();
    const { path } = useRouteMatch();
    if (vehicleData === emptyVehicleData) return null;
    return (
        <Route path={`${path}/search/:field/:value`}>
            <VehicleDataToggler />
            <Button360IAM />
            <Button360OEM />
            <QRCodeButton onClick={onClick} />
        </Route>
    );
};
