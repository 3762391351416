import * as React from "react";
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
} from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { PartsBreadcrumbs } from "@/components/Item/PartsBreadcrumbs";
import { PartsItemContainer } from "@/components/Item/PartsItemContainer";
import { SearchItemContainer } from "@/components/Item/SearchItemContainer";
import { PartSelectorSearch } from "@/components/PartSelectorSearch";
import { Restricted } from "@/components/Restricted";
import { SelectPartBrand } from "@/components/SelectPartBrand";
import { SlidesStack } from "@/components/SlidesStack";
import { iframePartSelector } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { exactSearchAtom } from "@/globalState/atoms";
import { FamilyAlbums } from "@/pages/CarSelector/FamilyAlbums";
import { useLanguage } from "@/utils/hooks";
import { makeHubidlpURL } from "@/utils/utils";

import "./PartSelector.css";

type SearchPartsProps = {
    exactSearchMode: boolean;
    skipRefSearch?: boolean;
};

const SearchParts = ({
    exactSearchMode,
    skipRefSearch = false,
}: SearchPartsProps) => {
    const { value } = useParams<{ value: string }>();
    return (
        <SearchItemContainer
            search={value}
            exactSearchMode={exactSearchMode}
            skipRefSearch={skipRefSearch}
        />
    );
};

const PartsFamilies = () => {
    const { 0: type, brand } = useParams<{ brand: string; 0: string }>();
    const isOE = type === "pieces-oe";
    return (
        <div className="selection-bloc main-bloc">
            <PartsBreadcrumbs isOE={isOE} />
            <FamilyAlbums isOE={isOE} brand={brand} />
        </div>
    );
};

const LanguageIframe = () => {
    const lang = useLanguage(true);
    const iframeSrc = iframePartSelector[lang];
    return (
        <iframe
            title="PartSelector initial slide"
            src={iframeSrc}
            frameBorder="0"
        />
    );
};

const HubIdlp = (): null => {
    const { value } = useParams<{ value: string }>();
    const { hasAccess, userInfo } = useAuth();
    const { addIframeSlide } = useAddItemSlide();
    React.useEffect(() => {
        if (hasAccess("HubIdlp")) {
            addIframeSlide(makeHubidlpURL(userInfo.userId, value));
        }
    }, [addIframeSlide, hasAccess, userInfo.userId, value]);
    return null;
};

const VariableSearchParts = () => {
    const exactSearchMode = useRecoilValue(exactSearchAtom);
    return <SearchParts exactSearchMode={exactSearchMode} />;
};

const PartSelectorSearchV1 = ({
    onSubmit,
}: {
    onSubmit: (search: string) => void;
}) => {
    const [exactSearchMode, setExactSearchMode] =
        useRecoilState(exactSearchAtom);
    const toggle = () => setExactSearchMode((m) => !m);
    return (
        <PartSelectorSearch
            onSubmit={onSubmit}
            exactSearchMode={exactSearchMode}
            toggleSearchMode={toggle}
        />
    );
};

const PartSelectorContent = (): JSX.Element => {
    const { pathname } = useLocation();
    const history = useHistory();

    let className = "inner-content part-selector";
    if (pathname === "/pieces") {
        className += " part-selector-home";
    }

    const searchValue = (ref: string) => {
        const url = `/pieces/search/${encodeURIComponent(ref)}`;
        history.push(url);
    };

    return (
        <React.Fragment>
            <div className={className}>
                <Route path="/(pieces|pieces-oe|pieces-stock)">
                    <PartSelectorSearchV1 onSubmit={searchValue} />
                </Route>
                <ErrorBoundary className="selection-bloc main-bloc">
                    <Switch>
                        <Route path="/pieces/search/:value">
                            <VariableSearchParts />
                            <HubIdlp />
                        </Route>
                        <Route path="/pieces/oes/:value">
                            <SearchParts exactSearchMode skipRefSearch />
                        </Route>
                        <Route path="/(pieces|pieces-oe|pieces-stock)/:brand/parts/:family/:subFamily/:itemId">
                            <PartsItemContainer />
                        </Route>
                        <Route path="/(pieces|pieces-oe|pieces-stock)/:brand">
                            <PartsFamilies />
                        </Route>
                        <Route path="/pieces" exact>
                            <SelectPartBrand />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </div>
            <Route path="/(pieces|pieces-oe|pieces-stock)">
                <SlidesStack className="iframe-wrapper iframe-partselector">
                    <LanguageIframe />
                </SlidesStack>
            </Route>
        </React.Fragment>
    );
};

export const PartSelector = (): JSX.Element => (
    <Restricted access="PartSelector">
        <PartSelectorContent />
    </Restricted>
);
