import type * as React from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContentProps } from "react-toastify";
import { EventSourcePolyfill } from "event-source-polyfill";

import type { SSEData } from "shared/dist/types/sse";

import { apiRootURL } from "@/config/settings";
import { getToken } from "@/context/AuthContext";
import { useSearchParams } from "@/utils/useSearchParams";

export const eventListener: { current: ((data: string) => void) | null } = {
    current: null,
};

type Client = (data: SSEData) => void;
let clientCounter = 0;
const clients = new Map<number, Client>();

export const addClient = (client: Client) => {
    clientCounter++;
    clients.set(clientCounter, client);
    return clientCounter;
};

export const removeClient = (id: number) => clients.delete(id);

const SearchToast = ({
    closeToast,
    data,
    type,
}: Omit<ToastContentProps<string>, "toastProps"> & {
    toastProps?: undefined;
    type: "vin" | "ean";
}) => {
    const history = useHistory();
    const path =
        type === "vin"
            ? "/identification-vehicule/search/vin/"
            : "/pieces/search";
    return (
        <div>
            <div>{`${type.toUpperCase()}: ${data}`}</div>
            <div className="d-flex justify-content-end mt-2">
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => {
                        closeToast?.();
                        history.push(`${path}/${data}`);
                    }}
                >
                    Chercher
                </button>
            </div>
        </div>
    );
};

export const SSEHandler = ({
    headers = { Authorization: "Bearer " + getToken() },
}: {
    headers?: Record<string, string>;
}): JSX.Element | null => {
    useEffect(() => {
        const url = `${apiRootURL}/api/sse${
            headers.Authorization ? "" : "/public"
        }`;
        const events = new EventSourcePolyfill(url, { headers });
        events.addEventListener("open", () => {
            console.log("eventSource open");
        });
        events.addEventListener("error", (error) => console.error(error));
        events.addEventListener("message", (message) => console.log(message));
        // searchVin and searchEAN events are declared in event-source-polyfill.d.ts
        events.addEventListener("searchVin", (event) => {
            console.log("SSE: searchVin received");
            const vin: string = JSON.parse(event.data);
            if (eventListener.current) {
                eventListener.current(vin);
            } else {
                toast.info(<SearchToast data={vin} type="vin" />, {
                    closeButton: true,
                    autoClose: false,
                });
            }
        });
        events.addEventListener("searchEAN", (event) => {
            console.log("SSE: searchEAN received");
            const ean: string = JSON.parse(event.data);
            if (eventListener.current) {
                eventListener.current(ean);
            } else {
                toast.info(<SearchToast data={ean} type="ean" />, {
                    closeButton: true,
                    autoClose: false,
                });
            }
        });
        events.addEventListener("data", (event) => {
            console.log("SSE: data received");
            const data = JSON.parse(event.data);
            for (const client of clients.values()) {
                client(data);
            }
        });
        return () => {
            events.close();
        };
    }, [headers]);

    return null;
};

export const PublicSSEHandler = () => {
    const params = useSearchParams();
    const headers = {
        "caia-customerid": params.get("id") || "",
    };
    return <SSEHandler headers={headers} />;
};

export const ScreenSSEHandler = () => {
    const { id } = useParams<{ id: string }>();
    const headers = {
        "caia-screenid": id,
    };
    return <SSEHandler headers={headers} />;
};
