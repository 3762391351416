import * as React from "react";

type ExpertDataModeContext = [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
];
export const ExpertDataContext = React.createContext<ExpertDataModeContext>([
    false,
    () => {},
]);

export const ExpertDataProvider = (props: {
    children: React.ReactNode;
}): JSX.Element => {
    const expertData = React.useState(false);
    return (
        <ExpertDataContext.Provider value={expertData}>
            {props.children}
        </ExpertDataContext.Provider>
    );
};

export const useExpertDataMode = (): boolean => {
    const [expertDataMode] = React.useContext(ExpertDataContext);
    return expertDataMode;
};
