import * as React from "react";
import { useContext, useEffect, useState } from "react";

import type { App, DBApp } from "shared/dist/types/apps";

import { getContent } from "@/utils/fetch";

export type PageApp = Omit<
    App,
    "widget" | "dateFrom" | "dateTo" | "alertStart" | "alertEnd"
> & {
    widget: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    alertStart: Date | null;
    alertEnd: Date | null;
};

type AppContextValue = [
    PageApp[],
    React.Dispatch<React.SetStateAction<PageApp[]>>
];

const createAppsContext = () =>
    React.createContext<AppContextValue | undefined>(undefined);

const dbAppToPageApp = <PageWidget extends string>(
    app: DBApp & { widget: PageWidget }
): PageApp => {
    const { dateFrom, dateTo, alertStart, alertEnd, widget } = app;
    return {
        ...app,
        widget: widget,
        dateFrom: dateFrom ? new Date(dateFrom) : null,
        dateTo: dateTo ? new Date(dateTo) : null,
        alertStart: alertStart ? new Date(alertStart) : null,
        alertEnd: alertEnd ? new Date(alertEnd) : null,
    };
};

const createAppsProvider = <PageWidget extends string>(
    AppContext: React.Context<AppContextValue | undefined>,
    prefix: string
) => {
    const AppsProvider = ({
        children,
    }: {
        children: React.ReactNode;
    }): JSX.Element => {
        const state = useState<PageApp[]>([]);
        const [, setApps] = state;
        useEffect(() => {
            getContent<DBApp[]>("apps")
                .then((data) => {
                    const apps = data
                        .filter((app): app is DBApp & { widget: PageWidget } =>
                            app.widget.startsWith(prefix)
                        )
                        .map(dbAppToPageApp);
                    setApps(apps);
                })
                .catch((error) => console.error(error));
        }, [setApps]);

        return (
            <AppContext.Provider value={state}>{children}</AppContext.Provider>
        );
    };
    return AppsProvider;
};

const createUseAppsContext = (
    AppsContext: React.Context<AppContextValue | undefined>
) => {
    const useAppsContext = (): AppContextValue => {
        const context = useContext<AppContextValue | undefined>(AppsContext);
        if (!context) {
            throw new Error(
                "useAppsContext must be used inside an AppsProvider"
            );
        }
        return context;
    };
    return useAppsContext;
};

export const appsContextFactory = (prefix: string) => {
    const AppsContext = createAppsContext();
    const AppsProvider = createAppsProvider(AppsContext, prefix);
    const useAppsContext = createUseAppsContext(AppsContext);
    return {
        AppsProvider,
        useAppsContext,
    };
};
