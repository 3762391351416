import * as React from "react";

import { Iframe } from "@/components/Iframe";
import { Restricted } from "@/components/Restricted";
import { WORDPRESS_URL } from "@/config/settings";

export const WordpressIframe = (): JSX.Element => (
    <Restricted access="WordPress">
        <Iframe app="wordpress" url={`${WORDPRESS_URL}/wp-admin?auth=sso`} />
    </Restricted>
);
