import type * as React from "react";
import { useTranslation } from "react-i18next";

import type { Tire } from "shared/dist/types/parts";

import { useAPI } from "@/utils/hooks";

import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";
import { TireStock } from "./TireStock";

import "react-table/react-table.css";
import "./TireStock.css";

const TireStockWrapper = (props: { children: React.ReactNode }) => {
    const { t } = useTranslation();
    return (
        <div className="selection-bloc main-bloc item-bloc">
            <h2>{t("albums.tires")}</h2>
            {props.children}
        </div>
    );
};

type Props = {
    search: string;
};

export const TireStockSearch = ({ search }: Props): JSX.Element => {
    const { t } = useTranslation();
    const { data: tires, error } = useAPI<Tire[]>(
        `tires/${encodeURIComponent(search)}`
    );

    if (error) {
        return (
            <TireStockWrapper>
                <ErrorMessage />
            </TireStockWrapper>
        );
    }

    if (!tires) {
        return (
            <TireStockWrapper>
                <Loading />
            </TireStockWrapper>
        );
    }

    if (tires.length === 0) {
        return (
            <TireStockWrapper>
                <p>{t("common.no-results")}</p>
            </TireStockWrapper>
        );
    }

    return (
        <TireStockWrapper>
            <TireStock tires={tires} />
        </TireStockWrapper>
    );
};
