import { useEffect } from "react";

export const useGoogleOptimize = (): void => {
    useEffect(() => {
        const id = process.env.REACT_APP_GA_OPTIMIZE_ID;
        if (!id) return;
        const g = document.createElement("script");
        const s = document.getElementsByTagName("script")[0];
        g.src = `https://www.googleoptimize.com/optimize.js?id=${id}`;
        s.parentNode?.insertBefore(g, s);
    }, []);
};
