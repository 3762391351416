import { useEffect } from "react";

export const useScript = (url: string): void => {
    useEffect(() => {
        const script = document.createElement("script");

        script.src = url;
        script.defer = true;

        document.body.appendChild(script);

        return (): void => {
            document.body.removeChild(script);
        };
    }, [url]);
};

// Insure script execution order
export const useScripts = (urls: string[]): void => {
    useEffect(() => {
        const scripts = urls.map((url) => {
            const script = document.createElement("script");
            script.src = url;
            script.async = false;
            document.body.appendChild(script);
            return script;
        });

        return (): void => {
            scripts.forEach((script) => document.body.removeChild(script));
        };
    }, [urls]);
};
