"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMotorisation = exports.getVehicleLabel = exports.getDateDCG = exports.getDateMec = exports.getCritAir = exports.getEuroNorm = exports.getCO2Emissions = void 0;
const settings_1 = require("../conf/settings");
const getCO2Emissions = (co2) => {
    for (const step of settings_1.CO2_STEPS) {
        if (co2 < step.index) {
            return step.label;
        }
    }
    return "G";
};
exports.getCO2Emissions = getCO2Emissions;
const getEuroNorm = (dateMec) => {
    for (const step of settings_1.EURO_NORMS) {
        const date = new Date(step.date);
        if (date < dateMec) {
            return step.label;
        }
    }
    return "";
};
exports.getEuroNorm = getEuroNorm;
const getCritAir = (energy, dateMec) => {
    if (!energy) {
        return "";
    }
    if (settings_1.CRITAIR_TYPE_ELECTRIC.includes(energy)) {
        return 0;
    }
    if (settings_1.CRITAIR_TYPE_GAZ_HYBRID.includes(energy)) {
        return 1;
    }
    if (settings_1.CRITAIR_TYPE_ESSENCE.includes(energy)) {
        for (const step of settings_1.CRITAIR_ESSENCE_STEPS) {
            if (new Date(step.date) < dateMec) {
                return step.value;
            }
        }
    }
    if (settings_1.CRITAIR_TYPE_GAZOLE.includes(energy)) {
        for (const step of settings_1.CRITAIR_GAZOLE_STEPS) {
            if (new Date(step.date) < dateMec) {
                return step.value;
            }
        }
    }
    return "";
};
exports.getCritAir = getCritAir;
const getDateMec = (data) => new Date(Number(data["Année 1ère MEC"]), Number(data["Mois 1ère MEC"]) - 1, Number(data["Jour 1ère MEC"]));
exports.getDateMec = getDateMec;
const getDateDCG = (data) => new Date(Number(data.Date_DCG_Annee), Number(data.Date_Dcg_Mois) - 1, Number(data.Date_Dcg_Jour));
exports.getDateDCG = getDateDCG;
const getVehicleLabel = (data) => `${data.Marque} ${data.Modèle} ${data.Version || ""}`;
exports.getVehicleLabel = getVehicleLabel;
const getMotorisation = (data) => {
    const capacity = Math.ceil(Number(data.Cylindree) / 100) / 10;
    const power = data.Puis_Kw || Math.round(Number(data.Puis_Ch) * 0.735);
    return `${capacity} ${data.Énergie} ${data.Code_Moteur} ${data.NB_Cylind}S de ${data.Puis_Ch}ch ${power && power + "kw"} ${data.Puis_Fisc}cv ${data.Turbo_Compr || ""}`;
};
exports.getMotorisation = getMotorisation;
