export const theme = {
    container: {
        position: "relative",
        flexGrow: 1,
    },
    input: {
        display: "block",
        width: "100%",
        padding: "0.375rem 0.75rem",
        fontSize: "1rem",
        lineHeight: 1.5,
        color: "#495057",
        border: "1px solid",
        borderColor: "#ced4da",
        borderRadius: "0.25rem",
    },
    inputFocused: {
        outline: "none",
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
    },
    suggestionsContainer: {
        display: "none",
    },
    suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        top: 35,
        width: "32em",
        border: "1px solid #aaa",
        backgroundColor: "#fff",
        fontFamily: "Helvetica, sans-serif",
        fontWeight: 300,
        fontSize: 16,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        zIndex: 2,
        maxHeight: 500,
        overflowY: "auto",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    suggestion: {
        cursor: "pointer",
        padding: "10px 20px",
    },
    suggestionHighlighted: {
        backgroundColor: "#ddd",
    },
} as const;
