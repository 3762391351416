import * as React from "react";

import { HUB_IDLP_HOST } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";

import "./BackgroundIframe.css";

export const BackgroundIframe = (): JSX.Element | null => {
    const { userInfo } = useAuth();
    if (!process.env.REACT_APP_SHOW_BACKGROUND_HUBIDLP) {
        return null;
    }
    const url = `${HUB_IDLP_HOST}/C@iA-${userInfo.userId}`;
    return (
        <div className="hubidlp-bg">
            <iframe className="h-100 w-100" src={url} />
        </div>
    );
};
