import type * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSlides } from "@/components/SlidesStack";

const BLOC_CLASSNAME =
    "search-item-container item-bloc main-bloc selection-bloc";
const MARGIN_RIGHT_5 = { marginRight: 5 };
const ROTATED = { rotate: 45 };

type Props = {
    search: string;
    title: string;
    isInSlide: boolean | undefined;
    onLastItemClick: () => void;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    children: React.ReactNode;
};

export const SearchItemWrapper = ({
    search,
    title,
    isInSlide,
    onLastItemClick,
    onInputChange,
    children,
}: Props): JSX.Element => {
    const { toggleVisibility } = useSlides();
    const { t } = useTranslation();
    return (
        <div className={BLOC_CLASSNAME}>
            <div className="breadcrumbs-wrapper breadcrumbs-family">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <FontAwesomeIcon
                            icon="barcode"
                            style={MARGIN_RIGHT_5}
                        />
                        {isInSlide ? (
                            <span>{t("leftmenu.pieces")}</span>
                        ) : (
                            <Link to="/pieces">{t("leftmenu.pieces")}</Link>
                        )}
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {t("partselector.search-part")}
                    </BreadcrumbItem>
                    <BreadcrumbItem onClick={onLastItemClick}>
                        {" "}
                        {search}{" "}
                    </BreadcrumbItem>
                    <div className="breadcrumb-filter">
                        <FontAwesomeIcon icon={faFilter} />
                        <input type="text" onChange={onInputChange}></input>
                    </div>
                    {process.env.REACT_APP_SHOW_CARSELECTOR_BACKBUTTON &&
                        !isInSlide && (
                            <Link to="/pieces">
                                <FontAwesomeIcon
                                    className="partselector-cross"
                                    icon={faTimesCircle}
                                />
                            </Link>
                        )}
                    <div className="breadcrumb-img">
                        {isInSlide ? (
                            <FontAwesomeIcon icon={faSearch} />
                        ) : (
                            <Link to="/pieces" className="d-flex flex-grow-1">
                                <FontAwesomeIcon icon={faSearch} />
                            </Link>
                        )}
                    </div>
                </Breadcrumb>
                {!isInSlide && (
                    <div className="breadcrumb-toggle mt-4">
                        <FontAwesomeIcon
                            icon="arrows-alt"
                            onClick={toggleVisibility}
                            transform={ROTATED}
                        />
                    </div>
                )}
            </div>
            <h2>{title}</h2>
            {children}
        </div>
    );
};
