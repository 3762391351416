import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Collapse, Form, Nav, TabContent, TabPane } from "reactstrap";
import {
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faCircle, faStar } from "@fortawesome/free-regular-svg-icons";
import {
    faBoxOpen,
    faHistory,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "classnames";

import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { IMAGES_SERVER, TIRE_SUPPLIERS } from "@/config/settings";
import {
    clearCacheStartingWith,
    getContent,
    postContent,
    setCachedContent,
} from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";
import { usePartsBrands } from "@/utils/usePartsBrands";

import { useAddItemSlide } from "./Item/ItemSlides";
import { getBrandLogoUrl } from "./Item/PartsBreadcrumbs";
import { AppButton } from "./AppButton";
import { AppsWidget } from "./AppsWidget";
import { NavItem } from "./NavItem";

import history from "@/img/history.png";
import star from "@/img/star.png";
import suppliers from "@/img/Suppliers.jpg";

const { useState } = React;
const tabList = ["Premium", "Quality", "1er prix", "Rechappé"] as const;
const MARGIN_BOTTOM_5 = { marginBottom: "5px" };

export const autocompleteTires = (input: string): Promise<CompletionEntry[]> =>
    getContent(`/completion/tires/${encodeURIComponent(input)}`);

const SortablePartBrand = ({ item }: { item: PartBrand }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: item.id });
    const style = { transform: CSS.Transform.toString(transform), transition };
    const brand = item.name;
    const { partsBrands } = usePartsBrands();
    const url = `/pieces/${brand}`;
    const app = { name: brand, url, img: getBrandLogoUrl(partsBrands, brand) };
    const className = clsx({ dragging: isDragging });
    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <AppButton app={app} linkTo={url} className={className} />
        </div>
    );
};

export type PartBrand = {
    id: string | number;
    name: string;
};

const SortablePartBrandContainer = ({
    items,
    onDragEnd,
}: {
    items: PartBrand[];
    onDragEnd(event: DragEndEvent): void;
}) => {
    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 20 } })
    );
    return (
        <DndContext sensors={sensors} onDragEnd={onDragEnd}>
            <SortableContext items={items}>
                <div className="main-brands">
                    {items.map((item) => (
                        <SortablePartBrand item={item} key={item.id} />
                    ))}
                </div>
            </SortableContext>
        </DndContext>
    );
};

const OtherBrands = () => {
    const { partsBrands, otherParts } = usePartsBrands();
    const [value, setValue] = useState("");
    return (
        <div className="other-brands tooltip-content d-flex flex-column">
            <input
                type="text"
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
                className="mb-1 mx-auto"
            />
            <ul className="mb-0">
                {otherParts
                    .filter((part) =>
                        part.toLowerCase().includes(value.toLowerCase())
                    )
                    .map((part) => (
                        <li key={part}>
                            <Link to={`/pieces/${part}`}>
                                <div className="img-app-small me-2 mb-2 d-inline-block">
                                    <img
                                        src={getBrandLogoUrl(partsBrands, part)}
                                        alt=""
                                    />
                                </div>
                                <span>{part}</span>
                            </Link>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export const onTireSelected = (
    evt: unknown,
    { suggestion }: { suggestion: CompletionEntry }
): void => {
    toastr.info(suggestion.label, {
        closeButton: true,
        autoClose: false,
        className: "toast-tire",
    });
};

export const SelectPartBrand = (): JSX.Element => {
    const [otherPartsOpen, setOtherPartsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState<string>(tabList[0]);
    const [searchValue, setSearchValue] = useState("");

    const { t } = useTranslation();
    const { addTireSlide } = useAddItemSlide();
    const { partsBrandsNames, favoritePartsBrands } = usePartsBrands();
    const { data, setData } = useAPI<string[]>("userPartBrands");

    const partBrands =
        data && data.length > 0
            ? data.map((id) => ({ id, name: partsBrandsNames[id] }))
            : favoritePartsBrands;

    const toggleOtherParts = (): void => {
        setOtherPartsOpen((open) => !open);
    };

    const onChange = (
        event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => {
        setSearchValue(newValue);
    };

    const searchTire = (evt: React.FormEvent<HTMLFormElement>): void => {
        evt.preventDefault();
        /*
         * Only keep the first 3 worlds. This allows to get results when
         * searching autosuggestion values
         */
        const search = searchValue.split(/\s+/).slice(0, 3).join(" ");
        addTireSlide(search);
    };

    const onSortEnd = ({ active, over }: DragEndEvent) => {
        const oldIndex = partBrands.findIndex((item) => item.id === active.id);
        const newIndex = partBrands.findIndex((item) => item.id === over?.id);
        const newPartBrands = arrayMove(partBrands, oldIndex, newIndex);
        postContent("setUserPartBrands", {
            partBrands: newPartBrands.map((brand) => brand.id).join(),
        });
        const ids = newPartBrands.map((brand) => brand.id);
        setCachedContent("userPartBrands", ids);
        setData(ids);
        /* Use /a to clear /allArticles and /articles, while not clearing
         * /{lang}. Note: using different routes would be better */
        clearCacheStartingWith("url_parts/vehicle/a");
        clearCacheStartingWith("search_"); // SearchItemContainer
        clearCacheStartingWith("url_vehicle-parts/"); // CarIdentifier
    };

    const displayTiresSuppliers =
        process.env.REACT_APP_SHOW_PARTSELECTOR_TIRE_SUPPLIERS;
    const displayTiresApps = process.env.REACT_APP_SHOW_PARTSELECTOR_TIRE_APPS;

    return (
        <div className="selection-bloc main-bloc selection-brand select-part-brand">
            <div>
                <span>{t("partselector.title-suppliers")}</span>
                <SortablePartBrandContainer
                    items={partBrands}
                    onDragEnd={onSortEnd}
                />
                <div className="brand-buttons">
                    <div className="marque" onClick={toggleOtherParts}>
                        <img src={suppliers} alt="Suppliers" />
                        <FontAwesomeIcon icon={faBoxOpen} />
                        <span> {t("partselector.other-brands")}</span>
                    </div>
                    <div className="marque">
                        <img src={history} alt="history" />
                        <FontAwesomeIcon icon={faHistory} />
                        <span> {t("carselector.history")}</span>
                    </div>
                    <div className="marque">
                        <img src={star} alt="favorites" />
                        <FontAwesomeIcon icon={faStar} />
                        <span> {t("carselector.bookmarks")}</span>
                    </div>
                </div>
                <Collapse isOpen={otherPartsOpen}>
                    <OtherBrands />
                </Collapse>
            </div>
            <div className="apps-catalog">
                <div>{t("partselector.apps-catalog")}</div>
                <AppsWidget widget="parts-catalog" />
            </div>
            <div className="tire-suppliers">
                <div>{t("partselector.title-tire-suppliers")}</div>
                <Form inline style={MARGIN_BOTTOM_5} onSubmit={searchTire}>
                    <FontAwesomeIcon icon={faCircle} />
                    <Autocomplete
                        value={searchValue}
                        onChange={onChange}
                        getSuggestions={autocompleteTires}
                        onSuggestionSelected={onTireSelected}
                    />
                    <Button style={{ marginLeft: 5 }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </Form>
                {displayTiresSuppliers && (
                    <>
                        <Nav tabs>
                            {tabList.map((tabId) => (
                                <NavItem
                                    key={tabId}
                                    tabId={tabId}
                                    toggleTab={setActiveTab}
                                    activeTab={activeTab}
                                />
                            ))}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {tabList.map((tabId) => (
                                <TabPane tabId={tabId} key={tabId}>
                                    <div>
                                        {TIRE_SUPPLIERS[tabId].map(
                                            (supplier) => (
                                                <img
                                                    key={supplier}
                                                    src={`${IMAGES_SERVER}/pneus/${supplier}.png`}
                                                    alt={supplier}
                                                    onClick={(): void =>
                                                        addTireSlide(supplier)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            )
                                        )}
                                    </div>
                                </TabPane>
                            ))}
                        </TabContent>
                    </>
                )}
            </div>
            {displayTiresApps && (
                <div className="main-brands-tire">
                    <div>{t("partselector.title-app-tires")}</div>
                    <AppsWidget widget="parts-tires" />
                </div>
            )}
            <div className="parts-mdd">
                <div>{t("partselector.title-mdd")}</div>
                <AppsWidget widget="parts-mdd" />
            </div>
            <div className="parts-origin">
                <div>{t("partselector.title-constructors")}</div>
                <AppsWidget widget="parts-origin" />
            </div>
        </div>
    );
};
