import * as React from "react";
import classnames from "classnames";

import { Restricted } from "@/components/Restricted";
import { RSESearchBar } from "@/components/RSE/RSESearchBar";
import { SetInitialSlides } from "@/components/RSE/SetInitialSlides";
import { SlidesStack } from "@/components/SlidesStack";

import { PanelLMS } from "./PanelLMS";

export const LMS = (): JSX.Element => {
    const [visible, setVisible] = React.useState(true);
    const className = classnames("iframe-wrapper", { "d-none": !visible });
    const wrapperName = classnames("inner-content", { "d-none": !visible });
    return (
        <Restricted access="LMS">
            <RSESearchBar setVisible={setVisible} />
            <div className={wrapperName}>
                <PanelLMS setVisible={setVisible} />
            </div>
            <SetInitialSlides widget="lms-initial-slides" />
            <SlidesStack className={className} />
        </Restricted>
    );
};
