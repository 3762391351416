import * as React from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import { ContactSelectorSearch } from "@/components/ContactSelectorSearch";
import { Restricted } from "@/components/Restricted";
import { SlidesStack } from "@/components/SlidesStack";
import { iframeContactSelector } from "@/config/settings";
import { useLanguage } from "@/utils/hooks";
import { isIn } from "@/utils/utils";

import { AddNewItem } from "./AddNewItem";
import { AddTipPage } from "./AddTipPage";
import { ContactSelectorPanel } from "./ContactSelectorPanel";
import { CustomSearch } from "./CustomSearch";

export const ContactSelector = (): JSX.Element => {
    const lang = useLanguage();
    const history = useHistory();

    const redirectToSocialPage = (): void => history.push("/social");

    const iframeSrc = isIn(iframeContactSelector, lang)
        ? iframeContactSelector[lang]
        : "";

    return (
        <Restricted access="Social">
            <div className="inner-content contact-selector">
                <ContactSelectorSearch />
                <Switch>
                    <Route path="/social/ajout-astuce">
                        <AddTipPage onClose={redirectToSocialPage} />
                    </Route>
                    <Route path="/social/new-custom-search">
                        <CustomSearch close={redirectToSocialPage} />
                    </Route>
                    <Route path="/social/add-new-item">
                        <AddNewItem close={redirectToSocialPage} />
                    </Route>
                    <Route>
                        <ContactSelectorPanel />
                    </Route>
                </Switch>
            </div>
            <SlidesStack className="iframe-wrapper iframe-contactselector">
                <iframe
                    title="ContactSelector"
                    src={iframeSrc}
                    frameBorder="0"
                    allow="microphone; camera"
                ></iframe>
            </SlidesStack>
        </Restricted>
    );
};
