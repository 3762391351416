import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import "./fontAwesomeLibrary.ts";

import { BackgroundIframe } from "@/components/BackgroundIframe/BackgroundIframe";
import { Copyright } from "@/components/Copyright";
import { Dock } from "@/components/Dock";
import { Header } from "@/components/Header/Header";
import { LeftMenu } from "@/components/LeftMenu";
import { MainContent } from "@/components/MainContent";
import { NotificationRedirect } from "@/components/NotificationRedirect";
import { ParcoursMIDRedirect } from "@/components/ParcoursMIDRedirect";
import { PhoneRedirect } from "@/components/PhoneRedirect";
import { PlusMenu } from "@/components/PlusMenu";
import { SlidesProvider, SlidesStack } from "@/components/SlidesStack";
import { DOCUMENT_TITLE } from "@/config/settings";
import { VehicleDataProvider } from "@/context/VehicleDataContext";
import { AdminIdCar } from "@/pages/AdminIdcar";
import { AppsManager } from "@/pages/AppsManager/AppsManager";
import { CarIdentifier } from "@/pages/CarIdentifier";
import { CarSelector } from "@/pages/CarSelector/CarSelector";
import { ChangePassword } from "@/pages/ChangePassword/ChangePassword";
import { Checkout } from "@/pages/Checkout/Checkout";
import { ConnectedUsers } from "@/pages/ConnectedUsers";
import { ContactSelector } from "@/pages/ContactSelector/ContactSelector";
import { Diag } from "@/pages/Diag/Diag";
import { Home } from "@/pages/Home/Home";
import { LMS } from "@/pages/LMS/LMS";
import { MyAccount } from "@/pages/MyAccount";
import { OESelector } from "@/pages/OESelector/OESelector";
import { PartSelector } from "@/pages/PartSelector/PartSelector";
import { PartSelectorOE } from "@/pages/PartSelectorOE/PartSelectorOE";
import { QRCodesAdmin } from "@/pages/QRCodesAdmin";
import { ContactSelectorRSE } from "@/pages/RSE/ContactSelectorRSE";
import { RSEEditor } from "@/pages/RSEEditor/RSEEditor";
import { ShoppingCart } from "@/pages/ShoppingCart/ShoppingCart";
import { Smart } from "@/pages/Smart/Smart";
import { StatsSelector } from "@/pages/StatsSelector/StatsSelector";
import { StockOccasion } from "@/pages/StockOccasion";
import { StockTechauto } from "@/pages/StockTechauto/StockTechauto";
import { UsersAdminPage } from "@/pages/UsersAdmin/UsersAdmin";
import { PageV3 } from "@/pages/v3/PageV3";
import { WordpressIframe } from "@/pages/WordpressIframe";
import { useChatwoot } from "@/utils/useChatwoot";

import { AppIframe, IframeERP, IframeMyBox } from "./AppIframe";
import { PersistPages } from "./PersistPages";

import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import "./Toastify.css";

import ombreHaut from "@/img/container_bottom_shadow.png";
import ombreBas from "@/img/container_top_shadow.png";

const PartsMap = React.lazy(() =>
    import("@/components/PartsMap/PartsMap").then((comp) => ({
        default: comp.PartsMapPage,
    }))
);

const App = (): JSX.Element => {
    useChatwoot();
    React.useEffect(() => {
        document.title = DOCUMENT_TITLE;
    }, []);

    return (
        <div className="app">
            <PersistPages />
            <img className="ombre-haut2" src={ombreHaut} width="100%" alt="" />
            <ToastContainer
                closeButton={false}
                transition={Slide}
                theme="colored"
            />
            <Header />

            <React.Suspense fallback={<div className="main-content" />}>
                <div className="main-content">
                    <BackgroundIframe />
                    <LeftMenu />

                    <Route path="/call/:phoneNumber">
                        <PhoneRedirect />
                    </Route>
                    <Route path="/parcoursMID/:mid/:genarts/:selectedGenart?">
                        <ParcoursMIDRedirect path="/identification-vehicule" />
                    </Route>
                    <Route path="/notification/:id">
                        <NotificationRedirect />
                    </Route>

                    <MainContent>
                        <Route path="/connectedUsers">
                            <ConnectedUsers />
                        </Route>
                        <Route path="/qrcodes">
                            <QRCodesAdmin />
                        </Route>
                        <SlidesProvider>
                            <Route path="/identification-vehicule">
                                <CarIdentifier />
                            </Route>
                        </SlidesProvider>
                        <Switch>
                            <Route path="/app/mybox">
                                <IframeMyBox />
                            </Route>
                            <Route path="/app/ERP">
                                <IframeERP />
                            </Route>
                            <Route path="/app/:app">
                                <AppIframe />
                            </Route>
                        </Switch>
                        <SlidesProvider>
                            <Route path="/diag">
                                <Diag />
                            </Route>
                        </SlidesProvider>
                        <SlidesProvider>
                            <Route path="/oe-selector">
                                <OESelector />
                            </Route>
                        </SlidesProvider>
                        <SlidesProvider>
                            <Route path="/part-selector-oe">
                                <PartSelectorOE />
                            </Route>
                        </SlidesProvider>
                        <Route path="/mon-compte">
                            <MyAccount />
                        </Route>
                        <SlidesProvider>
                            <Route path="/selection-vehicule">
                                <CarSelector />
                            </Route>
                        </SlidesProvider>
                        <SlidesProvider>
                            <Route path="/stats-selector">
                                <StatsSelector />
                            </Route>
                        </SlidesProvider>
                        <Route path="/stock/techauto">
                            <StockTechauto />
                        </Route>
                        <VehicleDataProvider>
                            <SlidesProvider>
                                <Route path="/(pieces|pieces-oe|pieces-stock)">
                                    <PartSelector />
                                </Route>
                            </SlidesProvider>
                        </VehicleDataProvider>
                        <SlidesProvider>
                            <Route path="/social">
                                <ContactSelector />
                            </Route>
                        </SlidesProvider>
                        <SlidesProvider>
                            <Route path="/rse">
                                <ContactSelectorRSE />
                            </Route>
                        </SlidesProvider>
                        <SlidesProvider>
                            <Route path="/smart">
                                <Smart />
                            </Route>
                        </SlidesProvider>
                        <SlidesProvider>
                            <Route path="/lms">
                                <LMS />
                            </Route>
                        </SlidesProvider>
                        <Route path="/password">
                            <ChangePassword />
                        </Route>
                        <SlidesProvider>
                            <Route path="/cart">
                                <div className="inner-content">
                                    <ShoppingCart />
                                    <SlidesStack className="oes-slider" />
                                </div>
                            </Route>
                        </SlidesProvider>
                        <Route path="/checkout">
                            <Checkout />
                        </Route>
                        <Route path="/admin-idcar">
                            <AdminIdCar />
                        </Route>
                        <Route path="/apps-manager">
                            <AppsManager />
                        </Route>
                        <Route path="/rse-editor">
                            <RSEEditor />
                        </Route>
                        <Route path="/wp">
                            <WordpressIframe />
                        </Route>
                        <SlidesProvider>
                            <Route path="/map">
                                <PartsMap />
                            </Route>
                        </SlidesProvider>
                        <Route path="/stock-occasion">
                            <StockOccasion />
                        </Route>
                        <Route path="/home">
                            <Home />
                        </Route>
                        <Route path="/users">
                            <UsersAdminPage />
                        </Route>
                        <Route path="/v3">
                            <PageV3 />
                        </Route>
                    </MainContent>

                    <PlusMenu />

                    <Dock />
                    <Copyright />
                </div>
            </React.Suspense>
            <img className="ombre-bas2" src={ombreBas} width="100%" alt="" />
        </div>
    );
};

const MemoApp = React.memo(App);
export { MemoApp as CaiaApp };
