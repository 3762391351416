import type * as React from "react";
import { faMask } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { PartsCompletion } from "@/components/PartSelectorSearch";
import { useAuth } from "@/context/AuthContext";
import { makeHubidlpURL } from "@/utils/utils";

export const RSESearchBar = ({
    setVisible,
}: {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element | null => {
    const { hasAccess, userInfo } = useAuth();
    const { addIframeSlide } = useAddItemSlide();
    if (!hasAccess("HubIdlp")) {
        return null;
    }

    const searchRef = (ref: string) => {
        if (ref) {
            setVisible(true);
            setTimeout(() => {
                // Workaround to show the search slide in front of the initial slides
                addIframeSlide(makeHubidlpURL(userInfo.userId, ref));
            }, 200);
        }
    };

    const toggle = () => setVisible((v) => !v);

    return (
        // The class "rse-searchbar" is used in ContactSelectorPanelRSE.tsx, don't remove it
        <PartsCompletion className="rse-searchbar" onSubmit={searchRef}>
            <button
                className="btn btn-outline-secondary ms-2"
                type="button"
                onClick={toggle}
            >
                <FontAwesomeIcon icon={faMask} />
            </button>
        </PartsCompletion>
    );
};
