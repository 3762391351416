import * as React from "react";

import type { OE } from "shared/dist/types/item";

import { AvailabilityCircle } from "@/components/Item/Availability";
import { useAvailability } from "@/utils/availability";

export const Availability = ({
    oe,
    supplier,
}: {
    oe: OE;
    supplier: string;
}): JSX.Element | null => {
    const { availability } = useAvailability(oe);
    if (supplier === "local") {
        const className =
            oe.stock && oe.stock > 0 ? "available" : "unavailable";
        const texte =
            oe.stock && oe.stock > 0 ? `${oe.stock} en stock` : "épuisé";
        return (
            <div>
                <div className={"availability " + className}></div>
                <span>Article local ({`${oe.partType}, ${texte}`})</span>
            </div>
        );
    }
    if (["GPA26", "REPARCAR"].includes(oe.makeId)) {
        return (
            <AvailabilityCircle
                quantity={1}
                from={supplier}
                text="Enseigne"
                itemRef={oe.id}
            />
        );
    }
    const stock = availability.find((data) => data.from === supplier);
    if (!stock || !stock.disponibilite) {
        return null;
    }
    const { disponibilite } = stock;
    return (
        <AvailabilityCircle
            availability={disponibilite}
            from={supplier}
            itemRef={oe.id}
        >
            <span>
                {disponibilite.texte + " " + (disponibilite.heure || "")}
            </span>
        </AvailabilityCircle>
    );
};
