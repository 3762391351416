import type * as React from "react";
import classnames from "classnames";

import { IMAGES_SERVER } from "@/config/settings";

import "./Iphone.css";

export const Iphone = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}): JSX.Element => (
    <div
        className={classnames(
            "katia-device iphone-x position-relative",
            className
        )}
    >
        <img src={`${IMAGES_SERVER}/images/iphone.png`} alt="" height="400px" />
        <div className="screen position-absolute">{children}</div>
    </div>
);
