import * as React from "react";

import { useCustomer } from "@/context/CustomerContext";

import { addClient, removeClient } from "./SSEHandler";

export const RegisterEvents = () => {
    const [, setCustomer] = useCustomer();
    React.useEffect(() => {
        const clientId = addClient((data) => {
            console.log("Event received", data);
            if (data.type === "Customer connected") {
                setCustomer((customer) => ({
                    id: data.customerId,
                    info: null,
                    vehicles: customer?.vehicles || [],
                }));
            } else if (data.type === "Customer data") {
                const info = data.customer;
                setCustomer((customer) => ({
                    id: data.customerId,
                    info,
                    vehicles: customer?.vehicles || [],
                }));
            }
        });
        return () => {
            removeClient(clientId);
        };
    }, [setCustomer]);
    return null;
};
