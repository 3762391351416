import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";

import type { VehicleData } from "shared/dist";

import { i18n } from "@/config/i18n";
import { getContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

import "./StockOccasion.css";

type FormData = {
    brand: string;
    model: string;
    priceMin: string;
    priceMax: string;
};

const search = async (params: FormData) => {
    const brand = encodeURIComponent(params.brand || "");
    const model = encodeURIComponent(params.model || "");
    const price = `${params.priceMin || ""},${params.priceMax || ""}`;
    const url = `vehicle/search?brand=${brand}&model=${model}&price=${price}`;
    const vehicles = await getContent<VehicleData[]>(url);
    return vehicles;
};

const SearchForm = ({
    setVehicles,
}: {
    setVehicles: React.Dispatch<React.SetStateAction<VehicleData[]>>;
}) => {
    const { register, handleSubmit } = useForm<FormData>();
    const submit = async (data: FormData) => {
        try {
            const vehicles = await search(data);
            setVehicles(vehicles);
        } catch (error) {
            setVehicles([]);
            console.error(error);
            toastr.error(i18n.t("common.error"));
        }
    };
    return (
        <div className="search-form">
            <h2>Recherche de véhicule</h2>
            <Form onSubmit={handleSubmit(submit)}>
                <FormGroup row>
                    <Label for="input-brand" sm={4}>
                        Marque
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="text"
                            id="input-brand"
                            {...register("brand")}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="input-model" sm={4}>
                        Modèle
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="text"
                            id="input-model"
                            {...register("model")}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="input-price-min" sm={4}>
                        Prix min
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="number"
                            id="input-price-min"
                            {...register("priceMin")}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="input-price-max" sm={4}>
                        Prix max
                    </Label>
                    <Col sm={8}>
                        <Input
                            type="number"
                            id="input-price-max"
                            {...register("priceMax")}
                        />
                    </Col>
                </FormGroup>
                <Button type="submit">Rechercher</Button>
            </Form>
        </div>
    );
};

const VehicleList = ({ vehicles }: { vehicles: VehicleData[] }) => {
    return (
        <div className="vehicle-list">
            <ul>
                {vehicles.map((vehicle, i) => (
                    <li key={i}>
                        <span className="me-2">{vehicle.Marque}</span>
                        <span className="me-2">{vehicle.Modèle}</span>
                        <span>Prix: {vehicle.Prix_Vehic}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const StockOccasion = (): JSX.Element => {
    const [vehicles, setVehicles] = React.useState<VehicleData[]>([]);
    return (
        <div className="stock-occasion content-box">
            <SearchForm setVehicles={setVehicles} />
            <VehicleList vehicles={vehicles} />
        </div>
    );
};
