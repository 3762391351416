import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Genart } from "shared/types/types";

import { GenartSelect } from "@/components/GenartSelect";

interface Props {
    onSubmit: () => void;
}

const GenartTagSelector = ({ onSubmit }: Props): JSX.Element => {
    const [genarts, setGenarts] = React.useState<ReadonlyArray<Genart>>([]);
    const { url } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();

    const selectGenarts = (): void => {
        if (genarts.length === 0) {
            return;
        }
        if (genarts.length === 1) {
            const { family, subFamily, categoryId, value } = genarts[0];
            const newURL = `${url}/parts/${family}/${subFamily}/${categoryId}/${value}`;
            history.push(newURL);
            setGenarts([]);
            onSubmit();
            return;
        }
        const ids = genarts.map((object) => object.value);
        const newURL = `${url}/search/from-breadcrumb/${ids.join("-")}/${
            ids[0]
        }`;
        history.push(newURL);
        setGenarts([]);
        onSubmit();
    };

    return (
        <>
            <GenartSelect
                placeholder={t("caridentifier.genart-placeholder")}
                genart={genarts}
                onGenartChange={setGenarts}
                onSubmit={selectGenarts}
                multi
            />
            <button className="btn genart-search" onClick={selectGenarts}>
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </>
    );
};

export default GenartTagSelector;
