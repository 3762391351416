import * as React from "react";
import { Modal, ModalBody } from "reactstrap";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useScript } from "@/v2/hooks";

declare global {
    class Html5QrcodeScanner {
        constructor(id: string);
        render(successCallback: (msg: string) => void): void;
        clear(): Promise<void>;
    }
}

const initReader = (
    cb: (msg: string) => void,
    ref: React.MutableRefObject<Html5QrcodeScanner | null>
) => {
    if (!("Html5QrcodeScanner" in window)) {
        setTimeout(() => initReader(cb, ref), 300);
        return;
    }
    const scanner = new Html5QrcodeScanner("qrcode-reader");
    ref.current = scanner;
    scanner.render(cb);
};

type QRCodeProps = {
    cb(vin: string): void;
    transformString?: (msg: string) => string | null;
};

const noop = <T,>(x: T) => x;

const QRCodeReader = ({
    cb,
    transformString = noop,
}: QRCodeProps): JSX.Element => {
    useScript("/lib/html5-qrcode.min.js");
    const scanner = React.useRef<Html5QrcodeScanner | null>(null);

    const onMatch = React.useCallback(
        (msg: string) => {
            const match = transformString(msg);
            if (match) {
                scanner.current?.clear();
                cb(match);
            }
        },
        [cb, transformString]
    );

    React.useEffect(() => {
        initReader(onMatch, scanner);
    }, [onMatch]);

    return <div id="qrcode-reader">Scan !</div>;
};

export const QRCodeReaderButton = ({
    cb,
    transformString = noop,
}: QRCodeProps): JSX.Element => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggle = React.useCallback(() => setModalOpen((o) => !o), []);
    const callback = (vin: string) => {
        toggle();
        cb(vin);
    };
    return (
        <>
            <button
                className="btn btn-outline-secondary ms-2"
                type="button"
                onClick={toggle}
            >
                <FontAwesomeIcon icon={faQrcode} />
            </button>
            <Modal isOpen={modalOpen} toggle={toggle}>
                <ModalBody>
                    <QRCodeReader
                        cb={callback}
                        transformString={transformString}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

const getVin = (url: string) => {
    const match = url.match(/^https?:\/\/[^/]+\/idcar\/(\w+)$/i);
    const vin = match?.[1] ?? null;
    return vin;
};

export const IdCarReaderButton = ({
    cb,
}: Pick<QRCodeProps, "cb">): JSX.Element => (
    <QRCodeReaderButton cb={cb} transformString={getVin} />
);
