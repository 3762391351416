import { appsContextFactory } from "@/pages/RSEEditor/AppsContext";

export const WIDGET_LABELS = {
    "lms-promotions": "Promotions",
    "lms-initial-slides": "Slides initiales",
    "lms-news": "Actualités",
    "lms-docs": "Documentations",
    "lms-forms": "Formulaires",
    "lms-catalog": "Catalogue",
    "lms-social": "Social",
    "lms-pack": "Pack App rse²",
    "lms-tools": "Outils Catalogue & Site",
    "lms-applications": "Applications",
    "lms-services": "Services",
    "lms-formations": "Formations",
} as const;

export const LMS_WIDGETS = [
    "lms-promotions",
    "lms-news",
    "lms-docs",
    "lms-forms",
    "lms-catalog",
    "lms-tools",
    "lms-social",
    "lms-pack",
    "lms-applications",
    "lms-services",
    "lms-formations",
] as const;

export const { AppsProvider, useAppsContext } = appsContextFactory("lms-");
