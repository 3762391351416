import type * as React from "react";
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";

import type { Bookmark } from "shared/dist/types/types";

import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { IMAGES_SERVER } from "@/config/settings";
import type { VehicleVersion } from "@/pages/CarSelector/VehicleBanner";
import { cache } from "@/utils/cache";
import { useAPI, useLanguage } from "@/utils/hooks";
import { usePartsBrands } from "@/utils/usePartsBrands";

import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";

import "./SelectPartFamily.css";

const defaultImageURL = `${IMAGES_SERVER}/gif/DEFAULT.png`;
const setDefaultImage = (
    event: React.SyntheticEvent<HTMLImageElement>
): void => {
    event.currentTarget.src = defaultImageURL;
};
const makeBookmarkLabel = (data: VehicleVersion): string =>
    `${data.makeLabel} ${data.versionLabel} ${data.motorLabel} ${data.yearFrom} - ${data.yearTo}`;

type Family = {
    id: string;
    label: string;
};

type Params = {
    brand: string;
    model: string;
    version: string;
    ktype: string;
};

export const SelectPartFamily = (): JSX.Element => {
    const lang = useLanguage();
    const params = useParams<Params>();
    const match = useRouteMatch<{ brand: string }>();
    const location = useLocation();
    const { partsBrands } = usePartsBrands();
    const path = location.pathname;

    const previousSearches: Bookmark[] = JSON.parse(
        localStorage.previousSearches || "[]"
    );
    const shouldAddToHistory =
        path.startsWith("/selection-vehicule") &&
        previousSearches.findIndex((search) => search.url === path) === -1;

    if (shouldAddToHistory) {
        const modelId = `${params.brand}/${params.model}`;
        const versionId = Number(params.version);
        const versions = cache.versions[modelId];
        if (versions && !("error" in versions)) {
            const data = versions.find((version) => version.id === versionId);
            if (data) {
                previousSearches.push({
                    url: path,
                    label: makeBookmarkLabel(data),
                });
            }
            localStorage.previousSearches = JSON.stringify(previousSearches);
            console.log("Added to history", path, data);
        }
    }

    const { ktype, brand } = params;
    const brandId = ktype
        ? null
        : partsBrands.find((partsBrand) => partsBrand.name === brand)?.id;
    const url = ktype
        ? `parts/vehicle/${lang}/${ktype}`
        : `parts/all/make/${lang}/${brandId}`;
    const { data, error } = useAPI<Family[]>(url);

    if (error) {
        return (
            <div className="selection-bloc main-bloc">
                <Breadcrumbs match={match} />
                <ErrorMessage />
            </div>
        );
    }

    if (!data) {
        return (
            <div className="selection-bloc main-bloc">
                <Breadcrumbs match={match} />
                <Loading messageKey="partselector.loading-part-families" />
            </div>
        );
    }

    const families = data.map((family) => ({
        id: family.id,
        label: family.label,
    }));

    return (
        <div className="selection-bloc main-bloc">
            <Breadcrumbs match={match} />
            <ul className="part-families">
                {families.map((family) => (
                    <li key={family.id}>
                        <Link
                            className="part-family"
                            to={`${path}/parts/${family.id}`}
                        >
                            <img
                                src={`${IMAGES_SERVER}/gif/${family.id}.png`}
                                alt=""
                                onError={setDefaultImage}
                            />
                            <div>{family.label}</div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};
