import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import { PlateType, SwissPlate } from "@/components/IdentifyVehicle";
import { IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";

export const IdentifyVehicle = (): JSX.Element => {
    const [immat, setImmat] = React.useState("");

    const { url, path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { hasAccess } = useAuth();

    const [plateType, setPlateType] = React.useState<PlateType>(
        hasAccess("immatFR") ? "fr" : hasAccess("immatCH") ? "ch" : null
    );
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggleModal = () => setModalOpen((o) => !o);

    const handleImmatChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => setImmat(event.target.value);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (!immat) {
            return;
        }
        if (plateType === "fr") {
            history.push(`${url}/search/immat/${immat}`);
        } else if (plateType === "ch") {
            setModalOpen(true);
        }
    };

    return (
        <>
            <div className="bloc-slide-title">
                {t("leftmenu.identification-vehicule")}
            </div>
            <form onSubmit={handleSubmit}>
                {plateType === "fr" && (
                    <div className="immatriculation">
                        <div className="immat-wrapper">
                            <input
                                type="text"
                                id="immat"
                                name="immat"
                                maxLength={11}
                                value={immat}
                                onChange={handleImmatChange}
                            />
                        </div>
                        {hasAccess("immatCH") && (
                            <button
                                className="btn btn-none"
                                type="button"
                                onClick={() => setPlateType("ch")}
                            >
                                <img
                                    src={`${IMAGES_SERVER}/countryFlags/ch.svg`}
                                    width="35"
                                    alt=""
                                />
                            </button>
                        )}
                    </div>
                )}
                {plateType === "ch" && (
                    <SwissPlate
                        plate={immat}
                        handleChange={handleImmatChange}
                        modalOpen={modalOpen}
                        toggleModal={toggleModal}
                        setPlateType={setPlateType}
                        prefix={path}
                    />
                )}
                <button className="blog-slider__button">
                    {t("carselector.open-catalog")}
                </button>
            </form>
        </>
    );
};
