import type * as React from "react";
import { useTranslation } from "react-i18next";

import { useAuth, useUpdateUser } from "@/context/AuthContext";

export const UserCompany = (): JSX.Element | null => {
    const { userInfo } = useAuth();
    const { switchToCompany } = useUpdateUser();
    const { t } = useTranslation();

    const companyIds = Object.keys(userInfo.companies).map(Number);
    if (!companyIds.length) {
        return null;
    }

    if (companyIds.length === 1) {
        const company = userInfo.companies[userInfo.companyId];
        return (
            <div className="user-company">
                <span className="me-2">{t("my-account.company")}:</span>
                <span>{company.name}</span>
            </div>
        );
    }

    const onCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const companyId = Number(event.currentTarget.value);
        switchToCompany(companyId);
    };

    return (
        <div className="user-company mt-2">
            <form>
                <label className="d-inline-flex align-items-center">
                    <span className="me-2">{t("my-account.company")}:</span>
                    <select
                        name="company"
                        onChange={onCompanyChange}
                        value={userInfo.companyId}
                    >
                        {companyIds.map((id) => (
                            <option key={id} value={userInfo.companies[id].id}>
                                {userInfo.companies[id].name}
                            </option>
                        ))}
                    </select>
                </label>
            </form>
        </div>
    );
};
