import * as React from "react";
import { useKeycloak } from "@react-keycloak/web";

import { apiRootURL } from "@/config/settings";

import { useIsMounted } from "./hooks";

type UseAPIReturn<Data> = {
    setData: React.Dispatch<React.SetStateAction<Data>>;
    fetchData: () => void;
    data: Data;
    error: unknown;
};

const fetchWithAuth = async <Data>(
    path: string,
    token: string,
    options: RequestInit = {}
): Promise<Data> => {
    const Authorization = "Bearer " + token;
    const headers = { ...options.headers, Authorization };
    const url = `${apiRootURL}/api/k/${path}`;
    const res = await fetch(url, { ...options, headers });
    if (!res.ok) {
        const { status } = res;
        const msg = `Server error (${status})`;
        return Promise.reject({ status, msg });
    }
    const json = await res.json();
    return json;
};

export function useAPIwithKeycloak<Data>(
    url: string | null
): UseAPIReturn<Data | null> {
    const [data, setData] = React.useState<Data | null>(null);
    const [error, setError] = React.useState<unknown>(null);
    const isMounted = useIsMounted();
    const { keycloak } = useKeycloak();

    const fetchData = React.useCallback(() => {
        if (!url || !keycloak.token) return;
        return fetchWithAuth<Data>(url, keycloak.token)
            .then((results) => {
                if (isMounted()) {
                    setData(results);
                }
            })
            .catch((err: unknown) => {
                console.error(err);
                if (isMounted()) {
                    setError(err);
                }
            });
    }, [isMounted, keycloak.token, url]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, setData, fetchData, error };
}
