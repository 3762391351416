import * as React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { NoResults } from "@/components/NoResults";
import { IMAGES_SERVER } from "@/config/settings";
import { FetchError, getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";

import { FamilyAlbum } from "./FamilyAlbum";

const ILLUSTRATION = `${IMAGES_SERVER}/gif/kilometrage.png`;

type Props = {
    mid: string;
};

type Service = {
    service_schedule_description: string;
    service_schedule_id: string;
    sort_order: number;
};

type ServicePart = {
    ad_part_id: string;
    part_description: string;
    genartnrs: string[];
    secondary_genartnrs: string[];
};

type ServiceInterval = {
    interval_description_for_kms: string;
    service_kms: number;
    service_months: number;
    sort_order: number;
    parts: ServicePart[];
};

const makeUrl = (rootUrl: string, parts: ServicePart[]) => {
    const genarts = parts.reduce<number[]>((genartList, part) => {
        genartList.push(...part.genartnrs.map(Number));
        return genartList;
    }, []);
    return `${rootUrl}/search/rev/${genarts.join("-")}/${genarts[0]}`;
};

type Status = "IDLE" | "LOADING" | "ERROR" | "NO_RESULTS" | "OK";

export const Revision = ({ mid }: Props): JSX.Element => {
    const match = useRouteMatch();
    const { pathname } = useLocation();
    const lang = useLanguage();
    const idMatch = useRouteMatch<{ serviceId: string }>(
        `${match.url}/rev/:serviceId`
    );
    const serviceId = idMatch?.params?.serviceId;
    const isSelected = pathname.replace(match.url, "").startsWith("/rev");

    const [services, setServices] = React.useState<Service[]>([]);
    const [intervals, setIntervals] = React.useState<ServiceInterval[]>([]);
    const [status, setStatus] = React.useState<Status>("IDLE");

    React.useEffect(() => {
        if (!isSelected) return;
        const url = `revisionServices/${mid}/${lang}`;
        setStatus("LOADING");
        getContent<Service[]>(url)
            .then((data) => {
                setServices(data);
                setStatus("OK");
            })
            .catch((error: unknown) => {
                if (error instanceof FetchError && error.status === 404) {
                    setStatus("NO_RESULTS");
                } else {
                    console.error(error);
                    setStatus("ERROR");
                }
            });
    }, [mid, lang, isSelected]);

    React.useEffect(() => {
        if (!serviceId) return;
        const url = `/revisionIntervals/${mid}/${lang}/${serviceId}`;
        setStatus("LOADING");
        getContent<ServiceInterval[]>(url)
            .then((data) => {
                setIntervals(data);
                setStatus("OK");
            })
            .catch((error) => {
                console.error(error);
                setStatus("ERROR");
            });
    }, [mid, lang, serviceId]);

    let content = null;
    if (status === "ERROR") {
        content = <ErrorMessage className="align-self-start" />;
    } else if (status === "NO_RESULTS") {
        content = <NoResults />;
    } else if (status === "LOADING") {
        content = <Loading />;
    } else if (status === "OK") {
        content = (
            <>
                <ul>
                    {!serviceId &&
                        services.map((service) => (
                            <li key={service.sort_order}>
                                <Link
                                    to={`${match.url}/rev/${service.service_schedule_id}`}
                                >
                                    {service.service_schedule_description}
                                </Link>
                            </li>
                        ))}
                    {serviceId &&
                        intervals.map((interval) => (
                            <li key={interval.sort_order}>
                                <Link to={makeUrl(match.url, interval.parts)}>
                                    {interval.interval_description_for_kms}
                                </Link>
                            </li>
                        ))}
                </ul>
                {serviceId && <Link to={`${match.url}/rev`}>Retour</Link>}
            </>
        );
    }

    return (
        <FamilyAlbum
            name="Révision"
            imgURL={ILLUSTRATION}
            isSelected={isSelected}
            path="/rev"
        >
            {content}
        </FamilyAlbum>
    );
};
