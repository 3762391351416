import type * as React from "react";
import { Modal } from "reactstrap";

import type { CompletionEntry } from "shared/dist/types/types";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useAPI } from "@/utils/hooks";

import type { PageApp } from "./AppsContext";
import { AppWizard, WizardApp } from "./AppWizard";

type Props = {
    widget: string;
    toggle(): void;
    isOpen: boolean;
    setApps: React.Dispatch<React.SetStateAction<PageApp[]>>;
    widgetLabel: string;
};

const DataLoader = (props: Omit<Props, "isOpen">) => {
    const { data: realmRoles, error } =
        useAPI<ReadonlyArray<CompletionEntry>>("realmRoles");
    if (error) {
        return <ErrorMessage />;
    }
    if (!realmRoles) {
        return <Loading />;
    }

    const app: WizardApp = {
        widget: props.widget,
        dateFrom: null,
        dateTo: null,
    };

    return (
        <ErrorBoundary>
            <AppWizard
                {...props}
                app={app}
                realmRoles={realmRoles}
                appRoles={[]}
                title="Ajouter une app"
                postTo="newApp"
                successMsg="App ajoutée."
                errorMsg="Impossible d'ajouter l'app"
            />
        </ErrorBoundary>
    );
};

export const NewAppWizard = ({
    isOpen,
    ...props
}: Props): JSX.Element | null => {
    return (
        <Modal isOpen={isOpen} size="lg" toggle={props.toggle}>
            {isOpen ? <DataLoader {...props} /> : null}
        </Modal>
    );
};
