import * as React from "react";

import type { RawCartItem } from "shared/dist/types/cart";

import { useCart } from "@/context/SimpleCartContext";
import { usePublicAPI } from "@/utils/hooks";
import { useSearchParams } from "@/utils/useSearchParams";

export const LoadInitialCart = () => {
    const params = useSearchParams();
    const customerId = params.get("id");
    const url = customerId ? `vc/cart/${customerId}` : null;
    const { data } = usePublicAPI<RawCartItem[]>(url);
    const [, setCartItems] = useCart();

    React.useEffect(() => {
        if (data) {
            setCartItems(data);
        }
    }, [data, setCartItems]);

    return null;
};
