import * as React from "react";
import { useTranslation } from "react-i18next";
import type {
    GroupBase,
    MultiValueGenericProps,
    OptionProps,
} from "react-select";
import type { SelectComponents } from "react-select/dist/declarations/src/components";
import { Alert } from "reactstrap";
import classnames from "classnames";
import type { TFunction } from "i18next";

import type { OE } from "shared/dist/types/item";

import { ErrorMessage } from "@/components/ErrorMessage";
import { FilterSelect } from "@/components/FilterSelect";
import { ItemTable } from "@/components/Item/ItemTable";
import { Loading } from "@/components/Loading";
import { IMAGES_SERVER } from "@/config/settings";
import { getContent } from "@/utils/fetch";
import { useLanguage } from "@/utils/hooks";
import { getSupplierLogoURL, isInArray } from "@/utils/utils";

import type { SearchType } from "./ItemSlides";
import { SearchItemWrapper } from "./SearchItemWrapper";

import "./SearchItemContainer.css";

type FilterOptionCommon = {
    label: string | undefined;
    value: string;
};
type FilterOptionGenart = FilterOptionCommon & {
    type: "genart";
    familyId: string | undefined;
};
type FilterOptionBrand = FilterOptionCommon & {
    type: "brand";
};

type FilterOption = FilterOptionGenart | FilterOptionBrand;

const makeFilterOptions = (
    oes: OE[],
    t: TFunction
): ReadonlyArray<{ label: string; options: FilterOption[] }> => {
    const genartsMap = new Map<string, OE>();
    const brands = new Map<string, string>();
    for (const oe of oes) {
        if (oe.articleId) {
            genartsMap.set(oe.articleId, oe);
        }
        brands.set(oe.makeId, oe.makeLabel);
    }
    const genarts: FilterOption[] = [];
    for (const [genartId, oe] of genartsMap.entries()) {
        genarts.push({
            label: oe.genArt,
            value: genartId,
            familyId: oe.familyId,
            type: "genart",
        });
    }
    const brandIds = [...brands.keys()];
    const brandOptions: FilterOption[] = brandIds.map((brandId) => ({
        label: brands.get(brandId),
        value: brandId,
        type: "brand",
    }));
    return [
        {
            label: t("new-item-wizard.genart-label"),
            options: genarts,
        },
        {
            label: t("item.make"),
            options: brandOptions,
        },
    ];
};

const computeOrderScore = (ids: string[], brandId: string): number => {
    const index = ids.indexOf(String(brandId));
    return index === -1 ? ids.length : index;
};

const sortOEs = (oes: OE[], ids: string[]): OE[] => {
    if (ids.length === 0) {
        return oes;
    }
    oes.sort((a, b) => {
        const scoreA = computeOrderScore(ids, a.makeId);
        const scoreB = computeOrderScore(ids, b.makeId);
        return scoreA - scoreB;
    });
    return oes;
};

const DEFAULT_IMG_URL = `${IMAGES_SERVER}/gif/DEFAULT.png`;

const getImageURL = (option: FilterOption) => {
    if (option.type === "brand") {
        return getSupplierLogoURL(option.value);
    }
    const { familyId } = option;
    if (!familyId) {
        return DEFAULT_IMG_URL;
    }
    return `${IMAGES_SERVER}/gif/${familyId}.png`;
};

const CustomOption = (props: OptionProps<FilterOption, true>) => {
    const { innerRef, innerProps, label, isDisabled, isFocused, isSelected } =
        props;
    const data: FilterOption = props.data;

    const className = classnames({
        "filter-option": true,
        "option--is-disabled": isDisabled,
        "option--is-focused": isFocused,
        "option--is-selected": isSelected,
    });

    return (
        <div className={className} ref={innerRef} {...innerProps}>
            <img src={getImageURL(data)} alt="" width="30" />
            <span>{label}</span>
        </div>
    );
};

const FilterLabel = (props: MultiValueGenericProps<FilterOption, true>) => {
    const { data } = props;

    return (
        <div>
            <img src={getImageURL(data)} alt="" width="30" />
            <span>{data.label}</span>
        </div>
    );
};

const customComponents: Partial<
    SelectComponents<FilterOption, true, GroupBase<FilterOption>>
> = {
    Option: CustomOption,
    MultiValueLabel: FilterLabel,
};

const filterItems = (oes: OE[], filters: readonly FilterOption[]) => {
    const genartFilters = (filters || []).filter(
        (filter) => filter.type === "genart"
    );
    const brandFilters = (filters || []).filter(
        (filter) => filter.type === "brand"
    );
    return oes.filter(
        (oe) =>
            (genartFilters.length === 0 ||
                genartFilters.some(
                    (filter) => filter.value === oe.articleId
                )) &&
            (brandFilters.length === 0 ||
                brandFilters.some((filter) => filter.value === oe.makeId))
    );
};

const noop = () => {};

type Props = {
    search: string;
    exactSearchMode: boolean;
    skipRefSearch: boolean;
    isInSlide?: boolean;
    onLastItemClick?: () => void;
    searchType?: SearchType;
};

type Status = "idle" | "loading" | "error" | "resolved";

export const SearchItemContainer = (props: Props): JSX.Element => {
    const [oes, setOes] = React.useState<OE[]>([]);
    const [selectedFilters, setSelectedFilters] = React.useState<
        readonly FilterOption[]
    >([]);
    const [text, setText] = React.useState("");
    const [status, setStatus] = React.useState<Status>("idle");
    const { t } = useTranslation();
    const lang = useLanguage();

    const { exactSearchMode, skipRefSearch } = props;
    const value = encodeURIComponent(props.search);

    const fetchData = (): (() => void) => {
        let unmounted = false;
        let searchType = "ref";
        if (/^7-\d{9}$/.test(value)) {
            searchType = "mdd";
        } else if (/^\d{1,2}-\d{8,14}$/.test(value)) {
            searchType = "reparcar";
        } else if (/^\d{13}$/.test(value)) {
            searchType = "ean";
        }
        if (
            isInArray(props.searchType, [
                "ref",
                "ean",
                "mdd",
                "reparcar",
            ] as const)
        ) {
            searchType = props.searchType;
        }
        const searchMode = exactSearchMode ? "get" : "search";
        setStatus("loading");
        const url = `parts/${searchMode}/${searchType}/${lang}/${value}`;
        const url2 = `parts/${searchMode}/oe/${lang}/${value}`;
        const promise1 = skipRefSearch
            ? Promise.resolve([])
            : getContent<OE[]>(url);
        const promise2 =
            searchType === "ref" ? getContent<OE[]>(url2) : Promise.resolve([]);
        Promise.all([
            promise1,
            promise2,
            getContent<string[]>("userPartBrands"),
        ])
            .then(([results1, results2, brandIds]) => {
                const newOes = sortOEs(results1.concat(results2), brandIds);
                if (unmounted) {
                    return;
                }
                setOes(newOes);
                setSelectedFilters([]);
                setStatus("resolved");
            })
            .catch((error) => {
                console.error(error);
                if (!unmounted) {
                    setStatus("error");
                }
            });
        return (): void => {
            unmounted = true;
        };
    };

    React.useEffect(fetchData, [
        exactSearchMode,
        lang,
        value,
        skipRefSearch,
        props.searchType,
    ]);

    const handleFilterChange = React.useCallback(
        (filters: readonly FilterOption[]): void =>
            setSelectedFilters(filters || []),
        [setSelectedFilters]
    );

    const filterIAM_EAN = (oe: OE): boolean =>
        (oe.id + oe.ean).toLowerCase().includes(text.toLowerCase());

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
        setText(e.currentTarget.value);

    const { isInSlide = false, onLastItemClick = noop, search } = props;
    const title = t("partselector.results");
    const passedProps = {
        search,
        isInSlide,
        title,
        onLastItemClick,
        onInputChange,
    };

    const filterOptions = React.useMemo(
        () => makeFilterOptions(oes, t),
        [oes, t]
    );

    if (status === "error") {
        return (
            <SearchItemWrapper {...passedProps}>
                <ErrorMessage />
            </SearchItemWrapper>
        );
    }
    if (status === "loading") {
        return (
            <SearchItemWrapper {...passedProps}>
                <Loading messageKey="item.loading" />
            </SearchItemWrapper>
        );
    }
    if (oes.length === 0) {
        return (
            <SearchItemWrapper {...passedProps}>
                <Alert color="info">
                    {t("partselector.no-part-found", { search })}
                </Alert>
            </SearchItemWrapper>
        );
    }
    const filteredOEs = filterItems(oes, selectedFilters).filter(filterIAM_EAN);
    const count = oes.length;
    let resultsTitle = t("partselector.resultsWithCount", { count });
    if (count > filteredOEs.length) {
        resultsTitle = `${filteredOEs.length}/${resultsTitle}`;
    }
    passedProps.title = resultsTitle;
    return (
        <SearchItemWrapper {...passedProps}>
            {count > 1 && (
                <FilterSelect
                    placeholder={t("partselector.filter-placeholder")}
                    options={filterOptions}
                    value={selectedFilters}
                    onChange={handleFilterChange}
                    components={customComponents}
                />
            )}
            <ItemTable oes={filteredOEs} />
        </SearchItemWrapper>
    );
};
