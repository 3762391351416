import * as React from "react";
import { Button } from "reactstrap";

import { i18n } from "@/config/i18n";
import { useCustomer } from "@/context/CustomerContext";
import { useVehicleData } from "@/context/VehicleDataContext";
import { postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

export const NewCustomerButton = () => {
    const [vehicleData] = useVehicleData();
    const [, setCustomer] = useCustomer();
    const vin = vehicleData.Codif_Vin_PRF;

    const createClient = async () => {
        try {
            const response = await postContent("vc/newClient", { vin });
            const json: { clientId: number } = await response.json();
            setCustomer({ id: json.clientId, info: null, vehicles: [] });
        } catch (error) {
            toastr.error(i18n.t("common.error"));
        }
    };

    return (
        <Button color="primary" type="button" onClick={createClient}>
            Nouveau client
        </Button>
    );
};
