import type * as React from "react";

import { AppsWidget } from "@/components/AppsWidget";
import { HideRSEButton } from "@/components/RSE/HideRSEButton";
import { RestoreSlidesButtons } from "@/components/RSE/RestoreSlidesButton";
import { SlideVisibilityWrapper } from "@/components/RSE/SlideVisibilityWrapper";
import { RSE_IFRAME_SLIDER_URL } from "@/config/settings";
import { NewsSlider } from "@/pages/ContactSelector/NewsSlider";

import { LMS_WIDGETS, WIDGET_LABELS } from "./LMSAppsContext";

import "@/pages/ContactSelector/ContactSelectorPanel.css";
import "./PanelLMS.css";

const IframeSlider = () => {
    if (!RSE_IFRAME_SLIDER_URL) {
        return null;
    }
    return (
        <div className="iframe-slider">
            <iframe src={RSE_IFRAME_SLIDER_URL} className="w-100 h-100" />
        </div>
    );
};

const Content = () => {
    return (
        <>
            <NewsSlider />
            <IframeSlider />
            {LMS_WIDGETS.map((widget) => (
                <div key={widget}>
                    <AppsWidget widget={widget}>
                        <div>{WIDGET_LABELS[widget]}</div>
                    </AppsWidget>
                </div>
            ))}
        </>
    );
};

const TopBar = ({ onClose }: { onClose(): void }) => {
    return (
        <div
            className="text-right"
            style={{ marginTop: -20, marginRight: -16 }}
        >
            <RestoreSlidesButtons widget="lms-initial-slides" />
            <HideRSEButton callback={onClose} />
        </div>
    );
};

type Props = {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const PanelLMS = ({ setVisible }: Props): JSX.Element => {
    return (
        <SlideVisibilityWrapper
            setVisible={setVisible}
            href="/lms"
            className="lms-page"
        >
            <TopBar onClose={() => setVisible(false)} />
            <Content />
        </SlideVisibilityWrapper>
    );
};
