export const apiRootURL = process.env.REACT_APP_API_ROOT_URL;
export const DOCUMENT_TITLE = "Démonstrateur | C@iA.parts ";

export const HUB_IDLP_HOST =
    process.env.REACT_APP_HUB_IDLP_HOST || "https://hubidlp.n.parts";

export const PROXY_PORT = {
    Opel: 3001,
    "07ZR": 3002,
    Michelin: 3003,
    Ford: 3004,
    RRG: 3005,
    GtEstimate: 8889,
};

export const dockApps = [
    "citroen",
    "ds",
    "opel",
    "peugeot",
    "misterauto",
    "eurorepar",
    "actia",
];

export const iframes = {
    citroen: "http://www.citroen.fr/accueil.html",
    ds: "http://www.dsautomobiles.fr/accueil.html",
    eurorepar: "https://www.eurorepar.fr/",
    misterauto: "https://www.mister-auto.com/",
    opel: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Opel,
    peugeot: "http://www.peugeot.fr/accueil.html",
    actia: "https://www.actia.com/fr",
    back: "https://back.ia.parts/",
    diag: "https://box.ia.parts/",
    erp: "http://office.equanim-it.fr/",
    store: "https://appstore.ia.parts",
    visio: "https://meetbox.ia.parts",
    com: "https://com.ia.parts/",
    help: "https://uniflix.ia.parts/",
    tecrmi: "https://rmi.ia.parts",
};

export const iframePartSelector: Record<string, string> = {
    fr: "https://www.eurorepar.fr/promotions.html",
    en: "https://www.eurorepar.fr/promotions.html",
};

export const iframeCarSelector: Record<string, string> = {
    fr: "https://plus.ia.parts/ie.php?https://www.groupe-psa.com/fr/marques-et-services/free2move/",
    en: "https://plus.ia.parts/ie.php?https://www.groupe-psa.com/en/brands-and-services/free2move/",
};

export const iframeContactSelector = {
    fr: "http://unicit.fr",
    en: "http://unicit.fr",
};

export const iframeStatsSelector: Record<string, string> = {
    fr: "/sv5actia",
    en: "/sv5actia",
};

export const defaultIframeStatsSelector = "/sv5actia";

export const IMAGES_SERVER = "https://medias.ia.parts";

export const DIAG_OTHER_BRANDS = [
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
];

// ContactSelector (Page /social)
export const CONTACT_SELECTOR_OTHER_PARTNERS: string[] = [];

export const PLUS_MENU_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const STOCK_IMAGE_URL = `${IMAGES_SERVER}/sivince/pimCE.png`;
export const CAIA_FULL_IMAGE_URL = `${IMAGES_SERVER}/sivince/vinCE.png`;
export const CAIA_GUII_IMAGE_URL = `${IMAGES_SERVER}/sivince/360IAM.png`;
export const CAIA_GUI_SELECTOR_IMAGE_URL = `${IMAGES_SERVER}/sivince/360OEM.png`;

export const PROXY_DATABOX_URL = "https://proxy-data-box.ia.parts";

export const TABLETTE_IMG = `${IMAGES_SERVER}/v2/items/C@IA.png`;
export const DATABOX_IMG = `${IMAGES_SERVER}/logos/databox.png`;

export const DTC_IMAGE = `${IMAGES_SERVER}/rmi/Voyant-moteur-orange.jpg`;

export const WORDPRESS_URL = "https://wp.ia.parts/dev";

export const USE_KEYCLOAK = false;

export const RSE_IFRAME_SLIDER_URL = "";

export const COMPANY_LOGO_PATH = "userDocs/logos";

export const CART_TABS = [
    "cart",
    "wishlist",
    "aside",
    "order",
    "delivery",
    "shipping",
    "waste-d3e",
] as const;

export const DEFAULT_COMPANY_ID = 11;

export const LEVAM_URL = "https://oem1.ia.parts/oem.html";

export const TIRE_SUPPLIERS = {
    Premium: [
        "continental",
        "michelin",
        "bridgestone",
        "dunlop",
        "pirelli",
        "goodyear",
    ],
    Quality: [
        "yokohama",
        "cooper",
        "bf-goodrich",
        "hankook",
        "nokian",
        "falken",
        "kleber",
        "firestone",
        "fulda",
        "genreal tire",
        "gt-radial",
        "kumho",
        "toyo",
        "uniroyal",
        "vredestein",
    ],
    "1er prix": [
        "maxxis",
        "aoteli",
        "aplus",
        "autogreen",
        "avon",
        "barum",
        "bct",
        "bkt",
        "blackstone",
        "cheyen",
        "compass",
        "dayton",
        "debica",
        "delinte",
        "federal",
        "fortuna",
        "galaxy",
        "gislaved",
        "goform",
        "gripmax",
        "haida",
        "hifly",
        "imperial",
        "infinity",
        "interstate",
        "kenda",
    ],
    Rechappé: ["black star", "nortenha"],
};

/* select ISOCode as id, Bez as name from `020` l
 * inner join `030` ld on l.BezNr = ld.BezNr and ld.SprachNr = 6
 * order by name */
export const LANGUAGES = [
    { id: "zh", name: "Chinese" },
    { id: "cs", name: "čeština" },
    { id: "da", name: "Dansk" },
    { id: "de", name: "Deutsch" },
    { id: "et", name: "Eesti" },
    { id: "en", name: "English" },
    { id: "qb", name: "English (AUS)" },
    { id: "qa", name: "English (USA)" },
    { id: "es", name: "Español" },
    { id: "qd", name: "Español (MEX)" },
    { id: "fr", name: "Français" },
    { id: "it", name: "Italiano" },
    { id: "lv", name: "Latviešu" },
    { id: "lt", name: "Lietuvių" },
    { id: "hu", name: "Magyar" },
    { id: "nl", name: "Nederlands" },
    { id: "no", name: "Norsk" },
    { id: "pl", name: "Polski" },
    { id: "pt", name: "Português" },
    { id: "qc", name: "português (BR)" },
    { id: "ro", name: "Română" },
    { id: "sk", name: "slovenčina" },
    { id: "sl", name: "Slovenski" },
    { id: "sr", name: "Srpski" },
    { id: "fi", name: "Suomi" },
    { id: "sv", name: "Svenska" },
    { id: "tr", name: "Türkçe" },
    { id: "el", name: "Ελληvικά" },
    { id: "bg", name: "Български" },
    { id: "ru", name: "русский" },
    { id: "he", name: "עברית" },
    { id: "ar", name: "عربي" },
    { id: "th", name: "ภาษาไทย (ประเทศไทย)" },
    { id: "ja", name: "日本語" },
    { id: "ko", name: "한국어" },
    { id: "hi", name: "हिन्दी", tecdocLanguage: "en" },
];

export const COLOR_MAP = {
    BEIGE: "beige",
    BLANC: "white",
    BLEU: "blue",
    GRIS: "gray",
    "INC.": "transparent",
    JAUNE: "yellow",
    MARRON: "saddlebrown",
    NOIR: "black",
    ORANGE: "orange",
    ROUGE: "red",
    VERT: "green",
} as const;

export const showWPSearchBar = true;
