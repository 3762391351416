import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Vehicle } from "shared/dist/types/customer";

import { i18n } from "@/config/i18n";
import { apiRootURL } from "@/config/settings";
import { useCustomer } from "@/context/CustomerContext";
import { toastr } from "@/utils/toastr";
import { getLogoURL } from "@/utils/utils";

export const CustomerVehicles = () => {
    const [customer] = useCustomer();
    if (!customer) return null;

    return (
        <ul className="me-3">
            {customer.vehicles.length === 0 ? (
                <li>Aucun véhicule défini</li>
            ) : null}
            {customer.vehicles.map((vehicle, i) => (
                <li key={i}>
                    <VehicleRow vehicle={vehicle} />
                </li>
            ))}
            <li>
                <NewVehicle />
            </li>
        </ul>
    );
};

const VehicleRow = ({ vehicle }: { vehicle: Vehicle }) => {
    const logoURL = getLogoURL(vehicle.brand);
    return (
        <div className="vehicle-row d-flex align-items-center mb-2">
            <div className="vehicle-row-brand">
                <img src={logoURL} alt={vehicle.brand} height="80" width="80" />
            </div>
            <div className="vehicle-row-content">
                <div className="d-flex align-items-center">
                    {vehicle.brand} {vehicle.model}
                </div>
            </div>
        </div>
    );
};

const NewVehicle = () => {
    type State = "button" | "form";
    const [state, setState] = React.useState<State>("button");

    if (state === "button") {
        return (
            <Button
                type="button"
                color="primary"
                onClick={() => setState("form")}
            >
                <FontAwesomeIcon icon={faPlus} size="2x" />
            </Button>
        );
    }
    const hideForm = () => setState("button");
    return <NewVehicleForm onCancel={hideForm} onSuccess={hideForm} />;
};

const NewVehicleForm = ({
    onCancel,
    onSuccess,
}: {
    onCancel(): void;
    onSuccess(): void;
}) => {
    const { t } = useTranslation();
    const [customer, setCustomer] = useCustomer();
    const customerId = customer?.id;
    type FormValues = {
        immat: string;
    };
    const { reset, register, handleSubmit } = useForm<FormValues>();

    const submit = async ({ immat }: FormValues) => {
        if (!immat || !customerId) return;
        const body = JSON.stringify({ immat, customerId });
        const res = await fetch(`${apiRootURL}/api/vc/newVehicle`, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body,
        });
        if (!res.ok) {
            toastr.error(i18n.t("common.error"));
            return;
        }
        const json: Record<string, string> = await res.json();
        if ("error" in json) {
            const msg =
                {
                    "This vehicle is already asssociated with a customer":
                        "Ce véhicule est déjà associé à un client",
                    "Vehicle not found": "Véhicule introuvable",
                }[json.error] || json.error;
            toastr.warning(msg);
            return;
        }
        setCustomer(
            (currentCustomer) =>
                currentCustomer && {
                    ...currentCustomer,
                    vehicles: [...currentCustomer.vehicles, json as Vehicle],
                }
        );
        reset();
        onSuccess();
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="d-flex">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Immatriculation"
                    {...register("immat")}
                />
                <Button type="submit" className="ms-2" color="primary">
                    {t("common.submit")}
                </Button>
                <Button type="button" className="ms-2" onClick={onCancel}>
                    {t("common.cancel")}
                </Button>
            </div>
        </form>
    );
};
