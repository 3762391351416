import type * as React from "react";
import { useTranslation } from "react-i18next";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";

import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { SelectAlbumFamily } from "@/components/SelectAlbumFamily";
import {
    apiRootURL,
    CAIA_FULL_IMAGE_URL,
    IMAGES_SERVER,
    STOCK_IMAGE_URL,
} from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useAPI } from "@/utils/hooks";
import { useOEMData } from "@/utils/useOEMData";
import { usePartsURL } from "@/utils/usePartsURL";

import { CustomSearches } from "./CustomSearches";
import { DTCSearch } from "./DTCSearch";
import { FamilyAlbum } from "./FamilyAlbum";
import { GenartSelectorSelect } from "./GenartSelectorSelect";
import { GSSApp } from "./GuiSelectorSelect";
import { PartsForReuseAlbum } from "./PartsForReuseAlbum";
import { Revision } from "./Revision";
import { TireAlbum } from "./TireAlbum";

import "./Albums.css";

const getSelectedName = (pathname: string): string => {
    const [, name] = pathname.match(/\/custom-search\/(.+)$/) || [];
    const [, id] = pathname.match(/\/parts\/(\d+)/) || [];
    const selected = name || id;
    return selected;
};

const OEMFamily = () => {
    const { url } = useRouteMatch();
    const { data } = useOEMData("OEMFamily");
    if (!data || data.error) {
        return null;
    }
    return (
        <li>
            <Link className="family-album tooltip-arrow" to={`${url}/oem`}>
                <img src={`${apiRootURL}/media/logos/C@IA.png`} alt="" />
                <div>OEM</div>
            </Link>
        </li>
    );
};

type Props = {
    vehicleData?: VehicleData;
    isOE?: boolean;
    ktype?: string;
    brand: string | undefined;
};

type Family = {
    id: string;
    label: string;
};

export const FamilyAlbums = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { hasAccess } = useAuth();

    const { pathname } = useLocation();
    const match = useRouteMatch<{ vtype: string; 0: string }>();
    let { vtype } = match.params;
    const { isOE, ktype, brand, vehicleData = {} } = props;
    if (!vtype) {
        vtype = vehicleData.ntypnr ? "pl" : "vl";
    }
    const isStock = match.params[0] === "pieces-stock";
    const typnr =
        ktype ||
        (vehicleData.ntypnr && String(vehicleData.ntypnr)) ||
        undefined;

    const partsURL = usePartsURL({ brand, typnr, vtype, isOE, isStock });
    const { data: families } = useAPI<Family[]>(partsURL, { initialData: [] });
    const { data: vehicleId } = useAPI<number>(
        vehicleData.id_vehiculo_kromeda || !ktype || vtype === "pl"
            ? null
            : `katia/vehicleId/${ktype}`,
        { initialData: vehicleData.id_vehiculo_kromeda }
    );

    const { path, url } = match;
    const displayPRE = hasAccess("PRE") && vehicleData.Type;
    const displayCustomSearches = typnr && !pathname.startsWith("/pieces");
    const displayGuiSelector =
        hasAccess("GuiSelector") &&
        !pathname.startsWith("/pieces") &&
        vtype !== "pl" &&
        Boolean(vehicleId);
    const displayGenartSelector =
        typnr && hasAccess("GenartSelector") && !pathname.startsWith("/pieces");
    const displayFull =
        hasAccess("Full") &&
        !pathname.startsWith("/pieces") &&
        vtype !== "pl" &&
        Boolean(vehicleId);
    const displayStock =
        hasAccess("Stock") && typnr && !pathname.startsWith("/pieces");
    const selected = getSelectedName(pathname);
    return (
        <ul className="albums families overflow-auto">
            {hasAccess("DTC") && vehicleData.Mid_Autodata && (
                <DTCSearch mid={vehicleData.Mid_Autodata} />
            )}
            {vehicleData.Mid_Autodata && (
                <Revision mid={vehicleData.Mid_Autodata} />
            )}
            {displayCustomSearches && <CustomSearches selected={selected} />}
            {families.map((family) => (
                <FamilyAlbum
                    key={family.id}
                    isSelected={selected === family.id}
                    name={family.label}
                    imgURL={`${IMAGES_SERVER}/gif/${family.id}.png`}
                    path={`/parts/${family.id}`}
                >
                    <Route path={path + "/parts/:family"}>
                        <SelectAlbumFamily
                            ktype={ktype}
                            ntypnr={vehicleData.ntypnr}
                            brand={brand}
                            isOE={isOE}
                        />
                    </Route>
                </FamilyAlbum>
            ))}
            {displayPRE && <PartsForReuseAlbum />}
            <TireAlbum ktype={ktype} />
            {displayStock && (
                <li>
                    <Link
                        className="family-album tooltip-arrow"
                        to={`${url}/stock`}
                    >
                        <img src={STOCK_IMAGE_URL} alt="" />
                        <div>{t("albums.stock")}</div>
                    </Link>
                </li>
            )}
            {displayFull && (
                <li>
                    <Link
                        className="family-album tooltip-arrow"
                        to={`${url}/katia/full`}
                    >
                        <img src={CAIA_FULL_IMAGE_URL} alt="" />
                        <div>{t("albums.vinCE")}</div>
                    </Link>
                </li>
            )}
            {displayGuiSelector && <GSSApp vehicleData={vehicleData} />}
            {displayGenartSelector && (
                <GenartSelectorSelect
                    url={url}
                    vehicleData={vehicleData}
                    isPL={vtype === "pl"}
                />
            )}
            <OEMFamily />
        </ul>
    );
};
