import * as React from "react";

import { getContent, postContent } from "@/utils/fetch";

type UserSettings = Record<string, string>;

type UserSettingsContext = [
    UserSettings,
    (name: string, value: string | ((oldValue: string) => string)) => void
];

const UserSettingsContext = React.createContext<
    UserSettingsContext | undefined
>(undefined);

export const UserSettingsProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [userSettings, setUserSettings] = React.useState<UserSettings>({});

    React.useEffect(() => {
        getContent<UserSettings>("userSettings").then((data) =>
            setUserSettings(data)
        );
    }, []);

    const setUserSetting = (
        name: string,
        newValue: string | ((oldValue: string) => string)
    ) => {
        setUserSettings((oldSettings) => {
            const value =
                typeof newValue === "string"
                    ? newValue
                    : newValue(oldSettings[name]);
            if (oldSettings[name] !== value) {
                postContent("setUserPreference", {
                    name,
                    value,
                });
            }
            return { ...oldSettings, [name]: value };
        });
    };

    return (
        <UserSettingsContext.Provider value={[userSettings, setUserSetting]}>
            {children}
        </UserSettingsContext.Provider>
    );
};

export const useUserSettingsContext = (): UserSettingsContext => {
    const context = React.useContext(UserSettingsContext);
    if (context === undefined) {
        throw new Error(
            "useUserSettingsContext must be used within a UserSettingsProvider"
        );
    }
    return context;
};

export const useUserSettings = (): UserSettings => {
    const [userSettings] = useUserSettingsContext();
    return userSettings;
};
