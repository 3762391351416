import * as React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Genart } from "shared/dist/types/types";
import type { Vtype } from "shared/dist/types/vehicle";

import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { ItemContainer } from "@/components/Item/ItemContainer";
import { useVehicleData } from "@/context/VehicleDataContext";
import { FamilyAlbums } from "@/pages/CarSelector/FamilyAlbums";
import { GenartSelectorDeal } from "@/pages/CarSelector/GenartSelectorDeal";
import { useAPI } from "@/utils/hooks";

import { ErrorBoundary } from "./ErrorBoundary";
import { GenartList } from "./GenartList";
import { GenartSelect } from "./GenartSelect";
import { GuiSelector } from "./GuiSelector";
import { LevamOEMTree } from "./LevamOEMTree";
import { RecyclingContainer } from "./RecyclingContainer";
import { StockCareco } from "./StockCareco";
import { TireStockContainer } from "./TireStockContainer";

import "./BlocWithAlbums.css";

const { useState } = React;

type ItemParams = {
    family: string;
    subFamily: string;
    categoryId: string;
    itemId: string;
    ktype: string;
    hmdnr: string;
};

const ItemContainerWrapper = () => {
    const match = useRouteMatch<ItemParams>();
    const [vehicleData] = useVehicleData();
    const { ktype, hmd_nr: hmdnr, Marque: brand, Modèle: model } = vehicleData;
    return (
        <ItemContainer
            hmdnr={String(hmdnr)}
            vehicleData={vehicleData}
            ktype={ktype}
            brand={brand}
            model={model}
            itemId={match.params.itemId}
            match={match}
        />
    );
};

const BreadcrumbsWrapper = ({ children }: { children: React.ReactNode }) => {
    const match = useRouteMatch<{ vtype?: Vtype }>();
    const nestedMatch = useRouteMatch([
        match.path + "/parts/:family/:subFamily/:categoryId",
        match.path + "/parts/:family/:subFamily",
        match.path + "/parts/:family",
        match.path,
    ]);
    const [vehicleData] = useVehicleData();
    const {
        ktype,
        hmd_nr: hmdnr,
        Marque: brand,
        Modèle: model,
        ntypnr,
    } = vehicleData;
    if (!ktype && !ntypnr) return null;
    let { vtype } = match.params;
    if (!vtype) {
        vtype = ntypnr ? "pl" : "vl";
    }
    const params = {
        ...nestedMatch?.params,
        ktype,
        brand,
        model,
        version: String(hmdnr),
        vtype,
    };
    const customMatch = { ...nestedMatch, params };

    return <Breadcrumbs match={customMatch}>{children}</Breadcrumbs>;
};

const FamilyAlbumsWrapper = ({ children }: { children: React.ReactNode }) => {
    const [vehicleData] = useVehicleData();
    const { ktype, Marque: brand } = vehicleData;
    const { data: cnitAndDate } = useAPI<{ cnit: string; date: string }>(
        ktype && !vehicleData.Type ? `cnit-and-date/${ktype}` : null
    );
    const data =
        vehicleData.Type || vehicleData.ntypnr
            ? vehicleData
            : { ...vehicleData, Type: cnitAndDate?.cnit };
    return (
        <div className="selection-bloc main-bloc d-flex flex-column">
            {children}
            <FamilyAlbums ktype={ktype} brand={brand} vehicleData={data} />
        </div>
    );
};

type Props = {
    searchForm?: { activeField: string; immat: string };
};

export const BlocWithAlbums = ({ searchForm }: Props): JSX.Element => {
    const [genarts, setGenarts] = useState<ReadonlyArray<Genart>>([]);
    const [genartsForDeal, setGenartsForDeal] = useState<string[]>([]);
    const history = useHistory();
    const routeMatch = useRouteMatch<{ ktype: string }>();

    const selectGenarts = (): void => {
        if (!genarts || genarts.length === 0) {
            return;
        }
        if (genarts.length === 1) {
            const { family, subFamily, categoryId, value } = genarts[0];
            const url = `${routeMatch.url}/parts/${family}/${subFamily}/${categoryId}/${value}`;
            history.push(url);
            return;
        }
        const ids = genarts.map((object) => object.value);
        const url = `${routeMatch.url}/search/from-breadcrumb/${ids.join(
            "-"
        )}/${ids[0]}`;
        history.push(url);
    };

    const { path, url } = routeMatch;
    return (
        <div className="with-short-bloc bloc-with-albums">
            <ErrorBoundary className="selection-bloc main-bloc item-bloc">
                <Switch>
                    <Route path={path + "/search/:name/:genartIds/:itemId"}>
                        <GenartList setGenartsForDeal={setGenartsForDeal} />
                    </Route>
                    <Route
                        path={
                            path +
                            "/parts/:family/:subFamily/:categoryId/:itemId"
                        }
                    >
                        <ItemContainerWrapper />
                    </Route>
                    <Route path={path + "/:virtual/recycling"}>
                        <RecyclingContainer />
                    </Route>
                    <Route path={path + "/:categories/tireStock"}>
                        <TireStockContainer searchForm={searchForm} />
                    </Route>
                    <Route path={path + "/stock"}>
                        <StockCareco />
                    </Route>
                    <Route path={path + "/katia/full"}>
                        <GuiSelector isFull url={url} />
                    </Route>
                    <Route path={path + "/katia/:oeNrs"}>
                        <GuiSelector url={url} />
                    </Route>
                    <Route
                        path={
                            "/identification-vehicule/search/(operation|MID)/:value"
                        }
                    >
                        <div className="selection-bloc main-bloc item-bloc d-flex">
                            <GenartSelectorDeal
                                selectedGenarts={genartsForDeal}
                            />
                        </div>
                    </Route>
                    <Route path={path + "/oem"}>
                        <LevamOEMTree />
                    </Route>
                    <Route>
                        <FamilyAlbumsWrapper>
                            <BreadcrumbsWrapper>
                                <GenartSelect
                                    genart={genarts}
                                    onGenartChange={setGenarts}
                                    onSubmit={selectGenarts}
                                    multi
                                />
                                <button
                                    className="btn genart-search"
                                    onClick={selectGenarts}
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </BreadcrumbsWrapper>
                        </FamilyAlbumsWrapper>
                    </Route>
                </Switch>
            </ErrorBoundary>
        </div>
    );
};
