import * as React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

export const NoResults = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Alert color="info" className="align-self-start">
            {t("common.no-results")}
        </Alert>
    );
};
