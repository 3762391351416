import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NewCustomSearch } from "@/components/NewCustomSearch";

export const CustomSearch = ({ close }: { close(): void }): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="selection-bloc main-bloc selection-brand custom-search-bloc">
            <div className="breadcrumbs-wrapper breadcrumbs-family">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <FontAwesomeIcon icon="users" className="me-1" />
                        <Link to="/social">{t("leftmenu.social")}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {t("plusmenu.new-custom-search")}
                    </BreadcrumbItem>
                    <div className="breadcrumb-img">
                        <FontAwesomeIcon icon={faSearchPlus} />
                    </div>
                </Breadcrumb>
            </div>
            <NewCustomSearch close={close} />
        </div>
    );
};
