import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";

import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { Lazy } from "@/components/Lazy";
import { Photo360 } from "@/components/Photo360";
import { CAIA_GUII_IMAGE_URL } from "@/config/settings";
import { useAPI, useGenarts } from "@/utils/hooks";

export type Genart360 = {
    id360: number;
    item_ids: string;
};

type Props = {
    url: string;
    vehicleData: VehicleData;
    isPL?: boolean;
};

const GenartSelectorSelectContent = (props: Props): JSX.Element => {
    const [photoNb, setPhotoNb] = React.useState(1);
    const { t } = useTranslation();
    const { genartsMap } = useGenarts();
    const { data: genartIds } = useAPI<Genart360[]>("genart360ids");
    const history = useHistory();
    const { register, handleSubmit } = useForm();

    const genarts = (genartIds || []).map((row) => {
        const itemIds = row.item_ids
            .split(",")
            .map((id) => ({ id, label: genartsMap[Number(id)]?.label }));
        return itemIds;
    });

    const { url, vehicleData } = props;
    const currentGenarts =
        genarts.length > photoNb + 1 ? genarts[photoNb - 1] : [];

    const submitGenartSelector = (values: Record<string, boolean>): void => {
        const ids = currentGenarts
            .map((genart) => genart.id)
            .filter((id) => values[id]);
        if (ids.length === 0) {
            return;
        }
        const newURL = `${url}/search/Guii-${photoNb}/${ids.join("-")}/${
            ids[0]
        }`;
        history.push(newURL);
    };

    return (
        <form
            className="details genart-selector guii d-flex"
            onSubmit={handleSubmit(submitGenartSelector)}
        >
            <ul>
                {currentGenarts.map((genart) => (
                    <li key={genart.id}>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked
                                {...register(genart.id)}
                            />{" "}
                            <span>{genart.label}</span>
                        </label>
                    </li>
                ))}
            </ul>
            <div className="d-flex align-items-end justify-content-end">
                {currentGenarts.length > 0 && (
                    <Button color="primary">{t("common.submit")}</Button>
                )}
                <Photo360
                    vehicleData={vehicleData}
                    onChange={setPhotoNb}
                    isPL={props.isPL}
                />
            </div>
        </form>
    );
};

export const GenartSelectorSelect = ({
    vehicleData,
    isPL,
}: Props): JSX.Element => {
    const { pathname } = useLocation();
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const isSelected = pathname.endsWith("genart360");
    return (
        <li className={isSelected ? "big-li selected" : ""}>
            <Link
                className="family-album tooltip-arrow guii"
                to={isSelected ? url : `${url}/genart360`}
            >
                <img src={CAIA_GUII_IMAGE_URL} alt="" />
                <div>{t("albums.C@iA-guii")}</div>
            </Link>
            <Lazy visible={isSelected}>
                <GenartSelectorSelectContent
                    url={url}
                    vehicleData={vehicleData}
                    isPL={isPL}
                />
            </Lazy>
        </li>
    );
};
