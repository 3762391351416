import { useEffect } from "react";

// This is needed by the keycloak client to enable silent sso, which will use an
// iframe instead of redirecting the whole page
// https://github.com/keycloak/keycloak-documentation/blob/main/securing_apps/topics/oidc/javascript-adapter.adoc

export const SilentSSO = (): null => {
    useEffect(() => {
        parent.postMessage(location.href, location.origin);
    }, []);
    return null;
};
