import type * as React from "react";
import type Autosuggest from "react-autosuggest";
import type { OnSuggestionSelected } from "react-autosuggest";

import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { IMAGES_SERVER } from "@/config/settings";
import { getContent } from "@/utils/fetch";
import { replaceIgnoreCase } from "@/utils/utils";

type WPPost = "post" | "page" | "r3d" | "3d-flip-book";
type WPSuggestion = CompletionEntry & {
    post_type: WPPost;
    url: string;
};
type AppSuggestion = CompletionEntry & { url: string; img: string };
export type Suggestion = WPSuggestion | AppSuggestion;

const getImgURL = (suggestion: Suggestion) =>
    "img" in suggestion
        ? suggestion.img
        : `${IMAGES_SERVER}/wp-type/${suggestion.post_type}.png`;

const renderSuggestion = (
    suggestion: Suggestion,
    { query }: { query: string }
): JSX.Element => (
    <div className="d-flex align-items-center">
        <div className="img-app-small me-2">
            <img src={getImgURL(suggestion)} alt="" />
        </div>
        <div>
            <span
                dangerouslySetInnerHTML={{
                    __html: replaceIgnoreCase(
                        suggestion.label ?? "",
                        query,
                        '<span class="autosuggest-query">' + query + "</span>"
                    ),
                }}
            />
        </div>
    </div>
);
const autocomplete = (input: string): Promise<Suggestion[]> =>
    getContent(`completion/wpLabel/${encodeURIComponent(input)}`);

export const TitleSuggestions = (props: {
    value: string;
    onChange(value: string): void;
    defaultValue?: string | null;
    inputRef?: React.Ref<HTMLInputElement>;
    onSuggestionSelected?: OnSuggestionSelected<Suggestion>;
}): JSX.Element => {
    const onChange = (
        _event: React.FormEvent<HTMLElement>,
        params: Autosuggest.ChangeEvent
    ): void => {
        props.onChange(params.newValue);
    };

    return (
        <Autocomplete
            value={props.value}
            onChange={onChange}
            getSuggestions={autocomplete}
            renderSuggestion={renderSuggestion}
            inputProps={{ maxLength: 200, ref: props.inputRef }}
            onSuggestionSelected={props.onSuggestionSelected}
        />
    );
};
