import * as React from "react";
import { Badge } from "reactstrap";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Customer, CustomerInfo } from "shared/dist/types/customer";

import { LanguageSelector } from "@/components/LanguageSelector";
import { ProfilePictureContent } from "@/components/ProfilePicture";
import { UserNameBloc, UserProfileBloc } from "@/components/UserProfileBloc";
import { apiRootURL, IMAGES_SERVER } from "@/config/settings";
import { useCustomer } from "@/context/CustomerContext";
import { useCart } from "@/context/SimpleCartContext";
import { useSearchParams } from "@/utils/useSearchParams";

export const CustomerBloc = () => (
    <UserProfileBloc
        reduced
        className="d-flex position-relative mb-4 align-self-start"
    >
        <ProfilePicture />
        <UserNameBloc>
            <CustomerName />
            <CartButton />
            <LanguageSelector className="me-2" />
        </UserNameBloc>
    </UserProfileBloc>
);

const CustomerName = () => {
    const [customer] = useCustomer();
    const info = customer?.info;
    if (!info) return null;
    const label = `${info.title} ${info.firstname} ${info.name}`;
    return <span className="ms-4 me-2">{label}</span>;
};

const ProfilePicture = () => {
    const params = useSearchParams();
    const [customer, setCustomer] = useCustomer();

    const customerId = params.get("id");
    const imgUrl = `${IMAGES_SERVER}/userDocs/customer/${
        customer?.info?.picture || "default_user.png"
    }`;

    const updatePicture = (filename: string) => {
        setCustomer((currentCustomer) => {
            if (!currentCustomer || !currentCustomer.info)
                return currentCustomer;
            const info: CustomerInfo = {
                ...currentCustomer.info,
                picture: filename,
            };
            const updatedCustomer: Customer = {
                ...currentCustomer,
                info,
            };
            return updatedCustomer;
        });
    };

    const postPicture = async (body: FormData): Promise<string> => {
        const res = await fetch(`${apiRootURL}/api/vc/picture/${customerId}`, {
            method: "POST",
            body,
        });
        const { filename } = await res.json();
        return filename;
    };

    return (
        <ProfilePictureContent
            imgUrl={imgUrl}
            updatePicture={updatePicture}
            postPicture={postPicture}
        />
    );
};

const CartButton = (): JSX.Element => {
    const [cart] = useCart();
    return (
        <a href="#shoppingCart" className="cart-link">
            <FontAwesomeIcon icon={faShoppingCart} />
            {cart.length > 0 && <Badge pill>{cart.length}</Badge>}
        </a>
    );
};
