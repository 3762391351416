import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import classnames from "classnames";

import type { CompletionEntry } from "shared/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { BarcodeReaderButton } from "@/components/BarcodeScanner";
import { IdCarReaderButton } from "@/components/QRCodeReader";
import { WORKSHOP_PATH } from "@/config/settings";
import { renderSuggestion } from "@/pages/CarSelector/CarSelectorSearch";
import { getContent } from "@/utils/fetch";

const autocomplete = (input: string): Promise<CompletionEntry[]> =>
    getContent<CompletionEntry[]>(`completion/vin/${input}`).catch((error) => {
        console.error(error);
        return [];
    });

export const SearchVin = ({ show = true }): JSX.Element => {
    const [vin, setVin] = React.useState("");
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();

    const handleAutosuggestChange = (
        event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => setVin(newValue);

    const handleQRCode = (code: string) => {
        history.push(`${WORKSHOP_PATH}/CarSelector/search/vin/${code}`);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (!vin) {
            return;
        }
        history.push(`${url}/search/vin/${vin}`);
    };

    return (
        <div
            className={classnames("form-group flex-grow-1", {
                ["d-none"]: !show,
            })}
        >
            <label htmlFor="vin">{t("vehicle-data.vin")}</label>
            <form
                className="d-flex align-items-center justify-content-center"
                onSubmit={handleSubmit}
            >
                <Autocomplete
                    value={vin}
                    onChange={handleAutosuggestChange}
                    getSuggestions={autocomplete}
                    placeholder={t("vehicle-data.vin-placeholder")}
                    renderSuggestion={renderSuggestion}
                />
                <IdCarReaderButton cb={handleQRCode} />
                <BarcodeReaderButton
                    showQRCode
                    type="vin"
                    title="VIN Scanner"
                    onSubmit={(code: string) => {
                        history.push(`${url}/search/vin/${code}`);
                    }}
                />
            </form>
        </div>
    );
};
