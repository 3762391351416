import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type {
    GroupBase,
    MultiValueGenericProps,
    OptionProps,
} from "react-select";
import type { SelectComponents } from "react-select/dist/declarations/src/components";
import classnames from "classnames";
import type { TFunction } from "i18next";

import type { OE } from "shared/dist/types/item";

import { ErrorMessage } from "@/components/ErrorMessage";
import { FilterSelect } from "@/components/FilterSelect";
import { Loading } from "@/components/Loading";
import { IMAGES_SERVER } from "@/config/settings";
import { useAPI, useLanguage } from "@/utils/hooks";
import { getSupplierLogoURL } from "@/utils/utils";

import { ItemTable } from "./ItemTable";

type Props = {
    mdd: string;
    genart: number;
};

type MddOE = OE & {
    id_fournisseur: number;
    label_fournisseur: string;
};

type ItemsResults = {
    items: MddOE[];
    nbOfResults: number;
};

type FilterType = "mdd" | "brand";

type FilterOption = {
    label: string;
    value: string;
    type: FilterType;
};

const getImageInfo = (data: FilterOption) => {
    let src = "";
    let alt = "";
    switch (data.type) {
        case "mdd":
            src = `${IMAGES_SERVER}/picto/mdd.png`;
            alt = "mdd";
            break;
        case "brand":
            src = /^\d+$/.test(data.value)
                ? getSupplierLogoURL(data.value)
                : `${IMAGES_SERVER}/logos/IDLP.png`;
            alt = data.value;
            break;
    }
    return { src, alt };
};

const CustomOption = (props: OptionProps<FilterOption, true>): JSX.Element => {
    const { innerRef, innerProps, label, isDisabled, isFocused, isSelected } =
        props;
    const data: FilterOption = props.data;

    const className = classnames({
        "filter-option": true,
        "option--is-disabled": isDisabled,
        "option--is-focused": isFocused,
        "option--is-selected": isSelected,
    });

    const { src, alt } = getImageInfo(data);

    return (
        <div className={className} ref={innerRef} {...innerProps}>
            <img src={src} alt={alt} width="30" />
            <span>{label}</span>
        </div>
    );
};

const FilterLabel = (
    props: MultiValueGenericProps<FilterOption, true>
): JSX.Element => {
    const { data } = props;
    const { src, alt } = getImageInfo(data);

    return (
        <div>
            <img src={src} alt={alt} width="30" />
            <span>{data.label}</span>
        </div>
    );
};

const customComponents: Partial<
    SelectComponents<FilterOption, true, GroupBase<FilterOption>>
> = {
    Option: CustomOption,
    MultiValueLabel: FilterLabel,
};

const makeFilterOptions = (
    items: MddOE[],
    t: TFunction
): ReadonlyArray<GroupBase<FilterOption>> => {
    const refs = items.map((item) => item.id);
    const mddOptions: FilterOption[] = Array.from(new Set(refs)).map((oe) => ({
        label: oe,
        value: oe,
        type: "mdd",
    }));
    const brands = new Map<string, string>();
    for (const item of items) {
        brands.set(
            String(
                item.id_fournisseur || item.label_fournisseur || item.makeId
            ),
            item.makeLabel
        );
    }
    const brandIds = [...brands.keys()];
    const brandOptions: FilterOption[] = brandIds.map((brandId) => ({
        label: brands.get(brandId) || "",
        value: brandId,
        type: "brand",
    }));

    return [
        {
            label: t("item.make"),
            options: brandOptions,
        },
        {
            label: t("item.manufacturer-reference"),
            options: mddOptions,
        },
    ];
};

const filterSelectedOEs = (
    items: MddOE[],
    filters: ReadonlyArray<FilterOption>
): MddOE[] => {
    const mddFilters = (filters || []).filter(
        (filter) => filter.type === "mdd"
    );
    const brandFilters = (filters || []).filter(
        (filter) => filter.type === "brand"
    );
    return (items || []).filter(
        (item) =>
            (mddFilters.length === 0 ||
                mddFilters.some((filter) => filter.value === item.id)) &&
            (brandFilters.length === 0 ||
                brandFilters.some(
                    (filter) =>
                        filter.value ===
                        String(
                            item.id_fournisseur ||
                                item.label_fournisseur ||
                                item.makeId
                        )
                ))
    );
};

const MddItems = ({ items }: { items: MddOE[] }) => {
    const [selectedTags, setSelectedTags] = React.useState<
        ReadonlyArray<FilterOption>
    >([]);
    const { t } = useTranslation();

    const options = useMemo(() => makeFilterOptions(items, t), [items, t]);

    const handleOEChange = (tags: ReadonlyArray<FilterOption>): void =>
        setSelectedTags(tags);

    const filteredOEs = filterSelectedOEs(items, selectedTags);

    const count = items.length;
    let label = t("partselector.resultsWithCount", {
        count,
    });
    if (count > filteredOEs.length) {
        label = `${filteredOEs.length}/${label}`;
    }

    return (
        <div className="d-flex flex-column flex-grow-1">
            <h2>{label}</h2>
            <FilterSelect
                placeholder="Choisissez une ou plusieurs marques"
                options={options}
                value={selectedTags}
                onChange={handleOEChange}
                components={customComponents}
            />
            <ItemTable oes={filteredOEs} className="stretch overflow-auto" />;
        </div>
    );
};

export const MddItemTable = ({ mdd, genart }: Props): JSX.Element => {
    const lang = useLanguage();
    const url = `parts/all/make/article/${lang}/${mdd}/${genart}/1`;
    const { data, error } = useAPI<ItemsResults>(url);
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    const items = data.items.map((item) => ({
        ...item,
        makeId: String(item.id_fournisseur || item.makeId),
        makeLabel: item.label_fournisseur || item.makeLabel,
    }));
    return <MddItems items={items} />;
};
