import React from "react";
import { useTranslation } from "react-i18next";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { OE } from "shared/dist/types/item";
import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { ErrorMessage } from "@/components/ErrorMessage";
import { ItemTable } from "@/components/Item/ItemTable";
import { Loading } from "@/components/Loading";
import { useSlides } from "@/components/SlidesStack";
import { useUserSettings } from "@/context/UserSettingsContext";
import { renderSuggestion } from "@/pages/CarSelector/CarSelectorSearch";
import { getContent } from "@/utils/fetch";
import { useAPI, useLanguage } from "@/utils/hooks";

import "./Cat4SearchBar.css";

const autocomplete = (input: string): Promise<CompletionEntry[]> =>
    getContent<CompletionEntry[]>(
        `completion/cat4/${encodeURIComponent(input)}`
    ).catch((error) => {
        console.error(error);
        return [];
    });

const Cat4ItemsLoader = ({ makeAndModel }: { makeAndModel: string }) => {
    const lang = useLanguage();
    const url = `parts/cat4/${lang}/${encodeURIComponent(makeAndModel)}`;
    const { data, error } = useAPI<OE[]>(url);
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    return <ItemTable oes={data} className="stretch" />;
};

const SearchCat4 = () => {
    const [input, setInput] = React.useState("");
    const { t } = useTranslation();
    const { addSlide } = useSlides();

    const onChange: React.ComponentProps<typeof Autocomplete>["onChange"] = (
        _event,
        { newValue }
    ) => setInput(newValue);
    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        addSlide({
            id: `cat4-${input}`,
            content: <Cat4ItemsLoader makeAndModel={input} />,
        });
    };

    return (
        <form
            className="d-inline-flex align-items-center ms-1 cat4-searchbar"
            onSubmit={onSubmit}
        >
            <Autocomplete
                value={input}
                onChange={onChange}
                getSuggestions={autocomplete}
                placeholder={t("carselector.search-brand-placeholder")}
                renderSuggestion={renderSuggestion}
            />
            <button className="btn btn-outline-secondary ms-2">
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </form>
    );
};

export const Cat4SearchBar = (): JSX.Element | null => {
    const userSettings = useUserSettings();
    return userSettings.cat4 === "show" ? <SearchCat4 /> : null;
};
