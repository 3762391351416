import * as React from "react";
import {
    faTimesCircle,
    faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { differenceInHours, differenceInMilliseconds } from "date-fns";

import { Countdown } from "@/components/Countdown";

import "./StackSlider.css";

export type AppDates = {
    dateTo?: Date;
    alertEnd?: Date;
};

type Slide = {
    id: string;
    content: React.ReactElement;
    className?: string;
    initial?: boolean;
    dates?: AppDates;
};

type StackSliderState = {
    active: boolean;
    mode3D: boolean;
    visible: boolean;
    slides: Slide[];
};

const SlidesContext = React.createContext<StackSliderState | undefined>(
    undefined
);

export const useSlidesContext = (): StackSliderState => {
    const context = React.useContext(SlidesContext);
    if (context === undefined) {
        throw new Error("useSlidesContext must be used insed a SlidesProvider");
    }
    return context;
};

type SetSlidesAction = {
    type: "setSlides";
    slides: Slide[];
};
type SetActiveAction = {
    type: "setActive";
    active: boolean;
};
type SetVisibleAction = {
    type: "setVisible";
    visible: boolean;
};
type ToggleAction = {
    type: "toggle3D" | "toggleVisibility";
};
type AddSlideAction = {
    type: "addSlide";
    slide: Slide;
};
type RemoveSlideAction = {
    type: "removeSlide";
    id: string;
};

type SlideAction =
    | SetSlidesAction
    | SetActiveAction
    | SetVisibleAction
    | ToggleAction
    | AddSlideAction
    | RemoveSlideAction;

const slidesReducer = (
    state: StackSliderState,
    action: SlideAction
): StackSliderState => {
    switch (action.type) {
        case "setSlides":
            return { ...state, slides: action.slides };
        case "setActive":
            return { ...state, active: action.active };
        case "setVisible":
            return { ...state, visible: action.visible };
        case "toggle3D":
            return { ...state, mode3D: !state.mode3D };
        case "toggleVisibility":
            return { ...state, visible: !state.visible };
        case "addSlide": {
            const index =
                state.slides.findIndex(
                    (slide) => slide.id === action.slide.id
                ) + 1;
            if (index === 0) {
                // Not found, add slide
                return {
                    ...state,
                    visible: true,
                    slides: state.slides.concat(action.slide),
                };
            }
            if (index === state.slides.length) {
                // Slide exists and is the last one, do nothing
                return state;
            }
            // Slide exists, move it to last position to make it visible
            const slides = state.slides
                .slice(index)
                .concat(state.slides.slice(0, index));
            return { ...state, active: true, slides };
            // The active property is used to make a transition effect. In order
            // for it to work properly, when it is set to true, it needs to be
            // set back to false a short while after. See use in useSlides.
        }
        case "removeSlide": {
            const index = state.slides.findIndex(
                (slide) => slide.id === action.id
            );
            if (index !== -1) {
                return {
                    ...state,
                    slides: state.slides.filter((_, i) => i !== index),
                };
            }
            return state;
        }
        default:
            throw new Error("Unsupported action type");
    }
};

const SlidesDispatchContext = React.createContext<
    React.Dispatch<SlideAction> | undefined
>(undefined);

const useSlidesDispatch = () => {
    const context = React.useContext(SlidesDispatchContext);
    if (context === undefined) {
        throw new Error("useSlidesContext must be used insed a SlidesProvider");
    }
    return context;
};

type SlideProps = Slide & {
    isLast: boolean;
};

const ROTATED = { rotate: 45 };

type AlertCountdownProps = {
    onComplete: () => void;
    dates?: AppDates;
};
const AlertCountdown = ({ dates = {}, onComplete }: AlertCountdownProps) => {
    type State = "idle" | "Show countdown";
    const [state, setState] = React.useState<State>("idle");

    React.useEffect(() => {
        const now = new Date();
        if (!dates.dateTo || !dates.alertEnd) {
            return;
        }
        if (dates.alertEnd <= now) {
            setState("Show countdown");
            return;
        }
        const hours = differenceInHours(dates.alertEnd, now);
        if (hours > 24) {
            return;
        }
        const timeout = setTimeout(() => {
            setState("Show countdown");
        }, differenceInMilliseconds(dates.alertEnd, now));
        return () => {
            clearTimeout(timeout);
        };
    }, [dates.alertEnd, dates.dateTo]);

    if (state !== "Show countdown") {
        return null;
    }

    return (
        <Countdown
            date={dates.dateTo}
            onComplete={onComplete}
            className="align-self-end"
        />
    );
};

const SlideComponent = (props: SlideProps) => {
    const { id, content, isLast, initial } = props;
    const { removeSlide } = useSlides();
    const onClose: React.MouseEventHandler = (event) => {
        event.stopPropagation();
        removeSlide(id);
    };
    const { active, slides } = useSlidesContext();
    const dispatch = useSlidesDispatch();
    const toggle3D = () => dispatch({ type: "toggle3D" });
    const [extended, setExtended] = React.useState(false);
    const toggleExtended = React.useCallback(
        () => setExtended((e) => !e),
        [setExtended]
    );

    const bringSlideToFront = () => {
        const frontSlideIndex = slides.length - 1;
        if (id === slides[frontSlideIndex].id) {
            return;
        }
        if (slides.find((s) => s.id === id) === undefined) {
            return;
        }
        let newSlides = slides;
        while (newSlides[frontSlideIndex].id !== id) {
            newSlides = shiftLeft(newSlides);
        }
        dispatch({ type: "setActive", active: true });
        dispatch({ type: "setSlides", slides: newSlides });
        setTimeout(() => dispatch({ type: "setActive", active: false }), 300);
        return;
    };
    const className = classnames(
        "slide",
        { active: active && isLast, extended },
        props.className
    );
    return (
        <div className={className}>
            <div className="slide-title d-flex">
                <div className="btn btn-none" onClick={toggle3D}>
                    <FontAwesomeIcon
                        icon={faWindowRestore}
                        className="toggle-3D"
                    />
                </div>
                <AlertCountdown
                    dates={props.dates}
                    onComplete={() => removeSlide(id)}
                />
                <button
                    className="flex-grow-1 btn btn-none"
                    onClick={bringSlideToFront}
                />
                {!initial && (
                    <div className="btn btn-none" onClick={onClose}>
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="closing-icon"
                        />
                    </div>
                )}

                <div className="btn btn-none" onClick={toggleExtended}>
                    <FontAwesomeIcon
                        icon="arrows-alt"
                        className="toggle-extended"
                        transform={ROTATED}
                    />
                </div>
            </div>
            <div className="slide-wrapper slide-context">{content}</div>
        </div>
    );
};
const Slide = React.memo(SlideComponent);

type UseSlides = {
    addSlide: (slide: Slide) => void;
    removeSlide: (id: string) => void;
    toggleVisibility(): void;
};

export const useSlides = (): UseSlides => {
    const dispatch = React.useContext(SlidesDispatchContext);
    if (dispatch === undefined) {
        throw new Error(
            "useSlides must be used within a SlidesDispatchContext"
        );
    }
    const addSlide = (slide: Slide) => {
        dispatch({ type: "addSlide", slide });
        setTimeout(() => dispatch({ type: "setActive", active: false }), 300);
    };
    const removeSlide = (id: string) => dispatch({ type: "removeSlide", id });
    const toggleVisibility = () => dispatch({ type: "toggleVisibility" });
    return { addSlide, removeSlide, toggleVisibility };
};

export const StackSliderButtons = (): JSX.Element => {
    const { slides, visible } = useSlidesContext();
    const dispatch = useSlidesDispatch();
    const toggle3D = () => dispatch({ type: "toggle3D" });
    const toggleVisibility = () => dispatch({ type: "toggleVisibility" });
    return (
        <div className="stack-slider-buttons">
            {slides.length > 0 && (
                <FontAwesomeIcon
                    icon="barcode"
                    className="icon-button"
                    onClick={toggleVisibility}
                />
            )}
            <FontAwesomeIcon
                icon={faWindowRestore}
                className={classnames("icon-button", !visible && "d-none")}
                onClick={toggle3D}
            />
        </div>
    );
};

export const SlidesProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const initialState: StackSliderState = {
        active: false,
        mode3D: false,
        visible: false,
        slides: [],
    };
    const [state, dispatch] = React.useReducer(slidesReducer, initialState);

    return (
        <SlidesContext.Provider value={state}>
            <SlidesDispatchContext.Provider value={dispatch}>
                {children}
            </SlidesDispatchContext.Provider>
        </SlidesContext.Provider>
    );
};

const shiftLeft = <T,>(array: T[]): T[] => array.slice(1).concat(array[0]);
const shiftRight = <T,>(array: T[]): T[] => [
    array[array.length - 1],
    ...array.slice(0, -1),
];

type SlidesStackProps = {
    className?: string;
    children?: React.ReactElement;
};

export const SlidesStack = ({
    className,
    children,
}: SlidesStackProps): JSX.Element => {
    const { mode3D, slides, visible } = useSlidesContext();
    const dispatch = useSlidesDispatch();

    const onKeyUp = React.useCallback(
        (e: KeyboardEvent): void => {
            if (e.key === "Escape" && mode3D) {
                dispatch({ type: "toggle3D" });
                return;
            }
            if (slides.length < 2) {
                return;
            }
            if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
                dispatch({ type: "setActive", active: true });
                dispatch({ type: "setSlides", slides: shiftLeft(slides) });
                setTimeout(
                    () => dispatch({ type: "setActive", active: false }),
                    300
                );
                return;
            }
            if (e.key === "ArrowRight" || e.key === "ArrowDown") {
                dispatch({ type: "setActive", active: true });
                setTimeout(() => {
                    dispatch({ type: "setSlides", slides: shiftRight(slides) });
                    dispatch({ type: "setActive", active: false });
                }, 300);
                return;
            }
        },
        [mode3D, slides, dispatch]
    );

    React.useEffect(() => {
        document.addEventListener("keyup", onKeyUp);
        return () => {
            document.removeEventListener("keyup", onKeyUp);
        };
    }, [onKeyUp]);

    React.useEffect(() => {
        if (slides.length === 0 && visible) {
            dispatch({ type: "setVisible", visible: false });
        }
    }, [slides, visible, dispatch]);

    React.useEffect(() => {
        if (slides.length === 0 && children) {
            const initialSlides: Slide[] = React.Children.map(
                children,
                (child, index) => ({
                    id: `initialSlide-${index}`,
                    content: child,
                    initial: true,
                })
            );
            dispatch({ type: "setSlides", slides: initialSlides });
            dispatch({ type: "setVisible", visible: true });
        }
    }, [children, slides, dispatch]);

    return (
        <div
            className={classnames(
                className,
                !visible && "hidden",
                mode3D && "_3D"
            )}
        >
            <div className="stack-slider">
                {slides.map((slide, i) => (
                    <Slide
                        {...slide}
                        key={slide.id}
                        isLast={i === slides.length - 1}
                    />
                ))}
            </div>
        </div>
    );
};
