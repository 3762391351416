import * as React from "react";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Widget } from "@/components/FicheArticle/Widget";
import { useExpertDataMode } from "@/context/ExpertDataContext";
import { useAPI } from "@/utils/hooks";

import { Countries } from "./Countries";

type Countries = {
    included: string[];
    excluded: string[];
};

type Props = {
    artNr: string;
    dlnr: string;
};

export const ItemCountries = ({ artNr, dlnr }: Props): JSX.Element | null => {
    const { t } = useTranslation();
    const expertDataMode = useExpertDataMode();

    const url = expertDataMode
        ? `part/countries/${encodeURIComponent(artNr)}/${dlnr}`
        : null;
    const { data: countries, error } = useAPI<Countries>(url, {
        initialData: { included: [], excluded: [] },
    });

    if (!expertDataMode) {
        return null;
    }

    return (
        <Widget name={t("item.countries")}>
            {error ? <ErrorMessage /> : <Countries {...countries} />}
        </Widget>
    );
};
