import * as React from "react";
import type { MenuListProps } from "react-select";
import { FixedSizeList } from "react-window";

const MENU_HEIGHT = 40;

export function MenuList<OptionType, IsMulti extends boolean = boolean>(
    props: MenuListProps<OptionType, IsMulti>
): JSX.Element | null {
    const { children, maxHeight } = props;

    if (!Array.isArray(children)) return null;
    return (
        <FixedSizeList
            height={maxHeight}
            itemCount={children.length}
            itemSize={MENU_HEIGHT}
            width="" // 100% width
        >
            {({ index, style }) => (
                <div className="option-wrapper" style={style}>
                    {children?.[index]}
                </div>
            )}
        </FixedSizeList>
    );
}
