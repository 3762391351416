import type * as React from "react";
import { Modal } from "reactstrap";

import type { RoleOption } from "shared/dist/types/apps";
import type { CompletionEntry } from "shared/dist/types/types";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useAPI } from "@/utils/hooks";

import type { PageApp } from "./AppsContext";
import { AppWizard } from "./AppWizard";

type Props = {
    app: PageApp;
    toggle(): void;
    isOpen: boolean;
    setApps: React.Dispatch<React.SetStateAction<PageApp[]>>;
    widgetLabel: string;
};

const DataLoader = (props: Omit<Props, "isOpen">) => {
    const { app } = props;
    const { id, widget } = app;
    const { data: realmRoles, error } =
        useAPI<ReadonlyArray<CompletionEntry>>("realmRoles");
    const { data: appRoles, error: errorApp } = useAPI<RoleOption[]>(
        `app/role/${widget}/${id}`,
        { ignoreCache: true }
    );
    if (error || errorApp) {
        return <ErrorMessage />;
    }
    if (!realmRoles || !appRoles) {
        return <Loading />;
    }
    return (
        <ErrorBoundary>
            <AppWizard
                {...props}
                realmRoles={realmRoles}
                appRoles={appRoles}
                title="Modification de l'app"
                postTo="updateApp"
                successMsg="App mise à jour."
                errorMsg="Impossible de mettre à jour l'app"
            />
        </ErrorBoundary>
    );
};

export const EditAppWizard = ({
    isOpen,
    ...props
}: Props): JSX.Element | null => {
    return (
        <Modal isOpen={isOpen} size="lg" toggle={props.toggle}>
            {isOpen ? <DataLoader {...props} /> : null}
        </Modal>
    );
};
