import * as React from "react";
import { useForm } from "react-hook-form";
import Select, { createFilter, GroupBase } from "react-select";
import type { SelectComponents } from "react-select/dist/declarations/src/components";

import type { User } from "shared/dist/types/auth";
import type { CompletionEntry } from "shared/dist/types/types";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { MenuList } from "@/components/OptimizedMenuList";
import { Restricted } from "@/components/Restricted";
import { i18n } from "@/config/i18n";
import { postContent } from "@/utils/fetch";
import { useAPI, useLanguage } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

type UserOption = User & CompletionEntry;

type CustomComponents = Partial<
    SelectComponents<UserOption, false, GroupBase<UserOption>>
>;
const selectComponents: CustomComponents = { MenuList };
const filterOption = createFilter({ ignoreAccents: false });

const SelectUsers = ({
    users,
    onChange,
}: {
    users: UserOption[];
    onChange(user: UserOption | null): void;
}) => {
    return (
        <Select
            options={users}
            onChange={onChange}
            components={selectComponents}
            filterOption={filterOption}
            isSearchable
            isClearable
        />
    );
};

const LoadUsers = ({
    onChange,
}: {
    onChange(user: UserOption | null): void;
}) => {
    const { data, error } = useAPI<User[]>("users");
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    const users: UserOption[] = data.map((user) => ({
        ...user,
        value: String(user.userId),
        label: `${user.name} - ${user.email}`,
    }));

    return <SelectUsers users={users} onChange={onChange} />;
};

type Branch = { id: string; label: string };
type Family = Branch & { branches: Branch[] };
type Families = Record<string, Family>;

const AccessRightsForm = ({
    families,
    userRights,
    userId,
    companyId,
    onSuccess,
}: {
    families: Families;
    userRights: string[];
    userId: number;
    companyId: number;
    onSuccess(): void;
}) => {
    const { register, handleSubmit, reset, setValue } = useForm();
    const onSubmit = async (data: Record<string, boolean>) => {
        const rights = Object.keys(data).filter((key) => data[key]);
        try {
            await postContent(`naiRights/${companyId}/${userId}`, {
                rights,
            });
            toastr.success(i18n.t("users.updated"));
            onSuccess();
        } catch (error) {
            toastr.error(i18n.t("common.error"));
        }
    };

    React.useEffect(() => {
        reset();
    }, [reset, userRights]);

    const checkEveryInput = (checked: boolean) => {
        for (const family of Object.values(families)) {
            setValue(family.id, checked);
            for (const branch of family.branches) {
                setValue(branch.id, checked);
            }
        }
    };

    return (
        <form
            className="mt-3 overflow-hidden d-flex flex-column"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="d-flex flex-wrap overflow-auto">
                {Object.entries(families).map(([id, family]) => (
                    <section
                        key={id}
                        className="d-flex flex-column align-items-start mx-2"
                    >
                        <h3>
                            <label className="cursor-pointer">
                                {family.label}
                                <input
                                    type="checkbox"
                                    className="ms-2"
                                    defaultChecked={userRights.includes(id)}
                                    {...register(id)}
                                />
                            </label>
                        </h3>
                        <ul className="px-3">
                            {family.branches.map((branch) => (
                                <li key={branch.id}>
                                    <label className="cursor-pointer d-flex">
                                        <span className="me-auto">
                                            {branch.label}
                                        </span>
                                        <input
                                            type="checkbox"
                                            className="ms-2"
                                            defaultChecked={userRights.includes(
                                                branch.id
                                            )}
                                            {...register(branch.id)}
                                        />
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </section>
                ))}
            </div>
            <div className="d-flex align-items-end">
                <button className="btn btn-secondary me-2" type="reset">
                    Réinitialiser
                </button>
                <button className="btn btn-primary">Valider</button>
                <button
                    className="btn btn-primary me-2 ms-auto"
                    type="button"
                    onClick={() => checkEveryInput(true)}
                >
                    Tous
                </button>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => checkEveryInput(false)}
                >
                    Aucun
                </button>
            </div>
        </form>
    );
};

const AccessRights = ({
    userId,
    companyId,
}: {
    userId: number;
    companyId: number;
}) => {
    const lang = useLanguage();
    const { data: families, error } = useAPI<Families>(
        `naiAccessLabels/${lang}`
    );
    const {
        data: userRights,
        error: errorUser,
        fetchData,
    } = useAPI<string[]>(`naiRights/${companyId}/${userId}`, {
        ignoreCache: true,
    });
    if (error || errorUser) return <ErrorMessage />;
    if (!families || !userRights) return <Loading />;
    return (
        <AccessRightsForm
            families={families}
            userRights={userRights}
            userId={userId}
            companyId={companyId}
            onSuccess={fetchData}
        />
    );
};

const OEMAdminWrapper = (): JSX.Element => {
    const [user, setUser] = React.useState<UserOption | null>(null);
    return (
        <div className="d-flex flex-column overflow-hidden h-100">
            <LoadUsers onChange={setUser} />
            {user && (
                <AccessRights
                    userId={user.userId}
                    companyId={user.companies[0].id}
                />
            )}
        </div>
    );
};

export const OEMAdmin = (): JSX.Element => (
    <Restricted access="admin">
        <div className="users-admin flex-grow-1 d-flex bg-light m-2 p-3 flex-column">
            <h1>Gestion des droits OEM</h1>
            <ErrorBoundary>
                <OEMAdminWrapper />
            </ErrorBoundary>
        </div>
    </Restricted>
);
