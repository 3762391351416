import * as React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { AppType, WidgetType } from "shared/dist/types/apps";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { Restricted } from "@/components/Restricted";
import { fetchWithAuth } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import { NewAppWizard } from "./NewAppWizard";

type App = {
    widget: WidgetType;
    id: number;
    name: string;
    appType: AppType;
    url: string | null;
    img: string | null;
    openInNewTab: 0 | 1;
    brandId: string | null;
    accessRight: string | null;
};

type AppsProps = {
    apps: App[];
};

const EditableApp = ({
    name,
    img,
    widget,
    id,
}: {
    name: string;
    img: string;
    widget: string;
    id: number;
}) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggle = () => setModalOpen((o) => !o);
    const deleteApp = async () => {
        try {
            await fetchWithAuth(`app/${widget}/${id}`, { method: "delete" });
            toastr.success(`L'app ${name} a été supprimée`);
            setModalOpen(false);
        } catch (error) {
            console.error(error);
            toastr.error("Impossible de supprimer l'app");
        }
    };
    return (
        <button className="marque new-app" onClick={toggle}>
            <img src={img} alt={name} title={name} />
            <Modal isOpen={modalOpen} toggle={toggle}>
                <ModalBody>
                    Êtes vous sûr de vouloir supprimer l&rsquo;app {name} du
                    widget {widget} ?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={deleteApp}>
                        Supprimer
                    </Button>
                    <Button color="secondary" type="button" onClick={toggle}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
        </button>
    );
};

const NewAppButton = ({ widget }: { widget: WidgetType }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggle = () => setModalOpen((o) => !o);
    return (
        <button className="marque new-app" onClick={toggle}>
            <FontAwesomeIcon icon={faPlus} size="2x" />
            <NewAppWizard widget={widget} toggle={toggle} isOpen={modalOpen} />
        </button>
    );
};

const Widget = ({ name, apps }: AppsProps & { name: WidgetType }) => {
    return (
        <div className="mb-4">
            <h3>{name}</h3>
            <div className="d-flex flex-wrap overflow-hidden">
                {apps.map((app) => (
                    <EditableApp
                        key={app.id}
                        name={app.name}
                        img={app.img ?? ""}
                        widget={name}
                        id={app.id}
                    />
                ))}
                <NewAppButton widget={name} />
            </div>
        </div>
    );
};

const WidgetList = ({ apps }: AppsProps) => {
    const widgets = [...new Set(apps.map((app) => app.widget))];
    return (
        <>
            {widgets.map((widget) => (
                <Widget
                    name={widget}
                    apps={apps.filter((app) => app.widget === widget)}
                    key={widget}
                />
            ))}
        </>
    );
};

const AppsLoader = () => {
    const { data, error } = useAPI<App[]>("apps");

    if (error) {
        return <ErrorMessage />;
    }

    if (!data) {
        return <Loading />;
    }

    return <WidgetList apps={data} />;
};

const AppsManagerWrapper = (): JSX.Element => {
    return (
        <div className="apps-manager flex-grow-1 d-flex flex-column mt-5 bg-light m-2 p-3 position-relative">
            <h1>Apps Manager</h1>
            <div className="overflow-auto">
                <AppsLoader />
            </div>
        </div>
    );
};

export const AppsManager = (): JSX.Element => (
    <Restricted access="admin">
        <AppsManagerWrapper />;
    </Restricted>
);
