import * as React from "react";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useAPI } from "@/utils/hooks";

import { DashboardContent } from "./DashboardContent";
import { DashboardHeader } from "./DashboardHeader";
import { FileBrowser } from "./FileBrowser";

import "./Dashboard.css";

export type DataPoint = { x: string; hits: number };
type StackedBarPoint = DataPoint & {
    adnOK: number;
    adn0: number;
    adnNull: number;
};

export type DashboardData = {
    areaData: Array<DataPoint>;
    sliding30daysData: Array<DataPoint>;
    topVCIData: Array<DataPoint>;
    pieData: Array<DataPoint>;
    stackedBarData: Array<StackedBarPoint>;
    deploymentData: Array<DataPoint>;
    newUsers: Array<{ x: string; nbUsers: number; newUsers: number }>;
};

const DataLoader = () => {
    const { data, error } = useAPI<DashboardData>("dashboard");
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    return <DashboardContent {...data} />;
};

const Dashboard = (): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    return (
        <div className="dashboard" ref={ref}>
            <DashboardHeader dashboard={ref} />
            <DataLoader />
            <FileBrowser />
            <div className="dashboard-footer">
                <span>
                    © C@iA sasu Node Auto Infrastructure :: powered by C@iA ::.
                </span>
            </div>
        </div>
    );
};

export default Dashboard;
