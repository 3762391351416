import * as React from "react";
import { Link } from "react-router-dom";
import { Badge, Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import clsx from "classnames";
import { differenceInHours, differenceInMilliseconds, min } from "date-fns";

import { App, WIDGET_TYPES, WidgetType } from "shared/dist/types/apps";

import { LEVAM_URL } from "@/config/settings";
import {
    DATABOX_IMG,
    PROXY_DATABOX_URL,
    USE_KEYCLOAK,
} from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useStatsContext } from "@/context/StatsContext";
import { DiagIframe } from "@/pages/Diag/Diag";
import { LMSEditor } from "@/pages/LMS/LMSEditor";
import { OEMAdmin } from "@/pages/OEMAdmin";
import { NotificationSender } from "@/pages/RSEEditor/NotificationSender";
import { RSEEditor } from "@/pages/RSEEditor/RSEEditor";
import { SmartEditor } from "@/pages/RSEEditor/SmartEditor";
import { V2Editor } from "@/pages/RSEEditor/V2Editor";
import { UsersAdminPage } from "@/pages/UsersAdmin/UsersAdmin";
import { postContent } from "@/utils/fetch";
import { useAPI as useOldAPI, useLanguage } from "@/utils/hooks";
import { useAPIwithKeycloak } from "@/utils/useAPIwithKeycloak";
import { isDefined, isIn, isInArray } from "@/utils/utils";

import { useAddItemSlide } from "./Item/ItemSlides";
import { MddItemTable } from "./Item/MddItemTable";
import { AppButton } from "./AppButton";
import { GenartApp, V2App, V2IframeApp } from "./AppsV2";
import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";
import { useSlides } from "./SlidesStack";

import "./AppsWidget.css";

const Dashboard = React.lazy(() => import("@/pages/Dashboard/Dashboard"));

type AppProps = Omit<App, "id" | "appType">;

export const getAppURL = (
    app: Partial<App>
): {
    url: string | null | undefined;
    dateTo: Date | undefined;
    alertEnd: Date | undefined;
} => {
    const dateFrom = app.dateFrom ? new Date(app.dateFrom) : undefined;
    const dateTo = app.dateTo ? new Date(app.dateTo) : undefined;
    const alertEnd = app.alertEnd ? new Date(app.alertEnd) : undefined;
    let url = app.url;
    if (app.urlStart && dateFrom && new Date() < dateFrom) {
        url = app.urlStart;
    }
    if (app.urlEnd && alertEnd && alertEnd < new Date()) {
        url = app.urlEnd;
    }
    if (app.maintenanceMode && app.urlMaintenance) {
        url = app.urlMaintenance;
    }
    return { url, dateTo, alertEnd };
};

export const getDataAttribute = (app: Partial<App>, key: string) => {
    if (!app.data) {
        return null;
    }
    try {
        const data = JSON.parse(app.data);
        return data[key];
    } catch (error) {
        // ignore
    }
    return null;
};

type IframeAppProps = {
    name: string;
    img: string | null;
    url: string | null;
    className?: string;
} & Partial<App>;

const IframeApp = (app: IframeAppProps) => {
    const { addIframeSlide } = useAddItemSlide();
    const onClick = () => {
        const { url, dateTo, alertEnd } = getAppURL(app);
        const sandbox = getDataAttribute(app, "sandbox") ?? true;
        if (url) {
            addIframeSlide(url, sandbox, { dateTo, alertEnd });
        }
    };
    return <AppButton app={app} onClick={onClick} />;
};

const DiagApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const addIframeSlide = () => {
        if (!app.url) return;
        addSlide({ id: app.url, content: <DiagIframe url={app.url} /> });
    };

    return <AppButton app={app} onClick={addIframeSlide} />;
};

type LinkToAppProps = IframeAppProps;
const LinkToApp = (app: LinkToAppProps) => {
    return <AppButton app={app} linkTo={app.url} className={app.className} />;
};

const SPINNER_STYLE = { color: "black", marginRight: "5px" };
const LoadingApp = () => (
    <div className="marque">
        <FontAwesomeIcon
            className="spinner"
            icon="spinner"
            spin
            style={SPINNER_STYLE}
        />
    </div>
);

const DataboxApp = () => {
    const { userInfo } = useAuth();
    if (!userInfo) {
        return <LoadingApp />;
    }
    const databoxUrl = `${PROXY_DATABOX_URL}?id=${userInfo.userId}`;
    return <IframeApp name="Databox" img={DATABOX_IMG} url={databoxUrl} />;
};

const StockApp = ({ name, img, className }: Omit<IframeAppProps, "url">) => {
    const url = `/pieces-stock/${name}`;
    return <LinkToApp name={name} img={img} url={url} className={className} />;
};

const IframeCarSelectorApp = ({
    name,
    img,
    className,
}: Omit<IframeAppProps, "url">) => {
    const url = `/selection-vehicule/app/${name}`;
    return <LinkToApp name={name} img={img} url={url} className={className} />;
};

const IconLinkApp = ({ name, url }: Omit<IframeAppProps, "img">) => (
    <Link className="marque" to={url || ""}>
        <FontAwesomeIcon icon={name as FontAwesomeIconProps["icon"]} />
    </Link>
);

const LevamApp = ({ name, img }: Omit<IframeAppProps, "url">) => {
    const lang = useLanguage();
    const url = `${LEVAM_URL}?brand=${name.toLowerCase()}&lang=${lang}`;
    return <IframeApp name={name} img={img} url={url} />;
};

const RSEEditorApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const addIframeSlide = () => {
        addSlide({ id: "RSEEditor", content: <RSEEditor /> });
    };

    return <AppButton app={app} onClick={addIframeSlide} />;
};

const SmartEditorApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const addEditorSlide = () => {
        addSlide({ id: "SmartEditor", content: <SmartEditor /> });
    };
    return <AppButton app={app} onClick={addEditorSlide} />;
};

const LMSEditorApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const addEditorSlide = () => {
        addSlide({ id: "LMSEditor", content: <LMSEditor /> });
    };
    return <AppButton app={app} onClick={addEditorSlide} />;
};

const V2EditorApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const addEditorSlide = () => {
        addSlide({ id: "V2Editor", content: <V2Editor /> });
    };
    return <AppButton app={app} onClick={addEditorSlide} />;
};

const OEMAdminApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const addEditorSlide = () => {
        addSlide({ id: "OEMAdmin", content: <OEMAdmin /> });
    };
    return <AppButton app={app} onClick={addEditorSlide} />;
};

const NotificationSenderApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const onClick = () => {
        addSlide({ id: "NotificationSender", content: <NotificationSender /> });
    };
    return <AppButton app={app} onClick={onClick} />;
};

const ItemTableBreadcrumbs = ({
    img,
    title,
}: {
    img: string;
    title: string;
}) => {
    return (
        <div className="breadcrumbs-wrapper breadcrumbs-family">
            <Breadcrumb>
                <div className="breadcrumb-img breadcrumb-img-brand">
                    <img src={img} alt="" />
                </div>
                <BreadcrumbItem>{title}</BreadcrumbItem>
            </Breadcrumb>
        </div>
    );
};

const ItemTableApp = (props: Omit<AppProps, "brandId">) => {
    const { addSlide } = useSlides();
    if (props.data === null) {
        return null;
    }
    const data = JSON.parse(props.data);
    const { mdd, genart } = data;
    const addIframeSlide = () => {
        addSlide({
            id: `ItemTable-${mdd}-${genart}`,
            content: (
                <>
                    <ItemTableBreadcrumbs
                        img={props.img || ""}
                        title={props.name}
                    />
                    <MddItemTable mdd={mdd} genart={genart} />
                </>
            ),
        });
    };

    return <AppButton app={props} onClick={addIframeSlide} />;
};

const WidgetApp = (props: Omit<AppProps, "brandId">) => {
    const { addSlide } = useSlides();
    const { data } = props;
    if (data === null || !isInArray(data, WIDGET_TYPES)) {
        return null;
    }
    const onClickHandler = () => {
        addSlide({
            id: `widget-${data}`,
            content: <AppsWidget widget={data} />,
        });
    };
    return <AppButton app={props} onClick={onClickHandler} />;
};

const DashboardApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const addDashboardSlide = () => {
        addSlide({
            id: "Dashboard",
            content: (
                <React.Suspense fallback={<Loading />}>
                    <Dashboard />
                </React.Suspense>
            ),
        });
    };

    return <AppButton app={app} onClick={addDashboardSlide} />;
};

const StatsApp = (app: IframeAppProps) => {
    const [statsData] = useStatsContext();
    const brandStat = statsData.stats.find((s) => s.make === app.name);
    return (
        <AppButton app={app} linkTo={app.url}>
            {brandStat ? <Badge>{brandStat.percent}%</Badge> : null}
        </AppButton>
    );
};

const IframeWithId = (app: IframeAppProps) => {
    const { userInfo } = useAuth();
    if (!userInfo) {
        return <LoadingApp />;
    }
    const url = app.url
        ? app.url.replace("${id}", String(userInfo.userId))
        : null;
    return <IframeApp {...app} url={url} />;
};

const UsersAdminApp = (app: IframeAppProps) => {
    const { addSlide } = useSlides();
    const addUsersSlide = () => {
        addSlide({ id: "UsersAdmin", content: <UsersAdminPage /> });
    };
    return <AppButton app={app} onClick={addUsersSlide} />;
};

const INTERNAL_APPS_MAP = {
    RSEEditor: RSEEditorApp,
    SmartEditor: SmartEditorApp,
    LMSEditor: LMSEditorApp,
    V2Editor: V2EditorApp,
    OEMAdmin: OEMAdminApp,
    NotificationSender: NotificationSenderApp,
    MDD: ItemTableApp,
    widget: WidgetApp,
    Dashboard: DashboardApp,
    stats: StatsApp,
    iframeWithId: IframeWithId,
    UsersAdmin: UsersAdminApp,
} as const;

const InternalApp = (props: AppProps) => {
    const { brandId, ...rest } = props;
    const RenderApp =
        brandId && isIn(INTERNAL_APPS_MAP, brandId)
            ? INTERNAL_APPS_MAP[brandId]
            : null;
    if (RenderApp == null) {
        return null;
    }
    return <RenderApp {...rest} />;
};

const APPS_MAP = {
    iframe: IframeApp,
    linkTo: LinkToApp,
    databox: DataboxApp,
    stock: StockApp,
    iframeCarSelector: IframeCarSelectorApp,
    iconLink: IconLinkApp,
    diag: DiagApp,
    levam: LevamApp,
    internal: InternalApp,
    v2: V2App,
    genart: GenartApp,
    iframeV2: V2IframeApp,
} as const;

type AppsWidgetProps = {
    widget: WidgetType;
    children?: React.ReactNode;
};

const useAPI = USE_KEYCLOAK ? useAPIwithKeycloak : useOldAPI;

const getDelay = (apps: App[], key: "dateFrom" | "dateTo" | "alertStart") => {
    const dateNow = new Date();
    const datesTo = apps
        .map((app) => app[key])
        .filter(isDefined)
        .map((date) => new Date(date))
        .filter((date) => date > dateNow);
    if (datesTo.length > 0) {
        const dateTo = min(datesTo);
        const hours = differenceInHours(dateTo, dateNow);
        if (hours >= 0 && hours < 24) {
            const delay = differenceInMilliseconds(dateTo, dateNow);
            return delay;
        }
    }
    return null;
};

export const getMinDelay = (apps: App[]): number => {
    const datesFromDelay = getDelay(apps, "dateFrom");
    const datesToDelay = getDelay(apps, "dateTo");
    const datesStartDelay = getDelay(apps, "alertStart");
    const delay = Math.min(
        ...[datesFromDelay, datesToDelay, datesStartDelay].filter(isDefined)
    );
    return delay;
};

const hasValidDates = (app: App): boolean => {
    const now = new Date();
    let isValid = true;
    if (app.dateTo) {
        const dateTo = new Date(app.dateTo);
        isValid = dateTo > now;
    }
    if (app.dateFrom) {
        const dateFrom = new Date(app.dateFrom);
        const date = app.alertStart ? new Date(app.alertStart) : dateFrom;
        isValid = isValid && date < now;
    }
    return isValid;
};

export const isValidApp = (app: App) => {
    const { appType, brandId } = app;
    if (!(appType in APPS_MAP)) return false;
    if (appType === "internal") {
        if (!brandId) return false;
        if (!isIn(INTERNAL_APPS_MAP, brandId)) return false;
    }
    return hasValidDates(app);
};

export const AppsWidget = ({
    widget,
    children,
}: AppsWidgetProps): JSX.Element | null => {
    const { data: apps, setData, error } = useAPI<App[]>(`widget/${widget}`);

    if (error) {
        return (
            <>
                {children}
                <ErrorMessage />
            </>
        );
    }

    if (!apps) return null;

    return (
        <>
            {apps.length > 0 ? children : null}
            <DndWidget widget={widget} apps={apps} setApps={setData} />
        </>
    );
};

export const DndWidget = ({
    widget,
    apps,
    setApps,
    className = "d-flex flex-wrap",
}: {
    widget: string;
    apps: App[];
    setApps: React.Dispatch<React.SetStateAction<App[] | null>>;
    className?: string;
}) => {
    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 20 } })
    );
    const filteredApps = apps.filter(isValidApp);
    const items = filteredApps.map((app) => String(app.id));

    React.useEffect(() => {
        let timeout: NodeJS.Timeout;
        const delay = getMinDelay(apps);
        if (delay !== Infinity) {
            console.log(`${widget}: Refresh apps in ${delay / 1000}s`);
            setTimeout(() => {
                setApps([...apps]);
            }, delay);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [apps, setApps, widget]);

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
            setApps((currentApps) => {
                if (!currentApps) return currentApps;
                const oldIndex = currentApps.findIndex(
                    (app) => String(app.id) === active.id
                );
                const newIndex = currentApps.findIndex(
                    (app) => String(app.id) === over?.id
                );
                const sortedApps = arrayMove(currentApps, oldIndex, newIndex);
                postContent(
                    `setAppOrder/${widget}`,
                    sortedApps.map((app) => app.id)
                ).catch((err) => console.error(err));
                return sortedApps;
            });
        }
    };

    return (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={items}>
                <div className={clsx("apps-widget", className)}>
                    {filteredApps.map((app) => (
                        <SortableItem app={app} key={app.id} />
                    ))}
                </div>
            </SortableContext>
        </DndContext>
    );
};

const SortableItem = ({ app }: { app: App }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: String(app.id) });
    const style = { transform: CSS.Transform.toString(transform), transition };
    const { id, appType, ...rest } = app;
    const Component = APPS_MAP[appType];
    const name = app.name === "REPARCAR" ? "SivinCE" : app.name;
    const className = clsx({ dragging: isDragging });
    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Component {...rest} name={name} className={className} />
        </div>
    );
};
