import { atom, AtomEffect } from "recoil";

import type { Astuce } from "@/components/Astuces";
import { iframes } from "@/config/settings";

type LeftMenuState = "collapsed" | "expanded";
const localStorageEffect: (key: string) => AtomEffect<LeftMenuState> =
    (key) =>
    ({ setSelf, onSet }) => {
        const savedValue = localStorage.getItem(key);
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue) => {
            localStorage.setItem(key, JSON.stringify(newValue));
        });
    };

export const leftMenuState = atom<LeftMenuState>({
    key: "leftMenuState",
    default: "collapsed",
    effects: [localStorageEffect("leftMenuState")],
});

export const astucesState = atom<Astuce[]>({
    key: "astuces",
    default: [],
});

export const astucesContentOpen = atom({
    key: "astucesContentOpen",
    default: false,
});

export const loadingState = atom({
    key: "loading",
    default: false,
});

export const identificationVehiculePageAtom = atom({
    key: "identificationVehiculePage",
    default: "/identification-vehicule",
});
export const selectionVehiculePageAtom = atom({
    key: "selectionVehiculePage",
    default: "/selection-vehicule",
});
export const piecesPageAtom = atom({
    key: "piecesPage",
    default: "/pieces",
});
export const socialPageAtom = atom({
    key: "socialPage",
    default: "/social",
});

export const urlMyBoxAtom = atom({
    key: "urlMyBox",
    default: "",
});
export const urlERPAtom = atom({
    key: "urlERP",
    default: iframes.erp,
});

export const exactSearchAtom = atom({
    key: "exactSearchMode",
    default: true,
});

export const identificationAlbumModeAtom = atom({
    key: "identificationAlbumMode",
    default: true,
});

export const selectionAlbumModeAtom = atom({
    key: "selectionAlbumMode",
    default: true,
});

export const partsAlbumModeAtom = atom({
    key: "partsAlbumMode",
    default: true,
});

export const rtpeModalOpen = atom({
    key: "rtpeModalOpen",
    default: false,
});

export const sidebarVisibleAtom = atom({
    key: "sidebarVisible",
    default: true,
});
