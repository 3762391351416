import * as React from "react";

type Props = { visible: boolean; children: React.ReactNode };

export const Lazy = ({ visible, children }: Props): JSX.Element | null => {
    const [initialized, setInitialized] = React.useState(false);

    React.useEffect(() => {
        if (visible && !initialized) {
            setInitialized(true);
        }
    }, [visible, initialized]);

    if (!initialized) {
        return null;
    }

    return <>{children}</>;
};
