import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { useExpertDataMode } from "@/context/ExpertDataContext";
import { fetchWithAuth, getContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

type Props = {
    articleId: string | undefined;
    refId: string;
    genartId: number | undefined;
    ktypnr: string | undefined;
};

export const HideShowButton = (props: Props): JSX.Element | null => {
    const { t } = useTranslation();
    const expertDataMode = useExpertDataMode();

    const [status, setStatus] = React.useState("LOADING");
    const [isHidden, setHidden] = React.useState(false);

    const { articleId, refId, genartId, ktypnr } = props;
    const label =
        status === "LOADING"
            ? t("common.loading")
            : status === "ERROR"
            ? "Error"
            : isHidden
            ? t("item.show")
            : t("item.hide");
    const hideShow = isHidden ? "show" : "hide";
    const url = `${hideShow}Ref/${ktypnr}/${genartId}/${articleId}/${encodeURIComponent(
        refId
    )}`;

    const hideButton = !ktypnr || !expertDataMode;

    React.useEffect(() => {
        if (hideButton) return;
        let unmounted = false;
        setStatus("LOADING");
        const endpoint = `shouldHideRef/${ktypnr}/${genartId}/${articleId}/${encodeURIComponent(
            refId
        )}`;
        getContent<boolean>(endpoint, { ignoreCache: true })
            .then((data) => {
                if (unmounted) return;
                setStatus("READY");
                setHidden(data);
            })
            .catch((error) => {
                console.error(error);
                if (unmounted) return;
                setStatus("ERROR");
            });
        return () => {
            unmounted = true;
        };
    }, [hideButton, ktypnr, genartId, articleId, refId]);

    const handleClick = () =>
        fetchWithAuth(url)
            .then(() => {
                toastr.success(t("item.toast.change-ref-display", { refId }));
                setHidden((h) => !h);
            })
            .catch((error) => {
                console.error(error);
                toastr.error(
                    t("item.toast.change-ref-display-error", { refId })
                );
            });

    if (hideButton) {
        return null;
    }

    return (
        <Button
            color="primary"
            onClick={handleClick}
            disabled={status !== "READY"}
            className="ms-auto align-self-center"
        >
            {label}
        </Button>
    );
};
