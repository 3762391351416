import * as React from "react";
import type {
    GroupBase,
    MultiValue,
    MultiValueGenericProps,
    OptionProps,
} from "react-select";
import StateManagedSelect, { createFilter } from "react-select";
import type { SelectComponents } from "react-select/dist/declarations/src/components";
import type Select from "react-select/dist/declarations/src/Select";
import classnames from "classnames";

import type {
    FamilyOption,
    GroupOption,
    RoleOption,
} from "shared/dist/types/apps";
import type { CompletionEntry } from "shared/dist/types/types";

import { Loading } from "@/components/Loading";
import { MenuList } from "@/components/OptimizedMenuList";
import { IMAGES_SERVER } from "@/config/settings";

import { useRoleGroups } from "./RoleGroupContext";

const getFamilyImageURL = (option: GroupOption) =>
    `${IMAGES_SERVER}/audienceGroups/${option.image}`;

const OptionElement = ({ data }: { data: RoleOption }) => (
    <>
        {data.type === "group" ? (
            <img
                src={getFamilyImageURL(data)}
                alt=""
                width="30"
                className="me-1"
            />
        ) : null}
        <span className="p-1">{data.label}</span>
    </>
);

const CustomOption = (props: OptionProps<RoleOption, true>) => {
    const { innerRef, innerProps, isDisabled, isFocused, isSelected, data } =
        props;

    const className = classnames({
        "filter-option": true,
        "option--is-disabled": isDisabled,
        "option--is-focused": isFocused,
        "option--is-selected": isSelected,
    });

    return (
        <div className={className} ref={innerRef} {...innerProps}>
            <OptionElement data={data} />
        </div>
    );
};

const LABEL_STYLE = { gridArea: "1/1/2/3" };
const FilterLabel = (props: MultiValueGenericProps<RoleOption>) => {
    const data: RoleOption = props.data;

    return (
        <div style={LABEL_STYLE}>
            <OptionElement data={data} />
        </div>
    );
};

type CustomComponents = Partial<
    SelectComponents<RoleOption, true, GroupBase<RoleOption>>
>;

const customComponents: CustomComponents = {
    Option: CustomOption,
    MultiValueLabel: FilterLabel,
    MenuList,
};
const filterOption = createFilter({ ignoreAccents: false });

type Props = {
    placeholder?: string;
    options: MultiValue<RoleOption>;
};

type SelectRef<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
> =
    | ((instance: Select<Option, IsMulti, Group> | null) => void)
    | React.MutableRefObject<Select<Option, IsMulti, Group> | null>
    | null;
type RoleSelectRef = SelectRef<RoleOption, true, GroupBase<RoleOption>>;

const RoleSelectContent = React.forwardRef(function RoleSelectContent(
    props: Props,
    ref?: RoleSelectRef
): React.ReactElement {
    const { options, placeholder = "Familles client", ...rest } = props;

    if (options.length === 0) {
        return <Loading />;
    }

    return (
        <StateManagedSelect
            {...rest}
            ref={ref}
            name="roleSelect"
            placeholder={placeholder}
            options={options}
            filterOption={filterOption}
            isMulti
            className="Select"
            components={customComponents}
        />
    );
});

export const RoleSelect = React.forwardRef(function RoleSelect(
    {
        realmRoles,
        ...rest
    }: {
        realmRoles: readonly CompletionEntry[];
    },
    ref: RoleSelectRef
) {
    const roles = useRoleGroups();
    const rolesOptions: GroupOption[] = Object.entries(roles).map(
        ([id, role]) => ({
            ...role,
            value: id,
            label: role.name,
            type: "group",
        })
    );
    const familyOptions: FamilyOption[] = realmRoles.map((role) => ({
        ...role,
        type: "family",
    }));
    const options = [...rolesOptions, ...familyOptions];
    return <RoleSelectContent {...rest} ref={ref} options={options} />;
});
