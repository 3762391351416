import * as React from "react";
import { Button } from "reactstrap";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useSlides } from "@/components/SlidesStack";
import { useAPI } from "@/utils/hooks";

import { TechautoOrder } from "./TechautoOrder";

import "./Orders.css";

type Order = {
    orderId: string;
    total: number;
    dateSent: string;
};

const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const OrderRow = ({ order }: { order: Order }) => {
    const { orderId, total, dateSent } = order;
    const { addSlide } = useSlides();
    const onClick = () => {
        addSlide({
            id: `techauto-order-${orderId}`,
            content: <TechautoOrder orderId={orderId} />,
        });
    };
    return (
        <Button color="none" onClick={onClick}>
            <strong>{orderId}</strong>
            <span className="ms-2">{formatDate(dateSent)}</span>
            <span className="ms-2">{total}CHF</span>
        </Button>
    );
};

const OrdersList = ({ orders }: { orders: Order[] }) => {
    return (
        <ol className="orders-list">
            {orders.map((order) => (
                <li key={order.orderId}>
                    <OrderRow order={order} />
                </li>
            ))}
        </ol>
    );
};

const OrdersWrapper = (): JSX.Element => {
    const { data: orders, error } = useAPI<Order[]>("orders", {
        ignoreCache: true,
    });
    if (error) {
        return <ErrorMessage />;
    }
    if (!orders) {
        return <Loading />;
    }
    return <OrdersList orders={orders} />;
};

export const Orders = (): JSX.Element => (
    <div className="commandes p-2">
        <OrdersWrapper />
    </div>
);
