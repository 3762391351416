import type React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import clsx from "classnames";

import type { Vtype } from "shared/dist/types/vehicle";

import { IMAGES_SERVER } from "@/config/settings";
import { setDefaultVehicleImage } from "@/pages/CarSelector/Albums";
import { getLogoURL, getModelImage } from "@/utils/utils";

const setDefaultImage = (e: React.SyntheticEvent<HTMLImageElement>): void => {
    e.currentTarget.src = `${IMAGES_SERVER}/gif/DEFAULT.png`;
};

type Props = {
    image?: string;
    children?: React.ReactNode;
    brand?: string;
    model?: string;
    family?: string;
    vehicleType?: Vtype;
    hideBrandLabel?: boolean;
    brandURL?: string;
    modelURL?: string;
    className?: string;
    url?: string;
};

const BrandImg = ({
    brand,
    brandURL,
    vehicleType,
}: Pick<Props, "brand" | "vehicleType" | "brandURL">) => {
    const brandLogo = getLogoURL(brand, vehicleType);
    const image = <img src={brandLogo} alt={brand} />;
    return (
        <div className="breadcrumb-img breadcrumb-img-brand">
            {brandURL ? <Link to={brandURL}>{image}</Link> : <>{image}</>}
        </div>
    );
};

const ModelImg = ({
    brand,
    model,
    modelURL,
    vehicleType,
}: Pick<Props, "brand" | "model" | "vehicleType" | "modelURL">) => {
    if (!model) {
        return null;
    }
    const modelImage = brand && model && getModelImage(brand, model);
    const image = (
        <img
            src={modelImage}
            alt={model}
            onError={setDefaultVehicleImage(vehicleType)}
        />
    );
    return (
        <div className="breadcrumb-img breadcrumb-img-model">
            {modelURL ? <Link to={modelURL}>{image}</Link> : <>{image}</>}
        </div>
    );
};

export const StateBreadcrumbs = (props: Props): JSX.Element => {
    const {
        brand,
        model,
        family,
        image,
        vehicleType,
        hideBrandLabel = false,
        url,
    } = props;
    const className = clsx(
        props.className,
        "breadcrumbs-wrapper breadcrumbs-family"
    );
    const brandLabel = model ? `${brand} ${decodeURIComponent(model)}` : brand;
    return (
        <div className={className}>
            <Breadcrumb>
                <BrandImg brand={brand} brandURL={props.brandURL} />
                <ModelImg
                    brand={brand}
                    model={model}
                    modelURL={props.modelURL}
                    vehicleType={vehicleType}
                />
                {!hideBrandLabel && (
                    <BreadcrumbItem>
                        {url ? (
                            <Link to={url}>{brandLabel}</Link>
                        ) : (
                            <span>{brandLabel}</span>
                        )}
                    </BreadcrumbItem>
                )}
                {props.children}
                {family && (
                    <div className="breadcrumb-img">
                        <img
                            src={`${IMAGES_SERVER}/gif/${family}.png`}
                            alt=""
                            onError={setDefaultImage}
                        />
                    </div>
                )}
                {image && (
                    <div className="breadcrumb-img">
                        <img src={image} alt="" />
                    </div>
                )}
            </Breadcrumb>
        </div>
    );
};
