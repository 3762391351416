import * as React from "react";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Restricted } from "@/components/Restricted";

import { AudienceManagement } from "./AudienceManagement";
import { RoleGroupsProvider } from "./RoleGroupContext";
import {
    AppsProvider,
    RSE_WIDGETS,
    useAppsContext,
    WIDGET_LABELS,
} from "./RSEAppsContext";
import { Widget } from "./Widget";

import "./RSEEditor.css";

const ALL_RSE_WIDGETS = [...RSE_WIDGETS, "rse-initial-slides"] as const;

const WidgetList = () => {
    const [apps, setApps] = useAppsContext();
    return (
        <>
            {ALL_RSE_WIDGETS.map((widget) => (
                <Widget
                    name={widget}
                    apps={apps.filter((app) => app.widget === widget)}
                    key={widget}
                    label={WIDGET_LABELS[widget]}
                    setApps={setApps}
                />
            ))}
        </>
    );
};

const RSEEditorWrapper = (): JSX.Element => {
    return (
        <div className="rse-editor flex-grow-1 d-flex flex-column mt-5 bg-light m-2 p-3 position-relative">
            <h1>RSE Editor</h1>
            <div className="overflow-auto">
                <ErrorBoundary>
                    <RoleGroupsProvider>
                        <AppsProvider>
                            <WidgetList />
                            <AudienceManagement />
                        </AppsProvider>
                    </RoleGroupsProvider>
                </ErrorBoundary>
            </div>
        </div>
    );
};

export const RSEEditor = (): JSX.Element => (
    <Restricted access="RSEEditor">
        <RSEEditorWrapper />
    </Restricted>
);
