import * as React from "react";
import classnames from "classnames";
import { intervalToDuration, isAfter } from "date-fns";

import "./Countdown.css";

const pad2 = (n: number | undefined) => String(n || 0).padStart(2, "0");

type CountdownProps = {
    date: Date | undefined;
    onComplete?: () => void;
    className?: string;
};

const CountdownContent = ({
    date,
    onComplete,
    className,
}: CountdownProps & { date: Date }) => {
    const [timeLeft, setTimeLeft] = React.useState(
        intervalToDuration({ start: new Date(), end: date })
    );

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (isAfter(date, new Date())) {
                setTimeLeft(
                    intervalToDuration({ start: new Date(), end: date })
                );
            }
        }, 1000);
        return () => clearTimeout(timer);
    });

    if (
        isAfter(new Date(), date) ||
        timeLeft.years ||
        timeLeft.months ||
        timeLeft.days
    ) {
        return null;
    }
    const { hours, minutes, seconds } = timeLeft;
    if (hours === 0 && minutes === 0 && seconds === 0) {
        onComplete?.();
    }
    return (
        <span className={classnames("countdown", className)}>
            Fermeture dans {pad2(hours)}:{pad2(minutes)}:{pad2(seconds)}
        </span>
    );
};

export const Countdown = ({
    date,
    ...other
}: CountdownProps): JSX.Element | null => {
    const now = new Date();
    if (!date || isAfter(now, date)) {
        return null;
    }
    return <CountdownContent date={date} {...other} />;
};
