import * as React from "react";
import { useParams } from "react-router-dom";
import { Badge } from "reactstrap";

import { IMAGES_SERVER } from "@/config/settings";
import { useStatsContext } from "@/context/StatsContext";

import { Versions } from "./Versions";

import "./Albums.css";

const { useState, useEffect, useCallback, useRef } = React;

const setDefaultVehicleImage =
    (vehicleType = "vl") =>
    (event: React.SyntheticEvent<HTMLImageElement>): void => {
        event.currentTarget.src = `${IMAGES_SERVER}/brands/${vehicleType}/default.jpg`;
    };
const setBigDefaultImage =
    (vehicleType: string) =>
    (event: React.SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.src = `${IMAGES_SERVER}/brands/${vehicleType}/defaultBig.png`;
    };

export type Model = {
    name: string;
    image: string;
    bigImage: string;
};

type ModelStat = { nb: number; model: string };

const StatsBadge = ({
    stats,
    model,
}: {
    model: string;
    stats: ModelStat[];
}) => {
    const [brandStats] = useStatsContext();
    const modelStat = stats.find((s) => s.model === model);
    if (!modelStat) {
        return null;
    }
    const percent = ((100 * modelStat.nb) / brandStats.total).toFixed(2);
    return <Badge>{percent}%</Badge>;
};

type Props = {
    models: Model[];
    brandName: string;
    modelName: string;
    stats?: ModelStat[];
};

export const Albums = ({
    models,
    brandName,
    modelName,
    stats = [],
}: Props): JSX.Element => {
    const [selected, setSelected] = useState<string | null>(null);
    const [bigImage, setBigImage] = useState("");
    const albumContainer = useRef<HTMLUListElement>(null);
    const { vtype } = useParams<{ vtype: string }>();

    useEffect(() => {
        if (!albumContainer.current) {
            return;
        }
        const selectedDiv = albumContainer.current.querySelector(".selected");
        if (selectedDiv) {
            selectedDiv.scrollIntoView({ block: "nearest" });
        }
    }, [selected]);

    const selectModel = useCallback(
        ({ name, bigImage: image }: Model) => {
            if (selected === name) {
                setSelected(null);
                return;
            }
            setSelected(name);
            setBigImage(image);
        },
        [selected]
    );

    useEffect(() => {
        const model = models.find((m) => m.name === modelName);
        console.log("Albums: " + modelName + (model ? " found" : " not found"));
        if (model) {
            selectModel(model);
        }
    }, [models, modelName, selectModel]);

    return (
        <ul className="albums" ref={albumContainer}>
            {models.map((model) => (
                <li
                    key={model.name}
                    className={selected === model.name ? "selected" : ""}
                >
                    <div
                        className="marque tooltip-arrow"
                        onClick={(): void => selectModel(model)}
                    >
                        <img
                            src={model.image}
                            alt={brandName}
                            width="120"
                            onError={setDefaultVehicleImage(vtype)}
                        />
                        <StatsBadge stats={stats} model={model.name} />

                        <div className="model-name">{model.name}</div>
                    </div>
                    <div className="details">
                        {selected === model.name && (
                            <>
                                <Versions
                                    brandName={brandName}
                                    modelName={model.name}
                                    vehicleType={vtype}
                                />
                                <img
                                    className="illustration"
                                    src={bigImage}
                                    alt={model.name}
                                    onError={setBigDefaultImage(vtype)}
                                />
                            </>
                        )}
                    </div>
                </li>
            ))}
        </ul>
    );
};
