import * as React from "react";
import { Button } from "reactstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { App, WidgetType } from "shared/dist/types/apps";

import { useAddItemSlide } from "@/components/Item/ItemSlides";
import { useSlidesContext } from "@/components/SlidesStack";
import { USE_KEYCLOAK } from "@/config/settings";
import { useAPI as useOldAPI } from "@/utils/hooks";
import { useAPIwithKeycloak } from "@/utils/useAPIwithKeycloak";

const useAPI = USE_KEYCLOAK ? useAPIwithKeycloak : useOldAPI;

const RestoreSlideButton = ({ app }: { app: App }) => {
    const { slides } = useSlidesContext();
    const { addIframeSlide } = useAddItemSlide();
    const slideIsVisible =
        slides.find((slide) => slide.id === app.url) !== undefined;
    if (slideIsVisible) {
        return null;
    }
    const addSlide = () => {
        app.url && addIframeSlide(app.url);
    };
    return (
        <Button
            color="none"
            className="p-1"
            onClick={addSlide}
            title={app.name}
        >
            <FontAwesomeIcon icon={faHome} />
        </Button>
    );
};

export const RestoreSlidesButtons = ({ widget }: { widget: WidgetType }) => {
    const { data } = useAPI<App[]>(`widget/${widget}`);
    if (!data || data.length === 0) {
        return null;
    }
    return (
        <>
            {data.map((app) => (
                <RestoreSlideButton app={app} key={app.id} />
            ))}
        </>
    );
};
