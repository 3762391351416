import * as React from "react";
import {
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { i18n } from "@/config/i18n";
import { postContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

import type { UserOption } from "./types";

const SelectCompanyForm = ({ user }: { user: UserOption }) => {
    const { data } = useAPI<{ id: number; name: string }[]>("users/companies", {
        initialData: [],
    });
    const userCompanyIds = user.companies.map((company) => company.id);
    const companies = data.filter(
        (company) => !userCompanyIds.includes(company.id)
    );

    const submit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData(event.currentTarget);
            const companyId = Number(formData.get("company"));
            const name = data.find((company) => company.id === companyId)?.name;
            await postContent("users/addCompany", {
                userId: user.userId,
                companyId,
            });
            // TODO: refresh user data
            toastr.success(`La société ${name} a été ajoutée`);
        } catch (error) {
            toastr.error(i18n.t("common.error"));
        }
    };

    return (
        <form onSubmit={submit}>
            <ModalBody>
                <FormGroup>
                    <Label>Société</Label>
                    <Input type="select" name="company">
                        {companies.map((company) => (
                            <option key={company.id} value={company.id}>
                                {company.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="primary">
                    Ajouter
                </Button>
            </ModalFooter>
        </form>
    );
};

export const AddCompany = ({ user }: { user: UserOption }) => {
    const [isOpen, setOpen] = React.useState(false);
    const toggle = () => setOpen((o) => !o);
    return (
        <>
            <button
                className="btn btn-primary ms-2"
                type="button"
                onClick={toggle}
                title="Associer une société supplémentaire"
            >
                <FontAwesomeIcon icon={faPlus} />
            </button>
            <Modal isOpen={isOpen} size="lg" toggle={toggle}>
                <ModalHeader toggle={toggle}>Ajouter une société</ModalHeader>
                <SelectCompanyForm user={user} />
            </Modal>
        </>
    );
};
