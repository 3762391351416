import * as React from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faBoxOpen, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppsWidget } from "@/components/AppsWidget";
import { CONTACT_SELECTOR_OTHER_PARTNERS } from "@/config/settings";

import { CompanyCover } from "./CompanyCover";
import { ConnectionApps } from "./ConnectionApps";
import { ProfilePictureAndUserName } from "./ProfilePictureAndUserName";

import "./ContactSelectorPanel.css";

import history from "@/img/history.png";
import star from "@/img/star.png";
import suppliers from "@/img/Suppliers.jpg";

export const ContactSelectorPanel = (): JSX.Element => {
    const [otherPartsOpen, setOtherPartsOpen] = React.useState(false);

    const { t } = useTranslation();

    const toggleOtherParts = (): void => setOtherPartsOpen((open) => !open);

    return (
        <div className="selection-bloc main-bloc selection-brand select-part-brand">
            <ProfilePictureAndUserName />
            <CompanyCover />
            <div>
                <span>{t("contactselector.partners")}</span>
                <div className="main-partners">
                    <AppsWidget widget="social-partners" />
                </div>
                <div className="brand-buttons">
                    <div className="marque" onClick={toggleOtherParts}>
                        <img src={suppliers} alt="Partners" />
                        <FontAwesomeIcon icon={faBoxOpen} />
                        <span> {t("contactselector.other-partners")}</span>
                    </div>
                    <div className="marque">
                        <img src={history} alt="history" />
                        <FontAwesomeIcon icon={faHistory} />
                        <span> {t("carselector.history")}</span>
                    </div>
                    <div className="marque">
                        <img src={star} alt="favorites" />
                        <FontAwesomeIcon icon={faStar} />
                        <span> {t("carselector.bookmarks")}</span>
                    </div>
                </div>
                <Collapse isOpen={otherPartsOpen}>
                    <div className="other-brands tooltip-content">
                        <ul>
                            {CONTACT_SELECTOR_OTHER_PARTNERS.map((partner) => (
                                <li key={partner}>
                                    <span>{partner}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Collapse>
            </div>
            <div className="apps-catalog">
                <div>{t("contactselector.apps-and-websites")}</div>
                <AppsWidget widget="social-digital" />
            </div>
            <ConnectionApps />
            <div className="customer-relations-apps">
                <div>{t("contactselector.apps-customer-relations")}</div>
                <AppsWidget widget="social-customer-relations" />
            </div>
            <div className="social-network-apps">
                <div>{t("contactselector.social-network")}</div>
                <AppsWidget widget="social-network" />
            </div>
        </div>
    );
};
