import * as React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import type { CustomerInfo } from "shared/dist/types/customer";
import type { QuoteData } from "shared/dist/types/quote";

import { addClient, removeClient } from "@/components/SSEHandler";
import { i18n } from "@/config/i18n";
import { useAuth } from "@/context/AuthContext";
import { CartContext } from "@/context/CartItems";
import { useCustomer } from "@/context/CustomerContext";
import { useVehicleData } from "@/context/VehicleDataContext";
import { postContent } from "@/utils/fetch";
import { toastr } from "@/utils/toastr";

const Quote = React.lazy(() => import("@/components/Quote"));

const defaultCustomerInfo: CustomerInfo = {
    title: "",
    firstname: "",
    name: "",
    phone: "",
    address: "",
    city: "",
    company: "",
    country: "",
    email: "",
    postalCode: "",
    picture: "default_user.png",
};

export const QuoteButton = () => {
    const [quoteData, setQuoteData] = React.useState<QuoteData>();
    const makeQuote = useQuote();

    const onClick = async () => {
        const data = await makeQuote();
        setQuoteData(data);
    };
    const showQuote = quoteData !== undefined;

    React.useEffect(() => {
        const clientId = addClient((data) => {
            console.log("Event received", data);
            if (data.type === "QuoteStatus") {
                setQuoteData((quote) =>
                    quote && quote.quoteId === data.quoteId
                        ? { ...quote, status: data.status }
                        : undefined
                );
            }
        });
        return () => {
            removeClient(clientId);
        };
    }, []);

    return (
        <>
            <Button
                type="button"
                color="primary"
                className="me-2"
                onClick={onClick}
            >
                Devis
            </Button>
            <Modal
                isOpen={showQuote}
                toggle={() => setQuoteData(undefined)}
                size="lg"
            >
                <ModalHeader>Devis</ModalHeader>
                <ModalBody className="d-flex tall-modal-body">
                    {quoteData ? (
                        <div className="d-flex flex-grow-1 flex-column">
                            <Quote {...quoteData} />
                            <QuoteStatus status={quoteData.status} />
                        </div>
                    ) : null}
                </ModalBody>
            </Modal>
        </>
    );
};

const QuoteStatus = ({ status = "new" }: { status: string | undefined }) => {
    const label = { new: "Nouveau", accepted: "Accepté", rejected: "Refusé" }[
        status
    ];
    return <p className="mt-2">État du devis: {label}</p>;
};

const useQuote = () => {
    const { company } = useAuth();
    const [vehicleData] = useVehicleData();
    const { cart } = React.useContext(CartContext);
    const [customer] = useCustomer();
    const customerInfo = customer?.info || defaultCustomerInfo;
    const customerId = customer?.id;

    return async () => {
        if (!customerId) {
            toastr.warning(
                "Veuillez sélectionner un client pour pouvoir générer le devis"
            );
            return;
        }
        if (!company) {
            console.warn("User's company is undefined");
            return;
        }
        const quoteData = {
            cart: JSON.stringify(cart),
            customerId,
            vehicleData: JSON.stringify(vehicleData),
        };
        try {
            const res = await postContent("quote/new", quoteData);
            const json: { id: number } = await res.json();
            const data: QuoteData = {
                quoteId: json.id,
                vehicleData,
                cart,
                customerInfo,
                company,
                status: "new",
                customerId,
            };
            return data;
        } catch (error) {
            toastr.error(i18n.t("common.error"));
        }
    };
};
