import * as React from "react";

import { addClient, removeClient } from "@/components/SSEHandler";
import { i18n } from "@/config/i18n";
import { apiRootURL } from "@/config/settings";
import { useCustomer } from "@/context/CustomerContext";
import { postPublic } from "@/utils/postPublic";
import { toastr } from "@/utils/toastr";

export const useCartHighlight = () => {
    const [customer] = useCustomer();
    const [customerRowIndex, setCustomerRowIndex] = React.useState(-1);
    const [vendorRowIndex, setVendorRowIndex] = React.useState(-1);
    const customerId = customer?.id;

    React.useEffect(() => {
        if (!customerId) return;
        const init = async () => {
            const url = `${apiRootURL}/api/cart/highlight/${customerId}`;
            const res = await fetch(url);
            if (!res.ok) {
                toastr.error(i18n.t("common.error"));
                return;
            }
            const json: { customerRow: number; vendorRow: number } =
                await res.json();
            setCustomerRowIndex(json.customerRow);
            setVendorRowIndex(json.vendorRow);
        };
        init();
    }, [customerId]);

    React.useEffect(() => {
        const clientId = addClient((data) => {
            console.log("Event received", data);
            if (data.type === "Cart Highlight") {
                if (data.customerRow !== undefined) {
                    setCustomerRowIndex(data.customerRow);
                }
                if (data.vendorRow !== undefined) {
                    setVendorRowIndex(data.vendorRow);
                }
            }
        });
        return () => {
            removeClient(clientId);
        };
    }, []);

    const toggleCustomerRow = (index: number | undefined) => {
        setCustomerRowIndex((currentIndex) => {
            const newIndex = currentIndex === index ? -1 : index ?? -1;
            postPublic("cart/highlight", {
                customerId,
                customerRow: newIndex,
            });
            return newIndex;
        });
    };

    const toggleVendorRow = (index: number | undefined) => {
        setVendorRowIndex((currentIndex) => {
            const newIndex = currentIndex === index ? -1 : index ?? -1;
            postPublic("cart/highlight", {
                customerId,
                vendorRow: newIndex,
            });
            return newIndex;
        });
    };

    return {
        customerRowIndex,
        vendorRowIndex,
        toggleCustomerRow,
        toggleVendorRow,
    };
};
