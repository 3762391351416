import * as React from "react";
import { QRCodeSVG } from "qrcode.react";

import type { App } from "shared/dist/types/apps";

import { COMPANY_LOGO_PATH, IMAGES_SERVER } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useCustomer } from "@/context/CustomerContext";
import { QRCodeScreen } from "@/pages/RSEEditor/QRCodeScreen";
import { useAPI } from "@/utils/hooks";
import { postPublic } from "@/utils/postPublic";

import { CustomerLabel } from "./CustomerLabel";
import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";
import { NewCustomerButton } from "./NewCustomerButton";

const QrCode = ({ clientId }: { clientId: number | null | undefined }) => {
    const { company } = useAuth();

    const imageSettings = {
        height: 125,
        width: 125,
        excavate: true,
        src: `${IMAGES_SERVER}/${COMPANY_LOGO_PATH}/${company?.logo}`,
    };

    if (!clientId) {
        return null;
    }
    const qrcodeURL = `${window.location.origin}/gcd?id=${clientId}`;

    return (
        <QRCodeSVG
            value={qrcodeURL}
            size={500}
            level="Q"
            imageSettings={imageSettings}
        />
    );
};

export const RTPEPanel = () => {
    const [customer] = useCustomer();
    return (
        <div className="rtpe p-3 overflow-auto">
            {customer ? (
                <div className="d-flex align-items-center flex-column mb-4">
                    <CustomerLabel customer={customer} />
                    <QrCode clientId={customer.id} />
                </div>
            ) : null}
            <div className="d-flex justify-content-center">
                <NewCustomerButton />
            </div>
            {customer ? <ScreenCastLoader clientId={customer.id} /> : null}
        </div>
    );
};

const ScreenCastLoader = ({ clientId }: { clientId: number }) => {
    const { data: screens, error } = useAPI<App[]>("widget/smart-screens");
    if (error) {
        return <ErrorMessage />;
    }
    if (!screens) {
        return <Loading />;
    }
    if (screens.length === 0) return null;
    const qrcodeURL = `${window.location.origin}/gcd?id=${clientId}`;
    return <ScreenCast url={qrcodeURL} screens={screens} />;
};

const ScreenCast = ({ url, screens }: { url: string; screens: App[] }) => {
    const [selectedScreen, setSelectedScreen] = React.useState(screens[0].id);
    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { checked } = e.currentTarget;
        postPublic("screen/qrcode", { url, checked, selectedScreen });
    };
    return (
        <>
            <div className="mt-3">
                <input
                    type="checkbox"
                    className="me-2"
                    id="qrcode-cast"
                    onChange={onChange}
                />
                <label htmlFor="qrcode-cast">
                    Afficher ce QRCode sur l&rsquo;écran
                </label>
                <select
                    className="ms-2"
                    value={selectedScreen}
                    onChange={(e) =>
                        setSelectedScreen(Number(e.currentTarget.value))
                    }
                >
                    {screens.map((screen) => (
                        <option key={screen.id} value={screen.id}>
                            {screen.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="rtpe-qrcode-screen d-flex justify-content-center align-items-center tall-modal-body">
                <QRCodeScreen id={selectedScreen} size={400} />
            </div>
        </>
    );
};
