import * as React from "react";
import { useForm } from "react-hook-form";
import clsx from "classnames";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { i18n } from "@/config/i18n";
import { postContent } from "@/utils/fetch";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

type AccessCategories = {
    rights: Record<string, { id: string; label: string }[]>;
    categories: string[];
};

const AccessRightsForm = ({
    accessCategories,
    userRights,
    userId,
    companyId,
    onSuccess,
}: {
    accessCategories: AccessCategories;
    userRights: string[];
    userId: number;
    companyId: number;
    onSuccess(): void;
}) => {
    const { register, handleSubmit, reset, setValue } = useForm();
    const onSubmit = async (data: Record<string, boolean>) => {
        const rights = Object.keys(data).filter((key) => data[key]);
        try {
            await postContent(`users/setRights/${companyId}/${userId}`, {
                rights,
            });
            toastr.success(i18n.t("users.updated"));
            onSuccess();
        } catch (error) {
            toastr.error(i18n.t("common.error"));
        }
    };

    React.useEffect(() => {
        reset();
    }, [reset, userRights]);

    const checkEveryInput = (checked: boolean) => {
        for (const [category, rights] of Object.entries(
            accessCategories.rights
        )) {
            if (
                accessCategories.categories.length > 0 &&
                !accessCategories.categories.includes(category)
            ) {
                continue;
            }
            for (const right of rights) {
                setValue(right.id, checked);
            }
        }
    };

    return (
        <form
            className="mt-3 overflow-hidden d-flex flex-column"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="d-flex flex-wrap overflow-auto">
                {Object.entries(accessCategories.rights).map(
                    ([category, rights]) => (
                        <section
                            key={category}
                            className={clsx(
                                "flex-column align-items-start mx-2",
                                accessCategories.categories.length > 0 &&
                                    !accessCategories.categories.includes(
                                        category
                                    )
                                    ? "d-none"
                                    : "d-flex"
                            )}
                        >
                            <h3>{category}</h3>
                            <ul className="px-3">
                                {rights.map((right) => (
                                    <li key={right.id}>
                                        <label className="cursor-pointer d-flex">
                                            <span className="me-auto">
                                                {right.label}
                                            </span>
                                            <input
                                                type="checkbox"
                                                className="ms-2"
                                                defaultChecked={userRights.includes(
                                                    right.id
                                                )}
                                                {...register(right.id)}
                                            />
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    )
                )}
            </div>
            <div className="d-flex align-items-end">
                <button className="btn btn-secondary me-2" type="reset">
                    Réinitialiser
                </button>
                <button className="btn btn-primary">Valider</button>
                <button
                    className="btn btn-primary me-2 ms-auto"
                    type="button"
                    onClick={() => checkEveryInput(true)}
                >
                    Tous
                </button>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => checkEveryInput(false)}
                >
                    Aucun
                </button>
            </div>
        </form>
    );
};

export const AccessRights = ({
    userId,
    companyId,
}: {
    userId: number;
    companyId: number;
}) => {
    const { data: categories, error } =
        useAPI<AccessCategories>("accessRights");
    const {
        data: userRights,
        error: errorUser,
        fetchData,
    } = useAPI<string[]>(`users/rights/${companyId}/${userId}`, {
        ignoreCache: true,
    });
    if (error || errorUser) return <ErrorMessage />;
    if (!categories || !userRights) return <Loading />;
    return (
        <AccessRightsForm
            accessCategories={categories}
            userRights={userRights}
            userId={userId}
            companyId={companyId}
            onSuccess={fetchData}
        />
    );
};
