import type * as React from "react";

type Props = {
    name: string;
    children: React.ReactNode;
};

export const Widget = ({ name, children }: Props): JSX.Element => {
    return (
        <div className="info">
            <h3>{name}</h3>
            {children}
        </div>
    );
};
