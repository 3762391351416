import * as React from "react";
import type Autosuggest from "react-autosuggest";

import type { CompletionEntry } from "shared/dist/types/types";

import { Autocomplete } from "@/components/Autocomplete";
import { IMAGES_SERVER } from "@/config/settings";
import { getContent } from "@/utils/fetch";
import { replaceIgnoreCase } from "@/utils/utils";

type WPPost = "post" | "page" | "r3d" | "3d-flip-book";
type WPSuggestion = CompletionEntry & {
    url: string;
    post_type: WPPost;
};

const getImgURL = (type: WPPost) => `${IMAGES_SERVER}/wp-type/${type}.png`;

const renderSuggestion = (
    suggestion: WPSuggestion,
    { query }: { query: string }
): JSX.Element => (
    <div className="d-flex align-items-center">
        <div className="img-app-small me-2">
            <img src={getImgURL(suggestion.post_type)} alt="" />
        </div>
        <div>
            <span
                dangerouslySetInnerHTML={{
                    __html: replaceIgnoreCase(
                        suggestion.label ?? "",
                        query,
                        '<span class="autosuggest-query">' + query + "</span>"
                    ),
                }}
            />
        </div>
    </div>
);

const autocomplete = async (input: string): Promise<WPSuggestion[]> => {
    const suggestions = await getContent<Omit<WPSuggestion, "url">[]>(
        `completion/wp/${encodeURIComponent(input)}`
    );
    return suggestions.map((suggestion) => ({
        label: suggestion.label,
        value: suggestion.label,
        url: suggestion.value,
        post_type: suggestion.post_type,
    }));
};

export const WordPressSuggestions = (props: {
    onChange(value: string): void;
    defaultValue?: string | null;
    onSuggestionSelected: Autosuggest.OnSuggestionSelected<WPSuggestion>;
}): JSX.Element => {
    const [currentValue, setCurrentValue] = React.useState(
        props.defaultValue || ""
    );
    const onChange = (
        _event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => {
        setCurrentValue(newValue);
        props.onChange(newValue);
    };

    return (
        <Autocomplete
            value={currentValue}
            onChange={onChange}
            getSuggestions={autocomplete}
            renderSuggestion={renderSuggestion}
            inputProps={{ maxLength: 200 }}
            onSuggestionSelected={props.onSuggestionSelected}
        />
    );
};
