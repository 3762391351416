import * as React from "react";
import { UncontrolledTooltip } from "reactstrap";

import type { OE } from "shared/dist/types/item";

import { IdlpData, useAvailability } from "@/utils/availability";

const IdlpPrice = (
    prix: Partial<IdlpData["prix"]> & { from: string; currency?: string }
): JSX.Element => {
    const ref = React.useRef<HTMLHeadingElement>(null);
    const currency = prix.currency || "€";
    return (
        <div className="item-price">
            <h3 ref={ref}>
                {prix.prixBase}
                {currency} HT
            </h3>
            <UncontrolledTooltip target={ref}>
                <div>{prix.from}</div>
                <div>remise 1: {prix.remise1}%</div>
                {prix.remise2 && <div>remise 2: {prix.remise2}%</div>}
                {prix.consigne && <div>consigne: {prix.consigne}€</div>}
            </UncontrolledTooltip>
        </div>
    );
};

export const Price = ({
    oe,
    supplier,
}: {
    oe: OE;
    supplier: string;
}): React.ReactElement => {
    const { availability } = useAvailability(oe);
    if (supplier === "local" || availability.length === 0) {
        return <h3>{oe.price || "XXX.xx"} € HT</h3>;
    }
    const data = availability.find((idlp) => idlp.from === supplier);
    if (!data) {
        return <h3>{oe.price || "XXX.xx"} € HT</h3>;
    }
    return (
        <IdlpPrice {...data.prix} from={data.from} currency={data.currency} />
    );
};
