import * as React from "react";
import ReactDatePicker, {
    ReactDatePickerProps,
    registerLocale,
} from "react-datepicker";

import { useLanguage } from "@/utils/hooks";

const DATE_FORMAT = "dd/MM/yyyy HH:mm";
const TIME_FORMAT = "HH:mm";

type DatePickerProps = {
    selected: Date | null;
    onChange: (date: Date) => void;
    disabled?: boolean;
} & ReactDatePickerProps;

export const DatePicker = ({
    selected,
    onChange,
    disabled = false,
    ...otherProps
}: DatePickerProps): JSX.Element => {
    const lang = useLanguage();
    const [locale, setLocale] = React.useState("");
    React.useEffect(() => {
        const dateLocale = lang === "zh" ? "zh-CN" : lang;
        import(`date-fns/locale/${dateLocale}/index.js`)
            .then((langData) => {
                registerLocale(lang, langData.default);
                setLocale(lang);
            })
            .catch((error) => console.error(error));
    }, [lang]);

    return (
        <ReactDatePicker
            wrapperClassName="ms-auto"
            selected={selected}
            onChange={onChange}
            disabled={disabled}
            dateFormat={DATE_FORMAT}
            timeFormat={TIME_FORMAT}
            showTimeSelect
            locale={locale}
            {...otherProps}
        />
    );
};
