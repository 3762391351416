import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faAccusoft,
    faAppStore,
    faWordpress,
} from "@fortawesome/free-brands-svg-icons";
import {
    faComments,
    faQuestionCircle,
    faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
    faCarSide,
    faChartLine,
    faDesktop,
    faGlobe,
    faHome,
    faQrcode,
    faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import type { CustomTypeOptions } from "i18next";
import { useRecoilValue } from "recoil";

import type { UserAccess } from "shared/dist/types/auth";

import { DEFAULT_COMPANY_ID } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import {
    identificationVehiculePageAtom,
    leftMenuState,
    piecesPageAtom,
    selectionVehiculePageAtom,
    sidebarVisibleAtom,
    socialPageAtom,
    urlMyBoxAtom,
} from "@/globalState/atoms";

type ButtonId =
    keyof CustomTypeOptions["resources"]["translations"]["leftmenu"];

type MenuButton = {
    id: ButtonId;
    access: UserAccess;
    icon: IconProp;
};

// exported for use in TitleWithIcon
export const LEFT_MENU_BUTTONS: MenuButton[] = [
    {
        id: "identification-vehicule",
        icon: "certificate",
        access: "CarIdentifier",
    },
    { id: "selection-vehicule", icon: "car", access: "CarSelector" },
    { id: "stats-selector", icon: faChartLine, access: "StatsSelector" },
    { id: "v3", icon: faChartLine, access: "v3" },
    { id: "pieces", icon: "barcode", access: "PartSelector" },
    { id: "diag", icon: faAccusoft, access: "Diag" },
    { id: "oe-selector", icon: faCarSide, access: "OESelector" },
    { id: "part-selector-oe", icon: faCarSide, access: "PartSelectorOE" },
    { id: "social", icon: "users", access: "Social" },
    { id: "rse", icon: "users", access: "RSE" },
    { id: "smart", icon: "users", access: "Smart" },
    { id: "lms", icon: "users", access: "LMS" },
    { id: "app/erp", icon: "building", access: "ERP" },
    { id: "app/com", icon: "bullhorn", access: "Com" },
    { id: "app/visio", icon: faComments, access: "Visio" },
    { id: "app/back", icon: faUser, access: "BackOffice" },
    { id: "app/help", icon: faQuestionCircle, access: "Help" },
    { id: "app/store", icon: "cart-plus", access: "Store" },
    { id: "qrcodes", icon: faQrcode, access: "admin" },
    { id: "admin-idcar", icon: faWallet, access: "admin" },
    { id: "apps-manager", icon: faAppStore, access: "admin" },
    { id: "wp", icon: faWordpress, access: "WordPress" },
    { id: "map", icon: faGlobe, access: "map" },
    { id: "users", icon: "users", access: "UsersAdmin" },
];

export const getTitleId = <ID extends ButtonId>(id: ID): `leftmenu.${ID}` =>
    `leftmenu.${id}`;

export const usePageURL = () => {
    const identificationVehiculePage = useRecoilValue(
        identificationVehiculePageAtom
    );
    const selectionVehiculePage = useRecoilValue(selectionVehiculePageAtom);
    const socialPage = useRecoilValue(socialPageAtom);
    const piecesPage = useRecoilValue(piecesPageAtom);
    return (id: string) => {
        switch (id) {
            case "identification-vehicule":
                return identificationVehiculePage;
            case "selection-vehicule":
                return selectionVehiculePage;
            case "pieces":
                return piecesPage;
            case "social":
                return socialPage;
            default:
                return "/" + id;
        }
    };
};

export const LeftMenu = (): JSX.Element => {
    const { t } = useTranslation();
    const { hasAccess, userInfo } = useAuth();
    const extended = useRecoilValue(leftMenuState) === "expanded";
    const getPageURL = usePageURL();
    const showMyBoxLink = useRecoilValue(urlMyBoxAtom) !== "";
    const visible = useRecoilValue(sidebarVisibleAtom);

    /* Warning: if you change HTML markup here, you might need to update
     * ContactSelectorPanelRSE clickHandler */
    return (
        <nav
            className={classnames("sidebar main-bloc", {
                extended,
                hidden: !visible,
            })}
        >
            {userInfo.companyId === DEFAULT_COMPANY_ID && (
                <Link to="/home">
                    <div className="btn-img">
                        <FontAwesomeIcon icon={faHome} />
                    </div>
                    <span>Accueil</span>
                </Link>
            )}
            {LEFT_MENU_BUTTONS.filter((button) => hasAccess(button.access)).map(
                (btn, i) => (
                    <Link
                        to={getPageURL(btn.id)}
                        title={t(getTitleId(btn.id))}
                        key={i}
                    >
                        <div className="btn-img">
                            <FontAwesomeIcon icon={btn.icon} />
                        </div>
                        <span>{t(getTitleId(btn.id))}</span>
                    </Link>
                )
            )}
            {showMyBoxLink && (
                <Link to="/app/mybox" title={t("leftmenu.app/mybox")}>
                    <div className="btn-img">
                        <FontAwesomeIcon icon={faDesktop} />
                    </div>
                </Link>
            )}
        </nav>
    );
};
