import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactTable, { Column, RowInfo } from "react-table";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "classnames";

import type { Channel, RawCartItem } from "shared/dist/types/cart";

import {
    getImgList,
    ItemColumnMake,
    ItemDesc as OEItemDesc,
} from "@/components/Item/ArticleTable";
import { AvailabilityCircle } from "@/components/Item/Availability";
import { PartImg } from "@/components/Item/PartImg";
import {
    DescriptionCell,
    PhotoCell,
    TireAvailabilityCell,
} from "@/components/TireStock";
import { MakeCell as TireMakeCell } from "@/components/TireStock";
import { CartContext } from "@/context/CartItems";
import { useCustomer } from "@/context/CustomerContext";
import { useAvailability } from "@/utils/availability";
import { fetchWithAuth } from "@/utils/fetch";
import { useCartHighlight } from "@/utils/useCartHighlight";

import { CheckoutButton } from "./CheckoutButton";
import { QuoteButton } from "./QuoteButton";

import "./CartTable.css";

export const ItemColumnPhoto = ({
    original,
}: {
    original: RawCartItem;
}): JSX.Element =>
    "saison" in original.item ? (
        <PhotoCell original={original.item} />
    ) : (
        <PartImg imgList={getImgList(original.item)} title={original.item.id} />
    );

const makeDeleteButton = (deleteItem: (itemId: number) => void) => {
    const DeleteButton = ({
        original: item,
    }: {
        original: RawCartItem;
    }): JSX.Element => {
        const [customer] = useCustomer();
        const handleClick = (): void => {
            fetchWithAuth(`cartItem/${customer?.id}/${item.id}`, {
                method: "delete",
            })
                .then(() => deleteItem(item.id))
                .catch((error) => console.error(error));
        };
        return (
            <button className="img-button" onClick={handleClick}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
        );
    };
    return DeleteButton;
};

const computeCartsTotal = (sum: number, item: RawCartItem): number =>
    sum + item.price * item.quantity;

export const formatPrice = (price: number, channel?: Channel): string => {
    const currency = channel === "Techauto" ? "CHF" : "€";
    return `${(price / 100).toFixed(2)}${currency}`;
};

const CATEGORY_LABEL = {
    Premium: "Norauto",
    Quality: "Oxylio",
    "1er prix": "Carter Cash",
} as const;

const isKnownCategory = (
    category: string
): category is keyof typeof CATEGORY_LABEL => category in CATEGORY_LABEL;

export const ItemPrice = ({ original }: { original: RawCartItem }) =>
    formatPrice(original.price, original.channel);

const ItemAvailability = ({
    original: item,
}: {
    original: RawCartItem;
}): JSX.Element => {
    const oe = item.channel === "Tire" ? null : item.item;
    const stock = useAvailability(oe);
    if (item.channel === "Tire") {
        return <TireAvailabilityCell />;
    }
    let texte = "";
    if (item.channel === "Caia") {
        texte = item.item.partType || "Local";
    }
    if (["Reparcar", "GPA26"].includes(item.channel)) {
        texte = "Enseigne";
    }
    const availability = stock.availability.find(
        (av) => av.from.replace(/\d+$/, "") === item.channel
    );
    return (
        <AvailabilityCircle
            quantity={item.quantity}
            from={item.channel}
            text={texte}
            availability={availability?.disponibilite}
            itemRef={oe?.id}
        />
    );
};

export const MakeCell = ({
    original,
}: {
    original: RawCartItem;
}): JSX.Element => {
    if ("saison" in original.item) {
        return <TireMakeCell value={original.item.marque} />;
    }
    return <ItemColumnMake original={original.item} />;
};

export const ItemDesc = ({
    original,
}: {
    original: RawCartItem;
}): JSX.Element => {
    const { item, ktype } = original;
    if ("saison" in item) {
        return <DescriptionCell original={item} hideCarPicto />;
    }
    return <OEItemDesc item={item} ktypnr={ktype} />;
};

export const vendorLabel = (item: RawCartItem) => {
    if (item.channel !== "Tire") return item.channel;
    const category = item.item["catégorie de marque"];
    const label = isKnownCategory(category)
        ? CATEGORY_LABEL[category]
        : "Oxylio";
    return label;
};

export const CartTable = (): JSX.Element => {
    const { t } = useTranslation();
    const { cart, updateCart } = React.useContext(CartContext);
    const { customerRowIndex, vendorRowIndex, toggleVendorRow } =
        useCartHighlight();

    const deleteItem = (itemId: number): void => {
        updateCart(cart.filter((item) => item.id !== itemId));
    };

    const price = cart.reduce(computeCartsTotal, 0);
    const total = formatPrice(price);

    const columns: Column<RawCartItem>[] = [
        {
            Header: t("item.seller"),
            width: 100,
            accessor: vendorLabel,
            id: "channel",
        },
        {
            Header: t("item.make"),
            width: 100,
            Cell: MakeCell,
            accessor: "makeLabel",
        },
        {
            Header: t("item.photo"),
            width: 100,
            Cell: ItemColumnPhoto,
            sortable: false,
        },
        {
            Header: t("item.desc"),
            Cell: ItemDesc,
            id: "Article",
        },
        {
            Header: t("item.availability"),
            Cell: ItemAvailability,
            id: "availability",
            width: 100,
        },
        {
            Header: t("item.price"),
            width: 100,
            accessor: "price",
            Cell: ItemPrice,
            id: "price",
        },
        {
            Header: t("item.quantity"),
            width: 100,
            accessor: "quantity",
        },
        {
            Header: t("item.total"),
            width: 100,
            id: "total",
            accessor: (item: RawCartItem): string =>
                formatPrice(item.price * item.quantity, item.channel),
        },
        {
            width: 50,
            Cell: makeDeleteButton(deleteItem),
            sortable: false,
        },
    ];

    return (
        <>
            <ReactTable
                data={cart}
                columns={columns}
                defaultPageSize={30}
                minRows={1}
                showPagination={false}
                className="overflow-hidden"
                getTrProps={(state: unknown, rowInfo: RowInfo | undefined) => {
                    const index = rowInfo?.index;
                    const className = clsx({
                        "customer-highlight": index === customerRowIndex,
                        "vendor-highlight": index === vendorRowIndex,
                    });
                    return {
                        onClick() {
                            toggleVendorRow(index);
                        },
                        className,
                    };
                }}
            />
            <div className="cart-footer">
                <strong>Total: {total}</strong>
                {/* <Link to="/checkout/address">Checkout</Link> */}
                <div>
                    <QuoteButton />
                    <CheckoutButton />
                </div>
            </div>
        </>
    );
};
