import * as React from "react";

import type { RawCartItem } from "shared/dist/types/cart";

type CartContext = [
    cart: RawCartItem[],
    updateCart: React.Dispatch<React.SetStateAction<RawCartItem[]>>
];

export const CartContext = React.createContext<CartContext | undefined>(
    undefined
);

type Props = {
    children: React.ReactNode;
};

export const CartProvider = (props: Props): JSX.Element => {
    const state = React.useState<RawCartItem[]>([]);

    return (
        <CartContext.Provider value={state}>
            {props.children}
        </CartContext.Provider>
    );
};

export const useCart = (): CartContext => {
    const context = React.useContext(CartContext);
    if (context === undefined) {
        throw new Error("useCart must be used within a CartProvider");
    }
    return context;
};
