import * as React from "react";

import type { LevamData } from "shared/dist/types/levam";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { NoResults } from "@/components/NoResults";
import { useVehicleData } from "@/context/VehicleDataContext";
import { useAPI, useLanguage } from "@/utils/hooks";

const OEMContent = (): JSX.Element => {
    const [data] = useVehicleData();
    const lang = useLanguage();
    const vin = data.Codif_Vin_PRF;
    const url = vin ? `naiVin/${vin}/${lang}` : null;
    const { data: oemData, error } = useAPI<LevamData>(url);

    if (error) {
        return <ErrorMessage />;
    }

    if (!oemData) {
        return <Loading />;
    }

    if (oemData.error) {
        return <NoResults />;
    }

    const altLabel = `${oemData.client.mark} ${oemData.client.model}`;

    return (
        <>
            <div>
                <strong>VIN:</strong>
                <span>{vin}</span>
            </div>
            <div className="flex-grow-1">
                <img src={oemData.image} alt={altLabel} />
            </div>
        </>
    );
};

export const OEMBloc = (): JSX.Element => (
    <div className="oem-bloc d-flex flex-column">
        <OEMContent />
    </div>
);
