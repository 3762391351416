import type * as React from "react";

import { CriteriaProvider } from "@/context/CriteriaContext";

import { CountryProvider } from "../context/CountryContext";

import { ErrorBoundary } from "./ErrorBoundary";

export const MainContent = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    return (
        <ErrorBoundary>
            <CountryProvider>
                <CriteriaProvider>{children}</CriteriaProvider>
            </CountryProvider>
        </ErrorBoundary>
    );
};
