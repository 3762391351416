import type { UserAccess } from "shared/dist/types/auth";
import type { LevamData } from "shared/dist/types/levam";

import { useAuth } from "@/context/AuthContext";
import { useVehicleData } from "@/context/VehicleDataContext";

import { useAPI, useLanguage } from "./hooks";

export const useOEMData = (access: UserAccess) => {
    const lang = useLanguage();
    const [data] = useVehicleData();
    const { hasAccess } = useAuth();
    const vin = data.Codif_Vin_PRF;
    const url = vin && hasAccess(access) ? `naiVin/${vin}/${lang}` : null;
    const result = useAPI<LevamData>(url);
    return result;
};
