import * as React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import { Loading } from "@/components/Loading";
import { WORDPRESS_URL } from "@/config/settings";
import { useAPI } from "@/utils/hooks";

// Can't use /components/ErrorMessage.tsx because it uses useAuth which is not
// available in this context
const ErrorMessage = (): JSX.Element => {
    const { t } = useTranslation();
    return <Alert color="danger">{t("common.error")}</Alert>;
};

const ExternalRedirect = ({ to }: { to: string }) => {
    React.useEffect(() => {
        window.location.replace(to);
    }, [to]);
    return null;
};

export const WordpressAuthent = (): JSX.Element => {
    const { data, error } = useAPI<{ token: string }>("wp-auth", {
        ignoreCache: true,
    });
    if (error) {
        return <ErrorMessage />;
    }
    if (!data) {
        return <Loading />;
    }
    const url = `${WORDPRESS_URL}?auth=sso&code=${encodeURIComponent(
        data.token
    )}`;
    return <ExternalRedirect to={url} />;
};
