import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";

import { apiRootURL } from "@/config/settings";
import { useAuth } from "@/context/AuthContext";
import { useExpertDataMode } from "@/context/ExpertDataContext";
import { useAPI } from "@/utils/hooks";

import { FamilyAlbum } from "./FamilyAlbum";

const MARGIN_RIGHT_350 = { marginRight: 350 };
const PADDING_20 = { padding: 20 };

type Props = {
    ktype: string | undefined;
};

export const TireAlbum = ({ ktype }: Props): JSX.Element | null => {
    const { t } = useTranslation();
    const { hasAccess } = useAuth();
    const expertDataMode = useExpertDataMode();
    const history = useHistory();
    const { pathname } = useLocation();
    const match = useRouteMatch<{ vtype: string; 0: string }>();

    const { data: hasTires } = useAPI<boolean>(
        ktype ? `hasTires/${ktype}` : null
    );

    const { register, handleSubmit } = useForm();

    const displayTireFamily =
        hasAccess("Pneu") &&
        match.path !== "/pieces/:brand" &&
        match.path !== "/pieces-oe/:brand" &&
        (hasTires || expertDataMode);

    if (!displayTireFamily) {
        return null;
    }

    const submitTires = (values: Record<string, boolean>): void => {
        const categoryIds = Object.keys(values).filter((key) => values[key]);
        if (categoryIds.length === 0) {
            return;
        }
        const url = `${match.url}/${categoryIds.join("-")}/tireStock`;
        history.push(url);
    };

    return (
        <FamilyAlbum
            name={t("albums.tires")}
            path="/tires"
            isSelected={pathname.endsWith("tires")}
            imgURL={`${apiRootURL}/media/img/tire.png`}
            bigImgUrl={`${apiRootURL}/media/img/tire-illustration.jpg`}
            detailsClassName="tire-inputs"
        >
            <form
                className="d-flex flex-grow-1 justify-content-between"
                onSubmit={handleSubmit(submitTires)}
                style={MARGIN_RIGHT_350}
            >
                <ul className="details-list" style={PADDING_20}>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked
                                {...register("Premium")}
                            />{" "}
                            <span>{t("albums.tire-quality.premium")}</span>
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked
                                {...register("Quality")}
                            />{" "}
                            <span>{t("albums.tire-quality.quality")}</span>
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked
                                {...register("1er prix")}
                            />{" "}
                            <span>{t("albums.tire-quality.low-cost")}</span>
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked
                                {...register("Rechappé")}
                            />{" "}
                            <span>{t("albums.tire-quality.retreaded")}</span>
                        </label>
                    </li>
                </ul>
                <Button color="primary" className="align-self-end">
                    {t("common.submit")}
                </Button>
            </form>
        </FamilyAlbum>
    );
};
