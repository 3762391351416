import * as React from "react";
import { Carousel, CarouselControl, CarouselItem } from "reactstrap";

import type { PageWidget } from "shared/dist/types/apps";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useAPI } from "@/utils/hooks";

interface Props {
    activeIndex: number;
    setActiveIndex: (index: number) => void;
}

const GenartCarousel = ({
    activeIndex,
    setActiveIndex,
}: Props): JSX.Element => {
    const { data: carouselItems, error } = useAPI<PageWidget[]>("apps/widgets");
    const [animating, setAnimating] = React.useState(false);

    if (error) {
        return <ErrorMessage />;
    }
    if (!carouselItems) {
        return <Loading />;
    }
    const next = (): void => {
        if (animating) return;
        const nextIndex =
            activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = (): void => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const slides = carouselItems.map((item) => {
        return (
            <CarouselItem
                onExiting={(): void => setAnimating(true)}
                onExited={(): void => setAnimating(false)}
                key={item.id}
            >
                <p>{item.name}</p>
                <p>{item.subtitle}</p>
                <img src={item.img} alt="logo" />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            className="genart-carousel"
            autoPlay={false}
            interval={false}
        >
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
};

export default GenartCarousel;
