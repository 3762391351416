import type * as React from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMotorcycle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Vehicle } from "shared/dist/types/eurotax";

import { useExpertDataMode } from "@/context/ExpertDataContext";
import { useAPI } from "@/utils/hooks";
import { getLogoURL } from "@/utils/utils";

import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";
import { NoResults } from "./NoResults";

const formatVehicle = (vehicle: Vehicle) =>
    `${vehicle.Make.Name} ${vehicle.Model.Name}`;

const VehicleRowLabel = ({
    vehicle,
    prefix = "/identification-vehicule",
}: {
    vehicle: Vehicle;
    prefix?: string;
}) => {
    const label = formatVehicle(vehicle);
    if (vehicle.ktypnr) {
        return (
            <Link
                className="flex-shrink-0"
                to={`${prefix}/search/ktype/${vehicle.ktypnr}`}
            >
                {label}
            </Link>
        );
    } else {
        return <span>{label}</span>;
    }
};

const VehicleRowFinish = ({ vehicle }: { vehicle: Vehicle }) => {
    const expertDataMode = useExpertDataMode();
    if (vehicle.variants.length === 0) {
        return null;
    }
    const display = (variant: Vehicle["variants"][0]) =>
        expertDataMode ? `${variant.finish} (${variant.code})` : variant.finish;
    if (vehicle.variants.length === 1) {
        return <span className="ms-2">{display(vehicle.variants[0])}</span>;
    }
    return (
        <Input type="select" className="ms-2">
            {vehicle.variants.map((variant, i) => (
                <option key={i}>{display(variant)}</option>
            ))}
        </Input>
    );
};

const VehicleRow = ({
    make,
    type,
    children,
}: {
    make: string;
    type: string;
    children?: React.ReactNode;
}) => {
    let icon: IconProp | null = null;
    let logoURL = getLogoURL(make);
    switch (type) {
        case "10":
            icon = "car";
            break;
        case "60":
            icon = faMotorcycle;
            logoURL = getLogoURL(make, "motos");
            break;
    }
    return (
        <div className="vehicle-row d-flex align-items-center mb-2">
            <div className="vehicle-row-brand">
                <img src={logoURL} alt={make} height="80" width="80" />
            </div>
            <div className="vehicle-row-content">
                <div className="d-flex align-items-center">{children}</div>
                {icon ? <FontAwesomeIcon icon={icon} className="ms-2" /> : null}
            </div>
        </div>
    );
};

const SwissVehiclesList = ({
    vehicles,
    prefix,
}: {
    vehicles: Vehicle[];
    prefix?: string;
}): JSX.Element => {
    return (
        <ul className="list-none ps-0">
            {vehicles.map((vehicle) => (
                <li key={vehicle.Type.NationalVehicleCode}>
                    <VehicleRow
                        make={vehicle.make || vehicle.Make.Name}
                        type={vehicle.VehicleType.VehicleTypeCode}
                    >
                        <VehicleRowLabel vehicle={vehicle} prefix={prefix} />
                        <VehicleRowFinish vehicle={vehicle} />
                    </VehicleRow>
                </li>
            ))}
        </ul>
    );
};

export const SwissVehicles = ({
    plate,
    prefix,
}: {
    plate: string;
    prefix?: string;
}): JSX.Element => {
    const { data, error } = useAPI<Vehicle[]>(
        plate ? `swissVehicles/${plate}` : null
    );

    if (error) {
        return <ErrorMessage />;
    }

    if (!data) {
        return <Loading />;
    }

    if (data.length === 0) {
        return <NoResults />;
    }

    return <SwissVehiclesList vehicles={data} prefix={prefix} />;
};
