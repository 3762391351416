import * as React from "react";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { NoResults } from "@/components/NoResults";
import { useAPI, useLanguage } from "@/utils/hooks";

import { TecdocPrice, TecdocPriceComponent } from "./TecdocPriceComponent";

type Props = {
    artNr: string;
    DLNr: string;
};

export const WorldMarketPrices = ({ artNr, DLNr }: Props): JSX.Element => {
    const lang = useLanguage();
    const { data, error } = useAPI<TecdocPrice[]>(
        `/prices/${lang}/${encodeURIComponent(artNr)}/${DLNr}`
    );

    if (error) {
        return <ErrorMessage />;
    }

    if (!data) {
        return <Loading />;
    }

    if (data.length === 0) {
        return <NoResults />;
    }

    return (
        <div className="p-2">
            {data.map((price, i) => (
                <TecdocPriceComponent key={i} price={price} />
            ))}
        </div>
    );
};
