import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";

import type { CustomerDetails } from "shared/dist/types/types";

import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { useAPI } from "@/utils/hooks";
import { toastr } from "@/utils/toastr";

const formatData = (data: CustomerDetails): JSX.Element => (
    <table>
        <tbody>
            <tr>
                <td>Nom</td>
                <td>{data.nom}</td>
            </tr>
            <tr>
                <td>Prénom</td>
                <td>{data.prenom}</td>
            </tr>
            <tr>
                <td>Société</td>
                <td>{data.societe}</td>
            </tr>
            <tr>
                <td>Immatriculation</td>
                <td>{data.immatriculation}</td>
            </tr>
            <tr>
                <td>Description</td>
                <td>
                    <pre>{data.description}</pre>
                </td>
            </tr>
        </tbody>
    </table>
);

type Props = {
    path?: string;
};

export const PhoneRedirect = ({
    path = "/identification-vehicule",
}: Props): JSX.Element => {
    const { phoneNumber } = useParams<{ phoneNumber: string }>();
    const { t } = useTranslation();

    const { data, error } = useAPI<CustomerDetails>(
        `phoneToVin/${phoneNumber}`
    );

    if (error || !data) {
        return (
            <div className="main-bloc phone-redirect">
                {error ? <ErrorMessage /> : <Loading />}
            </div>
        );
    }

    if (data.vin) {
        toastr.info(formatData(data), {
            closeButton: true,
            autoClose: false,
            className: "toast-crm-call",
        });
        const url = `${path}/search/vin/${data.vin}`;
        return <Redirect to={url} />;
    }

    return (
        <div className="main-bloc phone-redirect">
            <p>{t("common.no-results-for", { search: phoneNumber })}</p>
        </div>
    );
};
