import * as React from "react";
import classnames from "classnames";

import { Autocomplete } from "@/components/Autocomplete";
import { useAddItemSlide } from "@/components/Item/ItemSlides";
import {
    autocompleteTires,
    onTireSelected,
} from "@/components/SelectPartBrand";

export const SearchTires = ({ show = true }): JSX.Element => {
    const [searchValue, setSearchValue] = React.useState("");
    const { addTireSlide } = useAddItemSlide();

    const onChange = (
        event: React.FormEvent<HTMLElement>,
        { newValue }: { newValue: string }
    ): void => {
        setSearchValue(newValue);
    };

    const searchTire = (evt: React.FormEvent<HTMLFormElement>): void => {
        evt.preventDefault();
        /*
         * Only keep the first 3 worlds. This allows to get results when
         * searching autosuggestion values
         */
        const search = searchValue.split(/\s+/).slice(0, 3).join(" ");
        addTireSlide(search);
    };

    return (
        <div
            className={classnames("form-group flex-grow-1", {
                ["d-none"]: !show,
            })}
        >
            <label>Pneus</label>
            <form
                className="d-flex align-items-center justify-content-center"
                onSubmit={searchTire}
            >
                <Autocomplete
                    value={searchValue}
                    onChange={onChange}
                    getSuggestions={autocompleteTires}
                    onSuggestionSelected={onTireSelected}
                />
            </form>
        </div>
    );
};
