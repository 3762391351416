import * as React from "react";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { DBWidget } from "shared/dist/types/apps";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Loading } from "@/components/Loading";
import { Restricted } from "@/components/Restricted";
import { useAPI } from "@/utils/hooks";

import { appsContextFactory } from "./AppsContext";
import { RoleGroupsProvider } from "./RoleGroupContext";
import { Widget } from "./Widget";
import { WidgetWizard } from "./WidgetWizard";

import "./RSEEditor.css";

const { AppsProvider, useAppsContext } = appsContextFactory("v2-");

const EditWidget = ({
    widget,
    setWidgets,
}: {
    widget: DBWidget;
    setWidgets: React.ComponentProps<typeof WidgetWizard>["setWidgets"];
}) => {
    const [modalOpen, toggle] = React.useReducer((o) => !o, false);
    return (
        <>
            <button type="button" className="btn" onClick={toggle}>
                <FontAwesomeIcon icon={faEdit} />
            </button>
            {modalOpen && (
                <WidgetWizard
                    isOpen={modalOpen}
                    toggle={toggle}
                    title="Modifier un widget"
                    widget={widget}
                    setWidgets={setWidgets}
                />
            )}
        </>
    );
};

const NewWidget = ({
    setWidgets,
}: {
    setWidgets: React.ComponentProps<typeof WidgetWizard>["setWidgets"];
}) => {
    const [modalOpen, toggle] = React.useReducer((o) => !o, false);
    const widget: DBWidget = {
        id: 0,
        name: "",
        title: "",
        subtitle: "",
        img: "",
    };
    return (
        <>
            <button type="button" className="btn btn-primary" onClick={toggle}>
                Nouveau widget
            </button>
            {modalOpen && (
                <WidgetWizard
                    isOpen={modalOpen}
                    toggle={toggle}
                    title="Nouveau widget"
                    widget={widget}
                    setWidgets={setWidgets}
                />
            )}
        </>
    );
};

const WidgetList = () => {
    const [apps, setApps] = useAppsContext();
    const {
        data: widgets,
        error,
        setData,
    } = useAPI<DBWidget[]>("apps/allWidgets");
    if (error) {
        return <ErrorMessage />;
    }
    if (!widgets) {
        return <Loading />;
    }
    return (
        <>
            {widgets.map((widget) => (
                <Widget
                    name={`v2-${widget.name}`}
                    apps={apps.filter(
                        (app) => app.widget === `v2-${widget.name}`
                    )}
                    setApps={setApps}
                    key={widget.id}
                    label={widget.title}
                >
                    <EditWidget widget={widget} setWidgets={setData} />
                </Widget>
            ))}
            <NewWidget setWidgets={setData} />
        </>
    );
};

const V2EditorWrapper = (): JSX.Element => {
    return (
        <div className="v2-editor flex-grow-1 d-flex flex-column bg-light m-2 p-3 position-relative">
            <h1>V2 Editor</h1>
            <div className="overflow-auto">
                <ErrorBoundary>
                    <RoleGroupsProvider>
                        <AppsProvider>
                            <WidgetList />
                        </AppsProvider>
                    </RoleGroupsProvider>
                </ErrorBoundary>
            </div>
        </div>
    );
};

export const V2Editor = (): JSX.Element => (
    <Restricted access="v2Editor">
        <V2EditorWrapper />
    </Restricted>
);
