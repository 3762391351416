import * as React from "react";
import { useTranslation } from "react-i18next";
import type { Column, RowInfo } from "react-table";
import ReactTable from "react-table";
import clsx from "classnames";

import type { RawCartItem } from "shared/dist/types/cart";
import type { OE } from "shared/dist/types/item";

import { MakeCell as TireMakeCell } from "@/components/TireStock";
import { useCart } from "@/context/SimpleCartContext";
import {
    formatPrice,
    ItemColumnPhoto,
    ItemPrice,
} from "@/pages/ShoppingCart/CartTable";
import { useCartHighlight } from "@/utils/useCartHighlight";
import { getSupplierLogoURL } from "@/utils/utils";

export const Cart = () => {
    const [cart] = useCart();
    return <CartTable cart={cart} />;
};

const CartTable = ({ cart }: { cart: RawCartItem[] }): JSX.Element => {
    const { t } = useTranslation();
    const { customerRowIndex, vendorRowIndex, toggleCustomerRow } =
        useCartHighlight();

    const price = cart.reduce(computeCartsTotal, 0);
    const total = formatPrice(price);

    const columns: Column<RawCartItem>[] = [
        {
            Header: "Article",
            width: 100,
            id: "item",
            accessor: (item) =>
                item.channel === "Tire" ? 4400 : item.item.genartId || "",
        },
        {
            Header: t("item.make"),
            width: 100,
            Cell: MakeCell,
            accessor: "makeLabel",
        },
        {
            Header: t("item.photo"),
            width: 100,
            Cell: ItemColumnPhoto,
            sortable: false,
        },
        {
            Header: t("item.quantity"),
            width: 100,
            accessor: "quantity",
        },
        {
            Header: t("item.price"),
            width: 100,
            accessor: "price",
            Cell: ItemPrice,
            id: "price",
        },
        {
            Header: t("item.total"),
            width: 100,
            id: "total",
            accessor: (item: RawCartItem): string =>
                formatPrice(item.price * item.quantity, item.channel),
        },
    ];

    return (
        <div id="shoppingCart">
            <ReactTable
                data={cart}
                columns={columns}
                defaultPageSize={30}
                minRows={1}
                showPagination={false}
                getTrProps={(state: unknown, rowInfo: RowInfo | undefined) => {
                    const index = rowInfo?.index;
                    const className = clsx({
                        "customer-highlight": index === customerRowIndex,
                        "vendor-highlight": index === vendorRowIndex,
                    });
                    return {
                        onClick() {
                            toggleCustomerRow(index);
                        },
                        className,
                    };
                }}
            />
            <div className="cart-footer">
                <strong>Total TTC: {total}</strong>
            </div>
        </div>
    );
};

const computeCartsTotal = (sum: number, item: RawCartItem): number =>
    sum + item.price * item.quantity;

type ColumnComponent = (props: { original: OE }) => JSX.Element | null;
const ItemColumnMake: ColumnComponent = ({ original: oe }) => {
    return (
        <img src={getSupplierLogoURL(oe.makeId)} alt={oe.makeId} width="60px" />
    );
};

const MakeCell = ({ original }: { original: RawCartItem }): JSX.Element => {
    if ("saison" in original.item) {
        return <TireMakeCell value={original.item.marque} />;
    }
    return <ItemColumnMake original={original.item} />;
};
